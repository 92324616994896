import React, {
  useEffect, useState
} from 'react';
import {
  FormGroup,
  FormLabel,
  Popover
} from '@mui/material';
import {
  FormProvider, SubmitHandler, useForm
} from 'react-hook-form';
import {
  useAppDispatch, useAppSelector
} from '../../app/hooks';
import DropdownSelect from '../../components/forms/DropdownSelect';
import {
  bottomRightAnchorOrigin, topCenterTransformOrigin
} from '../../constants/menu.types';
import { schoolTypeList } from '../../util/dropdown';
import SubmitButton from '../../components/forms/SubmitButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  OrganizationType, postSecondaryDistrict
} from '../../features/organization/organization.model';
import { userSelectors } from '../../features/user/user.slice';
import { ListMenuItem } from '../../components/forms/form.types';
import { organizationSelectors } from '../../features/organization/organization.slice';
import { getAllSchoolDistricts } from '../../features/organization/organization.thunks';


interface SchoolFilterProps {
  anchorElement: HTMLElement | null;
  setAnchorElement: (anchorElement: HTMLElement | null) => void;
  setFiltersCallback: (schoolType?: string, schoolDistricts?: number[]) => void;
}

const SchoolFilter: React.FC<SchoolFilterProps> = ({
  anchorElement,
  setAnchorElement,
  setFiltersCallback,
}) => {

  const dispatch = useAppDispatch();

  const user = useAppSelector(userSelectors.selectUser);
  const schoolDistricts = useAppSelector(organizationSelectors.selectSchoolDistricts);

  const [
    filterSchoolTypeList,
    setFilterSchoolTypeList,
  ] = useState<ListMenuItem[]>([]);

  useEffect(() => {
    dispatch(getAllSchoolDistricts());
  }, []);

  useEffect(() => {
    if (!user) {
      setFilterSchoolTypeList([]);
    } else if (user.isDistrictAdmin && (user.organization?.name !== postSecondaryDistrict)) {
      setFilterSchoolTypeList(schoolTypeList.filter((item: ListMenuItem) => item.id !== OrganizationType.UniversityCollege));
    } else  {
      setFilterSchoolTypeList(schoolTypeList);
    }
  }, [
    user,
  ]);

  type FormValues = { schoolType: ListMenuItem, schoolDistricts: ListMenuItem[] };
  const methods = useForm<FormValues>();
  const { handleSubmit } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    const schoolTypeFilter = values.schoolType?.id.toString();
    const schoolDistrictFilter = (values.schoolDistricts && !!values.schoolDistricts.length) ? values.schoolDistricts.map((district: ListMenuItem) => +district.id) : undefined;
    setFiltersCallback(schoolTypeFilter, schoolDistrictFilter);
    setAnchorElement(null);
  };

  return (
    <Popover
      id="list-filter"
      anchorEl={anchorElement}
      open={Boolean(anchorElement)}
      onClose={() => setAnchorElement(null)}
      transformOrigin={topCenterTransformOrigin}
      anchorOrigin={bottomRightAnchorOrigin}
    >
      <CloseIcon className="close-icon" onClick={() => setAnchorElement(null)} />
      <form className="flex_col" onSubmit={handleSubmit(submitForm)}>
        <FormProvider {...methods}>
          <FormLabel id="school-filter">School Type</FormLabel>
          <FormGroup
            aria-labelledby="school-filter"
          >
            <DropdownSelect
              name="schoolType"
              itemList={filterSchoolTypeList}
              size="small"
              colorVariant="secondary"
            />
          </FormGroup>

          {user.isAdmin && (
            <>
              <FormLabel id="graduation-year-filter">School District</FormLabel>
              <FormGroup
                aria-labelledby="graduation-year-filter"
              >
                <DropdownSelect
                  name="schoolDistricts"
                  itemList={schoolDistricts}
                  size="small"
                  colorVariant="secondary"
                  multiple
                />
              </FormGroup>
            </>
          )}

          <SubmitButton
            className="submit-button"
            text="Apply Filters"
            variant="contained"
            size="small"
            color="secondary"
            disableElevation
          />
        </FormProvider>
      </form>
    </Popover>
  );
};

export default SchoolFilter;
