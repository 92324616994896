/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ListMenuItem } from '../../components/forms/form.types';
import { OrganizationType } from '../organization/organization.model';

export interface UserType extends ListMenuItem {
  type?: OrganizationType;
  isAdminType: boolean;
}

export const ConstructUserType = (data: any): UserType => {
  let type = undefined;
  if (data.name === 'Middle School & Jr. High' || data.label === 'Middle School & Jr. High') {
    type = OrganizationType.MiddleSchool;
  }
  else if (data.name === 'High School' || data.label === 'High School') {
    type = OrganizationType.HighSchool;
  }
  else if (data.name === 'Adult' || data.label === 'Adult') {
    type = OrganizationType.Adult;
  }
  else if (data.name === 'Community' || data.label === 'Community') {
    type = OrganizationType.Community;
  }

  if (data.is_admin_type) {
    type = undefined;
  }

  return {
    id: data.id,
    label: data.name || data.label,
    type,
    isAdminType: data.is_admin_type,
  };
};
