import React, {
  useState, useEffect
} from 'react';
import {
  useForm, FormProvider, SubmitHandler
} from 'react-hook-form';
import { useNavigate } from 'react-router';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, CircularProgress, Menu, MenuItem
} from '@mui/material';
import {
  snakeCase, startCase
} from 'lodash';
import dayjs from 'dayjs';

import { canUseAuthComponent } from '../../../app/app.types';
import {
  UserApprovalStatus,
  UserOverview,
  UserRole,
  User,
  getUserTypeByRole,
  getUserStatus
} from '../../../features/user/user.model';
import {
  getEducationLevels,
  getGenders,
  getEthnicities,
  updateUserInfo,
  deleteUser,
  approveStudent,
  rejectStudent,
  restoreDeletedUser
} from '../../../features/user/user.thunks';
import { userSelectors } from '../../../features/user/user.slice';
import {
  Organization, OrganizationType, postSecondaryDistrict
} from '../../../features/organization/organization.model';
import {
  getAllSchoolDistricts,
  getOrganizationsByType
} from '../../../features/organization/organization.api';
import { setAlert } from '../../../features/alert/alert.slice';
import { defaultMenuPaperProps } from '../../../constants/menu.types';
import {
  numberOnlyValidation,
  formatPhone
} from '../../../util/formatting';
import {
  organizationTypeList,
  userTypeDropdownList
} from '../../../util/dropdown';
import {
  useAppDispatch,
  useAppSelector
} from '../../../app/hooks';
import { ListMenuItem } from '../../forms/form.types';
import StatusLabel from '../../StatusLabel';
import TextInput from '../../forms/TextInput';
import DropdownSelect from '../../forms/DropdownSelect';
import SubmitButton from '../../forms/SubmitButton';
import ChangePasswordDialog from '../../dialog/ChangePasswordDialog';
import UpdatedPasswordSuccessDialog from '../../dialog/UpdatedPasswordSuccessDialog';
import DeleteConfirmationModal from '../../modal/DeleteConfirmationModal';
import RestoreUserConfirmationModal from '../../modal/RestoreUserConfirmationModal';
import { AppAssetPaths } from '../../../app/app.types';

import './EditUserDetail.scss';


interface EditUserDetailProps {
  user: User;
  overview?: UserOverview;
  refreshUserDetail: () => void;
  setEditing: (editing: boolean) => void;
}

const EditUserDetail: React.FC<EditUserDetailProps> = ({
  user,
  overview,
  refreshUserDetail,
  setEditing,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentUser = useAppSelector(userSelectors.selectUser);
  const educationLevels = useAppSelector(userSelectors.selectEducationLevels);
  const educationLevelsLoaded = useAppSelector(userSelectors.selectEducationLevelsLoaded);
  const genders = useAppSelector(userSelectors.selectGenders);
  const gendersLoaded = useAppSelector(userSelectors.selectGendersLoaded);
  const ethnicities = useAppSelector(userSelectors.selectEthnicities);
  const ethnicitiesLoaded = useAppSelector(userSelectors.selectEthnicitiesLoaded);

  const [
    gradYearList,
    setGradYearList,
  ] = useState<ListMenuItem[]>([]);
  const [
    filteredUserTypeList,
    setFilteredUserTypeList,
  ] = useState<ListMenuItem[]>([]);
  const [
    userType,
    setUserType,
  ] = useState<UserRole | undefined>(getUserTypeByRole(user.roles));
  const [
    organizationType,
    setOrganizationType,
  ] = useState<string | number | undefined>(overview?.organizationType);
  const [
    filteredOrganizationTypeList,
    setFilteredOrganizationTypeList,
  ] = useState<ListMenuItem[]>([]);
  const [
    allSchoolsList,
    setAllSchoolsList,
  ] = useState<Organization[]>([]);
  const [
    anchorElement,
    setAnchorElement,
  ] = useState<null | HTMLElement>(null);
  const [
    approvalStatus,
    setApprovalStatus,
  ] = useState<UserApprovalStatus>(undefined as unknown as UserApprovalStatus);
  const [
    showChangePasswordDialog,
    setShowChangePasswordDialog,
  ] = useState(false);
  const [
    showUpdatedPasswordSuccessDialog,
    setShowUpdatedPasswordSuccessDialog,
  ] = useState(false);
  const [
    schoolDistrictList,
    setSchoolDistrictList,
  ] = useState<Organization[]>([]);
  const [
    communityList,
    setCommunityList,
  ] = useState<Organization[]>([]);
  const [
    higherEdOrgList,
    setHigherEdOrgList,
  ] = useState<Organization[]>([]);
  const [
    showDeleteConfirmDialog,
    setShowDeleteConfirmDialog,
  ] = useState(false);
  const [
    showRestoreConfirmDialog,
    setShowRestoreConfirmDialog,
  ] = useState(false);
  const [
    readyToLoad,
    setReadyToLoad,
  ] = useState(false);
  const [
    pageLoaded,
    setPageLoaded,
  ] = useState(false);


  useEffect(() => {
    if (!educationLevelsLoaded) {
      dispatch(getEducationLevels());
    }
    if (!gendersLoaded) {
      dispatch(getGenders());
    }
    if (!ethnicitiesLoaded) {
      dispatch(getEthnicities());
    }

    createGradYears();
    handleUserTypeList();
    getSchoolsForUser();
    setUserType(getUserTypeByRole(user.roles));

    if (user?.isCommunity) {
      const orgList = organizationTypeList.filter(o => o.id !== OrganizationType.NoSchool);
      setFilteredOrganizationTypeList(orgList);
    } else {
      const orgList = organizationTypeList.filter(o => o.id !== OrganizationType.Community && o.id !== OrganizationType.NoSchool);
      setFilteredOrganizationTypeList(orgList);
    }

    setApprovalStatus(getUserStatus(user?.status?.accountApproved, user?.status?.emailVerified));
  }, []);

  useEffect(() => {
    if (userType == UserRole.districtAdmin && !schoolDistrictList.length) {
      getAllSchoolDistricts().then(res => setSchoolDistrictList(res));
    }

    if (userType === UserRole.community && !communityList.length) {
      getOrganizationsByType(UserRole.community).then(res => setCommunityList(res));
    }

    if (userType == UserRole.institutionAdmin && !higherEdOrgList.length) {
      getOrganizationsByType(OrganizationType.UniversityCollege).then(res => setHigherEdOrgList(res));
    }
  }, [
    userType,
  ]);

  useEffect(() => {
    if (pageLoaded) return;

    if (
      (allSchoolsList && !!allSchoolsList.length) &&
      (educationLevels && !!educationLevels.length) &&
      (genders && !!genders.length) &&
      (ethnicities && !!ethnicities.length)
    ) {
      setReadyToLoad(true);
    }
  }, [
    allSchoolsList,
    educationLevels,
    genders,
    ethnicities,
  ]);

  useEffect(() => {
    if (readyToLoad) {
      prePopulateValues();
      setPageLoaded(true);
    }
  }, [
    readyToLoad,
  ]);

  const prePopulateValues = () => {
    setValue('userType', userTypeDropdownList.find((e) => e.label === startCase(userType)));
    setValue('firstName', user.firstname);
    setValue('lastName', user.lastname);
    setValue('email', user.email);
    setValue('organization', handleSchoolFieldDefaultValue());
    setValue('organizationType', filteredOrganizationTypeList.find(e => e.id === overview?.organizationType));
    setValue('phoneNumber', overview?.phoneNumber);
    setValue('school', handleSchoolFieldDefaultValue());
    setValue('graduationYear', gradYearList.find(e => e.id === overview?.graduationYear));
    setValue('parentEducation', educationLevels.find(e => e.label === overview?.parentEducation));
    setValue('educationLevel', educationLevels.find(e => e.id === user?.educationLevelId));
    setValue('gender', genders.find(e => e.label === overview?.gender));
    setValue('ethnicities', (overview?.ethnicities && !!overview?.ethnicities.length) ? overview?.ethnicities : null);
    setValue('points', user.points?.toString());
  };

  const createGradYears = () => {
    const arr = [];
    for (let i = 0; i <= 6; i++) {
      arr.push({
        id: new Date().getFullYear() + i,
        label: (new Date().getFullYear() + i).toString(),
      });
    }
    setGradYearList(arr);
  };

  const handleUserTypeList = () => {
    const tempArr = userTypeDropdownList.map((ele) => {
      if (canUseAuthComponent(currentUser.roles, ele.authorizedRoles)) {
        return ele;
      }
    }).filter(Boolean);
    // This .filter is clearing out undefineds that get genereated from the .map

    setFilteredUserTypeList(tempArr as ListMenuItem[]);
  };

  const handleSchoolFieldDefaultValue = () => {
    if (userType == UserRole.institutionAdmin) {
      return higherEdOrgList.find(e => e.id === overview?.organizationId);
    }

    if (userType === UserRole.community) {
      return communityList.find(e => e.id === overview?.organizationId);
    }

    if (userType === UserRole.districtAdmin) {
      return schoolDistrictList.find(e => e.id === overview?.organizationId);
    }

    return allSchoolsList.find(e => e.id === overview?.organizationId);
  };

  const selectOrgType = (e: ListMenuItem) => {
    if (e && currentUser.isKeyContact || currentUser.isCounselor || currentUser.isInstitutionAdmin) {
      setOrganizationType(e.id);
    }
    else if (e && !currentUser.isDistrictAdmin) {
      setOrganizationType(e.id);

      getOrganizationsByType(e.id as string, undefined, true).then(res => setAllSchoolsList(res));
    } else if (e && currentUser.isDistrictAdmin) {
      setOrganizationType(e.id);
      getOrganizationsByType(e.id as string, currentUser.organizationId, true).then(res => setAllSchoolsList(res));
    }
  };

  const getSchoolsForUser = () => {
    getOrganizationsByType(undefined, undefined, true).then(res => setAllSchoolsList(res));
  };

  const handleUserTypeSelection = (organizationName: string) => {
    if (userType === UserRole.student) {
      return [
        UserRole.student,
        `${organizationType == OrganizationType.MiddleSchool ? UserRole.middleSchoolStudent :
          organizationType == OrganizationType.HighSchool ? UserRole.highSchoolStudent : UserRole.adult}`,
      ];
    }
    else if (userType === UserRole.keyContact) {
      return [
        UserRole.keyContact,
        `${organizationType == OrganizationType.MiddleSchool ? UserRole.middleSchoolKeyContact :
          organizationType == OrganizationType.HighSchool ? UserRole.highSchoolKeyContact :
            organizationType == OrganizationType.Adult ? UserRole.adultKeyContact : UserRole.universityKeyContact}`,
      ];
    }
    else if (userType === UserRole.counselor) {
      return [
        UserRole.counselor,
        `${organizationType == OrganizationType.MiddleSchool ? UserRole.middleSchoolCounselor :
          organizationType == OrganizationType.HighSchool ? UserRole.highSchoolCounselor :
            organizationType == OrganizationType.Adult ? UserRole.adultCounselor : UserRole.universityCounselor}`,
      ];
    }
    else if (userType === UserRole.community) {
      return [
        UserRole.community,
      ];
    }
    else if (userType == UserRole.districtAdmin) {
      if (organizationName === postSecondaryDistrict) {
        return [
          UserRole.districtAdmin,
          UserRole.institutionAdmin,
          UserRole.community,
        ];
      } else {
        return [
          UserRole.districtAdmin,
        ];
      }
    }
    else if (userType === UserRole.institutionAdmin) {
      return [
        UserRole.keyContact,
        UserRole.universityKeyContact,
        UserRole.community,
        UserRole.institutionAdmin,
      ];
    }
    else {
      return [
        UserRole.admin,
      ];
    }
  };

  const formValidationSchema = yup.object().shape({
    userType: yup.object()
      .required('User Type is required'),
    firstName: yup.string()
      .required('First Name is required')
      .matches(/^[a-zA-Z\s]+$/, 'This field only allows letters and spaces'),
    lastName: yup.string()
      .required('Last Name is required.')
      .matches(/^[a-zA-Z\s]+$/, 'This field only allows letters and spaces'),
    email: yup.string()
      .required('Contact Email is required')
      .email('Must be a valid email'),
    organizationType: (userType !== UserRole.districtAdmin && userType !== UserRole.admin) ?
      yup.object()
        .required('Organization Type is required') :
      yup.object().nullable(),
    organization: (userType === UserRole.community || userType === UserRole.institutionAdmin || userType === UserRole.districtAdmin) ?
      yup.object()
        .required('Organization is required') :
      yup.object().nullable(),
    school: (userType !== UserRole.districtAdmin && userType !== UserRole.admin && userType !== UserRole.community && userType !== UserRole.institutionAdmin) ?
      yup.object()
        .required('School is required') :
      yup.object().nullable(),
    points: yup.number().typeError(''),
    ethnicities: yup.array()
      .when('userType', {
        is: (userType: UserRole) => userType === UserRole.student && !currentUser.isInstitutionAdmin,
        then: yup.array()
          .min(1, 'Race / Ethnicity is required')
          .required('Race / Ethnicity is required')
          .typeError('Race / Ethnicity is required'),
        otherwise: yup.array().nullable(),
      }),
  }).required();

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const { handleSubmit, setValue, formState: { errors } } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    const payload = {
      id: user.id,
      firstName: values.firstName,
      lastName: values.lastName,
      gradYear: values.graduationYear || gradYearList.find(e => e.id === user.graduationYear),
      educationLevel: values.educationLevel || educationLevels.find(e => e.id === user.educationLevelId),
      parentEducation: values.parentEducation || educationLevels.find(e => e.id === user.parentEducationLevelId),
      gender: values.gender || genders.find(e => e.id === user.genderId),
      ethnicities: values.ethnicities || null,
      email: values.email,
      points: values.points,
      organization: values.school || values.organization,
      phoneNumber: values.phoneNumber,
      roles: handleUserTypeSelection(values?.organization?.name ? values.organization.name : values.school.name),
    };

    dispatch(updateUserInfo(payload))
      .unwrap()
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully updated user.',
        }));
        navigate(-1);
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to update user.',
        }));
      });
  };

  const changePassword = (values: { password: string }) => {
    const payload = {
      id: user.id,
      password: values.password,
    };

    dispatch(updateUserInfo(payload))
      .unwrap()
      .then(() => {
        setShowChangePasswordDialog(false);
        setShowUpdatedPasswordSuccessDialog(true);

        dispatch(setAlert({
          type: 'success',
          message: 'Successfully updated password.',
        }));
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to update password.',
        }));
      });
  };

  const onDeleteUser = () => {
    setShowDeleteConfirmDialog(false);
    if (!user.id) return;

    dispatch(deleteUser(user.id))
      .unwrap()
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully deleted user.',
        }));
        navigate(-1);
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to delete user.',
        }));
      });
  };

  const onRestoreUser = () => {
    setShowRestoreConfirmDialog(false);
    if (!user.id) return;

    dispatch(restoreDeletedUser(user.id))
      .unwrap()
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully restored user.',
        }));
        navigate(-1);
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to restore user.',
        }));
      });
  };

  const approveUser = () => {
    if (!user || !user.status?.approvalId) return;

    dispatch(approveStudent([
      user.status.approvalId,
    ]))
      .unwrap()
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully approved user.',
        }));

        refreshUserDetail();
        setApprovalStatus(UserApprovalStatus.approved);
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to approve the student.',
        }));
      });
  };

  const rejectUser = () => {
    if (!user || !user.status?.approvalId) return;

    dispatch(rejectStudent([
      user.status.approvalId,
    ]))
      .unwrap()
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully rejected user.',
        }));
        refreshUserDetail();
        setApprovalStatus(UserApprovalStatus.rejected);
      })
      .catch((error) => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to reject the student.',
        }));
      });
  };

  const showApprovalStatusList = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (organizationType !== OrganizationType.MiddleSchool && organizationType !== OrganizationType.HighSchool) {
      return;
    }

    if (!user.status?.emailVerified) {
      return;
    }

    setAnchorElement(e.currentTarget);
  };

  return (
    <div className="edit-user-detail">
      <form onSubmit={handleSubmit(submitForm)}>
        {pageLoaded ? (
          <FormProvider {...methods}>
            <div className="edit-user-detail-header flex_row_jend_acenter">

              {/* Show User Approval Status for student user */}
              {(user.isStudent) && (
                <div className="flex_row_acenter approval-status-wrapper">
                  <div className="approval-status">User Approval Status:</div>
                  <div onMouseUp={(e) => showApprovalStatusList(e)}>
                    <StatusLabel status={approvalStatus} />
                  </div>
                  <Menu className="flex_row_jcenter_acenter"
                    anchorEl={anchorElement}
                    open={Boolean(anchorElement)}
                    onClose={() => setAnchorElement(null)}
                    autoFocus={false}
                    PaperProps={defaultMenuPaperProps}
                  >
                    <MenuItem onClick={() => (setAnchorElement(null), approveUser())}>
                      Approve
                    </MenuItem>
                    <MenuItem onClick={() => (setAnchorElement(null), rejectUser())}>
                      Reject
                    </MenuItem>
                  </Menu>
                </div>
              )}

              {/* Show User Approval Status for Community user */}
              {(user.isCommunity) && (
                <div className="right-side flex_row_jbetween_acenter">
                  <div>User Approval Status:</div>
                  <div className="approval-status-label">
                    <div className="flex_acenter">
                      { (user.status?.emailVerified ) ? (
                        <img src={AppAssetPaths.icons.statusIcons.ACTIVE} alt={UserApprovalStatus.active} />
                      ) : (
                        <img src={AppAssetPaths.icons.statusIcons.UNVERIFIED_EMAIL} alt={UserApprovalStatus.unverified} />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className={`edit-user-detail-body flex_col_jbetween ${userType && userType.replace(/_/g, '-')}-body`}>
              <DropdownSelect
                inputClass="form-input"
                name="userType"
                label="USER TYPE"
                itemList={filteredUserTypeList}
                errorMessage={errors.userType && errors.userType.message}
                onChange={(e) => setUserType(snakeCase(e.label) as UserRole)}
                disabled={
                  currentUser.isCounselor ||
                  (currentUser.isDistrictAdmin && !currentUser.isInstitutionAdmin) ||
                  (currentUser.isCommunity && !currentUser.isInstitutionAdmin)
                }
                disableClearable
                size="small"
                required
              />

              <TextInput
                containerClass="main-input"
                name="firstName"
                label="FIRST NAME"
                errorMessage={errors.firstName?.message}
                type="text"
                size="small"
                required
              />

              <TextInput
                containerClass="main-input"
                name="lastName"
                label="LAST NAME"
                errorMessage={errors.lastName?.message}
                type="text"
                size="small"
                required
              />

              <TextInput
                containerClass="main-input"
                name="email"
                label="EMAIL"
                errorMessage={errors.email?.message}
                type="text"
                size="small"
                required
                disabled={currentUser.isDistrictAdmin || currentUser.isAdultKeyContact || currentUser.isCounselor}
              />

              {userType == UserRole.districtAdmin && (
                <DropdownSelect
                  inputClass="form-input"
                  name="organization"
                  label="DISTRICT"
                  itemList={schoolDistrictList}
                  errorMessage={errors.organization && errors.organization.message}
                  size="small"
                  required
                />
              )}

              {(userType !== UserRole.districtAdmin && userType !== UserRole.admin) && (
                <DropdownSelect
                  inputClass="form-input"
                  name="organizationType"
                  label="ORGANIZATION TYPE"
                  itemList={filteredOrganizationTypeList}
                  errorMessage={errors.organizationType && errors.organizationType.message}
                  size="small"
                  required
                  onChange={(e) => selectOrgType(e)}
                  disabled={currentUser.isKeyContact || currentUser.isCounselor || currentUser.isInstitutionAdmin || currentUser.isDistrictAdmin || userType == UserRole.community || userType == UserRole.institutionAdmin}
                />
              )}

              {(userType == UserRole.community || userType == UserRole.institutionAdmin) && (
                <DropdownSelect
                  inputClass="form-input"
                  name="organization"
                  label="ORGANIZATION"
                  itemList={userType == UserRole.community ? communityList : higherEdOrgList}
                  errorMessage={errors.organization && errors.organization.message}
                  disabled={user.isInstitutionAdmin}
                  size="small"
                  required
                />
              )}

              {(userType == UserRole.student || userType == UserRole.community || userType == UserRole.institutionAdmin) && (
                <TextInput
                  inputClass="form-input"
                  name="phoneNumber"
                  label="PHONE NUMBER"
                  errorMessage={errors.phoneNumber && errors.phoneNumber.message}
                  type="text"
                  size="small"
                  maxLength={12}
                  onKeyPress={(e) => {
                    numberOnlyValidation(e);
                    if (e.keyCode || e.keyCode == 0) {
                      e.target.value = formatPhone(e.target.value);
                    }
                  }}
                />
              )}

              {(userType !== UserRole.districtAdmin && userType !== UserRole.admin && userType !== UserRole.community && userType !== UserRole.institutionAdmin) && (
                <DropdownSelect
                  inputClass="form-input"
                  name="school"
                  label="SCHOOL"
                  itemList={allSchoolsList}
                  errorMessage={errors.school && errors.school.message}
                  disabled={!organizationType || currentUser.isKeyContact || currentUser.isCounselor || currentUser.isInstitutionAdmin || currentUser.isDistrictAdmin}
                  size="small"
                  required
                />
              )}

              {userType == UserRole.student && (
                <>
                  {(organizationType === OrganizationType.MiddleSchool || organizationType === OrganizationType.HighSchool) ? (
                    <>
                      <DropdownSelect
                        inputClass="form-input"
                        name="graduationYear"
                        label={organizationType === OrganizationType.MiddleSchool || organizationType === OrganizationType.HighSchool ? 'GRADUATION YEAR' : 'HIGH SCHOOL GRADUATION YEAR'}
                        itemList={gradYearList}
                        size="small"
                      />

                      <DropdownSelect
                        inputClass="form-input"
                        name="parentEducation"
                        label="PARENTS LEVEL OF EDUCATION"
                        itemList={educationLevels}
                        size="small"
                        disabled={currentUser.isDistrictAdmin || currentUser.isKeyContact || currentUser.isCounselor}
                      />
                    </>
                  ) : (
                    <DropdownSelect
                      inputClass="form-input"
                      name="educationLevel"
                      label="LEVEL OF EDUCATION"
                      itemList={educationLevels}
                      size="small"
                      disabled={currentUser.isDistrictAdmin || currentUser.isKeyContact || currentUser.isCounselor}
                    />
                  )}

                  <DropdownSelect
                    inputClass="form-input"
                    name="gender"
                    label="GENDER"
                    itemList={genders}
                    size="small"
                    disabled={currentUser.isDistrictAdmin || currentUser.isKeyContact || currentUser.isCounselor}
                  />

                  <DropdownSelect
                    inputClass="form-input"
                    name="ethnicities"
                    label="RACE / ETHNICITY"
                    itemList={ethnicities}
                    size="small"
                    disabled={currentUser.isDistrictAdmin || currentUser.isKeyContact || currentUser.isCounselor}
                    errorMessage={errors?.ethnicities?.message}
                    multiple
                    required={!currentUser.isInstitutionAdmin}
                  />

                  {(organizationType === OrganizationType.MiddleSchool || organizationType === OrganizationType.HighSchool) && (
                    <TextInput
                      inputClass="form-input"
                      name="points"
                      label="POINTS"
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 0,
                        },
                      }}
                    />
                  )}
                </>
              )}
            </div>

            {(overview?.isStudent && user.transferRequests?.length && user.transferRequests[0]?.approved) ? (
              <div className="transfer-history">
                <p><strong>School Transfer History</strong></p>
                <div>
                  <span>{user.transferRequests[0].currentSchool}</span>
                  <span className="split-line" />
                  <span>{user.transferRequests[1] ? dayjs(user.transferRequests[1].completedAt).format('MM/DD/YYYY') : dayjs(user.createdAt).format('MM/DD/YYYY')} - {dayjs(user.transferRequests[0].completedAt).format('MM/DD/YYYY')}</span>
                </div>
              </div>
            ) : null}

            <div className="edit-user-detail-footer flex_row_jbetween">
              <div className="left-side">
                {currentUser.isAdmin && (
                  <>
                    {user.deletedAt ? (
                      <Button
                        variant="outlined"
                        onClick={() => setShowRestoreConfirmDialog(true)}
                      >
                        Restore User
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        onClick={() => setShowDeleteConfirmDialog(true)}
                      >
                        Delete User
                      </Button>
                    )}
                  </>
                )}
              </div>
              <div className="right-side flex_row_jend">
                {currentUser.isAdmin && (
                  <Button
                    variant="outlined"
                    onClick={() => setShowChangePasswordDialog(true)}
                  >
                    Change Password
                  </Button>
                )}

                <Button className="overview-summary-edit-btn" variant="outlined" onClick={() => setEditing(false)}>
                  Cancel
                </Button>

                <SubmitButton
                  text="Save"
                  size="large"
                  variant="contained"
                  disableElevation={true}
                />
              </div>
            </div>
          </FormProvider>
        ) : (
          <div className="flex_jcenter_acenter loading-indicator">
            <CircularProgress size="50px" />
          </div>
        )}
      </form>

      <ChangePasswordDialog
        showDialog={showChangePasswordDialog}
        submitForm={changePassword}
        handleClose={() => setShowChangePasswordDialog(false)}
      />

      <UpdatedPasswordSuccessDialog
        showDialog={showUpdatedPasswordSuccessDialog}
        handleClose={() => setShowUpdatedPasswordSuccessDialog(false)}
      />

      <DeleteConfirmationModal
        open={showDeleteConfirmDialog}
        deleteItemText="user"
        onConfirm={onDeleteUser}
        onClose={() => setShowDeleteConfirmDialog(false)}
      />

      <RestoreUserConfirmationModal
        open={showRestoreConfirmDialog}
        onConfirm={onRestoreUser}
        onClose={() => setShowRestoreConfirmDialog(false)}
      />
    </div>
  );
};

export default EditUserDetail;
