import React, {
  useState,
  useEffect
} from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Button,
  Divider
} from '@mui/material';
import {
  FormProvider,
  SubmitHandler,
  useForm
} from 'react-hook-form';

import {
  useAppDispatch, useAppSelector
} from '../../app/hooks';
import { AppRoutes } from '../../app/app.types';

import {
  Organization, OrganizationType, postSecondaryDistrict
} from '../../features/organization/organization.model';
import {
  createNewOrganization, updateOrganization, deleteOrganization, getAllSchoolDistricts, getOrganizationbyId
} from '../../features/organization/organization.thunks';
import { setAlert } from '../../features/alert/alert.slice';
import { userSelectors } from '../../features/user/user.slice';
import { ListMenuItem } from '../../components/forms/form.types';
import { schoolTypeList } from '../../util/dropdown';
import TextInput from '../../components/forms/TextInput';
import DropdownSelect from '../../components/forms/DropdownSelect';
import SubmitButton from '../../components/forms/SubmitButton';
import UploadButton from '../../components/forms/UploadButton';
import DeleteConfirmationModal from '../../components/modal/DeleteConfirmationModal';
import { organizationSelectors } from '../../features/organization/organization.slice';

import './CreateUpdateSchool.scss';


interface CreateUpdateSchoolProps { }

const CreateUpdateSchool: React.FC<CreateUpdateSchoolProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const user = useAppSelector(userSelectors.selectUser);
  const organizationDetails = useAppSelector(organizationSelectors.selectOrganizationDetails);
  const schoolDistrictList = useAppSelector(organizationSelectors.selectSchoolDistricts);


  const [
    showDeleteConfirmDialog,
    setShowDeleteConfirmDialog,
  ] = useState<boolean>(false);
  const [
    filterSchoolTypeList,
    setFilterSchoolTypeList,
  ] = useState<ListMenuItem[]>([]);
  const [
    filteredSchoolDistrictList,
    setFilteredSchoolDistrictList,
  ] = useState<Organization[]>([]);
  const [
    higherEdSelected,
    setHigherEdSelected,
  ] = useState<boolean>(false);
  const [
    editMode,
    setEditMode,
  ] = useState(false);
  const [
    pageLoaded,
    setPageLoaded,
  ] = useState(false);


  useEffect(() => {
    dispatch(getAllSchoolDistricts());

    if (params && params.organizationId) {
      dispatch(getOrganizationbyId(+params.organizationId));
      setEditMode(true);
    }
  }, []);


  useEffect(() => {
    if ((params && params.organizationId && +params.organizationId !== organizationDetails.id) || pageLoaded) return;

    if ((!editMode || organizationDetails) && (schoolDistrictList && !!schoolDistrictList.length)) {
      prepopulateValues();
      setPageLoaded(true);
    }
  }, [
    organizationDetails,
    schoolDistrictList,
  ]);


  const prepopulateValues = async () => {
    setValue('name', editMode ? organizationDetails.name : '');
    setValue('url', editMode ? organizationDetails.url : '');
    setValue('type', editMode ? schoolTypeList.find(st => st.id === organizationDetails.type) : null);
    setValue('schoolDistrict', editMode ? schoolDistrictList.find(sd => sd.id === organizationDetails.schoolDistrict?.id) : null);

    if (editMode) {
      if (organizationDetails.type === OrganizationType.UniversityCollege) {
        setHigherEdSelected(true);
        setFilteredSchoolDistrictList([]);
      } else {
        setFilteredSchoolDistrictList(schoolDistrictList.filter(sd => sd.name !== postSecondaryDistrict));
      }
    } else {
      setFilteredSchoolDistrictList(schoolDistrictList);
    }
  };


  useEffect(() => {
    if (user.isDistrictAdmin && !user.isDistrictInstitutionAdmin) {
      setFilterSchoolTypeList(schoolTypeList.filter(st => st.id !== OrganizationType.UniversityCollege));
    } else {
      setFilterSchoolTypeList(schoolTypeList);
    }
  }, [
    user,
  ]);

  const formValidationSchema = yup.object().shape({
    name: yup.string()
      .required('School name is required')
      .typeError('School name is required'),
    url: yup.string()
      .required('Website url is required')
      .isValidUrl(),
    type: yup.object()
      .required('School type is required')
      .typeError('School type is required'),
    schoolDistrict: yup.object()
      .required('School district is required')
      .typeError('School district is required'),

  }).required();

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const { handleSubmit, setValue, formState: { errors } } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    if (editMode) {
      dispatch(updateOrganization({
        ...values,
        id: Number(params.organizationId),
      }))
        .unwrap()
        .then(() => {
          dispatch(setAlert({
            type: 'success',
            message: 'Successfully updated school.',
          }));

          navigate(AppRoutes.schoolOrganizations.path);
        })
        .catch(() => {
          dispatch(setAlert({
            type: 'error',
            message: 'Unable to update school.',
          }));
        });
    }
    else {
      dispatch(createNewOrganization(values))
        .unwrap()
        .then(() => {
          dispatch(setAlert({
            type: 'success',
            message: 'Successfully created school.',
          }));

          navigate(AppRoutes.schoolOrganizations.path);
        })
        .catch(() => {
          dispatch(setAlert({
            type: 'error',
            message: 'Unable to create new school.',
          }));
        });
    }
  };

  const onDeleteSchool = () => {
    if (!params.organizationId) return;

    setShowDeleteConfirmDialog(false);

    dispatch(deleteOrganization(+params.organizationId))
      .unwrap()
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully deleted school.',
        }));

        navigate(AppRoutes.schoolOrganizations.path);
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to delete school.',
        }));
      });
  };


  const handleSchoolTypeChange = (e: ListMenuItem) => {
    if (e.label === 'Higher Education') {
      setHigherEdSelected(true);
      setValue('schoolDistrict', schoolDistrictList.find(sd => sd.name === postSecondaryDistrict));
      // Field is disabled when Higher ed is selected so I'm emptying the list to save from doing another array method here.
      setFilteredSchoolDistrictList([]);
    }
    else {
      setHigherEdSelected(false);
      setValue('schoolDistrict', null);
      setFilteredSchoolDistrictList(schoolDistrictList.filter(sd => sd.name !== postSecondaryDistrict));
    }
  };

  return (
    <div id="create-update-school-page" className="card-background flex_col">
      <form className="create-form-container" onSubmit={handleSubmit(submitForm)}>
        <FormProvider {...methods}>
          <h3>{editMode ? 'KTS School Details' : 'New KTS School'}</h3>
          <Divider />

          {(pageLoaded) && (
            <>
              <div className="form-content-wrapper">
                <TextInput
                  containerClass="form-input"
                  name="name"
                  label="SCHOOL NAME"
                  errorMessage={errors.name && errors.name.message}
                  type="text"
                  size="small"
                  required
                />

                <TextInput
                  containerClass="form-input"
                  name="url"
                  label="WEBSITE"
                  errorMessage={errors.url && errors.url.message}
                  type="text"
                  size="small"
                  required
                />

                <DropdownSelect
                  containerClass="form-input"
                  name="type"
                  label="SCHOOL TYPE"
                  itemList={filterSchoolTypeList}
                  errorMessage={errors.type && errors.type.message}
                  size="small"
                  onChange={(e) => {
                    handleSchoolTypeChange(e);
                  }}
                  disabled={user.isDistrictInstitutionAdmin}
                  required
                />

                <DropdownSelect
                  containerClass="form-input"
                  name="schoolDistrict"
                  label="DISTRICT"
                  itemList={filteredSchoolDistrictList}
                  errorMessage={errors.schoolDistrict && errors.schoolDistrict.message}
                  size="small"
                  disabled={user.isDistrictInstitutionAdmin || higherEdSelected}
                  required
                />

                {editMode && (
                  <TextInput
                    containerClass="form-input"
                    name="schoolId"
                    label="SCHOOL ID"
                    defaultValue={organizationDetails.id}
                    type="text"
                    size="small"
                    disabled
                  />
                )}

                <div className="field-container">
                  <p className="static-input-label">LOGO</p>
                  <UploadButton
                    text="Upload Logo"
                    name="logo"
                    size="large"
                    disableElevation
                  />
                </div>
              </div>

              <div className="flex_row_jbetween">
                {editMode && (
                  <Button
                    className="form-button delete-button"
                    variant="outlined"
                    onClick={() => setShowDeleteConfirmDialog(true)}
                  >
                    Delete School
                  </Button>
                )}

                <div className={editMode ? 'right-side flex_row_jend' : 'right-side flex_row_jbetween'}>
                  <Button
                    className="form-button cancel-button"
                    variant="outlined"
                    onClick={() => editMode
                      ? navigate(AppRoutes.schoolDetail.path.replace(':organizationId', organizationDetails.id.toString()))
                      : navigate(AppRoutes.schoolOrganizations.path)
                    }>
                    Cancel
                  </Button>

                  <SubmitButton
                    className="form-button"
                    text="Save"
                    variant="contained"
                    size="large"
                    disableElevation
                  />
                </div>
              </div>
            </>
          )}
        </FormProvider>
      </form>

      <DeleteConfirmationModal
        open={showDeleteConfirmDialog}
        deleteItemText="school"
        onConfirm={onDeleteSchool}
        onClose={() => setShowDeleteConfirmDialog(false)}
      />
    </div>
  );
};

export default CreateUpdateSchool;
