import { createAsyncThunk } from '@reduxjs/toolkit';
import * as resourceTagsApi from './resourceTags.api';
import { ResourceTagListQueryParams } from './resourceTags.model';


export const getResourceTagList = createAsyncThunk(
  'resource-tags/getResourceTagList',
  async (query?: ResourceTagListQueryParams) => {
    return await resourceTagsApi.getResourceTagList(query);
  }
);


export const getResourceTagById = createAsyncThunk(
  'resource-tags/getResourceTagById',
  async (id: number) => {
    return await resourceTagsApi.getResourceTagById(id);
  }
);


export const createResourceTag = createAsyncThunk(
  'resource-tags/createResourceTag',
  async (body: { name: string, description: string }) => {
    return await resourceTagsApi.createResourceTag(body);
  }
);


export const updateResourceTag = createAsyncThunk(
  'resource-tags/updateResourceTag',
  async (data: {
    id: number,
    body: { name: string, description: string }
  }) => {
    return await resourceTagsApi.updateResourceTag(data.id, data.body);
  }
);


export const deleteResourceTag = createAsyncThunk(
  'resource-tags/deleteResourceTag',
  async (id: number) => {
    return await resourceTagsApi.deleteResourceTag(id);
  }
);