/* eslint-disable @typescript-eslint/no-explicit-any */

import { Organization } from '../organization/organization.model';



// Place holder stuff just so the linter doesn't break the app due to an empty file
let globalId = 0;

export interface BulkCreateModel {
  id: 0,
}

export interface BulkUser extends BulkCreateModel {
  firstName: string,
  lastName: string,
  email: string,
  school: string,
  grade: number | string,
}

// Step 3
export interface ExisitingUser extends BulkUser {
  userId: number,
}

// Step 4
export interface NewUsersQuery {
  bulkUploadId: number,
  userId: number
}

// Step 5
export interface AccountsCreated {
  count: number;
  code: string;
  expirationDate: string;
}

// Step 3
export const ConstructExisitingUser = (data: any): ExisitingUser => {
  return {
    id: data.bulk_id ? data.bulk_id : globalId++,
    userId: data.user_id,
    email: data.email,
    firstName: data.first_name,
    lastName: data.last_name,
    school: data.school,
    grade: data.grade,
  };
};

// Step 4
export const ConstructNewUser = (data: any): BulkUser => {
  return {
    id: data.bulk_id ? data.bulk_id : globalId++,
    email: data.email,
    firstName: data.first_name,
    lastName: data.last_name,
    school: data.school,
    grade: data.grade,
  };
};

export const ConstructBulkUser = (data: any, selectedSchool: Organization): BulkUser => {
  return {
    id: data.bulk_id ? data.bulk_id : globalId++,
    email: data.email,
    firstName: data.first_name,
    lastName: data.last_name,
    school: data.school ? data.school : selectedSchool.name,
    grade: data.grade,
  };
};



// Step 5
export const EmptyAccountCreated: AccountsCreated = {
  count: 0,
  code: '',
  expirationDate: '',
};