import { createAsyncThunk } from '@reduxjs/toolkit';
import * as careersApi from './careers.api';
import { CareerClusterListQuery } from './careers.model';


export const getCareerGroups = createAsyncThunk(
  'careers/getCareerGroups',
  async (query?: CareerClusterListQuery) => {
    return await careersApi.getCareerGroups(query);
  }
);

export const getCareerClusters = createAsyncThunk(
  'careers/getCareerClusters',
  async () => {
    return await careersApi.getCareerClusters();
  }
);

export const getCareerGroupById = createAsyncThunk(
  'careers/getCareerGroupById',
  async (id: number) => {
    return await careersApi.getCareerGroupById(id);
  }
);

export const updateCareerGroupSkills = createAsyncThunk(
  'careers/updateCareerGroupSkills',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (data: { skills: any[], careerId: number }) => {
    return await careersApi.updateCareerGroupSkills(data);
  }
);

export const removeAllCareerGroupSkills = createAsyncThunk(
  'careers/removeAllCareerGroupSkills',
  async (id: number) => {
    return await careersApi.removeAllCareerGroupSkills(id);
  }
);
