import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  NewCreateUser,
  SignupUser,
  UserListQueryParams
} from './user.model';
import * as userApi from './user.api';


export const signUp = createAsyncThunk(
  'user/signup',
  async (data: SignupUser) => {
    await userApi.signUp(data);
    return await userApi.getUser();
  }
);

export const createNewUser = createAsyncThunk(
  'users/new',
  async (data: NewCreateUser) => {
    return await userApi.createNewUser(data);
  }
);

export const getUser = createAsyncThunk(
  'user/getUser',
  async () => {
    return await userApi.getUser();
  }
);

export const getUserList = createAsyncThunk(
  'user/getUserList',
  async (query: UserListQueryParams) => {
    return await userApi.getUserList(query);
  }
);

export const getEducationLevels = createAsyncThunk(
  'user/educationLevels',
  async () => {
    return await userApi.getEducationLevels();
  }
);

export const getGenders = createAsyncThunk(
  'user/genders',
  async () => {
    return await userApi.getGenders();
  }
);

export const getEthnicities = createAsyncThunk(
  'user/ethnicities',
  async () => {
    return await userApi.getEthnicities();
  }
);

export const getHeardAboutKtsReasons = createAsyncThunk(
  'user/getHeardAboutKtsReasons',
  async () => {
    return await userApi.getHeardAboutKtsReasons();
  }
);

export const getGraduationYears = createAsyncThunk(
  'user/getGraduationYears',
  async () => {
    return await userApi.getGraduationYears();
  }
);

export const updateUserInfo = createAsyncThunk(
  'user/update',
  async (data: Partial<NewCreateUser>) => {
    return await userApi.updateUserInfo(data);
  }
);

export const deleteUser = createAsyncThunk(
  'user/deleteUser',
  async (userId: number) => {
    return await userApi.deleteUser(userId);
  }
);

export const restoreDeletedUser = createAsyncThunk(
  'user/restoreDeletedUser',
  async (userId: number) => {
    return await userApi.restoreDeletedUser(userId);
  }
);

export const getUserTypeList = createAsyncThunk(
  'user/getUserTypeList',
  async (includeAdminTypes?: boolean) => {
    return await userApi.getUserTypeList(includeAdminTypes);
  }
);

export const approveStudent = createAsyncThunk(
  'user/approveStudent',
  async (ids: number[]) => {
    return await userApi.approveStudent(ids);
  }
);

export const rejectStudent = createAsyncThunk(
  'user/rejectStudent',
  async (ids: number[]) => {
    return await userApi.rejectStudent(ids);
  }
);

export const approveTransfer = createAsyncThunk(
  'user/approveTransfer',
  async (ids: number[]) => {
    return await userApi.approveTransfer(ids);
  }
);

export const rejectTransfer = createAsyncThunk(
  'user/rejectTransfer',
  async (ids: number[]) => {
    return await userApi.rejectTransfer(ids);
  }
);

export const generateConfirmationCode = createAsyncThunk(
  'user/generateConfirmationCode',
  async (userId: number) => {
    return await userApi.generateConfirmationCode(userId);
  }
);