/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  TextField,
  Tooltip
} from '@mui/material';
import React, { ReactNode } from 'react';
import {
  Controller, DeepRequired, FieldError, FieldErrorsImpl, Merge, useFormContext
} from 'react-hook-form';
import { AppAssetPaths } from '../../app/app.types';
import { ListMenuItem } from './form.types';


export interface DropdownSelectProps {
  containerClass?: string;
  inputClass?: string;
  name: `${string}` | `${string}.${string}` | `${string}.${number}`;
  label?: string | undefined;
  itemList: ListMenuItem[];
  errorMessage?: string | FieldError | Merge<FieldError, FieldErrorsImpl<DeepRequired<any>>>;
  size?: 'small' | 'medium';
  multiple?: boolean;
  selectAll?: boolean;
  defaultValue?: ListMenuItem[] | ListMenuItem | string | null;
  disabled?: boolean;
  disableClearable?: boolean;
  colorVariant?: string;
  required?: boolean;
  tooltip?: string;
  fieldVariant?: 'filled' | 'standard' | 'outlined' | undefined;
  placeholder?: string;
  tooltipClick?: () => void,
  onChange?: (e: any) => void;
}

const DropdownSelect: React.FC<DropdownSelectProps> = ({
  containerClass,
  inputClass,
  name,
  label,
  itemList,
  errorMessage,
  size,
  multiple,
  selectAll,
  defaultValue,
  disabled,
  disableClearable,
  colorVariant,
  required,
  tooltip,
  fieldVariant,
  placeholder,
  tooltipClick,
  onChange,
}) => {

  const selectAllOption: ListMenuItem = {
    id: 0,
    label: 'Select All',
  };

  const { control } = useFormContext();

  const handleDefaultValue = () => {
    if (defaultValue == null) {
      return null;
    }
    (defaultValue && (typeof defaultValue !== 'string') && !!(defaultValue as ListMenuItem[]).length) && (defaultValue = (defaultValue as ListMenuItem[]).filter(e => e !== undefined));

    if (multiple && defaultValue && !!(defaultValue as string)?.length) {
      return [
        ...(defaultValue as ListMenuItem[]),
      ];
    }
    else if (multiple && defaultValue && !(defaultValue as string).length) {
      return [
        defaultValue,
      ];
    }
    else if (!multiple && defaultValue && !(defaultValue as string).length) {
      return defaultValue;
    }
    else if (multiple && !defaultValue) {
      return [
        '',
      ];
    }
    else if (!multiple && defaultValue) {
      return defaultValue;
    }
    else return null;
  };

  return (
    <div className={`field-container ${containerClass}`}>
      {label && (
        <div className="flex_acenter">
          <p className={`static-input-label ${required && 'required-field'} ${disabled && 'disabled-label'}`}> {label} </p>
          {tooltip && (
            <Tooltip title={tooltip}
              onClick={() => tooltipClick && tooltipClick()}
              arrow
              placement="right">
              <img className="info-icon" src={AppAssetPaths.icons.INFO} alt="info" />
            </Tooltip>
          )}
        </div>
      )}

      <FormControl className="form-input" error={!!errorMessage || false}>
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <Autocomplete className={`${inputClass} ${colorVariant} ${disabled ? 'disabled-input' : ''}`}
              {...field}
              size={size}
              value={field.value || handleDefaultValue() || (multiple ? [] : (defaultValue || null))}

              options={multiple && selectAll ? [
                selectAllOption,
                ...itemList,
              ] : itemList}

              getOptionLabel={(option) => {
                // Because of all the different things that the dropdown select can use I've found that adding these checks makes them work for what you pass in.
                // Otherwise it'll just use its default and potentially break
                if (option?.label) {
                  return option.label;
                }
                else if (option?.name) {
                  return option.name;
                }
                else if (typeof option === 'string') {
                  return option;
                }
                else if ((typeof option === 'object') || (option === undefined)) {
                  return null;
                }
              }}

              renderInput={(params) => <TextField {...params} variant={fieldVariant} placeholder={placeholder} />}
              renderOption={(props, option) =>
                <li {...props} key={option.id} className={`${props.className} ${colorVariant}`}>{option.label} </li>
              }
              isOptionEqualToValue={(option, value) => {
                if (option === undefined || value === undefined) {
                  return false;
                }
                if (option.id === value.id) {
                  return option.id === value.id;
                }
                else if (option.label === value.label) {
                  return option.label === value.label;
                }
                else if (option.id === value) {
                  return option.id === value;
                }
                else if (option.label === value) {
                  return option.label === value;
                }

                return false;
              }}
              onChange={(_, data) => {
                if (multiple && data && ('includes' in data) && data.includes(selectAllOption)) {
                  data.length > itemList.length ? field.onChange([]) : field.onChange(itemList);
                }
                else {
                  field.onChange(data);
                }
                onChange && onChange(data);
              }}
              disabled={disabled}
              multiple={multiple}
              limitTags={2}
              disableCloseOnSelect={multiple}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              disableClearable={disableClearable}
            />
          )}
        />
        <FormHelperText>
          {errorMessage as ReactNode}
        </FormHelperText>
      </FormControl>
    </div>
  );
};

export default DropdownSelect;
