import React, {
  useEffect, useState
} from 'react';

import { Button } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  useParams, useNavigate
} from 'react-router-dom';
import { startCase } from 'lodash';

import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import {
  AppRoutes, canUseAuthComponent
} from '../../../app/app.types';
import { notificationSelectors } from '../../../features/notifications/notifications.slice';
import {
  getNotificationById,
  approveNotification,
  rejectNotification
} from '../../../features/notifications/notifications.thunks';
import { userSelectors } from '../../../features/user/user.slice';
import {
  getEducationLevels,
  getEthnicities,
  getGenders, getUserTypeList
} from '../../../features/user/user.thunks';
import { organizationSelectors } from '../../../features/organization/organization.slice';
import {
  Organization, OrganizationType
} from '../../../features/organization/organization.model';
import {
  getAllSchoolDistricts, getAllSchools
} from '../../../features/organization/organization.thunks';
import { setAlert } from '../../../features/alert/alert.slice';

import { ListMenuItem } from '../../../components/forms/form.types';
import ApproveRejectCard from '../../../components/details/ApproveRejectCard';

import './NotificationDetails.scss';

interface NotificationDetailsProps { }

const NotificationDetails: React.FC<NotificationDetailsProps> = () => {

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector(userSelectors.selectUser);
  const userTypeList = useAppSelector(userSelectors.selectUserTypeList);
  const genders = useAppSelector(userSelectors.selectGenders);
  const gendersLoaded = useAppSelector(userSelectors.selectGendersLoaded);
  const ethnicities = useAppSelector(userSelectors.selectEthnicities);
  const ethnicitiesLoaded = useAppSelector(userSelectors.selectEthnicitiesLoaded);
  const educationLevels = useAppSelector(userSelectors.selectEducationLevels);
  const educationLevelsLoaded = useAppSelector(userSelectors.selectEducationLevelsLoaded);
  const schoolDistricts = useAppSelector(organizationSelectors.selectSchoolDistricts);
  const schools = useAppSelector(organizationSelectors.selectAllSchools);
  const notification = useAppSelector(notificationSelectors.selectNotification);

  useEffect(() => {
    if (!params || !params.notificationId) return;
    const id = parseInt(params.notificationId);

    dispatch(getNotificationById(id));
  }, []);

  useEffect(() => {
    dispatch(getUserTypeList());

    !gendersLoaded && dispatch(getGenders());
    !ethnicitiesLoaded && dispatch(getEthnicities());
    !educationLevelsLoaded && dispatch(getEducationLevels());

    if (!schoolDistricts || schoolDistricts.length === 0) {
      dispatch(getAllSchoolDistricts());
    }

    if (!schools || schools.length === 0) {
      dispatch(getAllSchools());
    }
  }, []);

  const [
    viewAllDistrict,
    setViewAllDistrict,
  ] = useState(false);

  const [
    viewAllSchool,
    setViewAllSchool,
  ] = useState(false);

  const notificationUserTypeNames = () => {
    if (!notification || !notification.userTypeIds) {
      return '';
    }

    const userTypeNameList = notification.userTypeIds.map((userTypeId: number) => {
      const userType = userTypeList.find(item => item.id === userTypeId);

      return userType && userType.label || '';
    }).filter((userTypeName: string) => !!userTypeName);

    return userTypeNameList.join(', ');
  };

  const notificationGenderNames = () => {
    if (!notification || !notification.genderIds) {
      return '';
    }

    const genderNameList = notification.genderIds.map((genderId: number) => {
      const gender = genders.find((item: ListMenuItem) => item.id === genderId);

      return gender && gender.label || '';
    }).filter((genderName: string) => !!genderName);

    return genderNameList.join(', ');
  };

  const notificationEthnicities = () => {
    if (!notification || !notification.ethnicityIds) return '';

    const ethnicityList = notification.ethnicityIds.map((ethId: number) => {
      const ethnic = ethnicities.find((item: ListMenuItem) => item.id === ethId);
      return ethnic && ethnic.label || '';
    }).filter((ethName: string) => !!ethName);

    return ethnicityList.join(', ');
  };

  const notificationParentEducationLevels = () => {
    if (!notification || !notification.parentEducationLevelIds) return '';

    const parentEduList = notification.parentEducationLevelIds.map((parentEduId: number) => {
      const parentEdu = educationLevels.find((item: ListMenuItem) => item.id === parentEduId);
      return parentEdu && parentEdu.label || '';
    }).filter((eduName: string) => !!eduName);

    return parentEduList.join(', ');
  };

  const notificationSchoolDistrictNames = () => {
    if (!notification || !notification.schoolDistrictIds) {
      return '';
    }

    const schoolDistrictNameList = notification.schoolDistrictIds.map((schoolDistrictId: number) => {
      const schoolDistrict = schoolDistricts.find((item: Organization) => item.id === schoolDistrictId);

      return schoolDistrict && schoolDistrict.label || '';
    }).filter((schoolDistrictName: string) => !!schoolDistrictName);

    return schoolDistrictNameList.join(', ');
  };

  const notificationSchoolNames = () => {
    if (!notification || !notification.organizationIds) {
      return '';
    }

    const schoolNameList = notification.organizationIds.map((organizationId: number) => {
      const school = schools.find((item: Organization) => item.id === organizationId);

      return school && school.label || '';
    }).filter((schoolName: string) => !!schoolName);

    return schoolNameList.join(', ');
  };

  const approvalStatusWrapper = () => {
    if (!notification.id || notification.approved) {
      return;
    }

    return user.isAdmin ? (
      <ApproveRejectCard
        onApprove={() => onApproveNotification()}
        onReject={() => onRejectNotification()}
      />
    ) : (
      <div className="approval-actions-wrapper flex_row_acenter">
        <span>Awaiting Approval</span>
      </div>
    );
  };

  const onApproveNotification = () => {
    if (!notification.id) return;

    dispatch(approveNotification(notification.id))
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully approved the notification.',
        }));
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to approve the notification.',
        }));
      });
  };

  const onRejectNotification = () => {
    if (!notification.id) return;

    dispatch(rejectNotification(notification.id))
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully rejected the notification.',
        }));

        navigate(-1);
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to reject the notification.',
        }));
      });
  };

  return (
    <div className="card-background notification-details">
      <div className="details-header flex_row_jbetween_acenter">
        <div className="flex_row back_arrow" onClick={() => navigate(-1)}>
          <ChevronLeftIcon className="carousel-icon" />
          <h3>Notification Details</h3>
        </div>

        <div className="flex_row_jend_acenter">
          {notification && !notification.approved && user.isAdmin ? (
            approvalStatusWrapper()
          ) : (!notification.approved && !user.isAdmin) && (
            <span className="awaiting-approval">
              <p> Awaiting Approval </p>
            </span>
          )}

          {(!notification.isPush &&
            (!notification.approved &&
              canUseAuthComponent(user.roles, AppRoutes.editNotification.authorizedRoles) || user.isAdmin)) && (
            <Button
              variant="outlined"
              onClick={() => notification.id && navigate(AppRoutes.editNotification.path.replace(':notificationId', notification.id.toString()))}
            >
                Edit
            </Button>
          )}
        </div>
      </div>

      <div className="main-content flex_column">
        <div className="flex_row_jbetween_acenter">
          <h3>{notification.title}</h3>
        </div>

        <div className="detail-field-group flex_row_astart">
          <p>Message</p>
          <p>{notification.body}</p>
        </div>

        <div className="detail-field-group flex_row_start">
          <p>Notification Type</p>
          <p>{notification.isPush ? 'Push Notification' : 'In-App Notification'}</p>
        </div>

        <div className="other-details flex_row_jbetween">
          <div className="left-column">
            <div className="detail-field-group flex_row_astart">
              <p>User Type</p>
              <p>{notificationUserTypeNames()}</p>
            </div>

            <div className="detail-field-group flex_row_astart">
              <p>Ethnicity</p>
              <p>{notificationEthnicities()}</p>
            </div>

            <div className="detail-field-group flex_row_astart">
              <p>Gender</p>
              <p>{notificationGenderNames()}</p>
            </div>

            <div className="detail-field-group flex_row_astart">
              <p>District</p>
              <p>
                {((notificationSchoolDistrictNames().split(',').length) <= 20)
                  ? notificationSchoolDistrictNames()
                  :
                  (viewAllDistrict)
                    ? notificationSchoolDistrictNames()
                    : notificationSchoolDistrictNames().split(',').slice(0, 19).join(',')
                }
                {((notificationSchoolNames().split(',').length) > 20) && (
                  <div className="flex_jcenter">
                    <Button className="center-view-all-button" variant="text"
                      onClick={() => setViewAllDistrict(!viewAllDistrict)}>
                      {viewAllDistrict ? 'View Less' : 'View All'}
                    </Button>
                  </div>)
                }
              </p>
            </div>
          </div>

          <div className="right-column">
            <div className="detail-field-group flex_row_astart">
              <p>Organization Type</p>
              <p>{notification?.organizationTypes ? notification.organizationTypes.map((organizationType: OrganizationType) => startCase(organizationType)).join(', ') : '-'}</p>
            </div>

            <div className="detail-field-group flex_row_astart">
              <p>Parent Level Of Education</p>
              <p>{notificationParentEducationLevels()}</p>
            </div>

            <div className="detail-field-group flex_row_astart">
              <p>Careers</p>
              <p>{notification?.careerGroups ? notification.careerGroups.map((item: ListMenuItem) => item.label).join(', ') : '-'}</p>
            </div>

            <div className="detail-field-group flex_row_astart">
              <p>School</p>
              <p>
                {((notificationSchoolNames().split(',').length) <= 20)
                  ? notificationSchoolNames()
                  :
                  (viewAllSchool)
                    ? notificationSchoolNames()
                    : notificationSchoolNames().split(',').slice(0, 19).join(',')
                }
                {((notificationSchoolNames().split(',').length) > 20) && (
                  <div className="flex_jcenter">
                    <Button className="center-view-all-button" variant="text"
                      onClick={() => setViewAllSchool(!viewAllSchool)}>
                      {viewAllSchool ? 'View Less' : 'View All'}
                    </Button>
                  </div>)
                }
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationDetails;
