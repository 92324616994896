import React, { useEffect } from 'react';
import {
  useParams, useNavigate
} from 'react-router-dom';
import { Button } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import dayjs from 'dayjs';
import { startCase } from 'lodash';

import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import {
  AppAssetPaths, AppRoutes
} from '../../../app/app.types';
import { masterKeySelectors } from '../../../features/masterKey/masterKey.slice';
import { getMasterKeySubmissionById } from '../../../features/masterKey/masterKey.thunks';
import { submissionPointsAward } from '../../../features/masterKey/masterKey.api';
import { setAlert } from '../../../features/alert/alert.slice';
import { getUserSchoolType } from '../../../features/user/user.model';

import './MasterKeySubmissionDetail.scss';

interface MasterKeySubmissionDetailsProps { }

const MasterKeySubmissionDetails: React.FC<MasterKeySubmissionDetailsProps> = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const masterKeySubmission = useAppSelector(masterKeySelectors.selectMasterKeySubmission);

  useEffect(() => {
    if (!params || !params.masterKeySubmissionId) return;
    const id = parseInt(params.masterKeySubmissionId);

    dispatch(getMasterKeySubmissionById(id));
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const generateQuestionElements = (questions: any[]) => {
    return (
      <>
        {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          questions.map((question: any, index: number) => {
            const imgSource = question.multi
              ? AppAssetPaths.icons.MULTIPLE_CHOICE
              : AppAssetPaths.icons.WRITTEN_RESPONSE;

            return (
              <div className="question-wrapper" key={index}>
                <div className="detail-field-group flex_row_astart">
                  <p>Question {index + 1}</p>
                  <p>{question.question}</p>
                  <img src={imgSource} alt="" />
                </div>

                <div className="detail-field-group flex_row_astart">
                  <p>Answer</p>
                  <p className="answer">{question.user_answers ? question.user_answers.join(', ') : question.user_answer}</p>
                </div>
              </div>
            );
          })
        }
      </>
    );
  };

  const getUserGrade = (graduationYear: number) => {
    const thisYear = dayjs().get('year');
    const gradeToGraduateThisYear = 12;
    const grade = (-graduationYear + thisYear) + gradeToGraduateThisYear;
    return grade;
  };

  const awardStatus = () => {
    if (!masterKeySubmission?.id || masterKeySubmission.pointsAwarded === null) {
      return;
    }

    if (masterKeySubmission.pointsAwarded) {
      return (<img src={AppAssetPaths.icons.statusIcons.SUBMISSION_POINTS_REWARDED} alt="submission points awarded" />);
    } else {
      return (<img src={AppAssetPaths.icons.statusIcons.SUBMISSION_POINTS_DENIED} alt="submission points denied" />);
    }
  };

  const rewardPoints = (status: boolean) => {
    if (!masterKeySubmission || !masterKeySubmission.id) return;

    submissionPointsAward(masterKeySubmission?.id, status)
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: `${status ? 'Reward' : 'Deny'} the points successfully!`,
        }));
        masterKeySubmission.id && dispatch(getMasterKeySubmissionById(masterKeySubmission?.id));
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to award the points.',
        }));
      });
  };

  return (
    <div className="card-background master-key-submission-details">
      <div className="details-header flex_row_jbetween_acenter">
        <div className="flex_row">
          <div className="back_arrow"
            onClick={() => navigate(AppRoutes.masterKeySubmissions.path.replace(':masterKeyId', masterKeySubmission.masterKeyId!.toString()), {
              state: {
                title: masterKeySubmission.masterKeyTitle,
              },
            })}>
            <ChevronLeftIcon className="carousel-icon" />
          </div>
          <h3>Master Key Submission</h3>
        </div>

        {awardStatus()}
      </div>

      <div className="main-content flex_col">
        <div className="flex_row">
          <div className="left-column flex_col">
            <div className="detail-field-group flex_row_astart">
              <p>Name</p>
              <p>{masterKeySubmission?.user?.fullname || '-'}</p>
            </div>

            <div className="detail-field-group flex_row_astart">
              <p>User Type</p>
              <p>{startCase(getUserSchoolType(masterKeySubmission?.user?.roles || []))}</p>
            </div>

            <div className="detail-field-group flex_row_astart">
              <p>Grade</p>
              <p>{masterKeySubmission?.user?.graduationYear && getUserGrade(masterKeySubmission.user.graduationYear) || '-'}</p>
            </div>
          </div>

          <div className="right-column flex_col">
            <div className="detail-field-group flex_row_astart">
              <p>Submission Date</p>
              <p>{masterKeySubmission?.createdAt && dayjs(masterKeySubmission.createdAt).format('DD/MM/YYYY') || '-'}</p>
            </div>

            <div className="detail-field-group flex_row_astart">
              <p>Points</p>
              <p>{masterKeySubmission?.points || '-'}</p>
            </div>
          </div>
        </div>

        <div className="question-content-wrapper">
          {masterKeySubmission?.questions && generateQuestionElements(masterKeySubmission.questions)}
        </div>
      </div>
      {
        masterKeySubmission?.id && masterKeySubmission.pointsAwarded === null && (
          <div className="buttons-wrapper flex_col_aend">
            <div className="flex_row">
              <Button
                variant="outlined"
                onClick={() => rewardPoints(false)}
              >
                Deny Points
              </Button>

              <Button
                variant="contained"
                onClick={() => rewardPoints(true)}
              >
                Reward Points
              </Button>
            </div>
            <div className="comments">
              Once you reward or deny, this action cannot be changed.
            </div>
          </div>
        )
      }
    </div>
  );
};

export default MasterKeySubmissionDetails;
