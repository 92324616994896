import { createSlice } from '@reduxjs/toolkit';
import { StateStatus } from '../../app/app.types';
import { RootState } from '../../app/store';
import {
  approveScholarshipApplication, rejectScholarshipApplication
} from '../scholarship/scholarship.thunks';
import {
  approveStudent, approveTransfer, rejectStudent, rejectTransfer
} from '../user/user.thunks';
import {
  NotificationApproval, StudentApproval, TransferApproval, ResourceApproval, SkillTrainingApproval, ApplicationApproval
} from './approvals.model';
import {
  getStudentApprovalList, getTransferApprovalList, getNotificationApprovalList, getResourceApprovalList, getSkillTrainingApprovalList, getApplicationApprovalList
} from './approvals.thunks';


/* State */

export interface ApprovalState {
  studentApprovals: {
    status: StateStatus;
    data: StudentApproval[];
    count: number;
  },
  transferApprovals: {
    status: StateStatus;
    data: TransferApproval[];
    count: number;
  },
  notificationApprovals: {
    status: StateStatus;
    data: NotificationApproval[];
    count: number;
  },
  resourceApprovals: {
    status: StateStatus;
    data: ResourceApproval[];
    count: number;
  },
  applicationApprovals: {
    status: StateStatus;
    data: ApplicationApproval[];
    count: number;
  },
  skillTrainingApprovals: {
    status: StateStatus;
    data: SkillTrainingApproval[];
    count: number;
  }
}

const initialState: ApprovalState = {
  studentApprovals: {
    status: StateStatus.IDLE,
    data: [],
    count: 0,
  },
  transferApprovals: {
    status: StateStatus.IDLE,
    data: [],
    count: 0,
  },
  notificationApprovals: {
    status: StateStatus.IDLE,
    data: [],
    count: 0,
  },
  resourceApprovals: {
    status: StateStatus.IDLE,
    data: [],
    count: 0,
  },
  applicationApprovals: {
    status: StateStatus.IDLE,
    data: [],
    count: 0,
  },
  skillTrainingApprovals: {
    status: StateStatus.IDLE,
    data: [],
    count: 0,
  },
};


/* Reducer */

export const approvalSlice = createSlice({
  name: 'approval',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      // Student Approvals list
      .addCase(getStudentApprovalList.pending, (state) => {
        state.studentApprovals.status = StateStatus.LOADING;
      })
      .addCase(getStudentApprovalList.fulfilled, (state, action) => {
        state.studentApprovals.status = StateStatus.IDLE;
        state.studentApprovals.data = action.payload.approvals;
        state.studentApprovals.count = action.payload.count;
      })
      .addCase(getStudentApprovalList.rejected, (state) => {
        state.studentApprovals.status = StateStatus.FAILED;
      })
      .addCase(approveStudent.pending, (state) => {
        state.studentApprovals.status = StateStatus.LOADING;
      })
      .addCase(approveStudent.fulfilled, (state, action) => {
        state.studentApprovals.status = StateStatus.IDLE;
        state.studentApprovals.data = state.studentApprovals.data.filter(a => !action.payload.includes(a.id));
        state.studentApprovals.count -= action.payload.length;
      })
      .addCase(approveStudent.rejected, (state) => {
        state.studentApprovals.status = StateStatus.FAILED;
      })
      .addCase(rejectStudent.pending, (state) => {
        state.studentApprovals.status = StateStatus.LOADING;
      })
      .addCase(rejectStudent.fulfilled, (state, action) => {
        state.studentApprovals.status = StateStatus.IDLE;
        state.studentApprovals.data = state.studentApprovals.data.filter(a => !action.payload.includes(a.id));
        state.studentApprovals.count -= action.payload.length;
      })
      .addCase(rejectStudent.rejected, (state) => {
        state.studentApprovals.status = StateStatus.FAILED;
      })

      // Transfer Approvals list
      .addCase(getTransferApprovalList.pending, (state) => {
        state.transferApprovals.status = StateStatus.LOADING;
      })
      .addCase(getTransferApprovalList.fulfilled, (state, action) => {
        state.transferApprovals.status = StateStatus.IDLE;
        state.transferApprovals.data = action.payload.approvals;
        state.transferApprovals.count = action.payload.count;
      })
      .addCase(getTransferApprovalList.rejected, (state) => {
        state.transferApprovals.status = StateStatus.FAILED;
      })
      .addCase(approveTransfer.pending, (state) => {
        state.transferApprovals.status = StateStatus.LOADING;
      })
      .addCase(approveTransfer.fulfilled, (state, action) => {
        state.transferApprovals.status = StateStatus.IDLE;
        state.transferApprovals.data = state.transferApprovals.data.filter(a => !action.payload.includes(a.id));
        state.transferApprovals.count -= action.payload.length;
      })
      .addCase(approveTransfer.rejected, (state) => {
        state.transferApprovals.status = StateStatus.FAILED;
      })
      .addCase(rejectTransfer.pending, (state) => {
        state.transferApprovals.status = StateStatus.LOADING;
      })
      .addCase(rejectTransfer.fulfilled, (state, action) => {
        state.transferApprovals.status = StateStatus.IDLE;
        state.transferApprovals.data = state.transferApprovals.data.filter(a => !action.payload.includes(a.id));
        state.transferApprovals.count -= action.payload.length;
      })
      .addCase(rejectTransfer.rejected, (state) => {
        state.transferApprovals.status = StateStatus.FAILED;
      })

      // Notifications Approvals list
      .addCase(getNotificationApprovalList.pending, (state) => {
        state.notificationApprovals.status = StateStatus.LOADING;
      })
      .addCase(getNotificationApprovalList.fulfilled, (state, action) => {
        state.notificationApprovals.status = StateStatus.IDLE;
        state.notificationApprovals.data = action.payload.approvals;
        state.notificationApprovals.count = action.payload.count;
      })
      .addCase(getNotificationApprovalList.rejected, (state) => {
        state.notificationApprovals.status = StateStatus.FAILED;
      })

      // Skill Training Approvals list
      .addCase(getSkillTrainingApprovalList.pending, (state) => {
        state.skillTrainingApprovals.status = StateStatus.LOADING;
      })
      .addCase(getSkillTrainingApprovalList.fulfilled, (state, action) => {
        state.skillTrainingApprovals.status = StateStatus.IDLE;
        state.skillTrainingApprovals.data = action.payload.approvals;
        state.skillTrainingApprovals.count = action.payload.count;
      })
      .addCase(getSkillTrainingApprovalList.rejected, (state) => {
        state.skillTrainingApprovals.status = StateStatus.FAILED;
      })

      // Resource Approvals list
      .addCase(getResourceApprovalList.pending, (state) => {
        state.resourceApprovals.status = StateStatus.LOADING;
      })
      .addCase(getResourceApprovalList.fulfilled, (state, action) => {
        state.resourceApprovals.status = StateStatus.IDLE;
        state.resourceApprovals.data = action.payload.approvals;
        state.resourceApprovals.count = action.payload.count;
      })
      .addCase(getResourceApprovalList.rejected, (state) => {
        state.resourceApprovals.status = StateStatus.FAILED;
      })

      // Application Approvals list
      .addCase(getApplicationApprovalList.pending, (state) => {
        state.applicationApprovals.status = StateStatus.LOADING;
      })
      .addCase(getApplicationApprovalList.fulfilled, (state, action) => {
        state.applicationApprovals.status = StateStatus.IDLE;
        state.applicationApprovals.data = action.payload.approvals;
        state.applicationApprovals.count = action.payload.count;
      })
      .addCase(getApplicationApprovalList.rejected, (state) => {
        state.applicationApprovals.status = StateStatus.FAILED;
      })
      .addCase(approveScholarshipApplication.pending, (state) => {
        state.applicationApprovals.status = StateStatus.LOADING;
      })
      .addCase(approveScholarshipApplication.fulfilled, (state, action) => {
        state.applicationApprovals.status = StateStatus.IDLE;
        state.applicationApprovals.data = state.applicationApprovals.data.filter(a => !action.payload.includes(a.id));
        state.applicationApprovals.count -= action.payload.length;
      })
      .addCase(approveScholarshipApplication.rejected, (state) => {
        state.applicationApprovals.status = StateStatus.FAILED;
      })
      .addCase(rejectScholarshipApplication.pending, (state) => {
        state.applicationApprovals.status = StateStatus.LOADING;
      })
      .addCase(rejectScholarshipApplication.fulfilled, (state, action) => {
        state.applicationApprovals.status = StateStatus.IDLE;
        state.applicationApprovals.data = state.applicationApprovals.data.filter(a => !action.payload.includes(a.id));
        state.applicationApprovals.count -= action.payload.length;
      })
      .addCase(rejectScholarshipApplication.rejected, (state) => {
        state.applicationApprovals.status = StateStatus.FAILED;
      });
  },
});


/* Selectors */

// Student Approvals list
const selectStudentApprovalList = (state: RootState): StudentApproval[] => state.approval.studentApprovals.data;
const selectStudentApprovalListStatus = (state: RootState): StateStatus => state.approval.studentApprovals.status;
const selectStudentApprovalListCount = (state: RootState): number => state.approval.studentApprovals.count;

// Transfer Approvals list
const selectTransferApprovalList = (state: RootState): TransferApproval[] => state.approval.transferApprovals.data;
const selectTransferApprovalListStatus = (state: RootState): StateStatus => state.approval.transferApprovals.status;
const selectTransferApprovalListCount = (state: RootState): number => state.approval.transferApprovals.count;

// Notifications Approvals list
const selectNotificationApprovalList = (state: RootState): NotificationApproval[] => state.approval.notificationApprovals.data;
const selectNotificationApprovalListStatus = (state: RootState): StateStatus => state.approval.notificationApprovals.status;
const selectNotificationApprovalListCount = (state: RootState): number => state.approval.notificationApprovals.count;

// Skill Training Approvals list
const selectSkillTrainingApprovalList = (state: RootState): SkillTrainingApproval[] => state.approval.skillTrainingApprovals.data;
const selectSkillTrainingApprovalListStatus = (state: RootState): StateStatus => state.approval.skillTrainingApprovals.status;
const selectSkillTrainingApprovalListCount = (state: RootState): number => state.approval.skillTrainingApprovals.count;

// Resources Approvals list
const selectResourceApprovalList = (state: RootState): ResourceApproval[] => state.approval.resourceApprovals.data;
const selectResourceApprovalListStatus = (state: RootState): StateStatus => state.approval.resourceApprovals.status;
const selectResourceApprovalListCount = (state: RootState): number => state.approval.resourceApprovals.count;

// Applications Approvals list
const selectApplicationApprovalList = (state: RootState): ApplicationApproval[] => state.approval.applicationApprovals.data;
const selectApplicationApprovalListStatus = (state: RootState): StateStatus => state.approval.applicationApprovals.status;
const selectApplicationApprovalListCount = (state: RootState): number => state.approval.applicationApprovals.count;

export const approvalSelectors = {
  // Student Approvals list
  selectStudentApprovalList,
  selectStudentApprovalListStatus,
  selectStudentApprovalListCount,

  // Transfer Approvals list
  selectTransferApprovalList,
  selectTransferApprovalListStatus,
  selectTransferApprovalListCount,

  // Notifications Approvals list
  selectNotificationApprovalList,
  selectNotificationApprovalListStatus,
  selectNotificationApprovalListCount,

  // Skill Training Approvals List
  selectSkillTrainingApprovalList,
  selectSkillTrainingApprovalListStatus,
  selectSkillTrainingApprovalListCount,

  // Resources Approvals list
  selectResourceApprovalList,
  selectResourceApprovalListStatus,
  selectResourceApprovalListCount,

  // Applications Approvals list
  selectApplicationApprovalList,
  selectApplicationApprovalListStatus,
  selectApplicationApprovalListCount,
};

export default approvalSlice.reducer;
