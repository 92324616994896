import {
  GridColumns, GridValueFormatterParams
} from '@mui/x-data-grid';
import dayjs from 'dayjs';


export enum CollegeApplicationChecklistTabs {
  applicants = 'Applicants',
  colleges = 'Colleges',
  financialAid = 'Financial Aid'
}

export const applicantsListColumns: GridColumns = [
  {
    headerName: 'Applicant Name',
    field: 'fullName',
    flex: .7,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    cellClassName: 'main-column',
  },
  {
    headerName: 'College',
    field: 'institutionName',
    flex: .7,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Application/Interested',
    field: 'applied',
    flex: .7,
    valueFormatter: (params: GridValueFormatterParams) => params.value === true ? 'Application' : 'Interested',
  },
  {
    headerName: 'Email',
    field: 'email',
    flex: .6,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Institution',
    field: 'organizationName',
    flex: .9,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Grad Year',
    field: 'graduationYear',
    flex: .4,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Date',
    field: 'createdAt',
    flex: .4,
    valueFormatter: (params: GridValueFormatterParams) => dayjs(params.value as string).format('MM/DD/YYYY'),
  },
];

export const collegesListColumns: GridColumns = [
  {
    headerName: 'College Name',
    field: 'name',
    flex: 2,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    cellClassName: 'main-column',
    sortable: true,
  },
  {
    headerName: 'College Type',
    field: 'type',
    flex: 2,
    valueFormatter: (params: GridValueFormatterParams) => params.value.name || '-',
  },
  {
    headerName: 'Date Last Edited',
    field: 'updatedAt',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => dayjs(params.value as string).format('MM/DD/YYYY'),
  },
];

export const financialAidListColumns: GridColumns = [
  {
    headerName: 'Page',
    field: 'name',
    flex: 3,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    cellClassName: 'main-column',
    sortable: false,
  },
  {
    headerName: 'Date Last Updated',
    field: 'lastUpdated',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => dayjs(params.value as string).format('MM/DD/YYYY') || '-',
    sortable: false,
  },
];
