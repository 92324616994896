
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { AppAssetPaths } from '../../../app/app.types';
import { useAppSelector } from '../../../app/hooks';
import { bulkAccountCreationSelectors } from '../../../features/bulkAccountCreation/bulkAccountCreation.slice';
import './BulkCreateStepFive.scss';


interface BulkCreateStepFiveProps { }

const BulkCreateStepFive: React.FC<BulkCreateStepFiveProps> = () => {

  const bulkAccountsCreated = useAppSelector(bulkAccountCreationSelectors.bulkAccountsCreated);
  const expirationDate = dayjs(bulkAccountsCreated.expirationDate).format('MM/DD/YYYY');
  const expirationTime = dayjs(bulkAccountsCreated.expirationDate).format('h:mma');

  return (
    <div className="step-five flex_row_jbetween">
      {bulkAccountsCreated.count !== 0 && bulkAccountsCreated.code && bulkAccountsCreated.expirationDate ? (
        <>
          <div className="left-container flex_col">
            <h2 className="red-text">{bulkAccountsCreated.count} new Keys to Success accounts have been created!</h2>
            <h3>Next Steps:</h3>
            <p className="next-steps">The access code below will be used for all users that were just created. To gain access to their new account, users will tap forgot password on the app’s login screen. They will enter their email and then be taken to a screen where they enter this code.</p>
            <p className="next-steps">When the correct code is entered in, the user will be asked to create a password. Going forward, the user will only be able to access their account with the password they created. </p>
            <p className="next-steps">A confirmation email with this code will be sent to you and all new users.</p>
            <p className="next-steps"><b>This code can only be used once per user and expires on {expirationDate} at {expirationTime}.</b></p>

            <h3 className="red-text">Access Code</h3>
            <div className="access-code flex_row_astart">
              <div className="code">{bulkAccountsCreated.code}</div>
              <div className="forgotten-code flex_col">
                <b>WARNING</b>
                If this code shown here is forgotten or does not work, you can generate a new code (for one user at a time) on their student details page</div>
            </div>
          </div>
          <span className="divider" />
          <div className="right-container flex_col_jcenter_acenter">
            <i>Student app screen with <q>Forgot Password</q></i>
            <img
              className="example-img"
              src={AppAssetPaths.images.IPHONE_FORGOT_PASSWORD_STUDENT_LOGIN}
            />

          </div>
        </>
      ) : (
        <div className="flex_jcenter_acenter loading-indicator">
          <CircularProgress size="50px" />
        </div>
      )}
    </div>
  );
};


export default BulkCreateStepFive;