import React, {
  useEffect, useState
} from 'react';
import {
  useParams, useLocation, useNavigate
} from 'react-router-dom';
import {
  useAppDispatch,
  useAppSelector
} from '../../app/hooks';
import {
  Box,
  Pagination
} from '@mui/material';
import { startCase } from 'lodash';
import {
  AppAssetPaths, canUseAuthComponent, AppRoutes
} from '../../app/app.types';
import { DataGrid } from '@mui/x-data-grid';
import { applicationsListColumns } from '../../constants/tables/resourceTable.types';
import { resourceSelectors } from '../../features/resources/resources.slice';
import { TableState } from '../../constants/tables/table.types';
import { getResourceApplications } from '../../features/resources/resources.thunks';
import TableRowCount from '../../components/table/TableRowCount';
import { DownloadButton } from '../../components/CustomStyledComponents';
import { ResourceType } from '../../features/resources/resources.model';
import { exportResouceApplicationsCSV } from '../../features/exports/exports.api';
import { getApplicationApprovalList } from '../../features/approvals/approvals.thunks';
import './ResourceApplicantsList.scss';
import {
  ApplicationApproval, ApprovalType
} from '../../features/approvals/approvals.model';
import { approvalSelectors } from '../../features/approvals/approvals.slice';
import { userSelectors } from '../../features/user/user.slice';


interface ResourceApplicantsListProps { }

const ResourceApplicantsList: React.FC<ResourceApplicantsListProps> = () => {

  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const location = useLocation();
  const user = useAppSelector(userSelectors.selectUser);

  const applicationList = useAppSelector(resourceSelectors.selectResourceApplications);
  const approvalsList: ApplicationApproval[] = state.type === ResourceType.scholarship ? useAppSelector(approvalSelectors.selectApplicationApprovalList) : [];

  const [
    tableState,
    setTableState,
  ] = useState<TableState>({
    page: 1,
    pageSize: 100,
    columns: applicationsListColumns,
    rows: [],
    loading: false,
  });

  const [
    filteredApplications,
    setFilteredApplications,
  ] = useState<any[]>([]);


  useEffect(() => {

    if (params.resourceId && state.type) {

      setTableState((prev) => ({
        ...prev,
        loading: true,
      }));

      dispatch(getResourceApplications({
        id: parseInt(params.resourceId),
        type: state.type,
      }));

      if (state.type === ResourceType.scholarship && canUseAuthComponent(user.roles, AppRoutes.applicationApprovals.authorizedRoles)) {
        dispatch(getApplicationApprovalList({
          offset: 0,
          limit: undefined,
          approvalType: ApprovalType.application,
          sortModel: undefined,
          userType: undefined,
          schoolType: undefined,
        }));
      }
    }
  }, []);

  // If the application approval is false then it's been denied and don't show
  useEffect(() => {
    const filteredList = applicationList.filter((el: any) => el.approved !== false);
    setFilteredApplications(filteredList);

  }, [
    applicationList,
  ]);

  useEffect(() => {
    setTableState((prev) => ({
      ...prev,
      loading: false,
      rows: filteredApplications,
    }));

  }, [
    filteredApplications,
  ]);

  // useEffect(() => {
  //   // const approvalUserIds = approvalsList.map((el) => el.userId);
  //   // const filteredList = applicationList.filter((el: any) => { return approvalUserIds.indexOf(el.id) !== -1; });
  //   const filteredList = applicationList.filter((el: any) => el.approved !== false);

  //   setTableState((prev) => ({
  //     ...prev,
  //     loading: false,
  //     rows: filteredList,
  //   }));


  // }, [
  //   approvalsList,
  // ]);

  return (
    <div id="resource-application-list">
      <Box className="page-content card-background">
        <div className="main-content flex_column">
          <div className="flex_row_jbetween_acenter">
            <h2> {startCase(state.type)} Applications </h2>
            <div className="table-buttons-and-filters flex_jbetween">
              <div className="filters flex_acenter">
                <DownloadButton
                  variant="contained"
                  startIcon={<img src={AppAssetPaths.icons.DOWNLOAD} />}
                  onClick={() => params.resourceId && exportResouceApplicationsCSV(state.type, +params.resourceId, state.title)}
                  disableElevation
                >
                  Download
                </DownloadButton>
              </div>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="resource-title-and-applicants">
          <span className="resource-title">{state.resourceTitle} {state.title}</span>
          <span className="applicant-count">{filteredApplications.length} Applications</span>
          <span></span>
        </div>

        <DataGrid
          className="table"
          {...tableState}
          components={{
            Pagination,
          }}
          componentsProps={{
            pagination: {
              page: tableState.page,
              count: Math.ceil(filteredApplications.length / tableState.pageSize),
              onChange: (_: void, page: number) => {
                setTableState((prev) => ({
                  ...prev,
                  page,
                }));
              },
            },
          }}
          onRowClick={(event) => {
            if (state.type === ResourceType.scholarship && event.row.essay) {
              // Cant return to page with no users if rejected
              const rejectReturnPage = (filteredApplications.length <= 1 && params.resourceId) ?
                AppRoutes.scholarshipDetails.path.replace(':scholarshipId', params.resourceId.toString()) : location.pathname;
              navigate(AppRoutes.scholarshipApplicationDetails.path.replace(':id', event.row.id.toString()), {
                state: {
                  type: ResourceType.scholarship,
                  id: params.resourceId,
                  title: state.title,
                  applicationId: approvalsList.find(o => o.userId.toString() == event.row.id && o.scholarshipId.toString() == params.resourceId)?.id,
                  returnPage: rejectReturnPage,
                },
              });
            }
          }}
          pagination
          paginationMode="server"
          sortingMode="server"
          disableColumnFilter
          disableColumnMenu
          hideFooterSelectedRowCount
        />

        <TableRowCount
          length={filteredApplications.length}
          pageSize={tableState.pageSize}
          page={tableState.page}
          totalCount={filteredApplications.length}
        />
      </Box>
    </div>
  );
};

export default ResourceApplicantsList;
