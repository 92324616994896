import React, {
  useState,
  useEffect
} from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Button,
  Divider
} from '@mui/material';
import {
  FormProvider,
  SubmitHandler,
  useForm
} from 'react-hook-form';

import {
  useAppDispatch, useAppSelector
} from '../../app/hooks';
import { AppRoutes } from '../../app/app.types';

import { Organization } from '../../features/organization/organization.model';
import {
  createNewOrganization, updateOrganization, deleteOrganization, getCommunityOrganizationTypeList
} from '../../features/organization/organization.thunks';
import { getOrganizationById } from '../../features/organization/organization.api';
import { organizationSelectors } from '../../features/organization/organization.slice';
import { getCareerGroups } from '../../features/careers/careers.thunks';
import { careerSelectors } from '../../features/careers/careers.slice';
import { setAlert } from '../../features/alert/alert.slice';
import {
  formatPhone, numberOnlyValidation
} from '../../util/formatting';

import TextInput from '../../components/forms/TextInput';
import DropdownSelect from '../../components/forms/DropdownSelect';
import SubmitButton from '../../components/forms/SubmitButton';
import UploadButton from '../../components/forms/UploadButton';
import DeleteConfirmationModal from '../../components/modal/DeleteConfirmationModal';

import './CreateUpdateCommunity.scss';
import {
  ConstructListMenuItem, ListMenuItem
} from '../../components/forms/form.types';

interface CreateUpdateCommunityProps { }

const CreateUpdateCommunity: React.FC<CreateUpdateCommunityProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const isEdit = Boolean(params && params.organizationId);

  const communityOrganizationTypes = useAppSelector(organizationSelectors.selectCommunityOrganizationTypeList);
  const careerGroups = useAppSelector(careerSelectors.selectCareerGroups);

  const [
    organization,
    setOrganization,
  ] = useState<Organization>(undefined as unknown as Organization);
  const [
    defaultCommunityType,
    setDefaultCommunityType,
  ] = useState<ListMenuItem>(undefined as unknown as ListMenuItem);
  const [
    showDeleteConfirmDialog,
    setShowDeleteConfirmDialog,
  ] = useState<boolean>(false);

  const formValidationSchema = yup.object().shape({
    name:
      yup.string()
        .default(organization?.name)
        .required('Organization name is required')
        .typeError('Organization name is required'),
    contactPhoneNumber:
      yup.string()
        .test('len', 'Must be exactly 10 characters', (val?: string) => {
          return !val || val.length === 12 || val.length === 0;
        }).typeError(''),
    organizationType:
      yup.object()
        .default(defaultCommunityType)
        .required('Organization type is required')
        .typeError('Organization type is required'),
    careerGroups:
      yup.array()
        .nullable()
        .min(1, 'Organization Career Field is required')
        .required('Organization Career Field is required'),
    contactFirstName:
      yup.string()
        .default(organization?.communityUser?.firstname)
        .required('Contact first name is required')
        .typeError('Contact first name is required'),
    contactLastName:
      yup.string()
        .default(organization?.communityUser?.lastname)
        .required('Contact last name is required')
        .typeError('Contact last name is required'),
    contactEmail:
      yup.string()
        .default(organization?.communityUser?.email)
        .email('Must be a valid email')
        .required('Contact email is required')
        .typeError('Contact email is required'),
  }).required();

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    dispatch(getCommunityOrganizationTypeList());
    dispatch(getCareerGroups());

    if (isEdit && params.organizationId) {
      getOrganizationData(+params.organizationId);
    }

  }, []);

  const getOrganizationData = async (id: number) => {
    const response = await getOrganizationById(id);
    setOrganization(response);

    if (response.communityOrganizationType) {
      setDefaultCommunityType(ConstructListMenuItem(response.communityOrganizationType.id, response.communityOrganizationType.label));
    }
  };

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    if (isEdit) {
      dispatch(updateOrganization({
        ...values,
        id: Number(params.organizationId),
      }))
        .unwrap()
        .then(() => {
          dispatch(setAlert({
            type: 'success',
            message: 'Successfully updated community.',
          }));

          navigate(AppRoutes.communityOrganizations.path);
        })
        .catch(() => {
          dispatch(setAlert({
            type: 'error',
            message: 'Unable to update community.',
          }));
        });
    }
    else {
      dispatch(createNewOrganization(values))
        .unwrap()
        .then(() => {
          dispatch(setAlert({
            type: 'success',
            message: 'Successfully created community.',
          }));

          navigate(AppRoutes.communityOrganizations.path);
        })
        .catch(() => {
          dispatch(setAlert({
            type: 'error',
            message: 'Unable to create new community.',
          }));
        });
    }
  };

  const onDeleteCommunity = () => {
    if (!params.organizationId) return;

    setShowDeleteConfirmDialog(false);

    dispatch(deleteOrganization(+params.organizationId))
      .unwrap()
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully deleted community.',
        }));

        navigate(AppRoutes.communityOrganizations.path);
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to delete community.',
        }));
      });
  };

  return (
    <div id="create-update-community-page" className="card-background flex_col">
      <form className="create-form-container" onSubmit={handleSubmit(submitForm)}>
        <FormProvider {...methods}>
          <h3>{isEdit ? 'Community Details' : 'New Community'}</h3>
          <Divider />

          {(organization || !params || !params.organizationId) && careerGroups.length > 0 && (
            <>
              <div className="form-content-wrapper">
                <TextInput
                  containerClass="form-input"
                  name="name"
                  label="ORGANIZATION NAME"
                  errorMessage={errors.name && errors.name.message}
                  type="text"
                  size="small"
                  required
                  defaultValue={organization?.name}
                />

                <TextInput
                  containerClass="form-input"
                  name="contactPhoneNumber"
                  label="CONTACT NUMBER"
                  errorMessage={errors.contactPhoneNumber && errors.contactPhoneNumber.message}
                  type="text"
                  size="small"
                  defaultValue={organization?.communityUser?.phoneNumber && formatPhone(organization?.communityUser?.phoneNumber)}
                  onKeyPress={(e) => {
                    numberOnlyValidation(e);
                    if (e.keyCode || e.keyCode == 0) {
                      e.target.value = formatPhone(e.target.value);
                    }
                  }}
                />

                <DropdownSelect
                  containerClass="form-input"
                  name="organizationType"
                  label="ORGANIZATION TYPE"
                  itemList={communityOrganizationTypes}
                  errorMessage={errors.organizationType && errors.organizationType.message}
                  size="small"
                  defaultValue={defaultCommunityType}
                  required
                />

                <DropdownSelect
                  containerClass="form-input"
                  name="careerGroups"
                  label="ORGANIZATION CAREER FIELD"
                  itemList={careerGroups}
                  errorMessage={errors.careerGroups && errors.careerGroups.message}
                  size="small"
                  // TODO: add default value to auto populate values on edit
                  required
                  multiple
                  selectAll
                />

                <div className="field-container">
                  <p className="static-input-label required-field">CONTACT NAME</p>
                  <div className="flex_row_jbetween">
                    <TextInput
                      containerClass="form-input mr-20"
                      name="contactFirstName"
                      defaultValue={organization?.communityUser?.firstname}
                      errorMessage={errors.contactFirstName && errors.contactFirstName.message}
                      type="text"
                      size="small"
                      required
                    />

                    <TextInput
                      containerClass="form-input"
                      name="contactLastName"
                      defaultValue={organization?.communityUser?.lastname}
                      errorMessage={errors.contactLastName && errors.contactLastName.message}
                      type="text"
                      size="small"
                      required
                    />
                  </div>
                </div>

                <div className="field-container">
                  <p className="static-input-label">LOGO</p>
                  <UploadButton
                    text="Upload Logo"
                    name="logo"
                    size="large"
                    disableElevation
                    defaultFileName={organization?.images && organization?.images[0] || ''}
                  />
                </div>


                <TextInput
                  containerClass="form-input"
                  name="contactEmail"
                  label="CONTACT EMAIL"
                  type="text"
                  size="small"
                  required
                  defaultValue={organization?.communityUser?.email}
                  errorMessage={errors.contactEmail && errors.contactEmail.message}
                />
              </div>

              <div className="flex_row_jbetween">
                {isEdit && (
                  <Button
                    className="form-button delete-button"
                    variant="outlined"
                    onClick={() =>  setShowDeleteConfirmDialog(true)}
                  >
                    Delete Organization
                  </Button>
                )}

                <div className={isEdit ? 'right-side flex_row_jend': 'right-side flex_row_jbetween'}>
                  <Button
                    className="form-button cancel-button"
                    variant="outlined"
                    onClick={() => isEdit
                      ? navigate(AppRoutes.communityDetail.path.replace(':organizationId', organization.id.toString()))
                      : navigate(AppRoutes.communityOrganizations.path)
                    }>
                    Cancel
                  </Button>

                  <SubmitButton
                    className="form-button"
                    text="Save"
                    variant="contained"
                    size="large"
                    disableElevation
                  />
                </div>
              </div>
            </>
          )}
        </FormProvider>
      </form>

      <DeleteConfirmationModal
        open={showDeleteConfirmDialog}
        deleteItemText="community"
        onConfirm={onDeleteCommunity}
        onClose={() => setShowDeleteConfirmDialog(false)}
      />
    </div>
  );
};

export default CreateUpdateCommunity;
