import {
  GridColumns, GridValueFormatterParams
} from '@mui/x-data-grid';


export const careerClusterListColumns: GridColumns = [
  {
    headerName: 'Career Cluster',
    field: 'label',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
];