import dayjs from 'dayjs';
import React from 'react';
import './AppFooter.scss';


interface AppFooterProps { }

const AppFooter: React.FC<AppFooterProps> = () => {

  return (
    <div id="footer" className="flex_jcenter">
      <p>&copy; {dayjs().get('year')} by Keys to Success, a Success in Education Foundation Program</p>
    </div>
  );
};

export default AppFooter;
