/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useState, useEffect
} from 'react';
import {
  useNavigate, useParams
} from 'react-router-dom';
import {
  FormProvider, SubmitHandler, useForm
} from 'react-hook-form';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Tooltip
} from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import {
  isEqual, uniqWith
} from 'lodash';
import {
  useAppSelector, useAppDispatch
} from '../../../app/hooks';
import {
  AppAssetPaths, AppRoutes
} from '../../../app/app.types';

import { userSelectors } from '../../../features/user/user.slice';
import {
  getEducationLevels,
  getEthnicities,
  getGenders, getUserTypeList
} from '../../../features/user/user.thunks';
import {
  UserRole, gradeEducationValuesListOptions
} from '../../../features/user/user.model';
import { organizationSelectors } from '../../../features/organization/organization.slice';
import {
  getAllSchoolDistricts, getByType
} from '../../../features/organization/organization.thunks';
import {
  Organization, OrganizationType
} from '../../../features/organization/organization.model';
import { careerSelectors } from '../../../features/careers/careers.slice';
import { getCareerGroups } from '../../../features/careers/careers.thunks';
import { setAlert } from '../../../features/alert/alert.slice';
import {
  createNotification, updateNotification, deleteNotification
} from '../../../features/notifications/notifications.api';
import { notificationSelectors } from '../../../features/notifications/notifications.slice';
import { getNotificationById } from '../../../features/notifications/notifications.thunks';

import { Notification } from '../../../features/notifications/notifications.model';
import { ListMenuItem } from '../../../components/forms/form.types';
import SubmitButton from '../../../components/forms/SubmitButton';
import TextInput from '../../../components/forms/TextInput';
import DropdownSelect from '../../../components/forms/DropdownSelect';
import DeleteConfirmationModal from '../../../components/modal/DeleteConfirmationModal';
import { UserType } from '../../../features/user/userType.model';

import './CreateEditNotification.scss';
import { END_OF_SCHOOL_YEAR } from '../../../util/dates';
import SendPushNotificationModal from '../../../components/modal/SendPushNotificationModal';

const CreateEditNotification: React.FC = () => {

  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const user = useAppSelector(userSelectors.selectUser);
  const userTypeList = useAppSelector(userSelectors.selectUserTypeList);
  const genders = useAppSelector(userSelectors.selectGenders);
  const gendersLoaded = useAppSelector(userSelectors.selectGendersLoaded);
  const ethnicities = useAppSelector(userSelectors.selectEthnicities);
  const ethnicitiesLoaded = useAppSelector(userSelectors.selectEthnicitiesLoaded);
  const educationLevels = useAppSelector(userSelectors.selectEducationLevels);
  const educationLevelsLoaded = useAppSelector(userSelectors.selectEducationLevelsLoaded);
  const schoolDistricts = useAppSelector(organizationSelectors.selectSchoolDistricts);
  const careerGroups = useAppSelector(careerSelectors.selectCareerGroups);
  const careerGroupsLoaded = useAppSelector(careerSelectors.selectCareerGroupsLoaded);
  const notification = useAppSelector(notificationSelectors.selectNotification);
  const organizationList = useAppSelector(organizationSelectors.selectOrganizationList);


  // BOOLEANS
  const [
    editMode,
    setEditMode,
  ] = useState<boolean>(false);
  const [
    readyToLoad,
    setReadyToLoad,
  ] = useState<boolean>(false);
  const [
    pageLoaded,
    setPageLoaded,
  ] = useState<boolean>(false);
  const [
    notifyAllUsers,
    setNotifyAllUsers,
  ] = useState(false);
  const [
    isPushNotification,
    setIsPushNotifiation,
  ] = useState(false);
  const [
    showDeleteConfirmDialog,
    setShowDeleteConfirmDialog,
  ] = useState<boolean>(false);
  const [
    disableDistrictDropDownList,
    setDisableDistrictDropDownList,
  ] = useState<boolean>(false);

  // Arrays
  const [
    staticGradeList,
    setStaticGradeList,
  ] = useState<any[]>([]);
  const [
    dynamicGradeList,
    setDynamicGradeList,
  ] = useState<any[]>([]);
  const [
    middleSchoolGrades,
    setMiddleSchoolGrades,
  ] = useState<any[]>([]);
  const [
    highSchoolGrades,
    setHighSchoolGrades,
  ] = useState<any[]>([]);
  const [
    adultGrades,
    setAdultGrades,
  ] = useState<any[]>([]);
  const [
    dynamicSchoolList,
    setDynamicSchoolList,
  ] = useState<Organization[]>([]);

  // Other
  const [
    pushNotificationData,
    setPushNotificationData,
  ] = useState<Notification>();


  useEffect(() => {
    createStaticGradeList();

    !careerGroupsLoaded && dispatch(getCareerGroups());
    !gendersLoaded && dispatch(getGenders());
    !ethnicitiesLoaded && dispatch(getEthnicities());
    !educationLevelsLoaded && dispatch(getEducationLevels());
    (!userTypeList || userTypeList.length === 0) && dispatch(getUserTypeList());
    (!schoolDistricts || schoolDistricts.length === 0) && dispatch(getAllSchoolDistricts());


    const userOrgId = (user.isDistrictAdmin)
      ? (user.organizationId)
      : ((user.isKeyContact || user.isCounselor) && !user.isUniversityCounselor && !user.isUniversityKeyContact)
        ? (user.organization.schoolDistrict?.id)
        : undefined;

    dispatch(getByType({
      orgType: undefined,
      schoolDistrictId: userOrgId,
      onlySchools: true,
    }));

    if (params && params.notificationId) {
      dispatch(getNotificationById(+params.notificationId));
      setEditMode(true);
    }
  }, []);

  useEffect(() => {
    if ((params && params.notificationId && +params.notificationId !== notification.id) || pageLoaded) return;

    // Push notifications are NOT allowed to be edited. So If someone somehow gets to the edit page for a push notification, kick them out
    if (editMode && notification && notification.isPush) return navigate(AppRoutes.notifications.path);


    if (user
      && !!userTypeList.length
      && !!genders.length
      && !!ethnicities.length
      && !!educationLevels.length
      && !!schoolDistricts.length
      && !!careerGroups.length
      && !!staticGradeList.length
      && !!middleSchoolGrades.length
      && !!highSchoolGrades.length
      && !!organizationList.length
    ) {
      handleGradesDynamically();
      setDynamicSchoolList(organizationList);
      setReadyToLoad(true);
    }

  }, [
    user,
    notification,
    userTypeList,
    genders,
    ethnicities,
    educationLevels,
    schoolDistricts,
    careerGroups,
    staticGradeList,
    middleSchoolGrades,
    highSchoolGrades,
    organizationList,
  ]);


  useEffect(() => {
    if (readyToLoad) {
      prePopulateValues();
      setPageLoaded(true);
    }
  }, [
    readyToLoad,
  ]);

  //   _____             _____
  //  |  __ \           |  __ \
  //  | |__) |_ __  ___ | |__) |___   _ __
  //  |  ___/| '__|/ _ \|  ___// _ \ | '_ \
  //  | |    | |  |  __/| |   | (_) || |_) |
  //  |_|    |_|   \___||_|    \___/ | .__/
  //                                 | |
  //                                 |_|

  const prePopulateValues = () => {
    setValue('subject', editMode ? notification.subject : '');
    setValue('message', editMode ? notification.body : '');
    setValue('userType', findDefaultUserTypes());
    handleGradesDynamically();
    setValue('grade', handleDefaultGrades());
    setValue('gender', editMode ? genders.filter((item: ListMenuItem) => notification?.genderIds?.includes(+item.id)) : undefined);
    setValue('ethnicity', editMode ? ethnicities.filter((item: ListMenuItem) => notification?.ethnicityIds?.includes(+item.id)) : []);
    setValue('parentEducationLevel', editMode ? educationLevels.filter((item: ListMenuItem) => notification?.parentEducationLevelIds?.includes(+item.id)) : []);
    setValue('schoolDistrict', handleDefaultSchoolDistricts());
    setValue('school', handleDefaultSchools());
    setValue('careers', editMode ? careerGroups.filter((item: ListMenuItem) => notification?.careerGroupIds?.includes(+item.id)) : undefined);
    setIsPushNotifiation(editMode && notification && notification.isPush);

  };

  //  _    _                    _______
  // | |  | |                  |__   __|
  // | |  | | ___   ___  _ __     | | _   _  _ __    ___
  // | |  | |/ __| / _ \| '__|    | || | | || '_ \  / _ \
  // | |__| |\__ \|  __/| |       | || |_| || |_) ||  __/
  //  \____/ |___/ \___||_|       |_| \__, || .__/  \___|
  //                                   __/ || |
  //                                  |___/ |_|

  const handleUserTypeFieldChange = () => {
    const fieldValue = getValues('userType');

    let filteredInstiList: any[] = [];

    if (fieldValue.some((ele: UserType) => ele.label === 'Select All')) {
      filteredInstiList = organizationList;
    }
    else {
      filteredInstiList = organizationList.filter((org: Organization) => fieldValue.some((u: UserType) => org.type === OrganizationType.UniversityCollege ? u.type === OrganizationType.Adult : org.type === u.type));
    }

    if (fieldValue && !fieldValue.length) {
      filteredInstiList = [];
    }
    setDynamicSchoolList(filteredInstiList);
    setValue('school', filteredInstiList);
    handleGradesDynamically();

    return filteredInstiList;
  };

  const findDefaultUserTypes = () => {
    if (editMode && notification.userTypeIds) {
      return userTypeList.filter((userType: any) => notification.userTypeIds?.includes(userType.id));
    }

    if (user.isDistrictInstitutionAdmin || user.isAdultAdmin) {
      return userTypeList.filter((e: UserType) => e.type === 'adult_school');
    }

    if (user.isKeyContact || user.isCounselor) {
      return userTypeList.filter((e: UserType) => user.organization.type === e.type);
    }

    return null;
  };



  //    _____                  _
  //   / ____|                | |
  //  | |  __  _ __  __ _   __| |  ___  ___
  //  | | |_ || '__|/ _` | / _` | / _ \/ __|
  //  | |__| || |  | (_| || (_| ||  __/\__ \
  //   \_____||_|   \__,_| \__,_| \___||___/

  const createStaticGradeList = () => {
    const arr: any[] = [];
    const newYearIndex = (dayjs() < dayjs(`${END_OF_SCHOOL_YEAR}/${dayjs().year()}`)) ? 0 : 1;
    for (let i = 0; i <= 6; i++) {
      arr.unshift({
        id: new Date().getFullYear() + i + newYearIndex,
        label: ((12 - i) + 'th').toString(),
      });
    }
    setStaticGradeList([
      ...arr,
      ...gradeEducationValuesListOptions.adult_school,
    ]);

    setMiddleSchoolGrades(filterStaticGradelist(arr, gradeEducationValuesListOptions.middle_school.map(e => e.label)));
    setHighSchoolGrades(filterStaticGradelist(arr, gradeEducationValuesListOptions.high_school.map(e => e.label)));
    setAdultGrades(gradeEducationValuesListOptions.adult_school);
  };

  const filterStaticGradelist = (array: any[], grades: string[]) => {
    return array.filter(grade => grades.includes(grade.label));
  };

  const handleGradesDynamically = () => {
    const userTypes = getValues('userType');

    let returnGradeList: ListMenuItem[] = [];

    if (userTypes && !!userTypes.length) {
      // For when middle school is selected
      (userTypes.some((o: UserType) => o.type === OrganizationType.MiddleSchool)) && (returnGradeList = returnGradeList.concat(middleSchoolGrades));
      // For when high school is selected
      (userTypes.some((o: UserType) => o.type === OrganizationType.HighSchool)) && (returnGradeList = returnGradeList.concat(highSchoolGrades));
      // For when adult user type is selected
      (userTypes.some((o: UserType) => o.type === OrganizationType.Adult)) && (returnGradeList = returnGradeList.concat(adultGrades));
    }

    returnGradeList = uniqWith(returnGradeList, isEqual);
    setDynamicGradeList(returnGradeList);
    return returnGradeList;
  };

  const handleGradeEducationFieldChange = (e: any) => {
    // if "Higher Eduction" is selected, set and lock schoolDistricts to "Post-Secondary Education
    // set SchoolList to Post-Secondary Education
    if (!user.isDistrictAdmin) {
      if (e && e.length === 1 && e[0].id === OrganizationType.UniversityCollege) {
        setValue('schoolDistrict', schoolDistricts.filter(s => s.name === 'Post-Secondary Education'));
        setValue('school', dynamicSchoolList.filter(s => s.schoolDistrict?.name === 'Post-Secondary Education'));
        setDisableDistrictDropDownList(true);
      } else {
        setDisableDistrictDropDownList(false);
      }
    }
  };

  const handleDefaultGrades = () => {
    if (editMode) {
      const gradeSchools = staticGradeList.filter((grade) => notification.graduationYears?.includes(grade.id));
      const adultSchools = staticGradeList.filter((grade) => notification.organizationTypes?.includes(grade.id));
      return [
        ...gradeSchools,
        ...adultSchools,
      ];
    }

    if (user.isAdultCounselor || user.isAdultKeyContact) {
      return gradeEducationValuesListOptions.adult_school.filter((item: ListMenuItem) => item.id === OrganizationType.Adult);
    }

    if (user.isUniversityCounselor || user.isUniversityKeyContact) {
      return gradeEducationValuesListOptions.adult_school.filter((item: ListMenuItem) => item.id === OrganizationType.UniversityCollege);
    }

    if (user.isDistrictInstitutionAdmin) {
      return gradeEducationValuesListOptions.adult_school.filter((item: ListMenuItem) => item.id === OrganizationType.UniversityCollege);
    }

    return null;
  };

  //   _____   _       _          _        _
  //  |  __ \ (_)     | |        (_)      | |
  //  | |  | | _  ___ | |_  _ __  _   ___ | |_
  //  | |  | || |/ __|| __|| '__|| | / __|| __|
  //  | |__| || |\__ \| |_ | |   | || (__ | |_
  //  |_____/ |_||___/ \__||_|   |_| \___| \__|

  const handleDistrictFieldChange = () => {
    const selectedDistricts = getValues('schoolDistrict');

    if (selectedDistricts && !!selectedDistricts.length) {
      handleUserTypeFieldChange();
      if (selectedDistricts.some((ele: ListMenuItem) => ele.label === 'Select All')) {
        return setDynamicSchoolList(organizationList);
      }

      const currentSchools = getValues('school');

      const filteredList: Organization[] = currentSchools.length > 0
        ? currentSchools.filter((org: Organization) => org.schoolDistrict && selectedDistricts.some((dis: any) => dis.id === org?.schoolDistrict?.id))
        : organizationList.filter((org: Organization) => org.schoolDistrict && selectedDistricts.some((dis: any) => dis.id === org?.schoolDistrict?.id));
      setValue('school', filteredList);
      setDynamicSchoolList(filteredList);
    }
    else {
      setDynamicSchoolList(organizationList);
    }

  };


  const handleDefaultSchoolDistricts = () => {
    if (editMode) {
      return schoolDistricts.filter((item: Organization) => notification?.schoolDistrictIds?.includes(item.id as number));
    }

    if (user.roles.includes(UserRole.admin)) {
      return undefined;
    }

    if (user.roles.includes(UserRole.districtAdmin)) {
      return schoolDistricts.filter(item => item.id === user.organizationId);
    }

    return [
      {
        id: user.organization.schoolDistrict?.id,
        label: user.organization.schoolDistrict?.name,
      },
    ];
  };


  //    _____        _                    _
  //   / ____|      | |                  | |
  //  | (___    ___ | |__    ___    ___  | | ___
  //   \___ \  / __|| '_ \  / _ \  / _ \ | |/ __|
  //   ____) || (__ | | | || (_) || (_) || |\__ \
  //  |_____/  \___||_| |_| \___/  \___/ |_||___/

  const handleDefaultSchools = () => {
    if (editMode) {
      const defaultSchools = organizationList.filter((item: Organization) => notification?.organizationIds?.includes(item.id as number));
      return defaultSchools;
    }

    if (user.isAdmin || user.isDistrictAdmin) {
      return organizationList;
    }

    return [
      {
        id: user.organization.id,
        label: user.organization.name,
      },
    ];
  };



  const formValidationSchema = yup.object().shape({
    subject: yup.string()
      .required('Subject is required.'),
    message: yup.string()
      .required('Message is required.'),
    userType: yup.array()
      .min(1, 'User type is required')
      .required('User type is required')
      .typeError('User type is required'),
    grade: yup.array()
      .min(1, 'Grade / education is required')
      .required('Grade / education is required')
      .typeError('Grade / education is required'),
    gender: yup.array()
      .min(1, 'Gender is required')
      .required('Gender is required'),
    schoolDistrict: yup.array()
      .min(1, 'School district is required')
      .required('School district is required'),
    school: yup.array()
      .min(1, 'School is required')
      .required('School is required'),
    careers: yup.array()
      .min(1, 'Careers is required')
      .required('Careers is required'),
    ethnicity: yup.array(),
    parentEducationLevel: yup.array(),
  }).required();

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });

  const { handleSubmit, setValue, getValues, formState: { errors } } = methods;

  const onDeleteNotification = () => {
    setShowDeleteConfirmDialog(false);
    if (!notification.id) return;

    deleteNotification(notification.id)
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully delete notification!',
        }));
        navigate(AppRoutes.notifications.path);
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to delete notification.',
        }));
      });
  };

  const onSendPushNotification = (data: Notification) => {
    createNotification(data)
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully created notification.',
        }));
        navigate(AppRoutes.notifications.path);
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to create new notification.',
        }));
      });
    setPushNotificationData(undefined);
  };

  //  ______                         _____               _                _
  // |  ____|                       / ____|             | |              | |
  // | |__  ___   _ __  _ __ ___   | |      ___   _ __  | |_  _ __  ___  | |
  // |  __|/ _ \ | '__|| '_ ` _ \  | |     / _ \ | '_ \ | __|| '__|/ _ \ | |
  // | |  | (_) || |   | | | | | | | |____| (_) || | | || |_ | |  | (_) || |
  // |_|   \___/ |_|   |_| |_| |_|  \_____|\___/ |_| |_| \__||_|   \___/ |_|

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    // If someone somehow manages to update a notification to a push notificaiton, kick them out.
    if (editMode && values.isPush === true) return navigate(AppRoutes.notifications.path);

    const gradYearMapPlaceholder = values.grade.filter((item: ListMenuItem) => typeof item.id === 'number');

    const graduationYears = gradYearMapPlaceholder.map((item: ListMenuItem) => item.id);

    const middleGrades = middleSchoolGrades.map(option => option.id);
    const highGrades = highSchoolGrades.map(option => option.id);
    const adultEducations = adultGrades.map(option => option.id);

    const organizationTypes = values.grade.map((item: ListMenuItem) => {
      if (middleGrades.includes(item.id)) {
        return OrganizationType.MiddleSchool;
      }
      if (highGrades.includes(item.id)) {
        return OrganizationType.HighSchool;
      }
      else if (adultEducations.includes(item.id)) {
        return item.id;
      }
    });

    const newNotificationData: Notification = {
      subject: values.subject,
      body: values.message,
      userTypeIds: values.userType.map((userType: UserType) => userType.id),
      graduationYears,
      organizationTypes: Array.from(new Set(organizationTypes)),
      genderIds: values.gender.map((gender: ListMenuItem) => gender.id),
      ethnicityIds: values.ethnicity.map((eth: ListMenuItem) => eth.id),
      parentEducationLevelIds: values.parentEducationLevel.map((edu: ListMenuItem) => edu.id),
      isPush: (user.isAdmin) ? isPushNotification : false,
      schoolDistrictIds: values.schoolDistrict.map((schoolDistrict: Organization) => schoolDistrict.id),
      organizationIds: values.school.map((school: Organization) => school.id),
      careerGroupIds: values.careers.map((career: ListMenuItem) => career.id),
    };

    if (editMode) {
      newNotificationData.id = notification.id;
      updateNotification(newNotificationData)
        .then(() => {
          dispatch(setAlert({
            type: 'success',
            message: 'Successfully updated notification.',
          }));
          navigate(AppRoutes.notifications.path);
        })
        .catch(() => {
          dispatch(setAlert({
            type: 'error',
            message: 'Unable to update notification.',
          }));
        });
    }
    else {
      // If a push notification, display and handle create in SendPushNotificationModal
      if (values.isPush === true || isPushNotification === true) {
        setPushNotificationData(newNotificationData);
      }
      else {
        createNotification(newNotificationData)
          .then(() => {
            dispatch(setAlert({
              type: 'success',
              message: 'Successfully created notification.',
            }));
            navigate(AppRoutes.notifications.path);
          })
          .catch(() => {
            dispatch(setAlert({
              type: 'error',
              message: 'Unable to create new notification.',
            }));
          });
      }
    }
  };

  const handleSelectAllUsers = () => {
    const canChangeUserType = user.isKeyContact || user.isCounselor;
    const canChangeGradeEducation = user.isDistrictInstitutionAdmin || user.isAdultAdmin;
    const canChangeSchoolDistrict = !user.roles.includes(UserRole.admin) || disableDistrictDropDownList;
    const canChangeSchool = !user.isAdmin && !user.isDistrictAdmin;


    !canChangeUserType && setValue('userType', !notifyAllUsers ? userTypeList : []);
    !canChangeGradeEducation && setValue('grade', !notifyAllUsers ? handleGradesDynamically() : []);
    setValue('gender', !notifyAllUsers ? genders.filter(item => !!item.id) : []);
    setValue('ethnicity', !notifyAllUsers ? ethnicities.filter(item => !!item.id) : []);
    setValue('parentEducationLevel', !notifyAllUsers ? educationLevels.filter(item => !!item.id) : []);
    // If user is District Admin, locked school District to that District school only
    !canChangeSchoolDistrict && setValue('schoolDistrict', !notifyAllUsers ? schoolDistricts : []);
    !canChangeSchool && setValue('school', !notifyAllUsers ? organizationList : []);
    setValue('careers', !notifyAllUsers ? careerGroups : []);
    setNotifyAllUsers(!notifyAllUsers);
  };

  return (
    <div className="card-background create-edit-notification-container">
      <h3>{editMode ? 'Notification Details' : 'New Notification'}</h3>
      {pageLoaded ? (
        <form className="form-content flex_row_jbetween" onSubmit={handleSubmit(submitForm)}>
          <FormProvider {...methods}>
            <TextInput
              containerClass="half-width"
              name="subject"
              errorMessage={errors.subject?.message}
              label="SUBJECT"
              type="text"
              size="small"
              required
            />


            {(user.isAdmin) && (

              <div className="half-width">
                <p className="static-input-label checkbox-label"> Notification Type </p>

                <div className="flex_row_acenter">
                  <FormControlLabel
                    label="Push Notification"
                    control={
                      <Checkbox
                        checked={isPushNotification}
                        onChange={() => setIsPushNotifiation(!isPushNotification)} name="Is Push Notification"
                        disabled={editMode}
                      />
                    }
                  />
                  <Tooltip
                    title="This notification will be sent as a notification to the users phone (outside of the application). It will also be visible on the notification feature of the app."
                    arrow
                    placement="right">
                    <img className="info-icon" src={AppAssetPaths.icons.INFO} alt="info" />
                  </Tooltip>
                </div>
              </div>
            )}

            <TextInput
              containerClass="full-width"
              name="message"
              label="MESSAGE"
              errorMessage={errors.message?.message}
              type="textarea"
              size="small"
              required
              multiLine
              rows={8}
            />

            <div className="full-width mt-10">
              <FormControlLabel
                label="Notify All Users"
                control={
                  <Checkbox checked={notifyAllUsers} onChange={handleSelectAllUsers} name="Notify All Users" />
                }
              />
            </div>


            <DropdownSelect
              containerClass={'half-width'}
              name="userType"
              label="USER TYPE"
              itemList={userTypeList}
              errorMessage={errors.userType?.message}
              onChange={(e) => {
                handleUserTypeFieldChange();
                setNotifyAllUsers(false);
              }}
              disabled={user.roles.includes(UserRole.keyContact) || user.roles.includes(UserRole.counselor)}
              size="small"
              required
              multiple
              selectAll
            />

            <DropdownSelect
              containerClass="half-width"
              name="grade"
              label="GRADE /EDUCATION"
              itemList={dynamicGradeList}
              errorMessage={errors.grade?.message}
              disabled={user.isDistrictInstitutionAdmin || user.isAdultAdmin}
              onChange={(e) => handleGradeEducationFieldChange(e)}
              size="small"
              required
              multiple
              selectAll={!!dynamicGradeList.length}
            />

            <DropdownSelect
              containerClass="half-width"
              inputClass="full-width"
              name="gender"
              label="GENDER"
              itemList={genders.filter(item => !!item.id)}
              errorMessage={errors.gender?.message}
              onChange={() => setNotifyAllUsers(false)}
              size="small"
              required
              multiple
              selectAll
            />

            <DropdownSelect
              containerClass="half-width"
              inputClass="full-width"
              name="schoolDistrict"
              label="DISTRICT"
              itemList={schoolDistricts}
              errorMessage={errors.schoolDistrict?.message}
              onChange={() => {
                handleDistrictFieldChange();
                setNotifyAllUsers(false);
              }}
              // if "Grade/Education" is "Higher Education", lock District to "Post Secondary Education"
              disabled={!user.roles.includes(UserRole.admin) || disableDistrictDropDownList}
              size="small"
              required
              multiple
              selectAll
            />

            <DropdownSelect
              containerClass="half-width"
              inputClass="full-width"
              name="school"
              label="SCHOOL"
              itemList={dynamicSchoolList}
              errorMessage={errors.school?.message}
              disabled={!user.roles.includes(UserRole.admin) && !user.roles.includes(UserRole.districtAdmin)}
              onChange={() => setNotifyAllUsers(false)}
              size="small"
              required
              multiple
              selectAll={!!dynamicSchoolList.length}
            />

            <DropdownSelect
              containerClass="half-width"
              inputClass="full-width"
              name="careers"
              label="CAREERS"
              itemList={careerGroups}
              errorMessage={errors.careers?.message}
              onChange={() => setNotifyAllUsers(false)}
              size="small"
              required
              multiple
              selectAll
            />

            <DropdownSelect
              containerClass="half-width"
              inputClass="full-width"
              name="ethnicity"
              label="RACE/ETHNICITY"
              itemList={ethnicities}
              errorMessage={errors.ethnicity?.message}
              onChange={() => setNotifyAllUsers(false)}
              size="small"
              multiple
              selectAll
            />

            <DropdownSelect
              containerClass="half-width"
              inputClass="full-width"
              name="parentEducationLevel"
              label="LEVEL OF EDUCATION (For middle school/jr high & high school this is parent’s level of education, for adult this is their level of education)"
              itemList={educationLevels}
              errorMessage={errors.parentEducationLevel?.message}
              onChange={() => setNotifyAllUsers(false)}
              size="small"
              multiple
              selectAll
            />

            <div className="bottom-buttons flex_row_jbetween">
              {editMode && (
                <Button
                  variant="outlined"
                  onClick={() => setShowDeleteConfirmDialog(true)}
                >
                  Delete Notification
                </Button>
              )}

              <div className={editMode ? 'right-side flex_jend' : 'right-side flex_jbetween'}>
                <Button
                  variant="outlined"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>

                <SubmitButton
                  className="submit-button form-button"
                  text="Save"
                  variant="contained"
                  size="large"
                  disableElevation
                />
              </div>
            </div >
          </FormProvider>
        </form>
      ) : (
        <div className="flex_jcenter_acenter loading-indicator">
          <CircularProgress size="50px" />
        </div>
      )}

      <DeleteConfirmationModal
        open={showDeleteConfirmDialog}
        deleteItemText="notification"
        onConfirm={onDeleteNotification}
        onClose={() => setShowDeleteConfirmDialog(false)}
      />

      <SendPushNotificationModal
        open={pushNotificationData !== undefined}
        onConfirm={() => pushNotificationData ? onSendPushNotification(pushNotificationData) : setPushNotificationData(undefined)}
        onClose={() => setPushNotificationData(undefined)}
      />
    </div>
  );
};

export default CreateEditNotification;
