import React from 'react';
import {
  Box,
  Button,
  Modal
} from '@mui/material';
import {
  useLocation, useNavigate
} from 'react-router-dom';
import {
  AppAssetPaths, AppRoutes
} from '../../app/app.types';

import './SessionExpired.modal.scss';
import { signOut } from '../../features/user/auth.thunks';
import { useAppDispatch } from '../../app/hooks';


export interface SessionExpiredModalProps {
  open: boolean;
  onClose: () => void;
}

const SessionExpiredModal: React.FC<SessionExpiredModalProps> = ({ open, onClose }) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const close = () => {
    onClose();
    dispatch(signOut());
    navigate(AppRoutes.signIn.path, {
      state: {
        from: location.pathname,
      },
    });
  };

  return (
    <Modal
      id="session-expired-modal"
      open={open}
      disableEscapeKeyDown
    >
      <Box className="card-background flex_col_jcenter">
        <div className="content-container flex_col_acenter">
          <img src={AppAssetPaths.icons.TIME_ICON} />
          <h3>Session Expired</h3>
          <p>Your session has expired. Sign in<br/>to continue working.</p>
          <Button
            variant="contained"
            onClick={close}
            disableElevation
          >
            Sign In
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default SessionExpiredModal;
