/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { GridSortModel } from '@mui/x-data-grid';
import {
  ConstructListMenuItem, ListMenuItem
} from '../../components/forms/form.types';
import {
  ConstructResourceTag, ResourceTag
} from '../resourceTags/resourceTags.model';
import {
  ConstructUserType, UserType
} from '../user/userType.model';


export enum ResourceType {
  scholarship = 'scholarship',
  tuition = 'tuition_waiver',
  internship = 'internship',
  opportunity = 'opportunity',
  other = 'other'
}

export interface ResourceListQueryParams {
  offset: number;
  limit?: number;
  role?: string;
  search?: string;
  orgType?: string;
  resourceType: string;
  queryType?: string;
  schoolDistricts?: number[];
  sortModel?: GridSortModel;
}


export interface Resource {
  id: number;
  title: string;
  url?: string;
  link?: string
  organization?: any;
  organizationId?: number;
  organizationName?: string;
  organizationUrl?: string;
  applicants: string;
  type: string;
  active?: boolean;
  approved?: boolean;
  startDate: string;
  endDate: string;
  createdAt: string;
  updatedAt?: string,
  updatedBy?: number;

  // Details Info
  dollarAmount?: number;
  dollarAmountRange?: number[];
  description?: string;
  details?: string;
  inApp?: boolean;
  userTypes?: UserType[];
  graduationYears: number[];
  careers?: ListMenuItem[];
  institutions?: ListMenuItem[];
  available?: number;
  availableRange?: number[];
  numberApplied?: number;
  zipcode?: string;
  tags?: ResourceTag[];
}

export interface ResourceApplication {
  id: number;
  applicantName: string;
  applicantEmail: string;
  applicantGraduationYear: number;
  applicantSchool: string;
  fieldOfInterest?: string;
  essay?: string;
  inApp: boolean;
  approved?: boolean;
}


export const EmptyResource: Resource = {
  id: 0,
  title: '',
  url: '',
  link: '',
  organization: null,
  organizationId: 0,
  organizationName: '',
  applicants: '0',
  type: ResourceType.scholarship,
  active: false,
  startDate: '',
  endDate: '',
  createdAt: '',

  // Details Info
  description: '',
  details: '',
  dollarAmount: 0,
  dollarAmountRange: [],
  available: 0,
  availableRange: [],
  inApp: false,
  userTypes: [],
  institutions: [],
  careers: [],
  graduationYears: [],
  numberApplied: 0,
  zipcode: '',
  tags: [],
};


export const ConstructResource = (data: any): Resource => {
  return {
    id: data.id,
    title: data.title,
    url: data.url || data.link,
    organization: (data.organization) ? ConstructListMenuItem(data.organization?.id || data.organization_id, data.organization.name) : null,
    organizationId: data.organization_id,
    organizationName: (data.organization && data.organization.name) ? data.organization.name : data.organization_name,
    organizationUrl: (data.organization && data.organization.name) ? data.organization.url : null,
    applicants: data.no_of_applicants,
    type: data.type,
    active: data.active || data.is_active,
    approved: data.approved,
    startDate: data.start_date,
    endDate: data.end_date,
    createdAt: data.created_at,
    updatedAt: data.updated_at,
    updatedBy: data.updated_by,

    description: data.description || data.details,
    dollarAmount: data.dollar_amount,
    dollarAmountRange: data.dollar_amount_range,
    available: data.number_available,
    availableRange: data.number_available_range,
    inApp: data.in_app,
    userTypes: (data.user_types && data.user_types.map((e: any) => ConstructUserType(e))),
    institutions: (data.schools && data.schools.map((e: any) => ConstructListMenuItem(e.id, e.name))),
    careers: (data.career_groups && data.career_groups.map((e: any) => ConstructListMenuItem(e.id, e.title))),
    graduationYears: data.graduation_years || [],
    numberApplied: data.no_of_applicants,
    zipcode: data.zipcode,
    tags: data?.resource_tags && data.resource_tags.map((e: any) => ConstructResourceTag(e)),
  };
};

export const ConstructResourceApplication = (data: any): ResourceApplication => {
  return {
    id: data.id,
    applicantName: data.name,
    applicantEmail: data.email,
    applicantGraduationYear: data.graduation_year,
    applicantSchool: data.school,
    fieldOfInterest: data.field_of_interest,
    essay: data.essay,
    inApp: data.in_app,
    approved: data?.approved ,
  };
};

export const getResourceType = (resource: string): string => {
  if (resource === ResourceType.scholarship || resource === ResourceType.tuition) {
    return 'scholarships';
  }
  else {
    return 'opportunities';
  }
};

export const getResourceQueryType = (resource: string): string => {
  if (resource === ResourceType.scholarship || resource === ResourceType.tuition) {
    return 'current';
  }
  else if (resource === ResourceType.internship) {
    return 'internship';
  }
  else {
    return 'other';
  }
};
