import React, {
  useEffect, useState
} from 'react';
import './CreateEditResourceTag.scss';
import * as yup from 'yup';
import { Box } from '@mui/system';
import {
  useNavigate, useParams
} from 'react-router-dom';
import {
  Button, CircularProgress
} from '@mui/material';
import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import { resourceTagSelectors } from '../../../features/resourceTags/resourceTags.slice';
import {
  FormProvider, SubmitHandler, useForm
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AppRoutes, StateStatus
} from '../../../app/app.types';
import TextInput from '../../../components/forms/TextInput';
import SubmitButton from '../../../components/forms/SubmitButton';
import {
  createResourceTag, deleteResourceTag, getResourceTagById, updateResourceTag
} from '../../../features/resourceTags/resourceTags.thunks';
import { setAlert } from '../../../features/alert/alert.slice';
import DeleteConfirmationModal from '../../../components/modal/DeleteConfirmationModal';



interface CreateEditResourceTagProps { }

const CreateEditResourceTag: React.FC<CreateEditResourceTagProps> = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const tagDetails = useAppSelector(resourceTagSelectors.selectResourceTagDetails);
  const tagStatus = useAppSelector(resourceTagSelectors.selectResourceTagDetailsStatus);


  const [
    editMode,
    setEditMode,
  ] = useState<boolean>(false);
  const [
    pageLoaded,
    setPageLoaded,
  ] = useState<boolean>(false);
  const [
    showDeleteModal,
    setShowDeleteModal,
  ] = useState<boolean>(false);
  const [
    descriptionLength,
    setDescriptionLength,
  ] = useState<number>(0);


  useEffect(() => {
    if (params && params.id) {
      dispatch(getResourceTagById(+params.id));
      setEditMode(true);
    } else {
      setPageLoaded(true);
    }
  }, []);

  useEffect(() => {
    if ((params && params.id && +params.id !== tagDetails.id) || pageLoaded) return;

    if (editMode) {
      setValue('name', tagDetails.name);
      setValue('description', tagDetails.description);
      setDescriptionLength(tagDetails.description.length);
      setPageLoaded(true);
    }
  }, [
    editMode,
    tagDetails,
  ]);


  const formValidationSchema = yup.object().shape({
    name: yup.string()
      .required('Name is required')
      .typeError('Name is required')
      .max(25, 'A max of 25 characters is allowed'),
    description: yup.string()
      .required('Description is required')
      .typeError('Description is required')
      .max(750, 'A max of 750 characters is allowed'),
  }).required();

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const { handleSubmit, setValue, formState: { errors } } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    const submitBody = {
      name: values.name,
      description: values.description,
    };

    if (editMode) {
      dispatch(updateResourceTag({
        id: tagDetails.id,
        body: submitBody,
      })).unwrap()
        .then(() => {
          dispatch(setAlert({
            type: 'success',
            message: 'Successfully updated tag.',
          }));
          navigate(AppRoutes.resourceTags.path);
        })
        .catch(() => {
          dispatch(setAlert({
            type: 'error',
            message: 'Unable to update tag.',
          }));
        });
    }
    else {
      dispatch(createResourceTag(submitBody))
        .unwrap()
        .then(() => {
          dispatch(setAlert({
            type: 'success',
            message: 'Successfully created tag.',
          }));
          navigate(AppRoutes.resourceTags.path);
        })
        .catch(() => {
          dispatch(setAlert({
            type: 'error',
            message: 'Unable to create tag.',
          }));
        });
    }
  };

  const handleDeleteResourceTag = () => {
    setShowDeleteModal(false);
    if (!tagDetails.id) return;

    dispatch(deleteResourceTag(tagDetails.id))
      .unwrap()
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully deleted tag.',
        }));
        navigate(AppRoutes.resourceTags.path);
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to delete tag.',
        }));
      });
  };



  return (
    <div id="resource-tag-form">
      <Box className="card-background">
        <h3> {editMode ? 'Edit' : 'New'} Tag</h3>

        {(tagStatus === StateStatus.IDLE && pageLoaded) ? (
          <form onSubmit={handleSubmit(submitForm)}>
            <FormProvider {...methods}>
              <TextInput
                containerClass="half-width"
                name="name"
                errorMessage={errors?.name?.message}
                label="Name"
                type="text"
                size="small"
                required
              />

              <TextInput
                name="description"
                errorMessage={errors?.description?.message}
                label="Description"
                type="textarea"
                size="small"
                required
                multiLine
                rows={5}
                onChange={(e) => setDescriptionLength(e.target.value.length)}
              />
              <p className="description-count">{descriptionLength} / 750</p>

              <div className="buttons-wrapper flex_row_jbetween">
                {editMode && (
                  <Button
                    variant="outlined"
                    onClick={() => setShowDeleteModal(true)}
                  >
                    Delete Tag
                  </Button>
                )}

                <div className={editMode ? 'right-side flex_jend' : 'right-side flex_jbetween'}>
                  <Button
                    variant="outlined"
                    onClick={() => navigate(AppRoutes.resourceTags.path)}
                  >
                    Cancel
                  </Button>

                  <SubmitButton
                    className="submit-button form-button"
                    text="Save"
                    variant="contained"
                    size="large"
                    disableElevation
                  />
                </div>

              </div>
            </FormProvider>
          </form>
        ) : (
          <div className="flex_jcenter_acenter loading-indicator">
            <CircularProgress size="50px" />
          </div>
        )}
      </Box>

      <DeleteConfirmationModal
        open={showDeleteModal}
        deleteItemText="resource tag"
        onConfirm={handleDeleteResourceTag}
        onClose={() => setShowDeleteModal(false)}
      />

    </div>
  );

};

export default CreateEditResourceTag;