import React, { ReactElement } from 'react';
import ExpandableCard from '../ExpandableCard';
import {
  PathwayCourse, PathwayCourseSchool
} from '../../../features/pathways/pathways.model';
import { addUrlProtocol } from '../../../util/formatting';

import './CourseTable.scss';


interface PostSecondaryResourcesTableProps {
  courses?: PathwayCourse[];
  defaultExpanded?: boolean;
  getCourseActiveStatus: (id?: number, active?: boolean, pathwayCourseSchool?: PathwayCourseSchool) => ReactElement;
}

const PostSecondaryResourcesTable: React.FC<PostSecondaryResourcesTableProps> = ({ courses, defaultExpanded, getCourseActiveStatus }) => {
  const EMPTY = '-';

  if (!courses) {
    courses = [];
  }

  const getDataRow = (header: boolean, course?: PathwayCourse) => (
    <div key={course?.id} className={`data-row flex_jstretch_acenter ${header ? 'header' : ''}`}>
      <div className="data-cell name">
        <div className="data-label">Course Name</div>
        <div className="data-content">{course?.name || EMPTY}</div>
      </div>
      <div className="data-cell link">
        <div className="data-label">Link</div>
        <div className="data-content">
          {course?.link ? (
            <a href={addUrlProtocol(course?.link)} target="_blank" rel="noreferrer noopener">
              <p>{course?.link}</p>
            </a>
          ) : (
            <p>{EMPTY}</p>
          )}
        </div>
      </div>
      <div className="data-cell active">
        <div className="data-label" />
        <div className="data-content">
          {getCourseActiveStatus(course?.id, course?.active, course?.pathwayCourseSchool)}
        </div>
      </div>
    </div>
  );

  const getDetails = () => (
    <div className="data-wrapper">
      {getDataRow(true)}
      {!!courses?.length && courses.map(c => getDataRow(false, c))}
    </div>
  );

  return (
    <ExpandableCard
      className="course-table"
      summary={<span>Post-Secondary Resources</span>}
      details={getDetails()}
      expandable
      defaultExpanded={defaultExpanded}
    />
  );
};

export default PostSecondaryResourcesTable;
