
interface UnitedState {
  id: string;
  label: string;
  abbreviation: string;
}

export const StateAbbreviations: UnitedState[] = [
  {
    id: 'UT',
    label: 'Utah',
    abbreviation: 'UT',
  },
  {
    id: 'AL',
    label: 'Alabama',
    abbreviation: 'AL',
  },
  {
    id: 'AK',
    label: 'Alaska',
    abbreviation: 'AK',
  },
  {
    id: 'AS',
    label: 'American Samoa',
    abbreviation: 'AS',
  },
  {
    id: 'AZ',
    label: 'Arizona',
    abbreviation: 'AZ',
  },
  {
    id: 'AR',
    label: 'Arkansas',
    abbreviation: 'AR',
  },
  {
    id: 'CA',
    label: 'California',
    abbreviation: 'CA',
  },
  {
    id: 'CO',
    label: 'Colorado',
    abbreviation: 'CO',
  },
  {
    id: 'CT',
    label: 'Connecticut',
    abbreviation: 'CT',
  },
  {
    id: 'DE',
    label: 'Delaware',
    abbreviation: 'DE',
  },
  {
    id: 'DC',
    label: 'District Of Columbia',
    abbreviation: 'DC',
  },
  {
    id: 'FM',
    label: 'Federated States Of Micronesia',
    abbreviation: 'FM',
  },
  {
    id: 'FL',
    label: 'Florida',
    abbreviation: 'FL',
  },
  {
    id: 'GA',
    label: 'Georgia',
    abbreviation: 'GA',
  },
  {
    id: 'GU',
    label: 'Guam',
    abbreviation: 'GU',
  },
  {
    id: 'HI',
    label: 'Hawaii',
    abbreviation: 'HI',
  },
  {
    id: 'ID',
    label: 'Idaho',
    abbreviation: 'ID',
  },
  {
    id: 'IL',
    label: 'Illinois',
    abbreviation: 'IL',
  },
  {
    id: 'IN',
    label: 'Indiana',
    abbreviation: 'IN',
  },
  {
    id: 'IA',
    label: 'Iowa',
    abbreviation: 'IA',
  },
  {
    id: 'KS',
    label: 'Kansas',
    abbreviation: 'KS',
  },
  {
    id: 'KY',
    label: 'Kentucky',
    abbreviation: 'KY',
  },
  {
    id: 'LA',
    label: 'Louisiana',
    abbreviation: 'LA',
  },
  {
    id: 'ME',
    label: 'Maine',
    abbreviation: 'ME',
  },
  {
    id: 'MH',
    label: 'Marshall Islands',
    abbreviation: 'MH',
  },
  {
    id: 'MD',
    label: 'Maryland',
    abbreviation: 'MD',
  },
  {
    id: 'MA',
    label: 'Massachusetts',
    abbreviation: 'MA',
  },
  {
    id: 'MI',
    label: 'Michigan',
    abbreviation: 'MI',
  },
  {
    id: 'MN',
    label: 'Minnesota',
    abbreviation: 'MN',
  },
  {
    id: 'MS',
    label: 'Mississippi',
    abbreviation: 'MS',
  },
  {
    id: 'MO',
    label: 'Missouri',
    abbreviation: 'MO',
  },
  {
    id: 'MT',
    label: 'Montana',
    abbreviation: 'MT',
  },
  {
    id: 'NE',
    label: 'Nebraska',
    abbreviation: 'NE',
  },
  {
    id: 'NV',
    label: 'Nevada',
    abbreviation: 'NV',
  },
  {
    id: 'NH',
    label: 'New Hampshire',
    abbreviation: 'NH',
  },
  {
    id: 'NJ',
    label: 'New Jersey',
    abbreviation: 'NJ',
  },
  {
    id: 'NM',
    label: 'New Mexico',
    abbreviation: 'NM',
  },
  {
    id: 'NY',
    label: 'New York',
    abbreviation: 'NY',
  },
  {
    id: 'NC',
    label: 'North Carolina',
    abbreviation: 'NC',
  },
  {
    id: 'ND',
    label: 'North Dakota',
    abbreviation: 'ND',
  },
  {
    id: 'MP',
    label: 'Northern Mariana Islands',
    abbreviation: 'MP',
  },
  {
    id: 'OH',
    label: 'Ohio',
    abbreviation: 'OH',
  },
  {
    id: 'OK',
    label: 'Oklahoma',
    abbreviation: 'OK',
  },
  {
    id: 'OR',
    label: 'Oregon',
    abbreviation: 'OR',
  },
  {
    id: 'PW',
    label: 'Palau',
    abbreviation: 'PW',
  },
  {
    id: 'PA',
    label: 'Pennsylvania',
    abbreviation: 'PA',
  },
  {
    id: 'PR',
    label: 'Puerto Rico',
    abbreviation: 'PR',
  },
  {
    id: 'RI',
    label: 'Rhode Island',
    abbreviation: 'RI',
  },
  {
    id: 'SC',
    label: 'South Carolina',
    abbreviation: 'SC',
  },
  {
    id: 'SD',
    label: 'South Dakota',
    abbreviation: 'SD',
  },
  {
    id: 'TN',
    label: 'Tennessee',
    abbreviation: 'TN',
  },
  {
    id: 'TX',
    label: 'Texas',
    abbreviation: 'TX',
  },
  {
    id: 'VT',
    label: 'Vermont',
    abbreviation: 'VT',
  },
  {
    id: 'VI',
    label: 'Virgin Islands',
    abbreviation: 'VI',
  },
  {
    id: 'VA',
    label: 'Virginia',
    abbreviation: 'VA',
  },
  {
    id: 'WA',
    label: 'Washington',
    abbreviation: 'WA',
  },
  {
    id: 'WV',
    label: 'West Virginia',
    abbreviation: 'WV',
  },
  {
    id: 'WI',
    label: 'Wisconsin',
    abbreviation: 'WI',
  },
  {
    id: 'WY',
    label: 'Wyoming',
    abbreviation: 'WY',
  },
];