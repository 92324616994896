/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HTTP_METHOD, requestFactory
} from '../../app/app.api';
import { courseFormatDropdownList } from '../../util/dropdown';
import {
  ConstructSkillTraining, SkillTraining, SkillTrainingListQueryParams
} from './skillTrainings.model';


export const getSkillTrainingList = async (query: SkillTrainingListQueryParams): Promise<{ skillTrainings: SkillTraining[], count: number }> => {
  const limitValue = query.limit || 100;
  const searchParam = query.search ? `&search=${query.search}` : '';
  let orderBy = query.sortModel?.length ? `&order_by=${query.sortModel[0].field}` : '';
  const orderDirecton = query.sortModel?.length ? `&order_direction=${query.sortModel[0].sort}` : '';

  // Convert camelcase values to underscore to match database
  orderBy = orderBy.replace('createdAt', 'created_at');
  orderBy = orderBy.replace('status', 'active');

  const url = `/skillup-courses/admin?offset=${query.offset}&limit=${limitValue}${searchParam}${orderBy}${orderDirecton}`;

  const { data } = await requestFactory(HTTP_METHOD.GET, url);

  const skillTrainings = data.data.map((ele: any) => ConstructSkillTraining(ele));

  return {
    skillTrainings,
    count: data.count,
  };
};

export const GetSkillTrainingById = async (id: number): Promise<SkillTraining> => {
  const { data } = await requestFactory(HTTP_METHOD.GET, `/skillup-courses/${id}`);

  return await ConstructSkillTraining(data.data);
};

// Check for duplicate Skill Trainings
export const duplicateSkillTrainingCheck = async (body: { name: string, link: string }): Promise<SkillTraining[]> => {

  const { data } = await requestFactory(HTTP_METHOD.POST, '/skillup-courses/check-for-duplicates', undefined, body);

  if (data.data && !!data.data.length) {
    return data.data.map((ele: any) => ConstructSkillTraining(ele));
  }
  else {
    return [];
  }
};

// Create skill training
export const createSkillTraining = async (values: any): Promise<SkillTraining> => {
  if (values.courseFormat.length) {
    values.courseFormat = values.courseFormat.filter((x: any) => x !== undefined);
  }

  const submitBody = {
    ...values,
    link: values.url,
    is_free: values.isFree,
    is_discounted: values.isDiscounted,
    is_learn_and_work: values.isLearnAndWork,
    start_date: values.ongoing ? null : values.startDate,
    end_date: values.ongoing ? null : values.endDate,
    duration_value: values.durationValue,
    duration_unit: values.durationUnit.value,
    online_self_paced: values.courseFormat.some((c: any) => c.value == courseFormatDropdownList[0].value),
    online_instructor_lead: values.courseFormat.some((c: any) => c.value == courseFormatDropdownList[1].value),
    in_person_instructor_lead: values.courseFormat.some((c: any) => c.value == courseFormatDropdownList[2].value),
    hybrid: values.courseFormat.some((c: any) => c.value == courseFormatDropdownList[3].value),
    skill_ids: values.skills.map((ele: any) => ele.id),
    zipcode: values.zipcode?.zipcode || '',
  };

  const { data } = await requestFactory(HTTP_METHOD.POST, '/skillup-courses', undefined, submitBody);

  if (values.logo && !!values.logo.length) {
    await uploadSkillTrainingLogo(data.data.id, values.logo[0]);
  }

  return data.data;
};

// Update Skill Training
export const updateSkillTraining = async (id: number, values: any): Promise<SkillTraining> => {
  if (values.courseFormat.length) {
    values.courseFormat = values.courseFormat.filter((x: any) => x !== undefined);
  }
  const submitBody = {
    ...values,
    link: values.url,
    is_free: values.isFree,
    is_discounted: values.isDiscounted,
    is_learn_and_work: values.isLearnAndWork,
    start_date: values.ongoing ? null : values.startDate,
    end_date: values.ongoing ? null : values.endDate,
    duration_value: values.durationValue,
    duration_unit: values.durationUnit.value,
    online_self_paced: values.courseFormat.some((c: any) => c.value == courseFormatDropdownList[0].value),
    online_instructor_lead: values.courseFormat.some((c: any) => c.value == courseFormatDropdownList[1].value),
    in_person_instructor_lead: values.courseFormat.some((c: any) => c.value == courseFormatDropdownList[2].value),
    hybrid: values.courseFormat.some((c: any) => c.value == courseFormatDropdownList[3].value),
    skill_ids: values.skills.map((ele: any) => ele.id),
    zipcode: values.zipcode?.zipcode || '',
  };

  const { data } = await requestFactory(HTTP_METHOD.PATCH, `/skillup-courses/${id}`, undefined, submitBody);

  if (values.logo && !!values.logo.length) {
    await uploadSkillTrainingLogo(data.data.id, values.logo[0]);
  }

  return data.data;
};

// Delete SkillTraining
export const deleteSkillTraining = async (id: number): Promise<any> => {
  const response = await requestFactory(HTTP_METHOD.DELETE, `/skillup-courses/${id}`);
  return response;
};

export const uploadSkillTrainingLogo = async (id: string, image: string | Blob) => {
  const newData = new FormData();
  newData.append('files', image);
  newData.append('resource_id', id);
  newData.append('resource_name', 'skillup_course');

  const response = await requestFactory(HTTP_METHOD.POST, '/assets/upload', undefined, newData);
  return await response.data;
};

export const approveSkillTraining = async (id: number): Promise<boolean> => {
  const { data } = await requestFactory(HTTP_METHOD.PATCH, `/skillup-courses/${id}/approve`);
  return data.approved;
};

export const rejectSkillTraining = async (id: number): Promise<void> => {
  await requestFactory(HTTP_METHOD.DELETE, `/skillup-courses/${id}/reject`);
};
