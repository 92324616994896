import React, { useEffect } from 'react';
import {
  Box, Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  AppRoutes, canUseAuthComponent
} from '../../../../app/app.types';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  useAppDispatch, useAppSelector
} from '../../../../app/hooks';
import { userSelectors } from '../../../../features/user/user.slice';
import { collegeApplicationChecklistSelectors } from '../../../../features/collegeApplicationChecklist/collegeApplicationChecklist.slice';
import { getFinancialAidInfoSections } from '../../../../features/collegeApplicationChecklist/collegeApplicationChecklist.thunks';
import { toWords } from 'number-to-words';
import { createDetailsSpan } from '../../../../util/sharedHTML';

import './FinancialAidInfoDetails.scss';


interface FinancialAidInfoDetailsProps { }

const FinancialAidInfoDetails: React.FC<FinancialAidInfoDetailsProps> = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(userSelectors.selectUser);
  const financialAidInfoSections = useAppSelector(collegeApplicationChecklistSelectors.selectFinancialAidInfoSections);
  const financialAidInfoSectionsLoaded = useAppSelector(collegeApplicationChecklistSelectors.selectFinancialAidInfoSectionsLoaded);

  useEffect(() => {
    if (!financialAidInfoSectionsLoaded) {
      dispatch(getFinancialAidInfoSections());
    }
  }, []);

  return (
    <div id="financial-aid-info-details">
      <Box className="card-background flex_col">
        <div className="content-header flex_row_jbetween">
          <span
            className="cursor-pointer flex_row_jstart_acenter"
            onClick={() => navigate(AppRoutes.collegeApplicationChecklistFinancialAidList.path)}
          >
            <ChevronLeftIcon className="back-icon" color="secondary" />
            <h3>Financial Aid Information</h3>
          </span>

          <span className="flex_row_jstart_acenter">
            {canUseAuthComponent(user.roles, AppRoutes.editFinancialAidInfo.authorizedRoles) && (
              <Button
                className="edit-button"
                variant="outlined"
                onClick={() => navigate(AppRoutes.editFinancialAidInfo.path)}
              >
                Edit
              </Button>
            )}
          </span>
        </div>

        <div className="details-container">
          {financialAidInfoSections.map((f) => (
            <div key={f.id} className="detail-section">
              <h3 className="section-header">Section {toWords(f.id)}</h3>
              {createDetailsSpan('Section Name', f.name)}
              {createDetailsSpan('Section Description', f.description)}
              {createDetailsSpan('Section Url', f.url)}
            </div>
          ))}
        </div>
      </Box>
    </div>
  );
};

export default FinancialAidInfoDetails;
