import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  MasterKey,
  MasterKeyListQueryParams,
  MasterKeySubmissionListQueryParams
} from './masterKey.model';

import * as masterKeyApi from './masterKey.api';


export const getMasterKeyList = createAsyncThunk(
  'masterKey/getMasterKeyList',
  async (query: MasterKeyListQueryParams) => {
    return await masterKeyApi.getMasterKeyList(query);
  }
);

export const getMasterKeyById = createAsyncThunk(
  'masterKey/masterKey',
  async (masterKeyId: number) => {
    return await masterKeyApi.getMasterKeyById(masterKeyId);
  }
);

export const deleteMasterKeyById = createAsyncThunk(
  '/masterKey/deleteMasterKey',
  async (masterKeyId: number) => {
    return await masterKeyApi.deleteMasterKeyById(masterKeyId);
  }
);

export const getMasterKeySubmissionList = createAsyncThunk(
  'masterKey/getMasterKeySubmissionList',
  async (query: MasterKeySubmissionListQueryParams) => {
    return await masterKeyApi.getMasterKeySubmissionList(query);
  }
);

export const getMasterKeySubmissionById = createAsyncThunk(
  'masterKey/getMasterKeySubmissionDetails',
  async (masterKeySubmissionId: number) => {
    return await masterKeyApi.getMasterKeySubmissionById(masterKeySubmissionId);
  }
);

export const getMasterKeyDetails = createAsyncThunk(
  'masterKey/getMasterKeyDetails',
  async (masterKeyId: number) => {
    return await masterKeyApi.getMasterKeyDetails(masterKeyId);
  }
);

export const createMasterKey = createAsyncThunk(
  'masterKey/createMasterKey',
  async (body: Partial<MasterKey>) => {
    return await masterKeyApi.createMasterKey(body);
  }
);

export const editMasterKey = createAsyncThunk(
  'masterKey/editMasterKey',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (body: { id: number, values: any }) => {
    return await masterKeyApi.editMasterKey(body.id, body.values);
  }
);

export const archiveMasterKey = createAsyncThunk(
  'masterKey/archiveMasterKey',
  async (id: number) => {
    return await masterKeyApi.archiveMasterKey(id);
  }
);

export const getMasterKeyTypeList = createAsyncThunk(
  'masterKey/getMasterKeyTypeList',
  async () => {
    return await masterKeyApi.getMasterKeyTypeList();
  }
);
