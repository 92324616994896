import {
  Box,
  Tab, Tabs
} from '@mui/material';
import React, {
  useEffect, useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import { canUseAuthComponent } from '../../app/app.types';
import {
  useAppDispatch, useAppSelector
} from '../../app/hooks';
import {
  ApprovalSummary, ApprovalSummaryByType
} from '../../features/dashboard/dashboard.model';
import { dashboardSelectors } from '../../features/dashboard/dashboard.slice';
import { getApprovalsSummary } from '../../features/dashboard/dashboard.thunk';
import {
  ApprovalSubItem, ApprovalSummaryTabs, ApprovalTab
} from '../../features/dashboard/types/approvalSummary.types';
import { userSelectors } from '../../features/user/user.slice';
import './ApprovalsSummaryCard.scss';


interface ApprovalsSummaryCardProps { }

const ApprovalsSummaryCard: React.FC<ApprovalsSummaryCardProps> = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(userSelectors.selectUser);
  const approvals = useAppSelector(dashboardSelectors.selectApprovalSummary);

  const [
    selectedTab,
    setSelectedTab,
  ] = useState(0);

  useEffect(() => {
    dispatch(getApprovalsSummary(user.roles));
  }, []);

  return (
    <div id="approval-summary-card" className="card-background">
      <h3 className="card-header">Approvals</h3>
      <Tabs value={selectedTab}
        onChange={(_, newValue) => setSelectedTab(newValue)}
        variant="fullWidth"
      >
        {ApprovalSummaryTabs.filter(a => canUseAuthComponent(user.roles, a.authorizedRoles)).map((tab: ApprovalTab, i: number) => (
          <Tab id="dashboard-tab"
            label={tab.title}
            wrapped={false}
            key={i}
          />
        ))}
      </Tabs>

      {ApprovalSummaryTabs.filter(a => canUseAuthComponent(user.roles, a.authorizedRoles)).map((tab: ApprovalTab, i: number) => (
        <div role="tabpanel" hidden={selectedTab !== i} key={i}>
          <Box className="tab-content">
            {tab.cards.map((card: ApprovalSubItem, j: number) => (
              canUseAuthComponent(user.roles, card.authorizedRoles) && (
                (approvals[tab.dataKey as keyof ApprovalSummaryByType] &&
                  (approvals[tab.dataKey as keyof ApprovalSummaryByType][card.amountKey as keyof ApprovalSummary] ||
                    approvals[tab.dataKey as keyof ApprovalSummaryByType][card.amountKey as keyof ApprovalSummary] == 0)) &&
                (
                  <div className="subcard flex_row_jstart_acenter"
                    key={j}
                    onClick={() => navigate(card.route)}
                  >
                    <img src={card.icon} />
                    <div className="flex_column_jstart">
                      <p>{card.title}</p>
                      <p>{approvals[tab.dataKey as keyof ApprovalSummaryByType][card.amountKey as keyof ApprovalSummary]}</p>
                    </div>
                  </div>
                )
              )
            ))}
          </Box>
        </div>
      ))}
    </div>
  );
};

export default ApprovalsSummaryCard;
