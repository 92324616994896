/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { GridSortModel } from '@mui/x-data-grid';

export interface ResourceTag {
  id: number;
  name: string;
  label: string;
  description: string;
  timesUsed: number;
  createdAt: string;
}

export interface CreateEditResourceTag {
  id?: number;
  name: string;
  description: string;
  timesUsed?: number;
  createdAt?: string;
}

export interface ResourceTagListQueryParams {
  offset: number;
  limit?: number;
  search?: string;
  sortModel?: GridSortModel;
}

export const EmptyResourceTag: ResourceTag = {
  id: 0,
  name: '',
  label: '',
  description: '',
  timesUsed: 0,
  createdAt: '',
};

export const ConstructResourceTag = (data: any): ResourceTag => {
  return {
    id: data.id,
    name: data.name,
    label: data.name,
    description: data.description,
    timesUsed: data.times_used,
    createdAt: data.created_at,
  };
};
