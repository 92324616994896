/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as yup from 'yup';
import {
  isStrongPassword, isValidUrl
} from '../validation';


export const passwordRequrementsMessage = 'Password must be 10-20 characters & contain an uppercase, lowercase, number & special character';

export const registerCustomValidations = () => {
  // Strong password
  yup.addMethod<yup.StringSchema>(yup.string, 'isStrongPassword', function (errorMessage?: string) {
    const defaultErrorMessage = passwordRequrementsMessage;

    return this.test('isStrongPassword', errorMessage = errorMessage || defaultErrorMessage, function (value) {
      const { path, createError } = this;

      return (
        value && isStrongPassword(value) ||
        createError({
          path,
          message: errorMessage,
        })
      );
    });
  });

  // Valid URL
  yup.addMethod<yup.StringSchema>(yup.string, 'isValidUrl', function (errorMessage?: string) {
    const defaultErrorMessage = 'Website url is invalid';

    return this.test('isValidUrl', errorMessage = errorMessage || defaultErrorMessage, function (value) {
      const { path, createError } = this;

      return (
        !value || (value && isValidUrl(value)) ||
        createError({
          path,
          message: errorMessage,
        })
      );
    });
  });
};
