import React, { useEffect } from 'react';
import AppRouter from './app/routing/AppRouter';

import './App.scss';
import './globalStyles/GlobalStyles.scss';
import {
  useAppDispatch, useAppSelector
} from './app/hooks';
import { getUser } from './features/user/user.thunks';
import { getSessionToken } from './util/localStorage';
import { userSelectors } from './features/user/user.slice';
// Material UI uses Roboto as it's font and recommends using it as their components are built with it in mind.
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ThemeProvider } from '@mui/material';
import { registerCustomValidations } from './util/yup/yup_validation';
import { BrowserRouter } from 'react-router-dom';
import { getStudentAppBaseUrl } from './app/app.api';
import { DashboardQuickLinks } from './features/dashboard/types/quickLink.types';
import { theme } from './globalStyles/AppTheme';
import { AppLinks } from './app/app.types';
import GlobalModals from './components/modal/GlobalModals';
import './globalStyles/variables.css';


interface AppProps { }

const App: React.FC<AppProps> = () => {

  const dispatch = useAppDispatch();

  const userIsLoaded = useAppSelector(userSelectors.selectUserLoaded);

  // Set up custom yup validations
  registerCustomValidations();

  const appInit = async () => {
    const isAuthenticated = !!getSessionToken();

    // Update student app base route in the quicklinks to match correct environment
    getStudentAppBaseUrl().then(response => {
      if (!response) return;

      const studentAppQuickLink = DashboardQuickLinks.find(l => l.route === AppLinks.STUDENT_APP_GUEST_SIGNIN);

      if (studentAppQuickLink?.route) {
        studentAppQuickLink.route = studentAppQuickLink.route.replace(AppLinks.STUDENT_APP, response);
      }
    });

    if (isAuthenticated && !userIsLoaded) {
      dispatch(getUser());
    }
  };

  useEffect(() => {
    appInit();
  }, []);


  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AppRouter />

        <GlobalModals />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
