import { createAsyncThunk } from '@reduxjs/toolkit';
import { NotificationListQueryParams } from './notifications.model';

import * as notificationsApi from './notifications.api';

export const getNotificationList = createAsyncThunk(
  'notifications/getNotificationList',
  async (query: NotificationListQueryParams) => {
    return await notificationsApi.getNotificationList(query);
  }
);

export const getNotificationById = createAsyncThunk(
  'notifications/notification',
  async (notificationId: number) => {
    return await notificationsApi.getNotificationById(notificationId);
  }
);

export const approveNotification = createAsyncThunk(
  'notifications/approve',
  async (notificationId: number) => {
    return await notificationsApi.approveNotification(notificationId);
  }
);

export const rejectNotification = createAsyncThunk(
  'notifications/reject',
  async (notificationId: number) => {
    return await notificationsApi.rejectNotification(notificationId);
  }
);
