import {
  HTTP_METHOD, requestFactory
} from '../../app/app.api';
import {
  ConstructScholarship, Scholarship, ConstructScholarshipApplicationDetails, ScholarshipApplicationDetails
} from './scholarship.model';


export const getScholarshipById = async (id: number): Promise<Scholarship> => {
  const { data } = await requestFactory(HTTP_METHOD.GET, `/scholarships/${id}`);

  const resource = ConstructScholarship(data.data);

  return resource;
};

export const getScholarshipApplicationDetails = async (id: number, userId: string): Promise<ScholarshipApplicationDetails> => {

  const url = `/scholarships/application?scholarship_id=${id}&user_id=${userId}`;
  const { data } = await requestFactory(HTTP_METHOD.GET, url);

  return ConstructScholarshipApplicationDetails(data.data);
};

export const approveScholarshipApplications = async (ids: number[], userId: string): Promise<number[]> => {
  const request = {
    ids: ids,
    user: {
      id: userId,
    },
  };

  const url = '/approvals/approve-applications';
  await requestFactory(HTTP_METHOD.POST, url, undefined, request);

  return ids;
};

export const rejectScholarshipApplications = async (ids: number[], userId: string): Promise<number[]> => {
  const request = {
    ids: ids,
    user: {
      id: userId,
    },
  };

  const url = '/approvals/reject-applications';
  await requestFactory(HTTP_METHOD.POST, url, undefined, request);

  return ids;
};
