/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { GridSortModel } from '@mui/x-data-grid';
import {
  ConstructListMenuItem, ListMenuItem
} from '../../components/forms/form.types';
import {
  CareerGroup, ConstructCareerGroup
} from '../careers/careers.model';
import {
  ConstructUser, User
} from '../user/user.model';


export const postSecondaryDistrict = 'Post-Secondary Education';

export enum OrganizationType {
  Community = 'community',
  School = 'school',
  HighSchool = 'high_school',
  MiddleSchool = 'middle_school',
  Adult = 'adult_school',
  NoSchool = 'no_school',
  UniversityCollege = 'university_college',
  schoolDistrict = 'school_district',
  // Secondary Education and Post Secondary Education types are only applicable to the announcement create/edit form.
  secondaryEducation = 'secondary_education',
  postSecondaryEducation = 'post_secondary_education'
}

export interface Organization {
  id: number;
  name: string;
  label: string;
  images?: string[];
  url: string;
  type?: OrganizationType;
  createdAt?: string;
  schoolDistrict?: Organization;
  communityUser?: User;
  communityOrganizationType?: ListMenuItem;
  communityOrganizationCareerGroups?: CareerGroup[];
}

export interface NewOrganization {
  id?: number;
  name: string;
  schoolDistrict?: Organization;
  type: any;
  url?: string;
  logo?: FileList;
  contactEmail?: string;
  contactFirstName?: string;
  contactLastName?: string;
  contactPhoneNumber?: string;
  careerGroups?: ListMenuItem[];
  organizationType: ListMenuItem;
}

export const EmptyOrganization: Organization = {
  id: 0,
  name: '',
  label: '',
  url: '',
};

export const ConstructOrganization = (data: any): Organization => {
  return {
    id: data.id || NaN,
    name: data.name || '',
    // dropdown lists for organization require the label key in order to work
    label: data.name || '',
    images: data.images && data.images.length ? data.images : [],
    url: data.url || '',
    type: data.type,
    createdAt: data.createdAt,
    schoolDistrict: data.school_district,
    communityUser: data.community_user && ConstructUser(data.community_user),
    communityOrganizationType: data.community_organization_type && ConstructListMenuItem(data.community_organization_type.id, data.community_organization_type.title),
    communityOrganizationCareerGroups: data.community_user?.career_groups?.map((c: any) => ConstructCareerGroup(c)),
  };
};

export interface OrganizationListQueryParams {
  offset: number;
  limit?: number | string;
  type?: string;
  search?: string;
  schoolType?: string;
  schoolDistricts?: number[];
  sortModel?: GridSortModel;
}
