import { Button } from '@mui/material';
import React, {
  useEffect, useState
} from 'react';
import { useNavigate } from 'react-router-dom';

import {
  useAppDispatch, useAppSelector
} from '../../app/hooks';
import { AppRoutes } from '../../app/app.types';

import EventRoundedIcon from '@mui/icons-material/EventRounded';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';

import { dashboardSelectors } from '../../features/dashboard/dashboard.slice';
import { getLatestAnnouncements } from '../../features/dashboard/dashboard.thunk';
import dayjs from 'dayjs';
import { UserType } from '../../features/user/userType.model';
import { OrganizationType } from '../../features/organization/organization.model';

import './LatestAnnouncementsCard.scss';


interface LatestAnnouncementsCardProps { }

const LatestAnnouncementsCard: React.FC<LatestAnnouncementsCardProps> = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const announcements = useAppSelector(dashboardSelectors.selectLatestAnnouncements);

  useEffect(() => {
    dispatch(getLatestAnnouncements());
  }, []);

  const [
    activeIndex,
    setActiveIndex,
  ] = useState(0);

  const prevAnnouncement = () => {
    setActiveIndex((activeIndex + announcements.length - 1) % announcements.length);
  };

  const nextAnnouncement = () => {
    setActiveIndex((activeIndex + 1) % announcements.length);
  };

  const getGroupLabel = (key: number, type?: string) => {
    if (type === OrganizationType.MiddleSchool) {
      return <div className="announcement-group-label middle-school-group" key={key}>Middle School / Jr. High</div>;
    }
    else if (type === OrganizationType.HighSchool) {
      return <div className="announcement-group-label high-school-group" key={key}>High School</div>;
    }
    else if (type === OrganizationType.Adult) {
      return <div className="announcement-group-label adult-group" key={key}>Adult</div>;
    }
  };

  const isAdminUserType = (): boolean => {
    return announcements[activeIndex].userTypes.some(u => u.isAdminType);
  };

  return (
    <div id="announcements-card" className="flex_col_jbetween card-background">
      <div className="title-bar flex_row_jbetween_aend">
        <h3 className="card-header">Current Announcements</h3>
        <AddCircleOutlinedIcon
          className="create-announcement-button"
          onClick={() => navigate(AppRoutes.createAnnouncement.path)}
        />
      </div>
      {announcements && !!announcements.length ? (
        <div className="announcement-content flex_col_jbetween_astart">
          <h4>Announcement</h4>
          <div className="announcement-date-widget-wrapper flex_row_jstart_acenter">
            <EventRoundedIcon className="calendar-icon" />&nbsp;
            {dayjs(announcements[activeIndex].startDate).format('DD/MM/YYYY')} - {dayjs(announcements[activeIndex].endDate).format('DD/MM/YYYY')}
          </div>
          <p className="announcement-content-text">
            {announcements[activeIndex].message}
          </p>
          <div className="announcement-roles-widget-wrapper flex_row_jstart">
            {isAdminUserType() ? (
              <div className="announcement-group-label admin-group">Admin</div>
            ) : (
              announcements[activeIndex].userTypes.map((u: UserType, j: number) => getGroupLabel(j, u.type))
            )}
          </div>
          <Button
            className="announcement-details-button"
            onClick={() => navigate(AppRoutes.announcementDetails.path.replace(':announcementId', announcements[activeIndex].id.toString()))}
          >
            Details<ChevronRightIcon className="carousel-icon" />
          </Button>
        </div>
      ) : (
        <div className="no-announcements flex_row_jcenter_acenter">
          No announcements are running
        </div>
      )}
      <div className="announcement-carousel-buttons flex_row_jbetween_acenter">
        <Button className="announcement-carousel-button previous" onClick={prevAnnouncement} disabled={activeIndex === 0}>
          <ChevronLeftIcon className="carousel-icon" />Previous
        </Button>
        <Button className="announcement-carousel-button next" onClick={nextAnnouncement} disabled={activeIndex === announcements.length - 1}>
          Next<ChevronRightIcon className="carousel-icon" />
        </Button>
      </div>
    </div>
  );
};

export default LatestAnnouncementsCard;
