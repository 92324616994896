
import React, { useState } from 'react';
import {
  useNavigate, Link, useLocation
} from 'react-router-dom';
import { HTTP_ERROR_CODES } from '../../app/app.api';
import {
  AppAssetPaths, AppLinks, AppRoutes
} from '../../app/app.types';
import { useAppDispatch } from '../../app/hooks';
import { signIn } from '../../features/user/auth.thunks';
import {
  FormProvider, SubmitHandler, useForm
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import TextInput from '../../components/forms/TextInput';
import SubmitButton from '../../components/forms/SubmitButton';
import { setAlert } from '../../features/alert/alert.slice';


interface SigninProps { }

const Signin: React.FC<SigninProps> = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [
    loading,
    setLoading,
  ] = useState<boolean>(false);

  const formValidationSchema = yup.object().shape({
    email: yup.string()
      .email('Must be a valid email')
      .required('Email is required'),
    password: yup.string().required('Password is required'),
  }).required();

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const { handleSubmit, clearErrors, formState: { errors } } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    setLoading(true);

    dispatch(signIn(values))
      .unwrap()   // Allows us to use the `results` or `error` outside of the AsyncThunk
      .then(() => {
        clearErrors();
        navigate(state && state.from ? state.from : AppRoutes.app.path, {
          replace: true,
        });
      })
      .catch((error) => {
        dispatch(setAlert({
          type: 'error',
          message: error.status === HTTP_ERROR_CODES.UNAUTHORIZED && error.message || 'Unable to sign in',
        }));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form className="content-container" onSubmit={handleSubmit(submitForm)}>
      <div className="top-section">
        <img src={AppAssetPaths.images.KTS_LOGO} alt="" />
        <h2>Administrative Login</h2>
        <FormProvider {...methods}>

          <TextInput
            containerClass="main-input"
            name="email"
            label="Email"
            errorMessage={errors.email && errors.email.message}
            type="text"
            size="small"
          />

          <TextInput
            containerClass="main-input"
            name="password"
            label="Password"
            errorMessage={errors.password && errors.password.message}
            type="password"
            size="small"
          />

          <SubmitButton
            className="submit-button"
            text="Sign In"
            variant="contained"
            size="large"
            disableElevation
            disabled={loading}
          />

          <div className="forgot-password flex_jend">
            <Link to={AppRoutes.forgotPassword.path}>
              <strong>Forgot Password?</strong>
            </Link>
          </div>
        </FormProvider>
      </div>

      <hr />

      <div className="bottom-section">
        <span>
          <p>Don&apos;t have a community account?&nbsp;
            <Link to={AppRoutes.signUp.path}>
              <strong>Create Account</strong>
            </Link>
          </p>

          <p>Don&apos;t have a school admin account?&nbsp;
            <a href={AppLinks.KTS_CONTACT_PAGE} target="_blank" rel="noreferrer noopener">
              <strong>Request Account</strong>
            </a>
          </p>
        </span>
      </div>
    </form>
  );
};

export default Signin;
