import React, {
  useEffect, useState
} from 'react';
import './Dashboard.scss';
import { useAppSelector } from '../app/hooks';
import { userSelectors } from '../features/user/user.slice';
import {
  AppAssetPaths, canUseAuthComponent, isPostSecondaryAdmin
} from '../app/app.types';
import ApprovalsSummaryCard from '../components/dashboard/ApprovalsSummaryCard';
import { DashboardApprovalSummary } from '../features/dashboard/types/approvalSummary.types';
import LatestAnnouncementsCard from '../components/dashboard/LatestAnnouncementsCard';
import {
  DashboardAnnouncementBanner, DashboardLatestAnnouncements
} from '../features/dashboard/types/adminAnnouncements.types';
import ScholarshipApplicationsCard from '../components/dashboard/ScholarshipApplicationsCard';
import { DashboardScholarshipApplications } from '../features/dashboard/types/scholarshipApplications.types';
import ExpiringResourcesCard from '../components/dashboard/ExpiringResourcesCard';
import QuickLinksCard from '../components/dashboard/QuickLinksCard';
import ExportsCard from '../components/dashboard/ExportsCard';
import { DashboardCurrentMasterKey } from '../features/dashboard/types/currentMasterKey.types';
import CurrentMasterKeyCard from '../components/dashboard/CurrentMasterKeyCard';
import { DashboardTopGuide } from '../features/dashboard/types/topGuide.types';
import TopGuideMenu from '../components/dashboard/TopGuideMenu';
import AdminAnnouncementBanner from '../components/dashboard/AdminAnnouncementBanner';
import { DashboardResourceApplicants } from '../features/dashboard/types/resourceApplicants.types';
import ResourceApplicantsCard from '../components/dashboard/ResourceApplicantsCard';


interface DashboardProps { }

const Dashboard: React.FC<DashboardProps> = () => {

  const user = useAppSelector(userSelectors.selectUser);
  const userIsLoaded = useAppSelector(userSelectors.selectUserLoaded);

  const [
    roleClass,
    setRoleClass,
  ] = useState('');

  useEffect(() => {
    if (userIsLoaded) {
      getUsersRoleForClass();
    }
  }, [
    userIsLoaded,
  ]);

  const getUsersRoleForClass = () => {
    if (user.isAdmin) {
      return setRoleClass('admin');
    }
    else if (isPostSecondaryAdmin(user)) {
      return setRoleClass('district-institution-admin');
    }
    else if (user.isDistrictAdmin) {
      return setRoleClass('district-admin');
    }
    else if (user.isInstitutionAdmin) {
      return setRoleClass('institution');
    }
    else if (user.isCounselor) {
      return setRoleClass('counselor');
    }
    else if (user.isCommunity) {
      return setRoleClass('community');
    }
    else if (user.isKeyContact) {
      if (user.isHighSchoolKeyContact || user.isMiddleSchoolKeyContact) {
        return setRoleClass('student-key-contact');
      }
      else if (user.isAdultKeyContact) {
        return setRoleClass('adult-key-contact');
      }
      else if (user.isUniversityKeyContact) {
        return setRoleClass('higher-key-contact');
      }
    }
  };

  return (
    <div className="dashboard-main">
      <h1>Welcome back, <b>{user.firstname}</b></h1>

      {canUseAuthComponent(user.roles, DashboardAnnouncementBanner.authorizedRoles) && <AdminAnnouncementBanner />}
      {canUseAuthComponent(user.roles, DashboardTopGuide.authorizedRoles) && <TopGuideMenu />}

      <div className={`page-content ${roleClass}-dashboard`}>
        <div className="approval-summary">
          {canUseAuthComponent(user.roles, DashboardApprovalSummary.authorizedRoles) && <ApprovalsSummaryCard />}
        </div>
        <div className="current-announcements">
          {canUseAuthComponent(user.roles, DashboardLatestAnnouncements.authorizedRoles) && <LatestAnnouncementsCard />}
        </div>
        <div className="scholarship-apps">
          {canUseAuthComponent(user.roles, DashboardScholarshipApplications.authorizedRoles) && !isPostSecondaryAdmin(user) && <ScholarshipApplicationsCard />}
        </div>
        <div className="resource-applicants">
          {canUseAuthComponent(user.roles, DashboardResourceApplicants.authorizedRoles) && <ResourceApplicantsCard />}
        </div>
        <div className="expiring-resources">
          <ExpiringResourcesCard />
        </div>
        <div className="quick-links">
          <QuickLinksCard />
        </div>
        <div className="exports">
          <ExportsCard />
        </div>
        <div className="master-key">
          {canUseAuthComponent(user.roles, DashboardCurrentMasterKey.authorizedRoles) && <CurrentMasterKeyCard />}
        </div>
      </div>

      <div className="people-at-desk">
        <img src={AppAssetPaths.images.TWO_PEOPLE_AT_A_DESK} />
      </div>
    </div>
  );
};

export default Dashboard;
