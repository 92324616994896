import { UserRole } from '../../user/user.model';
import { AuthComponent } from '../../../app/app.types';


export interface ScholarshipApplicationsTab extends AuthComponent {
  title: string;
  dataKey: string;
}

export const DashboardScholarshipApplications: AuthComponent = {
  authorizedRoles: [
    UserRole.admin,
    UserRole.districtAdmin,
  ],
};

export const ScholarshipApplicationTabs: ScholarshipApplicationsTab[] = [
  {
    title: 'High School',
    dataKey: 'highSchool',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
    ],
  },
  {
    title: 'Middle School / Jr. High',
    dataKey: 'middleSchool',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
    ],
  },
  {
    title: 'Adult',
    dataKey: 'adult',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
    ],
  },
];
