import React, {
  useEffect, useState
} from 'react';
import {
  Box,
  Button,
  Modal,
  Pagination
} from '@mui/material';
import './BulkAddPoints.modal.scss';
import TextInput from '../forms/TextInput';
import {
  FormProvider, SubmitHandler, useForm
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import SubmitButton from '../forms/SubmitButton';
import { User } from '../../features/user/user.model';
import { TableState } from '../../constants/tables/table.types';
import { addPointsListColumns } from '../../constants/tables/userTable.types';
import {
  DataGrid, GridCellParams, GridSelectionModel
} from '@mui/x-data-grid';
import { AppAssetPaths } from '../../app/app.types';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useAppDispatch } from '../../app/hooks';
import { bulkAddPoints } from '../../features/user/user.api';
import { setAlert } from '../../features/alert/alert.slice';


interface BulkAddPointsModalProps {
  parentSelectionModel: GridSelectionModel;
  setSelectionModelCallback: (newSelectionModel: GridSelectionModel) => void;
  selectedUsers: User[];
  setSelectedUsersCallback: (newUserSelection: User[]) => void;
  open: boolean;
  onClose: () => void;
}

const BulkAddPointsModal: React.FC<BulkAddPointsModalProps> = ({
  parentSelectionModel,
  setSelectionModelCallback,
  selectedUsers,
  setSelectedUsersCallback,
  open,
  onClose,
}) => {

  const dispatch = useAppDispatch();

  const [
    slide,
    setSlide,
  ] = useState(1);
  const [
    points,
    setPoints,
  ] = useState(0);
  const [
    tableState,
    setTableState,
  ] = useState<TableState>({
    page: 1,
    pageSize: 100,
    columns: addPointsListColumns,
    rows: [],
    loading: false,
  });

  useEffect(() => {
    const removePage = tableState.page > Math.ceil(selectedUsers.length / tableState.pageSize) && tableState.page > 1;

    setTableState((prev) => ({
      ...prev,
      page: removePage ? prev.page - 1 : prev.page,
      rows: removePage ? getNewRows(tableState.page - 2) : getNewRows(tableState.page - 1),
    }));
  }, [
    selectedUsers,
  ]);

  const getNewRows = (page: number) => {
    selectedUsers = selectedUsers.slice(page * tableState.pageSize, (page * tableState.pageSize) + tableState.pageSize);

    const seenValues = new Set();
    const uniqueObjects = selectedUsers.filter((user) => {
      if (!seenValues.has(user.id)) {
        seenValues.add(user.id);
        return true;
      }
      return false;
    });
    selectedUsers = uniqueObjects;
    return selectedUsers;
  };

  const formValidationSchema = yup.object().shape({
    points: yup.number()
      .required('Points are required')
      .typeError('Points are required'),
  }).required();

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    setPoints(values.points);
    setSlide(slide + 1);
  };

  return (
    <Modal
      id="bulk-add-points-modal"
      open={open}
      onClose={onClose}
    >
      <Box className="modal-content card-background flex_col_jcenter">
        {(slide === 1) ? (
          <form className="content-container flex_col_acenter" onSubmit={handleSubmit(submitForm)}>
            <h3>How many points would you like to add for these users?</h3>

            <FormProvider {...methods}>
              <TextInput
                name="points"
                errorMessage={errors.points && errors.points.message}
                type="number"
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
              />
              <p className="points-text">Points</p>

              <DataGrid
                className={'table'}
                columns={tableState.columns}
                rows={tableState.rows}
                components={{
                  Pagination: Pagination,
                }}
                componentsProps={{
                  pagination: {
                    page: tableState.page,
                    count: Math.ceil(selectedUsers.length / tableState.pageSize),
                    onChange: (_: void, page: number) => {
                      setTableState((prev) => ({
                        ...prev,
                        page,
                        rows: getNewRows(page - 1),
                      }));
                    },
                  },
                }}
                onCellClick={(params: GridCellParams) => {
                  if (params.field === 'delete') {
                    setSelectedUsersCallback(selectedUsers.filter(u => u.id !== params.id));
                    setSelectionModelCallback(parentSelectionModel.filter(u => u !== params.id));
                  }
                }}
                pagination
                disableColumnFilter
                disableColumnMenu
                disableSelectionOnClick
                hideFooterSelectedRowCount
              />

              <div className="modal-buttons flex_jbetween">
                <Button
                  variant="outlined"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <SubmitButton
                  text="Add Points"
                  variant="contained"
                  disableElevation
                  disabled={!selectedUsers.length}
                />
              </div>
            </FormProvider>
          </form>
        ) : (slide === 2) ? (
          <div className="content-container flex_col">
            <div className="flex_col_acenter">
              <div className="back-button flex_acenter" onClick={() => setSlide(slide - 1)}>
                <ChevronLeftIcon />
                Back
              </div>
              <img className="slide-img" src={AppAssetPaths.images.ADD_POINTS} alt={'add points'} />
              <h3>Are you sure you want to add {points} points to {selectedUsers.length} users?</h3>
              <p className="slide-msg">You can manually adjust each user&apos;s current points in their student details at any time.</p>
            </div>
            <div className="modal-buttons flex_jbetween">
              <Button
                variant="outlined"
                onClick={() => {
                  setSlide(1);
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  bulkAddPoints(points, parentSelectionModel.map(id => +id))
                    .then(() => {
                      setSlide(slide + 1);
                    })
                    .catch(() => {
                      dispatch(setAlert({
                        type: 'error',
                        message: 'Unable add points',
                      }));
                    });
                }}
                disableElevation
              >
                Add Points
              </Button>
            </div>
          </div>
        ) : (
          <div className="content-container flex_col">
            <div className="flex_col_acenter">
              <img className="slide-img" src={AppAssetPaths.images.ADD_POINTS_SUCCESSFUL} alt={'success'} />
              <h3>Points successfully added!</h3>
              <p className="slide-msg">New point totals are reflected in individual student details.</p>
            </div>
            <div className="modal-buttons">
              <Button
                variant="contained"
                onClick={() => {
                  setSlide(1);
                  reset();
                  setSelectionModelCallback([]);
                  setSelectedUsersCallback([]);
                  onClose();
                }}
                disableElevation
              >
                Close
              </Button>
            </div>
          </div>
        )}
      </Box>
    </Modal>
  );
};

export default BulkAddPointsModal;
