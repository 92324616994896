import { Button } from '@mui/material';
import React from 'react';


export interface SubmitButtonProps {
  className?: string;
  text?: string | undefined;
  variant?: 'text' | 'outlined' | 'contained';
  size?: 'small' | 'medium' | 'large';
  color?: 'primary' | 'secondary';
  disabled?: boolean;
  disableElevation?: boolean;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  className,
  text,
  variant,
  size,
  color,
  disabled,
  disableElevation,
}) => {

  return (
    <Button
      className={className}
      type="submit"
      variant={variant || 'outlined'}
      size={size}
      color={color}
      disabled={disabled}
      disableElevation={disableElevation}
    >
      {text}
    </Button>
  );
};

export default SubmitButton;
