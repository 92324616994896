import React, {
  useEffect, useState
} from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { userSelectors } from '../../features/user/user.slice';
import { getSessionToken } from '../../util/localStorage';
import SessionExpiredModal from './SessionExpired.modal';
import SignupCodeModal from './SignupCode.modal';


const GlobalModals: React.FC = () => {

  const location = useLocation();

  const user = useAppSelector(userSelectors.selectUser);
  const userIsLoaded = useAppSelector(userSelectors.selectUserLoaded);

  ////////////////////
  //  Control Vars  //
  ////////////////////

  // session expired modal
  const [
    sessionExpiredModalOpen,
    setSessionExpiredModalOpen,
  ] = useState(false);
  const [
    showSignupCodeModal,
    setShowSignupCodeModal,
  ] = useState(false);

  ///////////////////////////////////////
  //  Effects to open or close modals  //
  ///////////////////////////////////////

  // session expired modal
  useEffect(() => {
    const token = getSessionToken();
    if (!token) return;

    try {
      const payload = JSON.parse(atob(token.split('.')[1]));

      if (Date.now() > payload.exp * 1000) {
        setSessionExpiredModalOpen(true);
      }
    } catch(e) {
      return;
    }
  }, [
    location.pathname,
  ]);

  useEffect(() => {

    // Show email confirmation modal if current user is community user, whose not a institution admin(KTS-2990), and hasn't confirmed email
    if (userIsLoaded && user.isCommunity && !user.status?.emailVerified && !user.isInstitutionAdmin) {
      setShowSignupCodeModal(true);
    }
  }, [
    userIsLoaded,
  ]);

  ///////////////////
  //  Modal Index  //
  ///////////////////
  return (
    <>
      <SessionExpiredModal
        open={sessionExpiredModalOpen}
        onClose={() => setSessionExpiredModalOpen(false)}
      />

      <SignupCodeModal
        open={showSignupCodeModal}
        onClose={() => setShowSignupCodeModal(false)}
      />
    </>
  );
};

export default GlobalModals;
