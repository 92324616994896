import {
  Button, Modal, Pagination
} from '@mui/material';
import { Box } from '@mui/system';
import React, {
  useEffect, useState
} from 'react';
import './BulkApproveReject.modal.scss';
import {
  DataGrid,
  GridCellParams, GridSelectionModel
} from '@mui/x-data-grid';
import { StudentApproval } from '../../features/approvals/approvals.model';
import SubmitButton from '../forms/SubmitButton';
import { TableState } from '../../constants/tables/table.types';
import { bulkApproveRejectListColumns } from '../../constants/tables/approvalTable.types';
import { setAlert } from '../../features/alert/alert.slice';
import { useAppDispatch } from '../../app/hooks';
import {
  approveStudent, rejectStudent
} from '../../features/user/user.thunks';



interface BulkApproveRejectModalProps {
  parentSelectionModel: GridSelectionModel;
  setSelectionModelCallback: (newSelectionModel: GridSelectionModel) => void;
  selectedUsers: StudentApproval[];
  setSelectedUsersCallback: (newUserSelection: StudentApproval[]) => void;
  open: boolean;
  approving: boolean;
  onClose: () => void;
}


const BulkApproveRejectModal: React.FC<BulkApproveRejectModalProps> = ({
  parentSelectionModel,
  setSelectionModelCallback,
  selectedUsers,
  setSelectedUsersCallback,
  open,
  approving,
  onClose,
}) => {

  const dispatch = useAppDispatch();

  const [
    tableState,
    setTableState,
  ] = useState<TableState>({
    page: 1,
    pageSize: 100,
    columns: bulkApproveRejectListColumns,
    rows: [],
    loading: false,
  });

  useEffect(() => {
    const removePage = tableState.page > Math.ceil(selectedUsers.length / tableState.pageSize) && tableState.page > 1;

    setTableState((prev) => ({
      ...prev,
      page: removePage ? prev.page - 1 : prev.page,
      rows: removePage ? getNewRows(tableState.page - 2) : getNewRows(tableState.page - 1),
    }));
  }, [
    selectedUsers,
  ]);

  const getNewRows = (page: number) => {
    return selectedUsers.slice(page * tableState.pageSize, (page * tableState.pageSize) + tableState.pageSize);
  };

  const submitForm = async () => {
    if (selectedUsers && !!selectedUsers.length && !!parentSelectionModel.length) {
      if (approving) {
        dispatch(approveStudent(parentSelectionModel as number[])).unwrap()
          .then(() => {
            dispatch(setAlert({
              type: 'success',
              message: 'Sucessfully bulk approved users',
            }));
          })
          .catch(() => {
            dispatch(setAlert({
              type: 'error',
              message: 'Unable to bulk approve users',
            }));
          });
        onClose();
      } else {
        dispatch(rejectStudent(parentSelectionModel as number[])).unwrap()
          .then(() => {
            dispatch(setAlert({
              type: 'success',
              message: 'Sucessfully bulk rejected users',
            }));
          })
          .catch(() => {
            dispatch(setAlert({
              type: 'error',
              message: 'Unable to bulk reject users',
            }));
          });
        onClose();
      }
    }
  };


  return (
    <Modal
      id="bulk-approve-reject-modal"
      open={open}
      onClose={onClose}
    >

      <Box className="modal-content card-background flex_col_jcenter">
        <form className="content-container flex_col_acenter" onSubmit={submitForm}>
          <h3>  {`Would you like to ${approving ? 'approve' : 'reject'} these users`} </h3>

          <DataGrid
            className={'table'}
            columns={tableState.columns}
            rows={tableState.rows}
            components={{
              Pagination: Pagination,
            }}
            componentsProps={{
              pagination: {
                page: tableState.page,
                count: Math.ceil(selectedUsers.length / tableState.pageSize),
                onChange: (_: void, page: number) => {
                  setTableState((prev) => ({
                    ...prev,
                    page,
                    rows: getNewRows(page - 1),
                  }));
                },
              },
            }}
            onCellClick={(params: GridCellParams) => {
              if (params.field === 'delete') {
                setSelectedUsersCallback(selectedUsers.filter(u => u.id !== params.id));
                setSelectionModelCallback(parentSelectionModel.filter(u => u !== params.id));
              }
            }}
            pagination
            disableColumnFilter
            disableColumnMenu
            disableSelectionOnClick
            hideFooterSelectedRowCount
          />

          <div className="modal-buttons flex_jbetween">
            <Button
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            <SubmitButton
              text={`${approving ? 'Approve' : 'Reject'}`}
              variant="contained"
              disableElevation
              disabled={!selectedUsers.length}
            />
          </div>
        </form>
      </Box>

    </Modal>
  );
};

export default BulkApproveRejectModal;