/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { GridSortModel } from '@mui/x-data-grid';
import {
  BooleanListMenuItem,
  ConstructListMenuItem,
  emptyListMenuItem,
  ListMenuItem
} from '../../components/forms/form.types';


export enum PathwayCourseType {
  middleSchool = 'middle_school',
  supporting = 'supporting',
  explorer = 'explorer',
  concentrator = 'concentrator',
  completer = 'completer',
  postSecondaryResource = 'post_secondary',
}

export interface PathwayCluster extends ListMenuItem { }
export interface PathwayType extends ListMenuItem { }

export const emptyPathwaySchool: PathwaySchool = {
  id: 0,
  organizationId: 0,
  note: '',
  isHidden: true,
  certificateHidden: true,
  noteUpdated: false,
};

export interface Pathway {
  id: number;
  name: string;
  pathwayCluster: PathwayCluster;
  pathwayType: ListMenuItem;
  creditsToComplete: number;
  certificateTitle: string;
  certificateText: string;
  certificateLink: string;
  active: boolean;
  certificateActive: boolean;
  pathwaySchool: PathwaySchool;
  createdAt: string;
  updatedAt: string;
  updatedByDistrictAdmin?: boolean;
  courses?: {
    middleSchool: PathwayCourse[],
    supporting: PathwayCourse[],
    explorer: PathwayCourse[],
    concentrator: PathwayCourse[],
    completer: PathwayCourse[],
    postSecondaryResources: PathwayCourse[],
  };
}

export interface NewPathway {
  name: string;
  careerCluster: PathwayCluster;
  type: ListMenuItem;
  credits: number;
  active: boolean;
  certificateTitle: string;
  certificateText: string;
  certificateLink: string;
  certificateActive: boolean;
  note?: string;

  middleSchoolCourses: PathwayCourse[],
  supportingCourses: PathwayCourse[],
  explorerCourses: PathwayCourse[],
  concentratorCourses: PathwayCourse[],
  completerCourses: PathwayCourse[],
  postSecondaryResources: PathwayCourse[],
}

export const emptyPathway: Pathway = {
  id: 0,
  name: '',
  pathwayCluster: emptyListMenuItem,
  pathwayType: emptyListMenuItem,
  creditsToComplete: 0,
  certificateTitle: '',
  certificateText: '',
  certificateLink: '',
  active: false,
  certificateActive: true,
  pathwaySchool: emptyPathwaySchool,
  createdAt: '',
  updatedAt: '',
};

export interface PathwayListQueryParams {
  offset: number;
  limit?: number;
  role: string;
  search?: string;
  typeId?: number;
  schoolId?: number;
  sortModel?: GridSortModel;
}

export interface PathwayCourse {
  id?: number;
  // This separate 'courseId' is needed for the edit form where 'useFormArray' needs to use 'id' for something else.
  courseId?: number;
  pathwayId?: number;
  name: string;
  link: string;
  credits: number;
  type: PathwayCourseType;
  concurrentEnrollment: boolean | BooleanListMenuItem;
  recommendedExplorerCourse: boolean | BooleanListMenuItem;
  active?: boolean;
  alternateCourseName?: string;
  pathwayCourseSchool?: PathwayCourseSchool;
}

export interface PathwaySchool {
  id: number;
  organizationId: number;
  note: string;
  isHidden: boolean;
  certificateHidden: boolean;
  noteUpdated: boolean;
}

export interface PathwayCourseSchool {
  id: number;
  organizationId: number;
  alternateCourseName?: string;
  concurrentEnrollment?: boolean | BooleanListMenuItem;
  isHidden: boolean;
  updatedValues?: {
    alternateCourseName?: boolean;
    concurrentEnrollment?: boolean;
  }
}

export const ConstructPathway = (data: any): Pathway => {
  const allCourses = data.courses && data.courses.map((c: any) => ConstructPathwayCourse(c));

  return {
    id: data.id,
    name: data.name,
    pathwayCluster: data.pathway_cluster && ConstructListMenuItem(data.pathway_cluster_id, data.pathway_cluster.title) as PathwayCluster,
    pathwayType: data.pathway_type && ConstructListMenuItem(data.pathway_type_id, data.pathway_type.title) as PathwayType,
    creditsToComplete: data.credits_to_complete,
    certificateTitle: data.certificate_title,
    certificateText: data.certificate_text,
    certificateLink: data.certificate_link,
    active: (data.pathway_school && !!data.pathway_school.length) ? !data.pathway_school[0].is_hidden : data.active,
    certificateActive: data.certificate_active,
    pathwaySchool: data.pathway_school && !!data.pathway_school.length && ConstructPathwaySchool(data.pathway_school[0]),
    createdAt: data.created_at,
    updatedAt: data.updated_at,
    updatedByDistrictAdmin: data.updated_by_district_admin,
    courses: allCourses && !!allCourses.length && {
      middleSchool: allCourses.filter((c: any) => c.type === PathwayCourseType.middleSchool),
      supporting: allCourses.filter((c: any) => c.type === PathwayCourseType.supporting),
      explorer: allCourses.filter((c: any) => c.type === PathwayCourseType.explorer),
      concentrator: allCourses.filter((c: any) => c.type === PathwayCourseType.concentrator),
      completer: allCourses.filter((c: any) => c.type === PathwayCourseType.completer),
      postSecondaryResources: allCourses.filter((c: any) => c.type === PathwayCourseType.postSecondaryResource),
    },
  };
};

export const ConstructPathwayCourse = (data: any): PathwayCourse => {
  return {
    id: data.id,
    courseId: data.id,
    pathwayId: data.pathway_id,
    name: data.name,
    link: data.link,
    credits: data.credits,
    type: data.course_type,
    concurrentEnrollment: (data.pathway_course_school && !!data.pathway_course_school.length && data.pathway_course_school[0].concurrent_enrollment != null) ? data.pathway_course_school[0].concurrent_enrollment : data.concurrent_enrollment,
    recommendedExplorerCourse: data.recommended_explorer_course,
    active: (data.pathway_course_school && !!data.pathway_course_school.length) ? !data.pathway_course_school[0].is_hidden : data.active,
    alternateCourseName: data.pathway_course_school && !!data.pathway_course_school.length && data.pathway_course_school[0].alternate_course_name,
    pathwayCourseSchool: data.pathway_course_school && !!data.pathway_course_school.length && ConstructPathwayCourseSchool(data.pathway_course_school[0], data.concurrent_enrollment),
  };
};

export const ConstructEmptyPathwayCourseByType = (type: PathwayCourseType): PathwayCourse => {
  return {
    name: '',
    link: '',
    credits: 0,
    type: type,
    concurrentEnrollment: false,
    recommendedExplorerCourse: false,
    active: true,
  };
};

export const ConstructPathwaySchool = (data: any): PathwaySchool => {
  return {
    id: data.id,
    organizationId: data.organization_id,
    note: data.note,
    isHidden: data.is_hidden,
    certificateHidden: data.certificate_hidden,
    noteUpdated: data.pathway_modified_notifications && !!data.pathway_modified_notifications.length ? data.pathway_modified_notifications[0].note_updated : false,
  };
};

export const ConstructPathwayCourseSchool = (data: any, defaultConcurrentEnrollment: boolean): PathwayCourseSchool => {
  return {
    id: data.id,
    organizationId: data.organization_id,
    alternateCourseName: data.alternate_course_name,
    concurrentEnrollment: data.concurrent_enrollment,
    isHidden: data.is_hidden,
    updatedValues: data.alternate_name_updated || data.concurrent_enrollment_updated ? {
      alternateCourseName: data.alternate_name_updated,
      concurrentEnrollment: data.concurrent_enrollment_updated,
    } : undefined,
  };
};
