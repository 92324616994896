import React from 'react';
import { useLocation } from 'react-router-dom';
import ExpandableCard from '../ExpandableCard';
import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import {
  togglePathwayCertificateActive, togglePathwayCertificateActiveForSchool
} from '../../../features/pathways/pathways.thunks';
import { userSelectors } from '../../../features/user/user.slice';
import { pathwaySelectors } from '../../../features/pathways/pathways.slice';
import { UserRole } from '../../../features/user/user.model';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import './CourseTable.scss';


interface CertificateDetailsProps {
  defaultExpanded?: boolean;
}

const CertificateDetails: React.FC<CertificateDetailsProps> = ({ defaultExpanded }) => {
  const EMPTY = '-';

  const { state } = useLocation();
  const dispatch = useAppDispatch();

  const user = useAppSelector(userSelectors.selectUser);
  const pathwayDetails = useAppSelector(pathwaySelectors.selectPathwayDetails);

  const getCertificateActiveStatus = () => {
    if (user.roles.includes(UserRole.admin)) {
      return (
        pathwayDetails?.certificateActive ? (
          <VisibilityIcon className="eye-icon cursor-pointer" onClick={() => dispatch(togglePathwayCertificateActive(pathwayDetails.id))} />
        ) : (
          <VisibilityOffIcon className="eye-icon cursor-pointer" onClick={() => dispatch(togglePathwayCertificateActive(pathwayDetails.id))} />
        )
      );
    }
    else {
      return (
        !pathwayDetails.pathwaySchool?.certificateHidden ? (
          <VisibilityIcon
            className={`eye-icon ${!user.isMiddleSchoolAdmin && 'cursor-pointer'}`}
            onClick={() => {
              if (user.isMiddleSchoolAdmin) return;

              dispatch(togglePathwayCertificateActiveForSchool({
                id: pathwayDetails.id,
                isHidden: true,
                organizationId: user.isDistrictAdmin ? pathwayDetails.pathwaySchool.organizationId || state.schoolId : undefined,
              }));
            }}
          />
        ) : (
          <VisibilityOffIcon
            className={`eye-icon ${!user.isMiddleSchoolAdmin && 'cursor-pointer'}`}
            onClick={() => {
              if (user.isMiddleSchoolAdmin) return;

              dispatch(togglePathwayCertificateActiveForSchool({
                id: pathwayDetails.id,
                isHidden: false,
                organizationId: user.isDistrictAdmin ? pathwayDetails.pathwaySchool.organizationId || state.schoolId : undefined,
              }));
            }}
          />
        )
      );
    }
  };

  const getDataRow = (header = false) => (
    <div className={`data-row flex_jstretch_acenter ${header ? 'header' : ''}`}>
      <div className="data-cell name">
        <div className="data-label">Title</div>
        <div className="data-content">{pathwayDetails?.certificateTitle || EMPTY}</div>
      </div>
      <div className="data-cell link">
        <div className="data-label">Text</div>
        <div className="data-content">{pathwayDetails?.certificateText || EMPTY}</div>
      </div>
      <div className="data-cell active">
        <div className="data-label" />
        <div className="data-content">
          {getCertificateActiveStatus()}
        </div>
      </div>
    </div>
  );

  const getDetails = () => (
    <div className="data-wrapper">
      {getDataRow(true)}
      {getDataRow()}
    </div>
  );

  return (
    <ExpandableCard
      className="course-table"
      summary={<span>Certificate</span>}
      details={getDetails()}
      expandable
      defaultExpanded={defaultExpanded}
    />
  );
};

export default CertificateDetails;
