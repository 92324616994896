/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useEffect, useState
} from 'react';
import './SkillTrainingDetails.scss';
import {
  Box, Button
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  AppAssetPaths, AppRoutes
} from '../../../app/app.types';
import {
  useNavigate, useParams
} from 'react-router-dom';
import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import { skillTrainingSelectors } from '../../../features/skillTrainings/skillTrainings.slice';
import {
  approveSkillTraining, GetSkillTrainingById, rejectSkillTraining
} from '../../../features/skillTrainings/skillTrainings.thunks';
import { courseFormatDropdownList } from '../../../util/dropdown';
import { Skill } from '../../../features/skills/skills.model';
import { SkillTrainingCourse } from '../../../features/skillTrainings/skillTrainings.model';
import { getApiBaseUrl } from '../../../app/app.api';
import dayjs from 'dayjs';
import { userSelectors } from '../../../features/user/user.slice';
import {
  User, UserRole
} from '../../../features/user/user.model';
import { adminGetUserById } from '../../../features/user/user.api';
import ApproveRejectCard from '../../../components/details/ApproveRejectCard';
import { setAlert } from '../../../features/alert/alert.slice';


interface SkillTrainingDetailsProps { }

export const SkillTrainingDetails: React.FC<SkillTrainingDetailsProps> = () => {

  const details = useAppSelector(skillTrainingSelectors.selectSkillTrainingDetails);
  const user = useAppSelector(userSelectors.selectUser);

  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();

  const [
    imagePath,
    setImagePath,
  ] = useState('');


  useEffect(() => {
    if (params && params.id) {
      dispatch(GetSkillTrainingById(+params.id));
    }
  }, []);

  useEffect(() => {
    if (details.id !== 0) {
      getCompanyLogo();
    }
  }, [
    details,
  ]);

  const getCompanyLogo = async () => {
    const baseUrl = await getApiBaseUrl();
    setImagePath(`${baseUrl}/assets/images/skillup_courses/${details.id}/${details.image}`);
  };

  const handleCourseFormatDetails = (): string => {
    const courseFormatStr: SkillTrainingCourse[] = [];
    if (details && details.id !== 0) {
      Object.entries(details.courseFormat).map((key, index) => key[1] && courseFormatStr.push(courseFormatDropdownList[index]));
    }
    return courseFormatStr.map((e: SkillTrainingCourse) => e.label).join(', ');
  };

  const userIsAdminOrTheCreator = (): boolean => {
    let returnBool = false;

    if (user && (user.isAdmin || user.id === details.createdById)) {
      returnBool = true;
    }
    else if ((user.isDistrictAdmin || user.isDistrictInstitutionAdmin) && details.createdById) {
      adminGetUserById(details.createdById).then((res: User) => {
        if (res.organizationId === user.organizationId) {
          returnBool = true;
        }
        if (user.isInstitutionAdmin && res.isInstitutionAdmin) {
          returnBool = true;
        }
      });
    }

    return returnBool;
  };

  const handleApprove = () => {
    dispatch(approveSkillTraining(details.id))
      .unwrap()
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully approved skill training',
        }));
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to approve skill training',
        }));
      });
  };

  const handleReject = () => {
    dispatch(rejectSkillTraining(details.id))
      .unwrap()
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully rejected skill training',
        }));
        navigate(-1);
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to reject skill training',
        }));
      });
  };

  return (
    <div id="skill-training-details">
      <Box className="card-background flex_col">
        <div className="content-header flex_row_jbetween">

          <span className="back-button flex_acenter" onClick={() => navigate(AppRoutes.skillTrainings.path)}>
            <ChevronLeftIcon color="secondary" />
            <h3>Skill Training Details</h3>
          </span>

          <span className="flex_row_jstart_acenter">
            {details && !details.approved && user.isAdmin ? (
              <ApproveRejectCard
                onApprove={() => handleApprove()}
                onReject={() => handleReject()}
              />
            ) : (!details.approved && !user.isAdmin) ? (
              <span className="awaiting-approval">
                <p> Awaiting Approval </p>
              </span>
            ) : null
            }

            {userIsAdminOrTheCreator() && (
              <Button
                variant="outlined"
                onClick={() => navigate(AppRoutes.editSkillTraining.path.replace(':id', params.id!))}
              >
                Edit
              </Button>
            )}
          </span>
        </div>


        <div className="main-content flex_row">
          <div className="left flex_col">
            <span>
              <p>SKILLS TRAINING NAME</p>
              <p> {details.name} </p>
            </span>

            <span>
              <p>URL</p>
              <p>{details.url}</p>
            </span>

            <span>
              <p>COMPANY</p>
              <p>{details.company}</p>
            </span>

            <span>
              <p>COURSE FORMAT</p>
              <p> {handleCourseFormatDetails()} </p>
              <p></p>
            </span>

            <span>
              <p>COURSE DESCRIPTION</p>
              <p> {details.description || '-'} </p>
            </span>

            <span>
              <p>ZIP CODE</p>
              <p> {details.zipcode || '-'} </p>
            </span>

            <span>
              <p>DURATION</p>
              <p> {details.durationValue || '-'} &nbsp;{details.durationUnit || '-'} </p>
            </span>

            <span>
              <p>Start Date</p>
              <p> {details.startDate ? dayjs(details.startDate).format('MM/DD/YYYY') : '-'} </p>
            </span>

            <span>
              <p>End Date</p>
              <p> {details.endDate ? dayjs(details.endDate).format('MM/DD/YYYY') : '-'} </p>
            </span>

            <span>
              <p>SKILLS</p>
              <p>
                {details.skills && !!details.skills.length ? (
                  details.skills.map((e: Skill) => e.label).join(', ')) :
                  '-'
                }
              </p>
            </span>

            <span>
              <p>COST</p>
              <p> {details.cost || '-'} </p>
            </span>

            <span>
              <p>ADDITIONAL INFORMATION</p>
              <p> {details.note || '-'} </p>
            </span>

            <span>
              <p>LEARN &amp; WORK</p>
              <p> {details.isLearnAndWork ? 'Yes' : 'No'} </p>
            </span>

            <span>
              <p>Active</p>
              <p> {details.active ? 'Yes' : 'No'} </p>
            </span>
          </div>

          <div className="right flex_col_jstart_aend">
            <img className="skilltraining-image" src={imagePath} alt="Skill Training details Image" />

          </div>
        </div>
      </Box>
    </div>
  );
};

export default SkillTrainingDetails;
