import {
  createSelector, createSlice
} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { StateStatus } from '../../app/app.types';
import {
  EmptyOrganization,
  Organization, OrganizationType
} from './organization.model';
import {
  getAllSchoolDistricts,
  getAllSchools,
  getByType,
  getCommunityOrganizationTypeList,
  getOrganizationbyId,
  getOrganizationList,
  updateOrganization
} from './organization.thunks';
import { ListMenuItem } from '../../components/forms/form.types';

export interface OrganizationState {
  organizationDetails: {
    status: StateStatus;
    data: Organization;
  }
  organizationList: {
    status: StateStatus;
    data: Organization[];
    totalOrganizationCount: number;
    loaded: boolean;
  }
  schoolDistricts: {
    status: StateStatus;
    data: Organization[];
  }
  schools: {
    status: StateStatus;
    data: Organization[];
    loaded: boolean;
  }
  communityOrganizationTypeList: {
    status: StateStatus;
    data: ListMenuItem[];
  }
}

const initialState: OrganizationState = {
  organizationDetails: {
    status: StateStatus.IDLE,
    data: EmptyOrganization,
  },
  organizationList: {
    status: StateStatus.IDLE,
    data: [],
    totalOrganizationCount: 0,
    loaded: false,
  },
  schoolDistricts: {
    status: StateStatus.IDLE,
    data: [],
  },
  schools: {
    status: StateStatus.IDLE,
    data: [],
    loaded: false,
  },
  communityOrganizationTypeList: {
    status: StateStatus.IDLE,
    data: [],
  },
};

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      // Organization Details
      .addCase(getOrganizationbyId.pending, (state) => {
        state.organizationDetails.status = StateStatus.LOADING;
      })
      .addCase(getOrganizationbyId.fulfilled, (state, action) => {
        state.organizationDetails.status = StateStatus.IDLE;
        state.organizationDetails.data = action.payload;
      })
      .addCase(getOrganizationbyId.rejected, (state) => {
        state.organizationDetails.status = StateStatus.FAILED;
      })
      // Update Organization Details
      .addCase(updateOrganization.pending, (state) => {
        state.organizationDetails.status = StateStatus.LOADING;
      })
      .addCase(updateOrganization.fulfilled, (state, action) => {
        state.organizationDetails.status = StateStatus.IDLE;
        state.organizationDetails.data = action.payload;
      })
      .addCase(updateOrganization.rejected, (state) => {
        state.organizationDetails.status = StateStatus.FAILED;
      })
      // Organization List
      .addCase(getOrganizationList.pending, (state) => {
        state.organizationList.status = StateStatus.LOADING;
      })
      .addCase(getOrganizationList.fulfilled, (state, action) => {
        state.organizationList.status = StateStatus.IDLE;
        state.organizationList.data = action.payload.organizations;
        state.organizationList.totalOrganizationCount = action.payload.count;
        state.organizationList.loaded = true;
      })
      .addCase(getOrganizationList.rejected, (state) => {
        state.organizationList.status = StateStatus.FAILED;
      })
      // School Districts
      .addCase(getAllSchoolDistricts.pending, (state) => {
        state.schoolDistricts.status = StateStatus.LOADING;
      })
      .addCase(getAllSchoolDistricts.fulfilled, (state, action) => {
        state.schoolDistricts.status = StateStatus.IDLE;
        state.schoolDistricts.data = action.payload;
      })
      .addCase(getAllSchoolDistricts.rejected, (state) => {
        state.schoolDistricts.status = StateStatus.FAILED;
      })
      // All Schools
      .addCase(getAllSchools.pending, (state) => {
        state.schools.status = StateStatus.LOADING;
      })
      .addCase(getAllSchools.fulfilled, (state, action) => {
        state.schools.status = StateStatus.IDLE;
        state.schools.data = action.payload;
        state.schools.loaded = true;
      })
      .addCase(getAllSchools.rejected, (state) => {
        state.schools.status = StateStatus.FAILED;
      })
      // Community Org Type List
      .addCase(getCommunityOrganizationTypeList.pending, (state) => {
        state.communityOrganizationTypeList.status = StateStatus.LOADING;
      })
      .addCase(getCommunityOrganizationTypeList.fulfilled, (state, action) => {
        state.communityOrganizationTypeList.status = StateStatus.IDLE;
        state.communityOrganizationTypeList.data = action.payload;
      })
      .addCase(getCommunityOrganizationTypeList.rejected, (state) => {
        state.communityOrganizationTypeList.status = StateStatus.FAILED;
      })
      .addCase(getByType.pending, (state) => {
        state.organizationList.status = StateStatus.LOADING;
      })
      .addCase(getByType.fulfilled, (state, action) => {
        state.organizationList.status = StateStatus.IDLE;
        state.organizationList.data = action.payload;
      })
      .addCase(getByType.rejected, (state, action) => {
        state.organizationList.status = StateStatus.FAILED;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const { } = organizationSlice.actions;

/* Selectors */
// Organization Details
const selectOrganizationDetails = (state: RootState): Organization => state.organization.organizationDetails.data;
const selectOrganizationDetailsStatus = (state: RootState): StateStatus => state.organization.organizationDetails.status;
// Organization List
const selectOrganizationList = (state: RootState): Organization[] => state.organization.organizationList.data;
const selectOrganizationListStatus = (state: RootState): StateStatus => state.organization.organizationList.status || StateStatus.IDLE;
const selectTotalOrganizationListCount = (state: RootState): number => state.organization.organizationList.totalOrganizationCount;
const selectOrganizationListLoaded = (state: RootState): boolean => state.organization.organizationList.loaded;
// School Districts
const selectSchoolDistricts = (state: RootState): Organization[] => state.organization.schoolDistricts.data;
// All Schools
const selectAllSchools = (state: RootState): Organization[] => state.organization.schools.data;
const selectAllSchoolsLoaded = (state: RootState): boolean => state.organization.schools.loaded;
// Community Org Type List
const selectCommunityOrganizationTypeList = (state: RootState): ListMenuItem[] => state.organization.communityOrganizationTypeList.data;

/* Memoized Selectors */
const selectFilteredSchoolsArgs = (state: RootState, filteredSchoolsArgs: { organizationType?: OrganizationType, schoolDistrictId?: number }) => filteredSchoolsArgs;

const selectByType = (state: RootState): Organization[] => state.organization.organizationList.data;

const selectFilteredSchools = createSelector([
  selectAllSchools,
  selectFilteredSchoolsArgs,
], (schools, filteredSchoolsArgs): Organization[] => {
  return schools.filter(s => (
    (!filteredSchoolsArgs.organizationType || s.type === filteredSchoolsArgs.organizationType) &&
    (!filteredSchoolsArgs.schoolDistrictId || s.schoolDistrict?.id === filteredSchoolsArgs.schoolDistrictId)
  ));
});

export const organizationSelectors = {
  selectOrganizationDetails,
  selectOrganizationDetailsStatus,
  selectOrganizationListStatus,
  selectOrganizationList,
  selectTotalOrganizationListCount,
  selectOrganizationListLoaded,
  selectSchoolDistricts,
  selectAllSchools,
  selectAllSchoolsLoaded,
  selectCommunityOrganizationTypeList,
  selectFilteredSchools,
  selectByType,
};

export default organizationSlice.reducer;
