
import React, { ReactNode } from 'react';
import { AppAssetPaths } from '../../app/app.types';

import './LandingPage.scss';


interface LandingPageProps { pageContent: ReactNode }

const LandingPage: React.FC<LandingPageProps> = ({ pageContent }) => {

  return (
    <div id="landing-page">

      <div className="content-container left-container">
        <div className="top-section">
          <img src={AppAssetPaths.images.PEOPLE_TALKING_AROUND_TABLE} alt="" />
        </div>

        <hr />

        <div className="bottom-section">
          <div>
            <h1> Keys to Success Admin</h1>
            <p>Sign in or create an admin account to manage the</p>
            <p> Keys to Success app.</p>
          </div>
        </div>
      </div>

      <div className="right-container">
        {pageContent}
        <p className="red-text footer">© 2021 by Keys to Success, a Success in Education Foundation Program</p>
      </div>
    </div>

  );
};

export default LandingPage;
