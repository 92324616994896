import React, {
  useEffect, useState
} from 'react';
import {
  FormGroup,
  FormLabel,
  Popover
} from '@mui/material';
import {
  FormProvider, SubmitHandler, useForm
} from 'react-hook-form';
import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import DropdownSelect from '../../../components/forms/DropdownSelect';
import {
  bottomRightAnchorOrigin, topCenterTransformOrigin
} from '../../../constants/menu.types';
import SubmitButton from '../../../components/forms/SubmitButton';
import CloseIcon from '@mui/icons-material/Close';
import { SecondaryColorRadioGroup } from '../../../components/CustomStyledComponents';
import {
  Organization, OrganizationType
} from '../../../features/organization/organization.model';
import { organizationSelectors } from '../../../features/organization/organization.slice';
import { getAllSchools } from '../../../features/organization/organization.thunks';
import { store } from '../../../app/store';
import { userSelectors } from '../../../features/user/user.slice';
import { UserRole } from '../../../features/user/user.model';
import { canUseAuthComponent } from '../../../app/app.types';
import { pathwaySelectors } from '../../../features/pathways/pathways.slice';
import { getPathwayTypeList } from '../../../features/pathways/pathways.thunks';


interface PathwayFilterProps {
  anchorElement: HTMLElement | null;
  setAnchorElement: (anchorElement: HTMLElement | null) => void;
  setFiltersCallback: (typeId?: number, schoolId?: number) => void;
}

const PathwayFilter: React.FC<PathwayFilterProps> = ({
  anchorElement,
  setAnchorElement,
  setFiltersCallback,
}) => {

  const dispatch = useAppDispatch();

  const user = useAppSelector(userSelectors.selectUser);
  const pathwayTypes = useAppSelector(pathwaySelectors.selectPathwayTypeList);
  const highSchools = organizationSelectors.selectFilteredSchools(store.getState(), {
    organizationType: OrganizationType.HighSchool,
    schoolDistrictId: user.roles.includes(UserRole.districtAdmin) ? user.organizationId : undefined,
  });
  const schoolsLoaded = useAppSelector(organizationSelectors.selectAllSchoolsLoaded);

  const [
    defaultSchool,
    setDefaultSchool,
  ] = useState<Organization | undefined>(undefined);

  useEffect(() => {
    dispatch(getPathwayTypeList());

    if (!schoolsLoaded) {
      dispatch(getAllSchools());
    }
  }, []);

  useEffect(() => {
    if (
      (canUseAuthComponent(user.roles, [
        UserRole.districtAdmin,
        UserRole.middleSchoolKeyContact,
        UserRole.middleSchoolCounselor,
      ])) &&
      !!highSchools.length
    ) {
      setDefaultSchool(highSchools[0]);
    }
  }, [
    highSchools,
  ]);

  type FormValues = { type: number, school: Organization };
  const methods = useForm<FormValues>();
  const { handleSubmit } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    const schoolId = +values.school?.id || defaultSchool?.id;
    setFiltersCallback(values.type, schoolId);
    setAnchorElement(null);
  };

  return (
    <Popover
      id="list-filter"
      anchorEl={anchorElement}
      open={Boolean(anchorElement)}
      onClose={() => setAnchorElement(null)}
      transformOrigin={topCenterTransformOrigin}
      anchorOrigin={bottomRightAnchorOrigin}
    >
      <CloseIcon className="close-icon" onClick={() => setAnchorElement(null)} />
      <form className="flex_col" onSubmit={handleSubmit(submitForm)}>
        <FormProvider {...methods}>
          <FormLabel id="type-filter">Type</FormLabel>
          <SecondaryColorRadioGroup
            name="type"
            itemList={pathwayTypes}
            labelledBy="type-filter"
            size="small"
          />

          {!user.roles.includes(UserRole.highSchoolKeyContact) && !user.roles.includes(UserRole.highSchoolCounselor) && (
            <>
              <FormLabel id="school-filter">School</FormLabel>
              <FormGroup
                aria-labelledby="school-filter"
              >
                <DropdownSelect
                  name="school"
                  itemList={highSchools}
                  defaultValue={defaultSchool}
                  size="small"
                  colorVariant="secondary"
                  disableClearable={canUseAuthComponent(user.roles, [
                    UserRole.districtAdmin,
                    UserRole.middleSchoolKeyContact,
                    UserRole.middleSchoolCounselor,
                  ])}
                />
              </FormGroup>
            </>
          )}

          <SubmitButton
            className="submit-button"
            text="Apply Filters"
            variant="contained"
            size="small"
            color="secondary"
            disableElevation
          />
        </FormProvider>
      </form>
    </Popover>
  );
};

export default PathwayFilter;
