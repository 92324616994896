import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import {
  HTTP_METHOD, requestFactory
} from '../../app/app.api';

export const createExportFile = (data: AxiosResponse, fileName: string): void => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  const json = data.data;
  const blob = new Blob([
    json,
  ], {
    type: 'text/csv; charset=utf-8',
  });
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const exportUserListCSV = async (userType: string, orgType?: string, graduationYears?: number[]): Promise<void> => {
  const orgTypeParam = orgType ? `?org_type=${orgType}` : '';
  const gradYearsParam = (graduationYears && !!graduationYears.length) ? `${orgTypeParam ? '?' : '&'}graduation_years=${graduationYears.join()}` : '';

  const url = `/export/users/${userType}${orgTypeParam}${gradYearsParam}`;
  const data = await requestFactory(HTTP_METHOD.GET, url);

  const fileName = `${orgType ? `${orgType}_` : ''}${userType}_list_${dayjs().format('MM/DD/YYYY')}.csv`;
  return createExportFile(data, fileName);
};

export const exportPointRankingsCSV = async (userType?: string): Promise<void> => {
  const userTypeParam = userType ? `?user_type=${userType}` : '';

  const url = `/export/point-rankings${userTypeParam}`;
  const data = await requestFactory(HTTP_METHOD.GET, url);

  const fileName = `${userType ? `${userType}_` : 'all_'}point_rankings_${dayjs().format('MM/DD/YYYY')}.csv`;
  return createExportFile(data, fileName);
};

export const exportUnactivatedUsersCSV = async (): Promise<void> => {
  const url = '/export/unactivated-users';
  const data = await requestFactory(HTTP_METHOD.GET, url);

  const fileName = `Unactivated_users_${dayjs().format('MM/DD/YYYY')}.csv`;
  return createExportFile(data, fileName);
};

export const exportPendingTransfersCSV = async (userType?: string): Promise<void> => {
  const userTypeParam = userType ? `?user_type=${userType}` : '';

  const url = `/export/pending-transfers${userTypeParam}`;
  const data = await requestFactory(HTTP_METHOD.GET, url);

  const fileName = `${userType ? `${userType}_` : 'all_'}pending_transfers_${dayjs().format('MM/DD/YYYY')}.csv`;
  return createExportFile(data, fileName);
};

export const exportPendingAccountsCSV = async (userType?: string): Promise<void> => {
  const userTypeParam = userType ? `?user_type=${userType}` : '';

  const url = `/export/pending-accounts${userTypeParam}`;
  const data = await requestFactory(HTTP_METHOD.GET, url);

  const fileName = `${userType ? `${userType}_` : 'all_'}pending_accounts_${dayjs().format('MM/DD/YYYY')}.csv`;
  return createExportFile(data, fileName);
};

export const exportApplicationsCSV = async (userType?: string): Promise<void> => {
  const userTypeParam = userType ? `?user_type=${userType}` : '';

  const url = `/export/resource-applications${userTypeParam}`;
  const data = await requestFactory(HTTP_METHOD.GET, url);

  const fileName = `${userType ? `${userType}_` : 'all_'}applications_${dayjs().format('MM/DD/YYYY')}.csv`;
  return createExportFile(data, fileName);
};

export const exportCommunityUsersByStatusCSV = async (status: string): Promise<void> => {
  const url = `/export/community-users/${status}`;
  const data = await requestFactory(HTTP_METHOD.GET, url);

  const fileName = `${status}_community_users_${dayjs().format('MM/DD/YYYY')}.csv`;
  return createExportFile(data, fileName);
};

export const exportMasterKeySubmissionsCSV = async (masterKeyId: number, masterKeyTitle = 'master_key'): Promise<void> => {
  const url = `/masterkey-submissions/export/${masterKeyId}`;
  const data = await requestFactory(HTTP_METHOD.GET, url);

  const fileName = `${masterKeyTitle}_submissions_${dayjs().format('MM/DD/YYYY')}.csv`;
  return createExportFile(data, fileName);
};

export const exportResouceApplicationsCSV = async (resourceType: string, resourceId: number, resourceTitle: string): Promise<void> => {
  const type = resourceType === 'scholarship'
    ? 'scholarships'
    : 'opportunities';
  const url = `/${type}/${resourceId}/applications/export`;
  const data = await requestFactory(HTTP_METHOD.GET, url);

  const fileName = `${resourceTitle}_applications_${dayjs().format('MM/DD/YYYY')}.csv`;
  return createExportFile(data, fileName);
};

export const exportCommunityOrganizationsCSV = async (): Promise<void> => {
  const data = await requestFactory(HTTP_METHOD.GET, '/export/community-organizations');
  const fileName = `community_organizations_${dayjs().format('MM/DD/YYYY')}.csv`;
  return createExportFile(data, fileName);
};

export const exportSchoolsCSV = async (): Promise<void> => {
  const data = await requestFactory(HTTP_METHOD.GET, '/export/schools');
  const fileName = `schools_${dayjs().format('MM/DD/YYYY')}.csv`;
  return createExportFile(data, fileName);
};

export const exportCollegeApplicationChecklistApplicantsCSV = async (applicantExportType?: 'applicants' | 'interested', userType?: 'adult' | 'high_school'): Promise<void> => {
  const exportType = applicantExportType ? `?type=${applicantExportType}` : '';
  const type = userType ? `&user_type=${userType}` : '';
  const data = await requestFactory(HTTP_METHOD.GET, `/export/college-application-checklist-applicants${exportType}${type}`);
  const fileName = `college-application-checklist-applicants_${dayjs().format('MM/DD/YYYY')}.csv`;
  return createExportFile(data, fileName);
};