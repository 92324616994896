/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  ConstructUserType, UserType
} from '../user/userType.model';

/*
 * Approval Summary
 */
export interface ApprovalSummary {
  resources: number;
  scholarshipApplications: number;
  notifications: number;
  studentApprovals?: number;
  studentTransfers?: number;
}

export interface ApprovalSummaryByType {
  highSchool: ApprovalSummary,
  middleSchool: ApprovalSummary,
  adult: ApprovalSummary,
}

export const ConstructApprovalSummary = (data: any): ApprovalSummary => {

  if (!data) {
    return {
      resources: 0,
      scholarshipApplications: 0,
      studentApprovals: 0,
      studentTransfers: 0,
      notifications: 0,
    };
  }

  return {
    resources: data.resources || 0,
    scholarshipApplications: data.scholarship_applications || 0,
    notifications: data.notifications || 0,
    studentApprovals: data.student_approvals || 0,
    studentTransfers: data.student_transfers || 0,
  };
};

/*
 * Scholarship Applications
 */
export interface SchoolScholarshipApplicationInfo {
  organizationId: number;
  organizationName: string;
  organizationType: string;
  needReviewCount: number;
  newCount: number;
}

export interface ScholarshipApplicationsByType {
  highSchool: SchoolScholarshipApplicationInfo[],
  middleSchool: SchoolScholarshipApplicationInfo[],
  adult: SchoolScholarshipApplicationInfo[],
}

export const ConstructSchoolScholarshipApplicationInfo = (data: any): SchoolScholarshipApplicationInfo => {

  return {
    organizationId: data.organization_id,
    organizationName: data.organization_name,
    organizationType: data.organization_type,
    needReviewCount: data.need_review_count,
    newCount: data.new_count,
  };
};

/*
 * Latest Announcements
 */
export interface LatestAnnouncement {
  id: number;
  message: string;
  link: string;
  startDate: string;
  endDate: string;
  userTypes: UserType[]
}

export const ConstructLatestAnnouncement = (data: any): LatestAnnouncement => {
  return {
    id: data.id,
    message: data.message,
    link: data.link,
    startDate: data.start_date,
    endDate: data.end_date,
    userTypes: data.user_types ? data.user_types.map((u: any) => ConstructUserType(u)) : [],
  };
};

/*
 * Expiring Resources
 */
export interface ExpiringResource {
  id: number;
  title: string;
  endDate: Date;
  expiringSoon: boolean;
  resourceType: string;
}

export const ConstructExpiringResource = (data: any): ExpiringResource => {

  return {
    id: data.id,
    title: data.title,
    endDate: data.end_date,
    expiringSoon: data.expiring_soon,
    resourceType: data.resource_type,
  };
};

/*
 * Current Master Keys
 */
export interface CurrentMasterKey {
  id: number;
  userTypeIds: number[];
  points: number;
  title: string;
  questions: any[];
  archived: boolean;
  submissionCount: number;
}

export interface CurrentMasterKeysByType {
  highSchool?: CurrentMasterKey;
  middleSchool?: CurrentMasterKey;
  college?: CurrentMasterKey;
}

export const ConstructCurrentMasterKey = (data: any): CurrentMasterKey => {
  return {
    id: data.id,
    userTypeIds: data.user_type_ids,
    points: data.points,
    title: data.title,
    questions: data.questions,
    archived: data.archived,
    submissionCount: data.submission_count,
  };
};

/*
 * Admin Announcements
 */
export interface AdminAnnouncement {
  id: number;
  message: string;
  link: string;
  userTypeIds: number[];
  educationLevels?: string[];
}

export const ConstructAdminAnnouncement = (data: any): AdminAnnouncement | undefined => {

  if (!data.length) {
    return undefined;
  }

  return {
    id: data[0].id,
    message: data[0].message,
    link: data[0].link,
    userTypeIds: data[0].user_type_ids,
    educationLevels: data[0].education_levels,
  };
};
