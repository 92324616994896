import {
  createSelector, createSlice
} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { StateStatus } from '../../app/app.types';
import {
  emptyUser, User
} from './user.model';
import {
  signIn, signOut
} from './auth.thunks';
import {
  createNewUser,
  getEducationLevels,
  getEthnicities,
  getGenders,
  getGraduationYears,
  getHeardAboutKtsReasons,
  getUser,
  getUserList,
  getUserTypeList,
  signUp
} from './user.thunks';
import { ListMenuItem } from '../../components/forms/form.types';
import { UserType } from './userType.model';


/* State */

export interface UserState {
  adminUser: {
    status: StateStatus;
    user: User;
    userLoaded: boolean;
  }
  createNewUser: {
    status: StateStatus;
  }
  userList: {
    status: StateStatus;
    data: User[];
    totalUserCount: number;
    loaded: boolean;
  }
  genders: {
    status: StateStatus;
    data: ListMenuItem[];
    loaded: boolean;
  }
  ethnicities: {
    status: StateStatus;
    data: ListMenuItem[];
    loaded: boolean;
  }
  educationLevels: {
    status: StateStatus;
    data: ListMenuItem[];
    loaded: boolean;
  }
  heardAboutKtsReasons: {
    status: StateStatus;
    data: ListMenuItem[];
  }
  graduationYears: {
    status: StateStatus;
    data: ListMenuItem[]
  }
  userTypeList: {
    status: StateStatus;
    data: UserType[]
  }
}

const initialState: UserState = {
  adminUser: {
    status: StateStatus.IDLE,
    user: emptyUser,
    userLoaded: false,
  },
  createNewUser: {
    status: StateStatus.IDLE,
  },
  userList: {
    status: StateStatus.IDLE,
    data: [],
    totalUserCount: 0,
    loaded: false,
  },
  genders: {
    status: StateStatus.IDLE,
    data: [],
    loaded: false,
  },
  ethnicities: {
    status: StateStatus.IDLE,
    data: [],
    loaded: false,
  },
  educationLevels: {
    status: StateStatus.IDLE,
    data: [],
    loaded: false,
  },
  heardAboutKtsReasons: {
    status: StateStatus.IDLE,
    data: [],
  },
  graduationYears: {
    status: StateStatus.IDLE,
    data: [],
  },
  userTypeList: {
    status: StateStatus.IDLE,
    data: [],
  },
};


/* Reducer */

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      // Auth reducers
      .addCase(signIn.pending, (state) => {
        state.adminUser.status = StateStatus.LOADING;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.adminUser.status = StateStatus.IDLE;
        state.adminUser.user = action.payload as User;
        state.adminUser.userLoaded = true;
      })
      .addCase(signIn.rejected, (state) => {
        state.adminUser.status = StateStatus.FAILED;
      })
      .addCase(signOut.pending, (state) => {
        state.adminUser.status = StateStatus.LOADING;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.adminUser.status = StateStatus.IDLE;
        state.adminUser.user = emptyUser;
        state.adminUser.userLoaded = false;
      })
      .addCase(signUp.pending, (state) => {
        state.adminUser.status = StateStatus.LOADING;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.adminUser.status = StateStatus.IDLE;
        state.adminUser.user = action.payload;
        state.adminUser.userLoaded = true;
      })
      .addCase(signUp.rejected, (state) => {
        state.adminUser.status = StateStatus.FAILED;
      })
      // Create New User
      .addCase(createNewUser.pending, (state) => {
        state.createNewUser.status = StateStatus.LOADING;
      })
      .addCase(createNewUser.fulfilled, (state, action) => {
        state.createNewUser.status = StateStatus.IDLE;
      })
      .addCase(createNewUser.rejected, (state) => {
        state.createNewUser.status = StateStatus.FAILED;
      })
      // Get User
      .addCase(getUser.pending, (state) => {
        state.adminUser.status = StateStatus.LOADING;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.adminUser.status = StateStatus.IDLE;
        state.adminUser.user = action.payload;
        state.adminUser.userLoaded = true;
      })
      .addCase(getUser.rejected, (state) => {
        state.adminUser.status = StateStatus.FAILED;
      })
      // Get User List
      .addCase(getUserList.pending, (state) => {
        state.userList.status = StateStatus.LOADING;
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        state.userList.status = StateStatus.IDLE;
        state.userList.data = action.payload.users;
        state.userList.totalUserCount = action.payload.count;
        state.userList.loaded = true;
      })
      .addCase(getUserList.rejected, (state) => {
        state.userList.status = StateStatus.FAILED;
      })
      // Get dropdown item lists
      .addCase(getGenders.pending, (state) => {
        state.genders.status = StateStatus.LOADING;
      })
      .addCase(getGenders.fulfilled, (state, action) => {
        state.genders.status = StateStatus.IDLE;
        state.genders.data = action.payload;
        state.genders.loaded = true;
      })
      .addCase(getGenders.rejected, (state) => {
        state.genders.status = StateStatus.FAILED;
      })
      .addCase(getEthnicities.pending, (state) => {
        state.ethnicities.status = StateStatus.LOADING;
      })
      .addCase(getEthnicities.fulfilled, (state, action) => {
        state.ethnicities.status = StateStatus.IDLE;
        state.ethnicities.data = action.payload;
        state.ethnicities.loaded = true;
      })
      .addCase(getEthnicities.rejected, (state) => {
        state.ethnicities.status = StateStatus.FAILED;
      })
      .addCase(getEducationLevels.pending, (state) => {
        state.educationLevels.status = StateStatus.LOADING;
      })
      .addCase(getEducationLevels.fulfilled, (state, action) => {
        state.educationLevels.status = StateStatus.IDLE;
        state.educationLevels.data = action.payload;
        state.educationLevels.loaded = true;
      })
      .addCase(getEducationLevels.rejected, (state) => {
        state.educationLevels.status = StateStatus.FAILED;
      })
      .addCase(getHeardAboutKtsReasons.pending, (state) => {
        state.heardAboutKtsReasons.status = StateStatus.LOADING;
      })
      .addCase(getHeardAboutKtsReasons.fulfilled, (state, action) => {
        state.heardAboutKtsReasons.status = StateStatus.IDLE;
        state.heardAboutKtsReasons.data = action.payload;
      })
      .addCase(getHeardAboutKtsReasons.rejected, (state) => {
        state.heardAboutKtsReasons.status = StateStatus.FAILED;
      })
      .addCase(getGraduationYears.pending, (state) => {
        state.graduationYears.status = StateStatus.LOADING;
      })
      .addCase(getGraduationYears.fulfilled, (state, action) => {
        state.graduationYears.status = StateStatus.IDLE;
        state.graduationYears.data = action.payload;
      })
      .addCase(getGraduationYears.rejected, (state) => {
        state.graduationYears.status = StateStatus.FAILED;
      })
      .addCase(getUserTypeList.pending, (state) => {
        state.userTypeList.status = StateStatus.LOADING;
      })
      .addCase(getUserTypeList.fulfilled, (state, action) => {
        state.userTypeList.status = StateStatus.IDLE;
        state.userTypeList.data = action.payload;
      })
      .addCase(getUserTypeList.rejected, (state) => {
        state.userTypeList.status = StateStatus.FAILED;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const { } = userSlice.actions;


/* Selectors */

const selectUserStatus = (state: RootState): StateStatus => state.user.adminUser.status;
const selectUser = (state: RootState): User => state.user.adminUser.user;
const selectUserLoaded = (state: RootState): boolean => state.user.adminUser.userLoaded;
const selectUserListStatus = (state: RootState): StateStatus => state.user.userList.status;
const selectUserList = (state: RootState): User[] => state.user.userList.data;
const selectTotalUserListCount = (state: RootState): number => state.user.userList.totalUserCount;
const selectUserListLoaded = (state: RootState): boolean => state.user.userList.loaded;
const selectGenders = (state: RootState): ListMenuItem[] => state.user.genders.data;
const selectGendersLoaded = (state: RootState): boolean => state.user.genders.loaded;
const selectEthnicities = (state: RootState): ListMenuItem[] => state.user.ethnicities.data;
const selectEthnicitiesLoaded = (state: RootState): boolean => state.user.ethnicities.loaded;
const selectEducationLevels = (state: RootState): ListMenuItem[] => state.user.educationLevels.data;
const selectEducationLevelsLoaded = (state: RootState): boolean => state.user.educationLevels.loaded;
const selectHeardAboutKtsReasons = (state: RootState): ListMenuItem[] => state.user.heardAboutKtsReasons.data;
const selectGraduationYearsStatus = (state: RootState): StateStatus => state.user.graduationYears.status;
const selectGraduationYears = (state: RootState): ListMenuItem[] => state.user.graduationYears.data;
const selectUserTypeList = (state: RootState): UserType[] => state.user.userTypeList.data;

// Create New User
const selectCreateNewUserStatus = (state: RootState): StateStatus => state.user.createNewUser.status;

/* Memoized Selectors */
const selectFilteredUserTypesArgs = (state: RootState, filteredUserTypesArgs: { omittedTypes?: string[], isAdmin?: boolean }) => filteredUserTypesArgs;

const selectFilteredUserTypes = createSelector([
  selectUserTypeList,
  selectFilteredUserTypesArgs,
], (userTypes, filteredUserTypesArgs): UserType[] => {
  return userTypes.filter(u => (
    (!filteredUserTypesArgs.omittedTypes || !filteredUserTypesArgs.omittedTypes.length || !filteredUserTypesArgs.omittedTypes.includes(u.label)) &&
    (filteredUserTypesArgs.isAdmin == null || u.isAdminType === filteredUserTypesArgs.isAdmin)
  ));
});

export const userSelectors = {
  selectUserStatus,
  selectUser,
  selectUserLoaded,
  selectUserListStatus,
  selectUserList,
  selectTotalUserListCount,
  selectUserListLoaded,
  selectGenders,
  selectGendersLoaded,
  selectEthnicities,
  selectEthnicitiesLoaded,
  selectEducationLevels,
  selectEducationLevelsLoaded,
  selectHeardAboutKtsReasons,
  selectGraduationYearsStatus,
  selectGraduationYears,
  selectCreateNewUserStatus,
  selectUserTypeList,
  selectFilteredUserTypes,
};

export default userSlice.reducer;
