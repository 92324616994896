import { UserRole } from '../../user/user.model';
import {
  AppRoutes, AppAssetPaths, AuthComponent
} from '../../../app/app.types';


export interface ApprovalSubItem extends AuthComponent {
  title: string;
  amountKey: string;
  icon: string;
  route: string;
}

export interface ApprovalTab extends AuthComponent {
  title: string;
  dataKey: string;
  cards: ApprovalSubItem[]
}

export const DashboardApprovalSummary: AuthComponent = {
  authorizedRoles: [
    UserRole.admin,
    UserRole.districtAdmin,
    UserRole.middleSchoolKeyContact,
    UserRole.highSchoolKeyContact,
  ],
};

export const ApprovalTabSubItems: ApprovalSubItem[] = [
  {
    title: 'Resources',
    amountKey: 'resources',
    icon: AppAssetPaths.icons.RESOURCES_ICON_RECT,
    route: AppRoutes.resourceApprovals.path,
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  {
    title: 'Scholarship App.',
    amountKey: 'scholarshipApplications',
    icon: AppAssetPaths.icons.SCHOLARSHIP_APPS_RECT,
    route: AppRoutes.applicationApprovals.path,
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  {
    title: 'User Accounts',
    amountKey: 'studentApprovals',
    icon: AppAssetPaths.icons.USER_ACCOUNTS_RECT,
    route: AppRoutes.studentApprovals.path,
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.middleSchoolKeyContact,
      UserRole.highSchoolKeyContact,
    ],
  },
  {
    title: 'User Transfers',
    amountKey: 'studentTransfers',
    icon: AppAssetPaths.icons.USER_TRANSFERS_RECT,
    route: AppRoutes.transferApprovals.path,
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.middleSchoolKeyContact,
      UserRole.highSchoolKeyContact,
    ],
  },
  {
    title: 'Notifications',
    amountKey: 'notifications',
    icon: AppAssetPaths.icons.NOTIFICATIONS_RECT,
    route: AppRoutes.notificationApprovals.path,
    authorizedRoles: [
      UserRole.admin,
    ],
  },
];

export const ApprovalSummaryTabs: ApprovalTab[] = [
  {
    title: 'High School',
    dataKey: 'highSchool',
    cards: ApprovalTabSubItems,
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.highSchoolKeyContact,
    ],
  },
  {
    title: 'Middle School / Jr. High',
    dataKey: 'middleSchool',
    cards: ApprovalTabSubItems,
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.middleSchoolKeyContact,
    ],
  },
  {
    title: 'Adult',
    dataKey: 'adult',
    cards: ApprovalTabSubItems,
    authorizedRoles: [
      UserRole.admin,
    ],
  },
];
