import { Tooltip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import React, {
  createRef, useLayoutEffect, useState
} from 'react';


export interface TableCellTooltipProps {
  params: GridRenderCellParams
}

const TableCellTooltip: React.FC<TableCellTooltipProps> = ({ params }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const newRef: any = createRef();

  const [
    showTip,
    setShowTip,
  ] = useState(false);

  useLayoutEffect(() => {
    setShowTip(newRef.current.offsetWidth < newRef.current.scrollWidth);
  }, [
    newRef,
  ]);


  return <Tooltip
    ref={newRef}
    title={showTip === true ? params.value : ''}
    style={{
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }}
    arrow
    placement="right"
  >
    <span>{params.value}</span>
  </Tooltip >;

};

export default TableCellTooltip;
