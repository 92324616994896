import React from 'react';
import ExpandableCard from '../ExpandableCard';
import { Resource } from '../../../features/resources/resources.model';

import './OpportunitiesTable.scss';


interface OpportunitiesTableProps {
  opportunities?: Resource[];
  defaultExpanded?: boolean;
}

const OpportunitiesTable: React.FC<OpportunitiesTableProps> = ({ opportunities, defaultExpanded }) => {
  const EMPTY = '-';

  if (!opportunities) {
    opportunities = [];
  }

  const getDataRow = (header: boolean, key?: number, title?: string, orgName?: string, orgUrl?: string) => (
    <div key={key} className={`opportunities-data-row flex ${header ? 'header' : ''}`}>
      <div className="opportunities-data-cell flex_column_astart">
        <div className="opportunities-data-label">Opportunity Title</div>
        <div className="opportunities-data-content org-data">{ title || EMPTY }</div>
      </div>
      <div className="opportunities-data-cell flex_column_astart">
        <div className="opportunities-data-label">Organizational Name</div>
        <div className="opportunities-data-content org-data">{ orgName?.toLowerCase() || EMPTY }</div>
      </div>
      <div className="opportunities-data-cell flex_column_astart">
        <div className="opportunities-data-label">Organizational URL</div>
        <div className="opportunities-data-content">{ orgUrl || EMPTY }</div>
      </div>
    </div>
  );

  const getDetails = () => (
    <div className="opportunities-data-wrapper flex_column">
      { getDataRow(true) }
      { !!opportunities?.length && opportunities.map((app, i) => getDataRow(false, i, app.title, app.organizationName, app.organizationUrl)) }
    </div>
  );

  return (
    <ExpandableCard
      className="user-opportunities-table"
      summary={<span>Opportunities</span>}
      details={getDetails()}
      expandable
      defaultExpanded={defaultExpanded}
    />
  );
};

export default OpportunitiesTable;
