/* eslint-disable react/display-name */
import React from 'react';
import {
  GridColumns, GridValueFormatterParams, GridRenderCellParams, GridActionsCellItem, GridRowParams, GridValueGetterParams
} from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { AppAssetPaths } from '../../app/app.types';
import {
  setShowMasterKeyDeleteConfirmDialog, setMasterKeyIdToDelete
} from '../../features/masterKey/masterKey.slice';
import { store } from '../../app/store';
import { UserType } from '../../features/user/userType.model';
import { ListMenuItem } from '../../components/forms/form.types';


export enum AlertType {
  Notification = 'notification',
  Announcement = 'announcement',
  MasterKey = 'master_key'
}

export const notificationListColumns: GridColumns = [
  {
    headerName: 'Subject',
    field: 'subject',
    flex: .6,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    cellClassName: 'main-column',
  },
  {
    headerName: 'Message',
    field: 'body',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Type',
    field: 'is_push',
    flex: .25,
    valueGetter: (params: GridValueGetterParams) => (params.row.isPush ? 'Push' : 'In-app'),

  },
  {
    headerName: 'Sent Date',
    field: 'createdAt',
    flex: .2,
    valueFormatter: (params: GridValueFormatterParams) => dayjs(params.value as string).format('MM/DD/YYYY') || '-',
  },
];

export const announcementListColumns: GridColumns = [
  {
    headerName: 'Announcement',
    field: 'message',
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    cellClassName: 'main-column',
    flex: 1.2,
  },
  {
    headerName: 'User Type',
    field: 'userTypes',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Role',
    field: 'roles',
    flex: .4,
    sortable: false,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Education Level',
    field: 'educationLevels',
    flex: .4,
    valueFormatter: (params: GridValueFormatterParams) => params.value?.map((item: ListMenuItem) => item.label).join(', ') || '-',
  },
  {
    headerName: 'Status',
    field: 'status',
    flex: .3,
    sortable: false,
    renderCell: (params: GridRenderCellParams<boolean | undefined>) => (
      <div className="flex_acenter">
        {(params.row.status === 'expired') ? (
          <img src={AppAssetPaths.icons.statusIcons.EXPIRED} alt="expired" />
        ) : (params.row.status === 'active') ? (
          <img src={AppAssetPaths.icons.statusIcons.ACTIVE} alt="active" />
        ) : (params.row.status === 'inactive') ? (
          <img src={AppAssetPaths.icons.statusIcons.INACTIVE} alt="inactive" />
        ) : (
          <p>-</p>
        )}
      </div>
    ),
  },
  {
    headerName: 'End Date',
    field: 'endDate',
    flex: .2,
    valueFormatter: (params: GridValueFormatterParams) => dayjs(params.value as string).format('MM/DD/YYYY') || '-',
  },
];

export const masterKeyListColumns: GridColumns = [
  {
    headerName: 'Title',
    field: 'title',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    cellClassName: 'main-column',
  },
  {
    headerName: 'Master Key Type',
    field: 'masterKeyType',
    flex: .5,
    valueGetter: (params: GridValueGetterParams) => params.value.label || '-',
  },
  {
    headerName: 'User Type',
    field: 'userTypes',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value.length ? params.value.map((u: UserType) => u.label).join(', ') : '-',
  },
  {
    headerName: 'Start Date',
    field: 'startDate',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => dayjs(params.value as string).format('MM/DD/YYYY') || '-',
  },
  {
    headerName: 'End Date',
    field: 'endDate',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => dayjs(params.value as string).format('MM/DD/YYYY') || '-',
  },
  {
    headerName: 'Status',
    field: 'status',
    flex: .5,
    sortable: false,
    renderCell: (params: GridRenderCellParams<boolean | undefined>) => (
      <div className="flex_acenter">
        {(params.row.archived) ? (
          <img src={AppAssetPaths.icons.statusIcons.ARCHIVED} alt="archived" />
        ) : (params.row.status === 'expired') ? (
          <img src={AppAssetPaths.icons.statusIcons.EXPIRED} alt="expired" />
        ) : (params.row.status === 'active') ? (
          <img src={AppAssetPaths.icons.statusIcons.ACTIVE} alt="active" />
        ) : (params.row.status === 'inactive') ? (
          <img src={AppAssetPaths.icons.statusIcons.INACTIVE} alt="inactive" />
        ) : (
          <p>-</p>
        )}
      </div>
    ),
  },
  {
    headerName: '',
    field: 'delete',
    type: 'actions',
    getActions: (params: GridRowParams) => [
      (params.row.archived) ? (
        <GridActionsCellItem
          label="delete"
          icon={
            <img src={AppAssetPaths.icons.TRASH} alt="delete" />
          }
          onClick={() => {
            store.dispatch(setShowMasterKeyDeleteConfirmDialog(true));
            store.dispatch(setMasterKeyIdToDelete(params.row.id));
          }}
          disableRipple
        />
      ) : (<></>),
    ],
  },
];

export const masterKeySubmissionListColumns: GridColumns = [
  {
    headerName: 'Student',
    field: 'user',
    flex: 1.5,
    valueGetter: (params: GridValueGetterParams) => params.row.user?.fullname?.toLowerCase() || '-',
    cellClassName: 'main-column',
  },
  {
    headerName: 'Submission Date',
    field: 'createdAt',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => dayjs(params.value as string).format('MM/DD/YYYY') || '-',
  },
  {
    headerName: 'Status',
    field: 'status',
    flex: .5,
    sortable: false,
    renderCell: (params: GridRenderCellParams<boolean | null>) => (
      <div className="flex_acenter">
        {(params.row.pointsAwarded === true) ? (
          <img src={AppAssetPaths.icons.statusIcons.POINTS_AWARDED} alt="points awarded" />
        ) : (params.row.pointsAwarded === false) ? (
          <img src={AppAssetPaths.icons.statusIcons.POINTS_DENIED} alt="points denied" />
        ) : (
          <img src={AppAssetPaths.icons.statusIcons.PENDING_REVIEW} alt="pending review" />
        )}
      </div>
    ),
  },
];
