/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';
import { AppConfigVariables } from '../../app/app.types';
import { loadConfigData } from './config.thunks';


/* State */

export interface ConfigState {
  environmentConfig: {
    status: string;
    data: AppConfigVariables;
  };
}

const emptyConfig: AppConfigVariables = {
  api_url: '',
  student_base_url: '',
};

const initialState: ConfigState = {
  environmentConfig: {
    status: 'idle',
    data: emptyConfig,
  },
};


/* Reducer */

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadConfigData.pending, (state) => {
        state.environmentConfig.status = 'loading';
      })
      .addCase(loadConfigData.fulfilled, (state, action) => {
        state.environmentConfig.status = 'idle';
        state.environmentConfig.data = action.payload;
      })
      .addCase(loadConfigData.rejected, (state) => {
        state.environmentConfig.status = 'failed';
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const { } = configSlice.actions;

export default configSlice.reducer;
