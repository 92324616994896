
import React from 'react';
import {
  FormProvider, SubmitHandler, useForm
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  AppAssetPaths, AppRoutes
} from '../../../app/app.types';
import TextInput from '../../../components/forms/TextInput';
import SubmitButton from '../../../components/forms/SubmitButton';
import {
  Link, useNavigate, useLocation
} from 'react-router-dom';
import { resetPassword } from '../../../features/user/auth.thunks';
import { useAppDispatch } from '../../../app/hooks';
import { setAlert } from '../../../features/alert/alert.slice';


interface ResetPasswordProps { }

const ResetPassword: React.FC<ResetPasswordProps> = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const formValidationSchema = yup.object().shape({
    token: yup.string().required('Token is required'),
    password: (yup).string()
      .required('Password is required')
      .isStrongPassword(),
    // eslint-disable-next-line camelcase
    password_confirmation: yup.string()
      .oneOf([
        yup.ref('password'),
      ], 'Passwords do not match')
      .required('Password confirmation is required'),
  }).required();

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const { handleSubmit, formState: { errors } } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    const body = {
      email: state,
      ...values,
    };

    dispatch(resetPassword(body))
      .unwrap()
      .then(() => navigate(AppRoutes.passwordUpdated.path))
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to reset password',
        }));
      });
  };

  return (
    <form className="content-container flex" onSubmit={handleSubmit(submitForm)}>
      <div className="top-section">
        <img src={AppAssetPaths.images.KTS_LOGO} alt="" />
        <h2>Reset Password</h2>
        <p className="subtitle">A six-digit token has been sent to {state}</p>

        <FormProvider {...methods}>
          <TextInput
            containerClass="main-input"
            name="token"
            label="Token"
            errorMessage={errors.token && errors.token.message}
            type="text"
            size="small"
            autoComplete="new-password"
          />

          <TextInput
            containerClass="main-input"
            name="password"
            label="New Password"
            errorMessage={errors.password && errors.password.message}
            type="password"
            size="small"
            autoComplete="new-password"
          />

          <TextInput
            containerClass="main-input"
            name="password_confirmation"
            label="Confirm New Password"
            errorMessage={errors.password_confirmation && errors.password_confirmation.message}
            type="password"
            size="small"
            autoComplete="new-password"
          />

          <SubmitButton
            className="submit-button"
            text="Submit"
            variant="contained"
            size="large"
            disableElevation
          />

          <Link className="centered-link" to={AppRoutes.signIn.path}>
            <strong>Back to Sign In</strong>
          </Link>
        </FormProvider>
      </div>
    </form>
  );
};

export default ResetPassword;
