/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { GridSortModel } from '@mui/x-data-grid';
import { ResourceType } from '../resources/resources.model';


export enum ApprovalType {
  student = 'student',
  transfer = 'transfer',
  resource = 'resource',
  notification = 'notification',
  application = 'application'
}

export interface ApprovalListQueryParams {
  offset: number;
  search?: string;
  userType?: string;
  schoolType?: string;
  limit?: number;
  approvalType?: ApprovalType;
  sortModel?: GridSortModel;
}


export interface StudentApproval {
  id: number;
  name: string;
  school: string;
  email: string;
  createdAt: string;
  userId?: number;
  status?: boolean | null;
}

export const EmptyStudentApproval: StudentApproval = {
  id: 0,
  name: '',
  school: '',
  email: '',
  createdAt: '',
  status: undefined,
};

export interface TransferApproval {
  id: number;
  name: string;
  email: string;
  currentSchool: string;
  newSchool: string;
  createdAt: string;
  completedAt?: string;
  status?: boolean
  userId?: number;
  approved?: boolean;
}

export const EmptyTransferApproval: TransferApproval = {
  id: 0,
  name: '',
  email: '',
  currentSchool: '',
  newSchool: '',
  createdAt: '',
  status: undefined,
};

export interface NotificationApproval {
  id: number;
  title: string;
  creator: string;
  school: string;
  recipientCategory: string;
  createdAt: string;
  status?: boolean;
}

export const EmptyNotificationApproval: NotificationApproval = {
  id: 0,
  title: '',
  creator: '',
  school: '',
  recipientCategory: '',
  createdAt: '',
  status: undefined,
};

export interface SkillTrainingApproval {
  id: number;
  name: string;
  institution: string;
  link: string;
  createdAt: string;
  status?: boolean;
}

export const EmptySkillTrainingApproval: SkillTrainingApproval = {
  id: 0,
  name: '',
  institution: '',
  link: '',
  createdAt: '',
  status: undefined,
};

export interface ResourceApproval {
  id: number;
  contentType: ResourceType | null;
  title: string;
  creator: string;
  createDate: string;
  status?: boolean;
}
export const EmptyResourceApproval: ResourceApproval = {
  id: 0,
  contentType: null,
  title: '',
  creator: '',
  createDate: '',
  status: undefined,
};

// Placeholders
export interface ApplicationApproval {
  id: number;
  name: string;
  userId: string;
  school: string;
  scholarship: string;
  scholarshipId: string;
  graduationYear: string;
  createdAt: string;
  status?: boolean;
}
export const EmptyApplicationApproval: ApplicationApproval = {
  id: 0,
  name: '',
  userId: '',
  school: '',
  scholarship: '',
  scholarshipId: '',
  graduationYear: '',
  createdAt: '',
  status: undefined,
};

export const getRecipientCategory = (data: [], careers: any) => {
  if (data.length > 0) {
    return (data.map((id) => {
      const index = careers.findIndex((x: { id: any; }) => x.id === id);
      return careers[index].title;
    })).join(', ');

  } else {
    return '-';
  }

};

export const ConstructStudentApproval = (data: any): StudentApproval => {
  return {
    id: data.id,
    name: `${data.user.first_name} ${data.user.last_name}`,
    school: data.organization.name,
    email: data.user.email,
    createdAt: data.created_at,
    status: data.approved,
    userId: data.user_id,
  };
};

export const ConstructTransferApproval = (data: any): TransferApproval => {
  return {
    id: data.id,
    name: data.user ? `${data.user.first_name} ${data.user.last_name}` : '',
    email: data.user?.email || '',
    currentSchool: data.from_organization.name,
    newSchool: data.to_organization.name,
    createdAt: data.created_at,
    completedAt: data.completed_at,
    status: data.approved,
    userId: data.user_id,
    approved: data.approved,
  };
};

export const ConstructNotificationApproval = (data: any, careers: any): NotificationApproval => {
  return {
    id: data.id,
    title: data.subject,
    creator: `${data.created_by.first_name} ${data.created_by.last_name}`,
    school: data.created_by.organization.name,
    recipientCategory: getRecipientCategory(data.career_group_ids, careers),
    createdAt: data.created_at,
    // In the case of notifications & Resources, if approved is false, its pending.
    status: data.approved || undefined,
  };
};

export const ConstructSkillTrainingApproval = (data: any): SkillTrainingApproval => {
  return {
    id: data.resource.id,
    name: data.resource.name,
    link: data.resource.link || '',
    institution: data.resource.company,
    createdAt: data.created_at,
    status: data.resource.approved || undefined,
  };
};



export const ConstructResourceApproval = (data:any): ResourceApproval => {
  return {
    id: data.resource_id,
    contentType: data.content_type,
    title: data.resource.title,
    creator: data.organization_name,
    createDate: data.created_at|| data.createdAt,
    // In the case of notifications & Resources, if approved is false, its pending.
    status: data.resource.approved || undefined,
  };
};

export const ConstructApplicationApproval = (data: any): ApplicationApproval => {
  return {
    id: data.id,
    name: `${data.user.first_name} ${data.user.last_name}`,
    userId: data.user_id,
    school: data.user.organization.name,
    scholarship: data.scholarship.title,
    scholarshipId: data.scholarship_id,
    graduationYear: data.user.graduation_year,
    createdAt: data.created_at,
    status: data.approved || null,
  };
};