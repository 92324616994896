const SESSION_TOKEN = 'SESSION_TOKEN';
const SELECT_ALL_USERS_TOOLTIP_SEEN = 'SELECT_ALL_USERS_TOOLTIP_SEEN';
const SELECT_ALL_BULK_APPROVE_REJECT_TOOLTIP_SEEN = 'SELECT_ALL_BULK_APPROVE_REJECT_TOOLTIP_SEEN';

/* Session Token */
export const getSessionToken = (): string | null => {
  return localStorage.getItem(SESSION_TOKEN);
};

export const setSessionToken = (token: string): void => {
  localStorage.setItem(SESSION_TOKEN, token);
};

export const removeSessionToken = (): void => {
  localStorage.removeItem(SESSION_TOKEN);
};

/* Select All Users Tooltip */
export const getSelectAllUsersTooltipSeen = (): string | null => {
  return localStorage.getItem(SELECT_ALL_USERS_TOOLTIP_SEEN);
};

export const setSelectAllUsersTooltipSeen = (): void => {
  localStorage.setItem(SELECT_ALL_USERS_TOOLTIP_SEEN, 'true');
};

/* Select All Bulk Approve Reject Tooltip */
export const getSelectAllBulkApproveRejectTooltipSeen = (): string | null => {
  return localStorage.getItem(SELECT_ALL_BULK_APPROVE_REJECT_TOOLTIP_SEEN);
};
export const setSelectAllBulkApproveRejectTooltipSeen = (): void => {
  return localStorage.setItem(SELECT_ALL_BULK_APPROVE_REJECT_TOOLTIP_SEEN, 'true');
};
