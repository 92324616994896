import React, { ReactElement } from 'react';
import ExpandableCard from '../ExpandableCard';
import {
  PathwayCourse, PathwayCourseSchool
} from '../../../features/pathways/pathways.model';
import { addUrlProtocol } from '../../../util/formatting';
import { useAppSelector } from '../../../app/hooks';
import { userSelectors } from '../../../features/user/user.slice';
import { AppAssetPaths } from '../../../app/app.types';

import './CourseTable.scss';


interface SupportingCoursesTableProps {
  courses?: PathwayCourse[];
  defaultExpanded?: boolean;
  getCourseActiveStatus: (id?: number, active?: boolean, pathwayCourseSchool?: PathwayCourseSchool) => ReactElement;
  isConcurrentEnrollment: (course?: PathwayCourse) => boolean;
  showUpdateBanner: (courses?: PathwayCourse[]) => boolean;
}

const SupportingCoursesTable: React.FC<SupportingCoursesTableProps> = ({
  courses,
  defaultExpanded,
  getCourseActiveStatus,
  isConcurrentEnrollment,
  showUpdateBanner,
}) => {
  const EMPTY = '-';

  const user = useAppSelector(userSelectors.selectUser);

  if (!courses) {
    courses = [];
  }

  const getDataRow = (header: boolean, course?: PathwayCourse) => (
    <div key={course?.id} className={`data-row flex_jstretch_acenter ${header ? 'header' : ''}`}>
      <div className="data-cell name">
        <div className="data-label">Course Name</div>
        <div className={`data-content ${user.isHighSchoolAdmin && course?.pathwayCourseSchool?.updatedValues?.alternateCourseName ? 'red-text' : ''}`}>
          {(!user.isAdmin && course?.pathwayCourseSchool?.alternateCourseName ? course.pathwayCourseSchool.alternateCourseName : course?.name) || EMPTY}
        </div>
      </div>
      <div className="data-cell link">
        <div className="data-label">Link</div>
        <div className="data-content">
          {course?.link ? (
            <a href={addUrlProtocol(course?.link)} target="_blank" rel="noreferrer noopener">
              <p>{course?.link}</p>
            </a>
          ) : (
            <p>{EMPTY}</p>
          )}
        </div>
      </div>
      <div className="data-cell concurrent-enrollment">
        <div className="data-label">Concurrent Enrollment</div>
        <div className={`data-content ${user.isHighSchoolAdmin && course?.pathwayCourseSchool?.updatedValues?.concurrentEnrollment ? 'red-text' : ''}`}>
          {isConcurrentEnrollment(course) ? 'Yes' : 'No'}
        </div>
      </div>
      <div className="data-cell recommended" />
      <div className="data-cell active">
        <div className="data-label" />
        <div className="data-content">
          {getCourseActiveStatus(course?.id, course?.active, course?.pathwayCourseSchool)}
        </div>
      </div>
    </div>
  );

  const getDetails = () => (
    <div className="data-wrapper">
      {getDataRow(true)}
      {!!courses?.length && courses.map(c => getDataRow(false, c))}
    </div>
  );

  return (
    <ExpandableCard
      className="course-table"
      summary={<span>Supporting Courses</span>}
      summaryTag={showUpdateBanner(courses) && <img className="table-update-icon" src={AppAssetPaths.icons.statusIcons.UPDATE} alt="update" />}
      summaryDescription="Suggested high school courses that support the Career Pathway (Courses DO NOT count towards the Pathway)."
      details={getDetails()}
      expandable
      defaultExpanded={defaultExpanded}
    />
  );
};

export default SupportingCoursesTable;
