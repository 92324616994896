/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HTTP_METHOD, requestFactory
} from '../../app/app.api';
import { UserRole } from '../user/user.model';
import {
  LatestAnnouncement,
  ConstructLatestAnnouncement,
  ExpiringResource,
  ConstructExpiringResource,
  ScholarshipApplicationsByType,
  ConstructSchoolScholarshipApplicationInfo,
  ApprovalSummaryByType,
  ConstructApprovalSummary,
  CurrentMasterKeysByType,
  ConstructCurrentMasterKey,
  AdminAnnouncement,
  ConstructAdminAnnouncement
} from './dashboard.model';


export const getApprovalsSummary = async (userRoles: string[]): Promise<ApprovalSummaryByType> => {
  let url = '/approvals/summary';

  if (userRoles.includes(UserRole.districtAdmin)) {
    url = '/approvals/district-admin/summary';
  }
  if (userRoles.includes(UserRole.keyContact)) {
    url = '/approvals/key-contact/summary';
  }

  const { data } = await requestFactory(HTTP_METHOD.GET, url);

  const approvals: ApprovalSummaryByType = {
    highSchool: ConstructApprovalSummary(data.data.high_school),
    middleSchool: ConstructApprovalSummary(data.data.middle_school),
    adult: ConstructApprovalSummary(data.data.adult),
  };

  return approvals;
};

export const getScholarshipsBySchool = async (): Promise<ScholarshipApplicationsByType> => {

  const { data } = await requestFactory(HTTP_METHOD.GET, '/scholarships/applications-by-school');

  const scholarshipApplications: ScholarshipApplicationsByType = {
    highSchool: data.data.high_school_applications.map((s: any) => ConstructSchoolScholarshipApplicationInfo(s)),
    middleSchool: data.data.middle_school_applications.map((s: any) => ConstructSchoolScholarshipApplicationInfo(s)),
    adult: data.data.adult_applications.map((s: any) => ConstructSchoolScholarshipApplicationInfo(s)),
  };

  return scholarshipApplications;
};

export const getLatestAnnouncements = async (): Promise<LatestAnnouncement[]> => {

  const { data } = await requestFactory(HTTP_METHOD.GET, '/messageboard/latest/admin');

  const latestAnnouncement: LatestAnnouncement[] = data.data.map((a: any) => ConstructLatestAnnouncement(a));

  return latestAnnouncement;
};

export const getExpiringResources = async (): Promise<ExpiringResource[]> => {

  const { data } = await requestFactory(HTTP_METHOD.GET, '/scholarships/expiring-resources');

  const expiringResources: ExpiringResource[] = data.data.map((r: any) => ConstructExpiringResource(r));

  return expiringResources;
};

export const getCurrentMasterKey = async (): Promise<CurrentMasterKeysByType> => {

  const { data } = await requestFactory(HTTP_METHOD.GET, '/masterkey-current/admin');

  const currentMasterKeys: CurrentMasterKeysByType = {
    highSchool: data.data.high_school && ConstructCurrentMasterKey(data.data.high_school),
    middleSchool: data.data.middle_school && ConstructCurrentMasterKey(data.data.middle_school),
    college: data.data.college && ConstructCurrentMasterKey(data.data.college),
  };

  return currentMasterKeys;
};

export const getAdminAnnouncement = async (): Promise<AdminAnnouncement | undefined> => {

  const { data } = await requestFactory(HTTP_METHOD.GET, '/messageboard/active');

  const announcement = ConstructAdminAnnouncement(data.data);

  return announcement;
};

export const setLastSeenAnnouncementId = async (announcementId: number): Promise<void> => {
  await requestFactory(HTTP_METHOD.POST, `/modal-settings/announcement-seen/${announcementId}`);
};
