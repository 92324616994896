import { Button } from '@mui/material';
import React, {
  CSSProperties, useState
} from 'react';
import './BulkCreateStepTwo.scss';
import { AppAssetPaths } from '../../../app/app.types';
import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize
} from 'react-papaparse';
import papa from 'papaparse';
import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import { bulkAccountCreationSelectors } from '../../../features/bulkAccountCreation/bulkAccountCreation.slice';
import {
  uploadTemplateCSV, downloadTemplateCsv
} from '../../../features/bulkAccountCreation/bulkAccountCreation.thunks';
import { setAlert } from '../../../features/alert/alert.slice';

interface BulkCreateStepTwoProps { }

// I just yoinked this from the papparse demo and I'm behind enough to not want to mess with stylign too much right now.
// TODO: Move away from in-file styling variables and have it all in SCSS.
const GREY = '#CCC';
const GREY_LIGHT = 'rgba(255, 255, 255, 0.)';
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
const GREY_DIM = '#686868';
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);

const styles = {
  zone: {
    border: `2px dashed ${GREY_LIGHT}`,
    position: 'relative',
  } as CSSProperties,
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  } as CSSProperties,
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex',
  } as CSSProperties,
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: '0.5em',
  } as CSSProperties,
  progressBar: {
    bottom: 14,
    position: 'absolute',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  } as CSSProperties,
  zoneHover: {
    borderColor: GREY_DIM,
  } as CSSProperties,
  default: {
    borderColor: GREY,
  } as CSSProperties,
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23,
  } as CSSProperties,
};

const BulkCreateStepTwo: React.FC<BulkCreateStepTwoProps> = () => {

  const { CSVReader } = useCSVReader();
  const dispatch = useAppDispatch();


  const userSelectedSchool = useAppSelector(bulkAccountCreationSelectors.userSelectedSchool);

  const [
    zoneHover,
    setZoneHover,
  ] = useState(false);
  const [
    removeHoverColor,
    setRemoveHoverColor,
  ] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );

  const [
    uploadComplete,
    setUploadComplete,
  ] = useState<boolean | null>(null);


  const getTemplateCsv = async () => {
    dispatch(downloadTemplateCsv(userSelectedSchool));
  };

  const handleReadCsv = async (csvRes: any) => {
    setUploadComplete(null);
    papa.parse(papa.unparse(csvRes.data), {
      header: true,
      skipEmptyLines: true,
      complete: (res) => {
        dispatch(uploadTemplateCSV({
          selectedSchool: userSelectedSchool,
          csvFile: res,
        })).unwrap()
          .then(() => {
            // Verify fields are not missing data
            let invalidFieldFound = false;
            if (res.data.length > 0) {
              res.data.forEach((el: any) => {
                if (Object.values(el).findIndex(v => v == null || v === '') !== -1) {
                  dispatch(setAlert({
                    type: 'error',
                    message: 'Upload failed. Invalid or Missing Fields in CSV file.',
                  }));
                  invalidFieldFound = true;
                }
              });
            }

            setUploadComplete(!invalidFieldFound);
          })
          .catch((err) => {
            setUploadComplete(false);
            dispatch(setAlert({
              type: 'error',
              message: err.message === 'Request failed with status code 405'
                ? 'Duplicate emails found. Please make sure all emails are unique.'
                : 'Upload failed. Verify the school you are uploading for or check with an administrator.',
            }));
          });
      },
    });
  };


  return (
    <div className="step-two flex_row_jbetween">
      <div className="left-container flex_col">
        <h3>Instructions: </h3>
        <ol>
          <li> Download this <b onClick={getTemplateCsv}>CSV file</b> to use as a template </li>
          <li> Input user information into the file and save </li>
          <li> Upload the completed file (in CSV format) below </li>
          <li> After the file has been successfully uploaded, click &ldquo;Next&ldquo; </li>
        </ol>

        <CSVReader
          onUploadAccepted={(results: any) => {
            setZoneHover(false);
            handleReadCsv(results);

          }}
          onDragOver={(event: DragEvent) => {
            event.preventDefault();
            setZoneHover(true);
          }}
          onDragLeave={(event: DragEvent) => {
            event.preventDefault();
            setZoneHover(false);
          }}>
          {({
            getRootProps,
            acceptedFile,
            ProgressBar,
            getRemoveFileProps,
            Remove,
          }: any) => (
            <>
              <div
                className="csv-uploader flex_col_jcenter_acenter"
                {...getRootProps()}
                style={Object.assign(
                  {
                  },
                  styles.zone,
                  zoneHover && styles.zoneHover
                )}
              >
                {acceptedFile ? (
                  <div className="upload-in-progress flex_col_jcenter_acenter">
                    <div className="file-name flex_row_acenter">
                      <img src={AppAssetPaths.icons.CSV} />
                      <span className="flex_col_jstart">
                        <h4> {acceptedFile.name} </h4>
                        <p> {formatFileSize(acceptedFile.size)}</p>
                      </span>
                    </div>

                    {(uploadComplete === true)
                      ? (<img src={AppAssetPaths.images.UPLOAD_COMPLETE_GREEN} />)
                      : (uploadComplete === false)
                        ? (
                          <>
                            <img src={AppAssetPaths.images.UPLOAD_FAILED_RED} />
                            <h4 className="try-again"> Try Again </h4>
                          </>
                        )
                        : (
                          <div className="csv-progress-bar">
                            Uploading file...
                            <ProgressBar />
                          </div>
                        )}

                    <div
                      {...getRemoveFileProps()}
                      style={styles.remove}
                      onMouseOver={(event: Event) => {
                        event.preventDefault();
                        setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                      }}
                      onMouseOut={(event: Event) => {
                        event.preventDefault();
                        setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                      }}
                    >
                      <Remove color={removeHoverColor} />
                    </div>
                  </div>
                ) : (
                  <div className="flex_col_jcenter_acenter">
                    <img src={AppAssetPaths.icons.UPLOAD_CLOUD} />
                    <b> Drag your file here </b>
                    <p className="upload-or"> OR </p>
                    <Button
                      variant="contained"
                      disableElevation
                    >
                      Browse Files
                    </Button>
                  </div>
                )}
              </div>
              {(uploadComplete === true && userSelectedSchool.name) ? (
                <span className="school-name flex_row_jcenter_acenter">
                  <b>School: </b>
                  <p> {userSelectedSchool.name} </p>
                </span>
              ) : null}
            </>
          )}
        </CSVReader>

      </div>

      <span className="divider" />
      <div className="right-container flex_col">
        <i>Example of a completed CSV file</i>

        <img className="example-img" src={AppAssetPaths.images.BULK_CREATE_CSV_EXAMPLE} />

      </div>

    </div >
  );
};

export default BulkCreateStepTwo;