/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { HTTP_ERROR_CODES } from '../../app/app.api';
import {
  removeSessionToken, setSessionToken
} from '../../util/localStorage';
import {
  clearPageHeader, clearSelectedNavSection
} from '../navigation/navigation.slice';
import * as authApi from './auth.api';
import * as userApi from './user.api';
import { UserRole } from './user.model';


export const signIn = createAsyncThunk(
  'auth/signIn',
  async (data: { email: string, password: string }, { rejectWithValue }) => {
    const { email, password } = data;

    try {
      const token = await authApi.signIn(email, password);
      setSessionToken(token);
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        message: error.response.data ? error.response.data.message : undefined,
      });
    }

    const user = await userApi.getUser();
    if (user.roles.includes(UserRole.student)) {
      removeSessionToken();
      return rejectWithValue({
        status: HTTP_ERROR_CODES.UNAUTHORIZED,
        message: 'Unauthorized',
      });
    }
    return user;
  }
);

export const signOut = createAsyncThunk(
  'auth/signOut',
  async (_, { dispatch }) => {
    dispatch(clearPageHeader());
    dispatch(clearSelectedNavSection());
    await authApi.signOut();
    removeSessionToken();
  }
);

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (data: { email: string }) => {
    const { email } = data;
    await authApi.forgotPassword(email);
  }
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  // eslint-disable-next-line camelcase
  async (data: { email: string, token: string, password: string, password_confirmation: string }) => {
    await authApi.resetPassword(data);
  }
);

export const verifyUserEmail = createAsyncThunk(
  'auth/verifyUserEmail',
  async (email: string) => {
    await authApi.verifyUserEmail(email);
  }
);

export const verifyUserEmailByPin = createAsyncThunk(
  'auth/verifyUserEmailByPin',
  async (data: { email: string, pin: string }) => {
    await authApi.verifyUserEmailByPin(data);
  }
);

export const resetPin = createAsyncThunk(
  'auth/resetPin',
  async (data: { email: string }) => {
    await authApi.resetPin(data);
  }
);