import {
  Alert,
  AlertTitle,
  Slide,
  SlideProps,
  Snackbar
} from '@mui/material';
import React from 'react';


export interface PopupMessageProps {
  alertObj: {
    show: boolean;
    type: 'success' | 'info' | 'warning' | 'error' | undefined;
    message: string;
  }
  onClose: () => void;
}

const PopupMessage: React.FC<PopupMessageProps> = ({ alertObj, onClose }) => {
  const { show, type, message } = alertObj;

  const transitionLeft = (props: JSX.IntrinsicAttributes & SlideProps) => {
    return <Slide {...props} direction="left" />;
  };

  return (
    <Snackbar
      className={!show ? 'hidden' : ''}
      message={message}
      open={show}
      onClose={onClose}
      autoHideDuration={5000}
      TransitionComponent={transitionLeft}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <Alert severity={type}>
        <AlertTitle>{type?.toUpperCase()}</AlertTitle>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default PopupMessage;
