import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';

import './SendPushNotificationModal.scss';


interface SendPushNotificationModalProps {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const SendPushNotificationModal: React.FC<SendPushNotificationModalProps> = ({
  open,
  onConfirm,
  onClose,
}) => {
  return (
    <Dialog className="send-confirm-dialog" open={open}>
      <DialogTitle>Send Push Notification</DialogTitle>
      <DialogContent>
        <div className="dialog-text">
          <DialogContentText id="alert-dialog-slide-description">
          This push notification will be sent in the next 30 minutes and cannot be edited once saved.
          </DialogContentText>
        </div>

        <div className="flex_row_jbetween action-buttons">
          <Button className="cancel-button"
            variant="outlined"
            onClick={onClose}
            disableElevation
          >
            Cancel
          </Button>

          <Button className="send-button"
            variant="contained"
            onClick={onConfirm}
            disableElevation
          >
            Save & Send Push Notification
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SendPushNotificationModal;
