import {
  createAction, createSlice
} from '@reduxjs/toolkit';
import { StateStatus } from '../../app/app.types';
import { RootState } from '../../app/store';
import {
  EmptyOrganization, Organization
} from '../organization/organization.model';
import {
  AccountsCreated, BulkUser, EmptyAccountCreated, ExisitingUser
} from './bulkAccountCreation.model';
import {
  setUserSelectedOrg,
  uploadTemplateCSV,
  bulkCreateUsers
} from './bulkAccountCreation.thunks';



export interface BulkAccountCreationState {
  school: {
    status: StateStatus;
    data: Organization;
  }

  exisitingUsers: {
    status: StateStatus;
    data: ExisitingUser[];
  }

  newUsers: {
    status: StateStatus;
    data: BulkUser[];
  }

  accountsCreated: {
    status: StateStatus;
    data: AccountsCreated;
  }

  allowStepFive: {
    allow: boolean;
    data: BulkUser[];
  }

  editMode: boolean;
  pendingValidation: boolean;
  csvUploaded: boolean;
}

const initialState: BulkAccountCreationState = {
  school: {
    status: StateStatus.IDLE,
    data: EmptyOrganization,
  },

  exisitingUsers: {
    status: StateStatus.IDLE,
    data: [],
  },

  newUsers: {
    status: StateStatus.IDLE,
    data: [],
  },

  accountsCreated: {
    status: StateStatus.IDLE,
    data: EmptyAccountCreated,
  },

  allowStepFive: {
    allow: false,
    data: [],
  },

  editMode: false,
  pendingValidation: false,
  csvUploaded: false,

};


export const bulkAccountCreationSlice = createSlice({
  name: 'bulkAccountCreation',
  initialState,
  reducers: {
    setEditMode: (state, action) => {
      state.editMode = action.payload;
    },
    setPendingValidation: (state, action) => {
      state.pendingValidation = action.payload;
    },
    setAllowStepFive: (state, action) => {
      state.allowStepFive = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Step 1
      .addCase(setUserSelectedOrg.pending, (state) => {
        state.school.status = StateStatus.LOADING;
      })
      .addCase(setUserSelectedOrg.fulfilled, (state, action) => {
        state.school.status = StateStatus.IDLE;
        state.school.data = action.payload;
      })
      .addCase(setUserSelectedOrg.rejected, (state) => {
        state.school.status = StateStatus.FAILED;
      })

      .addCase(uploadTemplateCSV.fulfilled, (state, action) => {
        state.exisitingUsers.data = action.payload.existingUsers;
        state.newUsers.data = action.payload.newUsers;

        state.csvUploaded = true;
      })
      .addCase(uploadTemplateCSV.rejected, (state, action) => {
        state.exisitingUsers.data = [];
        state.newUsers.data = [];
        state.csvUploaded = false;
      })


      // Step 4
      .addCase(setEditMode, (state, action) => {
        state.editMode = action.payload;
      })
      .addCase(setPendingValidation, (state, action) => {
        state.pendingValidation = action.payload;
      })
      .addCase(setAllowStepFive, (state, action) => {
        state.allowStepFive = action.payload;
      })

      // Step 5
      .addCase(bulkCreateUsers.pending, (state) => {
        state.accountsCreated.status = StateStatus.LOADING;
      })
      .addCase(bulkCreateUsers.fulfilled, (state, action) => {
        state.accountsCreated.status = StateStatus.IDLE;
        state.accountsCreated.data = action.payload;
      })
      .addCase(bulkCreateUsers.rejected, (state) => {
        state.accountsCreated.status = StateStatus.FAILED;
      })

      // Reset States
      .addCase(resetStates, () => initialState);


  },
});

const setAllowStepFive = createAction<{ allow: boolean, data: BulkUser[] }>('setAllowStepFive');
const setEditMode = createAction<boolean>('setEditMode');
const setPendingValidation = createAction<boolean>('setPendingValidation');
const resetStates = createAction<void>('resetStates');

const userSelectedSchool = (state: RootState): Organization => state.bulkAccountCreation.school.data;
const bulkAccountsCreated = (state: RootState) => state.bulkAccountCreation.accountsCreated.data;
const exisitingUsers = (state: RootState): ExisitingUser[] => state.bulkAccountCreation.exisitingUsers.data;
const newUsers = (State: RootState): BulkUser[] => State.bulkAccountCreation.newUsers.data;
const editMode = (State: RootState) => State.bulkAccountCreation.editMode;
const pendingValidation = (State: RootState) => State.bulkAccountCreation.pendingValidation;
const allowStepFive = (State: RootState) => State.bulkAccountCreation.allowStepFive;
const csvUploaded = (State: RootState): boolean => State.bulkAccountCreation.csvUploaded;




export const bulkAccountCreationSelectors = {
  userSelectedSchool,
  bulkAccountsCreated,
  exisitingUsers,
  newUsers,
  editMode,
  setEditMode,
  pendingValidation,
  setPendingValidation,
  allowStepFive,
  setAllowStepFive,
  csvUploaded,
  resetStates,
};

export default bulkAccountCreationSlice.reducer;