import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';

import './DeleteConfirmationModal.scss';


interface ArchiveMasterKeyConfirmationModalProps {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const ArchiveMasterKeyConfirmationModal: React.FC<ArchiveMasterKeyConfirmationModalProps> = ({
  open,
  onConfirm,
  onClose,
}) => {
  return (
    <Dialog className="delete-confirm-dialog" open={open}>
      <DialogTitle>Archive Master Key</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Are you sure you want to archive this master key?
        </DialogContentText>

        <div className="flex_row_jbetween action-buttons">
          <Button
            variant="outlined"
            onClick={onClose}
            disableElevation
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            onClick={onConfirm}
            disableElevation
          >
            Archive Master Key
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ArchiveMasterKeyConfirmationModal;
