import React, {
  useEffect, useState
} from 'react';
import './ScholarshipDetails.scss';
import {
  useNavigate, useParams
} from 'react-router-dom';
import { Button } from '@mui/material';
import { ResourceType } from '../../../features/resources/resources.model';
import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import { userSelectors } from '../../../features/user/user.slice';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { AppRoutes } from '../../../app/app.types';
import {
  getResourceById, approveResource, rejectResource
} from '../../../features/resources/resources.thunks';
import { resourceSelectors } from '../../../features/resources/resources.slice';
import { ListMenuItem } from '../../../components/forms/form.types';
import ApproveRejectCard from '../../../components/details/ApproveRejectCard';
import { setAlert } from '../../../features/alert/alert.slice';
import { resourceTagSelectors } from '../../../features/resourceTags/resourceTags.slice';
import { getResourceTagList } from '../../../features/resourceTags/resourceTags.thunks';
import { User } from '../../../features/user/user.model';
import { adminGetUserById } from '../../../features/user/user.api';
import dayjs from 'dayjs';

interface ScholarshipDetailsProps { }

const ScholarshipDetails: React.FC<ScholarshipDetailsProps> = () => {

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector(userSelectors.selectUser);
  const scholarship = useAppSelector(resourceSelectors.selectResourceDetails);
  const tags = useAppSelector(resourceTagSelectors.selectResourceTagList);

  const [
    updatedByUser,
    setUpdatedByUser,
  ] = useState<User | null>(null);

  const [
    viewAllInstitution,
    setViewAllInstitution,
  ] = useState(false);


  useEffect(() => {
    if (!params || !params.scholarshipId) return;

    const id = parseInt(params.scholarshipId);
    dispatch(getResourceById({
      id,
      type: ResourceType.scholarship,
    }));

    dispatch(getResourceTagList());
  }, []);

  useEffect(() => {
    if (scholarship.updatedAt && scholarship.updatedBy && updatedByUser === null) {
      adminGetUserById(scholarship.updatedBy).then((res: User) => {
        setUpdatedByUser(res);
      });
    }
  }, [
    scholarship,
  ]);

  const onApproveScholarship = () => {
    dispatch(approveResource({
      id: scholarship.id,
      type: ResourceType.scholarship,
    }))
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully approved the scholarship.',
        }));
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to approve the scholarship.',
        }));
      });
  };

  const onRejectScholarship = () => {
    dispatch(rejectResource({
      id: scholarship.id,
      type: ResourceType.scholarship,
    }))
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully rejected the scholarship.',
        }));

        navigate(-1);
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to reject the scholarship.',
        }));
      });
  };

  return (
    <div className="card-background scholarship-details">
      <div className="details-header flex_row_jbetween_acenter">
        <div className="flex_row">
          <div className="back_arrow" onClick={() => navigate(-1)}>
            <ChevronLeftIcon className="carousel-icon" />
          </div>
          <h3> Scholarship Details </h3>
        </div>

        <div className="flex_row_jend_acenter">
          {
            user.isAdmin && !scholarship.approved && (
              <ApproveRejectCard
                onApprove={onApproveScholarship}
                onReject={onRejectScholarship}
              />
            )
          }

          {(user.isAdmin && scholarship.updatedAt && updatedByUser !== null) && ( 
            <h3 className="updated-banner"><span className="updated-text">Updated:&nbsp; </span> <span className="updated-by-text">{dayjs(scholarship.updatedAt).format('MM/DD/YYYY')} by {updatedByUser?.fullname}</span></h3>
          )}

          {(user.isAdmin || user.organizationId === scholarship.organizationId) && (
            <Button variant="outlined"
              onClick={() => navigate(AppRoutes.editResource.path.replace(':resourceId', scholarship.id.toString()), {
                state: {
                  id: scholarship.id,
                  type: ResourceType.scholarship,
                },
              })}
              disabled={!user.isAdmin && !scholarship.approved}
            >
              Edit
            </Button>
          )}
        </div>
      </div>

      <div className="main-content flex_column">
        <div className="flex_row_jbetween_acenter">
          <h2> {scholarship.title} </h2>

          <div
            className="view-all flex_row_jcenter_acenter"
            onClick={() => navigate(AppRoutes.resourceApplicants.path.replace(':resourceId', scholarship.id.toString()), {
              state: {
                title: scholarship.title,
                type: ResourceType.scholarship,
                inApp: scholarship.inApp,
              },
            })}
          >
            {(scholarship && scholarship?.numberApplied && scholarship.numberApplied > 0) ? (
              <>
                <div>{scholarship.numberApplied} Applications</div>

                <p className="flex_row_acenter">
                  View All <ChevronRightIcon />
                </p>
              </>
            ) : null}
          </div>
        </div>

        <span>
          <p>DESCRIPTION</p>
          <p>{scholarship.description ? scholarship.description : '-'}</p>
        </span>

        <div className="other-details flex_row_jbetween">
          <div className="left-column flex_col">
            <span>
              <p>INSTITUTION / ORGANIZATION</p>
              <p>{scholarship.organizationName ? scholarship.organizationName : '-'}</p>
            </span>

            <span>
              <p>APPLICATION LINK</p>
              <p>{scholarship.url}</p>
            </span>

            <span>
              <p>START DATE</p>
              <p>{scholarship.startDate}</p>
            </span>

            <span>
              <p>END DATE</p>
              <p>{scholarship.endDate}</p>
            </span>

            <span>
              <p>ZIP CODE</p>
              <p>{scholarship.zipcode || '-'}</p>
            </span>

            <span>
              <p>AMOUNT OF FUNDING</p>
              {(scholarship.dollarAmountRange && !!scholarship.dollarAmountRange.length) ?
                <p>{scholarship.dollarAmountRange[0]} - {scholarship.dollarAmountRange[1]}</p> :
                <p>{scholarship.dollarAmount}</p>
              }
            </span>

            <span>
              <p>SCHOLARSHIPS AVAILABLE</p>
              {(scholarship.availableRange && !!scholarship.availableRange.length) ?
                <p>{scholarship.availableRange[0]} - {scholarship.availableRange[1]}</p> :
                <p>{scholarship.available}</p>
              }
            </span>

            <span>
              <p>ORGANIZATION TYPE</p>
              {scholarship.userTypes && !!scholarship.userTypes.length ?
                <p>{scholarship.userTypes.map((e: ListMenuItem) => e.label).join(', ')}</p> :
                <p> - </p>
              }
            </span>

            <span>
              {/* TODO: convert graduation years to grades */}
              <p>GRADUATION YEARS</p>
              {scholarship.graduationYears && !!scholarship.graduationYears.length ?
                <p>{scholarship.graduationYears.map((e: number) => e).join(', ')}</p> :
                <p> - </p>
              }
            </span>
          </div>

          <div className="right-column flex_col">
            <span>
              <p>INSTITUTIONS</p>
              <p>
                {scholarship.institutions && (
                  <>
                    {/* if has more than 20 institutions show 20 and View-All/View-Less button */}
                    {scholarship.institutions.length <= 20 ? (
                      scholarship.institutions.map((e: ListMenuItem) => e.label).join(', ')
                    ) : (viewAllInstitution) ? (
                      scholarship.institutions.map((e: ListMenuItem) => e.label).join(', ')
                    ) : (
                      scholarship.institutions.slice(0, 19).map((e: ListMenuItem) => e.label).join(', ')
                    )}
                    {/* Show View All/View Less button */}
                    {(scholarship.institutions.length > 20) && (
                      <div className="flex_jcenter">
                        <Button className="center-view-all-button" variant="text"
                          onClick={() => setViewAllInstitution(!viewAllInstitution)}>
                          {viewAllInstitution ? 'View Less' : 'View All'}
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </p>
            </span>

            <span>
              <p>CAREERS</p>
              {scholarship.careers && !!scholarship.careers.length ?
                <p>{scholarship.careers.map((e: ListMenuItem) => e.label).join(', ')}</p> :
                <p> - </p>
              }
            </span>

            <span>
              <p>IN APP SCHOLARSHIP</p>
              <p>{scholarship.inApp ? 'Yes' : 'No'}</p>
            </span>

            <span>
              <p>TUITION WAIVER <br /> (NON-SCHOLARSHIP)</p>
              <p>{scholarship.type == ResourceType.tuition ? 'Yes' : 'No'}</p>
            </span>

            <span>
              <p>TAGS</p>
              {(scholarship.tags && !!scholarship.tags.length && tags.length) ? (
                <p>
                  {scholarship.tags && scholarship.tags.map((ele) => ele.name).join(', ')}
                </p>
              ) : (
                <p> - </p>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScholarshipDetails;
