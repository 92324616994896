import React from 'react';
import { AppAssetPaths } from '../app/app.types';
import { UserApprovalStatus } from '../features/user/user.model';

interface StatusLabelProps {
  status?: Status | UserApprovalStatus;
}

export enum Status {
  approved = 'approved',
  rejected = 'rejected',
  pending = 'pending',
  inProgress = 'in-progress',
  complete = 'complete',
  unverified = 'unverified',
  active = 'active',
  partialRegistration = 'partial_registration'
}

const StatusLabel: React.FC<StatusLabelProps> = ({ status }) => {

  if (!status) {
    return <></>;
  }

  switch (status) {
    case Status.approved:
      return <img src={AppAssetPaths.icons.statusIcons.APPROVED} alt={`${status} label`} />;
    case Status.rejected:
      return <img src={AppAssetPaths.icons.statusIcons.REJECTED} alt={`${status} label`} />;
    case Status.pending:
      return <img src={AppAssetPaths.icons.statusIcons.PENDING} alt={`${status} label`} />;
    case Status.inProgress:
      return <img src={AppAssetPaths.icons.statusIcons.IN_PROGRESS} alt={`${status} label`} />;
    case Status.complete:
      return <img src={AppAssetPaths.icons.statusIcons.COMPLETE} alt={`${status} label`} />;
    case Status.unverified:
      return <img src={AppAssetPaths.icons.statusIcons.UNVERIFIED_EMAIL} alt={`${status} label`} />;
    case Status.active:
      return <img src={AppAssetPaths.icons.statusIcons.ACTIVE} alt={`${status} label`} />;
    case Status.partialRegistration:
      return <img src={AppAssetPaths.icons.statusIcons.PARTIAL_REGISTRATION} alt={`${status} label`} />;
    default:
      return <img src={AppAssetPaths.icons.statusIcons.PENDING} alt={`${status} label`} />;
  }
};

export default StatusLabel;