
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppAssetPaths, AppRoutes
} from '../../app/app.types';
import {
  useAppDispatch, useAppSelector
} from '../../app/hooks';
import { ExpiringResource } from '../../features/dashboard/dashboard.model';
import { dashboardSelectors } from '../../features/dashboard/dashboard.slice';
import { getExpiringResources } from '../../features/dashboard/dashboard.thunk';
import { ResourceType } from '../../features/resources/resources.model';

import './ExpiringResourcesCard.scss';


interface ExpiringResourcesCardProps { }

const ExpiringResourcesCard: React.FC<ExpiringResourcesCardProps> = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const expiringResources = useAppSelector(dashboardSelectors.selectExpiringResources);

  useEffect(() => {
    dispatch(getExpiringResources());
  }, []);

  const navigateToDetailsPage = (resource: ExpiringResource) => {
    if (resource.resourceType === ResourceType.scholarship) {
      navigate(AppRoutes.scholarshipDetails.path.replace(':scholarshipId', resource.id.toString()));
    }
    else if (resource.resourceType.toLowerCase() === ResourceType.internship) {
      navigate(AppRoutes.internshipDetails.path.replace(':resourceId', resource.id.toString()), {
        state: {
          type: ResourceType.internship,
        },
      });
    }
    else {
      navigate(AppRoutes.opportunityDetails.path.replace(':resourceId', resource.id.toString()), {
        state: {
          type: ResourceType.opportunity,
        },
      });
    }
  };

  return (
    <div id="expiring-resources-card" className="card-background">
      <h3 className="card-header">Expiring Resources</h3>

      <Box className="card-content flex_col">
        <div className="column-headers flex_jbetween">
          <p>Title</p>
          <p className="date-headers">Exp. Date</p>
        </div>
        {(expiringResources && !!expiringResources.length) ? (
          <div className="scroll-container">
            {expiringResources.map((resource: ExpiringResource, i: number) => (
              <div key={i}
                className={`column-data flex_jbetween_acenter ${resource.expiringSoon && 'expiring-soon'}`}
                onClick={() => navigateToDetailsPage(resource)}
              >
                <div className="title-container flex">
                  {resource.expiringSoon && <img className="alert-icon" src={AppAssetPaths.icons.ALERT_RED} />}
                  <p className="title">{resource.title}</p>
                </div>
                <p className="date">{dayjs(resource.endDate).format('MM/DD/YYYY')}</p>
              </div>
            ))}
          </div>
        ) : (
          <div className="no-data flex_jcenter_acenter">
            <p>No resources available</p>
          </div>
        )}
      </Box>
    </div>
  );
};

export default ExpiringResourcesCard;
