/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from '@mui/material';
import React, {
  useEffect, useState
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../app/app.types';
import { useAppSelector } from '../../../app/hooks';
import { BulkUser } from '../../../features/bulkAccountCreation/bulkAccountCreation.model';
import { bulkAccountCreationSelectors } from '../../../features/bulkAccountCreation/bulkAccountCreation.slice';
import { bulkCreateUsers } from '../../../features/bulkAccountCreation/bulkAccountCreation.thunks';
import BulkCreateLandingStep from './BulkCreateLandingStep';
import BulkCreateStepFive from './BulkCreateStepFive';
import BulkCreateStepFour from './BulkCreateStepFour';
import BulkCreateStepOne from './BulkCreateStepOne';
import BulkCreateStepper from './BulkCreateStepper';
import BulkCreateStepThree from './BulkCreateStepThree';
import BulkCreateStepTwo from './BulkCreateStepTwo';

import './BulkAccountCreateWrapper.scss';


interface BulkAccountCreateWrapperProps { }

const BulkAccountCreateWrapper: React.FC<BulkAccountCreateWrapperProps> = () => {

  const navigate = useNavigate();

  const userSelectedSchool = useAppSelector(bulkAccountCreationSelectors.userSelectedSchool);

  // For Step 4
  const editMode = useAppSelector(bulkAccountCreationSelectors.editMode);
  const pendingValidation = useAppSelector(bulkAccountCreationSelectors.pendingValidation);
  const allowStepFive = useAppSelector(bulkAccountCreationSelectors.allowStepFive);
  const dispatch = useDispatch();

  const newUsers = useAppSelector(bulkAccountCreationSelectors.newUsers);
  const csvUploaded = useAppSelector(bulkAccountCreationSelectors.csvUploaded);

  const [
    currentStep,
    setCurrentStep,
  ] = useState<number>(0);

  const [
    showValidationMessage,
    setShowValidationMessage,
  ] = useState<boolean>(false);

  const handleShowCurrentStep = () => {
    switch (currentStep) {
      case 0:
        return <BulkCreateLandingStep />;
      case 1:
        return <BulkCreateStepOne />;
      case 2:
        return <BulkCreateStepTwo />;
      case 3:
        return <BulkCreateStepThree />;
      case 4:
        return <BulkCreateStepFour />;
      case 5:
        return <BulkCreateStepFive />;
      default:
        return <BulkCreateLandingStep />;
    }
  };

  useEffect(() => {
    if (!pendingValidation && allowStepFive.allow) {
      handleBulkCreate().then(() => {
        setShowValidationMessage(false);
        setCurrentStep(5);
      });
    }
  }, [
    allowStepFive,
  ]);

  const handleNextButtonDisabled = () => {
    if (currentStep === 1) {
      return userSelectedSchool.id == 0;
    }
    if (currentStep === 4 && allowStepFive.data.length === 0) {
      return true;
    }
    if (currentStep === 2 && !csvUploaded) {
      return true;
    }
  };

  const handleNextButtonOnClick = () => {
    if (currentStep === 4 && allowStepFive.data.length !== 0) {
      // Triggers step 4 validation
      //  Step 5 is moved too upon the state of AllowStepFive (which is set in step four after this validation state is set)
      dispatch(bulkAccountCreationSelectors.setPendingValidation(true));
      if (editMode) {
        setShowValidationMessage(true);
      }
    } else if (currentStep === 5) {
      exitBulkaccount();
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const exitBulkaccount = async () => {
    dispatch(bulkAccountCreationSelectors.resetStates());
    navigate(AppRoutes.studentUsers.path);
  };

  const handleCancelButtonOnClick = () => {
    if (currentStep === 0) {
      navigate(AppRoutes.studentUsers.path);
    }
    if (currentStep === 4 && editMode) {
      // Step 4 and Edit cancel on click should just leave edit mode
      dispatch(bulkAccountCreationSelectors.setEditMode(false));
      setShowValidationMessage(false);
    }
    setCurrentStep(currentStep - 1);
  };


  const handleBulkCreate = async () => {
    const returnData: BulkUser[] = allowStepFive.data.map((ele) => ({
      ...ele,
      grade: `${ele.grade.toString().replace('th', '')}`,
    }));

    await dispatch(bulkCreateUsers({
      newUsers: returnData,
      selectedSchool: userSelectedSchool,
    }));
  };

  return (
    <div id="bulk-account-create-wrapper" className="flex_col_jbetween">
      <div className="responsive-container flex_col_jbetween_astart">
        {(userSelectedSchool.id !== 0 && currentStep !== 0 && currentStep !== 1) && (
          <div className="selected-school flex_row_jcenter_acenter">
            <b>School: </b> <p> {userSelectedSchool.name} </p>
          </div>
        )}

        <h1>Bulk Upload Users</h1>
        <BulkCreateStepper currentStep={currentStep} />

        <div className="content-wrapper">
          {(handleShowCurrentStep())}
        </div>
        <div className="stepper-nav flex_row_jbetween_aend">
          {(currentStep !== 5) ? (
            <Button
              variant="outlined"
              onClick={() => handleCancelButtonOnClick()}
            >
              {currentStep === 0 ? 'Cancel' : 'Back'}
            </Button>
          ) : <span></span>}

          {(currentStep !== 4) && (
            <div className="flex_row_jbetween_wrap">
              <Button
                variant="contained"
                onClick={() => handleNextButtonOnClick()}
                disabled={handleNextButtonDisabled()}
              >
                {currentStep === 0 ? 'Get Started' : currentStep === 5 ? 'Exit' : 'Next'}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div >
  );
};

export default BulkAccountCreateWrapper;
