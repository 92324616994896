import { Pagination } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, {
  useEffect, useState
} from 'react';
import { AppRoutes } from '../../../app/app.types';
import { useAppSelector } from '../../../app/hooks';
import { exisitingUsersColumns } from '../../../constants/tables/bulkUploadUsersTable.types';
import { TableState } from '../../../constants/tables/table.types';
import { bulkAccountCreationSelectors } from '../../../features/bulkAccountCreation/bulkAccountCreation.slice';
import './BulkCreateStepThree.scss';


interface BulkCreateStepThreeProps {}

const BulkCreateStepThree: React.FC<BulkCreateStepThreeProps> = () => {

  const exisitingUsers = useAppSelector(bulkAccountCreationSelectors.exisitingUsers);

  const [
    tableState,
    setTableState,
  ] = useState<TableState>({
    page: 1,
    pageSize: 100,
    columns: exisitingUsersColumns,
    rows: [],
    loading: false,
  });

  useEffect(() => {
    setTableState((prev) => ({
      ...prev,
      loading: false,
      rows: exisitingUsers,
    }));
  }, [
    exisitingUsers,
  ]);

  const NoExistingUsersOverlay = () => (
    <h4>No exisiting users</h4>
  );

  return (
    <div className="step-three">
      <h3>Instructions:</h3>
      <p>1. The table below shows accounts that already exist from your uploaded file. If an existing user needs to be transferred to another school, do so on their student details page (access via the red icon next to their information) or contact a super admin.</p>
      <p>2. Once the list has been reviewed, click “Next”</p>
      <h3 className="exisiting-users">Existing Users</h3>
      <DataGrid
        className="table"
        {...tableState}
        components={{
          Pagination,
          NoRowsOverlay: NoExistingUsersOverlay,
        }}
        columns={exisitingUsersColumns}
        componentsProps={{
          pagination: {
            page: tableState.page,
            count: Math.ceil(exisitingUsers.length / tableState.pageSize),
            onChange: (_: void, page: number) => {
              setTableState((prev) => ({
                ...prev,
                page,
              }));
            },
          },
        }}
        onCellClick={(event) => {
          if (event.field === 'viewUser') {
            window.open((AppRoutes.userDetails.path.replace(':userId', event.row.userId.toString())));
          }
        } }
        pagination
        paginationMode="server"
        sortingMode="server"
        disableColumnFilter
        disableColumnMenu
        hideFooter
      />
    </div>
  );
};

export default BulkCreateStepThree;