import React, {
  useEffect, useState
} from 'react';
import {
  Box, Button, CircularProgress
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  useAppDispatch, useAppSelector
} from '../../../../app/hooks';
import { collegeApplicationChecklistSelectors } from '../../../../features/collegeApplicationChecklist/collegeApplicationChecklist.slice';
import {
  getFafsaLinks, updateFafsaLinks
} from '../../../../features/collegeApplicationChecklist/collegeApplicationChecklist.thunks';
import { AppRoutes } from '../../../../app/app.types';
import { FafsaLinkNames } from '../../../../features/collegeApplicationChecklist/collegeApplicationChecklist.model';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormProvider, SubmitHandler, useForm
} from 'react-hook-form';
import TextInput from '../../../../components/forms/TextInput';
import SubmitButton from '../../../../components/forms/SubmitButton';
import { setAlert } from '../../../../features/alert/alert.slice';

import './EditFafsaLinks.scss';


interface EditFafsaLinksProps { }

const EditFafsaLinks: React.FC<EditFafsaLinksProps> = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const fafsaLinks = useAppSelector(collegeApplicationChecklistSelectors.selectFafsaLinks);
  const fafsaLinksLoaded = useAppSelector(collegeApplicationChecklistSelectors.selectFafsaLinksLoaded);

  const [
    pageLoaded,
    setPageLoaded,
  ] = useState(false);

  useEffect(() => {
    if (!fafsaLinksLoaded) {
      dispatch(getFafsaLinks());
    }
  }, []);

  useEffect(() => {
    if (fafsaLinksLoaded) {
      prePopulateValues();
      setPageLoaded(true);
    }
  }, [
    fafsaLinks,
  ]);

  const prePopulateValues = async () => {
    // Setting the ids here to make sure we retain that info when it is passed to the api.
    setValue('fafsaVideoEnglish.id', fafsaLinks.fafsaVideoEnglish.id);
    setValue('fafsaVideoSpanish.id', fafsaLinks.fafsaVideoSpanish.id);
    setValue('howAidWorks.id', fafsaLinks.howAidWorks.id);
    setValue('formPrep.id', fafsaLinks.formPrep.id);
    setValue('workStudyJobs.id', fafsaLinks.workStudyJobs.id);
    setValue('deadlines.id', fafsaLinks.deadlines.id);

    setValue('fafsaVideoEnglish.url', fafsaLinks.fafsaVideoEnglish.url);
    setValue('fafsaVideoSpanish.url', fafsaLinks.fafsaVideoSpanish.url);
    setValue('howAidWorks.url', fafsaLinks.howAidWorks.url);
    setValue('formPrep.url', fafsaLinks.formPrep.url);
    setValue('workStudyJobs.url', fafsaLinks.workStudyJobs.url);
    setValue('deadlines.url', fafsaLinks.deadlines.url);
  };

  // Form validation
  const linkValidation = yup.object().shape({
    url: yup.string()
      .isValidUrl()
      .required('Url is required'),
  });

  const formValidationSchema = yup.object().shape({
    fafsaVideoEnglish: linkValidation,
    fafsaVideoSpanish: linkValidation,
    howAidWorks: linkValidation,
    formPrep: linkValidation,
    workStudyJobs: linkValidation,
    deadlines: linkValidation,
  }).required();

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const { handleSubmit, setValue, formState: { errors } } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    dispatch(updateFafsaLinks(values))
      .unwrap()
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully updated FAFSA links.',
        }));
        navigate(-1);
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to update FAFSA links.',
        }));
      });
  };

  return (
    <form id="edit-fafsa-links" onSubmit={handleSubmit(submitForm)}>
      {pageLoaded ? (
        <FormProvider {...methods}>
          <Box className="card-background flex_col">
            <div className="content-header flex_row_jbetween">
              <span className="flex_row_jstart_acenter">
                <h3>Fill Out a FAFSA Form</h3>
              </span>
            </div>

            <div className="form-container">
              <div className="form-section">
                <h3 className="section-header">FAFSA Videos</h3>
                <div className="section-row flex">
                  <TextInput
                    name={'fafsaVideoEnglish.url'}
                    label="English Version"
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    errorMessage={(errors?.fafsaVideoEnglish as any)?.url?.message}
                    type="text"
                    size="small"
                    required
                    containerClass="text-field"
                  />

                  <TextInput
                    name={'fafsaVideoSpanish.url'}
                    label="Spanish Version"
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    errorMessage={(errors?.fafsaVideoSpanish as any)?.url?.message}
                    type="text"
                    size="small"
                    required
                    containerClass="text-field"
                  />
                </div>
              </div>

              <div className="form-section">
                <h3 className="section-header">External Links</h3>
                <div className="section-row flex">
                  <TextInput
                    name={'howAidWorks.url'}
                    label={FafsaLinkNames.HowAidWorks}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    errorMessage={(errors?.howAidWorks as any)?.url?.message}
                    type="text"
                    size="small"
                    required
                    containerClass="text-field"
                  />

                  <TextInput
                    name={'formPrep.url'}
                    label={FafsaLinkNames.FormPrep}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    errorMessage={(errors?.formPrep as any)?.url?.message}
                    type="text"
                    size="small"
                    required
                    containerClass="text-field"
                  />
                </div>
                <div className="section-row flex">
                  <TextInput
                    name={'workStudyJobs.url'}
                    label={FafsaLinkNames.WorkStudyJobs}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    errorMessage={(errors?.workStudyJobs as any)?.url?.message}
                    type="text"
                    size="small"
                    required
                    containerClass="text-field"
                  />

                  <TextInput
                    name={'deadlines.url'}
                    label={FafsaLinkNames.Deadlines}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    errorMessage={(errors?.deadlines as any)?.url?.message}
                    type="text"
                    size="small"
                    required
                    containerClass="text-field"
                  />
                </div>
              </div>

              <div className="flex_jbetween">
                <Button
                  className="form-button"
                  variant="outlined"
                  onClick={() => navigate(AppRoutes.fafsaLinksDetails.path)}
                >
                  Cancel
                </Button>

                <SubmitButton
                  className="form-button"
                  text="Save"
                  variant="contained"
                  size="large"
                  disableElevation
                />
              </div>
            </div>
          </Box>
        </FormProvider>
      ) : (
        <div className="flex_jcenter_acenter loading-indicator">
          <CircularProgress size="50px" />
        </div>
      )}
    </form>
  );
};

export default EditFafsaLinks;
