import { createSlice } from '@reduxjs/toolkit';
import { StateStatus } from '../../app/app.types';
import { RootState } from '../../app/store';

import {
  Resource,
  EmptyResource,
  ResourceApplication
} from './resources.model';
import {
  getResourceById, getResourceList, getResourceApplications, updateResource, approveResource
} from './resources.thunks';


/* State */
export interface ResourceState {
  resources: {
    status: StateStatus;
    data: Resource[];
    count: number;
    loaded: boolean
  },
  resourceDetails: {
    status: StateStatus;
    data: Resource;
  }
  resourceApplications: {
    status: StateStatus;
    data: ResourceApplication[];
    count: number;
    loaded: boolean
  }
}

const initialState: ResourceState = {
  resources: {
    status: StateStatus.IDLE,
    data: [],
    count: 0,
    loaded: false,
  },
  resourceDetails: {
    status: StateStatus.IDLE,
    data: EmptyResource,
  },
  resourceApplications: {
    status: StateStatus.IDLE,
    data: [],
    count: 0,
    loaded: false,
  },
};

/* Reducer */
export const resourceSlice = createSlice({
  name: 'resource',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getResourceList.pending, (state) => {
        state.resources.status = StateStatus.LOADING;
      })
      .addCase(getResourceList.fulfilled, (state, action) => {
        state.resources.status = StateStatus.IDLE;
        state.resources.data = action.payload.resources;
        state.resources.count = action.payload.count;
        state.resources.loaded = true;
      })
      .addCase(getResourceList.rejected, (state) => {
        state.resources.status = StateStatus.FAILED;
      })
      // ---------- Resource Details ---------- \\
      .addCase(getResourceById.pending, (state) => {
        state.resourceDetails.status = StateStatus.LOADING;
      })
      .addCase(getResourceById.fulfilled, (state, action) => {
        state.resourceDetails.status = StateStatus.IDLE;
        state.resourceDetails.data = action.payload;

        // Reset resourceApplications state
        state.resourceApplications = initialState.resourceApplications;
      })
      .addCase(getResourceById.rejected, (state) => {
        state.resourceDetails.status = StateStatus.FAILED;
      })
      // ---------- Resource Applications ---------- \\
      .addCase(getResourceApplications.pending, (state) => {
        state.resourceApplications.status = StateStatus.LOADING;
      })
      .addCase(getResourceApplications.fulfilled, (state, action) => {
        state.resourceApplications.status = StateStatus.IDLE;
        state.resourceApplications.data = action.payload;
        state.resourceApplications.count = action.payload.length;
        state.resourceApplications.loaded = true;
      })
      .addCase(getResourceApplications.rejected, (state) => {
        state.resourceApplications.status = StateStatus.FAILED;
      })
      // ---------- Update Resource ---------- \\
      .addCase(updateResource.pending, (state) => {
        state.resourceDetails.status = StateStatus.LOADING;
      })
      .addCase(updateResource.fulfilled, (state, action) => {
        state.resourceDetails.status = StateStatus.IDLE;
      })
      .addCase(updateResource.rejected, (state) => {
        state.resourceDetails.status = StateStatus.FAILED;
      })
      .addCase(approveResource.pending, (state) => {
        state.resourceDetails.status = StateStatus.LOADING;
      })
      .addCase(approveResource.fulfilled, (state, action) => {
        state.resourceDetails.status = StateStatus.IDLE;
        state.resourceDetails.data.approved = action.payload;
      })
      .addCase(approveResource.rejected, (state) => {
        state.resourceDetails.status = StateStatus.FAILED;
      });
  },
});


/* Selectors */
const selectResourceList = (state: RootState): Resource[] => state.resource.resources.data;
const selectResourceListStatus = (state: RootState): StateStatus => state.resource.resources.status;
const selectResourceListCount = (state: RootState): number => state.resource.resources.count;
const selectResourceListLoaded = (state: RootState): boolean => state.resource.resources.loaded;
// ---------- Resource Details ---------- \\
const selectResourceDetails = (state: RootState): Resource => state.resource.resourceDetails.data;
const selectResourceDetailsStatus = (state: RootState): StateStatus => state.resource.resourceDetails.status;
// ---------- Resource Applications ---------- \\
const selectResourceApplications = (state: RootState): ResourceApplication[] => state.resource.resourceApplications.data;
const selectResourceApplicationsListLoaded = (state: RootState): boolean => state.resource.resourceApplications.loaded;

export const resourceSelectors = {
  selectResourceList,
  selectResourceListStatus,
  selectResourceListCount,
  selectResourceListLoaded,
  selectResourceDetails,
  selectResourceDetailsStatus,
  selectResourceApplications,
  selectResourceApplicationsListLoaded,
};

export default resourceSlice.reducer;
