/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box, Button, CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup
} from '@mui/material';
import React, {
  useEffect, useState
} from 'react';
import {
  useNavigate, useParams
} from 'react-router-dom';
import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import * as yup from 'yup';
import { careerSelectors } from '../../../features/careers/careers.slice';
import {
  getCareerGroupById, removeAllCareerGroupSkills, updateCareerGroupSkills
} from '../../../features/careers/careers.thunks';
import './EditCareerCluster.scss';
import {
  AppAssetPaths, AppRoutes
} from '../../../app/app.types';
import { skillSelectors } from '../../../features/skills/skills.slice';
import DropdownSelect from '../../../components/forms/DropdownSelect';
import {
  Skill, SkillListItem
} from '../../../features/skills/skills.model';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getSkillsList } from '../../../features/skills/skills.thunks';
import SubmitButton from '../../../components/forms/SubmitButton';
import { setAlert } from '../../../features/alert/alert.slice';
import DeleteConfirmationModal from '../../../components/modal/DeleteConfirmationModal';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';


interface EditCareerClusterProps { }

const EditCareerCluster: React.FC<EditCareerClusterProps> = () => {

  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const careerCluster = useAppSelector(careerSelectors.selectCareerGroupDetails);
  const dropdownSkillList = useAppSelector(skillSelectors.selectSkillsList);

  const [
    skillList,
    setSkillList,
  ] = useState<Skill[]>([]);
  const [
    hiddenList,
    setHiddenList,
  ] = useState<number[]>([]);
  const [
    pageLoaded,
    setPageLoaded,
  ] = useState<boolean>(false);
  const [
    showDeleteConfirmDialog,
    setShowDeleteConfirmDialog,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (params.id) {
      dispatch(getCareerGroupById(+params.id));
    }

    dispatch(getSkillsList({
      offset: 0,
    }));
  }, []);

  useEffect(() => {
    setSkillList([
      ...dropdownSkillList,
    ]);
  }, [
    dropdownSkillList,
  ]);

  useEffect(() => {
    setSkillList(dropdownSkillList.filter(skill => !hiddenList.some(t => skill.id == t)));
  }, [
    hiddenList,
  ]);

  useEffect(() => {
    if (careerCluster && !!careerCluster?.skills?.length && !!dropdownSkillList.length) {
      if (!fields.length) {
        careerCluster.skills.map((s: any) => {
          append({
            skill: dropdownSkillList.find(e => e.id === s.id),
            haveType: (s.haveType === 'mustHave') ? 'mustHave' : 'niceToHave',
          });
        });
      }

      setHiddenList([
        ...hiddenList,
        ...careerCluster.skills.map((s: any) => s.id),
      ]);

      setPageLoaded(true);
    }
  }, [
    careerCluster,
    dropdownSkillList,
  ]);


  const formValidationSchema = yup.object().shape({
    skills: yup.array().of(
      yup.object().shape({
        skill: yup.object().required('Skill selection is required').typeError('Skill selection is required'),
        haveType: yup.string(),
      })
    ),
  }).required();

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });

  const { control, handleSubmit, register, formState: { errors } } = methods;
  const { fields, append, remove } = useFieldArray({
    name: 'skills',
    control,
  });

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    if (!params.id) return;

    const submitBody = {
      ...values,
      careerId: +params.id,
    };
    dispatch(updateCareerGroupSkills(submitBody)).unwrap()
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Career cluster successfully updated',
        }));
        navigate(AppRoutes.careerClusters.path);
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Unable to update career cluster',
        }));
      });
  };


  const handleRemoveAllSkills = () => {
    setShowDeleteConfirmDialog(false);

    if (!params.id) return;

    dispatch(removeAllCareerGroupSkills(+params.id))
      .unwrap()
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'All skills removed.',
        }));
        navigate(AppRoutes.careerClusters.path);
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to remove all skills.',
        }));
      });
  };


  return (
    <form id="edit-career-cluster" onSubmit={handleSubmit(submitForm)}>
      <Box className="card-background flex_col">
        <div className="content-header flex_row_jbetween">
          <span
            className="cursor-pointer flex_row_jstart_acenter"
            onClick={() => navigate(AppRoutes.careerClusters.path)}
          >
            <ChevronLeftIcon className="back-icon" color="secondary" />
            <h3>Career Cluster Details</h3>
          </span>
        </div>

        <h4 className="skill-name"> {careerCluster.title} </h4>

        <div className="skills-container">
          <p className="skills-header">SKILLS</p>
          {pageLoaded ? (
            <FormProvider {...methods}>
              {fields.map((skill: any, i: number) => (
                <div key={i} className="skill-content flex_col">
                  <p className="static-input-label required-field">Skill</p>

                  <div className="width-100 flex_row_astart_jstart">
                    <DropdownSelect
                      containerClass="skill-input"
                      name={`skills.${i}.skill` as 'skills.0.skill'}
                      itemList={skillList as SkillListItem[]}
                      onChange={(e) => setHiddenList([
                        ...hiddenList,
                        e.id,
                      ])}
                      errorMessage={(errors.skills as any)?.[i]?.skill?.message}
                      size="small"
                    />

                    <div className="flex_row_acenter">
                      <FormControl>
                        <Controller
                          name={`skills.${i}.haveType` as 'skills.0.haveType'}
                          control={control}
                          render={({ field }) => (
                            <RadioGroup
                              {...field}
                              row
                            >
                              <FormControlLabel label="Must Have"
                                value={'mustHave'}
                                control={<Radio {...register(`skill.${i}.haveType` as const)} />}
                              />

                              <FormControlLabel label="Nice To Have"
                                value={'niceToHave'}
                                control={<Radio {...register(`skill.${i}.haveType` as const)} />}
                              />
                            </RadioGroup>
                          )}
                        />
                      </FormControl>

                      {(fields.length > 1) &&
                        <img
                          src={AppAssetPaths.icons.TRASH_RED}
                          alt="Remove Skill"
                          onClick={() => {
                            skill?.skill?.id && setHiddenList(hiddenList.filter(e => e !== skill?.skill?.id));
                            remove(i);
                          }}
                        />}
                    </div>
                  </div>
                </div>
              ))}

              <div className="button-container flex_row_jbetween">
                <div className="buttons-wrapper flex_row">
                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    onClick={(e) => {
                      append({
                        skill: null,
                        haveType: 'niceToHave',
                      });
                    }}
                  >
                    Add Skill
                  </Button>

                  <Button
                    startIcon={<img src={AppAssetPaths.icons.TRASH_RED} alt="Remove All Skills" />}
                    color="primary"
                    disableElevation
                    onClick={() => setShowDeleteConfirmDialog(true)}
                  >
                    Remove all skills
                  </Button>
                </div>

                <div className="buttons-wrapper flex_row_jend">
                  <Button
                    variant="outlined"
                    size="large"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>

                  <SubmitButton
                    text="Save"
                    variant="contained"
                    disableElevation
                  />
                </div>
              </div>
            </FormProvider>
          ) : (
            <div className="flex_jcenter_acenter loading-indicator">
              <CircularProgress size="50px" />
            </div>
          )}
        </div>

        <DeleteConfirmationModal
          open={showDeleteConfirmDialog}
          deleteItemText="all skills"
          onConfirm={handleRemoveAllSkills}
          onClose={() => setShowDeleteConfirmDialog(false)}
        />
      </Box>
    </form>
  );
};

export default EditCareerCluster;
