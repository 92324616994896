/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SkillTrainingListQueryParams } from './skillTrainings.model';
import * as skillTrainingApi from './skillTrainings.api';


export const getSkillTrainingList = createAsyncThunk(
  'skillTrainings/getSkillTrainingList',
  async (query: SkillTrainingListQueryParams) => {
    return await skillTrainingApi.getSkillTrainingList(query);
  }
);

export const GetSkillTrainingById = createAsyncThunk(
  'skillTrainings/getSkillTrainingById',
  async (id: number) => {
    return await skillTrainingApi.GetSkillTrainingById(id);
  }
);

export const createSkillTraining = createAsyncThunk(
  'skillTrainings/createSkillTraining',
  async (body: any) => {
    return await skillTrainingApi.createSkillTraining(body);
  }
);

export const updateSkillTraining = createAsyncThunk(
  'skillTrainings/updateSkillTraining',
  async (data: { id: number, body: any }) => {
    return await skillTrainingApi.updateSkillTraining(data.id, data.body);
  }
);

export const deleteSkillTraining = createAsyncThunk(
  'skillTrainings/deleteSkillTraining',
  async (id: number) => {
    return await skillTrainingApi.deleteSkillTraining(id);
  }
);

export const approveSkillTraining = createAsyncThunk(
  'skillTraining/approveSkillTraining',
  async (id: number) => {
    return await skillTrainingApi.approveSkillTraining(id);
  }
);

export const rejectSkillTraining = createAsyncThunk(
  'skillTraining/rejectSkillTraining',
  async (id: number) => {
    return await skillTrainingApi.rejectSkillTraining(id);
  }
);
