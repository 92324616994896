import createTheme from '@mui/material/styles/createTheme';


const globalPalette = createTheme({
  palette: {
    primary: {
      main: '#DB1E36',
    },
    secondary: {
      main: '#2F2F51',
    },
  },
});

export const theme = createTheme({
  ...globalPalette,
  // We can define default styling for components here
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          fontWeight: 'bold',
          textTransform: 'capitalize',
          borderRadius: '8px',
        },
        containedPrimary: {
          ':disabled': {
            color: '#FFFFFF',
            backgroundColor: globalPalette.palette.primary.main,
            opacity: 0.4,
          },
        },
        outlined: {
          border: '2px solid',
          ':hover': {
            border: '2px solid',
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          width: '20px',
          height: '20px',
          borderRadius: '3px',
          '&.Mui-selected': {
            color: '#FFFFFF',
            backgroundColor: globalPalette.palette.secondary.main,
            ':hover': {
              backgroundColor: globalPalette.palette.secondary.main,
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '&.secondary': {
            '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: globalPalette.palette.secondary.main,
            },
          },
        },
        option: {
          '&.secondary': {
            '&[aria-selected="true"]': {
              backgroundColor: 'rgba(47, 47, 81, 0.1) !important',
            },
          },
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          h3: {
            marginTop: 0,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: '#FFFFFF',
          backgroundColor: '#282828',
        },
        arrow: {
          color: '#282828',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&.Mui-disabled': {
            color: 'black',
            borderRadius: '8px',
            backgroundColor: '#E8E8E8',
            '-webkit-text-fill-color': 'unset',
          },
        },
      },
    },
  },
});
