import { createAsyncThunk } from '@reduxjs/toolkit';
import { Organization } from '../organization/organization.model';
import { BulkUser } from './bulkAccountCreation.model';
import * as bulkAccountCreationApi from './bulkAccountCreation.api';





// Step 1
export const setUserSelectedOrg = createAsyncThunk(
  'organization/getOrganizationById',
  async (selectedOrg: Organization) => {
    return selectedOrg;
  }
);


export const downloadTemplateCsv = createAsyncThunk(
  'bulkUpload/downloadTemplateCsv',
  async (selectedSchool: Organization) => {
    await bulkAccountCreationApi.downloadTemplateCsv(selectedSchool);
  }
);


export const uploadTemplateCSV = createAsyncThunk(
  'bulkUpload/uploadTemplateCSV',
  async (body: { selectedSchool: Organization, csvFile: any }) => {
    const data = await bulkAccountCreationApi.uploadTemplateCsv(body.selectedSchool, body.csvFile);
    return data;
  }
);

export const bulkCreateUsers = createAsyncThunk(
  '/users/bulk-create',
  async (body: { newUsers: BulkUser[], selectedSchool: Organization }) => {
    const data = await bulkAccountCreationApi.bulkCreateUsers(body.newUsers, body.selectedSchool);
    return data.data;
  }
);