import React from 'react';
import ExpandableCard from '../ExpandableCard';
import {
  Notification, NotificationType
} from '../../../features/notifications/notifications.model';
import dayjs from 'dayjs';

import './NotificationsTable.scss';


interface NotificationsTableProps {
  notifications?: Notification[];
  defaultExpanded?: boolean;
}

const NotificationsTable: React.FC<NotificationsTableProps> = ({ notifications, defaultExpanded }) => {
  const EMPTY = '-';

  if (!notifications) {
    notifications = [];
  }

  const getDataRow = (header: boolean, key?: number, title?: string, body?: string, type?: NotificationType, createdAt?: string) => (
    <div key={key} className={`notifications-data-row flex ${header ? 'header' : ''}`}>
      <div className="notifications-data-cell flex_column_astart">
        <div className="notifications-data-label">Notification Title</div>
        <div className="notifications-data-content">{ title || EMPTY }</div>
      </div>
      <div className="notifications-data-cell flex_column_astart">
        <div className="notifications-data-label">Body</div>
        <div className="notifications-data-content">{ body || EMPTY }</div>
      </div>
      <div className="notifications-data-cell flex_column_astart">
        <div className="notifications-data-label">Type</div>
        <div className="notifications-data-content">{ type || EMPTY }</div>
      </div>
      <div className="notifications-data-cell flex_column_astart">
        <div className="notifications-data-label">Date Created</div>
        <div className="notifications-data-content">{ dayjs(createdAt).format('MM/DD/YYYY') || EMPTY }</div>
      </div>
    </div>
  );

  const getDetails = () => (
    <div className="notifications-data-wrapper flex_column">
      { getDataRow(true) }
      { !!notifications?.length && notifications.map((n, i) => getDataRow(false, i, n.title, n.body, n.type, n.createdAt)) }
    </div>
  );

  return (
    <ExpandableCard
      className="user-notifications-table"
      summary={<span>Notifications</span>}
      details={getDetails()}
      expandable
      defaultExpanded={defaultExpanded}
    />
  );
};

export default NotificationsTable;
