import React, {
  useState, useRef
} from 'react';
import {
  Box,
  Button,
  Modal
} from '@mui/material';
import { useAppSelector } from '../../app/hooks';
import { userSelectors } from '../../features/user/user.slice';
import { useAppDispatch } from '../../app/hooks';
import {
  signOut, verifyUserEmailByPin
} from '../../features/user/auth.thunks';
import { resetPin } from '../../features/user/auth.thunks';
import { setAlert } from '../../features/alert/alert.slice';
import { HTTP_ERROR_CODES } from '../../app/app.api';

import './SignupCode.modal.scss';


export interface SignupCodeModalProps {
  open: boolean;
  onClose: () => void;
}

const SignupCodeModal: React.FC<SignupCodeModalProps> = ({ open, onClose }) => {

  const dispatch = useAppDispatch();

  const user = useAppSelector(userSelectors.selectUser);

  enum InputNames {
    DIGIT_1 = 'DIGIT_1',
    DIGIT_2 = 'DIGIT_2',
    DIGIT_3 = 'DIGIT_3',
    DIGIT_4 = 'DIGIT_4',
  }

  const digit2Ref = useRef<HTMLInputElement>(null);
  const digit3Ref = useRef<HTMLInputElement>(null);
  const digit4Ref = useRef<HTMLInputElement>(null);

  const [
    digit1,
    setDigit1,
  ] = useState('0');
  const [
    digit2,
    setDigit2,
  ] = useState('0');
  const [
    digit3,
    setDigit3,
  ] = useState('0');
  const [
    digit4,
    setDigit4,
  ] = useState('0');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const newDigit = e.target.value[e.target.value.length -1];
    if (e.target.name === InputNames.DIGIT_1) {
      setDigit1(newDigit);
      digit2Ref.current!.focus();
    }
    if (e.target.name === InputNames.DIGIT_2) {
      setDigit2(newDigit);
      digit3Ref.current!.focus();
    }
    if (e.target.name === InputNames.DIGIT_3) {
      setDigit3(newDigit);
      digit4Ref.current!.focus();
    }
    if (e.target.name === InputNames.DIGIT_4) {
      setDigit4(newDigit);
    }
  };

  const handleInputClick = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.currentTarget.name === InputNames.DIGIT_1)
      setDigit1('');
    if (e.currentTarget.name === InputNames.DIGIT_2)
      setDigit2('');
    if (e.currentTarget.name === InputNames.DIGIT_3)
      setDigit3('');
    if (e.currentTarget.name === InputNames.DIGIT_4)
      setDigit4('');
  };

  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      if (e.currentTarget.name === InputNames.DIGIT_1)
        setDigit1('0');
      if (e.currentTarget.name === InputNames.DIGIT_2)
        setDigit2('0');
      if (e.currentTarget.name === InputNames.DIGIT_3)
        setDigit3('0');
      if (e.currentTarget.name === InputNames.DIGIT_4)
        setDigit4('0');
    }
  };

  const resendCode = async () => {
    dispatch(resetPin({
      email: user.email,
    }))
      .then(() => {
        dispatch(setAlert({
          type: 'info',
          message: 'A new confirmation code has been sent to your email.',
        }));
      });
  };

  const manuallyVerifyEmail = async() => {
    const pin = digit1 + digit2 + digit3 + digit4;

    await dispatch(verifyUserEmailByPin( {
      email: user.email,
      pin: pin,
    } ))
      .unwrap()
      .then(() => {
        onClose();
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully validated user email.',
        }));
      })
      .catch((error) => {
        dispatch(setAlert({
          type: 'error',
          message: error.status === HTTP_ERROR_CODES.UNAUTHORIZED && error.message || 'Unable validate email',
        }));
      });
  };

  return (
    <Modal
      id="signup-code-page"
      open={open}
      disableEscapeKeyDown
    >
      <Box className="card-background flex_col_jcenter">
        <div className="content flex_col_jcenter">
          <section className="form flex_jcenter">
            <div className="form-container flex_col">
              <div className="header-text">
                <h1 className="sign-in">Almost Done!</h1>
                <p className="instructions">Please enter the 4 digit code sent to your email to verify your account.</p>
              </div>
              <div className="flex_jbetween">
                <div className="codeCol">
                  <input
                    className="digit"
                    name={InputNames.DIGIT_1}
                    type="number"
                    value={digit1}
                    onChange={handleInputChange}
                    onFocus={handleInputClick}
                    onBlur={handleInputBlur}
                    inputMode="numeric"
                  />
                </div>
                <div className="codeCol">
                  <input
                    ref={digit2Ref}
                    className="digit"
                    name={InputNames.DIGIT_2}
                    type="number"
                    value={digit2}
                    onChange={handleInputChange}
                    onFocus={handleInputClick}
                    onBlur={handleInputBlur}
                    inputMode="numeric"
                  />
                </div>
                <div className="codeCol">
                  <input
                    ref={digit3Ref}
                    className="digit"
                    name={InputNames.DIGIT_3}
                    type="number"
                    value={digit3}
                    onChange={handleInputChange}
                    onFocus={handleInputClick}
                    onBlur={handleInputBlur}
                    inputMode="numeric"
                  />
                </div>
                <div className="codeCol">
                  <input
                    ref={digit4Ref}
                    className="digit"
                    name={InputNames.DIGIT_4}
                    type="number"
                    value={digit4}
                    onChange={handleInputChange}
                    onFocus={handleInputClick}
                    onBlur={handleInputBlur}
                    inputMode="numeric"
                  />
                </div>
              </div>

              <p className="confirmation-code">Confirmation Code</p>
              <Button
                className="verify-email-button"
                variant="contained"
                onClick={() => manuallyVerifyEmail()}
              >
                NEXT
              </Button>
            </div>
          </section>
          <section className="flex_col_acenter">
            <p className="link" onClick={resendCode}>Resend Code</p>
            <p className="link" onClick={() => {
              onClose();
              dispatch(signOut());
            }}>
              Sign Out
            </p>
          </section>
        </div>
      </Box>
    </Modal>
  );
};

export default SignupCodeModal;
