import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { StateStatus } from '../../app/app.types';

import {
  MasterKeySubmission,
  MasterKey,
  EmptyMasterKey,
  MasterKeyDetails,
  EmptyMasterKeyDetails,
  EmptyMasterKeySubmission,
  MasterKeyType
} from './masterKey.model';

import {
  getMasterKeyList,
  getMasterKeyById,
  getMasterKeyDetails,
  getMasterKeySubmissionList,
  getMasterKeySubmissionById,
  getMasterKeyTypeList
} from './masterKey.thunks';

/* Type */
export interface MasterKeyState {
  masterKeyList: {
    status: StateStatus;
    data: MasterKey[];
    totalMasterKeyCount: number;
    loaded: boolean;
  },
  masterKeyTypeList: {
    status: StateStatus;
    data: MasterKeyType[];
    loaded: boolean;
  },
  masterKey: {
    status: StateStatus;
    data: MasterKey;
  },
  showMasterKeyDeleteConfirmDialog: boolean,
  masterKeyIdToDelete: number,
  masterKeySubmissionList: {
    status: StateStatus;
    data: MasterKeySubmission[];
    totalMasterKeySubmissionCount: number;
    loaded: boolean;
  },
  masterKeyDetails: {
    data: MasterKeyDetails;
    status: StateStatus;
  },
  masterKeySubmission: {
    status: StateStatus;
    data: MasterKeySubmission;
  }
}

/* State */
const initialState: MasterKeyState = {
  masterKeyList: {
    status: StateStatus.IDLE,
    data: [],
    totalMasterKeyCount: 0,
    loaded: false,
  },
  masterKeyTypeList: {
    status: StateStatus.IDLE,
    data: [],
    loaded: false,
  },
  masterKey: {
    status: StateStatus.IDLE,
    data: EmptyMasterKey,
  },
  showMasterKeyDeleteConfirmDialog: false,
  masterKeyIdToDelete: 0,
  masterKeySubmissionList: {
    status: StateStatus.IDLE,
    data: [],
    totalMasterKeySubmissionCount: 0,
    loaded: false,
  },
  masterKeyDetails: {
    data: EmptyMasterKeyDetails,
    status: StateStatus.IDLE,
  },
  masterKeySubmission: {
    status: StateStatus.IDLE,
    data: EmptyMasterKeySubmission,
  },
};

/* Reducer */
export const masterKeySlice = createSlice({
  name: 'masterKey',
  initialState,
  reducers: {
    setShowMasterKeyDeleteConfirmDialog: (state, action) => {
      state.showMasterKeyDeleteConfirmDialog = action.payload;
    },
    setMasterKeyIdToDelete: (state, action) => {
      state.masterKeyIdToDelete = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMasterKeyList.pending, (state) => {
        state.masterKeyList.status = StateStatus.LOADING;
      })
      .addCase(getMasterKeyList.fulfilled, (state, action) => {
        state.masterKeyList.status = StateStatus.IDLE;
        state.masterKeyList.data = action.payload.masterKeys;
        state.masterKeyList.totalMasterKeyCount = action.payload.count;
        state.masterKeyList.loaded = true;
      })
      .addCase(getMasterKeyList.rejected, (state) => {
        state.masterKeyList.status = StateStatus.FAILED;
      })
      .addCase(getMasterKeyById.pending, (state) => {
        state.masterKey.status = StateStatus.LOADING;
      })
      .addCase(getMasterKeyById.fulfilled, (state, action) => {
        state.masterKey.status = StateStatus.IDLE;
        state.masterKey.data = action.payload;
      })
      .addCase(getMasterKeyById.rejected, (state) => {
        state.masterKey.status = StateStatus.FAILED;
      })
      .addCase(getMasterKeySubmissionList.pending, (state) => {
        state.masterKeySubmissionList.status = StateStatus.LOADING;
      })
      .addCase(getMasterKeySubmissionList.fulfilled, (state, action) => {
        state.masterKeySubmissionList.status = StateStatus.IDLE;
        state.masterKeySubmissionList.data = action.payload.masterKeys;
        state.masterKeySubmissionList.totalMasterKeySubmissionCount = action.payload.count;
        state.masterKeySubmissionList.loaded = true;
      })
      .addCase(getMasterKeySubmissionList.rejected, (state) => {
        state.masterKeySubmissionList.status = StateStatus.FAILED;
      })
      .addCase(getMasterKeyDetails.pending, (state) => {
        state.masterKeyDetails.status = StateStatus.LOADING;
      })
      .addCase(getMasterKeyDetails.fulfilled, (state, action) => {
        state.masterKeyDetails.status = StateStatus.IDLE;
        state.masterKeyDetails.data = action.payload;
      })
      .addCase(getMasterKeyDetails.rejected, (state) => {
        state.masterKeyDetails.status = StateStatus.FAILED;
      })
      .addCase(getMasterKeySubmissionById.pending, (state) => {
        state.masterKeySubmission.status = StateStatus.LOADING;
      })
      .addCase(getMasterKeySubmissionById.fulfilled, (state, action) => {
        state.masterKeySubmission.status = StateStatus.IDLE;
        state.masterKeySubmission.data = action.payload;
      })
      .addCase(getMasterKeySubmissionById.rejected, (state) => {
        state.masterKeySubmission.status = StateStatus.FAILED;
      })
      .addCase(getMasterKeyTypeList.pending, (state) => {
        state.masterKeyTypeList.status = StateStatus.LOADING;
      })
      .addCase(getMasterKeyTypeList.fulfilled, (state, action) => {
        state.masterKeyTypeList.status = StateStatus.IDLE;
        state.masterKeyTypeList.data = action.payload;
        state.masterKeyTypeList.loaded = !!action.payload.length;
      })
      .addCase(getMasterKeyTypeList.rejected, (state) => {
        state.masterKeyTypeList.status = StateStatus.FAILED;
      });
  },
});

export const { setShowMasterKeyDeleteConfirmDialog, setMasterKeyIdToDelete } = masterKeySlice.actions;

/* Selectors */
const selectMasterKeyListStatus = (state: RootState): StateStatus => state.masterKey.masterKeyList.status || StateStatus.IDLE;
const selectMasterKeyList = (state: RootState): MasterKey[] => state.masterKey.masterKeyList.data || [];
const selectTotalMasterKeyListCount = (state: RootState): number => state.masterKey.masterKeyList.totalMasterKeyCount;
const selectMasterKey = (state: RootState): MasterKey => state.masterKey.masterKey.data;
const selectMasterKeyStatus = (state: RootState): StateStatus => state.masterKey.masterKey.status;
const selectShowMasterKeyDeleteConfirmDialog = (state: RootState): boolean => state.masterKey.showMasterKeyDeleteConfirmDialog;
const selectMasterKeyIdToDelete = (state: RootState): number => state.masterKey.masterKeyIdToDelete;
const selectMasterKeySubmissionListStatus = (state: RootState): StateStatus => state.masterKey.masterKeySubmissionList.status || StateStatus.IDLE;
const selectMasterKeySubmissionList = (state: RootState): MasterKeySubmission[] => state.masterKey.masterKeySubmissionList.data || [];
const selectTotalMasterKeySubmissionListCount = (state: RootState): number => state.masterKey.masterKeySubmissionList.totalMasterKeySubmissionCount;
const selectMasterKeyDetailsStatus = (state: RootState): StateStatus => state.masterKey.masterKeyDetails.status;
const selectMasterKeyDetails = (state: RootState): MasterKeyDetails => state.masterKey.masterKeyDetails.data;
const selectMasterKeySubmissionStatus = (state: RootState): StateStatus => state.masterKey.masterKeySubmission.status;
const selectMasterKeySubmission = (state: RootState): MasterKeySubmission => state.masterKey.masterKeySubmission.data;
const selectMasterKeyTypeList = (state: RootState): MasterKeyType[] => state.masterKey.masterKeyTypeList.data;
const selectMasterKeyTypeListLoaded = (state: RootState): boolean => state.masterKey.masterKeyTypeList.loaded;

export const masterKeySelectors = {
  selectMasterKeyListStatus,
  selectMasterKeyList,
  selectTotalMasterKeyListCount,
  selectMasterKey,
  selectMasterKeyStatus,
  selectShowMasterKeyDeleteConfirmDialog,
  selectMasterKeyIdToDelete,
  selectMasterKeySubmissionListStatus,
  selectMasterKeySubmissionList,
  selectTotalMasterKeySubmissionListCount,
  selectMasterKeyDetails,
  selectMasterKeyDetailsStatus,
  selectMasterKeySubmissionStatus,
  selectMasterKeySubmission,
  selectMasterKeyTypeList,
  selectMasterKeyTypeListLoaded,
};

export default masterKeySlice.reducer;
