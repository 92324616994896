/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import {
  HTTP_METHOD, requestFactory
} from '../../app/app.api';
import { ListMenuItem } from '../../components/forms/form.types';
import {
  Organization,
  OrganizationListQueryParams,
  ConstructOrganization,
  NewOrganization
} from './organization.model';


export const uploadImage = async (orgId: string, image: string | Blob): Promise<Organization> => {
  const newData = new FormData();
  newData.append('files', image);
  newData.append('resource_id', orgId);
  newData.append('resource_name', 'organization');
  const response = await requestFactory(HTTP_METHOD.POST, '/assets/upload', undefined, newData);
  return await response.data;
};

export const getOrganizationList = async (query: OrganizationListQueryParams): Promise<{ organizations: Organization[], count: number }> => {
  const limitValue = query.limit == 'none' ? '' : `&limit=${query.limit || 100}`;
  const typeParam = query.type ? `&type=${query.type}` : '';
  const searchParam = query.search ? `&search=${query.search}` : '';
  const schoolTypeParam = query.schoolType && query.type === 'school' ? `&school_type=${query.schoolType}` : '';
  const schoolDistrictsParam = query.schoolDistricts ? `&school_districts=${query.schoolDistricts}` : '';
  let orderBy = query.sortModel?.length ? `&order_by=${query.sortModel[0].field}` : '';
  const orderDirecton = query.sortModel?.length ? `&order_direction=${query.sortModel[0].sort}` : '';

  orderBy = orderBy.replace('createdAt', 'created_at');

  const url = `/organization?offset=${query.offset}${limitValue}${typeParam}${searchParam}${schoolTypeParam}${schoolDistrictsParam}${orderBy}${orderDirecton}`;

  const { data } = await requestFactory(HTTP_METHOD.GET, url);

  const organizations = data.data.map((u: any) => ConstructOrganization(u));

  return {
    organizations,
    count: data.count,
  };
};

export const getAllSchoolDistricts = async (): Promise<Organization[]> => {
  const { data } = await requestFactory(HTTP_METHOD.GET, '/schooldistricts');

  return data.data.map((district: any) => ConstructOrganization(district) as Organization);
};

export const getOrganizationsByType = async (orgType?: string, schoolDistrictId?: number, onlySchools?: boolean, allowAll?: boolean): Promise<Organization[]> => {
  const onlySchoolsParam = onlySchools ? 'schools?' : '?';
  const orgTypeParam = orgType ? `type=${orgType}&` : '';
  const schoolDistrictIdParam = schoolDistrictId ? `school_district_id=${schoolDistrictId}` : '';
  const allowAllParam = allowAll ? 'allow_all=true' : '';

  const url = `/organization/${onlySchoolsParam}${orgTypeParam}${schoolDistrictIdParam}${allowAllParam}`;

  const { data } = await requestFactory(HTTP_METHOD.GET, url);

  return data.data.map((org: any) => ConstructOrganization(org) as Organization);
};


export const getSchools = async (): Promise<Organization[]> => {
  const response = await requestFactory(HTTP_METHOD.GET, '/organization/schools');

  const data = await response.data.data;

  return data;
};

export const getOrganizationById = async (id: number): Promise<Organization> => {
  const response = await requestFactory(HTTP_METHOD.GET, `/organization/${id}`);

  const data = await response.data.data;

  const organization = ConstructOrganization(data);

  return organization;
};

export const createNewOrganization = async (newOrganization: NewOrganization): Promise<any> => {
  const body = {
    name: newOrganization.name,
    school_district_id: newOrganization.schoolDistrict?.id,
    type: newOrganization.type?.id,
    url: newOrganization.url,
    community_organization_type_id: newOrganization.organizationType?.id,
    community_user: {
      first_name: newOrganization.contactFirstName,
      last_name: newOrganization.contactLastName,
      email: newOrganization.contactEmail,
      phone_number: newOrganization.contactPhoneNumber,
      career_field_ids: newOrganization.careerGroups?.map((item: ListMenuItem) => item.id),
    },
  };

  const response = await requestFactory(HTTP_METHOD.POST, '/organization', undefined, body);

  if (newOrganization.logo && !!newOrganization.logo.length) {
    await uploadImage(response.data.id, newOrganization.logo[0]);
  }

  return await response.data;
};

export const updateOrganization = async (organization: NewOrganization): Promise<any> => {
  const body = {
    name: organization.name,
    school_district_id: organization.schoolDistrict?.id,
    type: organization.type?.id,
    url: organization.url,
    community_organization_type_id: organization.organizationType?.id,
    community_user: {
      first_name: organization.contactFirstName,
      last_name: organization.contactLastName,
      email: organization.contactEmail,
      phone_number: organization.contactPhoneNumber,
      career_field_ids: organization.careerGroups?.map((item: ListMenuItem) => item.id),
    },
  };

  const response = await requestFactory(HTTP_METHOD.PATCH, `/organization/${organization.id}`, undefined, body);

  if (organization.logo && !!organization.logo.length) {
    await uploadImage(response.data.data.id, organization.logo[0]);
  }

  return await response.data;
};

export const deleteOrganization = async (id: number): Promise<boolean> => {
  await requestFactory(HTTP_METHOD.DELETE, `/organization/${id}/remove`);
  return true;
};

export const getCommunityOrganizationTypes = async (): Promise<ListMenuItem[]> => {
  const { data } = await requestFactory(HTTP_METHOD.GET, '/community-organization-types');

  return await data.data.map((ele: any) => {
    return {
      id: ele.id,
      label: ele.title,
    };
  });
};
