import {
  Button, FormHelperText
} from '@mui/material';
import React, {
  ReactNode,
  useEffect,
  useState
} from 'react';
import {
  Controller, FieldError, FieldErrorsImpl, Merge, useFormContext
} from 'react-hook-form';

import DeleteIcon from '@mui/icons-material/Delete';

import './UploadButton.scss';


interface UploadButtonProps {
  className?: string;
  text?: string;
  name: `${string}` | `${string}.${string}` | `${string}.${number}`;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorMessage?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  variant?: 'text' | 'outlined' | 'contained';
  size?: 'small' | 'medium' | 'large';
  disableElevation?: boolean;
  defaultFileName?: string;
  disabled?: boolean;
}

const UploadButton: React.FC<UploadButtonProps> = ({
  className,
  text,
  name,
  errorMessage,
  variant,
  size,
  disableElevation,
  defaultFileName,
  disabled,
}) => {

  const { control } = useFormContext();

  const [
    file,
    setFile,
  ] = useState('');
  const [
    fileName,
    setFileName,
  ] = useState(defaultFileName);

  useEffect(() => {
    setFileName(defaultFileName);
  }, [
    defaultFileName,
  ]);

  return (
    <div>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <div id="upload-button">
            <input id="contained-button-file"
              {...field}
              type="file"
              accept="image/x-png,image/jpeg"
              value={file}
              onChange={(e) => {
                setFile(e.target.value);
                setFileName(e.target.value.split('\\').pop());
                field.onChange(e.target.files);
              }}
              disabled={disabled}
              hidden
            />

            {!fileName && (
              <label htmlFor="contained-button-file">
                <Button
                  className={className}
                  variant={variant ? variant : 'outlined'}
                  size={size}
                  disableElevation={disableElevation}
                  component="span"
                  disabled={disabled}
                >
                  {text}
                </Button>
              </label>
            )}

            {fileName && (
              <div className="uploaded-file">
                <span>{fileName}</span>
                {!disabled && (
                  <DeleteIcon
                    className="cancel-icon"
                    onClick={() => {
                      setFile('');
                      setFileName('');
                    }}
                  />
                )}
              </div>
            )}
          </div>
        )}
      />
      {errorMessage && (
        <FormHelperText className="helper-message error-message">
          {errorMessage as ReactNode}
        </FormHelperText>
      )}
    </div>
  );
};

export default UploadButton;
