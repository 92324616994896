import React,
{ useEffect } from 'react';
import {
  FormGroup,
  FormLabel,
  Popover
} from '@mui/material';
import {
  FormProvider, SubmitHandler, useForm
} from 'react-hook-form';
import {
  bottomRightAnchorOrigin, topCenterTransformOrigin
} from '../../../../constants/menu.types';
import DropdownSelect from '../../../../components/forms/DropdownSelect';
import CloseIcon from '@mui/icons-material/Close';
import SubmitButton from '../../../../components/forms/SubmitButton';
import { ListMenuItem } from '../../../../components/forms/form.types';
import { SecondaryColorRadioGroup } from '../../../../components/CustomStyledComponents';
import {
  useAppDispatch, useAppSelector
} from '../../../../app/hooks';
import { collegeApplicationChecklistSelectors } from '../../../../features/collegeApplicationChecklist/collegeApplicationChecklist.slice';
import { getCollegesList } from '../../../../features/collegeApplicationChecklist/collegeApplicationChecklist.thunks';
import { College } from '../../../../features/collegeApplicationChecklist/collegeApplicationChecklist.model';
import { userSelectors } from '../../../../features/user/user.slice';


interface ApplicantFilterProps {
  anchorElement: HTMLElement | null;
  setAnchorElement: (anchorElement: HTMLElement | null) => void;
  setFiltersCallback: (colleges?: number[], applicationInterested?: 'application' | 'interested') => void;
}

const ApplicantFilter: React.FC<ApplicantFilterProps> = ({
  anchorElement,
  setAnchorElement,
  setFiltersCallback,
}) => {

  const applicantFilterTypes: ListMenuItem[] = [
    {
      id: 'application',
      label: 'Application',
    },
    {
      id: 'interested',
      label: 'Interested',
    },
  ];

  const dispatch = useAppDispatch();
  const collegesList = useAppSelector(collegeApplicationChecklistSelectors.selectCollegeList);
  const user = useAppSelector(userSelectors.selectUser);

  useEffect(() => {
    if (!collegesList.length) {
      dispatch(getCollegesList({
        ...{
          offset: 0,
          limit: undefined,
          search: undefined,
          sortModel: undefined,
        },
        offset: 0,
      }));
    }
  }, [
    collegesList,
  ]);

  type FormValues = { colleges?: College[], queryType?: 'application' | 'interested' };
  const methods = useForm<FormValues>();
  const { handleSubmit } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    const collegeFilter = values.colleges ? values.colleges.map((c: College) => c.id) : undefined;
    const applicationInterested = values.queryType || undefined;
    setFiltersCallback(collegeFilter, applicationInterested);
    setAnchorElement(null);
  };

  return (
    <Popover
      id="list-filter"
      anchorEl={anchorElement}
      open={Boolean(anchorElement)}
      onClose={() => setAnchorElement(null)}
      transformOrigin={topCenterTransformOrigin}
      anchorOrigin={bottomRightAnchorOrigin}
    >
      <CloseIcon className="close-icon" onClick={() => setAnchorElement(null)} />
      <form className="flex_col" onSubmit={handleSubmit(submitForm)}>
        <FormProvider {...methods}>

          <FormLabel id="application-interested-filter">Application/Interested</FormLabel>
          <SecondaryColorRadioGroup
            name="queryType"
            itemList={applicantFilterTypes}
            labelledBy="applicant-type-filter"
            size="small"
          />

          {(user.isAdmin || user.isDistrictInstitutionAdmin) && (
            <>
              <FormLabel id="colleges-filter">Colleges</FormLabel>
              <FormGroup
                aria-labelledby="colleges-filter"
              >
                <DropdownSelect
                  name="colleges"
                  itemList={collegesList}
                  size="small"
                  colorVariant="secondary"
                  multiple />
              </FormGroup>
            </>
          )}
          <SubmitButton
            className="submit-button"
            text="Apply Filters"
            variant="contained"
            size="small"
            color="secondary"
            disableElevation
          />
        </FormProvider>
      </form>
    </Popover>
  );
};

export default ApplicantFilter;
