import React, { useEffect } from 'react';
import './AdminAnnouncementBanner.scss';
import {
  useAppDispatch, useAppSelector
} from '../../app/hooks';
import {
  getAdminAnnouncement, setLastSeenAnnouncementId
} from '../../features/dashboard/dashboard.thunk';
import { dashboardSelectors } from '../../features/dashboard/dashboard.slice';
import { addUrlProtocol } from '../../util/formatting';
import { AppAssetPaths } from '../../app/app.types';

import CloseIcon from '@mui/icons-material/Close';


interface AdminAnnouncementBannerProps { }

const AdminAnnouncementBanner: React.FC<AdminAnnouncementBannerProps> = () => {

  const dispatch = useAppDispatch();

  const announcement = useAppSelector(dashboardSelectors.selectAdminAnnouncement);

  useEffect(() => {
    dispatch(getAdminAnnouncement());
  }, []);

  if (announcement) {
    return (
      <div id="admin-announcement-banner" className="card-background flex">
        <div className="megaphone-icon flex_jcenter_acenter">
          <img src={AppAssetPaths.icons.MEGAPHONE_WHITE} />
        </div>

        <div className="announcement-content flex_col_jcenter">
          <h3>Announcement</h3>
          <p>{announcement.message}</p>
          <a href={addUrlProtocol(announcement.link)} target="_blank" rel="noreferrer noopener">
            {announcement.link}
          </a>
        </div>

        <CloseIcon className="close-icon" onClick={() => dispatch(setLastSeenAnnouncementId(announcement.id))} />
      </div>
    );
  }

  return (
    <></>
  );
};

export default AdminAnnouncementBanner;
