/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import dayjs from 'dayjs';
import {
  HTTP_METHOD, requestFactory
} from '../../app/app.api';
import { createExportFile } from '../exports/exports.api';
import { Organization } from '../organization/organization.model';
import { User } from '../user/user.model';
import {
  ConstructBulkUser,
  ConstructExisitingUser
} from './bulkAccountCreation.model';



export const downloadTemplateCsv = async (selectedSchool: Organization) => {
  const data = await requestFactory(HTTP_METHOD.GET, `/export/build-bulk-template/${selectedSchool.id}`);
  const fileName = `template_list_${dayjs().format('MM/DD/YYYY')}.csv`;
  return createExportFile(data, fileName);
};

export const uploadTemplateCsv = async (selectedSchool: Organization, csvFile: any) => {
  const body = {
    selectedSchool,
    csvFile,
  };
  const { data } = await requestFactory(HTTP_METHOD.POST, '/import/bulk-users', undefined, body);

  return {
    existingUsers: data.data.existingUsers.map((u: any) => ConstructExisitingUser(u)),
    newUsers: data.data.newUsers.map((u: any) => ConstructBulkUser(u, selectedSchool)),
  };
};

export const verifyEmails = async (emails: string[]): Promise<string[]> => {
  // Returns array of emails of users with exisiting emails or an empty array
  const response = await requestFactory(HTTP_METHOD.POST, '/users/check-email-taken', undefined, {
    emails,
  });
  if (response.data.data.length > 0 ) {
    return response.data.data.map((el: User) => el.email);
  } else {
    return [];
  }

};



export const bulkCreateUsers = async (newUsers: any[], selectedSchool: Organization) => {
  const body = {
    newUsers: newUsers,
    selectedSchool,
  };
  const { data } = await requestFactory(HTTP_METHOD.POST, '/users/bulk-create', undefined, body);
  return data;
};
