import React, {
  useEffect, useState
} from 'react';
import {
  Box, Button, Menu, MenuItem, Pagination, Tab, Tabs
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  DataGrid, GridRowClassNameParams, GridSortModel
} from '@mui/x-data-grid';
import { TableState } from '../../../constants/tables/table.types';
import {
  applicantsListColumns, CollegeApplicationChecklistTabs, collegesListColumns, financialAidListColumns
} from '../../../constants/tables/collegeApplicationChecklistTable.types';
import {
  FormProvider, SubmitHandler, useForm
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import SearchInput from '../../../components/table/SearchInput';
import TableRowCount from '../../../components/table/TableRowCount';
import {
  ApplicantListQueryParams, CollegeListQueryParams, FinancialAidListItemNames
} from '../../../features/collegeApplicationChecklist/collegeApplicationChecklist.model';
import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import { collegeApplicationChecklistSelectors } from '../../../features/collegeApplicationChecklist/collegeApplicationChecklist.slice';
import {
  getCollegesList,
  getFinancialAidList,
  getApplicantsList
} from '../../../features/collegeApplicationChecklist/collegeApplicationChecklist.thunks';
import { useNavigate } from 'react-router-dom';
import {
  AppAssetPaths, AppLinks, AppRoutes, canUseAuthComponent
} from '../../../app/app.types';
import { DownloadButton } from '../../../components/CustomStyledComponents';
import { userSelectors } from '../../../features/user/user.slice';
import { getUserSchoolType } from '../../../features/user/user.model';
import ApplicantFilter from './applicants/ApplicantFilter';
import { exportCollegeApplicationChecklistApplicantsCSV } from '../../../features/exports/exports.api';
import { defaultMenuPaperProps } from '../../../constants/menu.types';

import './CollegeApplicationChecklistPage.scss';


interface CollegeApplicationChecklistPageProps {
  initialTab: string;
}

const CollegeApplicationChecklistPage: React.FC<CollegeApplicationChecklistPageProps> = ({ initialTab }) => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector(userSelectors.selectUser);
  const applicantsList = useAppSelector(collegeApplicationChecklistSelectors.selectApplicantList);
  const totalApplicantCount = useAppSelector(collegeApplicationChecklistSelectors.selectApplicantListCount);
  const collegesList = useAppSelector(collegeApplicationChecklistSelectors.selectCollegeList);
  const totalCollegesCount = useAppSelector(collegeApplicationChecklistSelectors.selectCollegeListCount);
  const financialAidList = useAppSelector(collegeApplicationChecklistSelectors.selectFinancialAidList);
  const totalFinancialAidListCount = useAppSelector(collegeApplicationChecklistSelectors.selectFinancialAidListCount);

  const [
    selectedTab,
    setSelectedTab,
  ] = useState('');
  const [
    tableState,
    setTableState,
  ] = useState<TableState>({
    page: 1,
    pageSize: 100,
    columns: applicantsListColumns,
    rows: [],
    loading: false,
  });
  const [
    applicantsListQuery,
    setApplicantsListQuery,
  ] = useState<ApplicantListQueryParams>({
    offset: 0,
    limit: undefined,
    search: undefined,
    sortModel: undefined,
    colleges: undefined,
    applicationInterested: undefined,
  });
  const [
    collegesListQuery,
    setCollegesListQuery,
  ] = useState<CollegeListQueryParams>({
    offset: 0,
    limit: undefined,
    search: undefined,
    sortModel: undefined,
  });

  // For Filter
  const [
    filterAnchorElement,
    setFilterAnchorElement,
  ] = useState<HTMLElement | null>(null);
  // For Download Export
  const [
    anchorElement,
    setAnchorElement,
  ] = useState<null | HTMLElement>(null);


  const changeColumns = () => {
    if (selectedTab === CollegeApplicationChecklistTabs.applicants) {
      return applicantsListColumns;
    }
    else if (selectedTab === CollegeApplicationChecklistTabs.colleges) {
      return collegesListColumns;
    }
    else return financialAidListColumns;
  };


  useEffect(() => {
    setSelectedTab(initialTab);
  }, [
    initialTab,
  ]);

  // Load a different list
  useEffect(() => {
    setTableState((prev) => ({
      ...prev,
      page: 1,
      columns: changeColumns(),
      rows: [],
      loading: true,
    }));

    if (selectedTab === CollegeApplicationChecklistTabs.applicants) {
      // Create Dispatch to get the applicants list data
      dispatch(getApplicantsList({
        ...applicantsListQuery,
        offset: 0,
      }));
    }
    else if (selectedTab === CollegeApplicationChecklistTabs.colleges) {
      // Create Dispatch to get the colleges list data
      dispatch(getCollegesList({
        ...collegesListQuery,
        offset: 0,
      }));

    }
    else if (selectedTab === CollegeApplicationChecklistTabs.financialAid) {
      dispatch(getFinancialAidList());
    }
  }, [
    selectedTab,
  ]);

  // Filtering list
  useEffect(() => {
    setTableState((prev) => ({
      ...prev,
      page: 1,
      rows: [],
      loading: true,
    }));

    if (selectedTab === CollegeApplicationChecklistTabs.applicants) {
      // Dispatch to get Applicants list with the applicantsListQuery as a param
      dispatch(getApplicantsList({
        ...applicantsListQuery,
        offset: 0,
      }));
    }
    else if (selectedTab === CollegeApplicationChecklistTabs.colleges) {
      // Dispatch to get Applicants list with the collegesListQuery as a param
      dispatch(getCollegesList({
        ...collegesListQuery,
        offset: 0,
      }));
    }

  }, [
    applicantsListQuery,
    collegesListQuery,
  ]);

  // Load more data for pagination
  useEffect(() => {
    if (selectedTab === CollegeApplicationChecklistTabs.applicants) {
      if (tableState.page < applicantsList.length / tableState.pageSize || !applicantsList.length || applicantsList.length >= totalApplicantCount) return;
    }
    else if (selectedTab === CollegeApplicationChecklistTabs.colleges) {
      if (tableState.page < collegesList.length / tableState.pageSize || !collegesList.length || collegesList.length >= totalCollegesCount) return;
    }

    setTableState((prev) => ({
      ...prev,
      loading: true,
    }));

    if (selectedTab === CollegeApplicationChecklistTabs.applicants) {
      // Dispatch with applicants list query and offset to get paginated data
      dispatch(getApplicantsList({
        ...applicantsListQuery,
        offset: tableState.pageSize * (tableState.page - 1),
      }));
    }
    else if (selectedTab === CollegeApplicationChecklistTabs.colleges) {
      // Dispatch with applicants list query and offset to get paginated data
      dispatch(getCollegesList({
        ...collegesListQuery,
        offset: tableState.pageSize * (tableState.page - 1),
      }));
    }
  }, [
    tableState.page,
    tableState.pageSize,
  ]);

  useEffect(() => {
    setTableState((prev) => ({
      ...prev,
      loading: false,
      rows: selectedTab === CollegeApplicationChecklistTabs.applicants ? applicantsList
        : selectedTab === CollegeApplicationChecklistTabs.colleges ? collegesList
          : financialAidList,
    }));
  }, [
    applicantsList,
    collegesList,
    financialAidList,
  ]);


  const formValidationSchema = yup.object().shape({
    search: yup.string(),
  });

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const { handleSubmit } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    if (selectedTab === CollegeApplicationChecklistTabs.applicants) {
      // Dispatch to get Applicants list with the applicantsListQuery as a param
      setApplicantsListQuery((prev) => ({
        ...prev,
        search: values.search,
      }));
    }
    else if (selectedTab === CollegeApplicationChecklistTabs.colleges) {
      // Dispatch to get Applicants list with the collegesListQuery as a param
      setCollegesListQuery((prev) => ({
        ...prev,
        search: values.search,
      }));
    }
  };

  const handleRowClass = (params: GridRowClassNameParams) => {
    if (selectedTab === CollegeApplicationChecklistTabs.applicants && params.row.roles) {
      return `${getUserSchoolType(params.row.roles)?.replace(/_/g, '-')}-row`;
    }
    else {
      return '';
    }
  };

  return (
    <div id="college-application-checklist-list-container">
      <Box className="page-content card-background">
        <Tabs
          className="main-page-tabs"
          variant="fullWidth"
          value={selectedTab}
          onChange={(_, tabName) => setSelectedTab(tabName)}
        >
          {canUseAuthComponent(user.roles, AppRoutes.collegeApplicationChecklist.authorizedRoles) && (
            <Tab label="Applicants" value={CollegeApplicationChecklistTabs.applicants} />
          )}
          {canUseAuthComponent(user.roles, AppRoutes.collegeApplicationChecklistColleges.authorizedRoles) && (
            <Tab label="Colleges" value={CollegeApplicationChecklistTabs.colleges} />
          )}
          {canUseAuthComponent(user.roles, AppRoutes.collegeApplicationChecklistFinancialAidList.authorizedRoles) && (
            <Tab label="Financial Aid" value={CollegeApplicationChecklistTabs.financialAid} />
          )}
        </Tabs>

        {selectedTab !== CollegeApplicationChecklistTabs.financialAid && (
          <div className="table-buttons-and-filters flex_row_jbetween">
            <div className="filters flex_acenter">
              <form className="content-container" onSubmit={handleSubmit(submitForm)}>
                <FormProvider {...methods}>
                  <SearchInput
                    className="main-input search"
                    name="search"
                    onClear={handleSubmit(submitForm)}
                  />
                </FormProvider>
              </form>

              {(selectedTab === CollegeApplicationChecklistTabs.applicants) && (user.isAdmin || user.isDistrictInstitutionAdmin || user.isInstitutionAdmin) && (
                <div className="flex_acenter filter-button" onClick={(e) => setFilterAnchorElement(e.currentTarget)}>
                  <img src={AppAssetPaths.icons.FILTER} />
                  <p>Filter</p>
                </div>
              )}
            </div>

            {(selectedTab === CollegeApplicationChecklistTabs.colleges && user.isAdmin) && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate(AppRoutes.createCollegeApplicationChecklistCollege.path)}
              >
                New College
              </Button>
            )}

            {(selectedTab === CollegeApplicationChecklistTabs.applicants) && (
              <div className="flex_acenter">
                {(user.isAdmin || user.isDistrictInstitutionAdmin) && (
                  <Button
                    className="metabase-link"
                    variant="contained"
                    onClick={() => window.open(AppLinks.METABASE_COLLGE_APPLICATION_WEEK)}
                  >
                    Metabase
                  </Button>
                )}

                {user.isAdmin && (
                  <>
                    <DownloadButton
                      variant="contained"
                      startIcon={<img src={AppAssetPaths.icons.DOWNLOAD} />}
                      endIcon={<ExpandMoreIcon />}
                      disableElevation
                      onClick={(e) => setAnchorElement(e.currentTarget)}
                    >
                      Download
                    </DownloadButton>
                    <Menu className="flex_row_jcenter_acenter"
                      anchorEl={anchorElement}
                      open={Boolean(anchorElement)}
                      onClose={() => setAnchorElement(null)}
                      autoFocus={false}
                      PaperProps={defaultMenuPaperProps}
                    >
                      <MenuItem onClick={() => (setAnchorElement(null), exportCollegeApplicationChecklistApplicantsCSV('applicants'))}>
                        Applicants Only
                      </MenuItem>
                      <MenuItem onClick={() => (setAnchorElement(null), exportCollegeApplicationChecklistApplicantsCSV('interested'))}>
                        Interested Users Only
                      </MenuItem>
                      <MenuItem onClick={() => (setAnchorElement(null), exportCollegeApplicationChecklistApplicantsCSV())}>
                        All Applicants &amp; Interested Users
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </div>
            )}
          </div>
        )}

        <DataGrid
          className={`table ${selectedTab===CollegeApplicationChecklistTabs.applicants && 'user-list'}`}
          getRowClassName={(params) => handleRowClass(params)}
          {...tableState}
          components={{
            Pagination: Pagination,
          }}
          componentsProps={{
            pagination: {
              page: tableState.page,
              count: Math.ceil((selectedTab === CollegeApplicationChecklistTabs.applicants) ? (totalApplicantCount / tableState.pageSize)
                : (selectedTab === CollegeApplicationChecklistTabs.colleges) ? (totalCollegesCount / tableState.pageSize)
                  : (totalFinancialAidListCount / tableState.pageSize)),
              onChange: (_: void, page: number) => {
                setTableState((prev) => ({
                  ...prev,
                  page,
                }));
              },
            },
          }}
          onRowClick={(event) => {
            if (selectedTab === CollegeApplicationChecklistTabs.applicants && user.isAdmin) {
              navigate(AppRoutes.userDetails.path.replace(':userId', event.row.userId.toString()));
            }
            else if (selectedTab === CollegeApplicationChecklistTabs.colleges) {
              navigate(AppRoutes.collegeApplicationChecklistCollegeDetails.path.replace(':collegeId', event.row.id.toString()));
            }
            else if (selectedTab === CollegeApplicationChecklistTabs.financialAid) {
              if (event.row.name === FinancialAidListItemNames.FinancialAidInfo) {
                navigate(AppRoutes.financialAidInfoDetails.path);
              }
              else {
                navigate(AppRoutes.fafsaLinksDetails.path);
              }
            }
          }}
          pagination
          paginationMode="server"
          sortingMode="server"
          sortModel={(selectedTab === CollegeApplicationChecklistTabs.applicants ? applicantsListQuery.sortModel : collegesListQuery.sortModel)}
          onSortModelChange={(newSortModel: GridSortModel) => {
            if (selectedTab === CollegeApplicationChecklistTabs.applicants) {
              // Dispatch to get Applicants list with the applicantsListQuery as a param
              setApplicantsListQuery((prev) => ({
                ...prev,
                sortModel: newSortModel,
              }));
            }
            else if (selectedTab === CollegeApplicationChecklistTabs.colleges) {
              // Dispatch to get Colleges list with the collegesListQuery as a param
              setCollegesListQuery((prev) => ({
                ...prev,
                sortModel: newSortModel,
              }));
            }
          }}
          disableColumnFilter
          disableColumnMenu
          hideFooterSelectedRowCount
        />

        <TableRowCount
          length={(selectedTab === CollegeApplicationChecklistTabs.applicants ? applicantsList.length
            : selectedTab === CollegeApplicationChecklistTabs.colleges ? collegesList.length
              : financialAidList.length)}
          pageSize={tableState.pageSize}
          page={tableState.page}
          totalCount={(selectedTab === CollegeApplicationChecklistTabs.applicants ? totalApplicantCount
            : selectedTab === CollegeApplicationChecklistTabs.colleges ? totalCollegesCount
              : totalFinancialAidListCount)}
        />
      </Box>

      {/* Filter Menu */}
      <ApplicantFilter
        anchorElement={filterAnchorElement}
        setAnchorElement={setFilterAnchorElement}
        setFiltersCallback={(colleges?: number[], applicationInterested?: 'application' | 'interested') => {
          setApplicantsListQuery((prev) => ({
            ...prev,
            colleges,
            applicationInterested,
          }));
        }}
      />
    </div>
  );
};

export default CollegeApplicationChecklistPage;
