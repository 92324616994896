import React, {
  useEffect, useState
} from 'react';
import {
  FormProvider, SubmitHandler, useFieldArray, useForm
} from 'react-hook-form';
import {
  useLocation, useNavigate, useParams
} from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, CircularProgress, Divider, Menu, MenuItem
} from '@mui/material';
import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import DropdownSelect from '../../../components/forms/DropdownSelect';
import SubmitButton from '../../../components/forms/SubmitButton';
import TextInput from '../../../components/forms/TextInput';
import { setAlert } from '../../../features/alert/alert.slice';
import { userSelectors } from '../../../features/user/user.slice';
import { pathwaySelectors } from '../../../features/pathways/pathways.slice';
import {
  createPathway,
  deletePathway,
  getPathwayDetails,
  getPathwayTypeList,
  schoolAdminUpdatePathway,
  updatePathway
} from '../../../features/pathways/pathways.thunks';
import { careerSelectors } from '../../../features/careers/careers.slice';
import { getCareerClusters } from '../../../features/careers/careers.thunks';
import {
  ConstructEmptyPathwayCourseByType, PathwayCourse, PathwayCourseType
} from '../../../features/pathways/pathways.model';
import {
  AppAssetPaths, AppRoutes
} from '../../../app/app.types';
import { defaultMenuPaperProps } from '../../../constants/menu.types';
import { yesNoDropdownList } from '../../../util/dropdown';

import MiddleSchoolCourseForm from './courseForms/MiddleSchoolCourseForm';
import SupportingCourseForm from './courseForms/SupportingCourseForm';
import ExplorerCourseForm from './courseForms/ExplorerCourseForm';
import ConcentratorCourseForm from './courseForms/ConcentratorCourseForm';
import CompleterCourseForm from './courseForms/CompleterCourseForm';
import PostSecondaryResourceForm from './courseForms/PostSecondaryResourceForm';
import CertificateForm from './courseForms/CertificateForm';
import DeleteConfirmationModal from '../../../components/modal/DeleteConfirmationModal';

import './CreateEditPathway.scss';


interface CreateEditPathwayProps { }

const CreateEditPathway: React.FC<CreateEditPathwayProps> = () => {

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();

  const user = useAppSelector(userSelectors.selectUser);
  const pathway = useAppSelector(pathwaySelectors.selectPathwayDetails);
  const pathwayTypes = useAppSelector(pathwaySelectors.selectPathwayTypeList);
  const careerClusters = useAppSelector(careerSelectors.selectCareerClusters);

  const [
    pageLoaded,
    setPageLoaded,
  ] = useState(false);
  const [
    editMode,
    setEditMode,
  ] = useState(false);
  const [
    anchorElement,
    setAnchorElement,
  ] = useState<null | HTMLElement>(null);
  const [
    activeStatus,
    setActiveStatus,
  ] = useState(true);
  const [
    coursesToDelete,
    setCoursesToDelete,
  ] = useState<number[]>([]);
  const [
    showDeleteConfirmDialog,
    setShowDeleteConfirmDialog,
  ] = useState(false);

  useEffect(() => {
    dispatch(getPathwayTypeList());
    dispatch(getCareerClusters());
  }, []);

  useEffect(() => {
    if (!pathwayTypes.length || !careerClusters.length) { return; }

    if (!params || !params.id) {
      !middleSchoolCourseFields.length && appendMiddleSchoolCourse(ConstructEmptyPathwayCourseByType(PathwayCourseType.middleSchool));
      !supportingCourseFields.length && appendSupportingCourse(ConstructEmptyPathwayCourseByType(PathwayCourseType.supporting));
      !explorerCourseFields.length && appendExplorerCourse(ConstructEmptyPathwayCourseByType(PathwayCourseType.explorer));
      !concentratorCourseFields.length && appendConcentratorCourse(ConstructEmptyPathwayCourseByType(PathwayCourseType.concentrator));
      !completerCourseFields.length && appendCompleterCourse(ConstructEmptyPathwayCourseByType(PathwayCourseType.completer));
      !postSecondaryResourceFields.length && appendPostSecondaryResource(ConstructEmptyPathwayCourseByType(PathwayCourseType.postSecondaryResource));
      setPageLoaded(true);
      return;
    }

    if (!pathway.id) {
      const id = parseInt(params.id);
      dispatch(getPathwayDetails({
        id,
        organizationId: state && state.schoolId,
      })).then(() => setEditMode(true));
    }
    else {
      setEditMode(true);
    }
  }, [
    pathwayTypes,
    careerClusters,
  ]);

  useEffect(() => {
    if (editMode) {
      prePopulateValues();
      setPageLoaded(true);
    }
  }, [
    editMode,
  ]);

  const prePopulateValues = () => {
    setActiveStatus(pathway.active);
    setValue('name', pathway.name);
    setValue('credits', pathway.creditsToComplete);
    setValue('type', pathway.pathwayType ? pathwayTypes.find(p => p.id === pathway.pathwayType.id) : null);
    setValue('careerCluster', pathway.pathwayCluster ? careerClusters.find(p => p.id === pathway.pathwayCluster.id) : null);
    setValue('certificateTitle', pathway.certificateTitle);
    setValue('certificateText', pathway.certificateText);
    setValue('certificateActive', pathway.certificateActive);

    if (user.isDistrictAdmin || user.isHighSchoolAdmin) {
      setValue('note', pathway.pathwaySchool?.note);
    }

    // Middle school Courses
    if (pathway.courses?.middleSchool?.length) {
      !middleSchoolCourseFields.length && pathway.courses?.middleSchool.map((c: PathwayCourse) => appendMiddleSchoolCourse(c));
    }
    else if (user.isAdmin) {
      !middleSchoolCourseFields.length && appendMiddleSchoolCourse(ConstructEmptyPathwayCourseByType(PathwayCourseType.middleSchool));
    }

    // Supporting Courses
    if (pathway.courses?.supporting?.length) {
      !supportingCourseFields.length && pathway.courses?.supporting.map((c: PathwayCourse) => appendSupportingCourse({
        ...c,
        concurrentEnrollment: c.concurrentEnrollment ? yesNoDropdownList[0] : yesNoDropdownList[1],
      }));
    }
    else if (user.isAdmin) {
      !supportingCourseFields.length && appendSupportingCourse(ConstructEmptyPathwayCourseByType(PathwayCourseType.supporting));
    }

    // Explorer Courses
    if (pathway.courses?.explorer?.length) {
      !explorerCourseFields.length && pathway.courses?.explorer.map((c: PathwayCourse) => appendExplorerCourse({
        ...c,
        concurrentEnrollment: c.concurrentEnrollment ? yesNoDropdownList[0] : yesNoDropdownList[1],
        recommendedExplorerCourse: c.recommendedExplorerCourse ? yesNoDropdownList[0] : yesNoDropdownList[1],
      }));
    }
    else if (user.isAdmin) {
      !explorerCourseFields.length && appendExplorerCourse(ConstructEmptyPathwayCourseByType(PathwayCourseType.explorer));
    }

    // Concentrator Courses
    if (pathway.courses?.concentrator?.length) {
      !concentratorCourseFields.length && pathway.courses?.concentrator.map((c: PathwayCourse) => appendConcentratorCourse({
        ...c,
        concurrentEnrollment: c.concurrentEnrollment ? yesNoDropdownList[0] : yesNoDropdownList[1],
      }));
    }
    else if (user.isAdmin) {
      !concentratorCourseFields.length && appendConcentratorCourse(ConstructEmptyPathwayCourseByType(PathwayCourseType.concentrator));
    }

    // Completer Courses
    if (pathway.courses?.completer?.length) {
      !completerCourseFields.length && pathway.courses?.completer.map((c: PathwayCourse) => appendCompleterCourse({
        ...c,
        concurrentEnrollment: c.concurrentEnrollment ? yesNoDropdownList[0] : yesNoDropdownList[1],
      }));
    }
    else if (user.isAdmin) {
      !completerCourseFields.length && appendCompleterCourse(ConstructEmptyPathwayCourseByType(PathwayCourseType.completer));
    }

    // Post-Secondary Resources
    if (pathway.courses?.postSecondaryResources?.length) {
      !postSecondaryResourceFields.length && pathway.courses?.postSecondaryResources.map((c: PathwayCourse) => appendPostSecondaryResource(c));
    }
    else if (user.isAdmin) {
      !postSecondaryResourceFields.length && appendPostSecondaryResource(ConstructEmptyPathwayCourseByType(PathwayCourseType.postSecondaryResource));
    }
  };

  const prepCourseForDeletion = (courseId: number) => {
    setCoursesToDelete([
      ...coursesToDelete,
      courseId,
    ]);
  };

  const onDeletePathway = () => {
    setShowDeleteConfirmDialog(false);
    dispatch(deletePathway(pathway.id))
      .unwrap()
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully deleted pathway.',
        }));
        navigate(AppRoutes.pathways.path);
      })
      .catch((e) => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to delete pathway.',
        }));
      });
  };


  // Form validation
  const courseYupValidation = yup.array().of(
    yup.object().shape({
      name: yup.string(),
      link: yup.string().nullable().isValidUrl(),
    })
  );

  const formValidationSchema = yup.object().shape({
    name: yup.string()
      .required('Name is required'),
    credits: yup.number()
      .required('Credits are required')
      .typeError('Credits are required'),
    type: yup.object().nullable()
      .required('Type is required'),
    careerCluster: yup.object().nullable()
      .required('Career Cluster is required'),
    certificateTitle: yup.string().nullable()
      .when('certificateText', {
        is: (certificateText: string) => certificateText && !!certificateText.length,
        then: yup.string().required('Certificate title is required'),
      }),
    certificateText: yup.string().nullable(),
    certificateActive: yup.boolean(),
    middleSchoolCourses: courseYupValidation,
    supportingCourses: courseYupValidation,
    explorerCourses: courseYupValidation,
    concentratorCourses: courseYupValidation,
    completerCourses: courseYupValidation,
    postSecondaryResources: courseYupValidation,
  }).required();

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const { handleSubmit, setValue, getValues, control, formState: { errors } } = methods;
  const {
    fields: middleSchoolCourseFields,
    append: appendMiddleSchoolCourse,
    remove: removeMiddleSchoolCourse,
    update: updateMiddleSchoolCourse,
  } = useFieldArray({
    name: 'middleSchoolCourses',
    control,
  });
  const {
    fields: supportingCourseFields,
    append: appendSupportingCourse,
    remove: removeSupportingCourse,
    update: updateSupportingCourse,
  } = useFieldArray({
    name: 'supportingCourses',
    control,
  });
  const {
    fields: explorerCourseFields,
    append: appendExplorerCourse,
    remove: removeExplorerCourse,
    update: updateExplorerCourse,
  } = useFieldArray({
    name: 'explorerCourses',
    control,
  });
  const {
    fields: concentratorCourseFields,
    append: appendConcentratorCourse,
    remove: removeConcentratorCourse,
    update: updateConcentratorCourse,
  } = useFieldArray({
    name: 'concentratorCourses',
    control,
  });
  const {
    fields: completerCourseFields,
    append: appendCompleterCourse,
    remove: removeCompleterCourse,
    update: updateCompleterCourse,
  } = useFieldArray({
    name: 'completerCourses',
    control,
  });
  const {
    fields: postSecondaryResourceFields,
    append: appendPostSecondaryResource,
    remove: removePostSecondaryResource,
    update: updatePostSecondaryResource,
  } = useFieldArray({
    name: 'postSecondaryResources',
    control,
  });

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    values.active = activeStatus;
    // Remove all courses with no name value
    values.middleSchoolCourses = values.middleSchoolCourses.filter((c: PathwayCourse) => c.name);
    values.supportingCourses = values.supportingCourses.filter((c: PathwayCourse) => c.name);
    values.explorerCourses = values.explorerCourses.filter((c: PathwayCourse) => c.name);
    values.concentratorCourses = values.concentratorCourses.filter((c: PathwayCourse) => c.name);
    values.completerCourses = values.completerCourses.filter((c: PathwayCourse) => c.name);
    values.postSecondaryResources = values.postSecondaryResources.filter((c: PathwayCourse) => c.name);
    // Set certificateActive to false if it has no title
    values.certificateActive = values.certificateTitle ? values.certificateActive : false;

    if (editMode) {
      if (user.isDistrictAdmin || user.isHighSchoolAdmin) {
        dispatch(schoolAdminUpdatePathway({
          id: pathway.id,
          body: values,
          organizationId: state && state.schoolId,
        }))
          .unwrap()
          .then(() => {
            dispatch(setAlert({
              type: 'success',
              message: 'Successfully updated pathway.',
            }));
            navigate(-1);
          })
          .catch(() => {
            dispatch(setAlert({
              type: 'error',
              message: 'Unable to update pathway.',
            }));
          });

        return;
      }

      dispatch(updatePathway({
        id: pathway.id,
        body: values,
        deletedCourses: coursesToDelete,
      }))
        .unwrap()
        .then(() => {
          dispatch(setAlert({
            type: 'success',
            message: 'Successfully updated pathway.',
          }));
          navigate(-1);
        })
        .catch(() => {
          dispatch(setAlert({
            type: 'error',
            message: 'Unable to update pathway.',
          }));
        });
    }
    else {
      dispatch(createPathway(values))
        .unwrap()
        .then(() => {
          dispatch(setAlert({
            type: 'success',
            message: 'Successfully created pathway.',
          }));
          navigate(-1);
        })
        .catch(() => {
          dispatch(setAlert({
            type: 'error',
            message: 'Unable to create pathway.',
          }));
        });
    }
  };

  return (
    <form id="create-edit-pathway" onSubmit={handleSubmit(submitForm)}>
      {(pageLoaded) ? (
        <FormProvider {...methods}>
          <div className="card-background form-container">
            <div className="flex_jbetween_acenter">
              <h3>{editMode ? 'Edit CTE Pathway' : 'New CTE Pathway'}</h3>
              <div className="status flex_row_acenter" onMouseUp={(e) => setAnchorElement(e.currentTarget)}>
                <p>Status:</p>
                <div>
                  {activeStatus ? (
                    <img src={AppAssetPaths.icons.statusIcons.ACTIVE} alt="active" />
                  ) : (
                    <img src={AppAssetPaths.icons.statusIcons.INACTIVE} alt="inactive" />
                  )}
                </div>
                <Menu
                  anchorEl={anchorElement}
                  open={Boolean(anchorElement)}
                  onClose={() => setAnchorElement(null)}
                  autoFocus={false}
                  PaperProps={defaultMenuPaperProps}
                >
                  <MenuItem onClick={() => {
                    setActiveStatus(true);
                    setAnchorElement(null);
                  }}>
                    <img src={AppAssetPaths.icons.statusIcons.ACTIVE} alt="active" />
                  </MenuItem>
                  <MenuItem onClick={() => {
                    setActiveStatus(false);
                    setAnchorElement(null);
                  }}>
                    <img src={AppAssetPaths.icons.statusIcons.INACTIVE} alt="inactive" />
                  </MenuItem>
                </Menu>
              </div>
            </div>

            <Divider variant="middle" className="divider" />

            <div className="form-content flex_row_jbetween">
              <div className="form-column">
                <TextInput
                  name="name"
                  label="CTE PATHWAY NAME"
                  errorMessage={errors?.name?.message}
                  type="text"
                  size="small"
                  disabled={user.isDistrictAdmin || user.isHighSchoolAdmin}
                  required
                />
                <TextInput
                  name="credits"
                  label="TOTAL CREDITS TO COMPLETE PATHWAY"
                  errorMessage={errors?.credits?.message}
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      step: 0.5,
                    },
                  }}
                  size="small"
                  disabled={user.isDistrictAdmin || user.isHighSchoolAdmin}
                  required
                />
              </div>
              <div className="form-column">
                <DropdownSelect
                  name="type"
                  label="CTE PATHWAY TYPE"
                  itemList={pathwayTypes}
                  errorMessage={errors?.type?.message}
                  size="small"
                  disabled={user.isDistrictAdmin || user.isHighSchoolAdmin}
                  required
                />
                <DropdownSelect
                  name="careerCluster"
                  label="CAREER CLUSTER"
                  itemList={careerClusters}
                  errorMessage={errors?.careerCluster?.message}
                  size="small"
                  disabled={user.isDistrictAdmin || user.isHighSchoolAdmin}
                  required
                />
              </div>
            </div>
          </div>

          {/* Middle School Courses */}
          <div>
            <h2 className="section-header">Middle School/ Jr. High</h2>

            <MiddleSchoolCourseForm
              middleSchoolCourseFields={middleSchoolCourseFields}
              errors={errors}
              appendCourseCallback={appendMiddleSchoolCourse}
              removeCourseCallback={(fieldIndex: number, courseId?: number) => {
                removeMiddleSchoolCourse(fieldIndex);
                courseId && prepCourseForDeletion(courseId);
              }}
              updateCourseCallback={updateMiddleSchoolCourse}
              getValuesCallback={getValues}
            />
          </div>

          {/* High School Courses */}
          <div>
            <h2 className="section-header">High School</h2>

            <SupportingCourseForm
              supportingCourseFields={supportingCourseFields}
              errors={errors}
              appendCourseCallback={appendSupportingCourse}
              removeCourseCallback={(fieldIndex: number, courseId?: number) => {
                removeSupportingCourse(fieldIndex);
                courseId && prepCourseForDeletion(courseId);
              }}
              updateCourseCallback={updateSupportingCourse}
              getValuesCallback={getValues}
            />
            <ExplorerCourseForm
              explorerCourseFields={explorerCourseFields}
              errors={errors}
              appendCourseCallback={appendExplorerCourse}
              removeCourseCallback={(fieldIndex: number, courseId?: number) => {
                removeExplorerCourse(fieldIndex);
                courseId && prepCourseForDeletion(courseId);
              }}
              updateCourseCallback={updateExplorerCourse}
              getValuesCallback={getValues}
            />
            <ConcentratorCourseForm
              concentratorCourseFields={concentratorCourseFields}
              errors={errors}
              appendCourseCallback={appendConcentratorCourse}
              removeCourseCallback={(fieldIndex: number, courseId?: number) => {
                removeConcentratorCourse(fieldIndex);
                courseId && prepCourseForDeletion(courseId);
              }}
              updateCourseCallback={updateConcentratorCourse}
              getValuesCallback={getValues}
            />
            <CompleterCourseForm
              completerCourseFields={completerCourseFields}
              errors={errors}
              appendCourseCallback={appendCompleterCourse}
              removeCourseCallback={(fieldIndex: number, courseId?: number) => {
                removeCompleterCourse(fieldIndex);
                courseId && prepCourseForDeletion(courseId);
              }}
              updateCourseCallback={updateCompleterCourse}
              getValuesCallback={getValues}
            />
            <PostSecondaryResourceForm
              postSecondaryResourceFields={postSecondaryResourceFields}
              errors={errors}
              appendCourseCallback={appendPostSecondaryResource}
              removeCourseCallback={(fieldIndex: number, courseId?: number) => {
                removePostSecondaryResource(fieldIndex);
                courseId && prepCourseForDeletion(courseId);
              }}
              updateCourseCallback={updatePostSecondaryResource}
              getValuesCallback={getValues}
            />
            <CertificateForm
              editMode={editMode}
              errors={errors}
              setValueCallback={setValue}
            />
            {(user.isDistrictAdmin || user.isHighSchoolAdmin) && (
              <div className="card-background form-container">
                <TextInput
                  name="note"
                  label="NOTES"
                  type="text"
                  size="small"
                  placeholder="Add notes here"
                  multiLine
                  rows={3}
                />
              </div>
            )}
          </div>

          {/* Save and Delete Buttons */}
          <div className="button-container flex_jbetween">
            {editMode && user.isAdmin && (
              <Button
                variant="outlined"
                onClick={() => setShowDeleteConfirmDialog(true)}
              >
                Delete CTE Pathway
              </Button>
            )}

            <div className={editMode && user.isAdmin ? 'right-side flex_jend' : 'right-side flex_jbetween'}>
              <Button
                variant="outlined"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>

              <SubmitButton
                text="Save"
                variant="contained"
                size="large"
                disableElevation
              />
            </div>
          </div>
        </FormProvider>
      ) : (
        <div className="flex_jcenter_acenter loading-indicator">
          <CircularProgress size="50px" />
        </div>
      )}

      <DeleteConfirmationModal
        open={showDeleteConfirmDialog}
        deleteItemText="pathway"
        onConfirm={onDeletePathway}
        onClose={() => setShowDeleteConfirmDialog(false)}
      />
    </form>
  );
};

export default CreateEditPathway;
