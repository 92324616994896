import React, { useEffect } from 'react';
import './SkillDetails.scss';
import { useDispatch } from 'react-redux';
import {
  useNavigate, useParams
} from 'react-router-dom';
import {
  AppRoutes, canUseAuthComponent
} from '../../../app/app.types';
import { useAppSelector } from '../../../app/hooks';
import { userSelectors } from '../../../features/user/user.slice';
import { skillSelectors } from '../../../features/skills/skills.slice';
import { getSkillById } from '../../../features/skills/skills.thunks';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box } from '@mui/system';
import { Button } from '@mui/material';


interface SkillDetailsProps { }

export const SkillDetails: React.FC<SkillDetailsProps> = () => {

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(userSelectors.selectUser);
  const skillDetails = useAppSelector(skillSelectors.selectSkillDetails);

  useEffect(() => {
    if (params.id) {
      dispatch(getSkillById(+params.id));
    }
  }, []);

  return (
    <div id="skill-details">
      <Box className="card-background">
        <div className="list-header flex_row_jbetween">
          <span className="back-button flex_acenter" onClick={() => navigate(AppRoutes.skills.path)}>
            <ChevronLeftIcon color="secondary" />
            <h3>Skill Details</h3>
          </span>

          {canUseAuthComponent(user.roles, AppRoutes.editSkill.authorizedRoles) && (
            <Button
              variant="outlined"
              onClick={() => navigate(AppRoutes.editSkill.path.replace(':id', params.id!.toString()))}
            >
              Edit Skill
            </Button>
          )}
        </div>

        <div className="card-content flex_row_jstart_acenter">
          <p>SKILL</p>

          <p className="skill-name"> {skillDetails.label} </p>
        </div>
      </Box>
    </div>
  );
};

export default SkillDetails;
