import React from 'react';
import ExpandableCard from '../ExpandableCard';
import { Career } from '../../../features/careers/careers.model';
import { ListMenuItem } from '../../forms/form.types';

import './CareersTable.scss';


interface CareersTableProps {
  careers?: Career[];
  defaultExpanded?: boolean;
}

const CareersTable: React.FC<CareersTableProps> = ({ careers, defaultExpanded }) => {
  const EMPTY = '-';

  if (!careers) {
    careers = [];
  }

  const getDataRow = (header: boolean, key?: number, title?: string, groups?: ListMenuItem[]) => (
    <div key={key} className={`careers-data-row flex ${header ? 'header' : ''}`}>
      <div className="careers-data-cell flex_column_astart">
        <div className="careers-data-label">Career Name</div>
        <div className="careers-data-content proper">{ title || EMPTY }</div>
      </div>
      <div className="careers-data-cell flex_column_astart">
        <div className="careers-data-label">Career Categories</div>
        <div className="careers-data-content proper">
          <ol>
            { groups?.map((g, i) => <li key={i}>{ g.label }</li>) || EMPTY }
          </ol>
        </div>
      </div>
    </div>
  );

  const getDetails = () => (
    <div className="careers-data-wrapper flex_column">
      { getDataRow(true) }
      { !!careers?.length && careers.map((c, i) => getDataRow(false, i, c.title, c.groups)) }
    </div>
  );

  return (
    <ExpandableCard
      className="user-careers-table"
      summary={<span>Careers</span>}
      details={getDetails()}
      expandable
      defaultExpanded={defaultExpanded}
    />
  );
};

export default CareersTable;
