/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  OutlinedInputProps, TextField, Tooltip
} from '@mui/material';
import React, {
  ReactNode,
  useEffect,
  useState
} from 'react';
import {
  Controller, DeepRequired, FieldError, FieldErrorsImpl, Merge, useFormContext
} from 'react-hook-form';
import { AppAssetPaths } from '../../app/app.types';

// For better bundle size import the specific icons you want.
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


export interface TextInputProps {
  name: `${string}` | `${string}.${string}` | `${string}.${number}`;
  label?: string | undefined;
  type?: string;
  containerClass?: string;
  inputClass?: string;
  placeholder?: string;
  errorMessage?: string | FieldError | Merge<FieldError, FieldErrorsImpl<DeepRequired<any>>>;
  size?: 'small' | 'medium';
  autoComplete?: any;
  maxLength?: number;
  defaultValue?: string | number;
  required?: boolean;
  disabled?: boolean;
  multiLine?: boolean;
  rows?: number;
  tooltip?: string;
  InputProps?: Partial<OutlinedInputProps>;
  onChange?: (e: any) => void;
  onKeyPress?: (e: any) => void;
}

const TextInput: React.FC<TextInputProps> = ({
  name,
  label,
  type,
  containerClass,
  inputClass,
  placeholder,
  errorMessage,
  size,
  autoComplete,
  maxLength,
  defaultValue,
  required,
  disabled,
  multiLine,
  rows,
  tooltip,
  InputProps,
  onChange,
  onKeyPress,
}) => {

  const { control } = useFormContext();

  const [
    isPasswordField,
    setIsPasswordField,
  ] = useState(false);

  const [
    showPassword,
    setShowPassword,
  ] = useState(true);

  useEffect(() => {
    if (type === 'password') {
      setIsPasswordField(true);
      setShowPassword(false);
    }
  }, []);

  const showPasswordEye = () => {
    if (isPasswordField && showPassword) {
      return <VisibilityIcon onClick={() => setShowPassword(!showPassword)} />;
    }
    else if (isPasswordField && !showPassword) {
      return <VisibilityOffIcon onClick={() => setShowPassword(!showPassword)} />;
    }
  };

  return (
    <div className={`field-container ${containerClass}`}>
      {label && (
        <div className="flex_acenter">
          <p className={`static-input-label ${required && 'required-field'} ${disabled && 'disabled-label'}`}>{label}</p>
          {tooltip && (
            <Tooltip title={tooltip} arrow>
              <img className="info-icon" src={AppAssetPaths.icons.INFO} alt="info" />
            </Tooltip>
          )}
        </div>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <TextField className={`form-input ${inputClass} ${disabled ? 'disabled-input' : ''}`}
            {...field}
            // label={label}
            variant="outlined"
            defaultValue={field.value || defaultValue}
            // value={field.value || defaultValue}
            error={!!errorMessage || false}
            // error={errorMessage ? !!errorMessage.length : false}
            helperText={errorMessage && errorMessage as ReactNode}
            type={showPassword ? (type !== 'password' ? type : 'text') : 'password'}
            placeholder={placeholder}
            size={size}
            autoComplete={autoComplete || 'on'}
            onChange={(e) => {
              field.onChange(e);
              onChange && onChange(e);
            }}
            onKeyPress={onKeyPress}
            // Uppercase I is for the MUI Text Field props
            InputProps={{
              ...InputProps,
              endAdornment: (InputProps?.endAdornment) || (showPasswordEye()),
              startAdornment: (InputProps?.startAdornment),
            }}
            // Lowercase i is for the generic text input props or something
            inputProps={{
              maxLength: maxLength,
              defaultValue: defaultValue,
            }}
            disabled={disabled}
            multiline={multiLine}
            rows={rows}
          />
        )}
      />
    </div>
  );
};

export default TextInput;
