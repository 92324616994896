/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export interface AssessmentsData {
  cci: Assessment,                 // career cluster inventory
  cciQuick: Assessment,            // career cluster inventory quick pic
  entrepreneurial: Assessment,
  interestProfiler: Assessment,
  learningStyles: Assessment,
  occupationSort: Assessment,
  realityCheck: Assessment,
  wes: Assessment,                 // workplace employability skills
  wesJr: Assessment,               // workplace employability skills jr.
  wil: Assessment,                 // work importance locator
}

export interface Assessment {
  name: string,
  results: DataRow[],
}

interface DataRow {
  key: string;
  value: string | number;
}

interface AssessmentResult {
  area: string,
  score: string,
}

interface Sortable {
  SortId: string;
}

export const EmptyAssessmentsData = {
  cci: {
    name: 'Career Cluster Inventory',
    results: [],
  },
  cciQuick: {
    name: 'Career Cluster Inventory Quick Pic',
    results: [],
  },
  entrepreneurial: {
    name: 'Entrepreneurial Assessment',
    results: [],
  },
  interestProfiler: {
    name: 'Interest Profiler',
    results: [],
  },
  learningStyles: {
    name: 'Learning Styles Survey',
    results: [],
  },
  occupationSort: {
    name: 'Occupation Sort',
    results: [],
  },
  realityCheck: {
    name: 'Reality Check',
    results: [],
  },
  wes: {
    name: 'Workplace Employability Skills',
    results: [],
  },
  wesJr: {
    name: 'Workplace Employability Skills Jr.',
    results: [],
  },
  wil: {
    name: 'Work Importance Locator',
    results: [],
  },
};

// Since the API doesn't deliver a human-readable key value
const getEntrepreneurialThreshold = (score: number): string => {
  if(score >= 80){
    return 'Outstanding ability to be an entrepreneur';
  }
  else if (score >= 60){
    return 'Satisfactory ability to be an entrepreneur';
  }
  else if (score >= 40){
    return 'Self-employment may not be right';
  }
  else{
    return 'Avoid self-employment';
  }
};

// Since the API doens't deliver monthly expenses; only annual
const getMonthlyExp = (annual: string): string => {
  const formatter = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const annualExpenses = parseInt(annual.replace('$', '').replace(',', ''));

  return formatter.format(Math.floor(annualExpenses / 12)).replace('.00', '');
};

export const ConstructAssessmentsData = (data: any): AssessmentsData => {
  // CCI
  const cciEntry = data.others?.filter((a: Sortable) => a.SortId === 'CCIJr')[0];
  const cci = {
    name: 'Career Cluster Inventory',
    results: cciEntry?.results.map((r: AssessmentResult) => ({
      key: r.area,
      value: r.score,
    })) as DataRow[] || [],
  };

  // CCIQuick
  const cciQuickEntry = data.others?.filter((a: Sortable) => a.SortId === 'CCIQuick')[0];
  const cciQuick = {
    name: 'Career Cluster Inventory Quick Pic',
    results: cciQuickEntry?.results.map((r: AssessmentResult) => ({
      key: r.area,
      value: r.score,
    })) as DataRow[] || [],
  };

  // Entrepreneurial
  const eEntry = data.entrepreurialAssessment;
  const entrepreneurial = {
    name: 'Entrepreneurial Assessment',
    results: eEntry && [
      {
        key: getEntrepreneurialThreshold(eEntry.score),
        value: eEntry.score,
      },
    ] || [],
  };

  // Interest Profiler
  const ipEntry = data.others?.filter((a: Sortable) => a.SortId === 'ShortIP')[0];
  const interestProfiler = {
    name: 'Interest Profiler',
    results: ipEntry?.results.map((r: AssessmentResult) => ({
      key: r.area,
      value: r.score,
    })) as DataRow[] || [],
  };

  // Learning Styles
  const lsEntry = data.learnStyleSurvey;
  const learningStyles = {
    name: 'Learning Styles Survey',
    results: lsEntry?.results.map((r: AssessmentResult) => ({
      key: r.area,
      value: r.score,
    })) as DataRow[] || [],
  };

  // Occupation Sort
  const osEntry = data.occupationSort;
  const occupationSort = {
    name: 'Occupation Sort',
    results: osEntry && [
      {
        key: 'Career Matches',
        value: osEntry.score,
      },
    ] || [],
  };

  // Reality Check
  const rcEntry = data.realityCheck;
  const realityCheck = {
    name: 'Reality Check',
    results: rcEntry && [
      {
        key: 'Monthly Expenses',
        value: getMonthlyExp(rcEntry.annualExpense),
      },
      {
        key: 'Annual Expense',
        value: rcEntry.annualExpense,
      },
      {
        key: 'Annual Salary Needed',
        value: rcEntry.annualNeeded,
      },
    ] || [],
  };

  // WES
  const wesEntry = data.others?.filter((a: Sortable) => a.SortId === 'WES')[0];
  const wes = {
    name: 'Workplace Employability Skills',
    results: wesEntry?.results.map((r: AssessmentResult) => ({
      key: r.area,
      value: r.score,
    })) as DataRow[] || [],
  };

  // WES Jr.
  const wesJrEntry = data.others?.filter((a: Sortable) => a.SortId === 'WESJr')[0];
  const wesJr = {
    name: 'Workplace Employability Skills Jr.',
    results: wesJrEntry?.results.map((r: AssessmentResult) => ({
      key: r.area,
      value: r.score,
    })) as DataRow[] || [],
  };

  // WIL
  const wilEntry = data.others?.filter((a: Sortable) => a.SortId === 'WIL')[0];
  const wil = {
    name: 'Work Importance Locator',
    results: wilEntry?.results.map((r: AssessmentResult) => ({
      key: r.area,
      value: r.score,
    })) as DataRow[] || [],
  };

  return {
    cci,
    cciQuick,
    entrepreneurial,
    interestProfiler,
    learningStyles,
    occupationSort,
    realityCheck,
    wes,
    wesJr,
    wil,
  };
};
