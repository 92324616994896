import React, {
  useState,ReactElement
} from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import './ExpandableCard.scss';


interface ExpandableCardProps {
  className?: string,
  summary: ReactElement,
  summaryTag?: ReactElement | boolean,
  summaryDescription?: string,
  details: ReactElement,
  expandable?: boolean,
  defaultExpanded?: boolean,
}

const ExpandableCard: React.FC<ExpandableCardProps> = ({
  className,
  summary,
  summaryTag,
  summaryDescription,
  details,
  expandable,
  defaultExpanded,
}) => {

  const [
    expanded,
    setExpanded,
  ] = useState<boolean>(defaultExpanded || false);

  return (
    <Accordion
      id="details-accordion"
      className={className}
      expanded={expandable ? expanded : true}
      onChange={() => setExpanded(!expanded)}
      TransitionProps={{
        unmountOnExit: true,
      }}
    >
      <AccordionSummary
        className={`accordion-summary ${!expandable && 'not-expandable'}`}
        expandIcon={expandable ? <ExpandMoreIcon /> : <></>}
      >
        <div className="summary-content flex_acenter">
          {summary}
          {(summaryDescription && expanded) && (
            <div className="summary-description flex_acenter">
              <div className="vertical-line" />
              <p>{summaryDescription}</p>
            </div>
          )}
          {summaryTag && summaryTag}
        </div>
      </AccordionSummary>
      <AccordionDetails className="accordion-details">
        { details }
      </AccordionDetails>
    </Accordion>
  );
};

export default ExpandableCard;
