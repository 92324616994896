import { PopoverOrigin } from '@mui/material';

// Generic types
export const defaultMenuPaperProps = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(2px 3px 5px rgba(174, 174, 174, 0.25))',
    borderRadius: '10px',
  },
};

export const topCenterTransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

export const bottomCenterAnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

export const bottomRightAnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};


// Nav menu types
export const navMenuPaperProps = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(2px 3px 5px rgba(174, 174, 174, 0.25))',
    padding: '2px 15px 2px 10px',
    background: '#444462',
  },
};

export const navMenuTransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

export const navMenuAnchorOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};
