import React, {
  useEffect, useState
} from 'react';
import {
  useParams, useNavigate, useLocation
} from 'react-router-dom';
import {
  DataGrid,
  GridSortModel
} from '@mui/x-data-grid';
import { Pagination } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import {
  AppAssetPaths, AppRoutes
} from '../../../app/app.types';
import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import { masterKeySelectors } from '../../../features/masterKey/masterKey.slice';
import {
  getMasterKeySubmissionList, getMasterKeyById
} from '../../../features/masterKey/masterKey.thunks';
import { MasterKeySubmissionListQueryParams } from '../../../features/masterKey/masterKey.model';
import { exportMasterKeySubmissionsCSV } from '../../../features/exports/exports.api';

import { masterKeySubmissionListColumns } from '../../../constants/tables/alertTable.types';
import { DownloadButton } from '../../../components/CustomStyledComponents';

import './MasterKeySubmissionList.scss';
import { TableState } from '../../../constants/tables/table.types';

interface MasterKeySubmissionListProps { }

const MasterKeySubmissionList: React.FC<MasterKeySubmissionListProps> = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const submissionList = useAppSelector(masterKeySelectors.selectMasterKeySubmissionList);
  const submissionListCount = useAppSelector(masterKeySelectors.selectTotalMasterKeySubmissionListCount);

  const [
    tableState,
    setTableState,
  ] = useState<TableState>({
    page: 1,
    pageSize: 100,
    columns: masterKeySubmissionListColumns,
    rows: [],
    loading: false,
  });
  const [
    listQuery,
    setListQuery,
  ] = useState<MasterKeySubmissionListQueryParams>({
    offset: 0,
    limit: undefined,
    masterKeyId: 0,
    sortModel: undefined,
  });

  useEffect(() => {
    setTableState((prev) => ({
      ...prev,
      page: 1,
      rows: [],
      loading: true,
    }));

    if (!listQuery.masterKeyId) {
      return;
    }
    dispatch(getMasterKeySubmissionList({
      ...listQuery,
      offset: 0,
    }));
  }, [
    listQuery,
  ]);

  useEffect(() => {
    if (tableState.page < submissionList.length / tableState.pageSize || !submissionList.length || submissionList.length >= submissionListCount) return;

    setTableState((prev) => ({
      ...prev,
      loading: true,
    }));

    if (!listQuery.masterKeyId) {
      return;
    }
    dispatch(getMasterKeySubmissionList({
      ...listQuery,
      offset: tableState.pageSize * (tableState.page - 1),
    }));
  },
  [
    tableState.page,
    tableState.pageSize,
  ]);

  useEffect(() => {
    if (!params || !params.masterKeyId) return;
    const id = parseInt(params.masterKeyId);

    dispatch(getMasterKeyById(id));

    setListQuery({
      ...listQuery,
      masterKeyId: id,
    });
  }, []);


  useEffect(() => {
    setTableState((prev) => ({
      ...prev,
      loading: false,
      rows: submissionList,
    }));
  }, [
    submissionList,
  ]);

  return (
    <div className="card-background master-key-submission-list">
      <div className="details-header flex_row_jbetween_acenter">
        <div className="flex_row_acenter">
          <div className="back_arrow" onClick={() => navigate(AppRoutes.masterKey.path)}>
            <ChevronLeftIcon className="carousel-icon" />
          </div>
          <h3>Master Key Submissions</h3>
        </div>

        {(params && params.masterKeyId) && submissionListCount !== 0 && (
          <DownloadButton
            variant="contained"
            startIcon={<img src={AppAssetPaths.icons.DOWNLOAD} />}
            disableElevation
            onClick={() => params.masterKeyId && exportMasterKeySubmissionsCSV(+params.masterKeyId, state?.title)}
          >
            Download
          </DownloadButton>
        )}
      </div>

      <div className="master-key-info">
        <h3>{state && state?.title || ''}</h3>

        <div>{submissionListCount} submissions</div>
      </div>

      <DataGrid
        className="table"
        {...tableState}
        components={{
          Pagination: Pagination,
        }}
        componentsProps={{
          pagination: {
            page: tableState.page,
            count: Math.ceil(submissionListCount / tableState.pageSize),
            onChange: (_: void, page: number) => {
              setTableState((prev) => ({
                ...prev,
                page,
              }));
            },
          },
        }}
        onRowClick={(event) => navigate(AppRoutes.masterKeySubmissionDetails.path.replace(':masterKeySubmissionId', event.id.toString()))}
        pagination
        paginationMode="server"
        sortingMode="server"
        sortModel={listQuery.sortModel}
        onSortModelChange={(newSortModel: GridSortModel) => {
          setListQuery((prev) => ({
            ...prev,
            sortModel: newSortModel,
          }));
        }}
        disableColumnFilter
        disableColumnMenu
        hideFooterSelectedRowCount
      />
    </div>
  );
};

export default MasterKeySubmissionList;
