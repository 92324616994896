import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppRoutes, canUseAuthComponent
} from '../../../app/app.types';
import { useAppSelector } from '../../../app/hooks';
import { Pathway } from '../../../features/pathways/pathways.model';
import { userSelectors } from '../../../features/user/user.slice';
import ExpandableCard from '../ExpandableCard';

import './PathwayOverview.scss';


interface PathwayOverviewProps {
  pathway: Pathway;
  schoolId?: number;
}

const PathwayOverview: React.FC<PathwayOverviewProps> = ({ pathway, schoolId }) => {

  const navigate = useNavigate();

  const user = useAppSelector(userSelectors.selectUser);

  const getSummary = () => (
    <div className="overview-summary flex_row_jbetween">
      <span>CTE Pathway Details</span>
      {canUseAuthComponent(user.roles, AppRoutes.editPathway.authorizedRoles) && (
        <Button className="overview-summary-edit-btn"
          variant="outlined"
          onClick={() => navigate(AppRoutes.editPathway.path.replace(':id', pathway.id.toString()), {
            state: {
              schoolId: schoolId,
            },
          })}
        >
          Edit
        </Button>
      )}
    </div>
  );

  const getDetails = () => (
    <div>
      <div className="overview-header-wrapper flex">
        <h2 className="user-name">{pathway.name || '-'}</h2>
      </div>

      <div className="overview-details flex_row">
        <div className="overview-content-wrapper flex_column">
          <div className="overview-content-row flex">
            <div className="overview-label">CTE Pathway Type</div>
            <div className="overview-data">{pathway.pathwayType?.label || '-'}</div>
          </div>

          <div className="overview-content-row flex">
            <div className="overview-label">Career Cluster</div>
            <div className="overview-data overview-str">{pathway.pathwayCluster?.label || '-'}</div>
          </div>
        </div>

        <div className="overview-content-wrapper flex_column">
          <div className="overview-content-row flex">
            <div className="overview-label">Total Credits To Complete Pathway</div>
            <div className="overview-data overview-str">{pathway.creditsToComplete || '-'}</div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <ExpandableCard
      className="pathway-overview"
      summary={getSummary()}
      details={getDetails()}
    />
  );
};

export default PathwayOverview;
