import React, {
  useEffect, useState
} from 'react';
import {
  useAppDispatch, useAppSelector
} from '../../app/hooks';
import {
  Box, Tab, Tabs
} from '@mui/material';
import {
  ResourceApplicantsTab, ResourceApplicantsTabs
} from '../../features/dashboard/types/resourceApplicants.types';
import {
  AppRoutes, canUseAuthComponent
} from '../../app/app.types';
import { userSelectors } from '../../features/user/user.slice';
import { resourceSelectors } from '../../features/resources/resources.slice';
import { useNavigate } from 'react-router-dom';
import { getResourceList } from '../../features/resources/resources.thunks';
import {
  getResourceQueryType, getResourceType, Resource, ResourceType
} from '../../features/resources/resources.model';
import { getAllSchools } from '../../features/organization/organization.thunks';

import './ResourceApplicantsCard.scss';


interface ResourceApplicantsCardProps { }

const ResourceApplicantsCard: React.FC<ResourceApplicantsCardProps> = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(userSelectors.selectUser);
  const resources = useAppSelector(resourceSelectors.selectResourceList);

  const [
    selectedTab,
    setSelectedTab,
  ] = useState(0);
  const [
    resourcesWithApplicants,
    setResourcesWithApplicants,
  ] = useState<Resource[]>([]);

  useEffect(() => {
    dispatch(getAllSchools());
    dispatch(getResourceList({
      offset: 0,
      resourceType: getResourceType(ResourceApplicantsTabs[selectedTab].resourceType),
      queryType: getResourceQueryType(ResourceApplicantsTabs[selectedTab].resourceType),
    }));
  }, [
    selectedTab,
  ]);

  useEffect(() => {
    const filteredResources = resources.filter((r: Resource) => +r.applicants);


    setResourcesWithApplicants(filteredResources);
  }, [
    resources,
  ]);

  const navigateToDetailsPage = (resource: Resource) => {
    if (resource.type === ResourceType.scholarship) {
      navigate(AppRoutes.scholarshipDetails.path.replace(':scholarshipId', resource.id.toString()));
    }
    else if (resource.type.toLowerCase() === ResourceType.internship) {
      navigate(AppRoutes.internshipDetails.path.replace(':resourceId', resource.id.toString()), {
        state: {
          type: ResourceType.internship,
        },
      });
    }
    else {
      navigate(AppRoutes.opportunityDetails.path.replace(':resourceId', resource.id.toString()), {
        state: {
          type: ResourceType.opportunity,
        },
      });
    }
  };

  return (
    <div id="resource-applicants-card" className="flex_col_jbetween card-background">
      <div className="title-bar">
        <h3 className="card-header">Resource Applicants</h3>
      </div>
      <Tabs value={selectedTab}
        onChange={(_, newValue) => setSelectedTab(newValue)}
        variant="fullWidth"
      >
        {ResourceApplicantsTabs.filter(t => canUseAuthComponent(user.roles, t.authorizedRoles)).map((tab: ResourceApplicantsTab, i: number) => (
          <Tab id="dashboard-tab"
            label={tab.title}
            wrapped={false}
            key={i}
          />
        ))}
      </Tabs>

      {ResourceApplicantsTabs.filter(t => canUseAuthComponent(user.roles, t.authorizedRoles)).map((tab: ResourceApplicantsTab, i: number) => (
        <div role="tabpanel" hidden={selectedTab !== i} key={i}>
          <Box className="card-content flex_column">
            <div className="column-headers flex_jbetween">
              <p className="title">Title</p>
              <p className="applicants">Applicants</p>
            </div>
            {resourcesWithApplicants.length ? (
              <div className="scroll-container">
                {resourcesWithApplicants.map((r: Resource, j: number) => (
                  <div key={j}
                    className={'column-data flex_jbetween_acenter'}
                    onClick={() => navigateToDetailsPage(r)}
                  >
                    <p className="title">{r.title}</p>
                    <p className="applicants">{r.applicants}</p>
                  </div>
                ))}
              </div>
            ) : (
              <div className="empty-list flex_jcenter_acenter">
                <p>No {tab.title.toLowerCase()} with applicants</p>
              </div>
            )}
          </Box>
        </div>
      ))}
    </div>
  );
};

export default ResourceApplicantsCard;
