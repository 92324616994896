/* eslint-disable react/display-name */
import React from 'react';
import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
  GridValueFormatterParams
} from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { AppAssetPaths } from '../../app/app.types';
import { UserApprovalStatus } from '../../features/user/user.model';
import { addUrlProtocol } from '../../util/formatting';
import { setAlert } from '../../features/alert/alert.slice';
import {
  approveScholarshipApplication, rejectScholarshipApplication
} from '../../features/scholarship/scholarship.thunks';
import {
  approveStudent,
  rejectStudent,
  approveTransfer,
  rejectTransfer
} from '../../features/user/user.thunks';
import { store } from '../../app/store';


export enum ApprovalTypeTabs {
  StudentApprovals = 'students',
  TransferApprovals = 'transfers',
  ResourceApprovals = 'resources',
  NotificationApprovals = 'notifications',
  ApplicationApprovals = 'applications',
  SkillTrainingApprovals = 'skillTrainings'
}

export const studentApprovalsListColumns: GridColumns = [
  {
    headerName: 'Name',
    field: 'name',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    cellClassName: 'main-column',
  },
  {
    headerName: 'School',
    field: 'school',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Email',
    field: 'email',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Date Created',
    field: 'createdAt',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => dayjs(params.value as string).format('DD MMM YYYY'),
  },
  {
    headerName: 'Status',
    field: 'status',
    flex: .8,
    renderCell: (params: GridRenderCellParams<boolean | undefined>) => (
      <div className="flex_acenter">
        {(params.row.status === undefined) ? (
          <img src={AppAssetPaths.icons.statusIcons.PENDING} alt={UserApprovalStatus.pending} />
        ) : (params.row.status === false) ? (
          <img src={AppAssetPaths.icons.statusIcons.REJECTED} alt={UserApprovalStatus.rejected} />
        ) : (
          <img src={AppAssetPaths.icons.statusIcons.APPROVED} alt={UserApprovalStatus.approved} />
        )}
      </div>
    ),
  },
  {
    headerName: '',
    field: 'approve_reject',
    flex: .8,
    type: 'actions',
    getActions: (params: GridRowParams) => [
      <GridActionsCellItem
        key="approve"
        label="approve"
        icon={
          <img src={AppAssetPaths.icons.statusIcons.APPROVE} alt="approve" />
        }
        onClick={() => onApproveStudent(params.row.id)}
        disableRipple
      />,
      <GridActionsCellItem
        key="reject"
        label="reject"
        icon={
          <img src={AppAssetPaths.icons.statusIcons.REJECT} alt="reject" />
        }
        onClick={() => onRejectStudent(params.row.id)}
        disableRipple
      />,
    ],
  },
];

const onApproveStudent = (id: number) => {
  store.dispatch(approveStudent([
    id,
  ]))
    .unwrap()
    .then(() => {
      store.dispatch(setAlert({
        type: 'success',
        message: 'Successfully approved the student.',
      }));
    })
    .catch(() => {
      store.dispatch(setAlert({
        type: 'error',
        message: 'Unable to approve the student.',
      }));
    });
};

const onRejectStudent = (id: number) => {
  store.dispatch(rejectStudent([
    id,
  ]))
    .unwrap()
    .then(() => {
      store.dispatch(setAlert({
        type: 'success',
        message: 'Successfully rejected the student.',
      }));
    })
    .catch(() => {
      store.dispatch(setAlert({
        type: 'error',
        message: 'Unable to reject the student.',
      }));
    });
};

export const transferApprovalsListColumns: GridColumns = [
  {
    headerName: 'Name',
    field: 'name',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    cellClassName: 'main-column',
  },
  {
    headerName: 'Email',
    field: 'email',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Current School',
    field: 'currentSchool',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'New School',
    field: 'newSchool',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Date Created',
    field: 'createdAt',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => dayjs(params.value as string).format('DD MMM YYYY'),
  },
  {
    headerName: 'Status',
    field: 'status',
    flex: .8,
    renderCell: (params: GridRenderCellParams<boolean | undefined>) => (
      <div className="flex_acenter">
        {(params.row.status === undefined) ? (
          <img src={AppAssetPaths.icons.statusIcons.PENDING} alt={UserApprovalStatus.pending} />
        ) : (params.row.status === false) ? (
          <img src={AppAssetPaths.icons.statusIcons.REJECTED} alt={UserApprovalStatus.rejected} />
        ) : (
          <img src={AppAssetPaths.icons.statusIcons.APPROVED} alt={UserApprovalStatus.approved} />
        )}
      </div>
    ),
  },
  {
    headerName: '',
    field: 'approve_reject',
    flex: .8,
    type: 'actions',
    filterable: false,
    getActions: (params: GridRowParams) => [
      <GridActionsCellItem
        key="approve"
        label="approve"
        icon={
          <img src={AppAssetPaths.icons.statusIcons.APPROVE} alt="approve" />
        }
        onClick={() => onApproveTransfer(params.row.id)}
        disableRipple
      />,
      <GridActionsCellItem
        key="reject"
        label="reject"
        icon={
          <img src={AppAssetPaths.icons.statusIcons.REJECT} alt="reject" />
        }
        onClick={() => onRejectTransfer(params.row.id)}
        disableRipple
      />,
    ],
  },
];

const onApproveTransfer = (id: number) => {
  store.dispatch(approveTransfer([
    id,
  ]))
    .unwrap()
    .then(() => {
      store.dispatch(setAlert({
        type: 'success',
        message: 'Successfully approved the transfer.',
      }));
    })
    .catch(() => {
      store.dispatch(setAlert({
        type: 'error',
        message: 'Unable to approve the transfer.',
      }));
    });
};

const onRejectTransfer = (id: number) => {
  store.dispatch(rejectTransfer([
    id,
  ]))
    .unwrap()
    .then(() => {
      store.dispatch(setAlert({
        type: 'success',
        message: 'Successfully rejected the transfer.',
      }));
    })
    .catch(() => {
      store.dispatch(setAlert({
        type: 'error',
        message: 'Unable to reject the transfer.',
      }));
    });
};

export const notificationApprovalsListColumns: GridColumns = [
  {
    headerName: 'Title',
    field: 'title',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    cellClassName: 'main-column',
  },
  {
    headerName: 'Creator',
    field: 'creator',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'School',
    field: 'school',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Recipient Category',
    field: 'recipientCategory',
    flex: 1,
    // Unsortable at the moment as the values are all not gathered from this approval API
    sortable: false,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Date Created',
    field: 'createdAt',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => dayjs(params.value as string).format('DD MMM YYYY'),
  },
  {
    headerName: 'Status',
    field: 'status',
    flex: .8,
    renderCell: (params: GridRenderCellParams<boolean | undefined>) => (
      <div className="flex_acenter">
        {(params.row.status === undefined) ? (
          <img src={AppAssetPaths.icons.statusIcons.PENDING} alt={UserApprovalStatus.pending} />
        ) : (params.row.status === false) ? (
          <img src={AppAssetPaths.icons.statusIcons.REJECTED} alt={UserApprovalStatus.rejected} />
        ) : (
          <img src={AppAssetPaths.icons.statusIcons.APPROVED} alt={UserApprovalStatus.approved} />
        )}
      </div>
    ),
  },
];

export const skillTrainingsApprovalsListColumns: GridColumns = [
  {
    headerName: 'Name',
    field: 'name',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    cellClassName: 'main-column',
  },
  {
    headerName: 'Institution',
    field: 'institution',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Link',
    field: 'link',
    flex: 1,
    headerAlign: 'left',
    align: 'left',
    sortable: true,
    type: 'actions',
    getActions: (params: GridRowParams) => [
      <GridActionsCellItem
        key="link"
        label="link"
        disableRipple
        icon={
          <div>
            {params.row.link ? (
              <a className="link-action-column"
                href={addUrlProtocol(`${params.row.link}`)}
                target="_blank"
                rel="noreferrer noopener">
                {params.row.link || '-'}
              </a>
            ) : '-'
            }
          </div>
        }
      />,
    ],
  },
  {
    headerName: 'Date Created',
    field: 'createdAt',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => dayjs(params.value as string).format('DD MMM YYYY'),
  },
  {
    headerName: 'Status',
    field: 'status',
    flex: .8,
    renderCell: (params: GridRenderCellParams<boolean | undefined>) => (
      <div className="flex_acenter">
        {(params.row.status === undefined) ? (
          <img src={AppAssetPaths.icons.statusIcons.PENDING} alt={UserApprovalStatus.pending} />
        ) : (params.row.status === false) ? (
          <img src={AppAssetPaths.icons.statusIcons.REJECTED} alt={UserApprovalStatus.rejected} />
        ) : (
          <img src={AppAssetPaths.icons.statusIcons.APPROVED} alt={UserApprovalStatus.approved} />
        )}
      </div>
    ),
  },
];

export const resourceApprovalsListColumns: GridColumns = [
  {
    headerName: 'Content Type',
    field: 'contentType',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Title',
    field: 'title',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    cellClassName: 'main-column',
  },
  {
    headerName: 'Creator',
    field: 'creator',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Date Created',
    field: 'createDate',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => dayjs(params.value as string).format('DD MMM YYYY'),
  },
  {
    headerName: 'Status',
    field: 'status',
    flex: .8,
    renderCell: (params: GridRenderCellParams<boolean | undefined>) => (
      <div className="flex_acenter">
        {(params.row.status === undefined) ? (
          <img src={AppAssetPaths.icons.statusIcons.PENDING} alt={UserApprovalStatus.pending} />
        ) : (params.row.status === false) ? (
          <img src={AppAssetPaths.icons.statusIcons.REJECTED} alt={UserApprovalStatus.rejected} />
        ) : (
          <img src={AppAssetPaths.icons.statusIcons.APPROVED} alt={UserApprovalStatus.approved} />
        )}
      </div>
    ),
  },
];

export const applicationApprovalsListColumns: GridColumns = [
  {
    headerName: 'Name',
    field: 'name',
    flex: .8,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    cellClassName: 'main-column',
  },
  {
    headerName: 'Student School',
    field: 'school',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Scholarship',
    field: 'scholarship',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Graduation Year',
    field: 'graduationYear',
    flex: .8,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Date Applied',
    field: 'createdAt',
    flex: .8,
    valueFormatter: (params: GridValueFormatterParams) => dayjs(params.value as string).format('DD MMM YYYY'),
  },
  {
    headerName: 'Status',
    field: 'status',
    flex: .8,
    renderCell: (params: GridRenderCellParams<boolean | undefined>) => (
      <div className="flex_acenter">
        {(params.row.status === undefined) ? (
          <img src={AppAssetPaths.icons.statusIcons.PENDING} alt={UserApprovalStatus.pending} />
        ) : (params.row.status === false) ? (
          <img src={AppAssetPaths.icons.statusIcons.REJECTED} alt={UserApprovalStatus.rejected} />
        ) : (
          <img src={AppAssetPaths.icons.statusIcons.APPROVED} alt={UserApprovalStatus.approved} />
        )}
      </div>
    ),
  },
  {
    headerName: '',
    field: 'approve_reject',
    flex: .8,
    type: 'actions',
    getActions: (params: GridRowParams) => [
      <GridActionsCellItem
        key="approve"
        label="approve"
        icon={
          <img src={AppAssetPaths.icons.statusIcons.APPROVE} alt="approve" />
        }
        onClick={() => onApproveApplication(params.row.id, params.row.userId)}
        disableRipple
      />,
      <GridActionsCellItem
        key="reject"
        label="reject"
        icon={
          <img src={AppAssetPaths.icons.statusIcons.REJECT} alt="reject" />
        }
        onClick={() => onRejectApplication(params.row.id, params.row.userId)}
        disableRipple
      />,
    ],
  },
];

const onApproveApplication = (id: number, userId: string) => {
  store.dispatch(approveScholarshipApplication({
    ids: [
      id,
    ],
    userId,
  }))
    .unwrap()
    .then(() => {
      store.dispatch(setAlert({
        type: 'success',
        message: 'Successfully approved the application.',
      }));
    })
    .catch(() => {
      store.dispatch(setAlert({
        type: 'error',
        message: 'Unable to approve the application.',
      }));
    });
};

const onRejectApplication = (id: number, userId: string) => {
  store.dispatch(rejectScholarshipApplication({
    ids: [
      id,
    ],
    userId,
  }))
    .unwrap()
    .then(() => {
      store.dispatch(setAlert({
        type: 'success',
        message: 'Successfully rejected the application.',
      }));
    })
    .catch(() => {
      store.dispatch(setAlert({
        type: 'error',
        message: 'Unable to reject the application.',
      }));
    });
};

export const bulkApproveRejectListColumns: GridColumns = [
  {
    headerName: 'Name',
    field: 'name',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    cellClassName: 'main-column',
    sortable: false,
  },
  {
    headerName: 'School',
    field: 'school',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    cellClassName: 'org-column',
    sortable: false,
  },
  {
    headerName: 'Email',
    field: 'email',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    sortable: false,
  },
];
