import React from 'react';
import './ResourceTagsModal.scss';
import {
  Box, Modal
} from '@mui/material';
import ResourceTagsList from '../../pages/resource/resourceTags/ResourceTagsList';

interface ReactTagsModalProps {
  open: boolean;
  onClose: () => void;
}

const ResourceTagsModal: React.FC<ReactTagsModalProps> = ({ open, onClose }) => {

  return (
    <Modal
      id="resource-tags-modal"
      open={open}
      onClose={onClose}
    >
      <Box className="modal-content card-background flex_col_jcenter">
        <ResourceTagsList
          inModal={true}
          modalClose={onClose}
        />
      </Box>
    </Modal>
  );
};


export default ResourceTagsModal;