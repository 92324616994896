import {
  configureStore, ThunkAction, Action
} from '@reduxjs/toolkit';
import configReducer from '../features/config/config.slice';

export const configStore = configureStore({
  reducer: {
    config: configReducer,
  },
});

export type ConfigDispatch = typeof configStore.dispatch;
export type ConfigRootState = ReturnType<typeof configStore.getState>;
export type ConfigThunk<ReturnType = void> = ThunkAction<
ReturnType,
ConfigRootState,
unknown,
Action<string>
>;
