/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useEffect, useState
} from 'react';
import './CreateSkillTraining.scss';
import * as yup from 'yup';
import {
  FormProvider, SubmitHandler, useForm
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TextInput from '../../../components/forms/TextInput';
import SubmitButton from '../../../components/forms/SubmitButton';
import {
  Box, Button, Checkbox, CircularProgress, FormControlLabel
} from '@mui/material';
import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import {
  useNavigate, useParams
} from 'react-router-dom';
import { skillTrainingSelectors } from '../../../features/skillTrainings/skillTrainings.slice';
import {
  createSkillTraining,
  deleteSkillTraining,
  GetSkillTrainingById,
  updateSkillTraining
} from '../../../features/skillTrainings/skillTrainings.thunks';
import DropdownSelect from '../../../components/forms/DropdownSelect';
import {
  courseDurationDropdownList, courseFormatDropdownList
} from '../../../util/dropdown';
import { skillSelectors } from '../../../features/skills/skills.slice';
import { getSkillsList } from '../../../features/skills/skills.thunks';
import { SkillListItem } from '../../../features/skills/skills.model';
import { AppRoutes } from '../../../app/app.types';
import { startCase } from 'lodash';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import dayjs from 'dayjs';
import UploadButton from '../../../components/forms/UploadButton';
import {
  CourseFormats,
  SkillTraining,
  SkillTrainingCourse,
  SkilltrainingCourseFormat
} from '../../../features/skillTrainings/skillTrainings.model';
import { setAlert } from '../../../features/alert/alert.slice';
import DeleteConfirmationModal from '../../../components/modal/DeleteConfirmationModal';
import { adminGetUserById } from '../../../features/user/user.api';
import { userSelectors } from '../../../features/user/user.slice';
import { User } from '../../../features/user/user.model';
import { zipcodeSelectors } from '../../../features/zipcode/zipcode.slice';
import { getZipcodeList } from '../../../features/zipcode/zipcode.thunks';
import { duplicateSkillTrainingCheck } from '../../../features/skillTrainings/skillTrainings.api';
import DuplicateResourceModal from '../../../components/modal/DuplicateResourceModal';
import { getEndOfNextSchoolYear } from '../../../util/dates';


interface CostCheckboxesType {
  free: boolean,
  discounted: boolean,
  fullPrice: boolean,
}

interface DurationCheckboxesType {
  ongoing: boolean,
  startEndDate: boolean,
}

interface CreateSkillTrainingProps { }

const CreateSkillTraining: React.FC<CreateSkillTrainingProps> = () => {

  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(userSelectors.selectUser);
  const zipcodes = useAppSelector(zipcodeSelectors.selectZipcodeList);
  const skillTrainingDetails = useAppSelector(skillTrainingSelectors.selectSkillTrainingDetails);
  const skillList = useAppSelector(skillSelectors.selectSkillsList);

  const [
    editMode,
    setEditMode,
  ] = useState<boolean>(false);
  const [
    learnAndWorkCheckbox,
    setLearnAndWorkCheckbox,
  ] = useState<boolean>(false);
  const [
    activeCheckbox,
    setActiveCheckbox,
  ] = useState<boolean>(false);
  const [
    costCheckboxes,
    setCostCheckboxes,
  ] = useState<CostCheckboxesType>({
    free: true,
    discounted: false,
    fullPrice: false,
  });
  const [
    durationCheckboxes,
    setDurationCheckboxes,
  ] = useState<DurationCheckboxesType>({
    ongoing: true,
    startEndDate: false,
  });
  const [
    pageLoaded,
    setPageLoaded,
  ] = useState<boolean>(false);
  const [
    disableZip,
    setDisableZip,
  ] = useState(false);
  const [
    showDeleteConfirmDialog,
    setShowDeleteConfirmDialog,
  ] = useState(false);

  const [
    duplicateSkillTrainings,
    setDuplicateSkillTrainings,
  ] = useState<SkillTraining[]>([]);
  const [
    showDuplicateSkillTrainingModal,
    setShowDuplicateSkillTrainingModal,
  ] = useState(false);


  useEffect(() => {
    dispatch(getSkillsList({
      offset: 0,
    }));
    dispatch(getZipcodeList());

    if (params && params.id) {
      dispatch(GetSkillTrainingById(+params.id));
      setEditMode(true);
    }
    else {
      setPageLoaded(true);
    }
  }, []);

  useEffect(() => {
    if ((params && params.id && +params.id !== skillTrainingDetails.id) || pageLoaded) return;

    if (editMode && !!skillList.length && !!zipcodes.length) {
      prePopulateValues();
      setPageLoaded(true);
    }
  }, [
    editMode,
    skillTrainingDetails,
    skillList,
    zipcodes,
  ]);

  const prePopulateValues = () => {
    setValue('name', editMode ? skillTrainingDetails.name : '');
    setValue('url', editMode ? skillTrainingDetails.url : '');
    setValue('company', editMode ? skillTrainingDetails.company : '');
    setValue('courseFormat', editMode ? courseFormatDropdownList.filter(x => skillTrainingDetails.courseFormat[x.value as keyof SkilltrainingCourseFormat]) : null);
    setValue('description', editMode ? skillTrainingDetails.description : '');
    setValue('skills', editMode ? skillTrainingDetails.skills : null);
    setValue('zipcode', editMode && skillTrainingDetails.zipcode ? zipcodes.find(z => z.zipcode === skillTrainingDetails.zipcode) : null);
    setValue('isFree', editMode ? skillTrainingDetails.isFree : costCheckboxes.free);
    setValue('isDiscounted', editMode ? skillTrainingDetails.isDiscounted : costCheckboxes.discounted);
    setValue('isFullPrice', editMode ? (!skillTrainingDetails.isFree && !skillTrainingDetails.isDiscounted) : costCheckboxes.fullPrice);
    setValue('cost', editMode && (skillTrainingDetails.isDiscounted || (!skillTrainingDetails.isFree && !skillTrainingDetails.isDiscounted)) ? skillTrainingDetails.cost : null);
    setValue('startDate', (editMode && skillTrainingDetails.startDate) ? skillTrainingDetails.startDate : dayjs().format('YYYY-MM-DD'));
    setValue('endDate', (editMode && skillTrainingDetails.endDate) ? skillTrainingDetails.endDate : getEndOfNextSchoolYear());
    setValue('durationValue', editMode ? skillTrainingDetails.durationValue : 0);
    setValue('durationUnit', editMode ? durationUnitDefaultValue() : null);
    setValue('note', editMode ? skillTrainingDetails.note : '');
    setValue('isLearnAndWork', editMode ? skillTrainingDetails.isLearnAndWork : false);
    setValue('active', editMode ? skillTrainingDetails.active : false);

    setDurationCheckboxes({
      ongoing: Boolean(!skillTrainingDetails.startDate && !skillTrainingDetails.endDate),
      startEndDate: Boolean(skillTrainingDetails.startDate && skillTrainingDetails.endDate),
    });
    setCostCheckboxes({
      free: Boolean(skillTrainingDetails.isFree),
      discounted: Boolean(skillTrainingDetails.isDiscounted),
      fullPrice: Boolean(!skillTrainingDetails.isFree && !skillTrainingDetails.isDiscounted),
    });

    setActiveCheckbox(skillTrainingDetails.active);
    setLearnAndWorkCheckbox(skillTrainingDetails.isLearnAndWork);

    if (!skillTrainingDetails.courseFormat.inPersonInstructorLead && !skillTrainingDetails.courseFormat.hybrid) {
      setDisableZip(true);
    }
  };

  const handleAbleToDelete = (): boolean => {
    if (user.isAdmin) {
      // Super admin can delete any
      return true;
    }
    else if (skillTrainingDetails.createdById) {
      adminGetUserById(skillTrainingDetails.createdById).then((res: User) => {
        // Institution district admin can delete/ edit any created by any institution admins
        if ((user.isDistrictAdmin && user.isInstitutionAdmin) && res.isInstitutionAdmin) {
          return true;
          // Community users and Institution admins can only delete the ones created by their organization
        }
        else if ((user.isCommunity || user.isInstitutionAdmin) && res.organizationId === user.organizationId) {
          return true;
        }
      });
    }

    return false;
  };

  const checkCourseFormatForOnline = (arr: SkillTrainingCourse[]) => {
    const findVal = arr.some((ele: SkillTrainingCourse) => ele.value === CourseFormats.InPersonInstructorLead || ele.value === CourseFormats.Hybrid);
    setDisableZip(!findVal && !!arr.length);
  };

  const durationUnitDefaultValue = () => {
    return courseDurationDropdownList.find(e => e.value === skillTrainingDetails.durationUnit);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, stateObj: any) => {
    const tempObj: any = {
    };
    Object.keys(stateObj).map((ele: any) => {
      if (event.target.name === ele) {
        tempObj[event.target.name] = event.target.checked;
      }
      else tempObj[ele] = false;
    });

    if (stateObj === costCheckboxes) {
      setCostCheckboxes(tempObj);
    }
    else {
      setDurationCheckboxes(tempObj);
    }
  };

  const onDeleteSkillTraining = () => {
    setShowDeleteConfirmDialog(false);
    dispatch(deleteSkillTraining(skillTrainingDetails.id))
      .unwrap()
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully deleted skill training.',
        }));
        navigate(AppRoutes.pathways.path);
      })
      .catch((e) => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to delete skill training.',
        }));
      });
  };

  const createDynamicCostCheckboxes = () => {
    return Object.entries(costCheckboxes).map((key, index) =>
      <div className={index == 0 ? 'width-15' : 'width-25'}
        key={index}>

        <FormControlLabel label={startCase(key[0])}
          className="min-5-width"
          control={
            <Checkbox
              checked={costCheckboxes[key[0] as keyof CostCheckboxesType] = key[1]}
              onChange={(e) => handleCheckboxChange(e, costCheckboxes)}
              name={key[0]} />
          } />
      </div>
    );
  };

  const createDynamicDateCheckboxes = () => {
    return Object.entries(durationCheckboxes).map((key, index) =>
      <div
        className={index == 0 ? 'width-15' : 'width-40'}
        key={index}
      >
        <FormControlLabel label={key[0] == 'ongoing' ? startCase(key[0]) : 'Start / End Date'}
          className="min-5-width"
          control={
            <Checkbox
              checked={durationCheckboxes[key[0] as keyof DurationCheckboxesType] = key[1]}
              onChange={(e) => handleCheckboxChange(e, durationCheckboxes)}
              name={key[0]} />
          } />
      </div>
    );
  };

  const formValidationSchema = yup.object().shape({
    name: yup.string()
      .required('Skill training name is required')
      .typeError('Skill training name is required'),
    url: yup.string()
      .isValidUrl('URL entered is invalid')
      .required('URL is required')
      .typeError('URL is required'),
    company: yup.string()
      .required('Company is required')
      .typeError('Company is required'),
    courseFormat: yup.array()
      .min(1, 'Course format is required')
      .required('Course format is required')
      .typeError('Course format is required'),
    description: yup.string()
      .required('Description is required')
      .typeError('Description is required'),
    skills: yup.array()
      .min(1, 'Skills are required')
      .required('Skills are required')
      .typeError('Skills are required'),
    zipcode: yup.object().nullable(),
    isFree: yup.boolean(),
    isDiscounted: yup.boolean(),
    isFullPrice: yup.boolean(),
    cost: (costCheckboxes.discounted || costCheckboxes.fullPrice) ?
      yup.number()
        .required('Cost is required')
        .typeError('Cost is required') :
      yup.number().nullable(),
    startDate: (durationCheckboxes.startEndDate) ?
      yup.string()
        .test(
          'start_date_test',
          'Start date must be before end date',
          (value: any, parent: any) => parent.parent.endDate ? dayjs(parent.parent.endDate).isAfter(value) : true
        ) :
      yup.string(),
    endDate: (durationCheckboxes.startEndDate) ?
      yup.string()
        .test(
          'end_date_test',
          'End date must be after start date',
          (value: any, parent: any) => value ? dayjs(value).isAfter(parent.parent.startDate) : true
        ) :
      yup.string(),
    durationValue: yup.number()
      .required('Duration value is required')
      .typeError('Duration value is required'),
    durationUnit: yup.object()
      .required('Duration unit is required')
      .typeError('Duration unit is required'),
    note: yup.string().nullable(),
    isLearnAndWork: yup.boolean(),
    active: yup.boolean(),
  }).required();

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const { handleSubmit, setValue, formState: { errors } } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    const submitValue = {
      ...values,
      isFree: costCheckboxes.free,
      isDiscounted: costCheckboxes.discounted,
      isLearnAndWork: learnAndWorkCheckbox,
      active: activeCheckbox,
      ongoing: durationCheckboxes.ongoing,
      approved: editMode ? null : (user.isAdmin || null),
    };

    if (editMode) {
      dispatch(updateSkillTraining({
        id: skillTrainingDetails.id,
        body: submitValue,
      }))
        .unwrap()
        .then(() => {
          dispatch(setAlert({
            type: 'success',
            message: 'Successfully updated skill training.',
          }));
          navigate(-1);
        })
        .catch(() => {
          dispatch(setAlert({
            type: 'error',
            message: 'Unable to update skill training.',
          }));
        });
    }
    else {
      // Check for duplicate skill trainings if they haven't already been checked
      if (!duplicateSkillTrainings || !duplicateSkillTrainings.length) {
        const duplicates = await duplicateSkillTrainingCheck({
          name: submitValue.name,
          link: submitValue.url,
        });

        if (duplicates && !!duplicates.length) {
          setDuplicateSkillTrainings(duplicates);
          setShowDuplicateSkillTrainingModal(true);
          return;
        }
      }

      dispatch(createSkillTraining(submitValue))
        .unwrap()
        .then(() => {
          dispatch(setAlert({
            type: 'success',
            message: 'Successfully created skill training.',
          }));
          navigate(-1);
        })
        .catch(() => {
          dispatch(setAlert({
            type: 'error',
            message: 'Unable to create skill training.',
          }));
        });
    }
  };

  return (
    <form id="create-skill-training" className="card-background" onSubmit={handleSubmit(submitForm)}>
      {pageLoaded ? (
        <Box className="form-container flex_row_jbetween">
          <FormProvider {...methods}>
            <TextInput
              name="name"
              label="SKILLS TRAINING NAME"
              errorMessage={errors?.name?.message}
              containerClass="width-48"
              type="text"
              size="small"
              onChange={() => duplicateSkillTrainings.length && setDuplicateSkillTrainings([])}
              required
            />

            <TextInput
              name="url"
              label="URL"
              errorMessage={errors?.url?.message}
              containerClass="width-48"
              type="text"
              size="small"
              onChange={() => duplicateSkillTrainings.length && setDuplicateSkillTrainings([])}
              required
            />

            <TextInput
              name="company"
              label="COMPANY"
              errorMessage={errors?.company?.message}
              containerClass="width-48"
              type="text"
              size="small"
              required
            />

            <DropdownSelect
              name="courseFormat"
              label="COURSE FORMAT"
              itemList={courseFormatDropdownList}
              errorMessage={errors?.courseFormat?.message}
              containerClass="width-48"
              onChange={(e) => checkCourseFormatForOnline(e)}
              size="small"
              required
              multiple
            />

            <TextInput
              name="description"
              label="COURSE DESCRIPTION"
              errorMessage={errors?.description?.message}
              containerClass="width-100"
              type="textarea"
              size="small"
              required
              multiLine
              rows={5}
            />

            <DropdownSelect
              name="skills"
              label="SKILLS"
              itemList={skillList as SkillListItem[]}
              errorMessage={errors?.skills?.message}
              containerClass="width-48"
              size="small"
              multiple
              required
            />

            <DropdownSelect
              containerClass="width-48"
              name="zipcode"
              label="ZIP CODE"
              itemList={zipcodes}
              errorMessage={errors?.zipcode?.message}
              disabled={disableZip}
              size="small"
            />

            <p className="static-input-label checkbox-label width-100"> COST </p>
            <div className=" width-100 flex_row_acenter">
              {createDynamicCostCheckboxes()}
            </div>

            <div className=" width-100 flex_row_acenter">
              <span className="hidden-span width-15 flex_row" />

              <span className="width-25 flex_row">
                {costCheckboxes.discounted && (
                  <TextInput
                    name="cost"
                    containerClass="width-100 cost-textbox"
                    errorMessage={errors?.cost?.message}
                    type="number"
                    size="small"
                    required={costCheckboxes.discounted}
                    InputProps={{
                      startAdornment: <AttachMoneyIcon className="money-icon" />,
                      inputProps: {
                        min: 1,
                      },
                    }}
                  />
                )}
              </span>

              <span className="width-25 flex_row">
                {costCheckboxes.fullPrice && (
                  <TextInput
                    name="cost"
                    containerClass="width-100 cost-textbox"
                    errorMessage={errors?.cost?.message}
                    type="number"
                    size="small"
                    required={costCheckboxes.fullPrice}
                    InputProps={{
                      startAdornment: <AttachMoneyIcon className="money-icon" />,
                      inputProps: {
                        min: 1,
                      },
                    }}
                  />
                )}
              </span>
            </div>

            <p className="static-input-label checkbox-label width-100"> DURATION </p>
            <div className="static-length-container flex_row">
              <span className="field-container width-100">
                <p className="small-p required-field">COURSE DURATION</p>
                <TextInput
                  name="durationValue"
                  errorMessage={errors?.durationValue?.message}
                  containerClass="width-100 cost-textbox"
                  type="number"
                  size="small"
                  required
                  InputProps={{
                    inputProps: {
                      min: 1,
                    },
                  }}
                />
              </span>

              <span className="width-100">
                <p className="small-p required-field">DURATION UNIT</p>
                <DropdownSelect
                  name="durationUnit"
                  itemList={courseDurationDropdownList}
                  errorMessage={errors?.durationUnit?.message}
                  containerClass="width-100 cost-textbox"
                  size="small"
                  required
                />
              </span>
            </div>

            <p className="static-input-label checkbox-label width-100"> DATES </p>
            <div className=" width-100 flex_row_acenter">
              {createDynamicDateCheckboxes()}
            </div>

            <div className=" width-100 flex_row_acenter">
              <span className="hidden-span width-15 flex_row" />
              {durationCheckboxes.startEndDate ? (
                <span className="width-40 flex_row_jbetween">
                  <TextInput
                    name="startDate"
                    containerClass="width-100 cost-textbox"
                    errorMessage={errors?.startDate?.message}
                    type="date"
                    size="small"
                    required={durationCheckboxes.startEndDate}
                    InputProps={{
                      inputProps: {
                        // in case, we want to disable all the past date in calendar, may lead to conflict if we want to edit the old skill_training that had startDate in the past
                        // min: checkForEditDetails() ? null : dayjs().format('YYYY-MM-DD'),
                      },
                    }}
                  />

                  <TextInput
                    name="endDate"
                    errorMessage={errors?.endDate?.message}
                    containerClass="width-100 cost-textbox"
                    type="date"
                    size="small"
                    required={durationCheckboxes.startEndDate}
                    InputProps={{
                      inputProps: {
                        // in case, we want to disable all the past date in calendar, may lead to conflict if we want to edit the old skill_training that had endDate in the past
                        // min: checkForEditDetails() ? null : dayjs().add(1, 'day').format('YYYY-MM-DD'),
                      },
                    }}
                  />
                </span>
              ) : <span className="hidden-span width-40 flex_row" />}
            </div>

            <TextInput
              name="note"
              label="ADDITIONAL INFORMATION"
              errorMessage={errors?.note?.message}
              containerClass="width-100"
              type="textarea"
              size="small"
              multiLine
              rows={3}
            />

            <p className="static-input-label width-100"> LOGO </p>
            <div className="width-100">
              <UploadButton
                text="Upload Logo"
                name="logo"
                defaultFileName={editMode ? skillTrainingDetails.image : ''}
                size="large"
                disableElevation
              />
            </div>

            <span className="learn-and-work width-100">
              <FormControlLabel
                label="Learn &amp; Work"
                control={
                  <Checkbox
                    checked={learnAndWorkCheckbox}
                    onChange={() => setLearnAndWorkCheckbox(!learnAndWorkCheckbox)}
                  />
                }
              />
            </span>

            <span className="width-100">
              <FormControlLabel
                label="Active"
                control={
                  <Checkbox
                    checked={activeCheckbox}
                    onChange={() => setActiveCheckbox(!activeCheckbox)}
                  />
                }
              />
            </span>

            {/* Save and Delete Buttons */}
            <div className="button-container flex_jbetween width-100">
              {editMode && handleAbleToDelete() && (
                <Button
                  variant="outlined"
                  onClick={() => setShowDeleteConfirmDialog(true)}
                >
                  Delete Skills Training
                </Button>
              )}

              <div className={editMode && user.isAdmin ? 'right-side flex_jend' : 'right-side flex_jbetween'}>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>

                <SubmitButton
                  text="Save"
                  variant="contained"
                  size="large"
                  disableElevation
                />
              </div>
            </div>
          </FormProvider>
        </Box>
      ) : (
        <div className="flex_jcenter_acenter loading-indicator">
          <CircularProgress size="50px" />
        </div>
      )}

      <DuplicateResourceModal
        open={showDuplicateSkillTrainingModal}
        resourceText="Skills Training Course"
        additionalWarningText="Oops! This skills training course already exists. Check the course(s) below before continuing."
        resources={duplicateSkillTrainings}
        onClose={() => setShowDuplicateSkillTrainingModal(false)}
      />

      <DeleteConfirmationModal
        open={showDeleteConfirmDialog}
        deleteItemText="Skill Training"
        onConfirm={onDeleteSkillTraining}
        onClose={() => setShowDeleteConfirmDialog(false)}
      />
    </form>
  );
};

export default CreateSkillTraining;
