
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import React, { ReactElement } from 'react';
import {
  Navigate, useLocation
} from 'react-router-dom';
import {
  AppRoutes, AuthComponent, canUseAuthComponent
} from '../../app/app.types';
import { userSelectors } from '../../features/user/user.slice';
import { getSessionToken } from '../../util/localStorage';
import { useAppSelector } from '../hooks';


interface PrivateRouteProps extends AuthComponent {
  element: ReactElement;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element, authorizedRoles }) => {

  const location = useLocation();

  const isAuthenticated = !!getSessionToken();
  const user = useAppSelector(userSelectors.selectUser);
  const userIsLoaded = useAppSelector(userSelectors.selectUserLoaded);

  if (isAuthenticated && !userIsLoaded) {
    return (
      <div className="flex_jcenter_acenter loading-indicator">
        <CircularProgress size="50px" />
      </div>
    );
  }
  else if (isAuthenticated && (!authorizedRoles || userIsLoaded && canUseAuthComponent(user.roles, authorizedRoles))) {
    return element;
  }
  else if (isAuthenticated && (authorizedRoles && userIsLoaded && !canUseAuthComponent(user.roles, authorizedRoles))) {
    return (
      <Navigate to={AppRoutes.dashboard.path} />
    );
  }
  else {
    return (
      <Navigate
        to={AppRoutes.signIn.path}
        state={{
          from: location.pathname,
        }}
      />
    );
  }
};

export default PrivateRoute;
