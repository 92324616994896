import { yupResolver } from '@hookform/resolvers/yup';
import React, {
  useEffect, useState
} from 'react';
import {
  FormProvider, useForm
} from 'react-hook-form';
import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import DropdownSelect from '../../../components/forms/DropdownSelect';
import {
  EmptyOrganization, Organization, OrganizationType
} from '../../../features/organization/organization.model';
import './BulkCreateStepOne.scss';
import * as yup from 'yup';
import { setUserSelectedOrg } from '../../../features/bulkAccountCreation/bulkAccountCreation.thunks';
import { bulkAccountCreationSelectors } from '../../../features/bulkAccountCreation/bulkAccountCreation.slice';
import { userSelectors } from '../../../features/user/user.slice';
import { getOrganizationsByType } from '../../../features/organization/organization.api';

interface BulkCreateStepOneProps {
}

const BulkCreateStepOne: React.FC<BulkCreateStepOneProps> = () => {

  const dispatch = useAppDispatch();

  const user = useAppSelector(userSelectors.selectUser);
  const selectedSchool = useAppSelector(bulkAccountCreationSelectors.userSelectedSchool);

  const [
    schoolList,
    setSchoolList,
  ] = useState<Organization[]>([]);

  const [
    disableSchoolField,
    setDisableSchoolField,
  ] = useState<boolean>(false);


  useEffect(() => {
    if (user.isAdmin || user.isDistrictAdmin) {
      getOrganizationsByType(undefined, user.isDistrictAdmin ? user.organizationId : undefined, true, false)
        .then((res) => {
          setSchoolList(res.filter((e) => e.type === OrganizationType.MiddleSchool || e.type === OrganizationType.HighSchool));
        });
    }
    else {
      handleDefaultSchool();
    }
    // This is for when the user comes back to this tab. This just ensures that the right school does always show
    if (selectedSchool.id !== 0) {
      setValue('school', selectedSchool);
    }
  }, []);

  const handleDefaultSchool = () => {
    if (user.isMiddleSchoolAdmin || user.isHighSchoolAdmin) {
      dispatch(setUserSelectedOrg(user.organization));
      setDisableSchoolField(true);
      setValue('school', user.organization);
    }
  };

  const formValidationSchema = yup.object().shape({
    school: yup.object()
      .required('School is required')
      .typeError('Wrong Type'),
  }).required();


  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const { setValue } = methods;

  const handleFieldChange = (value: Organization) => {
    value ? dispatch(setUserSelectedOrg(value)) : dispatch(setUserSelectedOrg(EmptyOrganization));
  };


  return (
    <form className="step-one">
      <h3>Instructions: </h3>
      <p> Start by selecting the school the users attend. </p>

      <p> (If you only have access to one school on your account, the field has already been populated and you can move onto the next step.) </p>

      <FormProvider {...methods}>
        <DropdownSelect
          inputClass="school-select"
          name="school"
          label="School"
          itemList={schoolList}
          onChange={handleFieldChange}
          disabled={disableSchoolField}
          placeholder="Start typing school name..."
        />
      </FormProvider>
    </form>
  );
};

export default BulkCreateStepOne;