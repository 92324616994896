import axios from 'axios';
import { AppConfigVariables } from '../../app/app.types';


const configPath = '/config.json';

export const getConfig = async (): Promise<AppConfigVariables> => {
  try {
    const response = await axios.get(configPath);
    const envData: AppConfigVariables = await response.data;

    return envData;
  }
  catch (err) {
    console.error('Could not retrieve configuration');
    throw err;
  }
};
