import React from 'react';
import {
  FormLabel,
  Popover
} from '@mui/material';
import {
  FormProvider, SubmitHandler, useForm
} from 'react-hook-form';
import {
  bottomRightAnchorOrigin, topCenterTransformOrigin
} from '../../constants/menu.types';
import SubmitButton from '../../components/forms/SubmitButton';
import { ListMenuItem } from '../../components/forms/form.types';
import CloseIcon from '@mui/icons-material/Close';
import { SecondaryColorRadioGroup } from '../../components/CustomStyledComponents';

interface ApprovalFilterProps {
  anchorElement: HTMLElement | null;
  userTypeList?: ListMenuItem[];
  orgTypeList?: ListMenuItem[];
  setAnchorElement: (anchorElement: HTMLElement | null) => void;
  setFiltersCallback: (queryType?: string, schoolDistrictId?: number) => void;
}

const ApprovalFilter: React.FC<ApprovalFilterProps> = ({
  anchorElement,
  setAnchorElement,
  setFiltersCallback,
  userTypeList = [],
}) => {

  type FormValues = { userType?: string };
  const methods = useForm<FormValues>();
  const { handleSubmit } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    setFiltersCallback(values.userType);
    setAnchorElement(null);
  };

  return (
    <Popover
      id="list-filter"
      anchorEl={anchorElement}
      open={Boolean(anchorElement)}
      onClose={() => setAnchorElement(null)}
      transformOrigin={topCenterTransformOrigin}
      anchorOrigin={bottomRightAnchorOrigin}
    >
      <CloseIcon className="close-icon" onClick={() => setAnchorElement(null)} />
      <form className="flex_col" onSubmit={handleSubmit(submitForm)}>
        <FormProvider {...methods}>
          <FormLabel id="user-type-filter">User Type</FormLabel>
          <SecondaryColorRadioGroup
            name="userType"
            itemList={userTypeList}
            labelledBy="user-type-filter"
            size="small"
          />
          <SubmitButton
            className="submit-button"
            text="Apply Filters"
            variant="contained"
            size="small"
            color="secondary"
            disableElevation
          />
        </FormProvider>
      </form>
    </Popover>
  );
};

export const ApprovalOrgTypeFilter: React.FC<ApprovalFilterProps> = ({
  anchorElement,
  setAnchorElement,
  setFiltersCallback,
  orgTypeList = [],
}) => {

  type FormValues = { schoolType?: string };
  const methods = useForm<FormValues>();
  const { handleSubmit } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    setFiltersCallback(values.schoolType);
    setAnchorElement(null);
  };

  return (
    <Popover
      id="list-filter"
      anchorEl={anchorElement}
      open={Boolean(anchorElement)}
      onClose={() => setAnchorElement(null)}
      transformOrigin={topCenterTransformOrigin}
      anchorOrigin={bottomRightAnchorOrigin}
    >
      <CloseIcon className="close-icon" onClick={() => setAnchorElement(null)} />
      <form className="flex_col" onSubmit={handleSubmit(submitForm)}>
        <FormProvider {...methods}>
          <FormLabel id="school-type-filter">School Type</FormLabel>
          <SecondaryColorRadioGroup
            name="schoolType"
            itemList={orgTypeList}
            labelledBy="school-type-filter"
            size="small"
          />
          <SubmitButton
            className="submit-button"
            text="Apply Filters"
            variant="contained"
            size="small"
            color="secondary"
            disableElevation
          />
        </FormProvider>
      </form>
    </Popover>
  );
};

export default ApprovalFilter;
