
import React from 'react';
import {
  FormProvider, SubmitHandler, useForm
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  AppAssetPaths, AppRoutes
} from '../../../app/app.types';
import TextInput from '../../../components/forms/TextInput';
import SubmitButton from '../../../components/forms/SubmitButton';
import {
  Link, useNavigate
} from 'react-router-dom';
import { forgotPassword } from '../../../features/user/auth.thunks';
import { useAppDispatch } from '../../../app/hooks';


interface ForgotPasswordProps { }

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formValidationSchema = yup.object().shape({
    email: yup.string()
      .email('Must be a valid email')
      .required('Email is required'),
  }).required();

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const { handleSubmit, formState: { errors } } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    dispatch(forgotPassword(values))
      .unwrap()
      .then(() => {
        navigate(AppRoutes.resetPassword.path, {
          state: values.email,
        });
      });
  };

  return (
    <form className="content-container" onSubmit={handleSubmit(submitForm)}>
      <div className="top-section">
        <img src={AppAssetPaths.images.KTS_LOGO} alt="" />
        <h2>Forgot Password</h2>

        <FormProvider {...methods}>
          <TextInput
            containerClass="main-input"
            name="email"
            label="Email"
            errorMessage={errors.email && errors.email.message}
            type="text"
            size="small"
          />

          <SubmitButton
            className="submit-button"
            text="Submit"
            variant="contained"
            size="large"
            disableElevation
          />

          <div className="centered-link">
            <Link to={AppRoutes.signIn.path}>
              <strong>Back to Sign In</strong>
            </Link>
          </div>
        </FormProvider>
      </div>
    </form>
  );
};

export default ForgotPassword;
