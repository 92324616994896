import dayjs from 'dayjs';


export const END_OF_SCHOOL_YEAR = '06/30';

export const getEndOfNextSchoolYear = (): string => {

  if (dayjs() < dayjs(`${END_OF_SCHOOL_YEAR}/${dayjs().year()}`)) {
    return dayjs(`${END_OF_SCHOOL_YEAR}/${dayjs().year()}`).format('YYYY-MM-DD');
  }

  return dayjs(`${END_OF_SCHOOL_YEAR}/${dayjs().year() + 1}`).format('YYYY-MM-DD');
};
