import { createSlice } from '@reduxjs/toolkit';
import { StateStatus } from '../../app/app.types';
import { RootState } from '../../app/store';
import {
  Applicant,
  College,
  emptyFafsaLink,
  FafsaLinkNames,
  FafsaLinks,
  EmptyCollege, FinancialAidInfoSection,
  FinancialAidListItem,
  FinancialAidListItemNames,
  financialAidListItems,
  InstitutionType
} from './collegeApplicationChecklist.model';
import {
  getAllInstitutionTypes,
  getApplicantsList,
  getCollegeById, getCollegesList,
  getFafsaLinks,
  getFinancialAidInfoSections,
  getFinancialAidList,
  updateFafsaLinks,
  updateFinancialAidSections
} from './collegeApplicationChecklist.thunks';


/* State */
export interface CollegeApplicationChecklistState {
  applicantList: {
    data: Applicant[];
    totalCount: number;
    loaded: boolean;
    status: StateStatus;
  },
  collegeList: {
    data: College[];
    totalCount: number;
    loaded: boolean;
    status: StateStatus;
  },
  collegeDetails: {
    data: College;
    status: StateStatus;
    loaded: boolean;
  }

  financialAidList: {
    data: FinancialAidListItem[];
    totalCount: number;
    loaded: boolean;
    status: StateStatus;
  },
  financialAidInfoSections: {
    data: FinancialAidInfoSection[];
    loaded: boolean;
    status: StateStatus;
  },
  fafsaLinks: {
    data: FafsaLinks;
    loaded: boolean;
    status: StateStatus;
  },

  institutionTypes: {
    data: InstitutionType[];
    status: StateStatus;
  }
}

const initialState: CollegeApplicationChecklistState = {
  applicantList: {
    data: [],
    totalCount: 0,
    loaded: false,
    status: StateStatus.IDLE,
  },
  collegeList: {
    data: [],
    totalCount: 0,
    loaded: false,
    status: StateStatus.IDLE,
  },
  collegeDetails: {
    data: EmptyCollege,
    status: StateStatus.IDLE,
    loaded: false,
  },
  financialAidList: {
    data: [],
    totalCount: 0,
    loaded: false,
    status: StateStatus.IDLE,
  },
  financialAidInfoSections: {
    data: [],
    loaded: false,
    status: StateStatus.IDLE,
  },
  fafsaLinks: {
    data: {
      fafsaVideoEnglish: emptyFafsaLink,
      fafsaVideoSpanish: emptyFafsaLink,
      howAidWorks: emptyFafsaLink,
      formPrep: emptyFafsaLink,
      workStudyJobs: emptyFafsaLink,
      deadlines: emptyFafsaLink,
    },
    loaded: false,
    status: StateStatus.IDLE,
  },

  institutionTypes: {
    data: [],
    status: StateStatus.IDLE,
  },
};


/* Reducer */

export const collegeApplicationChecklistSlice = createSlice({
  name: 'collegeApplicationChecklist',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getApplicantsList.pending, (state) => {
        state.applicantList.status = StateStatus.LOADING;
      })
      .addCase(getApplicantsList.fulfilled, (state, action) => {
        state.applicantList.status = StateStatus.IDLE;
        state.applicantList.data = action.payload.applicants;
        state.applicantList.totalCount = action.payload.count;
        state.applicantList.loaded = true;
      })
      .addCase(getApplicantsList.rejected, (state) => {
        state.applicantList.status = StateStatus.FAILED;
      })

      // ------------------------------ COLLEGE LIST
      .addCase(getCollegesList.pending, (state) => {
        state.collegeList.status = StateStatus.LOADING;
      })
      .addCase(getCollegesList.fulfilled, (state, action) => {
        state.collegeList.status = StateStatus.IDLE;
        state.collegeList.data = action.payload.colleges;
        state.collegeList.totalCount = action.payload.count;
        state.collegeList.loaded = true;
      })
      .addCase(getCollegesList.rejected, (state) => {
        state.collegeList.status = StateStatus.FAILED;
      })

      // ------------------------------ COLLEGE DETAILS
      .addCase(getCollegeById.pending, (state) => {
        state.collegeDetails.status = StateStatus.LOADING;
        state.collegeDetails.loaded = false;
      })
      .addCase(getCollegeById.fulfilled, (state, action) => {
        state.collegeDetails.status = StateStatus.IDLE;
        state.collegeDetails.data = action.payload;
        state.collegeDetails.loaded = true;
      })
      .addCase(getCollegeById.rejected, (state) => {
        state.collegeDetails.status = StateStatus.FAILED;
      })

      .addCase(getFinancialAidList.pending, (state) => {
        state.financialAidList.status = StateStatus.LOADING;
      })
      .addCase(getFinancialAidList.fulfilled, (state, action) => {
        state.financialAidList.status = StateStatus.IDLE;

        // Set the data for each details page in global state so we don't have to rely on calling the api again, even though that will still be a valid option.
        const financialAidInfoSections = action.payload.financialAidInfoSections;
        const fafsaLinks = action.payload.fafsaLinks;

        state.financialAidInfoSections.data = [
          ...financialAidInfoSections,
        ];
        state.financialAidInfoSections.loaded = true;

        state.fafsaLinks.data.fafsaVideoEnglish = fafsaLinks.find(l => l.name === FafsaLinkNames.FafsaVideoLinkEnglish) || emptyFafsaLink;
        state.fafsaLinks.data.fafsaVideoSpanish = fafsaLinks.find(l => l.name === FafsaLinkNames.FafsaVideoLinkSpanish) || emptyFafsaLink;
        state.fafsaLinks.data.howAidWorks = fafsaLinks.find(l => l.name === FafsaLinkNames.HowAidWorks) || emptyFafsaLink;
        state.fafsaLinks.data.formPrep = fafsaLinks.find(l => l.name === FafsaLinkNames.FormPrep) || emptyFafsaLink;
        state.fafsaLinks.data.workStudyJobs = fafsaLinks.find(l => l.name === FafsaLinkNames.WorkStudyJobs) || emptyFafsaLink;
        state.fafsaLinks.data.deadlines = fafsaLinks.find(l => l.name === FafsaLinkNames.Deadlines) || emptyFafsaLink;
        state.fafsaLinks.loaded = true;

        // creating a new copy of the financialAidListItems array, this avoids bugs with trying to change read only objects
        const financialAidList = financialAidListItems.map(f => ({
          ...f,
        }));

        // Set the most recent updated_at date for each list item
        const financialAidInfoSectionsMostRecentUpdate = [
          ...financialAidInfoSections,
        ].sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1)[0].updatedAt;
        const fafsaLinksMostRecentUpdate = [
          ...fafsaLinks,
        ].sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1)[0].updatedAt;

        financialAidList.forEach(f => f.lastUpdated = (f.name === FinancialAidListItemNames.FinancialAidInfo ? financialAidInfoSectionsMostRecentUpdate : fafsaLinksMostRecentUpdate));

        state.financialAidList.data = financialAidList;
        state.financialAidList.totalCount = financialAidList.length;
        state.financialAidList.loaded = true;
      })
      .addCase(getFinancialAidList.rejected, (state) => {
        state.financialAidList.status = StateStatus.FAILED;
      })
      .addCase(getFinancialAidInfoSections.pending, (state) => {
        state.financialAidInfoSections.status = StateStatus.LOADING;
      })
      .addCase(getFinancialAidInfoSections.fulfilled, (state, action) => {
        state.financialAidInfoSections.status = StateStatus.IDLE;
        state.financialAidInfoSections.data = action.payload;
        state.financialAidInfoSections.loaded = true;
      })
      .addCase(getFinancialAidInfoSections.rejected, (state) => {
        state.financialAidInfoSections.status = StateStatus.FAILED;
      })
      .addCase(updateFinancialAidSections.pending, (state) => {
        state.financialAidInfoSections.status = StateStatus.LOADING;
      })
      .addCase(updateFinancialAidSections.fulfilled, (state, action) => {
        state.financialAidInfoSections.status = StateStatus.IDLE;
        state.financialAidInfoSections.data = action.payload;
      })
      .addCase(updateFinancialAidSections.rejected, (state) => {
        state.financialAidInfoSections.status = StateStatus.FAILED;
      })
      .addCase(getFafsaLinks.pending, (state) => {
        state.fafsaLinks.status = StateStatus.LOADING;
      })
      .addCase(getFafsaLinks.fulfilled, (state, action) => {
        state.fafsaLinks.status = StateStatus.IDLE;
        state.fafsaLinks.data.fafsaVideoEnglish = action.payload.find(l => l.name === FafsaLinkNames.FafsaVideoLinkEnglish) || emptyFafsaLink;
        state.fafsaLinks.data.fafsaVideoSpanish = action.payload.find(l => l.name === FafsaLinkNames.FafsaVideoLinkSpanish) || emptyFafsaLink;
        state.fafsaLinks.data.howAidWorks = action.payload.find(l => l.name === FafsaLinkNames.HowAidWorks) || emptyFafsaLink;
        state.fafsaLinks.data.formPrep = action.payload.find(l => l.name === FafsaLinkNames.FormPrep) || emptyFafsaLink;
        state.fafsaLinks.data.workStudyJobs = action.payload.find(l => l.name === FafsaLinkNames.WorkStudyJobs) || emptyFafsaLink;
        state.fafsaLinks.data.deadlines = action.payload.find(l => l.name === FafsaLinkNames.Deadlines) || emptyFafsaLink;
        state.fafsaLinks.loaded = true;
      })
      .addCase(getFafsaLinks.rejected, (state) => {
        state.fafsaLinks.status = StateStatus.FAILED;
      })
      .addCase(updateFafsaLinks.pending, (state) => {
        state.fafsaLinks.status = StateStatus.LOADING;
      })
      .addCase(updateFafsaLinks.fulfilled, (state, action) => {
        state.fafsaLinks.status = StateStatus.IDLE;
        state.fafsaLinks.data.fafsaVideoEnglish = action.payload.find(l => l.name === FafsaLinkNames.FafsaVideoLinkEnglish) || emptyFafsaLink;
        state.fafsaLinks.data.fafsaVideoSpanish = action.payload.find(l => l.name === FafsaLinkNames.FafsaVideoLinkSpanish) || emptyFafsaLink;
        state.fafsaLinks.data.howAidWorks = action.payload.find(l => l.name === FafsaLinkNames.HowAidWorks) || emptyFafsaLink;
        state.fafsaLinks.data.formPrep = action.payload.find(l => l.name === FafsaLinkNames.FormPrep) || emptyFafsaLink;
        state.fafsaLinks.data.workStudyJobs = action.payload.find(l => l.name === FafsaLinkNames.WorkStudyJobs) || emptyFafsaLink;
        state.fafsaLinks.data.deadlines = action.payload.find(l => l.name === FafsaLinkNames.Deadlines) || emptyFafsaLink;
      })
      .addCase(updateFafsaLinks.rejected, (state) => {
        state.fafsaLinks.status = StateStatus.FAILED;
      })

      .addCase(getAllInstitutionTypes.pending, (state) => {
        state.institutionTypes.status = StateStatus.LOADING;
      })
      .addCase(getAllInstitutionTypes.fulfilled, (state, action) => {
        state.institutionTypes.data = action.payload;
        state.institutionTypes.status = StateStatus.IDLE;
      })
      .addCase(getAllInstitutionTypes.rejected, (state) => {
        state.institutionTypes.status = StateStatus.FAILED;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const { } = collegeApplicationChecklistSlice.actions;


/* Selectors */

const selectApplicantList = (state: RootState): Applicant[] => state.collegeApplicationChecklist.applicantList.data;
const selectApplicantListCount = (state: RootState): number => state.collegeApplicationChecklist.applicantList.totalCount;

const selectCollegeList = (state: RootState): College[] => state.collegeApplicationChecklist.collegeList.data;
const selectCollegeListCount = (state: RootState): number => state.collegeApplicationChecklist.collegeList.totalCount;
const selectCollegeDetails = (state: RootState): College => state.collegeApplicationChecklist.collegeDetails.data;
const selectCollegeDetailsLoaded = (state: RootState): boolean => state.collegeApplicationChecklist.collegeDetails.loaded;

const selectFinancialAidList = (state: RootState): FinancialAidListItem[] => state.collegeApplicationChecklist.financialAidList.data;
const selectFinancialAidListCount = (state: RootState): number => state.collegeApplicationChecklist.financialAidList.totalCount;
const selectFinancialAidInfoSections = (state: RootState): FinancialAidInfoSection[] => state.collegeApplicationChecklist.financialAidInfoSections.data;
const selectFinancialAidInfoSectionsLoaded = (state: RootState): boolean => state.collegeApplicationChecklist.financialAidInfoSections.loaded;
const selectFafsaLinks = (state: RootState): FafsaLinks => state.collegeApplicationChecklist.fafsaLinks.data;
const selectFafsaLinksLoaded = (state: RootState): boolean => state.collegeApplicationChecklist.fafsaLinks.loaded;

const selectInstitutionTypes = (state: RootState): InstitutionType[] => state.collegeApplicationChecklist.institutionTypes.data;

export const collegeApplicationChecklistSelectors = {
  selectApplicantList,
  selectApplicantListCount,

  selectCollegeList,
  selectCollegeListCount,
  selectCollegeDetails,
  selectCollegeDetailsLoaded,
  
  selectFinancialAidList,
  selectFinancialAidListCount,
  selectFinancialAidInfoSections,
  selectFinancialAidInfoSectionsLoaded,
  selectFafsaLinks,
  selectFafsaLinksLoaded,

  selectInstitutionTypes,
};

export default collegeApplicationChecklistSlice.reducer;
