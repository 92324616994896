/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import {
  HTTP_METHOD, requestFactory
} from '../../app/app.api';
import {
  Announcement,
  AnnouncementListQueryParams,
  ConstructAnnouncement
} from './announcement.model';

export const getAnnouncementList = async (query: AnnouncementListQueryParams): Promise<{ announcements: Announcement[], count: number }> => {
  const limitValue = query.limit || 100;
  const searchParam = query.search ? `&search=${query.search}` : '';

  let orderBy = query.sortModel?.length ? `&order_by=${query.sortModel[0].field}` : '';
  const orderDirecton = query.sortModel?.length ? `&order_direction=${query.sortModel[0].sort}` : '';

  orderBy = orderBy.replace('endDate', 'end_date');
  orderBy = orderBy.replace('educationLevels', 'education_levels');
  orderBy = orderBy.replace('userTypes', 'user_type_ids');

  const url = `/messageboard?offset=${query.offset}&limit=${limitValue}${searchParam}${orderBy}${orderDirecton}`;

  const { data } = await requestFactory(HTTP_METHOD.GET, url);

  const announcements = data.data.map((announcement: any) => ConstructAnnouncement(announcement));

  return {
    announcements,
    count: data.count,
  };
};

export const getAnnouncementById = async (id: number): Promise<Announcement> => {
  const response = await requestFactory(HTTP_METHOD.GET, `/messageboard/${id}`);

  const data = await response.data.data;

  const announcement = ConstructAnnouncement(data);

  return announcement;
};

export const createAnnouncement = async (values: Announcement): Promise<void> => {
  const body = {
    message: values.message,
    user_type_ids: values.userTypeIds,
    education_levels: values.educationLevels?.map(item => item.id),
    start_date: values.startDate,
    end_date: values.endDate,
    is_past: false,
    link: values.link,
  };

  const response = await requestFactory(HTTP_METHOD.POST, '/messageboard', undefined, body);

  return await response.data;
};

export const updateAnnouncement = async (values: Announcement): Promise<void> => {
  const body = {
    message: values.message,
    user_type_ids: values.userTypeIds,
    education_levels: values.educationLevels?.map(item => item.id) || [],
    start_date: values.startDate,
    end_date: values.endDate,
    is_past: false,
    link: values.link,
  };

  const response = await requestFactory(HTTP_METHOD.PATCH, `/messageboard/${values.id}`, undefined, body);

  return await response.data;
};

export const deleteAnnouncement = async (id: number): Promise<boolean> => {
  await requestFactory(HTTP_METHOD.DELETE, `/messageboard/${id}`);
  return true;
};

export const getOverlapMessages = async (values: Announcement): Promise<Announcement[]> => {
  const body = {
    id: values.id,
    user_type_ids: values.userTypeIds,
    education_levels: values.educationLevels?.map(item => item.id),
    start_date: values.startDate,
    end_date: values.endDate,
  };

  const response = await requestFactory(HTTP_METHOD.POST, '/messageboard/overlap', undefined, body);

  const { data } = await response.data;
  const announcements = data.map((announcement: any) => ConstructAnnouncement(announcement));

  return announcements;
};