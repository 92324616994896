/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  ConstructEmptyPathwayCourseByType, PathwayCourse, PathwayCourseType
} from '../../../../features/pathways/pathways.model';
import ExpandableCard from '../../../../components/details/ExpandableCard';
import TextInput from '../../../../components/forms/TextInput';
import { AppAssetPaths } from '../../../../app/app.types';
import { Divider } from '@mui/material';
import { useAppSelector } from '../../../../app/hooks';
import { userSelectors } from '../../../../features/user/user.slice';

import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


interface PostSecondaryResourceFormProps {
  postSecondaryResourceFields: any;
  errors: any;
  appendCourseCallback: (course: PathwayCourse) => void;
  removeCourseCallback: (fieldIndex: number, courseId?: number, ) => void;
  updateCourseCallback: (fieldIndex: number, course: Partial<PathwayCourse>) => void;
  getValuesCallback: (fieldName: string) => any;
}

const PostSecondaryResourceForm: React.FC<PostSecondaryResourceFormProps> = ({
  postSecondaryResourceFields,
  errors,
  appendCourseCallback,
  removeCourseCallback,
  updateCourseCallback,
  getValuesCallback,
}) => {

  const user = useAppSelector(userSelectors.selectUser);

  return (
    <ExpandableCard
      className="course-form"
      summary={<span>Post-Secondary Resources</span>}
      details={
        <div className="course-container">
          {postSecondaryResourceFields.map((c: PathwayCourse, i: number) => (
            <div key={c.id}>
              {i > 0 && <Divider variant="middle" className="divider course-divider" />}
              <div className="flex_jbetween_acenter">
                <p className="course-number">Resource {i+1}</p>
                <div className="course-control flex">
                  <span
                    className="hide-button"
                    onClick={() => updateCourseCallback(i, {
                      ...postSecondaryResourceFields[i],
                      name: getValuesCallback(`postSecondaryResources.${i}.name`),
                      link: getValuesCallback(`postSecondaryResources.${i}.link`),
                      active: !postSecondaryResourceFields[i].active,
                    })}
                  >
                    {postSecondaryResourceFields[i].active ? (
                      <div className="flex_acenter">
                        <p>Hide Resource</p>
                        <VisibilityIcon className="eye-icon" />
                      </div>
                    ) : (
                      <div className="flex_acenter">
                        <p>Show Resource</p>
                        <VisibilityOffIcon className="eye-icon" />
                      </div>
                    )}
                  </span>
                  {user.isAdmin && (
                    <span
                      className="remove-button flex_acenter"
                      onClick={() => removeCourseCallback(i, postSecondaryResourceFields[i].courseId)}
                    >
                      <p>Remove Resource</p>
                      <img className="trash-icon" src={AppAssetPaths.icons.TRASH_RED} alt="delete" />
                    </span>
                  )}
                </div>
              </div>
              <div className="form-content flex_row_jbetween">
                <div className="form-column">
                  <TextInput
                    name={`postSecondaryResources.${i}.name` as 'postSecondaryResources.0.name'}
                    label="RESOURCE NAME"
                    errorMessage={errors?.postSecondaryResources?.[i]?.name?.message}
                    type="text"
                    size="small"
                    disabled={user.isDistrictAdmin || user.isHighSchoolAdmin}
                  />
                </div>
                <div className="form-column">
                  <TextInput
                    name={`postSecondaryResources.${i}.link` as 'postSecondaryResources.0.link'}
                    label="LINK"
                    errorMessage={errors?.postSecondaryResources?.[i]?.link?.message}
                    type="text"
                    size="small"
                    disabled={user.isDistrictAdmin || user.isHighSchoolAdmin}
                  />
                </div>
              </div>
            </div>
          ))}
          {user.isAdmin && (
            <span
              className="add-button flex_acenter"
              onClick={() => appendCourseCallback(ConstructEmptyPathwayCourseByType(PathwayCourseType.postSecondaryResource))}
            >
              <AddIcon className="add-icon" />
              <p>Add Resource</p>
            </span>
          )}
        </div>
      }
      expandable
    />
  );
};

export default PostSecondaryResourceForm;
