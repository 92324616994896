import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import RadioButtonGroup, { RadioButtonGroupProps } from './forms/RadioButtonGroup';


export const DownloadButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#282828',
  backgroundColor: '#F7F7F9',
  '&:hover': {
    backgroundColor: '#F7F7F9',
  },
  fontWeight: 'normal',
}));

export const RedTextButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#db1e36',
  backgroundColor: '#F7F7F9',
  '&:hover': {
    backgroundColor: '#F7F7F9',
  },
  fontWeight: 'bold',
}));

export const SecondaryColorRadioGroup = styled(RadioButtonGroup)<RadioButtonGroupProps>(({ theme }) => ({
  '.MuiRadio-root': {
    color: '#2f2f51 !important',
    '&:hover': {
      backgroundColor: 'rgba(47, 47, 81, 0.1)',
    },
  },
}));
