import * as collegeChecklistApi from './collegeApplicationChecklist.api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ApplicantListQueryParams,
  College,
  CollegeListQueryParams,
  FafsaLinks,
  FinancialAidInfoSection
} from './collegeApplicationChecklist.model';


export const getApplicantsList = createAsyncThunk(
  'collegeApplicationChecklist/getApplicantsList',
  async (query: ApplicantListQueryParams) => {
    return await collegeChecklistApi.getApplicantsList(query);
  }
);

export const getCollegesList = createAsyncThunk(
  'collegeApplicationChecklist/getCollegesList',
  async (query: CollegeListQueryParams) => {
    return await collegeChecklistApi.getCollegesList(query);
  }
);

export const getCollegeById = createAsyncThunk(
  'collegeApplicationChecklist/getCollegeById',
  async (id: number) => {
    return await collegeChecklistApi.getCollegeById(id);
  }
);

export const createNewCollege = createAsyncThunk(
  'institutions/create',
  async (body: College) => {
    return await collegeChecklistApi.createCollege(body);
  }
);

export const updateCollege = createAsyncThunk(
  'institutions/update',
  async (values: { id: number, body: Partial<College> }) => {
    return await collegeChecklistApi.updateCollege(values.id, values.body);
  }
);

export const getFinancialAidList = createAsyncThunk(
  'collegeApplicationChecklist/getFinancialAidList',
  async () => {
    const financialAidInfoSections = await collegeChecklistApi.getFinancialAidSections();
    const fafsaLinks = await collegeChecklistApi.getFafsaLinks();

    return {
      financialAidInfoSections,
      fafsaLinks,
    };
  }
);

export const getFinancialAidInfoSections = createAsyncThunk(
  'collegeApplicationChecklist/getFinancialAidInfoSections',
  async () => {
    return await collegeChecklistApi.getFinancialAidSections();
  }
);

export const updateFinancialAidSections = createAsyncThunk(
  'collegeApplicationChecklist/updateFinancialAidSections',
  async (body: FinancialAidInfoSection[]) => {
    return await collegeChecklistApi.updateFinancialAidSections(body);
  }
);

export const getFafsaLinks = createAsyncThunk(
  'collegeApplicationChecklist/getFafsaLinks',
  async () => {
    return await collegeChecklistApi.getFafsaLinks();
  }
);

export const updateFafsaLinks = createAsyncThunk(
  'collegeApplicationChecklist/updateFafsaLinks',
  async (body: FafsaLinks) => {
    return await collegeChecklistApi.updateFafsaLinks(body);
  }
);

export const getAllInstitutionTypes = createAsyncThunk(
  'collegeApplicationChecklist/getAllInstitutionTypes',
  async () => {
    return await collegeChecklistApi.getAllInstitutionTypes();
  }
);