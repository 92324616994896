/* eslint-disable react/display-name */
import React from 'react';
import {
  GridColumns, GridRenderCellParams, GridValueFormatterParams, GridValueGetterParams
} from '@mui/x-data-grid';
import { AppAssetPaths } from '../../app/app.types';
import dayjs from 'dayjs';
import { UserApprovalStatus } from '../../features/user/user.model';
import { startCase } from 'lodash';


export const studentListColumns: GridColumns = [
  {
    headerName: 'Name',
    field: 'fullname',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    cellClassName: 'main-column',
  },
  {
    headerName: 'School',
    field: 'organization',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.organization?.name?.toLowerCase() || '-',
    cellClassName: 'org-column',
  },
  {
    headerName: 'Graduation Year',
    field: 'graduationYear',
    flex: .7,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Email',
    field: 'email',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Status',
    field: 'status',
    flex: .8,
    renderCell: (params: GridRenderCellParams<boolean | undefined>) => (
      <div className="flex_acenter">
        {(params.row.status.partialRegistration) ? (
          <img src={AppAssetPaths.icons.statusIcons.PARTIAL_REGISTRATION} alt={UserApprovalStatus.partialRegistration} />
        ) : (!params.row.status.emailVerified && !params.row.status.accountApproved) ? (
          <img src={AppAssetPaths.icons.statusIcons.PENDING} alt={UserApprovalStatus.pending} />
        ) : (!params.row.status.emailVerified && params.row.status.accountApproved) ? (
          <img src={AppAssetPaths.icons.statusIcons.UNVERIFIED_EMAIL} alt={UserApprovalStatus.unverified} />
        ) : (params.row.status.emailVerified && params.row.status.accountApproved) ? (
          <img src={AppAssetPaths.icons.statusIcons.APPROVED} alt={UserApprovalStatus.approved} />
        ) : (params.row.status.accountApproved === false) ? (
          <img src={AppAssetPaths.icons.statusIcons.REJECTED} alt={UserApprovalStatus.rejected} />
        ) : (
          <img src={AppAssetPaths.icons.statusIcons.PENDING} alt={UserApprovalStatus.pending} />
        )}
      </div>
    ),
  },
];

export const schoolAdminListColumns: GridColumns = [
  {
    headerName: 'Name',
    field: 'fullname',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    cellClassName: 'main-column',
  },
  {
    headerName: 'School',
    field: 'organization',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.organization?.name?.toLowerCase() || '-',
    cellClassName: 'org-column',
  },
  {
    headerName: 'Email',
    field: 'email',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Date Created',
    field: 'createdAt',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => dayjs(params.value as string).format('DD MMM YYYY'),
  },
];

export const districtAdminListColumns: GridColumns = [
  {
    headerName: 'Name',
    field: 'fullname',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    cellClassName: 'main-column',
  },
  {
    headerName: 'School District',
    field: 'organization',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.organization?.name?.toLowerCase() || '-',
    cellClassName: 'org-column',
  },
  {
    headerName: 'Email',
    field: 'email',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Date Created',
    field: 'createdAt',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => dayjs(params.value as string).format('DD MMM YYYY'),
  },
];

export const institutionAdminListColumns: GridColumns = [
  {
    headerName: 'Name',
    field: 'fullname',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    cellClassName: 'main-column',
  },
  {
    headerName: 'Organization Name',
    field: 'organization',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.organization?.name?.toLowerCase() || '-',
    cellClassName: 'org-column',
  },
  {
    headerName: 'Email',
    field: 'email',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Date Created',
    field: 'createdAt',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => dayjs(params.value as string).format('DD MMM YYYY'),
  },
];

export const communityAdminListColumns: GridColumns = [
  {
    headerName: 'Organization Name',
    field: 'organization',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.organization?.name?.toLowerCase() || '-',
    cellClassName: 'main-column org-column',
  },
  {
    headerName: 'Main Contact Name',
    field: 'fullname',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Email',
    field: 'email',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Date Created',
    field: 'createdAt',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => dayjs(params.value as string).format('DD MMM YYYY'),
  },
  {
    headerName: 'Status',
    field: 'status',
    flex: .8,
    renderCell: (params: GridRenderCellParams<boolean | undefined>) => (
      <div className="flex_acenter">
        {(params.row.status.emailVerified) ? (
          <img src={AppAssetPaths.icons.statusIcons.ACTIVE} alt={UserApprovalStatus.active} />
        ) : (
          <img src={AppAssetPaths.icons.statusIcons.UNVERIFIED_EMAIL} alt={UserApprovalStatus.unverified} />
        )}
      </div>
    ),
  },
];

export const deletedUserListColumns: GridColumns = [
  {
    headerName: 'Name',
    field: 'fullname',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    cellClassName: 'main-column',
  },
  {
    headerName: 'User Type',
    field: 'userType',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => startCase(params.value) || '-',
    sortable: false,
  },
  {
    headerName: 'Email',
    field: 'email',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Date Deleted',
    field: 'deletedAt',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => dayjs(params.value as string).format('DD MMM YYYY'),
  },
];

export const addPointsListColumns: GridColumns = [
  {
    headerName: 'Name',
    field: 'fullname',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    cellClassName: 'main-column',
    sortable: false,
  },
  {
    headerName: 'School',
    field: 'organization',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.organization?.name?.toLowerCase() || '-',
    cellClassName: 'org-column',
    sortable: false,
  },
  {
    headerName: 'Graduation Year',
    field: 'graduationYear',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    sortable: false,
  },
  {
    headerName: '',
    field: 'delete',
    flex: .1,
    align: 'center',
    renderCell: () => (
      <img src={AppAssetPaths.icons.TRASH} alt="delete" />
    ),
    sortable: false,
  },
];
