import React, {
  useEffect, useState
} from 'react';
import {
  FormProvider, SubmitHandler, useForm
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useNavigate } from 'react-router-dom';
import {
  Button,
  Pagination,
  Tab, Tabs,
  Menu, MenuItem
} from '@mui/material';
import { Box } from '@mui/system';
import {
  DataGrid,
  GridRowParams,
  GridSortModel
} from '@mui/x-data-grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import './ResourceList.scss';
import {
  AppAssetPaths,
  AppRoutes
} from '../../app/app.types';
import {
  useAppDispatch, useAppSelector
} from '../../app/hooks';
import { TableState } from '../../constants/tables/table.types';
import {
  opportunityListColumns, scholarshipListColumns
} from '../../constants/tables/resourceTable.types';
import { defaultMenuPaperProps } from '../../constants/menu.types';

import {
  getResourceQueryType,
  getResourceType,
  ResourceListQueryParams,
  ResourceType
} from '../../features/resources/resources.model';
import { resourceSelectors } from '../../features/resources/resources.slice';
import { getResourceList } from '../../features/resources/resources.thunks';
import { exportApplicationsCSV } from '../../features/exports/exports.api';
import { UserRole } from '../../features/user/user.model';

import {
  DownloadButton, RedTextButton
} from '../../components/CustomStyledComponents';
import SearchInput from '../../components/table/SearchInput';
import TableRowCount from '../../components/table/TableRowCount';
import ResourceFilter from './scholarships/ResourceFilter';
import { userSelectors } from '../../features/user/user.slice';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


interface ResourceListProps {
  initialTab: string;
}

const ResourceList: React.FC<ResourceListProps> = ({ initialTab }) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(userSelectors.selectUser);
  const resourceList = useAppSelector(resourceSelectors.selectResourceList);
  const resourceListCount = useAppSelector(resourceSelectors.selectResourceListCount);
  const resourceListLoaded = useAppSelector(resourceSelectors.selectResourceListLoaded);

  const [
    selectedTab,
    setSelectedTab,
  ] = useState<ResourceType>(initialTab as ResourceType);
  const [
    tableState,
    setTableState,
  ] = useState<TableState>({
    page: 1,
    pageSize: 100,
    columns: scholarshipListColumns,
    rows: [],
    loading: false,
  });
  const [
    resourceListQuery,
    setResourceListQuery,
  ] = useState<ResourceListQueryParams>({
    offset: 0,
    limit: undefined,
    role: initialTab,
    search: undefined,
    resourceType: getResourceType(initialTab),
    queryType: getResourceQueryType(initialTab),
    schoolDistricts: undefined,
    sortModel: undefined,
  });
  const [
    filterAnchorElement,
    setFilterAnchorElement,
  ] = useState<HTMLElement | null>(null);
  const [
    anchorElement,
    setAnchorElement,
  ] = useState<null | HTMLElement>(null);


  const changeColumns = () => {
    if (selectedTab === ResourceType.scholarship) {
      return scholarshipListColumns;
    }
    else {
      return opportunityListColumns;
    }
  };

  useEffect(() => {
    setSelectedTab(initialTab as ResourceType);
  }, [
    initialTab,
  ]);

  useEffect(() => {
    if (!resourceListLoaded) return;

    setResourceListQuery((prev) => ({
      ...prev,
      resourceType: getResourceType(selectedTab),
      queryType: getResourceQueryType(selectedTab),
    }));
  }, [
    selectedTab,
  ]);

  // Switching resource lists
  useEffect(() => {
    setTableState((prev) => ({
      ...prev,
      page: 1,
      columns: changeColumns(),
      rows: [],
      loading: true,
    }));

    dispatch(getResourceList({
      ...resourceListQuery,
      offset: 0,
    }));
  }, [
    resourceListQuery,
  ]);

  // Loading more resource data form pagination
  useEffect(() => {
    if (tableState.page < resourceList.length / tableState.pageSize || !resourceList.length || resourceList.length >= resourceListCount) return;

    setTableState((prev) => ({
      ...prev,
      loading: true,
    }));

    dispatch(getResourceList({
      ...resourceListQuery,
      offset: tableState.pageSize * (tableState.page - 1),
    }));
  },
  [
    tableState.page,
    tableState.pageSize,
  ]);

  useEffect(() => {
    setTableState((prev) => ({
      ...prev,
      loading: false,
      rows: resourceList,
    }));
  }, [
    resourceList,
  ]);


  const formValidationSchema = yup.object().shape({
    search: yup.string(),
  });

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const { handleSubmit } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    setResourceListQuery((prev) => ({
      ...prev,
      search: values.search,
    }));
  };

  const handleDetailsNavigation = (event: GridRowParams): void => {
    if (selectedTab === ResourceType.scholarship) {
      return navigate(AppRoutes.scholarshipDetails.path.replace(':scholarshipId', event.row.id.toString()), {
        // state: {
        //   applicantCount: event.row.numberApplied,
        // },
      });
    }
    else if (selectedTab === ResourceType.internship) {
      return navigate(AppRoutes.internshipDetails.path.replace(':resourceId', event.row.id.toString()), {
        state: {
          type: ResourceType.internship,
        },
      });
    }
    else {
      return navigate(AppRoutes.opportunityDetails.path.replace(':resourceId', event.row.id.toString()), {
        state: {
          type: ResourceType.opportunity,
        },
      });
    }
  };

  const handleRouteToCreatePage = (): void => {
    if (selectedTab === ResourceType.scholarship) {
      return navigate(AppRoutes.createResource.path.replace(':resourceType', ResourceType.scholarship), {
        state: {
          type: ResourceType.scholarship,
        },
      });
    }
    else if (selectedTab === ResourceType.internship) {
      return navigate(AppRoutes.createResource.path.replace(':resourceType', ResourceType.internship), {
        state: {
          type: ResourceType.internship,
        },
      });
    }
    else {
      return navigate(AppRoutes.createResource.path.replace(':resourceType', ResourceType.opportunity), {
        state: {
          type: ResourceType.opportunity,
        },
      });
    }
  };

  return (
    <div id="resource-list">
      <Box className="page-content card-background">
        <Tabs
          className="main-page-tabs"
          variant="fullWidth"
          value={selectedTab}
          onChange={(_, tabName) => setSelectedTab(tabName)}
        >
          <Tab label="Scholarships" value={ResourceType.scholarship} onClick={() => navigate(AppRoutes.scholarships.path)} />
          <Tab label="Internships" value={ResourceType.internship} onClick={() => navigate(AppRoutes.internships.path)} />
          <Tab label="Other Opportunities" value={ResourceType.opportunity} onClick={() => navigate(AppRoutes.opportunities.path)} />
        </Tabs>

        <div className="table-buttons-and-filters flex_jbetween">
          <div className="filters flex_acenter">
            <form className="content-container" onSubmit={handleSubmit(submitForm)}>
              <FormProvider {...methods}>
                <SearchInput
                  className="main-input search"
                  name="search"
                  onClear={handleSubmit(submitForm)}
                />
              </FormProvider>
            </form>
            {(selectedTab === ResourceType.scholarship || user.isAdmin) && (
              <div className="flex_acenter filter-button" onClick={(e) => setFilterAnchorElement(e.currentTarget)}>
                <img src={AppAssetPaths.icons.FILTER} />
                <p>Filter</p>
              </div>
            )}
          </div>


          <div className="flex_acenter">
            <RedTextButton
              variant="contained"
              endIcon={<ChevronRightIcon />}
              onClick={() => navigate(AppRoutes.resourceTags.path)}
              disableElevation
            >
              View Tags
            </RedTextButton>

            {selectedTab === ResourceType.scholarship && (
              <>
                <DownloadButton
                  variant="contained"
                  startIcon={<img src={AppAssetPaths.icons.DOWNLOAD} />}
                  endIcon={<ExpandMoreIcon />}
                  disableElevation
                  onClick={(e) => setAnchorElement(e.currentTarget)}
                >
                  Download
                </DownloadButton>
                <Menu className="flex_row_jcenter_acenter"
                  anchorEl={anchorElement}
                  open={Boolean(anchorElement)}
                  onClose={() => setAnchorElement(null)}
                  autoFocus={false}
                  PaperProps={defaultMenuPaperProps}
                >
                  <MenuItem onClick={() => (setAnchorElement(null), exportApplicationsCSV())}>
                    All Applications
                  </MenuItem>
                  <MenuItem onClick={() => (setAnchorElement(null), exportApplicationsCSV(UserRole.middleSchoolStudent))}>
                    Middle / Jr. High
                  </MenuItem>
                  <MenuItem onClick={() => (setAnchorElement(null), exportApplicationsCSV(UserRole.highSchoolStudent))}>
                    High School
                  </MenuItem>
                  <MenuItem onClick={() => (setAnchorElement(null), exportApplicationsCSV(UserRole.adult))}>
                    Adults
                  </MenuItem>
                </Menu>
              </>
            )}

            <Button
              onClick={() => handleRouteToCreatePage()}
              variant="contained"
              disableElevation
            >
              New {selectedTab}
            </Button>
          </div>
        </div>

        <DataGrid
          className="table"
          {...tableState}
          components={{
            Pagination: Pagination,
          }}
          componentsProps={{
            pagination: {
              page: tableState.page,
              count: Math.ceil(resourceListCount / tableState.pageSize),
              onChange: (_: void, page: number) => {
                setTableState((prev) => ({
                  ...prev,
                  page,
                }));
              },

            },
          }}
          onRowClick={(event) => handleDetailsNavigation(event)}
          pagination
          paginationMode="server"
          sortingMode="server"
          sortModel={resourceListQuery.sortModel}
          onSortModelChange={(newSortModel: GridSortModel) => {
            setResourceListQuery((prev) => ({
              ...prev,
              sortModel: newSortModel,
            }));
          }}
          disableColumnFilter
          disableColumnMenu
          hideFooterSelectedRowCount
        />

        <TableRowCount
          length={resourceList.length}
          pageSize={tableState.pageSize}
          page={tableState.page}
          totalCount={resourceListCount}
        />
      </Box>

      {/* Filter Menu */}
      <ResourceFilter
        resourceType={selectedTab}
        anchorElement={filterAnchorElement}
        setAnchorElement={setFilterAnchorElement}
        setFiltersCallback={(queryType?: string, schoolDistricts?: number[]) => {
          setResourceListQuery((prev) => ({
            ...prev,
            queryType: selectedTab === ResourceType.scholarship ? queryType : queryType ? getResourceQueryType(queryType) : undefined,
            schoolDistricts,
          }));
        }}
      />
    </div>
  );
};

export default ResourceList;
