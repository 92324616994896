/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  ConstructEmptyPathwayCourseByType, PathwayCourse, PathwayCourseType
} from '../../../../features/pathways/pathways.model';
import ExpandableCard from '../../../../components/details/ExpandableCard';
import TextInput from '../../../../components/forms/TextInput';
import DropdownSelect from '../../../../components/forms/DropdownSelect';
import { yesNoDropdownList } from '../../../../util/dropdown';
import { AppAssetPaths } from '../../../../app/app.types';
import { Divider } from '@mui/material';
import { useAppSelector } from '../../../../app/hooks';
import { userSelectors } from '../../../../features/user/user.slice';

import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


interface ExplorerCourseFormProps {
  explorerCourseFields: any;
  errors: any;
  appendCourseCallback: (course: PathwayCourse) => void;
  removeCourseCallback: (fieldIndex: number, courseId?: number, ) => void;
  updateCourseCallback: (fieldIndex: number, course: Partial<PathwayCourse>) => void;
  getValuesCallback: (fieldName: string) => any;
}

const ExplorerCourseForm: React.FC<ExplorerCourseFormProps> = ({
  explorerCourseFields,
  errors,
  appendCourseCallback,
  removeCourseCallback,
  updateCourseCallback,
  getValuesCallback,
}) => {

  const user = useAppSelector(userSelectors.selectUser);

  return (
    <ExpandableCard
      className="course-form"
      summary={<span>Explorer Courses</span>}
      details={
        <div className="course-container">
          {explorerCourseFields.map((c: PathwayCourse, i: number) => (
            <div key={c.id}>
              {i > 0 && <Divider variant="middle" className="divider course-divider" />}
              <div className="flex_jbetween_acenter">
                <p className="course-number">Course {i+1}</p>
                <div className="course-control flex">
                  <span
                    className="hide-button flex_acenter"
                    onClick={() => updateCourseCallback(i, {
                      ...explorerCourseFields[i],
                      name: getValuesCallback(`explorerCourses.${i}.name`),
                      link: getValuesCallback(`explorerCourses.${i}.link`),
                      credits: getValuesCallback(`explorerCourses.${i}.credits`),
                      concurrentEnrollment: getValuesCallback(`explorerCourses.${i}.concurrentEnrollment`),
                      recommendedExplorerCourse: getValuesCallback(`explorerCourses.${i}.recommendedExplorerCourse`),
                      alternateCourseName: getValuesCallback(`explorerCourses.${i}.alternateCourseName`),
                      active: !explorerCourseFields[i].active,
                    })}
                  >
                    {explorerCourseFields[i].active ? (
                      <div className="flex_acenter">
                        <p>Hide Course</p>
                        <VisibilityIcon className="eye-icon" />
                      </div>
                    ) : (
                      <div className="flex_acenter">
                        <p>Show Course</p>
                        <VisibilityOffIcon className="eye-icon" />
                      </div>
                    )}
                  </span>
                  {user.isAdmin && (
                    <span
                      className="remove-button flex_acenter"
                      onClick={() => removeCourseCallback(i, explorerCourseFields[i].courseId)}
                    >
                      <p>Remove Course</p>
                      <img className="trash-icon" src={AppAssetPaths.icons.TRASH_RED} alt="delete" />
                    </span>
                  )}
                </div>
              </div>
              <div className="form-content flex_row_jbetween">
                <div className="form-column">
                  <TextInput
                    name={`explorerCourses.${i}.name` as any}
                    label="COURSE NAME"
                    errorMessage={errors?.explorerCourses?.[i]?.name?.message}
                    type="text"
                    size="small"
                    disabled={user.isDistrictAdmin || user.isHighSchoolAdmin}
                  />
                  <TextInput
                    name={`explorerCourses.${i}.credits` as any}
                    label="CREDITS"
                    errorMessage={errors?.explorerCourses?.[i]?.credits?.message}
                    type="number"
                    InputProps={{
                      inputProps: {
                        min: 0,
                        step: 0.5,
                      },
                    }}
                    size="small"
                    disabled={user.isDistrictAdmin || user.isHighSchoolAdmin}
                  />
                  <DropdownSelect
                    name={`explorerCourses.${i}.recommendedExplorerCourse` as any}
                    label="RECOMMENDED EXPLORER COURSE"
                    itemList={yesNoDropdownList}
                    errorMessage={errors?.explorerCourses?.[i]?.recommendedExplorerCourse?.message}
                    size="small"
                    disabled={user.isDistrictAdmin || user.isHighSchoolAdmin}
                  />
                </div>
                <div className="form-column">
                  <TextInput
                    name={`explorerCourses.${i}.link` as any}
                    label="LINK"
                    errorMessage={errors?.explorerCourses?.[i]?.link?.message}
                    type="text"
                    size="small"
                    disabled={user.isDistrictAdmin || user.isHighSchoolAdmin}
                  />
                  <DropdownSelect
                    name={`explorerCourses.${i}.concurrentEnrollment` as any}
                    label="CONCURRENT ENROLLMENT"
                    itemList={yesNoDropdownList}
                    errorMessage={errors?.explorerCourses?.[i]?.concurrentEnrollment?.message}
                    size="small"
                  />
                  {(user.isDistrictAdmin || user.isHighSchoolAdmin) && (
                    <TextInput
                      name={`explorerCourses.${i}.alternateCourseName` as any}
                      label="ALTERNATIVE COURSE NAME"
                      type="text"
                      size="small"
                      tooltip="If this field is filled out, the text here will replace the the original course name for your school."
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
          {user.isAdmin && (
            <span
              className="add-button flex_acenter"
              onClick={() => appendCourseCallback(ConstructEmptyPathwayCourseByType(PathwayCourseType.explorer))}
            >
              <AddIcon className="add-icon" />
              <p>Add Course</p>
            </span>
          )}
        </div>
      }
      expandable
    />
  );
};

export default ExplorerCourseForm;
