import { AuthComponent } from '../../../app/app.types';
import { ResourceType } from '../../resources/resources.model';
import { UserRole } from '../../user/user.model';


export interface ResourceApplicantsTab extends AuthComponent {
  title: string;
  resourceType: string;
}

export const DashboardResourceApplicants : AuthComponent = {
  authorizedRoles: [
    UserRole.districtAdmin,
    UserRole.institutionAdmin,
    UserRole.community,
  ],
};

export const ResourceApplicantsTabs: ResourceApplicantsTab[] = [
  {
    title: 'Scholarships',
    resourceType: ResourceType.scholarship,
    authorizedRoles: [
      UserRole.districtAdmin,
      UserRole.institutionAdmin,
      UserRole.community,
    ],
  },
  {
    title: 'Internships',
    resourceType: ResourceType.internship,
    authorizedRoles: [
      UserRole.districtAdmin,
      UserRole.institutionAdmin,
      UserRole.community,
    ],
  },
  {
    title: 'Opportunities',
    resourceType: ResourceType.opportunity,
    authorizedRoles: [
      UserRole.districtAdmin,
      UserRole.institutionAdmin,
      UserRole.community,
    ],
  },
];
