

import React, {
  useState, useEffect, useRef
} from 'react';
import { useParams } from 'react-router-dom';
import OverviewTable from '../../components/details/user/OverviewTable';
import ApplicationsTable from '../../components/details/user/ApplicationsTable';
import { Scholarship } from '../../features/scholarship/scholarship.model';
import { Resource } from '../../features/resources/resources.model';
import {
  adminGetAssessmentResultsById,
  adminGetUserById,
  adminGetUserCareers,
  adminGetUserNotifications,
  adminGetUserPathways
} from '../../features/user/user.api';
import {
  ConstructUserOverview,
  User, UserOverview
} from '../../features/user/user.model';

import OpportunitiesTable from '../../components/details/user/OpportunitiesTable';
import { Career } from '../../features/careers/careers.model';
import CareersTable from '../../components/details/user/CareersTable';
import { UserPathway } from '../../features/pathways/userPathways.model';
import PathwaysTable from '../../components/details/user/PathwaysTable';
import NotificationsTable from '../../components/details/user/NotificationsTable';
import AssessmentsTable from '../../components/details/user/AssessmentsTable';
import {
  AssessmentsData, EmptyAssessmentsData
} from '../../features/assessments/assessments.model';
import { Notification } from '../../features/notifications/notifications.model';
import { useReactToPrint } from 'react-to-print';
import './UserDetails.scss';


interface UserDetailsProps { }

const UserDetails: React.FC<UserDetailsProps> = () => {

  const params = useParams();

  const [
    targetUser,
    setTargetUser,
  ] = useState<User>();
  const [
    userOverview,
    setUserOverview,
  ] = useState<UserOverview>();
  const [
    userApplications,
    setUserApplications,
  ] = useState<Scholarship[]>([]);
  const [
    userOpportunities,
    setUserOpportunities,
  ] = useState<Resource[]>([]);
  const [
    userCareers,
    setUserCareers,
  ] = useState<Career[]>([]);
  const [
    userPathways,
    setUserPathways,
  ] = useState<UserPathway[]>([]);
  const [
    userNotifications,
    setUserNotifications,
  ] = useState<Notification[]>([]);
  const [
    userAssessments,
    setUserAssessments,
  ] = useState<AssessmentsData>(EmptyAssessmentsData);

  // get all the user details we can
  useEffect(() => {
    if(!params || !params.userId){
      return;
    }

    const id = parseInt(params.userId);

    const getUserData = async () => {
      const rUser = await adminGetUserById(id);
      const overview = await ConstructUserOverview(rUser);

      setTargetUser(rUser);
      setUserOverview(overview);

      if(overview.isStudent){
        const careers = await adminGetUserCareers(id);
        const pathways = await adminGetUserPathways(id);
        const notifications = await adminGetUserNotifications(id);
        const assessments = await adminGetAssessmentResultsById(id);

        setUserCareers(careers || []);
        setUserPathways(pathways || []);
        setUserNotifications(notifications || []);
        setUserAssessments(assessments);
        setUserApplications(rUser.scholarships || []);
        setUserOpportunities(rUser.opportunities || []);
      }
    };

    getUserData();
  }, []);

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    documentTitle: `${targetUser?.firstname}_${targetUser?.lastname}_user_details`,
    content: () => componentRef.current,
  });

  const refreshUserDetail = async () => {
    if (!params.userId) return;

    const id = parseInt(params.userId);
    const rUser = await adminGetUserById(id);
    setTargetUser(rUser);
  };

  return (
    <div id="user-details">
      <OverviewTable
        user={targetUser}
        overview={userOverview}
        refreshUserDetail={refreshUserDetail}
        printPageCallback={handlePrint}
      />
      {userOverview?.isStudent && (
        <div className="colapsable-sections">
          <ApplicationsTable applications={userApplications} />
          <OpportunitiesTable opportunities={userOpportunities} />
          <CareersTable careers={userCareers} />
          <PathwaysTable pathways={userPathways} />
          <NotificationsTable notifications={userNotifications} />
          <AssessmentsTable assessments={userAssessments} />
        </div>
      )}

      {/* Print components - These are hidden in the dom, but are needed to show expanded data in the print dialog */}
      <div className="print-componenets">
        <div ref={componentRef}>
          <OverviewTable
            user={targetUser}
            overview={userOverview}
            refreshUserDetail={refreshUserDetail}
            printPageCallback={handlePrint}
          />

          {userOverview?.isStudent && (
            <div className="colapsable-sections">
              <ApplicationsTable applications={userApplications} defaultExpanded />
              <OpportunitiesTable opportunities={userOpportunities} defaultExpanded />
              <CareersTable careers={userCareers} defaultExpanded />
              <PathwaysTable pathways={userPathways} defaultExpanded />
              <NotificationsTable notifications={userNotifications} defaultExpanded />
              <AssessmentsTable assessments={userAssessments} defaultExpanded />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
