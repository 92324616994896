import React, { useState } from 'react';
import './QuickLinksCard.scss';
import { useAppSelector } from '../../app/hooks';
import { userSelectors } from '../../features/user/user.slice';
import {
  DashboardQuickLinks, QuickLink, QuickLinkMenuItem
} from '../../features/dashboard/types/quickLink.types';
import OpenInNew from '@mui/icons-material/OpenInNew';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { canUseAuthComponent } from '../../app/app.types';


interface QuickLinksCardProps { }

const QuickLinksCard: React.FC<QuickLinksCardProps> = () => {

  const user = useAppSelector(userSelectors.selectUser);

  const [
    anchorElement,
    setAnchorElement,
  ] = useState<null | HTMLElement>(null);

  return (
    <div id="quicklinks-card" className="flex_col card-background">
      <span className="card-header flex_row_aend">
        <OpenInNew />
        <h3>Quick Links</h3>
      </span>
      <div className="tab-content">
        {DashboardQuickLinks.map((link: QuickLink, i: number) => (
          canUseAuthComponent(user.roles, link.authorizedRoles) && (
            <a className="quick-link flex_row_jcenter_acenter"
              href={link.route && link.route}
              target="_blank"
              rel="noreferrer noopener"
              key={i}
              onMouseUp={(e) => link.title === 'CIS360' && setAnchorElement(e.currentTarget)}
            >
              {link.title}
              {link.menu && !!link.menu.length && (
                <Menu className="quick-link flex_row_jcenter_acenter"
                  anchorEl={anchorElement}
                  open={Boolean(anchorElement)}
                  onClose={() => setAnchorElement(null)}
                  autoFocus={false}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(2px 3px 5px rgba(174, 174, 174, 0.25))',
                      borderRadius: '6px',
                    },
                  }}
                  transformOrigin={{
                    horizontal: 'center',
                    vertical: 'top',
                  }}
                  anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                  }}
                >
                  {link.menu.map((item: QuickLinkMenuItem, m: number) => (
                    canUseAuthComponent(user.roles, item.authorizedRoles) && (
                      <MenuItem
                        onClick={() => {
                          item.onClick();
                          setAnchorElement(null);
                        }}
                        key={m}
                      >
                        {item.title}
                      </MenuItem>
                    )))}
                </Menu>
              )}
            </a>
          )))}
      </div>
    </div >
  );
};

export default QuickLinksCard;
