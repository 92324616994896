export const formatPhone = (phone: string): string => {
  const phoneArr = phone.replace(/-/g, '').split('');

  const newPhoneArr = phoneArr.map((el, i) => {
    if (i === 2 || i === 5) {
      return el + '-';
    }
    return el;
  });

  return newPhoneArr.join('');
};

export const numberOnlyValidation = (event: React.KeyboardEvent): void => {
  const pattern = /[0-9]/;
  const inputChar = String.fromCharCode(event.charCode);

  if (!pattern.test(inputChar)) {
    // invalid character, prevent input
    event.preventDefault();
  }
};

export const letterOnlyValidation = (event: React.KeyboardEvent): void => {
  const pattern = /^[aA-zZ\s]+$/;
  const inputChar = String.fromCharCode(event.charCode);

  if (!pattern.test(inputChar)) {
    // invalid character, prevent input
    event.preventDefault();
  }
};

export const addUrlProtocol = (url?: string): string | undefined => {
  if (!url) return;

  return !/^https?:\/\//i.test(url) ? `//${url}` : url;
};
