/* eslint-disable react/display-name */
import React from 'react';
import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
  GridValueFormatterParams,
  GridValueGetterParams
} from '@mui/x-data-grid';
import { AppAssetPaths } from '../../app/app.types';
import dayjs from 'dayjs';
import { Tooltip } from '@mui/material';
import { store } from '../../app/store';
import {
  togglePathwayActive,
  togglePathwayActiveForSchool
} from '../../features/pathways/pathways.thunks';


export enum FeatureTabs {
  pathways = 'CTE Pathway',
  skillTrainings = 'Skill Training',
  careerClusters = 'Career Cluster',
  skills = 'Skills',
}

export const superAdminPathwayListColumns: GridColumns = [
  {
    headerName: 'Pathway',
    field: 'name',
    flex: 3,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    cellClassName: 'main-column',
  },
  {
    headerName: 'Career Cluster',
    field: 'pathwayCluster',
    flex: 3,
    valueGetter: (params: GridValueGetterParams) => params.row.pathwayCluster?.label || '-',
  },
  {
    headerName: 'Date Created',
    field: 'createdAt',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => dayjs(params.value as string).format('MM/DD/YYYY'),
  },
  {
    headerName: 'Status',
    field: 'status',
    flex: 1,
    headerAlign: 'left',
    align: 'left',
    sortable: true,
    type: 'actions',
    getActions: (params: GridRowParams) => [
      (params.row.active) ? (
        <GridActionsCellItem
          label="active"
          icon={
            <Tooltip title="Click to set to inactive" arrow>
              <img src={AppAssetPaths.icons.statusIcons.ACTIVE} alt="active" />
            </Tooltip>
          }
          onClick={() => store.dispatch(togglePathwayActive(params.row.id))}
          disableRipple
        />
      ) : (
        <GridActionsCellItem
          label="inactive"
          icon={
            <Tooltip title="Click to set to active" arrow>
              <img src={AppAssetPaths.icons.statusIcons.INACTIVE} alt="inactive" />
            </Tooltip>
          }
          onClick={() => store.dispatch(togglePathwayActive(params.row.id))}
          disableRipple
        />
      ),
    ],
  },
];

export const schoolAdminPathwayListColumns: GridColumns = [
  {
    headerName: 'Pathway',
    field: 'name',
    flex: 3,
    renderCell: (params: GridRenderCellParams<boolean | undefined>) => (
      <div className="flex_acenter">
        <p>{params.value}</p>
        {(params.row.updatedByDistrictAdmin) && (
          <img className="table-update-icon" src={AppAssetPaths.icons.statusIcons.UPDATE} alt="update" />
        )}
      </div>
    ),
    cellClassName: 'main-column',
  },
  {
    headerName: 'Career Cluster',
    field: 'pathwayCluster',
    flex: 3,
    valueGetter: (params: GridValueGetterParams) => params.row.pathwayCluster?.label || '-',
  },
  {
    headerName: 'Date Created',
    field: 'createdAt',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => dayjs(params.value as string).format('MM/DD/YYYY'),
  },
  {
    headerName: 'Status',
    field: 'status',
    flex: 1,
    headerAlign: 'left',
    align: 'left',
    sortable: true,
    type: 'actions',
    getActions: (params: GridRowParams) => [
      (!params.row.pathwaySchool || params.row.pathwaySchool.isHidden) ? (
        <GridActionsCellItem
          className="status-column-button"
          label="inactive"
          icon={
            <Tooltip title="Click to set to active for this school." arrow>
              <img src={AppAssetPaths.icons.statusIcons.INACTIVE} alt="inactive" />
            </Tooltip>
          }
          onClick={() => store.dispatch(togglePathwayActiveForSchool({
            id: params.row.id,
            isHidden: false,
            organizationId: params.row.pathwaySchool.organization_id,
          }))}
          disableRipple
        />
      ) : (
        <GridActionsCellItem
          className="status-column-button"
          label="inactive"
          icon={
            <Tooltip title="Click to set to inactive for this school" arrow>
              <img src={AppAssetPaths.icons.statusIcons.ACTIVE} alt="active" />
            </Tooltip>
          }
          onClick={() => store.dispatch(togglePathwayActiveForSchool({
            id: params.row.id,
            isHidden: true,
            organizationId: params.row.pathwaySchool.organization_id,
          }))}
          disableRipple
        />
      ),
    ],
  },
];

export const readOnlySchoolPathwayListColumns: GridColumns = [
  {
    headerName: 'Pathway',
    field: 'name',
    flex: 3,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    cellClassName: 'main-column',
  },
  {
    headerName: 'Career Cluster',
    field: 'pathwayCluster',
    flex: 3,
    valueGetter: (params: GridValueGetterParams) => params.row.pathwayCluster?.label || '-',
  },
  {
    headerName: 'Date Created',
    field: 'createdAt',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => dayjs(params.value as string).format('MM/DD/YYYY'),
  },
  {
    headerName: 'Status',
    field: 'status',
    flex: 1,
    renderCell: (params: GridRenderCellParams<boolean | undefined>) => (
      <div className="flex_acenter">
        {(!params.row.pathwaySchool || params.row.pathwaySchool.isHidden) ? (
          <img src={AppAssetPaths.icons.statusIcons.INACTIVE} alt="inactive" />
        ) : (
          <img src={AppAssetPaths.icons.statusIcons.ACTIVE} alt="active" />
        )}
      </div>
    ),
  },
];
