import React from 'react';


interface TableRowCountProps {
  length: number,
  pageSize: number,
  page: number,
  totalCount: number
}

const TableRowCount: React.FC<TableRowCountProps> = ({ length, pageSize, page, totalCount }) => {

  return (
    <div>
      {!!length && (
        <p className="row-count">
          {(pageSize * (page - 1)) + 1}-{(pageSize * (page - 1)) + length} of {totalCount}
        </p>
      )}
    </div>
  );
};

export default TableRowCount;
