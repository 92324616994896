import {
  AppLinks, AppRoutes, AuthComponent
} from '../../../app/app.types';
import { addUrlProtocol } from '../../../util/formatting';
import { ssoLogin } from '../../careers/careers.api';
import { UserRole } from '../../user/user.model';


export interface QuickLinkMenuItem extends AuthComponent {
  title: string;
  onClick: () => void;
}

export interface QuickLink extends AuthComponent {
  title: string;
  route?: string;
  menu?: QuickLinkMenuItem[];
}

export const DashboardQuickLinks: QuickLink[] = [
  {
    title: 'App Inquiries',
    route: addUrlProtocol(`${window.location.host}${AppRoutes.inquiries.path}`),
    authorizedRoles: AppRoutes.inquiries.authorizedRoles,
  },
  {
    title: 'Ticket Manager',
    route: AppLinks.TICKET_MANAGER_LOGIN,
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  {
    title: 'New User',
    route: addUrlProtocol(`${window.location.host}${AppRoutes.newUser.path.replace(':userType', UserRole.student)}`),
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  {
    title: 'CIS360',
    menu: [
      {
        title: 'Student Access',
        onClick: () => ssoLogin(UserRole.student),
        authorizedRoles: [
          UserRole.admin,
          UserRole.districtAdmin,
          UserRole.middleSchoolKeyContact,
          UserRole.middleSchoolCounselor,
          UserRole.highSchoolKeyContact,
          UserRole.highSchoolCounselor,
        ],
      },
      {
        title: 'Adult Access',
        onClick: () => ssoLogin(UserRole.adult),
        authorizedRoles: [
          UserRole.admin,
          UserRole.districtAdmin,
          UserRole.adultKeyContact,
          UserRole.adultCounselor,
          UserRole.universityKeyContact,
          UserRole.universityCounselor,
        ],
      },
    ],
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.keyContact,
      UserRole.counselor,
    ],
  },
  {
    title: 'Website Inquiries',
    route: AppLinks.WEBSITE_INQUIRIES,
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  {
    title: 'Metabase',
    route: AppLinks.METABASE_LOGIN,
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.keyContact,
      UserRole.counselor,
      UserRole.community,
    ],
  },
  {
    title: 'KTS Resources',
    route: AppLinks.KTS_RESOURCES,
    authorizedRoles: [
      UserRole.districtAdmin,
      UserRole.keyContact,
      UserRole.counselor,
    ],
  },
  {
    title: 'Planning Guide',
    route: AppLinks.PLANNING_GUIDE,
    authorizedRoles: [
      UserRole.districtAdmin,
      UserRole.keyContact,
      UserRole.counselor,
    ],
  },
  {
    title: 'Google Drive',
    route: AppLinks.KTS_GOOGLE_DRIVE,
    authorizedRoles: [
      UserRole.districtAdmin,
      UserRole.keyContact,
      UserRole.counselor,
    ],
  },
  {
    title: 'Contact KTS',
    route: AppLinks.KTS_CONTACT_PAGE,
    authorizedRoles: [
      UserRole.districtAdmin,
      UserRole.keyContact,
      UserRole.counselor,
    ],
  },
  {
    title: 'Student App Login',
    route: AppLinks.STUDENT_APP_GUEST_SIGNIN,
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.keyContact,
      UserRole.counselor,
    ],
  },

];
