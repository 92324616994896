/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/display-name */
import {
  GridColumns, GridRenderCellParams, GridValueFormatterParams
} from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { AppAssetPaths } from '../../app/app.types';

export const superAdminSkillTrainingsListColumns: GridColumns = [
  {
    headerName: 'Course Title',
    field: 'name',
    flex: 3,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    cellClassName: 'main-column',
  },
  {
    headerName: 'Company',
    field: 'company',
    flex: 3,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Date Created',
    field: 'createdAt',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => (params.value) ? dayjs(params.value as string).format('MM/DD/YYYY') : '-',
  },
  {
    headerName: 'Status',
    field: 'active',
    flex: 1,
    renderCell: (params: GridRenderCellParams<boolean | undefined>) => (
      <div className="flex_acenter">
        {params.row.approved ? (
          <>
            {params.row.active ?
              <img src={AppAssetPaths.icons.statusIcons.ACTIVE} alt="active" /> :
              <img src={AppAssetPaths.icons.statusIcons.INACTIVE} alt="inactive" />
            }
          </>
        ) : (
          <img src={AppAssetPaths.icons.statusIcons.PENDING} alt="Pending" />
        )}
      </div>
    ),
  },
];

export const SkillsListColumns: GridColumns = [
  {
    headerName: 'Skill',
    field: 'label',
    flex: 3,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    cellClassName: 'main-column',
  },
  {
    headerName: 'Date Created',
    field: 'createdAt',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => dayjs(params.value as string).format('DD MMM YYYY') || '-',
  },
  {
    headerName: 'Status',
    field: 'status',
    flex: .5,
    renderCell: (params: GridRenderCellParams<boolean | undefined>) => (
      <div className="flex_acenter">
        {(params.row.active) ? (
          <img src={AppAssetPaths.icons.statusIcons.ACTIVE} alt="active" />
        ) : (
          <img src={AppAssetPaths.icons.statusIcons.INACTIVE} alt="inactive" />
        )}
      </div>
    ),
  },
];
