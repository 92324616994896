import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApprovalListQueryParams } from './approvals.model';
import * as approvalApi from './approvals.api';


export const getStudentApprovalList = createAsyncThunk(
  'approvals/getStudentApprovalList',
  async (query: ApprovalListQueryParams) => {
    return await approvalApi.getStudentApprovalList(query);
  }
);

export const getTransferApprovalList = createAsyncThunk(
  'approvals/getTransferApprovalList',
  async (query: ApprovalListQueryParams) => {
    return await approvalApi.getTransferApprovalList(query);
  }
);

export const getNotificationApprovalList = createAsyncThunk(
  'approvals/getNotificationApprovalList',
  async (query: ApprovalListQueryParams) => {
    return await approvalApi.getNotificationApprovalList(query);
  }
);

export const getSkillTrainingApprovalList = createAsyncThunk(
  'approvals/getSkillTrainingApprovalList',
  async (query: ApprovalListQueryParams) => {
    return await approvalApi.getSkillTrainingApprovalList(query);
  }
);

export const getResourceApprovalList = createAsyncThunk(
  'approvals/getResourceApprovalList',
  async (query: ApprovalListQueryParams) => {
    return await approvalApi.getResourceApprovalList(query);
  }
);

export const getApplicationApprovalList = createAsyncThunk(
  'approvals/getApplicationApprovalList',
  async (query: ApprovalListQueryParams) => {
    return await approvalApi.getApplicationApprovalList(query);
  }
);
