import { createSlice } from '@reduxjs/toolkit';
import { StateStatus } from '../../app/app.types';
import { RootState } from '../../app/store';
import { Inquiry } from './inquiries.model';
import { getInquiryList } from './inquiries.thunks';



export interface InquiryState {
  inquiryList: {
    status: StateStatus;
    data: Inquiry[];
    totalCount: number;
    loaded: boolean;
  }
}

const initialState: InquiryState = {
  inquiryList: {
    status: StateStatus.IDLE,
    data: [],
    totalCount: 0,
    loaded: false,
  },
};


export const inquirySlice = createSlice({
  name: 'inquiries',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInquiryList.pending, (state) => {
        state.inquiryList.status = StateStatus.LOADING;
        state.inquiryList.loaded = false;
      })
      .addCase(getInquiryList.fulfilled, (state, action) => {
        state.inquiryList.status = StateStatus.IDLE;
        state.inquiryList.data = action.payload.inquiries;
        state.inquiryList.totalCount = action.payload.count;
        state.inquiryList.loaded = true;
      })
      .addCase(getInquiryList.rejected, (state) => {
        state.inquiryList.status = StateStatus.FAILED;
        state.inquiryList.loaded = false;
      });
  },
});


const selectInquiryList = (state: RootState): Inquiry[] => state.inquiry.inquiryList.data;
const selectInquiryListCount = (state: RootState): number => state.inquiry.inquiryList.totalCount;

export const inquirySelectors = {
  selectInquiryList,
  selectInquiryListCount,
};

export default inquirySlice.reducer;