import { createAsyncThunk } from '@reduxjs/toolkit';
import * as dashboardApi from './dashboard.api';

export const getApprovalsSummary = createAsyncThunk(
  '/dashboard/approvalsSummary',
  async (userRoles: string[]) => {
    return await dashboardApi.getApprovalsSummary(userRoles);
  }
);

export const getScholarshipsBySchool = createAsyncThunk(
  '/dashboard/scholarshipsBySchool',
  async () => {
    return await dashboardApi.getScholarshipsBySchool();
  }
);

export const getLatestAnnouncements = createAsyncThunk(
  '/dashboard/latestAnnouncements',
  async () => {
    return await dashboardApi.getLatestAnnouncements();
  }
);

export const getExpiringResources = createAsyncThunk(
  '/dashboard/expiringResources',
  async () => {
    return await dashboardApi.getExpiringResources();
  }
);

export const getCurrentMasterKeys = createAsyncThunk(
  '/dashboard/currentMasterKeys',
  async () => {
    return await dashboardApi.getCurrentMasterKey();
  }
);

export const getAdminAnnouncement = createAsyncThunk(
  '/dashboard/adminAnnouncement',
  async () => {
    return await dashboardApi.getAdminAnnouncement();
  }
);

export const setLastSeenAnnouncementId = createAsyncThunk(
  '/dashboard/adminAnnouncementSeen',
  async (announcementId: number) => {
    return await dashboardApi.setLastSeenAnnouncementId(announcementId);
  }
);
