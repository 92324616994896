import React, {
  useState,
  useEffect
} from 'react';
import './OrganizationList.scss';
import { useNavigate } from 'react-router-dom';
import {
  FormProvider,
  SubmitHandler,
  useForm
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  DataGrid,
  GridSortModel
} from '@mui/x-data-grid';
import {
  Box,
  Button,
  Pagination,
  Tab,
  Tabs
} from '@mui/material';
import {
  AppAssetPaths,
  AppRoutes,
  canUseAuthComponent
} from '../../app/app.types';
import {
  useAppDispatch,
  useAppSelector
} from '../../app/hooks';
import { TableState } from '../../constants/tables/table.types';
import {
  schoolListColumns,
  communityListColumns
} from '../../constants/tables/organizationTable.types';
import { userSelectors } from '../../features/user/user.slice';
import { organizationSelectors } from '../../features/organization/organization.slice';
import {
  OrganizationListQueryParams,
  OrganizationType
} from '../../features/organization/organization.model';
import { getOrganizationList } from '../../features/organization/organization.thunks';
import SearchInput from '../../components/table/SearchInput';
import SchoolFilter from './SchoolFilter';
import { DownloadButton } from '../../components/CustomStyledComponents';
import {
  exportCommunityOrganizationsCSV, exportSchoolsCSV
} from '../../features/exports/exports.api';

import './OrganizationList.scss';

interface OrganizationListProps {
  initialTab: string;
}

const OrganizationList: React.FC<OrganizationListProps> = ({ initialTab }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(userSelectors.selectUser);
  const organizationList = useAppSelector(organizationSelectors.selectOrganizationList);
  const totalOrganizationListCount = useAppSelector(organizationSelectors.selectTotalOrganizationListCount);
  const organizationListLoaded = useAppSelector(organizationSelectors.selectOrganizationListLoaded);

  const [
    selectedTab,
    setSelectedTab,
  ] = useState(initialTab);
  const [
    tableState,
    setTableState,
  ] = useState<TableState>({
    page: 1,
    pageSize: 100,
    columns: schoolListColumns,
    rows: [],
    loading: false,
  });
  const [
    organizationListQuery,
    setOrganizationListQuery,
  ] = useState<OrganizationListQueryParams>({
    offset: 0,
    limit: undefined,
    type: initialTab,
    search: undefined,
    schoolType: undefined,
    schoolDistricts: undefined,
    sortModel: undefined,
  });
  const [
    filterAnchorElement,
    setFilterAnchorElement,
  ] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setSelectedTab(initialTab);
  }, [
    initialTab,
  ]);

  useEffect(() => {
    if (!organizationListLoaded) return;

    setOrganizationListQuery((prev) => ({
      ...prev,
      type: selectedTab,
    }));
  }, [
    selectedTab,
  ]);

  // Switching user lists
  useEffect(() => {
    setTableState((prev) => ({
      ...prev,
      page: 1,
      columns: changeColumns(),
      rows: [],
      loading: true,
    }));

    dispatch(getOrganizationList({
      ...organizationListQuery,
      offset: 0,
    }));
  }, [
    organizationListQuery,
  ]);

  useEffect(() => {
    if (tableState.page < organizationList.length / tableState.pageSize || !organizationList.length || organizationList.length >= totalOrganizationListCount) return;

    setTableState((prev) => ({
      ...prev,
      loading: true,
    }));

    dispatch(getOrganizationList({
      ...organizationListQuery,
      offset: tableState.pageSize * (tableState.page - 1),
    }));
  }, [
    tableState.page,
    tableState.pageSize,
  ]);

  useEffect(() => {
    setTableState((prev) => ({
      ...prev,
      loading: false,
      rows: organizationList,
    }));
  }, [
    organizationList,
  ]);

  const changeColumns = () => {
    if (selectedTab === OrganizationType.School) {
      return schoolListColumns;
    }
    else if (selectedTab === OrganizationType.Community) {
      return communityListColumns;
    }

    return schoolListColumns;
  };

  const formValidationSchema = yup.object().shape({
    search: yup.string(),
  });

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const { handleSubmit } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    setOrganizationListQuery((prev) => ({
      ...prev,
      search: values.search,
    }));
  };

  const gotoOrganizationDetailPage = (organizationId: number) => {
    if (selectedTab === OrganizationType.School) {
      navigate(AppRoutes.schoolDetail.path.replace(':organizationId', organizationId.toString()));
    } else if (selectedTab === OrganizationType.Community) {
      navigate(AppRoutes.communityDetail.path.replace(':organizationId', organizationId.toString()));
    }
  };

  const gotoNewOrganizationPage = () => {
    if (selectedTab === OrganizationType.School) {
      navigate(AppRoutes.newSchool.path);
    }
  };

  return (
    <div id="organization-list">
      <Box className="page-content card-background">
        <Tabs
          className="main-page-tabs"
          variant="fullWidth"
          value={selectedTab}
          onChange={(_, tabName) => setSelectedTab(tabName)}
        >
          {canUseAuthComponent(user.roles, AppRoutes.schoolOrganizations.authorizedRoles) && (
            <Tab label="KTS Schools" value={OrganizationType.School} onClick={() => navigate(AppRoutes.schoolOrganizations.path)} />
          )}

          {canUseAuthComponent(user.roles, AppRoutes.communityOrganizations.authorizedRoles) && (
            <Tab label="Community" value={OrganizationType.Community} onClick={() => navigate(AppRoutes.communityOrganizations.path)} />
          )}
        </Tabs>

        <div className="table-buttons-and-filters flex_jbetween">
          <div className="filters flex_acenter">
            <form className="content-container" onSubmit={handleSubmit(submitForm)}>
              <FormProvider {...methods}>
                <SearchInput
                  className="main-input search"
                  name="search"
                  onClear={handleSubmit(submitForm)}
                />
              </FormProvider>
            </form>
            {(selectedTab === OrganizationType.School) && !user.isDistrictInstitutionAdmin && (
              <div className="flex_acenter filter-button" onClick={(e) => setFilterAnchorElement(e.currentTarget)}>
                <img src={AppAssetPaths.icons.FILTER} />
                <p>Filter</p>
              </div>
            )}
          </div>

          {user.isAdmin && (
            <div className="flex_acenter">
              <DownloadButton
                variant="contained"
                startIcon={<img src={AppAssetPaths.icons.DOWNLOAD} />}
                onClick={() => {
                  if (selectedTab === OrganizationType.School) {
                    exportSchoolsCSV();
                  } else {
                    exportCommunityOrganizationsCSV();
                  }
                }}
                disableElevation
              >
                Download
              </DownloadButton>

              {selectedTab === OrganizationType.School && (
                <Button
                  variant="contained"
                  className="new-organization-btn"
                  onClick={gotoNewOrganizationPage}
                  disableElevation
                >
                  New Organization
                </Button>
              )}
            </div>
          )}
        </div>

        <DataGrid
          className="table"
          {...tableState}
          components={{
            Pagination: Pagination,
          }}
          componentsProps={{
            pagination: {
              page: tableState.page,
              count: Math.ceil(totalOrganizationListCount / tableState.pageSize),
              onChange: (_: void, page: number) => {
                setTableState((prev) => ({
                  ...prev,
                  page,
                }));
              },
            },
          }}
          onRowClick={(event) => gotoOrganizationDetailPage(event.row.id)}
          pagination
          paginationMode="server"
          sortingMode="server"
          sortModel={organizationListQuery.sortModel}
          onSortModelChange={(newSortModel: GridSortModel) => {
            setOrganizationListQuery((prev) => ({
              ...prev,
              sortModel: newSortModel,
            }));
          }}
          disableColumnFilter
          disableColumnMenu
          hideFooterSelectedRowCount
        />

        {!!organizationList.length &&
          <p className="row-count">
            {(tableState.pageSize * (tableState.page - 1)) + 1}-{(tableState.pageSize * (tableState.page - 1)) + organizationList.length} of {totalOrganizationListCount}
          </p>
        }
      </Box>

      {/* Filter Menu */}
      <SchoolFilter
        anchorElement={filterAnchorElement}
        setAnchorElement={setFilterAnchorElement}
        setFiltersCallback={(schoolType?: string, schoolDistricts?: number[]) => {
          setOrganizationListQuery((prev) => ({
            ...prev,
            schoolType,
            schoolDistricts,
          }));
        }}
      />
    </div>
  );
};

export default OrganizationList;
