import React, {
  useEffect, useState
} from 'react';
import './CreateSkill.scss';
import {
  FormProvider, SubmitHandler, useForm
} from 'react-hook-form';
import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import SubmitButton from '../../../components/forms/SubmitButton';
import {
  useNavigate, useParams
} from 'react-router-dom';
import TextInput from '../../../components/forms/TextInput';
import {
  Button, Checkbox, FormControlLabel, CircularProgress
} from '@mui/material';
import {
  createSkill, getSkillById, deleteSkill, updateSkill
} from '../../../features/skills/skills.thunks';
import { skillSelectors } from '../../../features/skills/skills.slice';
import {
  AppRoutes, StateStatus
} from '../../../app/app.types';
import { setAlert } from '../../../features/alert/alert.slice';

interface CreateSkillProps { }

export const CreateSkill: React.FC<CreateSkillProps> = () => {

  const skill = useAppSelector(skillSelectors.selectSkillDetails);
  const skillStatus = useAppSelector(skillSelectors.selectSkillDetailsStatus);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [
    activeCheckbox,
    setActiveCheckbox,
  ] = useState<boolean>(false);
  const [
    editMode,
    setEditMode,
  ] = useState<boolean>(false);
  const [
    pageLoaded,
    setPageLoaded,
  ] = useState<boolean>(false);


  useEffect(() => {
    if (params && params.id) {
      dispatch(getSkillById(+params.id));
      setEditMode(true);
    } else {
      setPageLoaded(true);
    }
  }, []);

  useEffect(() => {
    if ((params && params.id && +params.id !== skill.id) || pageLoaded) return;
    if (editMode) {
      setValue('label', skill.label);
      setActiveCheckbox(skill.active);
      setPageLoaded(true);
    }
  }, [
    editMode,
    skill,
  ]);

  const formValidationSchema = yup.object().shape({
    label: yup.string()
      .required('Skill name is required')
      .typeError('Skill name is required'),
  }).required();

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const { handleSubmit, setValue, formState: { errors } } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    const submitBody = {
      name: values.label,
      active: activeCheckbox,
    };

    if (editMode) {
      dispatch(updateSkill({
        id: skill.id,
        values: submitBody,
      })).then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully updated skill.',
        }));
      }).catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to update skill.',
        }));
      }).finally(() => navigate(AppRoutes.skills.path));
    }
    else {
      dispatch(createSkill(submitBody))
        .then(() => {
          dispatch(setAlert({
            type: 'success',
            message: 'Successfully created skill.',
          }));
        }).catch(() => {
          dispatch(setAlert({
            type: 'error',
            message: 'Unable to create skill.',
          }));
        }).finally(() => navigate(AppRoutes.skills.path));
    }
  };

  const handleSkillDelete = async () => {
    await dispatch(deleteSkill(skill.id));
    navigate(AppRoutes.skills.path);
  };


  return (
    <div id="create-skill" className="card-background">
      <h3> {editMode ? 'Edit' : 'New'} Skill</h3>
      {(skillStatus == StateStatus.IDLE && pageLoaded) ? (
        <form onSubmit={handleSubmit(submitForm)}>
          <FormProvider {...methods}>

            <TextInput
              containerClass="half-width"
              name="label"
              errorMessage={errors?.label?.message}
              label="Skill"
              type="text"
              size="small"
              required
            />

            <FormControlLabel
              className="active-checkbox"
              label="Active"
              control={
                <Checkbox
                  checked={activeCheckbox}
                  onChange={() => setActiveCheckbox(!activeCheckbox)}
                  name="active"
                  color="secondary" />
              } />


            <div className="flex_row_jbetween buttons-wrapper">
              {(editMode && skill.id) && (
                <Button
                  variant="outlined"
                  disableElevation
                  onClick={handleSkillDelete}
                >
                  Delete Skill
                </Button>
              )}

              <div className={editMode ? 'right-side flex_jend' : 'right-side flex_jbetween'}>
                <Button
                  variant="outlined"
                  disableElevation
                  onClick={() => navigate(AppRoutes.skills.path)}
                >
                  Cancel
                </Button>

                <SubmitButton
                  text="Submit"
                  variant="contained"
                  disableElevation
                />
              </div>
            </div>
          </FormProvider>
        </form>
      ) : (
        <div className="flex_jcenter_acenter loading-indicator">
          <CircularProgress size="50px" />
        </div>
      )}
    </div>
  );
};


export default CreateSkill;
