import { createAsyncThunk } from '@reduxjs/toolkit';
import * as configApi from './config.api';


export const loadConfigData = createAsyncThunk(
  'config/loadConfigData',
  async () => {
    return await configApi.getConfig();
  }
);
