import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';

import './DeleteConfirmationModal.scss';


interface DeleteConfirmationModalProps {
  open: boolean;
  deleteItemText: string;
  additionalWarningText?: string;
  onConfirm: () => void;
  onClose: () => void;
}

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
  deleteItemText,
  additionalWarningText,
  open,
  onConfirm,
  onClose,
}) => {
  return (
    <Dialog className="delete-confirm-dialog" open={open}>
      <DialogTitle>Delete {deleteItemText}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Are you sure you want to delete {deleteItemText}?
        </DialogContentText>
        {additionalWarningText && (
          <DialogContentText>
            {additionalWarningText}
          </DialogContentText>
        )}

        <div className="flex_row_jbetween action-buttons">
          <Button
            variant="outlined"
            onClick={onClose}
            disableElevation
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            onClick={onConfirm}
            disableElevation
          >
            Delete {deleteItemText}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteConfirmationModal;
