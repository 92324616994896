import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { StateStatus } from '../../app/app.types';
import {
  ConstructPathwaySchool,
  emptyPathway,
  Pathway,
  PathwayType
} from './pathways.model';
import {
  dismissPathwayUpdatedBanner,
  getPathwayDetails,
  getPathwayList,
  getPathwayTypeList,
  togglePathwayActive,
  togglePathwayActiveForSchool,
  togglePathwayCertificateActive,
  togglePathwayCertificateActiveForSchool,
  togglePathwayCourseActive,
  togglePathwayCourseActiveForSchool
} from './pathways.thunks';


/* State */

export interface PathwayState {
  pathwayTypeList: {
    status: StateStatus;
    data: PathwayType[];
  },
  pathwayList: {
    status: StateStatus;
    data: Pathway[];
    totalCount: number;
    loaded: boolean;
  },
  pathwayDetails: {
    status: StateStatus;
    data: Pathway;
  }
}

const initialState: PathwayState = {
  pathwayTypeList: {
    status: StateStatus.IDLE,
    data: [],
  },
  pathwayList: {
    status: StateStatus.IDLE,
    data: [],
    totalCount: 0,
    loaded: false,
  },
  pathwayDetails: {
    status: StateStatus.IDLE,
    data: emptyPathway,
  },
};


/* Reducer */

export const pathwaySlice = createSlice({
  name: 'pathway',
  initialState,
  reducers: {
    toggleCertificateActiveState: (state) => {
      state.pathwayDetails.data.certificateActive = !state.pathwayDetails.data.certificateActive;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPathwayTypeList.pending, (state) => {
        state.pathwayTypeList.status = StateStatus.LOADING;
      })
      .addCase(getPathwayTypeList.fulfilled, (state, action) => {
        state.pathwayTypeList.status = StateStatus.IDLE;
        state.pathwayTypeList.data = action.payload;
      })
      .addCase(getPathwayTypeList.rejected, (state) => {
        state.pathwayTypeList.status = StateStatus.FAILED;
      })
      .addCase(getPathwayList.pending, (state) => {
        state.pathwayList.status = StateStatus.LOADING;
      })
      .addCase(getPathwayList.fulfilled, (state, action) => {
        state.pathwayList.status = StateStatus.IDLE;
        state.pathwayList.data = action.payload.pathways;
        state.pathwayList.totalCount = action.payload.count;
        state.pathwayList.loaded = true;
      })
      .addCase(getPathwayList.rejected, (state) => {
        state.pathwayList.status = StateStatus.FAILED;
      })
      .addCase(getPathwayDetails.pending, (state) => {
        state.pathwayDetails.status = StateStatus.LOADING;
      })
      .addCase(getPathwayDetails.fulfilled, (state, action) => {
        state.pathwayDetails.status = StateStatus.IDLE;
        state.pathwayDetails.data = action.payload;
      })
      .addCase(getPathwayDetails.rejected, (state) => {
        state.pathwayDetails.status = StateStatus.FAILED;
      })
      .addCase(togglePathwayActive.pending, (state) => {
        state.pathwayList.status = StateStatus.LOADING;
      })
      .addCase(togglePathwayActive.fulfilled, (state, action) => {
        state.pathwayList.status = StateStatus.IDLE;
        const pathway = state.pathwayList.data.find(p => p.id === action.payload);
        if (pathway) {
          pathway.active = !pathway.active;
        }
      })
      .addCase(togglePathwayActive.rejected, (state) => {
        state.pathwayList.status = StateStatus.FAILED;
      })
      .addCase(togglePathwayCertificateActive.pending, (state) => {
        state.pathwayDetails.status = StateStatus.LOADING;
      })
      .addCase(togglePathwayCertificateActive.fulfilled, (state) => {
        state.pathwayDetails.status = StateStatus.IDLE;
        state.pathwayDetails.data.certificateActive = !state.pathwayDetails.data.certificateActive;
      })
      .addCase(togglePathwayCertificateActive.rejected, (state) => {
        state.pathwayDetails.status = StateStatus.FAILED;
      })
      .addCase(togglePathwayActiveForSchool.pending, (state) => {
        state.pathwayList.status = StateStatus.LOADING;
      })
      .addCase(togglePathwayActiveForSchool.fulfilled, (state, action) => {
        state.pathwayList.status = StateStatus.IDLE;
        const pathway = state.pathwayList.data.find(p => p.id === action.payload.id);
        if (pathway && pathway.pathwaySchool) {
          pathway.pathwaySchool.isHidden = action.payload.isHidden;
        }
        else if (pathway) {
          // Create a pathway school with an is_hidden value if there isn't one already
          // Fixes a bug where toggling status on a new pathway didn't render the change without reloading
          pathway.pathwaySchool = ConstructPathwaySchool({
            // eslint-disable-next-line camelcase
            is_hidden: action.payload.isHidden,
          });
        }
      })
      .addCase(togglePathwayActiveForSchool.rejected, (state) => {
        state.pathwayList.status = StateStatus.FAILED;
      })
      .addCase(togglePathwayCertificateActiveForSchool.pending, (state) => {
        state.pathwayDetails.status = StateStatus.LOADING;
      })
      .addCase(togglePathwayCertificateActiveForSchool.fulfilled, (state, action) => {
        state.pathwayDetails.status = StateStatus.IDLE;
        if (state.pathwayDetails.data.pathwaySchool) {
          state.pathwayDetails.data.pathwaySchool.certificateHidden = action.payload.isHidden;
        }
      })
      .addCase(togglePathwayCertificateActiveForSchool.rejected, (state) => {
        state.pathwayDetails.status = StateStatus.FAILED;
      })
      .addCase(togglePathwayCourseActive.pending, (state) => {
        state.pathwayDetails.status = StateStatus.LOADING;
      })
      .addCase(togglePathwayCourseActive.fulfilled, (state, action) => {
        state.pathwayDetails.status = StateStatus.IDLE;

        if (state.pathwayDetails.data.courses) {
          const courses = [
            ...state.pathwayDetails.data.courses.middleSchool,
            ...state.pathwayDetails.data.courses.supporting,
            ...state.pathwayDetails.data.courses.explorer,
            ...state.pathwayDetails.data.courses.concentrator,
            ...state.pathwayDetails.data.courses.completer,
            ...state.pathwayDetails.data.courses.postSecondaryResources,
          ];

          const course = courses.find(c => c.id === action.payload);
          if (course) {
            course.active = !course.active;
          }
        }
      })
      .addCase(togglePathwayCourseActive.rejected, (state) => {
        state.pathwayDetails.status = StateStatus.FAILED;
      })
      .addCase(togglePathwayCourseActiveForSchool.pending, (state) => {
        state.pathwayDetails.status = StateStatus.LOADING;
      })
      .addCase(togglePathwayCourseActiveForSchool.fulfilled, (state, action) => {
        state.pathwayDetails.status = StateStatus.IDLE;

        if (state.pathwayDetails.data.courses) {
          const courses = [
            ...state.pathwayDetails.data.courses.middleSchool,
            ...state.pathwayDetails.data.courses.supporting,
            ...state.pathwayDetails.data.courses.explorer,
            ...state.pathwayDetails.data.courses.concentrator,
            ...state.pathwayDetails.data.courses.completer,
            ...state.pathwayDetails.data.courses.postSecondaryResources,
          ];

          const course = courses.find(c => c.id === action.payload.id);
          if (course?.pathwayCourseSchool) {
            course.pathwayCourseSchool.isHidden = action.payload.isHidden;
          }
        }
      })
      .addCase(togglePathwayCourseActiveForSchool.rejected, (state) => {
        state.pathwayDetails.status = StateStatus.FAILED;
      })
      .addCase(dismissPathwayUpdatedBanner.pending, (state) => {
        state.pathwayDetails.status = StateStatus.LOADING;
      })
      .addCase(dismissPathwayUpdatedBanner.fulfilled, (state) => {
        state.pathwayDetails.status = StateStatus.IDLE;

        state.pathwayDetails.data.updatedByDistrictAdmin = false;
        if (state.pathwayDetails.data.pathwaySchool) {
          state.pathwayDetails.data.pathwaySchool.noteUpdated = false;
        }

        if (state.pathwayDetails.data.courses) {
          const courses = [
            ...state.pathwayDetails.data.courses.middleSchool,
            ...state.pathwayDetails.data.courses.supporting,
            ...state.pathwayDetails.data.courses.explorer,
            ...state.pathwayDetails.data.courses.concentrator,
            ...state.pathwayDetails.data.courses.completer,
            ...state.pathwayDetails.data.courses.postSecondaryResources,
          ];

          courses.forEach(c => {
            if (c.pathwayCourseSchool) {
              c.pathwayCourseSchool.updatedValues = undefined;
            }
          });
        }
      })
      .addCase(dismissPathwayUpdatedBanner.rejected, (state) => {
        state.pathwayDetails.status = StateStatus.FAILED;
      });
  },
});

export const { toggleCertificateActiveState } = pathwaySlice.actions;


/* Selectors */

const selectPathwayTypeList = (state: RootState): PathwayType[] => state.pathway.pathwayTypeList.data;
const selectPathwayListStatus = (state: RootState): StateStatus => state.pathway.pathwayList.status;
const selectPathwayList = (state: RootState): Pathway[] => state.pathway.pathwayList.data;
const selectTotalPathwayListCount = (state: RootState): number => state.pathway.pathwayList.totalCount;
const selectPathwayListLoaded = (state: RootState): boolean => state.pathway.pathwayList.loaded;
const selectPathwayDetailsStatus = (state: RootState): StateStatus => state.pathway.pathwayDetails.status;
const selectPathwayDetails = (state: RootState): Pathway => state.pathway.pathwayDetails.data;

export const pathwaySelectors = {
  selectPathwayTypeList,
  selectPathwayListStatus,
  selectPathwayList,
  selectTotalPathwayListCount,
  selectPathwayListLoaded,
  selectPathwayDetailsStatus,
  selectPathwayDetails,
};

export default pathwaySlice.reducer;
