import { createAsyncThunk } from '@reduxjs/toolkit';
import * as skillsApi from './skills.api';
import { SkillsListQueryParams } from './skills.model';



export const getSkillsList = createAsyncThunk(
  'skills/getSkillsList',
  async (query: SkillsListQueryParams) => {
    return await skillsApi.getSkillsList(query);
  }
);

export const getSkillById = createAsyncThunk(
  'skills/getSkillById',
  async (id: number) => {
    return await skillsApi.getSkillById(id);
  }
);

export const createSkill = createAsyncThunk(
  'skills/create',
  async (body: { name: string, active: boolean }) => {
    return await skillsApi.createSkill(body);
  }
);

export const updateSkill = createAsyncThunk(
  'skills/update',
  async (body: { id: number, values: { name: string, active: boolean } }) => {
    return await skillsApi.updateSkill(body.id, body.values);
  }
);

export const deleteSkill = createAsyncThunk(
  'skills/delete',
  async (id: number) => {
    return await skillsApi.deleteSkill(id);
  }
);