/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/display-name */
import {
  GridColumns, GridRenderCellParams, GridValueFormatterParams
} from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { AppAssetPaths } from '../../app/app.types';
import TableCellTooltip from './tableCellTooltip';
import './resourceTable.types.scss';

export const scholarshipListColumns: GridColumns = [
  {
    headerName: 'Resource',
    field: 'title',
    flex: 1,
    cellClassName: 'main-column',
    renderCell: (params: GridRenderCellParams) => (<TableCellTooltip params={params} />)
    ,
  },
  {
    headerName: 'Organization',
    field: 'organizationName',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    cellClassName: 'org-column',
  },
  {
    headerName: 'Date Updated',
    field: 'updatedAt',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => params.value ? dayjs(params.value as string).format('MM/DD/YYYY') : '-',
  },
  {
    headerName: 'Applicants',
    field: 'applicants',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Start Date',
    field: 'startDate',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => params.value ? dayjs(params.value as string).format('MM/DD/YYYY') : '-',
  },
  {
    headerName: 'End Date',
    field: 'endDate',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => params.value ? dayjs(params.value as string).format('MM/DD/YYYY') : '-',
  },
  {
    headerName: 'Status',
    field: 'status',
    flex: .8,
    renderCell: (params: GridRenderCellParams<boolean | undefined>) => (
      <div className="flex_acenter">
        {params.row.approved ? (
          <>
            {(dayjs().isAfter(params.row.endDate)) ? (
              <img src={AppAssetPaths.icons.statusIcons.EXPIRED} alt="expired" />
            ) : (params.row.active) ? (
              <img src={AppAssetPaths.icons.statusIcons.ACTIVE} alt="active" />
            ) : (
              <img src={AppAssetPaths.icons.statusIcons.INACTIVE} alt="inactive" />
            )}
          </>
        ) : (
          <img src={AppAssetPaths.icons.statusIcons.PENDING} alt="Pending" />
        )}
      </div>
    ),
  },
];

export const opportunityListColumns: GridColumns = [
  {
    headerName: 'Resource',
    field: 'title',
    flex: 1,
    cellClassName: 'main-column',
    renderCell: (params: GridRenderCellParams) => (<TableCellTooltip params={params} />),
  },
  {
    headerName: 'Organization',
    field: 'organizationName',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    cellClassName: 'org-column',
  },
  {
    headerName: 'Date Updated',
    field: 'updatedAt',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => params.value ? dayjs(params.value as string).format('MM/DD/YYYY') : '-',
  },
  {
    headerName: 'Applicants',
    field: 'applicants',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Start Date',
    field: 'startDate',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => params.value ? dayjs(params.value as string).format('MM/DD/YYYY') : '-',
  },
  {
    headerName: 'End Date',
    field: 'endDate',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => params.value ? dayjs(params.value as string).format('MM/DD/YYYY') : '-',
  },
  {
    headerName: 'Status',
    field: 'status',
    flex: .8,
    renderCell: (params: GridRenderCellParams<boolean | undefined>) => (
      <div className="flex_acenter">
        {params.row.approved ? (
          <>
            {(dayjs().isAfter(params.row.endDate)) ? (
              <img src={AppAssetPaths.icons.statusIcons.EXPIRED} alt="expired" />
            ) : (params.row.active) ? (
              <img src={AppAssetPaths.icons.statusIcons.ACTIVE} alt="active" />
            ) : (
              <img src={AppAssetPaths.icons.statusIcons.INACTIVE} alt="inactive" />
            )}
          </>
        ) : (
          <img src={AppAssetPaths.icons.statusIcons.PENDING} alt="Pending" />
        )}
      </div>
    ),
  },
];

export const applicationsListColumns: GridColumns = [
  {
    headerName: 'Student',
    field: 'applicantName',
    flex: 1,
    cellClassName: 'main-column',
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'School',
    field: 'applicantSchool',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Graduation Year',
    field: 'applicantGraduationYear',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Email',
    field: 'applicantEmail',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
];


export const resourceTagListColumns: GridColumns = [
  {
    headerName: 'Tag',
    field: 'name',
    flex: .5,
    cellClassName: 'main-column',
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Description',
    field: 'description',
    flex: 1.5,
    cellClassName: 'main-column description-column',
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Date Created',
    field: 'createdAt',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => params.value ? dayjs(params.value as string).format('MM/DD/YYYY') : '-',
  },
];

