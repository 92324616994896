import { AuthComponent } from '../../../app/app.types';
import { UserRole } from '../../user/user.model';
import {
  exportApplicationsCSV,
  exportCommunityOrganizationsCSV,
  exportCommunityUsersByStatusCSV,
  exportPendingAccountsCSV,
  exportPendingTransfersCSV,
  exportPointRankingsCSV, 
  exportSchoolsCSV, 
  exportUserListCSV,
  exportCollegeApplicationChecklistApplicantsCSV
} from '../../exports/exports.api';
import { OrganizationType } from '../../organization/organization.model';


export interface ExportMenuItem extends AuthComponent {
  title: string;
  onClick: () => void;
}

export interface Export extends AuthComponent {
  title: string;
  alternateTitle?: {
    title: string;
    authorizedRoles?: UserRole[];
  }
  menu: ExportMenuItem[];
}

export interface ExportCard extends AuthComponent {
  exportMenus: Export[];
}

export const DistrictInstitutionAdminDashboardExports: Export[] = [
  {
    title: 'All Users',
    menu: [
      {
        title: 'Adults',
        onClick: () => exportUserListCSV(UserRole.student, OrganizationType.Adult),
      },
    ],
  },
  {
    title: 'All Applications',
    alternateTitle: {
      title: 'Resource Applicants',
    },
    menu: [
      {
        title: 'All',
        onClick: () => exportApplicationsCSV(),
      },
      {
        title: 'Middle / Jr. High',
        onClick: () => exportApplicationsCSV(UserRole.middleSchoolStudent),
      },
      {
        title: 'High School',
        onClick: () => exportApplicationsCSV(UserRole.highSchoolStudent),
      },
      {
        title: 'Adults',
        onClick: () => exportApplicationsCSV(UserRole.adult),
      },
    ],
  },
];

export const DashboardExports: Export[] = [
  {
    title: 'All Users',
    menu: [
      {
        title: 'Middle / Jr. High',
        onClick: () => exportUserListCSV(UserRole.student, OrganizationType.MiddleSchool),
        authorizedRoles: [
          UserRole.admin,
          UserRole.districtAdmin,
          UserRole.middleSchoolKeyContact,
          UserRole.middleSchoolCounselor,
        ],
      },
      {
        title: 'High School',
        onClick: () => exportUserListCSV(UserRole.student, OrganizationType.HighSchool),
        authorizedRoles: [
          UserRole.admin,
          UserRole.districtAdmin,
          UserRole.highSchoolKeyContact,
          UserRole.highSchoolCounselor,
        ],
      },
      {
        title: 'Adults',
        onClick: () => exportUserListCSV(UserRole.student, OrganizationType.Adult),
        authorizedRoles: [
          UserRole.admin,
          UserRole.districtAdmin,
          UserRole.adultKeyContact,
          UserRole.universityKeyContact,
          UserRole.adultCounselor,
          UserRole.universityCounselor,
        ],
      },
    ],
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.keyContact,
      UserRole.counselor,
    ],
  },
  {
    title: 'Organizations',
    menu: [
      {
        title: 'KTS Schools',
        onClick: () => exportSchoolsCSV(),
        authorizedRoles: [
          UserRole.admin,
        ],
      },
      {
        title: 'Community',
        onClick: () => exportCommunityOrganizationsCSV(),
        authorizedRoles: [
          UserRole.admin,
        ],
      },
    ],
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  {
    title: 'Point Rankings',
    menu: [
      {
        title: 'All',
        onClick: () => exportPointRankingsCSV(),
        authorizedRoles: [
          UserRole.admin,
          UserRole.districtAdmin,
        ],
      },
      {
        title: 'Middle / Jr. High',
        onClick: () => exportPointRankingsCSV(UserRole.middleSchoolStudent),
        authorizedRoles: [
          UserRole.admin,
          UserRole.districtAdmin,
          UserRole.middleSchoolKeyContact,
          UserRole.middleSchoolCounselor,
        ],
      },
      {
        title: 'High School',
        onClick: () => exportPointRankingsCSV(UserRole.highSchoolStudent),
        authorizedRoles: [
          UserRole.admin,
          UserRole.districtAdmin,
          UserRole.highSchoolKeyContact,
          UserRole.highSchoolCounselor,
        ],
      },
    ],
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.middleSchoolKeyContact,
      UserRole.highSchoolKeyContact,
      UserRole.middleSchoolCounselor,
      UserRole.highSchoolCounselor,
    ],
  },
  {
    title: 'All Applications',
    alternateTitle: {
      title: 'Resource Applicants',
      authorizedRoles: [
        UserRole.community,
      ],
    },
    menu: [
      {
        title: 'All',
        onClick: () => exportApplicationsCSV(),
        authorizedRoles: [
          UserRole.admin,
          UserRole.districtAdmin,
          UserRole.community,
        ],
      },
      {
        title: 'Middle / Jr. High',
        onClick: () => exportApplicationsCSV(UserRole.middleSchoolStudent),
        authorizedRoles: [
          UserRole.admin,
          UserRole.districtAdmin,
          UserRole.community,
        ],
      },
      {
        title: 'High School',
        onClick: () => exportApplicationsCSV(UserRole.highSchoolStudent),
        authorizedRoles: [
          UserRole.admin,
          UserRole.districtAdmin,
          UserRole.community,
        ],
      },
      {
        title: 'Adults',
        onClick: () => exportApplicationsCSV(UserRole.adult),
        authorizedRoles: [
          UserRole.admin,
          UserRole.districtAdmin,
          UserRole.community,
        ],
      },
    ],
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.community,
    ],
  },
  {
    title: 'Community Users',
    menu: [
      {
        title: 'Active',
        onClick: () => exportCommunityUsersByStatusCSV('active'),
        authorizedRoles: [
          UserRole.admin,
        ],
      },
      {
        title: 'Inactive',
        onClick: () => exportCommunityUsersByStatusCSV('inactive'),
        authorizedRoles: [
          UserRole.admin,
        ],
      },
      {
        title: 'Static',
        onClick: () => exportCommunityUsersByStatusCSV('static'),
        authorizedRoles: [
          UserRole.admin,
        ],
      },
    ],
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  {
    title: 'Pending Account Approvals',
    menu: [
      {
        title: 'All',
        onClick: () => exportPendingAccountsCSV(),
        authorizedRoles: [
          UserRole.admin,
          UserRole.districtAdmin,
        ],
      },
      {
        title: 'Middle / Jr. High',
        onClick: () => exportPendingAccountsCSV(UserRole.middleSchoolStudent),
        authorizedRoles: [
          UserRole.admin,
          UserRole.districtAdmin,
          UserRole.middleSchoolKeyContact,
        ],
      },
      {
        title: 'High School',
        onClick: () => exportPendingAccountsCSV(UserRole.highSchoolStudent),
        authorizedRoles: [
          UserRole.admin,
          UserRole.districtAdmin,
          UserRole.highSchoolKeyContact,
        ],
      },
    ],
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.middleSchoolKeyContact,
      UserRole.highSchoolKeyContact,
    ],
  },
  {
    title: 'Pending Transfer Approvals',
    menu: [
      {
        title: 'All',
        onClick: () => exportPendingTransfersCSV(),
        authorizedRoles: [
          UserRole.admin,
          UserRole.districtAdmin,
        ],
      },
      {
        title: 'Middle / Jr. High',
        onClick: () => exportPendingTransfersCSV(UserRole.middleSchoolStudent),
        authorizedRoles: [
          UserRole.admin,
          UserRole.districtAdmin,
          UserRole.middleSchoolKeyContact,
        ],
      },
      {
        title: 'High School',
        onClick: () => exportPendingTransfersCSV(UserRole.highSchoolStudent),
        authorizedRoles: [
          UserRole.admin,
          UserRole.districtAdmin,
          UserRole.highSchoolKeyContact,
        ],
      },
    ],
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.middleSchoolKeyContact,
      UserRole.highSchoolKeyContact,
    ],
  },

  {
    title: 'All Colleges - Applicants',
    menu: [
      { 
        title: 'All',
        onClick: () => exportCollegeApplicationChecklistApplicantsCSV('applicants'),
      },
      { 
        title: 'High School',
        onClick: () => exportCollegeApplicationChecklistApplicantsCSV('applicants', 'high_school'),
      },
      { 
        title: 'Adult',
        onClick: () => exportCollegeApplicationChecklistApplicantsCSV('applicants', 'adult'),
      },
    ],
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  {
    title: 'All Colleges - Interested Users',
    menu: [
      { 
        title: 'All',
        onClick: () => exportCollegeApplicationChecklistApplicantsCSV('interested'),
      },
      { 
        title: 'High School',
        onClick: () => exportCollegeApplicationChecklistApplicantsCSV('interested', 'high_school'),
      },
      { 
        title: 'Adult',
        onClick: () => exportCollegeApplicationChecklistApplicantsCSV('interested', 'adult'),
      },
    ],
    authorizedRoles: [
      UserRole.admin,
    ],
  },
];  
