import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText
} from '@mui/material';
import React, {
  ChangeEvent,
  useEffect,
  useState
} from 'react';

import {
  FormProvider, SubmitHandler, useForm
} from 'react-hook-form';
import {
  AppAssetPaths, AppRoutes
} from '../../app/app.types';
import * as yup from 'yup';
import TextInput from '../../components/forms/TextInput';
import SubmitButton from '../../components/forms/SubmitButton';
import { useNavigate } from 'react-router';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import UploadButton from '../../components/forms/UploadButton';
import {
  useAppDispatch, useAppSelector
} from '../../app/hooks';
import {
  getHeardAboutKtsReasons, signUp
} from '../../features/user/user.thunks';
import {
  formatPhone, letterOnlyValidation, numberOnlyValidation
} from '../../util/formatting';
import { setAlert } from '../../features/alert/alert.slice';
import DropdownSelect from '../../components/forms/DropdownSelect';
import { careerSelectors } from '../../features/careers/careers.slice';
import { getCareerGroups } from '../../features/careers/careers.thunks';
import { getCommunityOrganizationTypeList } from '../../features/organization/organization.thunks';
import { organizationSelectors } from '../../features/organization/organization.slice';
import { passwordRequrementsMessage } from '../../util/yup/yup_validation';
import { userSelectors } from '../../features/user/user.slice';

import './Signup.scss';

interface SignupProps { }

const Signup: React.FC<SignupProps> = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const communityOrganizationTypes = useAppSelector(organizationSelectors.selectCommunityOrganizationTypeList);
  const careerGroups = useAppSelector(careerSelectors.selectCareerGroups);
  const heardAboutKtsReasons = useAppSelector(userSelectors.selectHeardAboutKtsReasons);

  const [
    checkboxes,
    setCheckboxes,
  ] = useState({
    scholarships: false,
    internships: false,
    openHouses: false,
    jobShadowing: false,
    other: false,
  });

  useEffect(() => {
    dispatch(getCommunityOrganizationTypeList());
    dispatch(getCareerGroups());
    dispatch(getHeardAboutKtsReasons());
  }, []);

  const formValidationSchema = yup.object().shape({
    organizationName: yup.string().required('Organization Name is required.'),
    organizationType: yup.object().nullable()
      .required('Organization Type is required.'),
    firstName: yup.string()
      .required('First Name is required.')
      .matches(/^[a-zA-Z\s]+$/, 'This field only allows letters and spaces.'),
    lastName: yup.string()
      .required('Last Name is required.')
      .matches(/^[a-zA-Z\s]+$/, 'This field only allows letters and spaces.'),
    email: yup.string()
      .email('Must be a valid email')
      .required('Contact Email is required'),
    password: yup.string()
      .required('Password is required')
      .isStrongPassword(),
    phoneNumber: yup.string()
      .test('len', 'Must be exactly 10 characters', (val?: string) => {
        // The numbers account for 10 but the dashes add 2 so that means the length is really 12
        return !val || val.length === 12 || val.length === 0;
      }),
    careerGroups: yup.array().nullable()
      .min(1, 'Organization Career Field is required')
      .required('Organization Career Field is required'),
    logo: yup.mixed()
      .required('Logo is required'),
  }).required();

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const { handleSubmit, clearErrors, formState: { errors } } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    const signupData = {
      ...values,
      intendedParticipation: checkboxes,
    };

    dispatch(signUp(signupData))
      .unwrap()
      .then(() => {
        clearErrors();
        navigate(AppRoutes.dashboard.path);
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to create new account.',
        }));
      });
  };

  const handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckboxes({
      ...checkboxes,
      [event.target.name]: event.target.checked,
    });
  };

  const createCheckboxes = () => {
    const boxNames = [
      'Scholarships',
      'Internships',
      'Open Houses',
      'Job Shadowing',
      'Other',
    ];

    const checkList = Object.entries(checkboxes).map((ele, index) =>
      <FormControlLabel
        key={ele[0]}
        label={boxNames[index]}
        control={
          <Checkbox
            checked={ele[1]}
            onChange={handleCheckbox}
            name={ele[0]}
          />
        }
      />
    );

    return checkList;
  };

  return (
    <form id="signup-page" className="content-container" onSubmit={handleSubmit(submitForm)}>
      <div className="back-button">
        <Button startIcon={<ArrowBackRoundedIcon />}
          onClick={() => navigate(AppRoutes.signIn.path)}
        >
          Back to Sign In
        </Button>
      </div>

      <div>
        <img src={AppAssetPaths.images.KTS_LOGO} alt="" />
        <h2>Community Account Sign Up</h2>
        <h3> Create an account for your oganization to participate in the keys to Success Program. </h3>
      </div>

      <FormProvider {...methods}>
        <TextInput
          name="organizationName"
          label="Organization Name"
          errorMessage={errors.organizationName?.message}
          type="text"
          size="small"
          required
        />

        <DropdownSelect
          name="organizationType"
          label="Organization Type"
          itemList={communityOrganizationTypes}
          errorMessage={errors.organizationType?.message}
          size="small"
          required
        />

        <TextInput
          name="firstName"
          label="Contact First Name"
          errorMessage={errors.firstName?.message}
          type="text"
          size="small"
          required
          onChange={(e) => letterOnlyValidation(e)}
        />

        <TextInput
          name="lastName"
          label="Contact Last name"
          errorMessage={errors.lastName?.message}
          type="text"
          size="small"
          required
          onChange={(e) => letterOnlyValidation(e)}
        />

        <TextInput
          name="email"
          label="Contact Email"
          errorMessage={errors.email?.message}
          type="text"
          size="small"
          required
          autoComplete="new-password"
        />

        <TextInput
          name="password"
          label="Password"
          errorMessage={errors.password?.message}
          type="password"
          size="small"
          required
          autoComplete="new-password"
        />
        {/* This hides the message when the error version shows up, basically making the color change to red */}
        {errors.password?.message !== passwordRequrementsMessage && (
          <FormHelperText className="helper-message">
            {passwordRequrementsMessage}
          </FormHelperText>
        )}

        <TextInput
          name="phoneNumber"
          label="Contact Number (optional)"
          errorMessage={errors.phoneNumber?.message}
          type="text"
          size="small"
          maxLength={12}
          onKeyPress={(e) => {
            numberOnlyValidation(e);
            if (e.keyCode || e.keyCode == 0) {
              e.target.value = formatPhone(e.target.value);
            }
          }}
        />

        <DropdownSelect
          name="careerGroups"
          label="Organization Career Field"
          itemList={careerGroups}
          errorMessage={errors.careerGroups?.message}
          size="small"
          required
          multiple
          selectAll
        />

        <p className="static-input-label required-field">LOGO</p>
        <div className="main-input">
          <UploadButton
            text="Upload Logo"
            name="logo"
            errorMessage={errors.logo?.message}
            size="large"
            disableElevation
          />
        </div>

        <p className="static-input-label">INTENDED KEYS TO SUCCESS PARTICIPATION (check all that apply)</p>
        <FormGroup className="main-input">
          {createCheckboxes()}
        </FormGroup>

        <DropdownSelect
          name="heardAboutKts"
          label="How did you hear about us?"
          itemList={heardAboutKtsReasons}
          size="small"
        />

        <SubmitButton
          className="submit-button"
          text="Create Account"
          variant="contained"
          size="large"
          disableElevation
        />

      </FormProvider>
    </form>
  );
};

export default Signup;
