import React, {
  useEffect, useState
} from 'react';
import {
  FormProvider, SubmitHandler, useForm
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useNavigate } from 'react-router-dom';
import {
  useAppDispatch, useAppSelector
} from '../../app/hooks';
import { userSelectors } from '../../features/user/user.slice';
import {
  FeatureTabs,
  readOnlySchoolPathwayListColumns,
  schoolAdminPathwayListColumns,
  superAdminPathwayListColumns
} from '../../constants/tables/pathwayTable.types';
import { TableState } from '../../constants/tables/table.types';
import { PathwayListQueryParams } from '../../features/pathways/pathways.model';
import {
  UserRole,
  getUserSchoolType
} from '../../features/user/user.model';
import {
  Box, Button, Pagination, Tab, Tabs
} from '@mui/material';
import {
  AppAssetPaths,
  AppRoutes,
  canUseAuthComponent,
  isPostSecondaryAdmin
} from '../../app/app.types';
import SearchInput from '../../components/table/SearchInput';
import {
  DataGrid, GridSortModel
} from '@mui/x-data-grid';
import TableRowCount from '../../components/table/TableRowCount';
import { pathwaySelectors } from '../../features/pathways/pathways.slice';
import { getPathwayList } from '../../features/pathways/pathways.thunks';
import './FeatureList.scss';
import { getSkillTrainingList } from '../../features/skillTrainings/skillTrainings.thunks';
import { skillTrainingSelectors } from '../../features/skillTrainings/skillTrainings.slice';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { RedTextButton } from '../../components/CustomStyledComponents';
import { SkillTrainingListQueryParams } from '../../features/skillTrainings/skillTrainings.model';
import PathwayFilter from './pathways/PathwayFilter';
import { superAdminSkillTrainingsListColumns } from '../../constants/tables/skillTrainingTable.types';
import { organizationSelectors } from '../../features/organization/organization.slice';
import { store } from '../../app/store';
import { OrganizationType } from '../../features/organization/organization.model';
import { getAllSchools } from '../../features/organization/organization.thunks';


interface FeatureListProps {
  initialTab: string;
}

const FeatureList: React.FC<FeatureListProps> = ({ initialTab }) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(userSelectors.selectUser);
  const pathwayList = useAppSelector(pathwaySelectors.selectPathwayList);
  const totalPathwayCount = useAppSelector(pathwaySelectors.selectTotalPathwayListCount);
  const skillTrainingList = useAppSelector(skillTrainingSelectors.selectSkillTrainingList);
  const totalSkillTrainingsCount = useAppSelector(skillTrainingSelectors.selectSkillTrainingListCount);
  const highSchools = organizationSelectors.selectFilteredSchools(store.getState(), {
    organizationType: OrganizationType.HighSchool,
    schoolDistrictId: user.roles.includes(UserRole.districtAdmin) ? user.organizationId : undefined,
  });
  const schoolsLoaded = useAppSelector(organizationSelectors.selectAllSchoolsLoaded);

  const setPathwayListRole = (): string => {
    if (user.roles.includes(UserRole.districtAdmin)) {
      return UserRole.districtAdmin;
    }
    else if (!user.isAdmin && (user.roles.includes(UserRole.universityCounselor) || isPostSecondaryAdmin(user))) {
      // Adding to prevent Higher Ed Counselor users from manually entering the URL
      navigate(-1);
    }
    else if (user.roles.includes(UserRole.keyContact) || user.roles.includes(UserRole.counselor)) {
      return getUserSchoolType(user.roles) || '';
    }

    return UserRole.admin;
  };

  const [
    selectedTab,
    setSelectedTab,
  ] = useState('');
  const [
    tableState,
    setTableState,
  ] = useState<TableState>({
    page: 1,
    pageSize: 100,
    columns: superAdminPathwayListColumns,
    rows: [],
    loading: false,
  });

  const [
    pathwayListQuery,
    setPathwayListQuery,
  ] = useState<PathwayListQueryParams>({
    offset: 0,
    limit: undefined,
    role: setPathwayListRole(),
    search: undefined,
    typeId: undefined,
    schoolId: undefined,
    sortModel: undefined,
  });
  const [
    skillTrainingListQuery,
    setSkillTrainingListQuery,
  ] = useState<SkillTrainingListQueryParams>({
    offset: 0,
    limit: undefined,
    search: undefined,
    sortModel: undefined,
  });

  const [
    pathwayFilterAnchorElement,
    setPathwayFilterAnchorElement,
  ] = useState<HTMLElement | null>(null);


  const changeColumns = (filteredBySchool?: boolean) => {
    if (selectedTab === FeatureTabs.pathways) {
      if (filteredBySchool) {
        return readOnlySchoolPathwayListColumns;
      }
      else if (user.roles.includes(UserRole.admin)) {
        return superAdminPathwayListColumns;
      }
      else {
        return schoolAdminPathwayListColumns;
      }
    }
    else return superAdminSkillTrainingsListColumns;
  };

  useEffect(() => {
    setSelectedTab(initialTab);
  }, [
    initialTab,
  ]);

  // Load a different list
  useEffect(() => {
    setTableState((prev) => ({
      ...prev,
      page: 1,
      columns: changeColumns(),
      rows: [],
      loading: true,
    }));

    if (selectedTab === FeatureTabs.pathways) {
      if (user.roles.includes(UserRole.districtAdmin) && !pathwayListQuery.schoolId) return;

      dispatch(getPathwayList({
        ...pathwayListQuery,
        offset: 0,
      }));
    }
    else if (selectedTab === FeatureTabs.skillTrainings) {
      dispatch(getSkillTrainingList({
        ...skillTrainingListQuery,
        offset: 0,
      }));
    }
  }, [
    selectedTab,
  ]);

  // Filtering list
  useEffect(() => {
    setTableState((prev) => ({
      ...prev,
      page: 1,
      rows: [],
      loading: true,
    }));

    if (selectedTab === FeatureTabs.pathways) {
      if (user.roles.includes(UserRole.districtAdmin) && !pathwayListQuery.schoolId) return;

      dispatch(getPathwayList({
        ...pathwayListQuery,
        offset: 0,
      }));
    }
    else if (selectedTab === FeatureTabs.skillTrainings) {
      dispatch(getSkillTrainingList({
        ...skillTrainingListQuery,
        offset: 0,
      }));
    }
  }, [
    pathwayListQuery,
    skillTrainingListQuery,
  ]);

  // Loading more data for pagination
  useEffect(() => {
    if (selectedTab == FeatureTabs.pathways) {
      if (tableState.page < pathwayList.length / tableState.pageSize || !pathwayList.length || pathwayList.length >= totalPathwayCount) return;
    }
    else {
      if (tableState.page < skillTrainingList.length / tableState.pageSize || !skillTrainingList.length || skillTrainingList.length >= totalSkillTrainingsCount) return;
    }

    setTableState((prev) => ({
      ...prev,
      loading: true,
    }));

    if (selectedTab === FeatureTabs.pathways) {
      dispatch(getPathwayList({
        ...pathwayListQuery,
        offset: tableState.pageSize * (tableState.page - 1),
      }));
    }
    if (selectedTab === FeatureTabs.skillTrainings) {
      dispatch(getSkillTrainingList({
        ...skillTrainingListQuery,
        offset: tableState.pageSize * (tableState.page - 1),
      }));
    }
  }, [
    tableState.page,
    tableState.pageSize,
  ]);

  useEffect(() => {
    setTableState((prev) => ({
      ...prev,
      loading: false,
      rows: selectedTab === FeatureTabs.pathways ? pathwayList : skillTrainingList,
    }));
  }, [
    pathwayList,
    skillTrainingList,
  ]);


  // Effects for setting default school for pathway filter
  useEffect(() => {
    if (canUseAuthComponent(user.roles, [
      UserRole.districtAdmin,
      UserRole.middleSchoolKeyContact,
      UserRole.middleSchoolCounselor,
    ])) {
      dispatch(getAllSchools());
    }
  }, []);

  useEffect(() => {
    if (
      (canUseAuthComponent(user.roles, [
        UserRole.districtAdmin,
        UserRole.middleSchoolKeyContact,
        UserRole.middleSchoolCounselor,
      ])) &&
      !!highSchools.length
    ) {
      const schoolId = highSchools[0]?.id;
      setPathwayListQuery((prev) => ({
        ...prev,
        schoolId,
      }));
    }
  }, [
    schoolsLoaded,
  ]);


  const formValidationSchema = yup.object().shape({
    search: yup.string(),
  });

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const { handleSubmit } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    if (selectedTab === FeatureTabs.pathways) {
      setPathwayListQuery((prev) => ({
        ...prev,
        search: values.search,
      }));
    }
    else {
      setSkillTrainingListQuery((prev) => ({
        ...prev,
        search: values.search,
      }));
    }
  };

  return (
    <div id="feature-list">
      <Box className="page-content card-background">
        <Tabs
          className="main-page-tabs"
          variant="fullWidth"
          value={selectedTab}
          onChange={(_, tabName) => setSelectedTab(tabName)}
        >
          {(canUseAuthComponent(user.roles, AppRoutes.pathways.authorizedRoles) && !user.isDistrictInstitutionAdmin) && (
            <Tab label="CTE Pathways" value={FeatureTabs.pathways} onClick={() => navigate(AppRoutes.pathways.path)} />
          )}
          {canUseAuthComponent(user.roles, AppRoutes.skillTrainings.authorizedRoles) && (
            <Tab label="Skill Trainings" value={FeatureTabs.skillTrainings} onClick={() => navigate(AppRoutes.skillTrainings.path)} />
          )}
        </Tabs>

        <div className="table-buttons-and-filters flex_jbetween">
          <div className="filters flex_acenter">
            <form className="content-container" onSubmit={handleSubmit(submitForm)}>
              <FormProvider {...methods}>
                <SearchInput
                  className="main-input search"
                  name="search"
                  onClear={handleSubmit(submitForm)}
                />
              </FormProvider>
            </form>
            {selectedTab === FeatureTabs.pathways && (
              <div className="flex_acenter filter-button" onClick={(e) => setPathwayFilterAnchorElement(e.currentTarget)}>
                <img src={AppAssetPaths.icons.FILTER} />
                <p>Filter</p>
              </div>
            )}
          </div>

          <div className="flex_acenter">
            {(selectedTab === FeatureTabs.skillTrainings && user.isAdmin) && (
              <RedTextButton
                variant="contained"
                endIcon={<ChevronRightIcon />}
                onClick={() => navigate(AppRoutes.careerClusters.path)}
                disableElevation
              >
                View Career Clusters
              </RedTextButton>
            )}

            {(selectedTab === FeatureTabs.skillTrainings && user.isAdmin) && (
              <RedTextButton
                variant="contained"
                endIcon={<ChevronRightIcon />}
                onClick={() => navigate(AppRoutes.skills.path)}
                disableElevation
              >
                View Skills
              </RedTextButton>
            )}

            {(selectedTab === FeatureTabs.skillTrainings || user.isAdmin) && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (selectedTab === FeatureTabs.pathways) {
                    navigate(AppRoutes.createPathway.path);
                  }
                  else {
                    navigate(AppRoutes.createSkillTraining.path);
                  }
                }}
                disableElevation
              >
                New {selectedTab}
              </Button>
            )}
          </div>
        </div>

        <DataGrid
          className="table"
          {...tableState}
          components={{
            Pagination: Pagination,
          }}
          componentsProps={{
            pagination: {
              page: tableState.page,
              count: Math.ceil((selectedTab === FeatureTabs.pathways ? totalPathwayCount : totalSkillTrainingsCount) / tableState.pageSize),
              onChange: (_: void, page: number) => {
                setTableState((prev) => ({
                  ...prev,
                  page,
                }));
              },
            },
          }}
          onRowClick={(event) => {
            if (selectedTab === FeatureTabs.pathways) {
              navigate(AppRoutes.pathwayDetails.path.replace(':id', event.row.id.toString()), {
                state: {
                  schoolId: pathwayListQuery.schoolId,
                },
              });
            }
            else {
              navigate(AppRoutes.skillTrainingDetails.path.replace(':id', event.row.id.toString()));
            }
          }}
          pagination
          paginationMode="server"
          sortingMode="server"
          sortModel={(selectedTab === FeatureTabs.pathways) ? pathwayListQuery.sortModel : skillTrainingListQuery.sortModel}
          onSortModelChange={(newSortModel: GridSortModel) => {
            if (selectedTab === FeatureTabs.pathways) {
              setPathwayListQuery((prev) => ({
                ...prev,
                sortModel: newSortModel,
              }));
            }
            else {
              setSkillTrainingListQuery((prev) => ({
                ...prev,
                sortModel: newSortModel,
              }));
            }
          }}
          disableColumnFilter
          disableColumnMenu
          hideFooterSelectedRowCount
        />

        <TableRowCount
          length={(selectedTab === FeatureTabs.pathways) ? pathwayList.length : skillTrainingList.length}
          pageSize={tableState.pageSize}
          page={tableState.page}
          totalCount={(selectedTab === FeatureTabs.pathways) ? totalPathwayCount : totalSkillTrainingsCount}
        />
      </Box>

      {/* Filter Menu */}
      <PathwayFilter
        anchorElement={pathwayFilterAnchorElement}
        setAnchorElement={setPathwayFilterAnchorElement}
        setFiltersCallback={(typeId?: number, schoolId?: number) => {
          setTableState((prev) => ({
            ...prev,
            page: 1,
            columns: changeColumns(Boolean(schoolId)),
            rows: [],
            loading: true,
          }));

          setPathwayListQuery((prev) => ({
            ...prev,
            typeId,
            schoolId,
          }));
        }}
      />
    </div >
  );
};

export default FeatureList;
