import {
  Button, Pagination
} from '@mui/material';
import { Box } from '@mui/system';
import {
  DataGrid, GridSortModel
} from '@mui/x-data-grid';
import React, {
  useEffect, useState
} from 'react';
import './SkillsList.scss';
import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import TableRowCount from '../../../components/table/TableRowCount';
import { TableState } from '../../../constants/tables/table.types';
import { skillSelectors } from '../../../features/skills/skills.slice';
import { getSkillsList } from '../../../features/skills/skills.thunks';
import { SkillsListQueryParams } from '../../../features/skills/skills.model';
import SearchInput from '../../../components/table/SearchInput';
import {
  FormProvider, SubmitHandler, useForm
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../app/app.types';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { SkillsListColumns } from '../../../constants/tables/skillTrainingTable.types';


interface SkillsListProps { }

const SkillsList: React.FC<SkillsListProps> = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const skillsList = useAppSelector(skillSelectors.selectSkillsList);
  const totalSkillsCount = useAppSelector(skillSelectors.selectSkillsCount);

  const [
    tableState,
    setTableState,
  ] = useState<TableState>({
    page: 1,
    pageSize: 100,
    columns: SkillsListColumns,
    rows: [],
    loading: false,
  });

  const [
    skillsListQuery,
    setSkillsListQuery,
  ] = useState<SkillsListQueryParams>({
    offset: 0,
    limit: undefined,
    search: undefined,
    sortModel: undefined,
  });

  // Filtering list
  useEffect(() => {
    setTableState((prev) => ({
      ...prev,
      page: 1,
      rows: [],
      loading: true,
    }));

    dispatch(getSkillsList({
      ...skillsListQuery,
      offset: 0,
    }));
  }, [
    skillsListQuery,
  ]);


  // Loading more data for pagination
  useEffect(() => {
    if (tableState.page < skillsList.length / tableState.pageSize || !skillsList.length || skillsList.length >= totalSkillsCount) return;

    setTableState((prev) => ({
      ...prev,
      loading: true,
    }));

    dispatch(getSkillsList({
      ...skillsListQuery,
      offset: tableState.pageSize * (tableState.page - 1),
    }));
  }, [
    tableState.page,
    tableState.pageSize,
  ]);

  useEffect(() => {
    setTableState((prev) => ({
      ...prev,
      loading: false,
      rows: skillsList,
    }));
  }, [
    skillsList,
  ]);

  const formValidationSchema = yup.object().shape({
    search: yup.string(),
  });

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const { handleSubmit } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    setSkillsListQuery((prev) => ({
      ...prev,
      search: values.search,
    }));
  };

  return (
    <div id="skills-list">
      <Box className="page-content card-background">
        <div className="list-header flex_col">
          <span className="back-button flex_acenter" onClick={() => navigate(AppRoutes.skillTrainings.path)}>
            <ChevronLeftIcon color="secondary" />
            <h3>Skills</h3>
          </span>
          <p>Skills are assigned to career clusters and then marked as a <b>must have</b> or <b>nice to have</b> skill.</p>
        </div>

        <div className="top-container flex_jbetween_acenter">
          <form onSubmit={handleSubmit(submitForm)}>
            <FormProvider {...methods}>
              <SearchInput
                className="main-input search"
                name="search"
                onClear={handleSubmit(submitForm)}
              />
            </FormProvider>
          </form>

          <Button
            variant="contained"
            disableElevation
            onClick={(event) => navigate(AppRoutes.createSkill.path)}
          >
            New Skill
          </Button>
        </div>

        <DataGrid
          className="table"
          {...tableState}
          components={{
            Pagination: Pagination,
          }}
          componentsProps={{
            pagination: {
              page: tableState.page,
              count: Math.ceil(totalSkillsCount / tableState.pageSize),
              onChange: (_: void, page: number) => {
                setTableState((prev) => ({
                  ...prev,
                  page,
                }));
              },
            },
          }}
          onRowClick={(event) => navigate(AppRoutes.skillDetails.path.replace(':id', event.row.id.toString()))}
          pagination
          paginationMode="server"
          sortingMode="server"
          sortModel={skillsListQuery.sortModel}
          onSortModelChange={(newSortModel: GridSortModel) => {
            setSkillsListQuery((prev) => ({
              ...prev,
              sortModel: newSortModel,
            }));
          }}
          disableColumnFilter
          disableColumnMenu
          hideFooterSelectedRowCount
        />

        <TableRowCount
          length={skillsList.length}
          pageSize={tableState.pageSize}
          page={tableState.page}
          totalCount={totalSkillsCount}
        />
      </Box>
    </div>
  );
};

export default SkillsList;
