/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { GridSortModel } from '@mui/x-data-grid';


export interface InquiryType {
  id: number;
  title: string;
}

export interface Inquiry {
  id: number;
  name: string;
  email: string;
  phoneNumber: string;
  typeId: number;
  type: InquiryType;
  message: string;
  response: string;
  createdAt: string;
}

export interface InquiryListQueryParams {
  offset: number;
  limit?: number;
  search?: string;
  sortModel?: GridSortModel;
}

export const ConstructInquiry = (data: any): Inquiry => {
  return {
    id: data.id,
    name: data.name,
    email: data.email,
    phoneNumber: data.phone_number,
    typeId: data.type_id,
    type: data.type,
    message: data.message,
    response: data.response,
    createdAt: data.created_at,
  };
};
