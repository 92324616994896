import React, {
  useEffect, useRef, useState
} from 'react';
import {
  FormProvider, SubmitHandler, useForm
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  useLocation, useNavigate 
} from 'react-router-dom';
import {
  isEqual, lowerCase, uniqWith
} from 'lodash';
import {
  Box, Button, Pagination, Popper, Tab, Tabs
} from '@mui/material';
import {
  DataGrid,
  GridColumns,
  GridSortModel,
  GridRowParams,
  GridSelectionModel,
  GridColumnHeaderParams
} from '@mui/x-data-grid';

import {
  useAppDispatch, useAppSelector
} from '../../app/hooks';
import {
  AppRoutes, canUseAuthComponent, AppAssetPaths
} from '../../app/app.types';

import { approvalSelectors } from '../../features/approvals/approvals.slice';
import {
  skillTrainingsApprovalsListColumns,
  studentApprovalsListColumns,
  transferApprovalsListColumns,
  notificationApprovalsListColumns,
  resourceApprovalsListColumns,
  applicationApprovalsListColumns,
  ApprovalTypeTabs
} from '../../constants/tables/approvalTable.types';
import {
  ApprovalListQueryParams, TransferApproval, StudentApproval, NotificationApproval, ResourceApproval, SkillTrainingApproval, ApplicationApproval, ApprovalType
} from '../../features/approvals/approvals.model';
import {
  getStudentApprovalList, getTransferApprovalList, getNotificationApprovalList, getResourceApprovalList, getSkillTrainingApprovalList, getApplicationApprovalList
} from '../../features/approvals/approvals.thunks';
import {
  exportPendingAccountsCSV, exportPendingTransfersCSV
} from '../../features/exports/exports.api';
import { UserRole } from '../../features/user/user.model';
import { userSelectors } from '../../features/user/user.slice';
import { ResourceType } from '../../features/resources/resources.model';
import { TableState } from '../../constants/tables/table.types';
import { OrganizationType } from '../../features/organization/organization.model';
import { organizationTypeList } from '../../util/dropdown';
import { ListMenuItem } from '../../components/forms/form.types';
import SearchInput from '../../components/table/SearchInput';
import TableRowCount from '../../components/table/TableRowCount';
import { DownloadButton } from '../../components/CustomStyledComponents';
import ApprovalFilter, { ApprovalOrgTypeFilter } from './ApprovalFilter';
import {
  getSelectAllBulkApproveRejectTooltipSeen, setSelectAllBulkApproveRejectTooltipSeen
} from '../../util/localStorage';
import BulkApproveRejectModal from '../../components/modal/BulkApproveReject.modal';

import './ApprovalList.scss';


interface ApprovalListProps {
  initialTab: ApprovalTypeTabs
}

const ApprovalList: React.FC<ApprovalListProps> = ({ initialTab }) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(userSelectors.selectUser);
  const location = useLocation();
  let approvalList: StudentApproval[] | TransferApproval[] | NotificationApproval[] | ResourceApproval[] | SkillTrainingApproval[] | ApplicationApproval[];
  let approvalListCount: number;
  let approvalsListColumns: GridColumns = [];

  const noDataElement = () => {
    return (
      <div className="flex_row_acenter_jcenter no-more-approvals">
        You’re all caught up!
      </div>
    );
  };

  switch (initialTab) {
    case ApprovalTypeTabs.TransferApprovals: {
      approvalList = useAppSelector(approvalSelectors.selectTransferApprovalList);
      approvalListCount = useAppSelector(approvalSelectors.selectTransferApprovalListCount);
      approvalsListColumns = transferApprovalsListColumns;
      break;
    }
    case ApprovalTypeTabs.NotificationApprovals: {
      approvalList = useAppSelector(approvalSelectors.selectNotificationApprovalList);
      approvalListCount = useAppSelector(approvalSelectors.selectNotificationApprovalListCount);
      approvalsListColumns = notificationApprovalsListColumns;
      break;
    }
    case ApprovalTypeTabs.SkillTrainingApprovals: {
      approvalList = useAppSelector(approvalSelectors.selectSkillTrainingApprovalList);
      approvalListCount = useAppSelector(approvalSelectors.selectSkillTrainingApprovalListCount);
      approvalsListColumns = skillTrainingsApprovalsListColumns;
      break;
    }

    case ApprovalTypeTabs.ResourceApprovals: {
      approvalList = useAppSelector(approvalSelectors.selectResourceApprovalList);
      approvalListCount = useAppSelector(approvalSelectors.selectResourceApprovalListCount);
      approvalsListColumns = resourceApprovalsListColumns;
      break;
    }

    case ApprovalTypeTabs.ApplicationApprovals: {
      approvalList = useAppSelector(approvalSelectors.selectApplicationApprovalList);
      approvalListCount = useAppSelector(approvalSelectors.selectApplicationApprovalListCount);
      approvalsListColumns = applicationApprovalsListColumns;
      break;
    }

    case ApprovalTypeTabs.StudentApprovals:
    default: {
      approvalList = useAppSelector(approvalSelectors.selectStudentApprovalList);
      approvalListCount = useAppSelector(approvalSelectors.selectStudentApprovalListCount);
      approvalsListColumns = studentApprovalsListColumns;
      break;
    }
  }

  const getApprovalType = (approval: ApprovalTypeTabs): ApprovalType => {
    switch (approval) {
      case ApprovalTypeTabs.StudentApprovals: {
        return ApprovalType.student;
      }

      case ApprovalTypeTabs.NotificationApprovals: {
        return ApprovalType.notification;
      }

      case ApprovalTypeTabs.ResourceApprovals: {
        return ApprovalType.resource;
      }

      case ApprovalTypeTabs.ApplicationApprovals: {
        return ApprovalType.application;
      }

      case ApprovalTypeTabs.TransferApprovals: {
        return ApprovalType.transfer;
      }

      default: {
        return ApprovalType.student;
      }
    }
  };

  const [
    selectedTab,
    setSelectedTab,
  ] = useState(initialTab);
  const [
    tableState,
    setTableState,
  ] = useState<TableState>({
    page: 1,
    pageSize: 100,
    columns: approvalsListColumns,
    rows: [],
    loading: false,
  });
  const [
    approvalListQuery,
    setApprovalListQuery,
  ] = useState<ApprovalListQueryParams>({
    offset: 0,
    limit: undefined,
    approvalType: getApprovalType(initialTab),
    sortModel: undefined,
    userType: undefined,
    schoolType: undefined,
  });
  const [
    userTypeList,
    setUserTypeList,
  ] = useState<ListMenuItem[]>([]);
  const [
    orgTypeList,
    setOrgTypeList,
  ] = useState(organizationTypeList.filter(o => o.id !== OrganizationType.Community && o.id !== OrganizationType.NoSchool));
  const [
    approveRejectModalOpen,
    setApproveRejectModalOpen,
  ] = useState({
    open: false,
    approving: false,
  });
  const [
    selectAllTooltipAnchorElement,
    setSelectAllTooltipAnchorElement,
  ] = useState<HTMLElement | null>(null);

  // State for row selection in conjunction with server side pagination
  const [
    selectionModel,
    setSelectionModel,
  ] = useState<GridSelectionModel>([]);
  const prevSelectionModel = useRef<GridSelectionModel>(selectionModel);
  const [
    selectedUsers,
    setSelectedUsers,
  ] = useState<StudentApproval[]>([]);
  const prevSelectedUsers = useRef<StudentApproval[]>(selectedUsers);

  useEffect(() => {
    setSelectedTab(initialTab);
  }, [
    initialTab,
  ]);

  const [
    filterAnchorElement,
    setFilterAnchorElement,
  ] = useState<HTMLElement | null>(null);


  const changeColumns = () => {

    switch (selectedTab) {
      case ApprovalTypeTabs.StudentApprovals: {
        return studentApprovalsListColumns;
      }
      case ApprovalTypeTabs.TransferApprovals: {
        return transferApprovalsListColumns;
      }
      case ApprovalTypeTabs.SkillTrainingApprovals: {
        return skillTrainingsApprovalsListColumns;
      }
      case ApprovalTypeTabs.NotificationApprovals: {
        return notificationApprovalsListColumns;
      }
      case ApprovalTypeTabs.ResourceApprovals: {
        return resourceApprovalsListColumns;
      }
      case ApprovalTypeTabs.ApplicationApprovals: {
        return applicationApprovalsListColumns;
      }
      default: { return []; }
    }
  };


  useEffect(() => {
    setSelectedTab(initialTab);
  }, [
    initialTab,
  ]);

  useEffect(() => {
    switch (selectedTab) {
      case ApprovalTypeTabs.StudentApprovals: {
        setUserTypeList([
          {
            id: UserRole.middleSchoolStudent,
            label: 'Middle School/Jr. High',
          },
          {
            id: UserRole.highSchoolStudent,
            label: 'High School',
          },
        ]);
        break;
      }
      case ApprovalTypeTabs.TransferApprovals: {
        setUserTypeList([
          {
            id: OrganizationType.MiddleSchool,
            label: 'Middle School/Jr. High',
          },
          {
            id: OrganizationType.HighSchool,
            label: 'High School',
          },
        ]);
        break;
      }
      case ApprovalTypeTabs.SkillTrainingApprovals: {
        break;
      }
      case ApprovalTypeTabs.ResourceApprovals:
      case ApprovalTypeTabs.NotificationApprovals: {
        if (user.isAdmin) {
          setOrgTypeList(organizationTypeList.filter(o => o.id !== OrganizationType.Community && o.id !== OrganizationType.NoSchool));
        }
        break;
      }
      case ApprovalTypeTabs.ApplicationApprovals: {
        if (user.isAdmin) {
          setOrgTypeList(organizationTypeList.filter(o => o.id !== OrganizationType.UniversityCollege && o.id !== OrganizationType.Community && o.id !== OrganizationType.NoSchool));
        }
        break;
      }
      default: { break; }
    }

    setApprovalListQuery((prev) => ({
      ...prev,
      approvalType: getApprovalType(selectedTab),
      userType: undefined,
      schoolType: undefined,
    }));
  }, [
    selectedTab,
  ]);

  // Switching approval lists
  useEffect(() => {
    setTableState((prev) => ({
      ...prev,
      page: 1,
      columns: changeColumns(),
      rows: [],
      loading: true,
    }));

    switch (selectedTab) {
      case ApprovalTypeTabs.TransferApprovals: {
        dispatch(getTransferApprovalList({
          ...approvalListQuery,
          offset: 0,
        }));
        break;
      }
      case ApprovalTypeTabs.NotificationApprovals: {
        dispatch(getNotificationApprovalList({
          ...approvalListQuery,
          offset: 0,
        }));
        break;
      }
      case ApprovalTypeTabs.SkillTrainingApprovals: {
        dispatch(getSkillTrainingApprovalList({
          ...approvalListQuery,
          offset: 0,
        }));
        break;
      }
      case ApprovalTypeTabs.ResourceApprovals: {
        dispatch(getResourceApprovalList({
          ...approvalListQuery,
          offset: 0,
        }));
        break;
      }
      case ApprovalTypeTabs.ApplicationApprovals: {
        dispatch(getApplicationApprovalList({
          ...approvalListQuery,
          offset: 0,
        }));
        break;
      }
      case ApprovalTypeTabs.StudentApprovals:
      default: {
        // Save selection model before list changes
        prevSelectionModel.current = selectionModel;
        prevSelectedUsers.current = selectedUsers;

        dispatch(getStudentApprovalList({
          ...approvalListQuery,
          offset: 0,
        }));
        break;
      }
    }
  }, [
    approvalListQuery,
  ]);

  // Loading more approval data form pagination
  useEffect(() => {
    if (tableState.page < approvalList.length / tableState.pageSize || !approvalList.length || approvalList.length >= approvalListCount) return;

    setTableState((prev) => ({
      ...prev,
      loading: true,
    }));

    switch (selectedTab) {
      case ApprovalTypeTabs.StudentApprovals: {
        dispatch(getStudentApprovalList({
          ...approvalListQuery,
          offset: tableState.pageSize * (tableState.page - 1),
        }));
        break;
      }
      case ApprovalTypeTabs.TransferApprovals: {
        dispatch(getTransferApprovalList({
          ...approvalListQuery,
          offset: tableState.pageSize * (tableState.page - 1),
        }));
        break;
      }
      case ApprovalTypeTabs.NotificationApprovals: {
        dispatch(getNotificationApprovalList({
          ...approvalListQuery,
          offset: tableState.pageSize * (tableState.page - 1),
        }));
        break;
      }
      case ApprovalTypeTabs.SkillTrainingApprovals: {
        dispatch(getSkillTrainingApprovalList({
          ...approvalListQuery,
          offset: tableState.pageSize * (tableState.page - 1),
        }));
        break;
      }
      case ApprovalTypeTabs.ResourceApprovals: {
        dispatch(getResourceApprovalList({
          ...approvalListQuery,
          offset: tableState.pageSize * (tableState.page - 1),
        }));
        break;
      }
      case ApprovalTypeTabs.ApplicationApprovals: {
        dispatch(getApplicationApprovalList({
          ...approvalListQuery,
          offset: tableState.pageSize * (tableState.page - 1),
        }));
        break;
      }
      default: { break; }
    }
  },
  [
    tableState.page,
    tableState.pageSize,
  ]);

  useEffect(() => {
    setTableState((prev) => ({
      ...prev,
      loading: false,
      rows: approvalList,
    }));

    setTimeout(() => {
      setSelectionModel(prevSelectionModel.current);
      setSelectedUsers(prevSelectedUsers.current);
    });
  }, [
    approvalList,
  ]);

  const handleDetailsNavigation = (event: GridRowParams): void => {
    if (selectedTab === ApprovalTypeTabs.StudentApprovals) {
      navigate(AppRoutes.userDetails.path.replace(':userId', event.row.userId.toString()));
    }
    else if (selectedTab === ApprovalTypeTabs.NotificationApprovals) {
      navigate(AppRoutes.notificationDetails.path.replace(':notificationId', event.row.id.toString()));
    }
    else if (selectedTab === ApprovalTypeTabs.SkillTrainingApprovals) {
      navigate(AppRoutes.skillTrainingDetails.path.replace(':id', event.row.id.toString()));
    }
    else if (selectedTab === ApprovalTypeTabs.TransferApprovals) {
      navigate(AppRoutes.userDetails.path.replace(':userId', event.row.userId.toString()));
    }
    else if (selectedTab === ApprovalTypeTabs.ResourceApprovals) {
      const contentType = lowerCase(event.row.contentType);
      if (contentType === ResourceType.scholarship) {
        navigate(AppRoutes.scholarshipDetails.path.replace(':scholarshipId', event.row.id.toString()));
      }
      else if (contentType === ResourceType.internship) {
        navigate(AppRoutes.internshipDetails.path.replace(':resourceId', event.row.id.toString()), {
          state: {
            type: ResourceType.internship,
          },
        });
      }
      else if (contentType === ResourceType.other) {
        navigate(AppRoutes.opportunityDetails.path.replace(':resourceId', event.row.id.toString()), {
          state: {
            type: ResourceType.opportunity,
          },
        });
      }
    }
    else if (selectedTab === ApprovalTypeTabs.ApplicationApprovals) {
      navigate(AppRoutes.scholarshipApplicationDetails.path.replace(':id', event.row.userId.toString()), {
        state: {
          type: ResourceType.scholarship,
          id: event.row.scholarshipId,
          title: event.row.scholarship,
          applicationId: event.row.id,
          returnPage: location.pathname,
        },
      });
    }
  };

  const formValidationSchema = yup.object().shape({
    search: yup.string(),
  });

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const { handleSubmit } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    setApprovalListQuery((prev) => ({
      ...prev,
      search: values.search,
    }));
  };

  return (
    <div id="approval-list">
      <Box className="page-content card-background">
        <Tabs
          className="main-page-tabs"
          variant="fullWidth"
          value={selectedTab}
          onChange={(_, tabName) => setSelectedTab(tabName)}
        >
          {(canUseAuthComponent(user.roles, AppRoutes.studentApprovals.authorizedRoles) && !user.isInstitutionAdmin && !user.isAdultAdmin) && (
            <Tab label="Students" value={ApprovalTypeTabs.StudentApprovals} onClick={() => navigate(AppRoutes.studentApprovals.path)} />
          )}
          {(canUseAuthComponent(user.roles, AppRoutes.transferApprovals.authorizedRoles) && !user.isInstitutionAdmin && !user.isAdultAdmin) && (
            <Tab label="Transfers" value={ApprovalTypeTabs.TransferApprovals} onClick={() => navigate(AppRoutes.transferApprovals.path)} />
          )}
          {(canUseAuthComponent(user.roles, AppRoutes.notificationApprovals.authorizedRoles) && !user.isInstitutionAdmin && !user.isAdultAdmin) && (
            <Tab label="Notifications" value={ApprovalTypeTabs.NotificationApprovals} onClick={() => navigate(AppRoutes.notificationApprovals.path)} />
          )}
          {(canUseAuthComponent(user.roles, AppRoutes.resourceApprovals.authorizedRoles) && !user.isInstitutionAdmin && !user.isAdultAdmin) && (
            <Tab label="Resources" value={ApprovalTypeTabs.ResourceApprovals} onClick={() => navigate(AppRoutes.resourceApprovals.path)} />
          )}
          {(canUseAuthComponent(user.roles, AppRoutes.applicationApprovals.authorizedRoles) && !user.isInstitutionAdmin && !user.isAdultAdmin) && (
            <Tab label="Applications" value={ApprovalTypeTabs.ApplicationApprovals} onClick={() => navigate(AppRoutes.applicationApprovals.path)} />
          )}
          {(canUseAuthComponent(user.roles, AppRoutes.skillTrainingApprovals.authorizedRoles) && !user.isInstitutionAdmin && !user.isAdultAdmin) && (
            <Tab label="Skill Trainings" value={ApprovalTypeTabs.SkillTrainingApprovals} onClick={() => navigate(AppRoutes.skillTrainingApprovals.path)} />
          )}
        </Tabs >

        <div className="table-buttons-and-filters flex_jbetween">
          <div className="filters flex_acenter">
            <form className="content-container" onSubmit={handleSubmit(submitForm)}>
              <FormProvider {...methods}>
                <SearchInput
                  className="main-input search"
                  name="search"
                  onClear={handleSubmit(submitForm)}
                />
              </FormProvider>
            </form>
            {(selectedTab !== ApprovalTypeTabs.SkillTrainingApprovals && canUseAuthComponent(user.roles, [
              UserRole.admin,
              UserRole.districtAdmin,
            ])) && (
              <div className="flex_acenter filter-button"
                onClick={(e) => setFilterAnchorElement(e.currentTarget)}
              >
                <img src={AppAssetPaths.icons.FILTER} alt="filter" />
                <p>Filter</p>
              </div>
            )}
          </div>

          <div className="flex_acenter">
            {(selectedTab === ApprovalTypeTabs.StudentApprovals || selectedTab === ApprovalTypeTabs.TransferApprovals) && (
              <DownloadButton
                variant="contained"
                startIcon={<img src={AppAssetPaths.icons.DOWNLOAD} alt="download" />}
                disableElevation
                onClick={() => selectedTab === ApprovalTypeTabs.StudentApprovals
                  ? exportPendingAccountsCSV(approvalListQuery.userType)
                  : exportPendingTransfersCSV(approvalListQuery.userType)
                }
              >
                Download
              </DownloadButton>
            )}
          </div>
        </div>

        {(selectedTab === ApprovalTypeTabs.StudentApprovals && !user.isInstitutionAdmin && !user.isAdultAdmin) && (
          <div className="select-all-container flex_acenter">
            <p>{selectionModel.length} Selected</p>
            <Button
              variant="contained"
              disabled={!selectionModel.length}
              onClick={() => setApproveRejectModalOpen({
                open: true,
                approving: true,
              })}
              disableElevation
            >
              Bulk Approve
            </Button>

            <Button
              variant="contained"
              disabled={!selectionModel.length}
              onClick={() => setApproveRejectModalOpen({
                open: true,
                approving: false,
              })}
              disableElevation
            >
              Bulk Reject
            </Button>
          </div>
        )}

        <DataGrid
          className="table"
          {...tableState}
          components={{
            Pagination: Pagination,
            NoRowsOverlay: noDataElement,
            NoResultsOverlay: noDataElement,
          }}
          componentsProps={{
            pagination: {
              page: tableState.page,
              count: Math.ceil(approvalListCount / tableState.pageSize),
              onChange: (_: void, page: number) => {
                prevSelectionModel.current = selectionModel;
                prevSelectedUsers.current = selectedUsers;
                setTableState((prev) => ({
                  ...prev,
                  page,
                }));
              },
            },
          }}
          onColumnHeaderClick={(params: GridColumnHeaderParams, event) => {
            if (params.field === '__check__' && !getSelectAllBulkApproveRejectTooltipSeen()) {
              setSelectAllTooltipAnchorElement(event.currentTarget);
            }
          }}
          pagination
          paginationMode="server"
          sortingMode="server"
          sortModel={approvalListQuery.sortModel}
          onSortModelChange={(newSortModel: GridSortModel) => {
            setApprovalListQuery((prev) => ({
              ...prev,
              sortModel: newSortModel,
            }));
          }}
          onSelectionModelChange={(newSelectionModel: GridSelectionModel) => {
            setSelectionModel(newSelectionModel);
            if (selectedTab === ApprovalTypeTabs.StudentApprovals) {
              const filteredList = (approvalList as StudentApproval[]).filter((u: StudentApproval) => newSelectionModel.includes(u.id ? u.id : 0));
              setSelectedUsers([
                ...prevSelectedUsers.current,
                ...filteredList,
              ]);
            }
          }}
          selectionModel={selectionModel}
          onRowClick={(event) => handleDetailsNavigation(event)}
          checkboxSelection={selectedTab === ApprovalTypeTabs.StudentApprovals && !user.isInstitutionAdmin && !user.isAdultAdmin}
          disableColumnFilter
          disableColumnMenu
          hideFooterSelectedRowCount
        />

        <TableRowCount
          length={approvalList.length}
          pageSize={tableState.pageSize}
          page={tableState.page}
          totalCount={approvalListCount}
        />
      </Box>

      {/* Filter Menu */}
      {((selectedTab === ApprovalTypeTabs.StudentApprovals) ||
        selectedTab === ApprovalTypeTabs.TransferApprovals) && (
        <ApprovalFilter
          anchorElement={filterAnchorElement}
          setAnchorElement={setFilterAnchorElement}
          userTypeList={userTypeList}
          setFiltersCallback={(userType?: string) => {
            setApprovalListQuery((prev) => ({
              ...prev,
              approvalType: getApprovalType(selectedTab),
              userType,
            }));
          }}
        />
      )}

      {(((selectedTab === ApprovalTypeTabs.ResourceApprovals) ||
        selectedTab === ApprovalTypeTabs.NotificationApprovals) ||
        selectedTab === ApprovalTypeTabs.ApplicationApprovals) && (
        <ApprovalOrgTypeFilter
          anchorElement={filterAnchorElement}
          setAnchorElement={setFilterAnchorElement}
          orgTypeList={orgTypeList}
          setFiltersCallback={(schoolType?: string) => {
            setApprovalListQuery((prev) => ({
              ...prev,
              approvalType: getApprovalType(selectedTab),
              schoolType,
            }));
          }}
        />
      )}

      <Popper
        id="select-all-tooltip"
        className="interactive-tooltip"
        anchorEl={selectAllTooltipAnchorElement}
        open={Boolean(selectAllTooltipAnchorElement)}
        placement="right-start"
      >
        <span className="arrow" />
        <div className="tooltip-content">
          <h4>Select All</h4>
          <p>This checkbox selects all users on this page only. To select more than 100 users, go to the next page(s) available.</p>
          <div className="flex_jend">
            <Button
              variant="outlined"
              onClick={() => {
                setSelectAllBulkApproveRejectTooltipSeen();
                setSelectAllTooltipAnchorElement(null);
              }}>
              Got it
            </Button>
          </div>
        </div>
      </Popper>

      <BulkApproveRejectModal
        parentSelectionModel={selectionModel}
        setSelectionModelCallback={setSelectionModel}
        selectedUsers={uniqWith(selectedUsers.filter((u: StudentApproval) => u.id && selectionModel.includes(u.id)), isEqual)}
        setSelectedUsersCallback={setSelectedUsers}
        open={approveRejectModalOpen.open}
        approving={approveRejectModalOpen.approving}
        onClose={() => {
          setApproveRejectModalOpen({
            open: false,
            approving: false,
          });
        }} />
    </div>
  );
};

export default ApprovalList;
