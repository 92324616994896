import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { StateStatus } from '../../app/app.types';
import {
  Notification, EmptyNotification
} from './notifications.model';
import {
  getNotificationList, getNotificationById, approveNotification
} from './notifications.thunks';

export interface NotificationState {
  notificationList: {
    status: StateStatus;
    data: Notification[];
    totalNotificationCount: number;
    loaded: boolean;
  },
  notification: {
    status: StateStatus;
    data: Notification;
  }
}

const initialState: NotificationState = {
  notificationList: {
    status: StateStatus.IDLE,
    data: [],
    totalNotificationCount: 0,
    loaded: false,
  },
  notification: {
    status: StateStatus.IDLE,
    data: EmptyNotification,
  },
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationList.pending, (state) => {
        state.notificationList.status = StateStatus.LOADING;
      })
      .addCase(getNotificationList.fulfilled, (state, action) => {
        state.notificationList.status = StateStatus.IDLE;
        state.notificationList.data = action.payload.notifications;
        state.notificationList.totalNotificationCount = action.payload.count;
        state.notificationList.loaded = true;
      })
      .addCase(getNotificationList.rejected, (state) => {
        state.notificationList.status = StateStatus.FAILED;
      })
      .addCase(getNotificationById.pending, (state) => {
        state.notification.status = StateStatus.LOADING;
      })
      .addCase(getNotificationById.fulfilled, (state, action) => {
        state.notification.status = StateStatus.IDLE;
        state.notification.data = action.payload;
      })
      .addCase(getNotificationById.rejected, (state) => {
        state.notification.status = StateStatus.FAILED;
      })
      .addCase(approveNotification.pending, (state) => {
        state.notification.status = StateStatus.LOADING;
      })
      .addCase(approveNotification.fulfilled, (state, action) => {
        state.notification.status = StateStatus.IDLE;
        state.notification.data.approved = action.payload.approved;
      })
      .addCase(approveNotification.rejected, (state) => {
        state.notification.status = StateStatus.FAILED;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const {  } = notificationSlice.actions;

/* Selectors */
const selectNotificationListStatus = (state: RootState): StateStatus => state.notification.notificationList.status || StateStatus.IDLE;
const selectNotificationList = (state: RootState): Notification[] => state.notification.notificationList.data || [];
const selectTotalNotificationListCount = (state: RootState): number => state.notification.notificationList.totalNotificationCount;
const selectNotificationListLoaded = (state: RootState): boolean => state.notification.notificationList.loaded;
const selectNotification = (state: RootState): Notification => state.notification.notification.data;

export const notificationSelectors = {
  selectNotificationListStatus,
  selectNotificationList,
  selectTotalNotificationListCount,
  selectNotificationListLoaded,
  selectNotification,
};

export default notificationSlice.reducer;
