import React from 'react';
import ExpandableCard from '../ExpandableCard';
import { Scholarship } from '../../../features/scholarship/scholarship.model';

import './ApplicationsTable.scss';


interface ApplicationsTableProps {
  applications?: Scholarship[];
  defaultExpanded?: boolean;
}

const ApplicationsTable: React.FC<ApplicationsTableProps> = ({ applications, defaultExpanded }) => {
  const EMPTY = '-';

  if (!applications) {
    applications = [];
  }

  const getDataRow = (header: boolean, key?: number, title?: string, orgName?: string, orgUrl?: string) => (
    <div key={key} className={`applications-data-row flex ${header ? 'header' : ''}`}>
      <div className="applications-data-cell flex_column_astart">
        <div className="applications-data-label">Scholarship Title</div>
        <div className="applications-data-content org-data">{ title || EMPTY }</div>
      </div>
      <div className="applications-data-cell flex_column_astart">
        <div className="applications-data-label">Organizational Name</div>
        <div className="applications-data-content org-data">{ orgName?.toLowerCase() || EMPTY }</div>
      </div>
      <div className="applications-data-cell flex_column_astart">
        <div className="applications-data-label">Organizational URL</div>
        <div className="applications-data-content">{ orgUrl || EMPTY }</div>
      </div>
    </div>
  );

  const getDetails = () => (
    <div className="applications-data-wrapper flex_column">
      { getDataRow(true) }
      { !!applications?.length && applications.map((app, i) => getDataRow(false, i, app.title, app.organizationName, app.organizationUrl)) }
    </div>
  );

  return (
    <ExpandableCard
      className="user-applications-table"
      summary={<span>Applications</span>}
      details={getDetails()}
      expandable
      defaultExpanded={defaultExpanded}
    />
  );
};

export default ApplicationsTable;
