import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Button } from '@mui/material';
import dayjs from 'dayjs';

import './GenerateStudentCode.modal.scss';


interface GenerateStudentCodeModalProps {
  code: string;
  expireDate: string;
  open: boolean;
  onClose: () => void;
}

const GenerateStudentCodeModal: React.FC<GenerateStudentCodeModalProps> = ({
  code,
  expireDate,
  open,
  onClose,
}) => {
  
  const day =  dayjs(expireDate).format('MM/DD/YYYY');
  let hour = dayjs(expireDate).hour();
  const minutes = dayjs(expireDate).minute();
  const amOrPm = (hour >= 12 ? 'pm' : 'am');

  if(hour > 12){
    hour = dayjs(expireDate).hour() - 12;
  }

  return (
    < Dialog id="generate-code-modal" open={open} onClose={onClose} >
      <DialogContent className="modal-content flex_col_jcenter_acenter" >
        <h2>Account Confirmation Code</h2>

        <b>Share this code with the student who needs to activate their account.</b>

        <div className="code-container flex_col_jcenter_acenter">
          <h4>Confirmation Code</h4>
          <p>{code}</p>
        </div>

        <p> This code is for one time use only.  It expires on {day} at {hour}:{minutes}{amOrPm} </p>

        <Button
          variant="contained"
          onClick={onClose}
          disableElevation
        >
          Close
        </Button>
      </DialogContent>
    </Dialog >
  );
};

export default GenerateStudentCodeModal;
