import React from 'react';
import ExpandableCard from '../ExpandableCard';
import {
  Assessment, AssessmentsData
} from '../../../features/assessments/assessments.model';

import './AssessmentsTable.scss';


interface AssessmentsTableProps {
  assessments: AssessmentsData;
  defaultExpanded?: boolean;
}

const AssessmentsTable: React.FC<AssessmentsTableProps> = ({ assessments, defaultExpanded }) => {

  const getAssessmentResults = (a: Assessment) => {
    return (
      <div className="assessment-results">
        <div className="assessment-results-header">{ a.name }</div>
        <div className="assessment-results-data">
          {a.results.length > 0 ? (
            a.results.map((d, i) => (
              <div key={i} className="data-row flex_row_jbetween">
                <div className="data-key">{ d.key }</div>
                <div className="data-value">{ d.value }</div>
              </div>
            ))
          ) : (
            'Student has not completed this assessment.'
          )}
        </div>
      </div>
    );
  };

  const getDetails = () => (
    <div className="assessment-wrapper flex">
      <div className="assessment-column">
        <div className="assessment-cell">
          { getAssessmentResults(assessments.cci) }
        </div>
        <div className="assessment-cell">
          { getAssessmentResults(assessments.cciQuick) }
        </div>
        <div className="assessment-cell">
          { getAssessmentResults(assessments.entrepreneurial) }
        </div>
        <div className="assessment-cell">
          { getAssessmentResults(assessments.interestProfiler) }
        </div>
        <div className="assessment-cell">
          { getAssessmentResults(assessments.learningStyles) }
        </div>
      </div>
      <div className="assessment-column">
        <div className="assessment-cell">
          { getAssessmentResults(assessments.occupationSort) }
        </div>
        <div className="assessment-cell">
          { getAssessmentResults(assessments.realityCheck) }
        </div>
        <div className="assessment-cell">
          { getAssessmentResults(assessments.wes) }
        </div>
        <div className="assessment-cell">
          { getAssessmentResults(assessments.wesJr) }
        </div>
        <div className="assessment-cell">
          { getAssessmentResults(assessments.wil) }
        </div>
      </div>
    </div>
  );

  return (
    <ExpandableCard
      className="assessments-table"
      summary={<span>Assessment Results</span>}
      details={getDetails()}
      expandable
      defaultExpanded={defaultExpanded}
    />
  );
};

export default AssessmentsTable;
