/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { GridSortModel } from '@mui/x-data-grid';
import { Organization } from '../organization/organization.model';


export enum FinancialAidListItemNames {
  FinancialAidInfo = 'Financial Aid Information',
  FillOutFafsa = 'Fill Out a FAFSA Form'
}

export enum FafsaLinkNames {
  FafsaVideoLinkEnglish = 'Fafsa Video English',
  FafsaVideoLinkSpanish = 'Fafsa Video Spanish',
  HowAidWorks = 'How Federal Aid Works',
  FormPrep = 'Form Preparation',
  WorkStudyJobs = 'Work-Study Jobs',
  Deadlines = 'Deadlines'
}

export interface Applicant {
  id: number;
  userId: number;
  firstName: string;
  lastName: string;
  fullName: string;
  roles: [];
  email: string;
  institutionMetadataId: number;
  applied: boolean;
  institutionName: string;
  organizationId: number;
  organizationName: string;
  graduationYear: string;
  createdAt: string;
}

export interface InstitutionType {
  id: number;
  label: string;
}

export const ConstructInstitutionType = (data: any): InstitutionType => {
  return {
    id: data.id,
    label: data.name,
  };
};

export interface CertificateDegree {
  id: number;
  label: string;
  value: string;
}

// Decided to hard code this array since I don't think the name of degrees are going to change
export const CertificateDegreeListItems: CertificateDegree[] = [
  {
    id: 0,
    label: 'Certificate Program',
    value: 'certificateProgram',
  },
  {
    id: 1,
    label: 'Applied Associate',
    value: 'appliedAssociate',
  },
  {
    id: 2,
    label: 'Associate',
    value: 'associate',
  },
  {
    id: 3,
    label: 'Bachelors',
    value: 'bachelors',
  },
  {
    id: 4,
    label: 'Masters',
    value: 'masters',
  },
  {
    id: 5,
    label: 'Doctorate',
    value: 'doctorate',
  },
];

export interface CollegeDegreesOffered {
  certificateProgram: boolean;
  appliedAssociate: boolean;
  associate: boolean;
  bachelors: boolean;
  masters: boolean;
  doctorate: boolean;
}

export interface TuitionOption {
  label: string;
  amount: number;
}

export interface College {
  id: number;
  name: string;
  label: string;
  logo: string;
  type: InstitutionType;
  createdAt: string;
  updatedAt: string;
  studentsEnrolled: number;
  overview: string;
  adultOnly: boolean;
  hasFeeWaiver: boolean;
  feeWaiverCode: string;
  feeWaiverExpirationDate: string;
  feeWaiverExplanation: string;
  postOctoberApplicationFee: number;
  enrollmentFee: number;
  enrollmentFeeStartDate: string;
  utahProgramsMajorGuide: boolean;
  scholarshipApplicationDeadlineNote?: string;
  organization?: Organization;

  address: {
    city: string;
    state: string;
    zipcode: string;
    streetAddress: string;
  }
  admissions: {
    phone: string;
    email: string;
  }
  deadlines: {
    fallAdmission: string;
    scholarshipApplication: string;
    financialAidPriority: string;
  }
  degreesOffered: CollegeDegreesOffered;
  websiteLinks: {
    scholarshipPage?: string;
    scholarshipApplication?: string;
    financialAidPage?: string;
    applicationPage?: string;
    tourVideo?: string;
    website?: string;
    campusLink?: string;
    scheduleCampusTourLink?: string;
  };
  socialMediaLinks: {
    linkedIn?: string;
    facebook?: string;
    instagram?: string;
    twitter?: string;
    youtube?: string;
    pintrest?: string;
    discord?: string;
  };
  tuition: {
    resident: string;
    nonResident: string;
    options?: TuitionOption[];
  }
}

export const EmptyCollege: College = {
  id: 0,
  name: '',
  label: '',
  logo: '',
  type: {
    id: 0,
    label: '',
  },
  createdAt: '',
  updatedAt: '',
  studentsEnrolled: 0,
  overview: '',
  adultOnly: false,
  hasFeeWaiver: false,
  feeWaiverCode: '',
  feeWaiverExpirationDate: '',
  feeWaiverExplanation: '',
  postOctoberApplicationFee: 0,
  enrollmentFee: 0,
  enrollmentFeeStartDate: '',
  utahProgramsMajorGuide: false,

  address: {
    city: '',
    state: '',
    zipcode: '',
    streetAddress: '',
  },
  admissions: {
    phone: '',
    email: '',
  },
  deadlines: {
    fallAdmission: '',
    scholarshipApplication: '',
    financialAidPriority: '',
  },
  degreesOffered: {
    appliedAssociate: false,
    associate: false,
    bachelors: false,
    certificateProgram: false,
    doctorate: false,
    masters: false,
  },
  websiteLinks: {
  },
  socialMediaLinks: {
  },
  tuition: {
    resident: '',
    nonResident: '',
  },
};

// TODO: might want to save this in global state so the lastUpdated field can be changed by the contents of the pages
export interface FinancialAidListItem {
  id: number;
  name: string;
  lastUpdated: string;
}

export const financialAidListItems: FinancialAidListItem[] = [
  {
    id: 0,
    name: FinancialAidListItemNames.FinancialAidInfo,
    lastUpdated: '',
  },
  {
    id: 1,
    name: FinancialAidListItemNames.FillOutFafsa,
    lastUpdated: '',
  },
];

export interface FinancialAidInfoSection {
  id: number;
  // This separate 'sectionId' is needed for the edit form where 'useFormArray' needs to use 'id' for something else.
  sectionId: number;
  name: string;
  description: string;
  url: string;
  updatedAt: string;
}

export interface FafsaLink {
  id: number;
  name: string;
  url: string;
  updatedAt: string;
}

export const emptyFafsaLink: FafsaLink = {
  id: -1,
  name: '',
  url: '',
  updatedAt: '',
};

export interface FafsaLinks {
  fafsaVideoEnglish: FafsaLink;
  fafsaVideoSpanish: FafsaLink;
  howAidWorks: FafsaLink;
  formPrep: FafsaLink;
  workStudyJobs: FafsaLink;
  deadlines: FafsaLink;
}

export interface ApplicantListQueryParams {
  offset: number;
  limit?: number;
  search?: string;
  sortModel?: GridSortModel;
  colleges?: number[];
  applicationInterested?: 'application' | 'interested';
}

export interface CollegeListQueryParams {
  offset: number;
  limit?: number;
  search?: string;
  sortModel?: GridSortModel
}


export const ConstructApplicant = (data: any): Applicant => {
  return {
    id: data.id,
    userId: data.user_id,
    firstName: data.user.first_name,
    lastName: data.user.last_name,
    fullName: data.user.full_name || `${data.user.first_name} ${data.user.last_name}`,
    roles: data.user.roles,
    email: data.user.email,
    institutionMetadataId: data.institution_metadata_id,
    applied: data.applied,
    institutionName: data.institution.name,
    organizationId: data.user.organization.id,
    organizationName: data.user.organization.name,
    graduationYear: data.user.graduation_year,
    createdAt: data.created_at,
  };
};

export const ConstructCollegeForApi = (body: any) => {
  return {
    name: body.name,
    institution_type_id: body.schoolType.id,
    website: body.collegeWebsiteUrl.url,
    tour_video_link: body.campusTourVideoLink.url,
    application_page_link: body.collegeApplicationUrl.url,
    scholarship_page_link: body.scholarshipWebsiteUrl.url,
    financial_aid_page_link: body.financialAidPageLink.url,
    scholarship_application_link: body.scholarshipWebsiteUrl.url,
    has_fee_waiver: body.displayApplicationFeeWaiver,
    fee_waiver_code: body.displayApplicationFeeWaiver ? body.applicationFeeWaiverCode : null,
    fee_waiver_expiration_date: body.displayApplicationFeeWaiver ? body.applicationFeeWaiverExpDate : null,
    fee_waiver_explanation: body.displayApplicationFeeWaiver ? body.waiverExplanation : null,
    resident_tuition: body.residentTuition ? body.residentTuition : 0,
    non_resident_tuition: body.nonResidentTuition ? body.nonResidentTuition : 0,
    fall_admission_deadline: body.fallAdmissionDeadlineChecked ? body.fallAdmissionDeadlineDate : 'Year-Round',
    scholarship_application_deadline: body.scholarshipApplicationDeadlineChecked ? body.scholarshipApplicationDeadline : 'Year-Round',
    financial_aid_priority_deadline: body.financialAidPriorityDeadline ? body.financialAidPriorityDeadline : 'Year-Round',
    admissions_phone_number: body.admissionsPhone,
    admission_email: body.admission_email ? body.admission_email : '',
    has_certificate_program: body.degreesOffered.some((e: CertificateDegree) => e.value === 'certificateProgram'),
    has_applied_associate: body.degreesOffered.some((e: CertificateDegree) => e.value === 'appliedAssociate'),
    has_associate: body.degreesOffered.some((e: CertificateDegree) => e.value === 'associate'),
    has_bachelors: body.degreesOffered.some((e: CertificateDegree) => e.value === 'bachelors'),
    has_masters: body.degreesOffered.some((e: CertificateDegree) => e.value === 'masters'),
    has_doctorate: body.degreesOffered.some((e: CertificateDegree) => e.value === 'doctorate'),
    logo: body.logo,
    facebook_link: body.facebook || null,
    instagram_link: body.instagram || null,
    twitter_link: body.twitter || null,
    youtube_link: body.youtube || null,
    linkedin_link: body.linkedin || null,
    pintrest_link: body.pintrest || null,
    discord_link: body.discord || null,
    overview: body.overview || null,
    address: body.streetAddress,
    city: body.city,
    state: body.state.abbreviation,
    zipcode: body.zipcode.zipcode,
    enrollment_fee: body.applicationFeeAmount || null,
    enrollment_fee_start_date: body.applicationEnrollmentChecked ? body.applicationEnrollmentStartDate : 'Year-Round',
    utah_programs_major_guide: body.utahMajorsProgram,
    number_of_students: body.numberOfEnrolled,
    campus_link: body.allCampusLocationsLink,
    tuition_options: (body.tuitionOptions && !!body.tuitionOptions.length) ? body.tuitionOptions : null,
    schedule_campus_tour_link: body.scheduleCampusTourLink.url,
    scholarship_application_deadline_note: body.customText || null,
  };
};

export const ConstructCollege = (data: any): College => {
  return {
    id: data.id,
    name: data.name,
    label: data.name,
    logo: data.logo,
    type: data.institution_type,
    createdAt: data.created_at,
    updatedAt: data.updated_at,
    studentsEnrolled: data.number_of_students || null,
    overview: data.overview,
    adultOnly: data.is_adult_only,
    hasFeeWaiver: data.has_fee_waiver,
    feeWaiverCode: data.fee_waiver_code,
    feeWaiverExpirationDate: data.fee_waiver_expiration_date,
    feeWaiverExplanation: data.fee_waiver_explanation,
    postOctoberApplicationFee: data.post_october_application_fee || null,
    enrollmentFee: data.enrollment_fee || null,
    enrollmentFeeStartDate: data.enrollment_fee_start_date,
    utahProgramsMajorGuide: data.utah_programs_major_guide,
    scholarshipApplicationDeadlineNote: data.scholarship_application_deadline_note,
    organization: data.organization || null,

    address: {
      city: data.city,
      state: data.state,
      zipcode: data.zipcode,
      streetAddress: data.address,
    },
    admissions: {
      phone: data.admissions_phone_number,
      email: data.admissions_email,
    },
    deadlines: {
      fallAdmission: data.fall_admission_deadline,
      scholarshipApplication: data.scholarship_application_deadline,
      financialAidPriority: data.financial_aid_priority_deadline,
    },
    degreesOffered: {
      certificateProgram: data.has_certificate_program,
      appliedAssociate: data.has_applied_associate,
      associate: data.has_associate,
      bachelors: data.has_bachelors,
      masters: data.has_masters,
      doctorate: data.has_doctorate,
    },
    websiteLinks: {
      scholarshipPage: data.scholarship_page_link,
      scholarshipApplication: data.scholarship_application_link,
      financialAidPage: data.financial_aid_page_link,
      applicationPage: data.application_page_link,
      tourVideo: data.tour_video_link,
      website: data.website,
      campusLink: data.campus_link,
      scheduleCampusTourLink: data.schedule_campus_tour_link,
    },
    socialMediaLinks: {
      linkedIn: data.linkedin_link,
      facebook: data.facebook_link,
      instagram: data.instagram_link,
      twitter: data.twitter_link,
      youtube: data.youtube_link,
      pintrest: data.pintrest_link,
      discord: data.discord_link,
    },
    tuition: {
      resident: data.resident_tuition,
      nonResident: data.non_resident_tuition,
      options: data.tuition_options || null,
    },
  };
};

export const ConstructFinancialAidInfoSection = (data: any): FinancialAidInfoSection => {
  return {
    id: data.id,
    sectionId: data.id,
    name: data.name || '',
    description: data.description || '',
    url: data.url || '',
    updatedAt: data.updated_at,
  };
};

export const ConstructFafsaLink = (data: any): FafsaLink => {
  return {
    id: data.id,
    name: data.name || '',
    url: data.url || '',
    updatedAt: data.updated_at,
  };
};
