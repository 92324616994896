/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ExpandableCard from '../../../../components/details/ExpandableCard';
import TextInput from '../../../../components/forms/TextInput';
import {
  useAppDispatch, useAppSelector
} from '../../../../app/hooks';
import {
  pathwaySelectors, toggleCertificateActiveState
} from '../../../../features/pathways/pathways.slice';
import { userSelectors } from '../../../../features/user/user.slice';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


interface CertificateFormProps {
  editMode: boolean;
  errors: any;
  setValueCallback: (label: any, value: any) => void;
}

const CertificateForm: React.FC<CertificateFormProps> = ({
  editMode,
  errors,
  setValueCallback,
}) => {

  const dispatch = useAppDispatch();

  const user = useAppSelector(userSelectors.selectUser);
  const pathway = useAppSelector(pathwaySelectors.selectPathwayDetails);

  return (
    <ExpandableCard
      className="course-form"
      summary={<span>Certificate</span>}
      details={
        <div className="certificate-container">
          <div className="course-control flex_jend">
            <span
              className="hide-button flex_acenter"
              onClick={() => {
                dispatch(toggleCertificateActiveState());
                setValueCallback('certificateActive', !pathway.certificateActive);
              }}
            >
              {pathway.certificateActive ? (
                <div className="flex_acenter">
                  <p>Hide Certificate</p>
                  <VisibilityIcon className="eye-icon" />
                </div>
              ) : (
                <div className="flex_acenter">
                  <p>Show Certificate</p>
                  <VisibilityOffIcon className="eye-icon" />
                </div>
              )}
            </span>
          </div>
          <div className="form-content flex_row_jbetween">
            <div className="form-column">
              <TextInput
                name="certificateTitle"
                label="TITLE"
                errorMessage={errors?.certificateTitle?.message}
                defaultValue={editMode ? pathway.certificateTitle : ''}
                type="text"
                size="small"
                disabled={user.isDistrictAdmin || user.isHighSchoolAdmin}
              />
            </div>
            <div className="form-column">
              <TextInput
                name="certificateText"
                label="TEXT"
                errorMessage={errors?.certificateText?.message}
                defaultValue={editMode ? pathway.certificateText : ''}
                type="text"
                size="small"
                multiLine
                InputProps={{
                  inputProps: {
                    maxRows: 4,
                  },
                }}
                disabled={user.isDistrictAdmin || user.isHighSchoolAdmin}
              />
            </div>
          </div>
        </div>
      }
      expandable
    />
  );
};

export default CertificateForm;
