import React, {
  useEffect, useState
} from 'react';
import { startCase } from 'lodash';
import { Button } from '@mui/material';
import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import {
  useParams, useNavigate
} from 'react-router-dom';
import {
  AppAssetPaths, AppRoutes
} from '../../../app/app.types';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { masterKeySelectors } from '../../../features/masterKey/masterKey.slice';
import {
  getMasterKeyDetails, getMasterKeyTypeList
} from '../../../features/masterKey/masterKey.thunks';
import { UserType } from '../../../features/user/userType.model';

import './MasterKeyDetails.scss';


interface MasterKeyDetailsProps { }

const MasterKeyDetails: React.FC<MasterKeyDetailsProps> = () => {

  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const masterKeyDetails = useAppSelector(masterKeySelectors.selectMasterKeyDetails);
  const masterKeyTypeList = useAppSelector(masterKeySelectors.selectMasterKeyTypeList);
  const masterKeyTypeListLoaded = useAppSelector(masterKeySelectors.selectMasterKeyTypeListLoaded);

  const [
    QAs,
    setQAs,
  ] = useState<JSX.Element[]>();


  useEffect(() => {
    if (params.masterKeyId) {
      dispatch(getMasterKeyDetails(parseInt(params.masterKeyId)));
    }

    !masterKeyTypeListLoaded && dispatch(getMasterKeyTypeList());
  }, []);

  useEffect(() => {
    if (masterKeyDetails.questions && masterKeyDetails.questions.length > 0) {
      setQAs(generateQAs(masterKeyDetails.questions));
    }
  }, [
    masterKeyDetails,
  ]);

  const generateQAs = (data: []) => {
    let count = 0;
    const QAs: JSX.Element[] = [];
    data.forEach((q: {
      question: string,
      answers?: Array<string>,
      multi?: boolean,
    }) => {

      count++;
      QAs.push((<hr key={`question-${count}-hr`} ></hr>));

      const imgSource = q.multi ? (<img src={AppAssetPaths.icons.MULTIPLE_CHOICE} alt="" />) : (<img src={AppAssetPaths.icons.WRITTEN_RESPONSE} alt="" />);
      QAs.push((
        <span key={`question-${count}`}>
          <p> QUESTION {count} </p>
          <p> {q.question} </p>
          {imgSource}
        </span>
      ));

      if (q.answers) {
        let aCount = 0;
        q.answers.forEach((a) => {
          aCount++;
          QAs.push((
            <span key={`question-${count}_answer-${aCount}`}>
              <p> ANSWER {aCount} </p>
              <p> {a} </p>
            </span>
          ));
        });
      }
    });

    return QAs;
  };

  return (
    <div className="card-background master-key-details">
      <div className="content-header flex_row_jbetween_acenter">
        <span className="cursor-pointer flex_acenter"
          onClick={() => navigate(AppRoutes.masterKey.path)}>
          <ChevronLeftIcon color="secondary" />
          <h3>Master Key Details</h3>
        </span>

        <Button className="edit-masterkey-button"
          variant="outlined"
          onClick={() => navigate(AppRoutes.editMasterKey.path.replace(':id', masterKeyDetails.id.toString()))}
        >
          Edit
        </Button>
      </div>

      <div className="details-header flex_row_jbetween_acenter">
        <h3> {startCase(masterKeyDetails.title)}</h3>
        <div className="view-all-right flex_acenter">
          <div className="button-border"> {masterKeyDetails.submissionCount} Submissions </div>
          <Button className="view-all-button"
            variant="text"
            onClick={() => navigate(AppRoutes.masterKeySubmissions.path.replace(':masterKeyId', masterKeyDetails.id.toString()), {
              state: {
                title: masterKeyDetails.title,
              },
            })}
          >View All</Button>
        </div>
      </div>


      <div className="main-content flex_column">
        <div className="other-details flex_row_jbetween">
          <div className="left-column flex_col">
            <span>
              <p> MASTER KEY TYPE </p>
              <p> {masterKeyTypeList.find(t => t.id === masterKeyDetails.masterKeyTypeId)?.label || '-'} </p>
            </span>

            <span>
              <p> USER TYPE </p>
              <p> {masterKeyDetails.userTypes.length ? masterKeyDetails.userTypes.map((u: UserType) => u.label).join(', ') : '-'} </p>
            </span>

            <span>
              <p> POINTS </p>
              <p> {masterKeyDetails.points ? masterKeyDetails.points : '-'} </p>
            </span>
          </div>
          <div className="right-column flex_col">
            <span>
              <p> START DATE </p>
              <p> {masterKeyDetails.startDate ? masterKeyDetails.startDate : '-'} </p>
            </span>

            <span>
              <p> END DATE </p>
              <p> {masterKeyDetails.endDate ? masterKeyDetails.endDate : '-'} </p>
            </span>
          </div>
        </div>

        {QAs}
      </div>
    </div>
  );
};

export default MasterKeyDetails;
