import { createAsyncThunk } from '@reduxjs/toolkit';
import { AnnouncementListQueryParams } from './announcement.model';

import * as announcementApi from './announcement.api';

export const getAnnouncementList = createAsyncThunk(
  'announcement/getAnnouncementList',
  async (query: AnnouncementListQueryParams) => {
    return await announcementApi.getAnnouncementList(query);
  }
);

export const getAnnouncementById = createAsyncThunk(
  'announcement/announcement',
  async (announcementId: number) => {
    return await announcementApi.getAnnouncementById(announcementId);
  }
);
