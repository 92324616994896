/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button, Checkbox, CircularProgress, FormControlLabel, FormGroup
} from '@mui/material';
import React, {
  useEffect, useState
} from 'react';
import * as yup from 'yup';
import TextInput from '../../../../components/forms/TextInput';
import {
  FormProvider, SubmitHandler, useFieldArray, useForm
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import './CreateEditCollege.scss';
import DropdownSelect from '../../../../components/forms/DropdownSelect';
import {
  useAppDispatch, useAppSelector
} from '../../../../app/hooks';
import {
  createNewCollege, getAllInstitutionTypes, getCollegeById, updateCollege
} from '../../../../features/collegeApplicationChecklist/collegeApplicationChecklist.thunks';
import { collegeApplicationChecklistSelectors } from '../../../../features/collegeApplicationChecklist/collegeApplicationChecklist.slice';
import {
  CertificateDegreeListItems, TuitionOption
} from '../../../../features/collegeApplicationChecklist/collegeApplicationChecklist.model';
import { StateAbbreviations } from '../../../../util/states';
import { zipcodeSelectors } from '../../../../features/zipcode/zipcode.slice';
import { getZipcodeList } from '../../../../features/zipcode/zipcode.thunks';
import {
  formatPhone, numberOnlyValidation
} from '../../../../util/formatting';
import {
  AppAssetPaths,
  AppRoutes
} from '../../../../app/app.types';
import {
  useNavigate, useParams
} from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import UploadButton from '../../../../components/forms/UploadButton';
import { setAlert } from '../../../../features/alert/alert.slice';
import SubmitButton from '../../../../components/forms/SubmitButton';


interface CreateEditCollegeProps { }

const CreateEditCollege: React.FC<CreateEditCollegeProps> = () => {

  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const institutionTypes = useAppSelector(collegeApplicationChecklistSelectors.selectInstitutionTypes);
  const zipcodeList = useAppSelector(zipcodeSelectors.selectZipcodeList);

  const college = useAppSelector(collegeApplicationChecklistSelectors.selectCollegeDetails);
  const collegeLoaded = useAppSelector(collegeApplicationChecklistSelectors.selectCollegeDetailsLoaded);

  const [
    pageLoaded,
    setPageLoaded,
  ] = useState(false);
  const [
    editMode,
    setEditMode,
  ] = useState(false);

  const [
    fallAdmissionDeadlineChecked,
    setFallAdmissionDeadlineChecked,
  ] = useState(false);

  const [
    financialAidPriorityDeadlineChecked,
    setFinancialAidPriorityDeadlineChecked,
  ] = useState(false);

  const [
    applicationEnrollmentChecked,
    setApplicationEnrollmentChecked,
  ] = useState(false);

  const [
    scholarshipApplicationDeadlineChecked,
    setScholarshipApplicationDeadlineChecked,
  ] = useState(false);

  const [
    displayApplicationFeeWaiverChecked,
    setDisplayApplicationFeeWaiverChecked,
  ] = useState(true);

  const [
    scholarshipsCustomTextChecked,
    setScholarshipsCustomTextChecked,
  ] = useState(false);

  const [
    utahMajorsChecked,
    setUtahMajorsChecked,
  ] = useState(false);

  useEffect(() => {
    dispatch(getAllInstitutionTypes());
    dispatch(getZipcodeList());

    if (params && params.collegeId) {
      dispatch(getCollegeById(+params.collegeId));
    }
    else {
      setPageLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (collegeLoaded && params && params.collegeId) {
      setEditMode(true);
    }
  }, [
    college,
  ]);

  useEffect(() => {
    if (editMode && !!zipcodeList.length) {
      prePopulateValues();
      setPageLoaded(true);
    }
  }, [
    editMode,
    zipcodeList,
  ]);


  const linkValidation = yup.object().shape({
    url: yup.string()
      .isValidUrl()
      .required('Url is required')
      .typeError('Please enter a valid url'),
  });

  const requiredStringValidation = (field: string) => {
    return yup.string()
      .required(`${field} is required`)
      .typeError(`${field} is required`);
  };

  const requiredNumberValidation = (field: string) => {
    return yup.number()
      .required(`${field} is required`)
      .typeError(`${field} is required`)
      .min(0, 'Cannot be negative');
  };

  const requiredArrayValidation = (field: string) => {
    return yup.array()
      .required(`${field} is required`)
      .typeError(`${field} is required`)
      .min(1, `${field} is required`);
  };

  const requiredObjectValidation = (field: string) => {
    return yup.object()
      .required(`${field} is required`)
      .typeError(`${field} is required`);
  };



  const handleDegreesOfferedField = () => {
    const tempArr = [];
    for (const [
      key,
      val,
    ] of Object.entries(college.degreesOffered)) {
      if (val === true) {
        tempArr.push(CertificateDegreeListItems.find((e) => e.value === key));
      }
    }
    return tempArr;
  };


  const prePopulateValues = async () => {

    setValue('name', college.name);
    setValue('schoolType', college.type);
    setValue('collegeWebsiteUrl.url', college.websiteLinks.website);
    setValue('degreesOffered', handleDegreesOfferedField());
    setValue('numberOfEnrolled', college.studentsEnrolled);
    setValue('streetAddress', college.address.streetAddress);
    setValue('city', college.address.city);
    setValue('state', StateAbbreviations.find(s => s.abbreviation === college.address.state));
    setValue('zipcode', zipcodeList.find(z => z.zipcode === college.address.zipcode));
    setValue('allCampusLocationsLink', college.websiteLinks.campusLink);
    setValue('admissionsPhone', college.admissions.phone);

    // --------------- Tuition
    setValue('residentTuition', college.tuition.resident);
    setValue('nonResidentTuition', college.tuition.nonResident);

    // --------------- Social Media
    setValue('instagram', college.socialMediaLinks.instagram);
    setValue('facebook', college.socialMediaLinks.facebook);
    setValue('linkedin', college.socialMediaLinks.linkedIn);
    setValue('twitter', college.socialMediaLinks.twitter);
    setValue('youtube', college.socialMediaLinks.youtube);
    setValue('pintrest', college.socialMediaLinks.pintrest);
    setValue('discord', college.socialMediaLinks.discord);


    // --------------- Application
    setValue('collegeApplicationUrl.url', college.websiteLinks.applicationPage);
    setValue('fallAdmissionDeadlineChecked', college.deadlines.fallAdmission !== 'Year-Round');
    setFallAdmissionDeadlineChecked(college.deadlines.fallAdmission !== 'Year-Round');
    setValue('fallAdmissionDeadlineDate', college.deadlines.fallAdmission);

    setValue('applicationFeeAmount', college.enrollmentFee);
    setValue('applicationEnrollmentChecked', college.enrollmentFeeStartDate !== 'Year-Round');
    setApplicationEnrollmentChecked(college.enrollmentFeeStartDate !== 'Year-Round');
    // No value in the college object for the applicationEnrollmentStartdate
    setValue('applicationEnrollmentStartDate', college.enrollmentFeeStartDate);

    setValue('displayApplicationFeeWaiver', college.hasFeeWaiver);
    setDisplayApplicationFeeWaiverChecked(college.hasFeeWaiver);
    // No value in the college object for the applicationFeeWaiverExpDate
    setValue('applicationFeeWaiverExpDate', college.feeWaiverExpirationDate);
    setValue('applicationFeeWaiverCode', college.feeWaiverCode);
    setValue('waiverExplanation', college.feeWaiverExplanation);

    // --------------- Scholarships
    setValue('scholarshipDeadlineChecked', college.deadlines.scholarshipApplication !== 'Year-Round');
    setScholarshipApplicationDeadlineChecked(college.deadlines.scholarshipApplication !== 'Year-Round');
    setValue('scholarshipApplicationDeadline', college.deadlines.scholarshipApplication);

    setValue('customTextchecked', college.scholarshipApplicationDeadlineNote !== null);
    setScholarshipsCustomTextChecked(college.scholarshipApplicationDeadlineNote !== null);
    setValue('customText', college.scholarshipApplicationDeadlineNote);

    setValue('scholarshipWebsiteUrl.url', college.websiteLinks.scholarshipPage);

    setValue('financialAidPageLink.url', college.websiteLinks.financialAidPage);

    setValue('financialAidPriorityDeadlineChecked', college.deadlines.financialAidPriority !== 'Year-Round');
    setFinancialAidPriorityDeadlineChecked(college.deadlines.financialAidPriority !== 'Year-Round');
    setValue('financialAidPriorityDeadline', college.deadlines.financialAidPriority);

    // --------------- Campus Tour
    // No campus tour link in the college object
    setValue('scheduleCampusTourLink.url', college.websiteLinks.scheduleCampusTourLink);
    setValue('campusTourVideoLink.url', college.websiteLinks.tourVideo);

    // --------------- UtahMajors.com Access
    setValue('featuredOnUtahMajors', college.utahProgramsMajorGuide);
    setUtahMajorsChecked(college.utahProgramsMajorGuide);


    if (college.tuition.options && !!college.tuition.options.length) {
      college.tuition.options.map((option: TuitionOption) => {
        appendNewTuitionOption({
          label: option.label,
          amount: option.amount,
        });
      });
    }
  };


  const formValidationSchema = yup.object().shape({
    // ----- School Information
    name: requiredStringValidation('Name'),
    schoolType: requiredObjectValidation('School type'),
    collegeWebsiteUrl: linkValidation,
    degreesOffered: requiredArrayValidation('Certificate & degrees'),
    numberOfEnrolled: requiredNumberValidation('Number of students enrolled'),
    streetAddress: requiredStringValidation('Street address'),
    city: requiredStringValidation('Ctiy'),
    state: requiredObjectValidation('State'),
    zipcode: yup.object()
      .required('Zipcode is required')
      .typeError('Zipcode is required'),
    allCampusLocationsLink: yup.string().isValidUrl().nullable(),
    admissionsPhone: yup.string()
      .required('Admissions phone number is required')
      .typeError('Admissions phone number is required')
      .test('len', 'Phone number must be 10 characters', (val?: string) => (!val || val.length === 12)),

    // ----- Tuition
    residentTuition: yup.string().nullable().min(0, 'Cannot be negative'),
    nonResidentTuition: yup.string().nullable().min(0, 'Cannot be negative'),

    // ----- Social Media
    instagram: yup.string().nullable(),
    facebook: yup.string().nullable(),
    linkedin: yup.string().nullable(),
    twitter: yup.string().nullable(),
    youtube: yup.string().nullable(),
    pintrest: yup.string().nullable(),
    discord: yup.string().nullable(),

    // ----- Application
    collegeApplicationUrl: linkValidation,
    fallAdmissionDeadlineDate: (fallAdmissionDeadlineChecked) ? (
      requiredStringValidation('Date')
    ) : (yup.string().nullable()),


    applicationFeeAmount: requiredNumberValidation('Application fee amount'),
    applicationEnrollmentStartDate: (applicationEnrollmentChecked) ? (
      requiredStringValidation('Date')
    ) : (yup.string().nullable()),

    applicationFeeWaiverExpDate: (displayApplicationFeeWaiverChecked) ? (
      requiredStringValidation('Date ')
    ) : yup.string().nullable(),

    applicationFeeWaiverCode: (displayApplicationFeeWaiverChecked) ? (
      requiredStringValidation('Application fee waiver code')
    ) : (yup.string().nullable()),

    waiverExplanation: yup.string().nullable(),

    // ----- Scholarships
    scholarshipApplicationDeadline: (scholarshipApplicationDeadlineChecked) ? (
      requiredStringValidation('Date')
    ) : (yup.string().nullable()),

    financialAidPriorityDeadline: (financialAidPriorityDeadlineChecked) ? (
      requiredStringValidation('Date')
    ) : (yup.string().nullable()),

    customText: (scholarshipsCustomTextChecked) ? (
      requiredStringValidation('Custom text')
    ) : (yup.string().nullable()),

    scholarshipWebsiteUrl: linkValidation,
    financialAidPageLink: linkValidation,

    // // ----- Campus Tour
    scheduleCampusTourLink: linkValidation,
    campusTourVideoLink: linkValidation,

    // ----- UtahMajors.com
    featuredOnUtahMajors: yup.bool(),

    // --------------- Tuition
    tuitionOptions: yup.array()
      .max(4, 'Max of 4')
      .of(
        yup.object().shape({
          label: yup.string().required('Label is required'),
          amount: yup.number().required('Amount is required'),
        })
      ),
  }).required();

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const { control, handleSubmit, setValue, formState: { errors } } = methods;

  const {
    fields,
    append: appendNewTuitionOption,
    remove,
  } = useFieldArray({
    name: 'tuitionOptions',
    control,
  });

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    const returnVal = {
      ...values,

      fallAdmissionDeadlineChecked: fallAdmissionDeadlineChecked,
      applicationEnrollmentChecked: applicationEnrollmentChecked,
      displayApplicationFeeWaiver: displayApplicationFeeWaiverChecked,
      scholarshipApplicationDeadlineChecked: scholarshipApplicationDeadlineChecked,
      financialAidPriorityDeadlineChecked: financialAidPriorityDeadlineChecked,
      customTextChecked: scholarshipsCustomTextChecked,
      utahMajorsProgram: utahMajorsChecked,
    };

    if (editMode) {
      dispatch(updateCollege({
        id: college.id,
        body: returnVal,
      })).unwrap()
        .then(() => {
          dispatch(setAlert({
            type: 'success',
            message: 'Successfully updated college',
          }));
          navigate(AppRoutes.collegeApplicationChecklistCollegeDetails.path.replace(':collegeId', college.id.toString()));
        })
        .catch(() => {
          dispatch(setAlert({
            type: 'error',
            message: 'Unable to update college',
          }));
        });
    } else {
      dispatch(createNewCollege(returnVal)).unwrap()
        .then(() => {
          dispatch(setAlert({
            type: 'success',
            message: 'Successfully created college',
          }));
          navigate(AppRoutes.collegeApplicationChecklistColleges.path);
        })
        .catch(() => {
          dispatch(setAlert({
            type: 'error',
            message: 'Unable to create college',
          }));
        });
    }
  };


  return (
    <div id="create-college-checklist-form-container" className="card-background">
      <div className="list-header flex_row_jbetween">
        <span className="back-button flex_acenter"
          onClick={() => editMode ? (
            navigate(AppRoutes.collegeApplicationChecklistCollegeDetails.path.replace(':collegeId', college.id.toString()))
          ) : navigate(AppRoutes.collegeApplicationChecklistColleges.path)}
        >
          <ChevronLeftIcon color="secondary" />
          <h3>College</h3>
        </span>
      </div>
      {(pageLoaded) ? (
        <form className="form-content flex_row_jbetween_wrap" onSubmit={handleSubmit(submitForm)}>
          <FormProvider {...methods}>

            <section className="form-section flex_row_jbetween_wrap">

              <h4 className="width-100"> School Information </h4>

              {/* -------------------------------------------------- College Name*/}
              <TextInput
                containerClass="width-45"
                label="COLLEGE NAME"
                name="name"
                errorMessage={errors?.name?.message}
                type="text"
                size="small"
                required
              />

              {/* -------------------------------------------------- School Type*/}
              <DropdownSelect
                containerClass="width-45"
                inputClass="width-100"
                label="SCHOOL TYPE"
                name="schoolType"
                itemList={institutionTypes}
                errorMessage={errors?.schoolType?.message}
                size="small"
                required
              />

              {/* -------------------------------------------------- COLLEGE WEBSITE URL */}
              <TextInput
                containerClass="width-45"
                label="COLLEGE WEBSITE URL"
                name="collegeWebsiteUrl.url"
                errorMessage={(errors?.collegeWebsiteUrl as any)?.url?.message}
                type="text"
                size="small"
                required
              />

              {/* -------------------------------------------------- CERTIFICATE & DEGREES OFFERED*/}

              <DropdownSelect
                containerClass="width-45"
                inputClass="width-100"
                label="CERTIFICATES & DEGREES OFFERED"
                name="degreesOffered"
                itemList={CertificateDegreeListItems}
                errorMessage={errors?.degreesOffered?.message}
                size="small"
                required
                multiple
              />

              {/* -------------------------------------------------- Number Of Students Enrolled*/}
              <TextInput
                containerClass="width-45"
                label="NUMBER OF STUDENTS ENROLLED"
                name="numberOfEnrolled"
                errorMessage={errors?.numberOfEnrolled?.message}
                type="number"
                size="small"
                required
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
              />

              {/* -------------------------------------------------- Street Address*/}
              <TextInput
                containerClass="width-45"
                label="STREET ADDRESS"
                name="streetAddress"
                errorMessage={errors?.streetAddress?.message}
                type="text"
                size="small"
                required
              />

              {/* -------------------------------------------------- City*/}
              <TextInput
                containerClass="width-45"
                label="CITY"
                name="city"
                errorMessage={errors?.city?.message}
                type="text"
                size="small"
                required
              />

              {/* -------------------------------------------------- State*/}
              <DropdownSelect
                containerClass="width-45"
                inputClass="width-100"
                label="STATE"
                name="state"
                itemList={StateAbbreviations}
                errorMessage={errors?.state?.message}
                size="small"
                required
              />

              {/* -------------------------------------------------- Zipcode*/}
              <DropdownSelect
                containerClass="width-45"
                inputClass="width-100"
                label="ZIPCODE"
                name="zipcode"
                itemList={zipcodeList}
                errorMessage={errors?.zipcode?.message}
                size="small"
                required
              />

              {/* -------------------------------------------------- All Campus Locations Link*/}
              <TextInput
                containerClass="width-45"
                label="ALL CAMPUS LOCATIONS LINK"
                name="allCampusLocationsLink"
                errorMessage={errors?.allCampusLocationsLink?.message}
                type="text"
                size="small"
              />

              {/* -------------------------------------------------- Admissions Phone Number*/}
              <TextInput
                containerClass="width-45"
                label="ADMISSIONS PHONE NUMBER"
                name="admissionsPhone"
                errorMessage={errors?.admissionsPhone?.message}
                type="text"
                size="small"
                required
                placeholder="xxx-xxx-xxxx"
                onKeyPress={(e) => {
                  numberOnlyValidation(e);
                  if (e.keyCode || e.keyCode == 0) {
                    e.target.value = formatPhone(e.target.value);
                  }
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 12,
                  },
                }}
              />


              <h4 className="width-100"> TUITION </h4>
              {/* -------------------------------------------------- Utah Resident Annual Tuition*/}
              <TextInput
                containerClass="width-45"
                label="UTAH RESIDET ANNUAL TUITION"
                name="residentTuition"
                errorMessage={errors?.residentTuition?.message}
                type="text"
                size="small"
              />

              {/* -------------------------------------------------- Utah Non-Resident Annual Tuition*/}
              <TextInput
                containerClass="width-45"
                label="UTAH NON-RESIDET ANNUAL TUITION"
                name="nonResidentTuition"
                errorMessage={errors?.nonResidentTuition?.message}
                type="text"
                size="small"
              />

              {fields.length ? (
                fields.map((option: any, i: number) => {
                  return (
                    <div className="width-100 flex_row_jbetween" key={i}>
                      <span className="width-45 flex_row_jbetween_acenter">
                        <img className="cursor-pointer delete-option-icon"
                          src={AppAssetPaths.icons.TRASH_RED}
                          alt="Remove"
                          onClick={() => remove(i)}
                        />
                        <TextInput
                          containerClass="width-100"
                          label="TUITION LABEL"
                          name={`tuitionOptions.${i}.label` as 'tuitionOptions.0.label'}
                          errorMessage={(errors?.tuitionOptions as any)?.[i]?.label?.message}
                          type="text"
                          size="small"
                          required
                        />
                      </span>

                      <TextInput
                        containerClass="width-45"
                        label="TUITION AMOUNT"
                        name={`tuitionOptions.${i}.amount` as 'tuitionOptions.0.amount'}
                        errorMessage={(errors?.tuitionOptions as any)?.[i]?.amount?.message}
                        type="number"
                        size="small"
                        InputProps={{
                          startAdornment: '$',
                          inputProps: {
                            min: 0,
                          },
                        }}
                      />
                    </div>
                  );
                })
              ) : <></>}

              {(fields.length <= 3) && (
                <div className="width-100">
                  <Button
                    onClick={() => (
                      appendNewTuitionOption({
                        label: '',
                        amount: 0,
                      })
                    )}
                  >
                    + Add tuition option
                  </Button>
                </div>
              )}

              <h4 className="width-100"> SOCIAL MEDIA </h4>

              {/* -------------------------------------------------- Instagram*/}
              <TextInput
                containerClass="width-45"
                label="INSTAGRAM"
                name="instagram"
                errorMessage={errors?.instagram?.message}
                type="text"
                size="small"
                InputProps={{
                  startAdornment: '@',
                }}
              />

              {/* -------------------------------------------------- Facebook*/}
              <TextInput
                containerClass="width-45"
                label="FACEBOOK"
                name="facebook"
                errorMessage={errors?.facebook?.message}
                type="text"
                size="small"
              />

              {/* -------------------------------------------------- Linkedin*/}
              <TextInput
                containerClass="width-45"
                label="LINKEDIN"
                name="linkedin"
                errorMessage={errors?.linkedin?.message}
                type="text"
                size="small"
              />

              {/* -------------------------------------------------- Twitterm*/}
              <TextInput
                containerClass="width-45"
                label="TWITTER"
                name="twitter"
                errorMessage={errors?.twitter?.message}
                type="text"
                size="small"
                InputProps={{
                  startAdornment: '@',
                }}
              />

              {/* -------------------------------------------------- Youtube*/}
              <TextInput
                containerClass="width-45"
                label="YOUTUBE"
                name="youtube"
                errorMessage={errors?.youtube?.message}
                type="text"
                size="small"
              />

              {/* -------------------------------------------------- Pintrest*/}
              <TextInput
                containerClass="width-45"
                label="PINTREST"
                name="pintrest"
                errorMessage={errors?.pintrest?.message}
                type="text"
                size="small"
              />

              {/* -------------------------------------------------- Discord*/}
              <TextInput
                containerClass="width-45"
                label="DISCORD"
                name="discord"
                errorMessage={errors?.discord?.message}
                type="text"
                size="small"
              />


              <h4 className="width-100"> APPLICATION</h4>

              {/* -------------------------------------------------- College Application URL*/}
              <TextInput
                containerClass="width-45"
                label="COLLEGE APPLICATION URL"
                name="collegeApplicationUrl.url"
                errorMessage={(errors?.collegeApplicationUrl as any)?.url?.message}
                type="text"
                size="small"
                required
              />

              {/* -------------------------------------------------- Fall Admission Deadline Checkboxes*/}
              <div className="width-45">
                <p className="static-input-label checkbox-label"> FALL ADMISSION DEADLINE</p>
                <FormGroup className="width-75 flex_row">
                  <FormControlLabel
                    className="width-45"
                    control={
                      <Checkbox
                        name="fallAdmissionDeadlineChecked"
                        checked={!fallAdmissionDeadlineChecked}
                        onChange={() => (setFallAdmissionDeadlineChecked(false),
                        setValue('fallAdmissionDeadlineDate', '')
                        )}
                      />
                    } label="Year Round" />

                  <FormControlLabel
                    className="width-45"
                    control={
                      <Checkbox
                        name="fallAdmissionDeadlineChecked"
                        checked={fallAdmissionDeadlineChecked}
                        onChange={() => (setFallAdmissionDeadlineChecked(true),
                        setValue('fallAdmissionDeadlineDate', '')
                        )}
                      />
                    } label="Date Specific" />
                </FormGroup>

                <div className="width-75 flex_row">
                  <span className="width-45"></span>
                  {(fallAdmissionDeadlineChecked) && (
                    <TextInput
                      containerClass="width-45"
                      name="fallAdmissionDeadlineDate"
                      errorMessage={errors?.fallAdmissionDeadlineDate?.message}
                      type="date"
                      size="small"
                      required={fallAdmissionDeadlineChecked}
                    />
                  )}
                </div>
              </div>

              {/* -------------------------------------------------- Application Fee Amount*/}
              <TextInput
                containerClass="width-45"
                label="APPLICATION FEE AMOUNT"
                name="applicationFeeAmount"
                errorMessage={errors?.applicationFeeAmount?.message}
                type="number"
                size="small"
                required
                InputProps={{
                  startAdornment: '$',
                  inputProps: {
                    min: 0,
                  },
                }}
              />

              {/* -------------------------------------------------- Application/Enrollment Fee Start Date Checkboxes*/}
              <div className="width-45">
                <p className="static-input-label checkbox-label"> APPLICATION/ENROLLMENT FEE START DATE</p>
                <FormGroup className="width-75 flex_row">
                  <FormControlLabel
                    className="width-45"
                    control={
                      <Checkbox
                        name="enrollmentStartDateChecked"
                        checked={!applicationEnrollmentChecked}
                        onChange={() => (setApplicationEnrollmentChecked(false), setValue('applicationEnrollmentStartDate', ''))}
                      />
                    } label="Year Round" />

                  <FormControlLabel
                    className="width-45"
                    control={
                      <Checkbox
                        name="enrollmentStartDateChecked"
                        checked={applicationEnrollmentChecked}
                        onChange={() => (setApplicationEnrollmentChecked(true), setValue('applicationEnrollmentStartDate', ''))}
                      />
                    } label="Date Specific" />
                </FormGroup>

                <div className="width-75 flex_row">
                  <span className="width-45"></span>
                  {(applicationEnrollmentChecked) && (
                    <TextInput
                      containerClass="width-45"
                      name="applicationEnrollmentStartDate"
                      errorMessage={errors?.applicationEnrollmentStartDate?.message}
                      type="date"
                      size="small"
                      required={applicationEnrollmentChecked}
                    />
                  )}
                </div>
              </div>

              {/* -------------------------------------------------- Display Application Fee Waiver Checkboxes*/}
              <div className="width-45">
                <p className="static-input-label checkbox-label"> DISPLAY APPLICATION FEE WAIVER</p>
                <FormGroup className="width-45 flex_row_jbetween">
                  <FormControlLabel control={
                    <Checkbox
                      name="displayApplicationFeeWaiver"
                      checked={displayApplicationFeeWaiverChecked}
                      onChange={() => setDisplayApplicationFeeWaiverChecked(!displayApplicationFeeWaiverChecked)}
                    />
                  } label="Yes" />

                  <FormControlLabel control={
                    <Checkbox
                      name="displayApplicationFeeWaiver"
                      checked={!displayApplicationFeeWaiverChecked}
                      onChange={() => setDisplayApplicationFeeWaiverChecked(!displayApplicationFeeWaiverChecked)}
                    />
                  } label="No" />
                </FormGroup>
              </div>

              {(displayApplicationFeeWaiverChecked) && (
                <>
                  <TextInput
                    containerClass="width-45"
                    label="APPLICATION FEE WAIVER EXPIRATION DATE"
                    name="applicationFeeWaiverExpDate"
                    errorMessage={errors?.applicationFeeWaiverExpDate?.message}
                    type="date"
                    size="small"
                    required={displayApplicationFeeWaiverChecked}
                  />

                  <TextInput
                    containerClass="width-45"
                    label="APPLICATION FEE WAIVER CODE (IF NONE PLEASE TYPE 'N/A')"
                    name="applicationFeeWaiverCode"
                    errorMessage={errors?.applicationFeeWaiverCode?.message}
                    type="text"
                    size="small"
                    required={displayApplicationFeeWaiverChecked}
                  />

                  <TextInput
                    containerClass="width-45"
                    label="WAIVER EXPLANATION"
                    name="waiverExplanation"
                    errorMessage={errors?.waiverExplanation?.message}
                    type="text"
                    size="small"
                  />
                </>
              )}

              <h4 className="width-100"> SCHOLARSHIPS </h4>

              {/* -------------------------------------------------- Scholarship Application Deadline Checkboxes*/}
              <div className="width-25">
                <p className="static-input-label checkbox-label"> SCHOLARSHIP APPLICATION DEADLINE</p>
                <FormGroup className="width-100 flex_row_jbetween">
                  <FormControlLabel
                    className="width-45"
                    control={
                      <Checkbox
                        name="Year Round"
                        checked={!scholarshipApplicationDeadlineChecked}
                        onChange={() => (setScholarshipApplicationDeadlineChecked(false), setValue('scholarshipApplicationDeadline', ''))}
                      />
                    } label="Year Round" />

                  <FormControlLabel
                    className="width-45"
                    control={
                      <Checkbox
                        name="Date Specific"
                        checked={scholarshipApplicationDeadlineChecked}
                        onChange={() => (setScholarshipApplicationDeadlineChecked(true), setValue('scholarshipApplicationDeadline', ''))}
                      />
                    } label="Date Specific" />
                </FormGroup>

                {(scholarshipApplicationDeadlineChecked) && (
                  <div className="width-100 flex_row_jbetween">
                    <span className="width-45"></span>
                    <TextInput
                      containerClass="width-45"
                      name="scholarshipApplicationDeadline"
                      errorMessage={errors?.scholarshipApplicationDeadline?.message}
                      type="date"
                      size="small"
                      required={scholarshipApplicationDeadlineChecked}
                    />
                  </div>
                )}
              </div>

              {/* -------------------------------------------------- Custom Text*/}

              <div className="width-25">
                <p className="static-input-label checkbox-label">&nbsp;</p>
                <FormGroup className="width-100 flex_row_jbetween">
                  <FormControlLabel control={
                    <Checkbox
                      name="customTextChecked"
                      checked={scholarshipsCustomTextChecked}
                      onChange={() => (setScholarshipsCustomTextChecked(!scholarshipsCustomTextChecked), setValue('customText', ''))}
                    />
                  } label="Custom Text" />

                </FormGroup>
                {(scholarshipsCustomTextChecked) && (
                  <TextInput
                    containerClass="width-75"
                    name="customText"
                    errorMessage={errors?.customText?.message}
                    type="text"
                    size="small"
                    required={scholarshipsCustomTextChecked}
                  />
                )}
              </div>

              {/* -------------------------------------------------- Scholarship Website URl*/}
              <TextInput
                containerClass="width-45"
                label="SCHOLARSHIP WEBSITE URL"
                name="scholarshipWebsiteUrl.url"
                errorMessage={(errors?.scholarshipWebsiteUrl as any)?.url?.message}
                type="text"
                size="small"
                required
              />

              <TextInput
                containerClass="width-45"
                label="FINANCIAL AID PAGE URL"
                name="financialAidPageLink.url"
                errorMessage={(errors?.financialAidPageLink as any)?.url?.message}
                type="text"
                size="small"
                required
              />

              {/* -------------------------------------------------- Financial Aid Priority Deadline Checkboxes/Date*/}
              <div className="width-45">
                <p className="static-input-label checkbox-label">Financial Aid Priority Deadline</p>
                <FormGroup className="width-75 flex_row">
                  <FormControlLabel
                    className="width-45"
                    control={
                      <Checkbox
                        name="financialAidPriorityDeadlineChecked"
                        checked={!financialAidPriorityDeadlineChecked}
                        onChange={() => (setFinancialAidPriorityDeadlineChecked(false),
                        setValue('financialAidPriorityDeadline', '')
                        )}
                      />
                    } label="Year Round" />

                  <FormControlLabel
                    className="width-45"
                    control={
                      <Checkbox
                        name="financialAidPriorityDeadlineChecked"
                        checked={financialAidPriorityDeadlineChecked}
                        onChange={() => (setFinancialAidPriorityDeadlineChecked(true),
                        setValue('financialAidPriorityDeadline', '')
                        )}
                      />
                    } label="Date Specific" />
                </FormGroup>

                <div className="width-75 flex_row">
                  <span className="width-45"></span>
                  {(financialAidPriorityDeadlineChecked) && (
                    <TextInput
                      containerClass="width-45"
                      name="financialAidPriorityDeadline"
                      errorMessage={errors?.financialAidPriorityDeadline?.message}
                      type="date"
                      size="small"
                      required={financialAidPriorityDeadlineChecked}
                    />
                  )}
                </div>
              </div>

              <h4 className="width-100"> CAMPUS TOUR </h4>

              {/* -------------------------------------------------- Link To Schedule A Campus Tour*/}
              <TextInput
                containerClass="width-45"
                label="LINK TO SCHEDULE A CAMPUS TOUR"
                name="scheduleCampusTourLink.url"
                errorMessage={(errors?.scheduleCampusTourLink as any)?.url?.message}
                type="text"
                size="small"
                required
              />

              {/* -------------------------------------------------- Campus Tour Video Link*/}
              <TextInput
                containerClass="width-45"
                label="CAMPUS TOUR VIDEO LINK"
                name="campusTourVideoLink.url"
                errorMessage={(errors?.campusTourVideoLink as any)?.url?.message}
                type="text"
                size="small"
                required
              />

              <h4 className="width-100"> UTAHMAJORS.COM ACCESS</h4>

              {/* -------------------------------------------------- Featured On UtahMajors.com Checkboxes*/}
              <div className="width-100">
                <p className="static-input-label checkbox-label"> FEATURED ON UTAHMAJORS.COM</p>
                <FormGroup className="width-25 flex_row_jbetween">
                  <FormControlLabel control={
                    <Checkbox
                      name="Yes"
                      checked={utahMajorsChecked}
                      onChange={() => setUtahMajorsChecked(true)}
                    />
                  } label="Yes" />

                  <FormControlLabel control={
                    <Checkbox
                      name="No"
                      checked={!utahMajorsChecked}
                      onChange={() => setUtahMajorsChecked(false)}
                    />
                  } label="No" />
                </FormGroup>
              </div>

              {/* -------------------------------------------------- Upload Logo Button*/}
              <div className="logo-button-container flex_col_jstart">
                <p>Logo</p>
                <UploadButton
                  className="form-button-wide"
                  text="Upload Logo"
                  name="logo"
                  size="large"
                  disableElevation
                  defaultFileName={editMode ? college.logo : ''}
                />
              </div>

              {/* -------------------------------------------------- Cancel and Save Buttons*/}
              <div className="width-100 flex_row_jbetween">
                <Button
                  className="form-button-wide"
                  variant="outlined"
                  onClick={() => navigate(AppRoutes.collegeApplicationChecklistColleges.path)}
                >
                  Cancel
                </Button>

                <SubmitButton
                  className="form-button-wide"
                  variant="contained"
                  text="Save"
                />
              </div>

            </section>
          </FormProvider>
        </form>
      ) : (
        <div className="flex_jcenter_acenter loading-indicator">
          <CircularProgress size="50px" />
        </div>
      )}
    </div>
  );
};

export default CreateEditCollege;
