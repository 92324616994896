/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs';
import {
  ConstructListMenuItem, ListMenuItem
} from '../../components/forms/form.types';
import { ResourceType } from '../resources/resources.model';


export interface Scholarship {
  id: number,
  title: string,
  url?: string,
  organizationId: number;
  organizationName: string;
  organizationUrl?: string;
  applicants: string;
  type?: string;
  active?: boolean;
  approved?: boolean;
  startDate: string;
  endDate: string;
  createdAt?: string;
  updatedAt?: string,
  updatedBy?: number;

  // Details / Info
  dollarAmount?: number;
  dollarAmountRange?: number[];
  description?: string;
  inApp?: boolean;
  userTypes?: ListMenuItem[];
  graduationYears?: number[];
  careers?: ListMenuItem[];
  institutions?: ListMenuItem[];
  available?: number;
  availableRange?: number[];
  numberApplied?: number;
  zipcode?: string;
}

export const EmptyScholarship: Scholarship = {
  id: 0,
  title: '',
  dollarAmount: 0,
  dollarAmountRange: [],
  description: '',
  inApp: false,
  organizationId: 0,
  organizationName: '',
  userTypes: [],
  institutions: [],
  careers: [],
  url: '',
  type: ResourceType.scholarship,
  graduationYears: [],
  available: 0,
  availableRange: [],
  numberApplied: 0,
  applicants: '0',
  startDate: '',
  endDate: '',
  zipcode: '',
};

export interface ScholarshipApplicationDetails {
  id: number,
  email?: string,
  name: string,
  graduationYear?: number | null,
  school?: string,
  inApp?: boolean,
  fieldofInterest?: string,
  essay?: string,
  approved?: boolean,
  dateApplied: string,
}

export const EmptyScholarshipApplicationDetails: ScholarshipApplicationDetails = {
  id: 0,
  email: '',
  name: '',
  graduationYear: null,
  school: '',
  inApp: false,
  fieldofInterest: '',
  essay: '',
  approved: false,
  dateApplied: '',
};


export const ConstructScholarship = (data: any): Scholarship => {
  return {
    id: data.id,
    title: data.title,
    url: data.url,
    organizationId: data.organization_id,
    organizationName: data.organization.name,
    organizationUrl: (data.organization && data.organization.name) ? data.organization.url : null,
    numberApplied: data.no_of_applicants,
    applicants: data.no_of_applicants,
    type: data.type,
    active: data.active,
    approved: data.approved,
    startDate: data.start_date,
    endDate: data.end_date,
    createdAt: data.created_at,
    updatedAt: data.updated_at,
    updatedBy: data.updated_by,

    dollarAmount: data.dollar_amount || '-',
    dollarAmountRange: data.dollar_amount_range || [],
    description: data.description,
    inApp: data.in_app || false,
    userTypes: (data.user_types && data.user_types.map((e: any) => ConstructListMenuItem(e.id, e.name))) || [],
    graduationYears: data.graduation_years || [],
    careers: (data.career_groups && data.career_groups.map((e: any) => ConstructListMenuItem(e.id, e.title))) || [],
    institutions: (data.schools && data.schools.map((e: any) => ConstructListMenuItem(e.id, e.name))) || [],
    available: data.number_available || '-',
    availableRange: data.number_available_range || [],
    zipcode: data.zipcode,
  };
};

export const ConstructScholarshipApplicationDetails = (data: any): ScholarshipApplicationDetails => {
  return {
    id: data.id,
    email: data.users[0].email || '',
    name: `${data.users[0].first_name} ${data.users[0].last_name}` || '',
    graduationYear: data.users[0].graduation_year || null,
    school: data.users[0].organization.name || '',
    inApp: data.in_app || false,
    fieldofInterest: data.users[0].UserScholarship.application.field_of_interest || '',
    essay: data.users[0].UserScholarship.application.essay || '',
    approved: data.users[0].UserScholarship.approved !== null ? data.users[0].UserScholarship.approved : null,
    dateApplied: dayjs(data.created_at).format('MM/DD/YYYY') || '',
  };
};
