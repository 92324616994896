/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import {
  HTTP_METHOD, requestFactory
} from '../../app/app.api';
import {
  ConstructListMenuItem, ListMenuItem
} from '../../components/forms/form.types';
import { setSessionToken } from '../../util/localStorage';
import {
  AssessmentsData, ConstructAssessmentsData
} from '../assessments/assessments.model';
import {
  Career, ConstructCareer
} from '../careers/careers.model';
import {
  ConstructNotification, Notification
} from '../notifications/notifications.model';
import { uploadImage } from '../organization/organization.api';
import {
  ConstructUserPathway, UserPathway
} from '../pathways/userPathways.model';
import {
  ConstructUser, deletedUser, NewCreateUser, SignupUser, User, UserListQueryParams, UserRole
} from './user.model';
import {
  ConstructUserType, UserType
} from './userType.model';


export const signUp = async (data: SignupUser): Promise<User> => {
  const body = {
    organization: {
      name: data.organizationName,
      community_organization_type_id: data.organizationType.id,
    },
    user: {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      password: data.password,
      phone_number: data.phoneNumber,
      heard_about_kts_id: data.heardAboutKts?.id,
    },
    career_fields: data.careerGroups.map(c => c.id),
    intended_participation: data.intendedParticipation,
  };

  const response = await requestFactory(HTTP_METHOD.POST, '/users/community-signup', undefined, body);
  await setSessionToken(response.data.auth_token);

  if (data.logo && !!data.logo.length) {
    await uploadImage(response.data.organization_id, data.logo[0]);
  }

  return await ConstructUser(response.data);
};

export const createNewUser = async (data: NewCreateUser): Promise<any> => {
  if (data.roles[0] === UserRole.districtAdmin && data.organization.name === 'Post-Secondary Education') {
    data.roles = [
      UserRole.districtAdmin,
      UserRole.institutionAdmin,
      UserRole.community,
    ];
  }
  const body = {
    first_name: data.firstName,
    last_name: data.lastName,
    email: data.email,
    password: data.password,
    organization_id: data.organization?.id || null,
    education_level_id: data.educationLevel?.id,
    parent_education_level_id: data.parentEducation?.id,
    gender_id: data.gender?.id,
    ethnicity_ids: data.ethnicities?.map(e => e.id) || null,
    graduation_year: data.gradYear?.label,
    roles: data.roles,
    points: data.points,
    phone_number: data.phoneNumber,
  };

  const response = await requestFactory(HTTP_METHOD.POST, '/users/admin', undefined, body);

  return response;
};

export const updateUserInfo = async (data: Partial<NewCreateUser>): Promise<any> => {
  const body = {
    first_name: data.firstName,
    last_name: data.lastName,
    email: data.email,
    organization_id: data.organization?.id || null,
    education_level_id: data.educationLevel?.id,
    parent_education_level_id: data.parentEducation?.id,
    gender_id: data.gender?.id,
    ethnicity_ids: data.ethnicities?.map(e => e.id) || null,
    graduation_year: data.gradYear?.label,
    roles: data.roles,
    points: data.points,
    password: data.password,
    phone_number: data.phoneNumber,
  };

  const response = await requestFactory(HTTP_METHOD.PATCH, `/users/${data.id}`, undefined, body);

  return await response.data;
};

export const getUser = async (): Promise<User> => {
  const { data } = await requestFactory(HTTP_METHOD.GET, '/users/me');

  const user = await ConstructUser(data.data);

  return user;
};

export const getUserList = async (query: UserListQueryParams): Promise<{ users: User[], count: number }> => {
  let url = '/users';

  if (query.role === deletedUser) {
    url = `${url}/deleted-users`;
  }

  const limitValue = query.limit || 100;
  const roleParam = query.role ? `&role=${query.role}` : '';
  const searchParam = query.search ? `&search=${query.search}` : '';
  const schoolTypeParam = query.role !== UserRole.community && query.schoolType ? `&school_type=${query.schoolType}` : '';
  const schoolDistrictsParam = query.role !== UserRole.community && query.schoolDistricts ? `&school_districts=${query.schoolDistricts}` : '';
  const gradYearsParam = (query.graduationYears && !!query.graduationYears.length) ? `&graduation_years=${query.graduationYears.join()}` : '';
  let orderBy = query.sortModel?.length ? `&order_by=${query.sortModel[0].field}` : '';
  const orderDirecton = query.sortModel?.length ? `&order_direction=${query.sortModel[0].sort}` : '';

  // Convert camelcase values to underscore to match database
  orderBy = orderBy.replace('graduationYear', 'graduation_year');
  orderBy = orderBy.replace('userType', 'roles');
  orderBy = orderBy.replace('createdAt', 'created_at');
  orderBy = orderBy.replace('deletedAt', 'deleted_at');

  url = `${url}?offset=${query.offset}&limit=${limitValue}${roleParam}${searchParam}${schoolTypeParam}${schoolDistrictsParam}${gradYearsParam}${orderBy}${orderDirecton}`;

  const { data } = await requestFactory(HTTP_METHOD.GET, url);

  const users = data.data.map((u: any) => ConstructUser(u));

  return {
    users,
    count: data.count,
  };
};

export const adminGetUserById = async (id: number): Promise<User> => {
  const response = await requestFactory(HTTP_METHOD.GET, `/users/${id}`);

  const data = await response.data.data;

  const user = ConstructUser(data);

  return user;
};

export const adminGetUserCareers = async (id: number): Promise<Career[]> => {
  const response = await requestFactory(HTTP_METHOD.GET, `/users/${id}/user-careers`);

  const data = await response.data.data;

  const careers = data.map((career: any) => ConstructCareer(career.career));

  return careers;
};

export const adminGetUserPathways = async (id: number): Promise<UserPathway[]> => {
  const response = await requestFactory(HTTP_METHOD.GET, `/users/${id}/pathways`);

  const data = await response.data.data;

  const pathways = data.map((pathway: any) => ConstructUserPathway(pathway));

  return pathways;
};

export const adminGetUserNotifications = async (id: number): Promise<Notification[]> => {
  const response = await requestFactory(HTTP_METHOD.GET, `/users/${id}/notifications`);

  const data = await response.data.data;

  const notifications = data.map((notification: any): Notification => ConstructNotification(notification));

  return notifications;
};

export const adminGetAssessmentResultsById = async (id: number): Promise<AssessmentsData> => {
  const response = await requestFactory(HTTP_METHOD.GET, `/into-careers/assessments/${id}`);

  const data = await response.data.data;

  const assessments = ConstructAssessmentsData(data);

  return assessments;
};

export const getEducationLevels = async (): Promise<ListMenuItem[]> => {
  const { data } = await requestFactory(HTTP_METHOD.GET, '/education-levels');
  return await data.data.map((ele: any) => ConstructListMenuItem(ele.id, ele.title));
};

export const getGenders = async (): Promise<ListMenuItem[]> => {
  const { data } = await requestFactory(HTTP_METHOD.GET, '/genders');
  // TODO: remove this once option is added to the database
  data.data.push({
    id: 0,
    name: 'Prefer not to answer',
  });
  return data.data.map((ele: any) => ConstructListMenuItem(ele.id, ele.name));
};

export const getEthnicities = async (): Promise<ListMenuItem[]> => {
  const { data } = await requestFactory(HTTP_METHOD.GET, '/ethnicities');
  return data.data.map((ele: any) => ConstructListMenuItem(ele.id, ele.name));
};

export const getHeardAboutKtsReasons = async (): Promise<ListMenuItem[]> => {
  const { data } = await requestFactory(HTTP_METHOD.GET, '/heard-about-kts');
  return data.data.map((ele: any) => ConstructListMenuItem(ele.id, ele.reason));
};

export const getGraduationYears = async (): Promise<ListMenuItem[]> => {
  const response = await requestFactory(HTTP_METHOD.GET, '/users/graduation-years');

  const data = await response.data.data;

  return data.map((year: number) => {
    return {
      id: year,
      label: year.toString(),
    };
  });
};



export const deleteUser = async (id: number): Promise<boolean> => {
  await requestFactory(HTTP_METHOD.DELETE, `/users/${id}`);
  return true;
};

export const restoreDeletedUser = async (id: number): Promise<void> => {
  await requestFactory(HTTP_METHOD.PATCH, `/users/restore/${id}`);
};

export const getUserTypeList = async (includeAdminTypes?: boolean): Promise<UserType[]> => {
  const { data } = await requestFactory(HTTP_METHOD.GET, '/user-type');

  const userTypes = data.data.map((e: any) => ConstructUserType(e));

  if (!includeAdminTypes) {
    return userTypes.filter((e: UserType) => !e.isAdminType);
  }

  return userTypes;
};

export const bulkAddPoints = async (points: number, userIds: number[]): Promise<void> => {
  const body = {
    points,
    user_ids: userIds,
  };

  await requestFactory(HTTP_METHOD.POST, '/users/bulk-points', undefined, body);
};

export const approveStudent = async (ids: number[]): Promise<number[]> => {
  const body = {
    ids,
  };
  await requestFactory(HTTP_METHOD.POST, '/approvals/approve-students', undefined, body);

  return ids;
};

export const rejectStudent = async (ids: number[]): Promise<number[]> => {
  const body = {
    ids,
  };
  await requestFactory(HTTP_METHOD.POST, '/approvals/reject-students', undefined, body);

  return ids;
};

export const approveTransfer = async (ids: number[]): Promise<number[]> => {
  const body = {
    ids,
  };
  await requestFactory(HTTP_METHOD.POST, '/approvals/approve-transfer', undefined, body);

  return ids;
};

export const rejectTransfer = async (ids: number[]): Promise<number[]> => {
  const body = {
    ids,
  };
  await requestFactory(HTTP_METHOD.POST, '/approvals/reject-transfer', undefined, body);

  return ids;
};


export const generateConfirmationCode = async (userId: number): Promise<any> => {
  const { data } = await requestFactory(HTTP_METHOD.GET, `/users/${userId}/generate-code`);
  return data.data;
};