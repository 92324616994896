export const isStrongPassword = (password: string): boolean => {
  /*
    Password Requirements:
      - At least 1 lowercase letter
      - 1 uppercase letter
      - 1 number
      - 1 special character
      - 10 to 20 characters long
  */
  const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{10,})');

  // Checking for max 20 characters in the regex isn't working here for some reason
  const MAX_PASSWORD_LENGTH = 20;

  return (
    strongPassword.test(password) &&
    password.length <= MAX_PASSWORD_LENGTH
  );
};

export const isValidUrl = (url: string): boolean => {
  const validUrl = new RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g);

  return validUrl.test(url);
};

export const isValidZipcode = (value: string): boolean => {
  const valueTrimmed = value.trim();
  const onlyIntegers = valueTrimmed.replace(/[^0-9]/g, '');

  if (valueTrimmed.length > 0 && valueTrimmed.length !== 5) {
    return false;
  }
  else if (valueTrimmed.length > 0 && (isNaN(parseInt(onlyIntegers)) || onlyIntegers.length !== 5)) {
    return false;
  }
  else {
    return true;
  }

};