/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useState, useEffect
} from 'react';
import {
  useParams, useNavigate
} from 'react-router-dom';
import {
  FormProvider, SubmitHandler, useForm
} from 'react-hook-form';
import {
  Button, CircularProgress
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import { AppRoutes } from '../../../app/app.types';

import { announcementSelectors } from '../../../features/announcement/announcement.slice';
import { getAnnouncementById } from '../../../features/announcement/announcement.thunks';
import {
  createAnnouncement, updateAnnouncement, getOverlapMessages, deleteAnnouncement
} from '../../../features/announcement/announcement.api';
import {
  Announcement, EmptyAnnouncement
} from '../../../features/announcement/announcement.model';
import { OrganizationType } from '../../../features/organization/organization.model';
import { setAlert } from '../../../features/alert/alert.slice';

import {
  organizationTypeList, secondaryOrganizationTypeList
} from '../../../util/dropdown';
import { isValidUrl } from '../../../util/validation';

import SubmitButton from '../../../components/forms/SubmitButton';
import TextInput from '../../../components/forms/TextInput';
import DropdownSelect from '../../../components/forms/DropdownSelect';
import DeleteConfirmationModal from '../../../components/modal/DeleteConfirmationModal';
import { UserType } from '../../../features/user/userType.model';

import './CreateEditAnnouncement.scss';
import { userSelectors } from '../../../features/user/user.slice';
import {
  getEducationLevels, getUserTypeList
} from '../../../features/user/user.thunks';
import { ListMenuItem } from '../../../components/forms/form.types';


const adminUserType = {
  id: 'admin',
  label: 'Admin',
};

const CreateEditAnnouncement: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const announcement = useAppSelector(announcementSelectors.selectAnnouncement);
  const educationLevels = useAppSelector(userSelectors.selectEducationLevels);


  const [
    editMode,
    setEditMode,
  ] = useState<boolean>(false);
  const [
    userTypeList,
    setUserTypeList,
  ] = useState<any[]>([]);
  const [
    roleTypeList,
    setRoleTypeList,
  ] = useState<any>([]);
  const [
    isAdminUserType,
    setIsAdminUserType,
  ] = useState<boolean>(false);
  const [
    isAvailableToChooseEducationLevel,
    setIsAvailableToChooseEducationLevel,
  ] = useState<boolean>(true);
  const [
    overlapMessages,
    setOverlapMessages,
  ] = useState<Announcement[]>([]);
  const [
    showOverlapDialog,
    setShowOverlapDialog,
  ] = useState<boolean>(false);
  const [
    newAnnouncementData,
    setNewAnnouncementData,
  ] = useState<Announcement>(EmptyAnnouncement);
  const [
    showDeleteConfirmDialog,
    setShowDeleteConfirmDialog,
  ] = useState<boolean>(false);
  const [
    readyToLoad,
    setReadyToLoad,
  ] = useState<boolean>(false);
  const [
    pageLoaded,
    setPageLoaded,
  ] = useState<boolean>(false);
  const [
    orgTypeList,
    setOrgTypeList,
  ] = useState<any[]>([]);


  useEffect(() => {
    if (!userTypeList || !userTypeList.length) {
      dispatch(getUserTypeList(true)).unwrap()
        .then((res) => {
          const adminTypes = res.filter((item: UserType) => item.isAdminType);
          setRoleTypeList(adminTypes);

          const nonAdminTypes = res.filter((item: UserType) => !item.isAdminType);
          setUserTypeList([
            ...nonAdminTypes,
            adminUserType,
          ]);

          setOrgTypeList(organizationTypeList.filter(o => o.id !== OrganizationType.Community && o.id !== OrganizationType.NoSchool));
        });
    }
    if (!educationLevels || !educationLevels.length) {
      dispatch(getEducationLevels());
    }

    if (params && params.announcementId) {
      dispatch(getAnnouncementById(+params.announcementId));
      setEditMode(true);
    }
  }, []);

  useEffect(() => {
    if ((params && params.announcementId && +params.announcementId !== announcement.id) || pageLoaded) return;

    if (announcement && !!announcement?.educationLevels?.length) {
      if (announcement.educationLevels.some((e: ListMenuItem) => e.id === OrganizationType.secondaryEducation || e.id === OrganizationType.postSecondaryEducation)) {
        setOrgTypeList(secondaryOrganizationTypeList.filter(o => o.id !== OrganizationType.Community && o.id !== OrganizationType.NoSchool));
      }
    }

    if (
      !!userTypeList.length &&
      !!roleTypeList.length &&
      !!orgTypeList.length &&
      !!educationLevels.length
    ) {
      setReadyToLoad(true);
    }

  }, [
    announcement,
    userTypeList,
    roleTypeList,
    orgTypeList,
    educationLevels,
  ]);

  useEffect(() => {
    if (readyToLoad) {
      prePopulateValues();
      setPageLoaded(true);
    }
  }, [
    readyToLoad,
  ]);

  const prePopulateValues = () => {
    handleDefaultUserTypes();
    handleDefaultRoles();
    setValue('educationLevels', editMode ? announcement.educationLevels : []);
    setValue('message', editMode ? announcement.message : '');
    setValue('link', editMode ? announcement.link : '');
    setValue('startDate', editMode ? announcement.startDate : dayjs().format('YYYY-MM-DD'));
    setValue('endDate', editMode ? announcement.endDate : dayjs().format('YYYY-MM-DD'));
  };

  const handleDefaultUserTypes = () => {
    if (!editMode) return;

    if (announcement.isForAdmin) {
      setValue('userType', [
        adminUserType,
        ...userTypeList.filter((item: any) => announcement?.userTypeIds?.includes(item.id)),
      ]);
      setIsAdminUserType(true);
    } else {
      setValue('userType', userTypeList.filter((item: any) => announcement?.userTypeIds?.includes(item.id)));
      setIsAdminUserType(false);
    }

    return;
  };

  const handleDefaultRoles = () => {
    if (!editMode) return;

    const roles = roleTypeList.filter((item: any) => announcement?.userTypeIds?.includes(item.id));

    setValue('role', !roles.length ? [] : roles);
  };

  const handleUserTypeFieldChange = (e: UserType[]) => {
    if (e.some(ele => ele.label === 'Select All')) {
      setIsAdminUserType(true);
      setIsAvailableToChooseEducationLevel(true);
      return;
    }
    setIsAdminUserType(e.some((x: UserType) => x.id === adminUserType.id));
    setIsAvailableToChooseEducationLevel(e.some((x: UserType) => x.label === 'Adult' || x.id === adminUserType.id));

  };

  const handleRoleFieldChange = (e: ListMenuItem[]) => {
    if (!!e?.length && e.some(x => x.label === 'District Admin')) {
      setOrgTypeList(secondaryOrganizationTypeList.filter(o => o.id !== OrganizationType.Community && o.id !== OrganizationType.NoSchool));
    }
    else {
      setOrgTypeList(organizationTypeList.filter(o => o.id !== OrganizationType.Community && o.id !== OrganizationType.NoSchool));
    }
  };

  const formValidationSchema = yup.object().shape({
    message: yup.string()
      .required('Message is required.'),
    userType: yup.array()
      .min(1, 'User type is required')
      .required('User type is required'),
    role: isAdminUserType ?
      yup.array()
        .min(1, 'Role is required')
        .required('Role is required') :
      (yup.array().nullable()),
    educationLevels: isAvailableToChooseEducationLevel ?
      yup.array()
        .min(1, 'Education is required')
        .required('Education is required') :
      (yup.array().nullable()),
    link: yup.string()
      .nullable()
      .test(
        'link_test',
        'Link is invalid',
        (value) => value ? isValidUrl(value) : true
      ),
    startDate: yup.string()
      .test(
        'start_date_test',
        'Start date must be before end date',
        (value, parent) => parent.parent.endDate ? dayjs(parent.parent.endDate).isSame(value) || dayjs(parent.parent.endDate).isAfter(value) : true
      ),
    endDate: yup.string()
      .test(
        'end_date_test',
        'End date must be after start date',
        (value, parent) => parent.parent.startDate ? dayjs(parent.parent.startDate).isSame(value) || dayjs(parent.parent.startDate).isBefore(value) : true
      ),
  }).required();

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const { handleSubmit, setValue, formState: { errors } } = methods;

  const checkOverlapMessages = async (data: Announcement) => {
    const today = dayjs().format('YYYY-MM-DD');
    if (today !== data.startDate) {
      return false;
    }

    try {
      const overlapMessages = await getOverlapMessages(data);

      if (overlapMessages.length === 0) {
        setOverlapMessages([]);
        return false;
      }

      setShowOverlapDialog(true);
      setOverlapMessages(overlapMessages);
      return true;
    } catch (error) {
      dispatch(setAlert({
        type: 'error',
        message: 'Unable to get overlap announcement.',
      }));
    }

    return false;
  };

  const createUpdateAnnouncement = async (data: Announcement) => {
    if (editMode) {
      try {
        await updateAnnouncement(data);

        dispatch(setAlert({
          type: 'success',
          message: 'Successfully updated announcement.',
        }));
        navigate(AppRoutes.announcements.path);
      } catch (err) {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to update announcement.',
        }));
      }
    } else {
      try {
        await createAnnouncement(data);

        dispatch(setAlert({
          type: 'success',
          message: 'Successfully created announcement.',
        }));
        navigate(AppRoutes.announcements.path);
      } catch (err) {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to create the announcement.',
        }));
      }
    }
  };

  const onDeleteAnnouncement = () => {
    setShowDeleteConfirmDialog(false);
    if (announcement?.id) {
      deleteAnnouncement(announcement.id)
        .then(() => {
          dispatch(setAlert({
            type: 'success',
            message: 'Successfully deleted announcement.',
          }));
          navigate(AppRoutes.announcements.path);
        })
        .catch(() => {
          dispatch(setAlert({
            type: 'error',
            message: 'Unable to delete the announcement.',
          }));
        });
    }
  };

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    let userTypeIds = [];
    userTypeIds = values.userType.filter((item: UserType) => item.id !== adminUserType.id).map((item: UserType) => item.id);
    if (isAdminUserType) {
      userTypeIds = [
        ...userTypeIds,
        ...values.role.map((item: UserType) => item.id),
      ];
    }

    const data = {
      id: editMode ? announcement.id : 0,
      message: values.message,
      userTypeIds,
      link: values.link,
      startDate: values.startDate,
      endDate: values.endDate,
      educationLevels: (isAdminUserType || isAvailableToChooseEducationLevel) ? values.educationLevels : [],
    };

    setNewAnnouncementData(data);

    const isOverlapped = await checkOverlapMessages(data);

    if (isOverlapped) {
      return;
    }

    createUpdateAnnouncement(data);
  };


  return (
    <div className="card-background create-edit-announcement-container">
      <h3>{editMode ? 'Announcement Details' : 'New Announcement'}</h3>

      <form className="form-content flex_row_jbetween" onSubmit={handleSubmit(submitForm)}>
        {(pageLoaded) ? (
          <FormProvider {...methods}>
            <TextInput
              containerClass="full-width"
              name="message"
              label="MESSAGE"
              errorMessage={errors.message && errors.message.message}
              type="textarea"
              size="small"
              required
              multiLine
              rows={8}
            />

            <DropdownSelect
              containerClass="half-width"
              name="userType"
              label="USER TYPE"
              itemList={userTypeList}
              errorMessage={errors.userType && errors.userType.message}
              onChange={(e) => {
                handleUserTypeFieldChange(e);
              }}
              size="small"
              required
              multiple
              selectAll
            />

            <DropdownSelect
              containerClass="half-width"
              name="role"
              label="Role"
              itemList={roleTypeList}
              errorMessage={errors.role && errors.role.message}
              onChange={(e) => {
                handleRoleFieldChange(e);
                setValue('educationLevels', []);
                setIsAvailableToChooseEducationLevel(true);
              }}
              disabled={!isAdminUserType}
              size="small"
              required={isAdminUserType}
              multiple
              selectAll
            />

            <DropdownSelect
              containerClass="half-width"
              name="educationLevels"
              label="Education Level"
              itemList={orgTypeList}
              disabled={!isAvailableToChooseEducationLevel}
              errorMessage={errors.educationLevels && errors.educationLevels.message}
              size="small"
              required
              multiple
              selectAll
            />

            <TextInput
              containerClass="half-width"
              name="link"
              label="LINK"
              errorMessage={errors.link && errors.link.message}
              type="text"
              size="small"
            />

            <TextInput
              containerClass="half-width"
              name="startDate"
              label="START DATE"
              errorMessage={errors.startDate && errors.startDate.message}
              type="date"
              size="small"
            />

            <TextInput
              containerClass="half-width"
              name="endDate"
              label="END DATE"
              errorMessage={errors.endDate && errors.endDate.message}
              type="date"
              size="small"
            />

            <div className="bottom-buttons flex_row_jbetween">
              {editMode && (
                <Button
                  variant="outlined"
                  onClick={() => setShowDeleteConfirmDialog(true)}
                >
                  Delete Announcement
                </Button>
              )}

              <div className={editMode ? 'right-side flex_jend' : 'right-side flex_jbetween'}>
                <Button
                  variant="outlined"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>

                <SubmitButton
                  className="submit-button form-button"
                  text="Save"
                  variant="contained"
                  size="large"
                  disableElevation
                />
              </div>
            </div>
          </FormProvider>) :
          <div className="flex_jcenter_acenter loading-indicator">
            <CircularProgress size="50px" />
          </div>
        }
      </form>

      <Dialog className="overlap-dialog" open={showOverlapDialog}>
        <DialogTitle>Override Authorization Required</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The dates of this announcement overlap with dates of the scheduled announcemenet below. To override this announcement select Override:
          </DialogContentText>

          <div className="overlap-messages-container">
            {
              overlapMessages.map((item: Announcement, index: number) => (
                <div className="overlap-message" key={index}>
                  {item.message}
                </div>
              ))
            }
          </div>

          <div className="flex_row_jbetween action-buttons">
            <Button
              variant="outlined"
              onClick={() => setShowOverlapDialog(false)}
              disableElevation
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              onClick={() => createUpdateAnnouncement(newAnnouncementData)}
              disableElevation
            >
              Override
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <DeleteConfirmationModal
        open={showDeleteConfirmDialog}
        deleteItemText="announcement"
        onConfirm={onDeleteAnnouncement}
        onClose={() => setShowDeleteConfirmDialog(false)}
      />
    </div>
  );
};

export default CreateEditAnnouncement;
