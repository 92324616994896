import React from 'react';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import './UpdatedPasswordSuccessDialog.scss';

interface UpdatePasswordSuccessDialogProps {
  showDialog: boolean;
  handleClose: () => void;
}

const UpdatePasswordSuccessDialog: React.FC<UpdatePasswordSuccessDialogProps> = ({ showDialog, handleClose }) => {
  return (
    <Dialog className="update-password-success-dialog" open={showDialog}>
      <DialogTitle>Success!</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Password has been updated.
        </DialogContentText>

        <div className="flex_row_jcenter action-buttons">
          <Button
            variant="contained"
            onClick={handleClose}
            disableElevation
          >
            Close
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UpdatePasswordSuccessDialog;
