/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HTTP_METHOD, requestFactory
} from '../../app/app.api';
import {
  ConstructZipCode, Zipcode
} from './zipcode.model';


export const getZipcodeList = async (): Promise<{ zipcodes: Zipcode[], count: number }> => {

  const { data } = await requestFactory(HTTP_METHOD.GET, '/zipcodes');

  const zipcodes = data.data.map((z: any) => ConstructZipCode(z));

  return {
    zipcodes,
    count: data.count,
  };
};
