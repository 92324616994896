/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */


/*
 * This function shouldn't be changed to add anymore HTML tags.
 * The styling should all be handled in each components own css file, using the specified class names.
 */
export const createDetailsSpan = (title: string, details?: string | number) => {
  return (
    <span className="info-span">
      <p className="details-title">{title}</p>
      <p className="details">{details || '-'}</p>
    </span>
  );
};
