import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup
} from '@mui/material';
import React, { useState } from 'react';
import {
  Controller,
  useFormContext
} from 'react-hook-form';
import { ListMenuItem } from './form.types';


export interface RadioButtonGroupProps {
  className?: string;
  name: `${string}` | `${string}.${string}` | `${string}.${number}`;
  itemList: ListMenuItem[];
  labelledBy?: string;
  errorMessage?: string;
  size?: 'small' | 'medium';
  onChange?: () => void;
}

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  className,
  name,
  itemList,
  labelledBy,
  errorMessage,
  size,
  onChange,
}) => {

  const { control } = useFormContext();

  const [
    selectedItem,
    setSelectedItem,
  ] = useState('');

  return (
    <FormControl className={className} error={errorMessage ? !!errorMessage.length : false}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <RadioGroup
            {...field}
            value={field.value || selectedItem}
            aria-labelledby={labelledBy}
            onChange={(_, data) => {
              field.onChange(data);
              onChange && onChange();
            }}
          >
            {itemList.map((listItem, i: number) => (
              <FormControlLabel
                key={i}
                label={listItem.label}
                value={listItem.id}
                control={
                  <Radio
                    size={size}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onClick={(event: any) => {
                      if (event.target.value === field.value || event.target.value === selectedItem) {
                        setSelectedItem('');
                        field.onChange(undefined);
                      }
                      else {
                        setSelectedItem(event.target.value);
                      }
                    }}
                  />
                }
              />
            ))}
          </RadioGroup>
        )}
      />
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
};

export default RadioButtonGroup;
