import React, {
  useState,
  useEffect
} from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import {
  Button,
  Divider
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { startCase } from 'lodash';

import {
  AppRoutes, canUseAuthComponent
} from '../../app/app.types';
import { getApiBaseUrl } from '../../app/app.api';
import { getOrganizationById } from '../../features/organization/organization.api';
import {
  Organization,
  OrganizationType
} from '../../features/organization/organization.model';
import { CareerGroup } from '../../features/careers/careers.model';
import { useAppSelector } from '../../app/hooks';
import { userSelectors } from '../../features/user/user.slice';

import './OrganizationDetail.scss';


interface OrganizationDetailsProps { }

const OrganizationDetails: React.FC<OrganizationDetailsProps> = () => {

  const navigate = useNavigate();
  const params = useParams();

  const user = useAppSelector(userSelectors.selectUser);

  const [
    organization,
    setOrganization,
  ] = useState<Organization>(undefined as unknown as Organization);
  const [
    isCommunity,
    setIsCommunity,
  ] = useState(false);
  const [
    imagePath,
    setImagePath,
  ] = useState('');

  // get organization detail data
  useEffect(() => {
    if (!params || !params.organizationId) {
      return;
    }

    const id = parseInt(params.organizationId);

    getOrganizationData(id);
  }, []);

  const getOrganizationData = async (id: number) => {
    const response = await getOrganizationById(id);

    setOrganization(response);
    setIsCommunity(response.type === OrganizationType.Community);

    if (response.images && response.images.length > 0) {
      const baseUrl = await getApiBaseUrl();
      setImagePath(`${baseUrl}/assets/images/${id}/${response.images[response.images.length-1]}`);
    }
  };

  const getCarrerFields = (careerGroups: CareerGroup[] | undefined) => {
    if (!careerGroups || !careerGroups.length) {
      return '-';
    }

    return careerGroups.map((item: CareerGroup) => item.label).join(', ');
  };

  const gotoEditPage = () => {
    if (isCommunity) {
      navigate(AppRoutes.editCommunity.path.replace(':organizationId', organization.id.toString()));
    } else {
      navigate(AppRoutes.editSchool.path.replace(':organizationId', organization.id.toString()));
    }
  };

  return (
    <div id="organization-detail-page" className="card-background flex_col">
      <div className="header-wrapper">
        <h3>{isCommunity ? 'Community Details' : 'KTS School Details'}</h3>

        {(organization && (canUseAuthComponent(user.roles, AppRoutes.editSchool.authorizedRoles) || canUseAuthComponent(user.roles, AppRoutes.editCommunity.authorizedRoles))) && (
          <Button
            className="form-button"
            variant="outlined"
            onClick={gotoEditPage}>
              Edit
          </Button>
        )}
      </div>

      <Divider />

      {organization && (
        <Grid container className="detail-content">
          {isCommunity && (
            <Grid item xs={12} md={6}>
              <h4>{organization.label}</h4>
              <div className="organization-type">{startCase(organization.communityOrganizationType?.label || organization.type)}</div>
              <div>
                <div className="detail-item">
                  <p className="detail-item-type">Contact Name</p>
                  <p>{organization.communityUser?.firstname} {organization.communityUser?.lastname}</p>
                </div>

                <div className="detail-item">
                  <p className="detail-item-type">Contact Email</p>
                  <p>{organization.communityUser?.email || '-'}</p>
                </div>

                <div className="detail-item">
                  <p className="detail-item-type">Contact Number</p>
                  <p>{organization.communityUser?.phoneNumber ? organization.communityUser.phoneNumber : '-'}</p>
                </div>

                <div className="detail-item">
                  <p className="detail-item-type">Organization Career</p>
                  <p>{getCarrerFields(organization.communityOrganizationCareerGroups)}</p>
                </div>
              </div>
            </Grid>
          )}

          {!isCommunity && (
            <Grid item xs={12} md={6}>
              <h4>{organization.label}</h4>
              {/* Display "Higher Education" instead of "University College" for organization.type Filed*/}
              {
                organization.type === OrganizationType.UniversityCollege
                  ? <div className="organization-type">Higher Education</div>
                  : <div className="organization-type">{startCase(organization.type)}</div>
              }
              <Grid>
                <div className="detail-item">
                  <p className="detail-item-type">School District</p>
                  <p>{organization.schoolDistrict?.name || '-'}</p>
                </div>

                <div className="detail-item">
                  <p className="detail-item-type">Website</p>
                  <p>{organization.url || '-'}</p>
                </div>

                <div className="detail-item">
                  <p className="detail-item-type">School Id</p>
                  <p>{organization.id || '-'}</p>
                </div>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} md={6} className="image-wrapper">
            {imagePath && (
              <img src={imagePath} />
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default OrganizationDetails;
