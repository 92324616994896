/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import {
  HTTP_METHOD, requestFactory
} from '../../app/app.api';
import {
  MasterKey,
  MasterKeyListQueryParams,
  MasterKeySubmissionListQueryParams,
  ConstructMasterKey,
  ConstructMasterKeySubmission,
  MasterKeySubmission,
  MasterKeyDetails,
  ConstructMasterKeyDetails,
  ConstructMasterKeyTypes,
  MasterKeyType
} from './masterKey.model';
import { getUserTypeList } from '../../features/user/user.api';
import { ListMenuItem } from '../../components/forms/form.types';
import dayjs from 'dayjs';


export const getMasterKeyList = async (query: MasterKeyListQueryParams): Promise<{ masterKeys: MasterKey[], count: number }> => {
  const limitValue = query.limit || 100;
  const searchParam = query.search ? `&search=${query.search}` : '';
  let orderBy = query.sortModel?.length ? `&order_by=${query.sortModel[0].field}` : '';
  const orderDirecton = query.sortModel?.length ? `&order_direction=${query.sortModel[0].sort}` : '';

  orderBy = orderBy.replace('masterKeyType', 'master_key_type');
  orderBy = orderBy.replace('userType', 'user_type_ids');
  orderBy = orderBy.replace('startDate', 'start_date');
  orderBy = orderBy.replace('endDate', 'end_date');

  const url = `/masterkey?offset=${query.offset}&limit=${limitValue}${searchParam}${orderBy}${orderDirecton}`;

  const { data } = await requestFactory(HTTP_METHOD.GET, url);

  const userTypeList = await getUserTypeList();
  const masterKeys = data.data.map((masterKey: any) => ConstructMasterKey([
    masterKey,
    userTypeList,
  ]));

  return {
    masterKeys,
    count: data.count,
  };
};

export const getMasterKeyById = async (id: number): Promise<MasterKey> => {
  const response = await requestFactory(HTTP_METHOD.GET, `/masterkey/${id}`);

  const data = await response.data.data;

  const masterKey = ConstructMasterKey([
    data,
    [],
  ]);

  return masterKey;
};

export const deleteMasterKeyById = async (id: number): Promise<void> => {
  await requestFactory(HTTP_METHOD.DELETE, `/masterkey/${id}`);
};

export const getMasterKeySubmissionList = async (query: MasterKeySubmissionListQueryParams): Promise<{ masterKeys: MasterKeySubmission[], count: number }> => {
  const limitValue = query.limit || 100;
  let orderBy = query.sortModel?.length ? `&order_by=${query.sortModel[0].field}` : '';
  orderBy = orderBy.replace('createdAt', 'created_at');
  const orderDirecton = query.sortModel?.length ? `&order_direction=${query.sortModel[0].sort}` : '';
  const url = `/masterkey-submissions/${query.masterKeyId}?offset=${query.offset}&limit=${limitValue}${orderBy}${orderDirecton}`;

  const { data } = await requestFactory(HTTP_METHOD.GET, url);
  const masterKeySubmissions = data.data.map((item: any) => ConstructMasterKeySubmission(item));

  return {
    masterKeys: masterKeySubmissions,
    count: data.count,
  };
};

export const getMasterKeySubmissionById = async (id: number): Promise<MasterKeySubmission> => {
  const { data } = await requestFactory(HTTP_METHOD.GET, `/masterkey-submission/${id}`);

  return ConstructMasterKeySubmission(data.data);
};

export const getMasterKeyDetails = async (id: number): Promise<MasterKeyDetails> => {
  const { data } = await requestFactory(HTTP_METHOD.GET, `/masterkey/${id}`);

  if (data.data.user_type_ids) {
    data.data.user_type_list = await getUserTypeList();
  }

  return ConstructMasterKeyDetails(data.data);
};

export const submissionPointsAward = async (id: number, pointsAwarded: boolean): Promise<void> => {
  const url = `/masterkey-submission/points-awarded/${id}?awarded=${pointsAwarded}`;
  await requestFactory(HTTP_METHOD.PATCH, url);
};

export const createMasterKey = async (body: any): Promise<any> => {
  // Catch if the multi gets changed to a string value by the radio buttons and change them back to booleans.
  body.questions.map((q: any) => {
    /*
    * 'multi' here determines whether a question allows mutliple answers to be selected at once by the user answering, not whether it has multiple possible answers
    * Multi select vs single select
    * Written questions should not have this value at all
    */
    !q.answers ? delete q.multi : (q.multi === 'true' || q.multi) ? q.multi = true : q.multi = false;
  });

  const submitBody = {
    title: body.title,
    user_type_ids: body.userType.map((u: ListMenuItem) => u.id),
    points: body.points,
    start_date: body.startDate,
    end_date: body.endDate,
    archived: dayjs(dayjs()).isBetween(body.startDate, body.endDate),
    questions: body.questions,
    type_id: body.masterKeyType.id,
  };

  const response = await requestFactory(HTTP_METHOD.POST, '/masterkey', undefined, submitBody);
  return response;
};

export const editMasterKey = async (id: number, body: any): Promise<any> => {
  // Catch if the multi gets changed to a string value by the radio buttons and change them back to booleans.
  body.questions.map((q: any) => {
    /*
    * 'multi' here determines whether a question allows mutliple answers to be selected at once by the user answering, not whether it has multiple possible answers
    * Multi select vs single select
    * Written questions should not have this value at all
    */
    !q.answers ? delete q.multi : (q.multi === 'true' || q.multi) ? q.multi = true : q.multi = false;
  });

  const submitBody = {
    title: body.title,
    user_type_ids: body.userType.map((u: ListMenuItem) => u.id),
    points: body.points,
    start_date: body.startDate,
    end_date: body.endDate,
    questions: body.questions,
    type_id: body.masterKeyType.id,
  };

  const response = await requestFactory(HTTP_METHOD.PATCH, `/masterkey/${id}`, undefined, submitBody);
  return response;
};

export const archiveMasterKey = async (id: number): Promise<any> => {
  const response = await requestFactory(HTTP_METHOD.PATCH, `/masterkey/archive/${id}`);
  return response;
};

export const getMasterKeyTypeList = async (): Promise<MasterKeyType[]> => {
  const { data } = await requestFactory(HTTP_METHOD.GET, '/masterkey-types');

  return data.data.map((type: any) => ConstructMasterKeyTypes(type));
};
