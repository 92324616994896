import React from 'react';
import { startCase } from 'lodash';
import { Button } from '@mui/material';
import { canUseAuthComponent } from '../../../app/app.types';
import { AppAssetPaths } from '../../../app/app.types';
import { UserApprovalStatus } from '../../../features/user/user.model';

import {
  UserOverview,
  User,
  getGenericUserType,
  UserRole
} from '../../../features/user/user.model';

import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';

import './UserDetail.scss';
import { verifyUserEmail } from '../../../features/user/auth.thunks';
import { setAlert } from '../../../features/alert/alert.slice';
import { userSelectors } from '../../../features/user/user.slice';
import { AppPermissions } from '../../../app/app.permissions';

interface UserDetailProps {
  user: User;
  overview?: UserOverview;
}

const UserDetail: React.FC<UserDetailProps> = ({
  user,
  overview,
}) => {
  const EMPTY = '-';
  const dispatch = useAppDispatch();

  const adminUser = useAppSelector(userSelectors.selectUser);


  const manuallyVerifyEmail = () => {
    dispatch(verifyUserEmail(user.email))
      .unwrap()
      .then(() => {
        if (user.status) {
          user.status.emailVerified = true;
        }

        dispatch(setAlert({
          type: 'success',
          message: 'Successfully validated user email.',
        }));
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to validate user email.',
        }));
      });
  };

  return (
    <>
      <div className="overview-header-wrapper flex">
        <div className="left-side">
          <h2 className="user-name">{ user.fullname }</h2>
          <p className="user-type overview-str">{ startCase(getGenericUserType(user.roles)) }</p>
        </div>
        <div className="approval-status">
          <div className="right-side flex_row_jbetween_acenter">
            <div>User Approval Status:</div>
            <div className="approval-status-label">
              <div className="flex_acenter">
                { (user.status?.emailVerified ) ? (
                  <img src={AppAssetPaths.icons.statusIcons.ACTIVE} alt={UserApprovalStatus.active} />
                ) : (
                  <img src={AppAssetPaths.icons.statusIcons.UNVERIFIED_EMAIL} alt={UserApprovalStatus.unverified} />
                )}
              </div>
            </div>
          </div>
          <div className="flex_jend">
            {(!user.status?.emailVerified && canUseAuthComponent(adminUser.roles, AppPermissions.manualEmailVerification.authorizedRoles)) && (
              <Button
                className="verify-email-button"
                variant="contained"
                onClick={() => manuallyVerifyEmail()}
              >
                Manually Verify
              </Button>
            )}
          </div>
        </div>
      </div>

      <div className="overview-content-wrapper flex_column_jstart_nwrap">
        <div className="overview-content-row flex">
          <div className="overview-label">Email</div>
          <div className="overview-data">{ user.email }</div>
        </div>
        {(user.roles.includes(UserRole.community) || user.roles.includes(UserRole.institutionAdmin)) ? (
          <>
            <div className="overview-content-row flex">
              <div className="overview-label">Organization Type</div>
              <div className="overview-data overview-str">{ startCase(overview?.organizationType) || EMPTY }</div>
            </div>
            <div className="overview-content-row flex">
              <div className="overview-label">Organization</div>
              <div className="overview-data overview-str">{ overview?.organizationName || EMPTY }</div>
            </div>
            <div className="overview-content-row flex">
              <div className="overview-label">Phone Number</div>
              <div className="overview-data overview-str">{ overview?.phoneNumber || EMPTY }</div>
            </div>
          </>
        ) : (
          <>
            <div className="overview-content-row flex">
              <div className="overview-label">Organization Type</div>
              <div className="overview-data overview-str">{ startCase(overview?.organizationType) || EMPTY }</div>
            </div>
            { user?.roles.includes(UserRole.districtAdmin) ? (
              <div className="overview-content-row flex">
                <div className="overview-label">Organization</div>
                <div className="overview-data overview-str">{ overview?.organizationName || EMPTY }</div>
              </div>
            ) : (
              <div className="overview-content-row flex">
                <div className="overview-label">School</div>
                <div className="overview-data overview-str">{ overview?.schoolName?.toLowerCase() || EMPTY }</div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default UserDetail;
