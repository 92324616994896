/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { GridSortModel } from '@mui/x-data-grid';
import { ListMenuItem } from '../../components/forms/form.types';


export interface Skill {
  id: number;
  label?: string;
  active: boolean;
  createdAt: string;
}

export interface CreateEditSkill {
  id?: number;
  label: string;
  active?: boolean;
  createdAt?: string;
}

export const EmptySkill: Skill = {
  id: 0,
  label: '',
  active: false,
  createdAt: '',
};

export interface SkillsListQueryParams {
  offset: number;
  limit?: number;
  search?: string;
  sortModel?: GridSortModel;
}

export interface SkillListItem extends ListMenuItem {
}

export const ConstructSkill = (data: any): Skill => {
  return {
    id: data.id,
    label: data.name,
    active: data.active,
    createdAt: data.created_at,
  };
};
