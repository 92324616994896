import {
  BooleanListMenuItem, ListMenuItem
} from '../components/forms/form.types';
import { OrganizationType } from '../features/organization/organization.model';
import { SkillTrainingCourse } from '../features/skillTrainings/skillTrainings.model';
import { UserRole } from '../features/user/user.model';



export const yesNoDropdownList: BooleanListMenuItem[] = [
  {
    id: 1,
    label: 'Yes',
    value: true,
  },
  {
    id: 2,
    label: 'No',
    value: false,
  },
];

export const organizationTypeList: ListMenuItem[] = [
  {
    id: OrganizationType.MiddleSchool,
    label: 'Middle School & Jr. High',
  },
  {
    id: OrganizationType.HighSchool,
    label: 'High School',
  },
  {
    id: OrganizationType.Adult,
    label: 'Adult Education School',
  },
  {
    id: OrganizationType.UniversityCollege,
    label: 'Higher Education',
  },
  {
    id: OrganizationType.Community,
    label: 'Community Organization',
  },
  {
    id: OrganizationType.NoSchool,
    label: 'No School Associated',
  },
];

export const secondaryOrganizationTypeList: ListMenuItem[] = [
  {
    id: OrganizationType.MiddleSchool,
    label: 'Middle School & Jr. High',
  },
  {
    id: OrganizationType.HighSchool,
    label: 'High School',
  },
  {
    id: OrganizationType.Adult,
    label: 'Adult Education School',
  },
  {
    id: OrganizationType.UniversityCollege,
    label: 'Higher Education',
  },
  {
    id: OrganizationType.Community,
    label: 'Community Organization',
  },
  {
    id: OrganizationType.secondaryEducation,
    label: 'Secondary Education',
  },
  {
    id: OrganizationType.postSecondaryEducation,
    label: 'Post Secondary Education',
  },
  {
    id: OrganizationType.NoSchool,
    label: 'No School Associated',
  },
];

export const userTypeDropdownList: ListMenuItem[] = [
  {
    id: 1,
    label: 'Student',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.keyContact,
      UserRole.counselor,
      UserRole.institutionAdmin,
    ],
  },
  {
    id: 2,
    label: 'Key Contact',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.institutionAdmin,
    ],
  },
  {
    id: 3,
    label: 'Counselor',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.keyContact,
      UserRole.counselor,
      UserRole.institutionAdmin,
    ],
  },
  {
    id: 4,
    label: 'District Admin',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  {
    id: 5,
    label: 'Community',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  {
    id: 6,
    label: 'Institution Admin',
    authorizedRoles: [
      UserRole.admin,
      UserRole.universityKeyContact,
      UserRole.institutionDistrictAdmin,
    ],
  },
  {
    id: 8,
    label: 'KTS Admin',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
];


export const courseFormatDropdownList: SkillTrainingCourse[] = [
  {
    id: 1,
    label: 'Online, Self-paced',
    value: 'onlineSelfPaced',
  },
  {
    id: 2,
    label: 'Online, Instructor Lead',
    value: 'onlineInstructorLead',
  },
  {
    id: 3,
    label: 'In-person, Instructor Lead',
    value: 'inPersonInstructorLead',
  },
  {
    id: 4,
    label: 'Hybrid',
    value: 'hybrid',
  },
];

export const courseDurationDropdownList: SkillTrainingCourse[] = [
  {
    id: 1,
    label: 'Hours',
    value: 'hours',
  },
  {
    id: 2,
    label: 'Weeks',
    value: 'weeks',
  },
  {
    id: 3,
    label: 'Months',
    value: 'months',
  },
];

export const schoolTypeList: ListMenuItem[] = [
  {
    id: OrganizationType.MiddleSchool,
    label: 'Middle School & Jr. High',
  },
  {
    id: OrganizationType.HighSchool,
    label: 'High School',
  },
  {
    id: OrganizationType.Adult,
    label: 'Adult Education School',
  },
  {
    id: OrganizationType.UniversityCollege,
    label: 'Higher Education',
  },
];