import React from 'react';
import './ApproveRejectCard.scss';
import { AppAssetPaths } from '../../app/app.types';


interface ApproveRejectCardProps {
  onApprove: () => void;
  onReject: () => void;
  approveText?: string;
}

const ApproveRejectCard: React.FC<ApproveRejectCardProps> = ({
  onApprove,
  onReject,
  approveText = 'Approval Needed',
}) => {

  return (
    <div className="approve-reject-container flex_row_jcenter_acenter">
      <p>{approveText}</p>
      <img src={AppAssetPaths.icons.statusIcons.APPROVE} alt="Approve"
        onClick={onApprove}
      />
      <img src={AppAssetPaths.icons.statusIcons.REJECT} alt="Reject"
        onClick={onReject}
      />
    </div>
  );
};

export default ApproveRejectCard;
