import {
  Box, Button, CircularProgress
} from '@mui/material';
import React, {
  useEffect, useState
} from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  useNavigate, useParams
} from 'react-router-dom';
import {
  AppRoutes, canUseAuthComponent
} from '../../../app/app.types';
import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import { getCareerGroupById } from '../../../features/careers/careers.thunks';
import { careerSelectors } from '../../../features/careers/careers.slice';
import { getSkillsList } from '../../../features/skills/skills.thunks';
import { userSelectors } from '../../../features/user/user.slice';
import { skillSelectors } from '../../../features/skills/skills.slice';
import { startCase } from 'lodash';
import { CareerClusterSkill } from '../../../features/careers/careers.model';

import './CareerClusterDetails.scss';


interface CareerClusterDetailsProps { }

const CareerClusterDetails: React.FC<CareerClusterDetailsProps> = () => {

  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();

  const user = useAppSelector(userSelectors.selectUser);
  const careerClusterDetails = useAppSelector(careerSelectors.selectCareerGroupDetails);
  const dropdownSkillList = useAppSelector(skillSelectors.selectSkillsList);

  const [
    pageLoaded,
    setPageLoaded,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (params.id) {
      dispatch(getCareerGroupById(+params.id)).then(() => setPageLoaded(true));
    }
    dispatch(getSkillsList({
      offset: 0,
    }));
  }, []);

  return (
    <div id="career-cluster-details">
      {pageLoaded ? (
        <Box className="card-background flex_col">
          <div className="content-header flex_row_jbetween">
            <span
              className="cursor-pointer flex_row_jstart_acenter"
              onClick={() => navigate(AppRoutes.careerClusters.path)}
            >
              <ChevronLeftIcon className="back-icon" color="secondary" />
              <h3>Career Cluster Details</h3>
            </span>

            <span className="flex_row_jstart_acenter">
              {canUseAuthComponent(user.roles, AppRoutes.editSkill.authorizedRoles) && (
                <Button
                  className="edit-button"
                  variant="outlined"
                  onClick={() => navigate(AppRoutes.editCareerCluster.path.replace(':id', params.id!.toString()))}
                >
                  Edit
                </Button>
              )}
            </span>
          </div>

          <h4 className="skill-name"> {careerClusterDetails.title} </h4>

          <div className="cluster-skills-container">
            <p className="skills-header">SKILLS</p>
            {careerClusterDetails.skills.map((skill: CareerClusterSkill, i: number) => (
              <span key={i}>
                <p> {dropdownSkillList.find(e => e.id == skill.id)?.label} </p>
                <p> {startCase(skill.haveType)} </p>
              </span>
            ))}
          </div>
        </Box>
      )
        :
        <div className="flex_jcenter_acenter loading-indicator">
          <CircularProgress size="50px" />
        </div>}

    </div>
  );
};

export default CareerClusterDetails;
