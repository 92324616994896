import { AuthComponent } from '../../app/app.types';

export interface ListMenuItem extends AuthComponent {
  id: number | string;
  label: string;
}

export interface BooleanListMenuItem extends ListMenuItem {
  value: boolean;
}

export const emptyListMenuItem: ListMenuItem = {
  id: 0,
  label: '',
};

export const ConstructListMenuItem = (id: number | string, label: string): ListMenuItem => {
  return {
    id,
    label,
  };
};
