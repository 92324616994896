import React, { useEffect } from 'react';
import { startCase } from 'lodash';

import {
  UserOverview,
  User,
  getGenericUserType,
  getUserStatus
} from '../../../features/user/user.model';
import StatusLabel from '../../StatusLabel';
import { Button } from '@mui/material';
import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import { verifyUserEmail } from '../../../features/user/auth.thunks';
import { setAlert } from '../../../features/alert/alert.slice';

import './StudentDetail.scss';
import { userSelectors } from '../../../features/user/user.slice';
import { OrganizationType } from '../../../features/organization/organization.model';
import { getEducationLevels } from '../../../features/user/user.thunks';
import { canUseAuthComponent } from '../../../app/app.types';
import { AppPermissions } from '../../../app/app.permissions';


interface StudentDetailProps {
  user: User;
  overview?: UserOverview;
}

const StudentDetail: React.FC<StudentDetailProps> = ({
  user,
  overview,
}) => {

  const EMPTY = '-';

  const dispatch = useAppDispatch();

  const adminUser = useAppSelector(userSelectors.selectUser);
  const educationLevels = useAppSelector(userSelectors.selectEducationLevels);

  useEffect(() => {
    dispatch(getEducationLevels());
  }, []);

  const manuallyVerifyEmail = () => {
    dispatch(verifyUserEmail(user.email))
      .unwrap()
      .then(() => {
        if (user.status) {
          user.status.emailVerified = true;
        }

        dispatch(setAlert({
          type: 'success',
          message: 'Successfully validated user email.',
        }));
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to validate user email.',
        }));
      });
  };


  return (
    <div id="student-details">
      <div className="overview-header-wrapper flex">
        <div className="left-side">
          <h2>{user.fullname}</h2>
          <p className="user-type overview-str">{startCase(getGenericUserType(user.roles))}</p>
        </div>
        <div className="approval-status">
          <div className="right-side flex_row_jbetween_acenter">
            <div>User Approval Status:</div>
            <div className="approval-status-label"><StatusLabel status={getUserStatus(user.status?.accountApproved, user.status?.emailVerified, user.status?.partialRegistration)} /></div>
          </div>
          <div className="flex_jend">
            {(!user.status?.emailVerified && canUseAuthComponent(adminUser.roles, AppPermissions.manualEmailVerification.authorizedRoles)) && (
              <Button
                className="verify-email-button"
                variant="contained"
                onClick={() => manuallyVerifyEmail()}
              >
                Manually Verify
              </Button>
            )}
          </div>
        </div>
      </div>

      <div className="overview-details flex_row">
        <div className="overview-content-wrapper flex_column">
          <div className="overview-content-row flex">
            <div className="overview-label">Email</div>
            <div className="overview-data">{user.email}</div>
          </div>

          <div className="overview-content-row flex">
            <div className="overview-label">Phone</div>
            <div className="overview-data">{user.phoneNumber}</div>
          </div>

          <div className="overview-content-row flex">
            <div className="overview-label">Organization Type</div>
            <div className="overview-data overview-str">{startCase(overview?.organizationType) || EMPTY}</div>
          </div>

          <div className="overview-content-row flex">
            <div className="overview-label">School</div>
            <div className="overview-data overview-str">{overview?.schoolName?.toLowerCase() || EMPTY}</div>
          </div>

          <div className="overview-content-row flex">
            <div className="overview-label">
              High School Graduation Year
            </div>
            <div className="overview-data">{user.graduationYear || EMPTY}</div>
          </div>
        </div>

        <div className="overview-content-wrapper flex_column">
          {(overview?.organizationType === OrganizationType.MiddleSchool || overview?.organizationType === OrganizationType.HighSchool) ? (
            <div className="overview-content-row flex">
              <div className="overview-label">Parents Level of Education</div>
              <div className="overview-data overview-str">{overview?.parentEducation || EMPTY}</div>
            </div>
          ) : (
            <div className="overview-content-row flex">
              <div className="overview-label">Level of Education</div>
              <div className="overview-data overview-str">{educationLevels.find(e => e.id === user.educationLevelId)?.label || EMPTY}</div>
            </div>
          )}

          <div className="overview-content-row flex">
            <div className="overview-label">Gender</div>
            <div className="overview-data overview-str">{overview?.gender || EMPTY}</div>
          </div>

          <div className="overview-content-row flex">
            <div className="overview-label">Race / Ethnicity</div>
            <div className="overview-data overview-str">{overview?.ethnicities?.map(e => e.label).join(', ') || EMPTY}</div>
          </div>

          <div className="overview-content-row flex">
            <div className="overview-label">Points</div>
            <div className="overview-data">{user.points || EMPTY}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentDetail;
