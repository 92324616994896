import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { StateStatus } from '../../app/app.types';
import {
  getCareerClusters, getCareerGroupById, getCareerGroups
} from './careers.thunks';
import {
  CareerGroup, CareerCluster
} from './careers.model';


/* State */

export interface CareerState {
  careerGroups: {
    status: StateStatus;
    data: CareerGroup[];
    count: number;
    loaded: boolean;
  }
  careerClusters: {
    status: StateStatus;
    data: CareerGroup[];
  }
  careerGroupDetails: {
    status: StateStatus;
    data: CareerCluster;
  }
}

const initialState: CareerState = {
  careerGroups: {
    status: StateStatus.IDLE,
    data: [],
    count: 0,
    loaded: false,
  },
  careerClusters: {
    status: StateStatus.IDLE,
    data: [],
  },
  careerGroupDetails: {
    status: StateStatus.IDLE,
    data: {
    } as CareerCluster,
  },
};


/* Reducer */

export const careerSlice = createSlice({
  name: 'careers',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCareerGroups.pending, (state) => {
        state.careerGroups.status = StateStatus.LOADING;
        state.careerGroups.loaded = false;
      })
      .addCase(getCareerGroups.fulfilled, (state, action) => {
        state.careerGroups.status = StateStatus.IDLE;
        state.careerGroups.data = action.payload;
        state.careerGroups.count = action.payload.length;
        state.careerGroups.loaded = true;
      })
      .addCase(getCareerGroups.rejected, (state) => {
        state.careerGroups.status = StateStatus.FAILED;
        state.careerGroups.loaded = false;
      })
      .addCase(getCareerClusters.pending, (state) => {
        state.careerClusters.status = StateStatus.LOADING;
      })
      .addCase(getCareerClusters.fulfilled, (state, action) => {
        state.careerClusters.status = StateStatus.IDLE;
        state.careerClusters.data = action.payload;
      })
      .addCase(getCareerClusters.rejected, (state) => {
        state.careerClusters.status = StateStatus.FAILED;
      })
      // ------------------ Career Group Details ------------------ \\
      .addCase(getCareerGroupById.pending, (state) => {
        state.careerGroupDetails.status = StateStatus.LOADING;
      })
      .addCase(getCareerGroupById.fulfilled, (state, action) => {
        state.careerGroupDetails.status = StateStatus.IDLE;
        state.careerGroupDetails.data = action.payload;
      })
      .addCase(getCareerGroupById.rejected, (state) => {
        state.careerGroupDetails.status = StateStatus.FAILED;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const { } = careerSlice.actions;


/* Selectors */

const selectCareerGroups = (state: RootState): CareerGroup[] => state.career.careerGroups.data;
const selectCareerGroupsCount = (state: RootState): number => state.career.careerGroups.count;
const selectCareerGroupsLoaded = (state: RootState): boolean => state.career.careerGroups.loaded;

const selectCareerClusters = (state: RootState): CareerGroup[] => state.career.careerClusters.data;
// ------------------ Career Group Details ------------------ \\
const selectCareerGroupDetails = (state: RootState): CareerCluster => state.career.careerGroupDetails.data;
const selectCareerGroupDetailsStatus = (state: RootState): StateStatus => state.career.careerGroupDetails.status;

export const careerSelectors = {
  selectCareerGroups,
  selectCareerGroupsCount,
  selectCareerGroupsLoaded,

  selectCareerClusters,
  selectCareerGroupDetails,
  selectCareerGroupDetailsStatus,
};

export default careerSlice.reducer;
