import { createSlice } from '@reduxjs/toolkit';
import { StateStatus } from '../../app/app.types';
import { RootState } from '../../app/store';
import {
  EmptySkillTraining, SkillTraining
} from './skillTrainings.model';
import {
  approveSkillTraining,
  GetSkillTrainingById, getSkillTrainingList, rejectSkillTraining
} from './skillTrainings.thunks';



/* State */

export interface SkillTrainingState {
  skillTrainingList: {
    status: StateStatus;
    data: SkillTraining[];
    totalCount: number;
    loaded: boolean;
  },
  skillTrainingDetails: {
    status: StateStatus;
    data: SkillTraining;
  }
  approveSkillTraining: {
    status: StateStatus;
  },
  rejectSkillTraining: {
    status: StateStatus
  }
}

const initialState: SkillTrainingState = {
  skillTrainingList: {
    status: StateStatus.IDLE,
    data: [],
    totalCount: 0,
    loaded: false,
  },
  skillTrainingDetails: {
    status: StateStatus.IDLE,
    data: EmptySkillTraining,
  },
  approveSkillTraining: {
    status: StateStatus.IDLE,
  },
  rejectSkillTraining: {
    status: StateStatus.IDLE,
  },
};

/* Reducer */

export const skillTrainingSlice = createSlice({
  name: 'skillTrainings',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSkillTrainingList.pending, (state) => {
        state.skillTrainingList.status = StateStatus.LOADING;
      })
      .addCase(getSkillTrainingList.fulfilled, (state, action) => {
        state.skillTrainingList.status = StateStatus.IDLE;
        state.skillTrainingList.data = action.payload.skillTrainings;
        state.skillTrainingList.totalCount = action.payload.count;
        state.skillTrainingList.loaded = true;
      })
      .addCase(getSkillTrainingList.rejected, (state) => {
        state.skillTrainingList.status = StateStatus.FAILED;
      })

      .addCase(GetSkillTrainingById.pending, (state) => {
        state.skillTrainingDetails.status = StateStatus.LOADING;
      })
      .addCase(GetSkillTrainingById.fulfilled, (state, action) => {
        state.skillTrainingDetails.status = StateStatus.IDLE;
        state.skillTrainingDetails.data = action.payload;
      })
      .addCase(GetSkillTrainingById.rejected, (state) => {
        state.skillTrainingDetails.status = StateStatus.FAILED;
      })

      .addCase(approveSkillTraining.pending, (state) => {
        state.approveSkillTraining.status = StateStatus.LOADING;
      })
      .addCase(approveSkillTraining.fulfilled, (state, action) => {
        state.approveSkillTraining.status = StateStatus.IDLE;
        state.skillTrainingDetails.data.approved = action.payload;
      })
      .addCase(approveSkillTraining.rejected, (state) => {
        state.approveSkillTraining.status = StateStatus.FAILED;
      })

      .addCase(rejectSkillTraining.pending, (state) => {
        state.rejectSkillTraining.status = StateStatus.LOADING;
      })
      .addCase(rejectSkillTraining.fulfilled, (state, action) => {
        state.rejectSkillTraining.status = StateStatus.IDLE;
      })
      .addCase(rejectSkillTraining.rejected, (state) => {
        state.rejectSkillTraining.status = StateStatus.FAILED;
      });
  },
});


/* Selectors */
const selectSkillTrainingList = (state: RootState): SkillTraining[] => state.skillTrainings.skillTrainingList.data;
const selectSkillTrainingListCount = (state: RootState): number => state.skillTrainings.skillTrainingList.totalCount;
const selectSkillTrainingListStatus = (state: RootState): StateStatus => state.skillTrainings.skillTrainingList.status;
const selectSkillTrainingListLoaded = (state: RootState): boolean => state.skillTrainings.skillTrainingList.loaded;

const selectSkillTrainingDetails = (state: RootState): SkillTraining => state.skillTrainings.skillTrainingDetails.data;
const selectSkillTrainingDetailsStatus = (state: RootState): StateStatus => state.skillTrainings.skillTrainingDetails.status;

const selectApproveSkillTraining = (state: RootState): StateStatus => state.skillTrainings.approveSkillTraining.status;
const selectRejectSkillTraining = (state: RootState): StateStatus => state.skillTrainings.rejectSkillTraining.status;

export const skillTrainingSelectors = {
  selectSkillTrainingList,
  selectSkillTrainingListCount,
  selectSkillTrainingListStatus,
  selectSkillTrainingListLoaded,

  selectSkillTrainingDetails,
  selectSkillTrainingDetailsStatus,

  selectApproveSkillTraining,
  selectRejectSkillTraining,
};

export default skillTrainingSlice.reducer;
