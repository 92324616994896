import React, { useEffect } from 'react';
import {
  FormGroup,
  FormLabel,
  Popover
} from '@mui/material';
import {
  FormProvider, SubmitHandler, useForm
} from 'react-hook-form';
import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import DropdownSelect from '../../../components/forms/DropdownSelect';
import {
  bottomRightAnchorOrigin, topCenterTransformOrigin
} from '../../../constants/menu.types';
import SubmitButton from '../../../components/forms/SubmitButton';
import { ListMenuItem } from '../../../components/forms/form.types';
import CloseIcon from '@mui/icons-material/Close';
import { SecondaryColorRadioGroup } from '../../../components/CustomStyledComponents';
import { organizationSelectors } from '../../../features/organization/organization.slice';
import { getAllSchoolDistricts } from '../../../features/organization/organization.thunks';
import { ResourceType } from '../../../features/resources/resources.model';
import { userSelectors } from '../../../features/user/user.slice';


interface ResourceFilterProps {
  resourceType: ResourceType;
  anchorElement: HTMLElement | null;
  setAnchorElement: (anchorElement: HTMLElement | null) => void;
  setFiltersCallback: (queryType?: string, schoolDistricts?: number[]) => void;
}

const ResourceFilter: React.FC<ResourceFilterProps> = ({
  resourceType,
  anchorElement,
  setAnchorElement,
  setFiltersCallback,
}) => {

  const scholarshipFilterTypes: ListMenuItem[] = [
    {
      id: 'current',
      label: 'Current Scholarships',
    },
    {
      id: 'all',
      label: 'All Scholarships',
    },
    {
      id: 'archived',
      label: 'Archived In-App',
    },
  ];

  const dispatch = useAppDispatch();

  const user = useAppSelector(userSelectors.selectUser);
  const schoolDistricts = useAppSelector(organizationSelectors.selectSchoolDistricts);

  useEffect(() => {
    dispatch(getAllSchoolDistricts());
  }, []);

  type FormValues = { queryType: string, schoolDistricts: ListMenuItem[] };
  const methods = useForm<FormValues>();
  const { handleSubmit } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    const queryTypeFilter = resourceType === ResourceType.scholarship ? values.queryType : resourceType;
    const schoolDistrictFilter = (values.schoolDistricts && !!values.schoolDistricts.length) ? values.schoolDistricts.map((district: ListMenuItem) => +district.id) : undefined;
    setFiltersCallback(queryTypeFilter, schoolDistrictFilter);
    setAnchorElement(null);
  };

  return (
    <Popover
      id="list-filter"
      anchorEl={anchorElement}
      open={Boolean(anchorElement)}
      onClose={() => setAnchorElement(null)}
      transformOrigin={topCenterTransformOrigin}
      anchorOrigin={bottomRightAnchorOrigin}
    >
      <CloseIcon className="close-icon" onClick={() => setAnchorElement(null)} />
      <form className="flex_col" onSubmit={handleSubmit(submitForm)}>
        <FormProvider {...methods}>

          {resourceType === ResourceType.scholarship && (
            <>
              <FormLabel id="scholarship-type-filter">Scholarships</FormLabel>
              <SecondaryColorRadioGroup
                name="queryType"
                itemList={scholarshipFilterTypes}
                labelledBy="scholarship-type-filter"
                size="small"
              />
            </>
          )}

          {user.isAdmin && (
            <>
              <FormLabel id="school-district-filter">School District</FormLabel>
              <FormGroup
                aria-labelledby="school-district-filter"
              >
                <DropdownSelect
                  name="schoolDistricts"
                  itemList={schoolDistricts}
                  size="small"
                  colorVariant="secondary"
                  multiple
                />
              </FormGroup>
            </>
          )}

          <SubmitButton
            className="submit-button"
            text="Apply Filters"
            variant="contained"
            size="small"
            color="secondary"
            disableElevation
          />
        </FormProvider>
      </form>
    </Popover>
  );
};

export default ResourceFilter;
