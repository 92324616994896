import {
  configureStore, ThunkAction, Action
} from '@reduxjs/toolkit';
import alertReducer from '../features/alert/alert.slice';
import navigationReducer from '../features/navigation/navigation.slice';
import userReducer from '../features/user/user.slice';
import careerReducer from '../features/careers/careers.slice';
import dashboardReducer from '../features/dashboard/dashboard.slice';
import scholarshipReducer from '../features/scholarship/scholarship.slice';
import resourceReducer from '../features/resources/resources.slice';
import organizationReducer from '../features/organization/organization.slice';
import notificationReducer from '../features/notifications/notifications.slice';
import pathwayReducer from '../features/pathways/pathways.slice';
import skillTrainingReducer from '../features/skillTrainings/skillTrainings.slice';
import skillsReducer from '../features/skills/skills.slice';
import announcementReducer from '../features/announcement/announcement.slice';
import masterKeyReducer from '../features/masterKey/masterKey.slice';
import approvalReducer from '../features/approvals/approvals.slice';
import zipcodeReducer from '../features/zipcode/zipcode.slice';
import resourceTagReducer from '../features/resourceTags/resourceTags.slice';
import collegeApplicationChecklistReducer from '../features/collegeApplicationChecklist/collegeApplicationChecklist.slice';
import inquiryReducer from '../features/inquiries/inquiries.slice';
import bulkAccountCreationReducer from '../features/bulkAccountCreation/bulkAccountCreation.slice';

export const store = configureStore({
  reducer: {
    alert: alertReducer,
    navigation: navigationReducer,
    user: userReducer,
    career: careerReducer,
    dashboard: dashboardReducer,
    scholarship: scholarshipReducer,
    resource: resourceReducer,
    organization: organizationReducer,
    notification: notificationReducer,
    pathway: pathwayReducer,
    skillTrainings: skillTrainingReducer,
    skills: skillsReducer,
    announcement: announcementReducer,
    masterKey: masterKeyReducer,
    approval: approvalReducer,
    zipcode: zipcodeReducer,
    resourceTag: resourceTagReducer,
    collegeApplicationChecklist: collegeApplicationChecklistReducer,
    inquiry: inquiryReducer,
    bulkAccountCreation: bulkAccountCreationReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>;
