/* eslint-disable camelcase */
import {
  HTTP_METHOD, requestFactory
} from '../../app/app.api';


export const signIn = async (email: string, password: string): Promise<string> => {
  const body = {
    email,
    password,
    is_admin_login: true,
  };

  const response = await requestFactory(HTTP_METHOD.POST, '/auth', undefined, body);

  return await response.data.token;
};

export const signOut = async () => {
  await requestFactory(HTTP_METHOD.POST, '/auth/logout', undefined, {
  });
  return;
};

export const forgotPassword = async (email: string): Promise<void> => {
  const body = {
    email,
  };

  await requestFactory(HTTP_METHOD.POST, '/forgot', undefined, body);
};

export const resetPassword = async (data: { email: string, token: string, password: string, password_confirmation: string }): Promise<void> => {
  await requestFactory(HTTP_METHOD.POST, '/reset-password', undefined, data);
};

export const verifyUserEmail = async (email: string): Promise<void> => {
  const body = {
    email,
  };

  await requestFactory(HTTP_METHOD.POST, '/auth/manual-verify', undefined, body);
};

export const verifyUserEmailByPin = async (data: { email: string, pin: string }): Promise<void> => {
  const body = {
    user_email: data.email,
    pin: data.pin,

  };
  await requestFactory(HTTP_METHOD.POST, '/auth/verify', undefined, body);
};

export const resetPin = async (data: { email: string }): Promise<void> => {
  const body = {
    user_email: data.email,
  };
  await requestFactory(HTTP_METHOD.POST, '/resetpin', undefined, body);
};