import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  NewPathway, PathwayListQueryParams
} from './pathways.model';
import * as pathwayApi from './pathways.api';


export const getPathwayTypeList = createAsyncThunk(
  'pathway/getPathwayTypeList',
  async () => {
    return await pathwayApi.getPathwayTypeList();
  }
);

export const getPathwayList = createAsyncThunk(
  'pathway/getPathwayList',
  async (query: PathwayListQueryParams) => {
    return await pathwayApi.getPathwayList(query);
  }
);

export const getPathwayDetails = createAsyncThunk(
  'pathway/getPathwayDetails',
  async (data: { id: number, organizationId?: number }) => {
    return await pathwayApi.getPathwayDetails(data.id, data.organizationId);
  }
);

export const createPathway = createAsyncThunk(
  'pathway/createPathway',
  async (data: NewPathway) => {
    return await pathwayApi.createPathway(data);
  }
);

export const updatePathway = createAsyncThunk(
  'pathway/updatePathway',
  async (data: { id: number, body: NewPathway, deletedCourses: number[] }) => {
    return await pathwayApi.updatePathway(data);
  }
);

export const schoolAdminUpdatePathway = createAsyncThunk(
  'pathway/schoolAdminUpdatePathway',
  async (data: { id: number, body: NewPathway, organizationId?: number }) => {
    return await pathwayApi.schoolAdminUpdatePathway(data);
  }
);

export const deletePathway = createAsyncThunk(
  'pathway/updatePathway',
  async (id: number) => {
    return await pathwayApi.deletePathway(id);
  }
);

export const togglePathwayActive = createAsyncThunk(
  'pathway/togglePathwayActive',
  async (id: number) => {
    await pathwayApi.togglePathwayActive(id);
    return id;
  }
);

export const togglePathwayCertificateActive = createAsyncThunk(
  'pathway/togglePathwayCertificateActive',
  async (id: number) => {
    await pathwayApi.togglePathwayCertificateActive(id);
    return id;
  }
);

export const togglePathwayActiveForSchool = createAsyncThunk(
  'pathway/togglePathwayActiveForSchool',
  async (data: { id: number, isHidden: boolean, organizationId?: number }) => {
    const newIsHiddenValue = await pathwayApi.togglePathwayActiveForSchool(data.id, data.isHidden, data.organizationId);

    return {
      id: data.id,
      isHidden: newIsHiddenValue,
    };
  }
);

export const togglePathwayCertificateActiveForSchool = createAsyncThunk(
  'pathway/togglePathwayCertificateActiveForSchool',
  async (data: { id: number, isHidden: boolean, organizationId?: number }) => {
    const newIsHiddenValue = await pathwayApi.togglePathwayCertificateActiveForSchool(data.id, data.isHidden, data.organizationId);

    return {
      id: data.id,
      isHidden: newIsHiddenValue,
    };
  }
);

export const togglePathwayCourseActive = createAsyncThunk(
  'pathway/togglePathwayCourseActive',
  async (id: number) => {
    await pathwayApi.togglePathwayCourseActive(id);
    return id;
  }
);

export const togglePathwayCourseActiveForSchool = createAsyncThunk(
  'pathway/togglePathwayCourseActiveForSchool',
  async (data: { id: number, isHidden: boolean, organizationId?: number }) => {
    const newIsHiddenValue = await pathwayApi.togglePathwayCourseActiveForSchool(data.id, data.isHidden, data.organizationId);

    return {
      id: data.id,
      isHidden: newIsHiddenValue,
    };
  }
);

export const dismissPathwayUpdatedBanner = createAsyncThunk(
  'pathway/dismissPathwayUpdatedBanner',
  async (pathwaySchoolId: number) => {
    return await pathwayApi.dismissPathwayUpdatedBanner(pathwaySchoolId);
  }
);
