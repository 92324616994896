import {
  User, UserRole
} from '../features/user/user.model';
import { postSecondaryDistrict } from '../features/organization/organization.model';

export enum StateStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  FAILED = 'failed'
}

export type AppConfigVariables = {
  // eslint-disable-next-line camelcase
  api_url: string;
  // eslint-disable-next-line camelcase
  student_base_url: string;
}

export interface AuthComponent {
  authorizedRoles?: UserRole[];
}

export const canUseAuthComponent = (userRoles: UserRole[], authorizedRoles?: UserRole[]): boolean => {
  if (!authorizedRoles || !authorizedRoles.length) {
    return true;
  }

  return authorizedRoles.some(role => {
    /*
     * This logic allows using institution_admin for authRole checking
     * Use for permissions that institution admins get but key contacts or community users otherwise don't
     */
    if (role === UserRole.institutionAdmin) {
      return userRoles.includes(UserRole.keyContact) && userRoles.includes(UserRole.community) && userRoles.includes(UserRole.institutionAdmin);
    }
    else if (role === UserRole.institutionDistrictAdmin) {
      return userRoles.includes(UserRole.districtAdmin) && userRoles.includes(UserRole.community) && userRoles.includes(UserRole.institutionAdmin);
    } else {
      return userRoles.includes(role);
    }
  });
};

export const isPostSecondaryAdmin = (user: User): boolean => {
  return user?.organization?.name === postSecondaryDistrict;
};

export interface AppRoute extends AuthComponent {
  path: string;
  externalLink?: boolean,
}

export const AppRoutes: Record<string, AppRoute> = {
  app: {
    path: '/app',
  },
  dashboard: {
    path: '/app/dashboard',
  },

  // Landing Page Routes
  signIn: {
    path: '/signin',
  },
  signUp: {
    path: '/signup',
  },
  forgotPassword: {
    path: '/forgot-password',
  },
  resetPassword: {
    path: '/reset-password',
  },
  passwordUpdated: {
    path: '/password-updated',
  },

  // User Routes
  users: {
    path: '/app/users',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.keyContact,
      UserRole.counselor,
    ],
  },
  studentUsers: {
    path: '/app/users/students',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.keyContact,
      UserRole.counselor,
    ],
  },
  keyContactUsers: {
    path: '/app/users/key-contacts',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.keyContact,
      UserRole.counselor,
    ],
  },
  counselorUsers: {
    path: '/app/users/counselors',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.keyContact,
      UserRole.counselor,
    ],
  },
  districtAdminUsers: {
    path: '/app/users/district-admins',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  communityUsers: {
    path: '/app/users/community',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  institutionAdminUsers: {
    path: '/app/users/institution-admins',
    authorizedRoles: [
      UserRole.admin,
      UserRole.institutionAdmin,
      UserRole.institutionDistrictAdmin,
    ],
  },
  deletedUsers: {
    path: '/app/users/deleted',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  newUser: {
    path: '/app/users/new/:userType',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.keyContact,
      UserRole.counselor,
    ],
  },
  userDetails: {
    path: '/app/users/:userId',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.keyContact,
      UserRole.counselor,
    ],
  },
  bulkUserCreate: {
    path: '/app/users/bulk-create',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.keyContact,
      UserRole.counselor,
    ],
  },
  // Organization Routes
  organizations: {
    path: '/app/organizations',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
    ],
  },
  schoolOrganizations: {
    path: '/app/organizations/schools',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
    ],
  },
  communityOrganizations: {
    path: '/app/organizations/community',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  schoolDetail: {
    path: '/app/organizations/school/:organizationId/view',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
    ],
  },
  communityDetail: {
    path: '/app/organizations/community/:organizationId/view',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  newSchool: {
    path: '/app/organizations/school/new',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  editSchool: {
    path: '/app/organizations/school/:organizationId/edit',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
    ],
  },
  editCommunity: {
    path: '/app/organizations/community/:organizationId/edit',
    authorizedRoles: [
      UserRole.admin,
    ],
  },

  // Resource Routes
  resources: {
    path: '/app/resources',
  },
  scholarships: {
    path: '/app/resources/scholarships',
  },
  scholarshipDetails: {
    path: '/app/resources/scholarships/:scholarshipId',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.keyContact,
      UserRole.counselor,
      UserRole.community,
      UserRole.institutionAdmin,
    ],
  },
  scholarshipApplicationDetails: {
    path: '/app/resources/scholarships/applications/:id',
  },
  internships: {
    path: '/app/resources/internships',
  },
  internshipDetails: {
    path: '/app/resources/internships/:resourceId',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.keyContact,
      UserRole.counselor,
      UserRole.community,
      UserRole.institutionAdmin,
    ],
  },
  opportunities: {
    path: '/app/resources/opportunities',
  },
  opportunityDetails: {
    path: '/app/resources/opportunities/:resourceId',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.keyContact,
      UserRole.counselor,
      UserRole.community,
      UserRole.institutionAdmin,
    ],
  },
  resourceApplicants: {
    path: '/app/resources/:resourceId/applicants',
    authorizedRoles: [
      UserRole.admin,
      UserRole.community,
      UserRole.counselor,
      UserRole.districtAdmin,
      UserRole.highSchoolKeyContact,
      UserRole.middleSchoolKeyContact,
      UserRole.institutionAdmin,
    ],
  },
  createResource: {
    path: '/app/resources/create/:resourceType',
  },
  editResource: {
    path: '/app/resources/edit/:resourceId',
  },
  // Resource Tags
  resourceTags: {
    path: '/app/resources/tags',
  },
  resourceTagDetails: {
    path: '/app/resources/tags/details/:id',
  },
  createResourceTag: {
    path: '/app/resources/tags/create',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  editResourceTag: {
    path: '/app/resources/tags/edit/:id',
    authorizedRoles: [
      UserRole.admin,
    ],
  },


  // Feature Routes
  features: {
    path: '/app/features',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.institutionAdmin,
      UserRole.keyContact,
      UserRole.counselor,
      UserRole.community,
    ],
  },
  pathways: {
    path: '/app/features/pathways',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.highSchoolKeyContact,
      UserRole.highSchoolCounselor,
      UserRole.middleSchoolKeyContact,
      UserRole.middleSchoolCounselor,
    ],
  },
  pathwayDetails: {
    path: '/app/features/pathways/:id',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.highSchoolKeyContact,
      UserRole.highSchoolCounselor,
      UserRole.middleSchoolKeyContact,
      UserRole.middleSchoolCounselor,
    ],
  },
  createPathway: {
    path: '/app/features/pathways/create',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  editPathway: {
    path: '/app/features/pathways/:id/edit',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.highSchoolKeyContact,
      UserRole.highSchoolCounselor,
    ],
  },
  skillTrainings: {
    path: '/app/features/skill-trainings',
    authorizedRoles: [
      UserRole.admin,
      UserRole.community,
      UserRole.institutionAdmin,
      UserRole.universityKeyContact,
      UserRole.universityCounselor,
      UserRole.community,
    ],
  },
  skillTrainingDetails: {
    path: '/app/features/skill-trainings/:id',
    authorizedRoles: [
      UserRole.admin,
      UserRole.community,
      UserRole.universityKeyContact,
      UserRole.universityCounselor,
      UserRole.institutionAdmin,
      UserRole.community,
    ],
  },
  createSkillTraining: {
    path: '/app/features/skill-trainings/create',
    authorizedRoles: [
      UserRole.admin,
      UserRole.community,
      UserRole.universityKeyContact,
      UserRole.universityCounselor,
      UserRole.institutionAdmin,
      UserRole.community,
    ],
  },
  editSkillTraining: {
    path: '/app/features/skill-trainings/:id/edit',
    authorizedRoles: [
      UserRole.admin,
      UserRole.community,
      UserRole.universityKeyContact,
      UserRole.universityCounselor,
      UserRole.institutionAdmin,
      UserRole.community,
    ],
  },
  careerClusters: {
    path: '/app/features/career-clusters',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  careerClusterDetails: {
    path: '/app/features/career-clusters/:id',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  editCareerCluster: {
    path: '/app/features/career-clusters/:id/edit',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  skills: {
    path: '/app/features/skills',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  skillDetails: {
    path: '/app/features/skills/:id',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  createSkill: {
    path: '/app/features/skills/create',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  editSkill: {
    path: '/app/features/skills/:id/edit',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  inquiries: {
    path: '/app/features/inquiries',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  collegeApplicationChecklist: {
    path: '/app/features/college-application-checklist',
    authorizedRoles: [
      UserRole.admin,
      UserRole.institutionAdmin,
      UserRole.institutionDistrictAdmin,
    ],
  },
  collegeApplicationChecklistApplicants: {
    path: '/app/features/college-application-checklist/applicants',
    authorizedRoles: [
      UserRole.admin,
      UserRole.institutionAdmin,
      UserRole.institutionDistrictAdmin,
    ],
  },
  collegeApplicationChecklistColleges: {
    path: '/app/features/college-application-checklist/colleges',
    authorizedRoles: [
      UserRole.admin,
      UserRole.institutionAdmin,
      UserRole.institutionDistrictAdmin,
    ],
  },
  collegeApplicationChecklistFinancialAidList: {
    path: '/app/features/college-application-checklist/financial-aid',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  createCollegeApplicationChecklistCollege: {
    path: '/app/features/college-application-checklist/colleges/create',
    authorizedRoles: [
      UserRole.admin,
      UserRole.institutionAdmin,
      UserRole.institutionDistrictAdmin,
    ],
  },
  editCollegeApplicationChecklistCollege: {
    path: '/app/features/college-application-checklist/colleges/edit/:collegeId',
    authorizedRoles: [
      UserRole.admin,
      UserRole.institutionAdmin,
      UserRole.institutionDistrictAdmin,
    ],
  },
  collegeApplicationChecklistCollegeDetails: {
    path: '/app/features/college-application-checklist/colleges/:collegeId',
    authorizedRoles: [
      UserRole.admin,
      UserRole.institutionAdmin,
    ],
  },

  financialAidInfoDetails: {
    path: '/app/features/college-application-checklist/financial-aid-info',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  editFinancialAidInfo: {
    path: '/app/features/college-application-checklist/financial-aid-info/edit',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  fafsaLinksDetails: {
    path: '/app/features/college-application-checklist/fafsa-links',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  editFafsaLinks: {
    path: '/app/features/college-application-checklist/fafsa-links/edit',
    authorizedRoles: [
      UserRole.admin,
    ],
  },

  // Alert Routes
  alerts: {
    path: '/app/alerts',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.keyContact,
      UserRole.counselor,
    ],
  },
  notifications: {
    path: '/app/alerts/notifications',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.keyContact,
      UserRole.counselor,
    ],
  },
  notificationDetails: {
    path: '/app/alerts/notifications/:notificationId',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.keyContact,
      UserRole.counselor,
    ],
  },
  createNotification: {
    path: '/app/alerts/notifications/create',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.keyContact,
      UserRole.counselor,
    ],
  },
  editNotification: {
    path: '/app/alerts/notifications/edit/:notificationId',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  announcements: {
    path: '/app/alerts/announcements',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  announcementDetails: {
    path: '/app/alerts/announcements/:announcementId',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  createAnnouncement: {
    path: '/app/alerts/announcements/create',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  editAnnouncement: {
    path: '/app/alerts/announcements/edit/:announcementId',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  masterKey: {
    path: '/app/alerts/master-key',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  masterKeyDetails: {
    path: '/app/alerts/master-key/:masterKeyId',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  createMasterKey: {
    path: '/app/alerts/master-key/create',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  editMasterKey: {
    path: '/app/alerts/master-key/:id/edit',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  masterKeySubmissions: {
    path: '/app/alerts/master-key-submissions/:masterKeyId',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  masterKeySubmissionDetails: {
    path: '/app/alerts/master-key-submission/:masterKeySubmissionId',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  // Approval Routes
  approvals: {
    path: '/app/approvals',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.highSchoolKeyContact,
      UserRole.middleSchoolKeyContact,
    ],
  },
  studentApprovals: {
    path: '/app/approvals/students',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.highSchoolKeyContact,
      UserRole.middleSchoolKeyContact,
    ],
  },
  transferApprovals: {
    path: '/app/approvals/transfers',
    authorizedRoles: [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.highSchoolKeyContact,
      UserRole.middleSchoolKeyContact,
    ],
  },
  resourceApprovals: {
    path: '/app/approvals/resources',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  notificationApprovals: {
    path: '/app/approvals/notifications',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  applicationApprovals: {
    path: '/app/approvals/applications',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  skillTrainingApprovals: {
    path: '/app/approvals/skill-trainings',
    authorizedRoles: [
      UserRole.admin,
    ],
  },
  organizationSettings: {
    path: '/app/organization-settings',
    authorizedRoles: [
      UserRole.community,
    ],
  },
};

export const AppAssetPaths = {
  icons: {
    A_TO_Z_RED: 'assets/icons/a-to-z.svg',
    ALERT_PURPLE: 'assets/icons/alert-purple.svg',
    ALERT_RED: 'assets/icons/alert-red.svg',
    APPROVALS: 'assets/icons/approvals.svg',
    APPROVALS_RED: 'assets/icons/approvals-red.svg',
    ARROW_DOWN_BLACK: 'assets/icons/arrow-down-black.svg',
    ARROW_UP_BLACK: 'assets/icons/arrow-up-black.svg',
    CSV: 'assets/icons/csv.svg',
    DOWNLOAD: 'assets/icons/download.svg',
    EXPORT: 'assets/icons/export.svg',
    FILTER: 'assets/icons/filter.svg',
    INFO: 'assets/icons/info.svg',
    KTS_ICON_WHITE: 'assets/icons/kts-icon-white.svg',
    MEGAPHONE_WHITE: 'assets/icons/megaphone-white.svg',
    NOTIFICATIONS_RECT: 'assets/icons/notifications-rect.svg',
    NOTIFICATIONS_WHITE: 'assets/icons/notifications-white.svg',
    ORGANIZATIONS_BLUE: 'assets/icons/organizations-blue.svg',
    ORGANIZATIONS_WHITE: 'assets/icons/organizations-white.svg',
    RESOURCES_ICON_RECT: 'assets/icons/resources-rect.svg',
    RED_OPEN_IN_NEW_TAB: 'assets/icons/red_open_in_new_tab.svg',
    RESOURCES_PURPLE: 'assets/icons/resources-purple.svg',
    RESOURCES_WHITE: 'assets/icons/resources-white.svg',
    SCHOLARSHIP_APPS_RECT: 'assets/icons/scholarship-apps-rect.svg',
    MASTERKEY_APPS_RECT: 'assets/icons/masterkey-purple.svg',
    SEARCH: 'assets/icons/search.svg',
    STAR_GREEN: 'assets/icons/star-green.svg',
    STAR_WHITE: 'assets/icons/star-white.svg',
    TIME_ICON: 'assets/icons/time-icon.svg',
    TRASH: 'assets/icons/trash.svg',
    TRASH_RED: 'assets/icons/trash-red.svg',
    TRASH_WHITE: 'assets/icons/trash-white.svg',
    USER_ACCOUNTS_RECT: 'assets/icons/user-accounts-rect.svg',
    USER_TRANSFERS_RECT: 'assets/icons/user-transfers-rect.svg',
    USERS_ORANGE: 'assets/icons/users-orange.svg',
    USERS_WHITE: 'assets/icons/users-white.svg',
    MY_DASHBOARD: 'assets/icons/my-dashboard.svg',
    WRITTEN_RESPONSE: 'assets/icons/written-response.svg',
    MULTIPLE_CHOICE: 'assets/icons/multiple-choice.svg',
    INFO_RED: 'assets/icons/info-red.svg',
    INQUIRIES: 'assets/icons/inquiries.svg',
    UPLOAD_CLOUD: 'assets/icons/upload-cloud.svg',

    // Status Icons
    statusIcons: {
      APPROVE: 'assets/icons/status/approve.svg',
      REJECT: 'assets/icons/status/reject.svg',
      APPROVED: 'assets/icons/status/approved.svg',
      ARCHIVED: 'assets/icons/status/archived.svg',
      PENDING: 'assets/icons/status/pending.svg',
      REJECTED: 'assets/icons/status/rejected.svg',
      UNVERIFIED_EMAIL: 'assets/icons/status/unverified-email.svg',
      IN_PROGRESS: 'assets/icons/status/in-progress.svg',
      COMPLETE: 'assets/icons/status/complete.svg',
      ACTIVE: 'assets/icons/status/active.svg',
      INACTIVE: 'assets/icons/status/inactive.svg',
      EXPIRED: 'assets/icons/status/expired.svg',
      UPDATE: 'assets/icons/status/update.svg',
      POINTS_AWARDED: 'assets/icons/status/points-awarded.svg',
      POINTS_DENIED: 'assets/icons/status/points-denied.svg',
      PENDING_REVIEW: 'assets/icons/status/pending-review.svg',
      SUBMISSION_POINTS_REWARDED: 'assets/icons/status/submission-points-rewarded.svg',
      SUBMISSION_POINTS_DENIED: 'assets/icons/status/submission-points-denied.svg',
      PARTIAL_REGISTRATION: 'assets/icons/status/partial-registration.svg',
    },
  },
  images: {
    KTS_LOGO_WHITE: 'assets/images/kts-logo-white.svg',
    KTS_LOGO: 'assets/images/kts-logo.svg',
    HANDSOME_THORNBERRY: 'assets/images/handsome_thornberry.png',
    ADD_POINTS: 'assets/images/add-points.svg',
    ADD_POINTS_SUCCESSFUL: 'assets/images/add-points-successful.svg',
    PEOPLE_TALKING_AROUND_TABLE: 'assets/images/people-talking-around-table.svg',
    TWO_PEOPLE_AT_A_DESK: 'assets/images/two-people-at-a-desk.svg',
    IPHONE_FORGOT_PASSWORD_STUDENT_LOGIN: 'assets/images/iphone-kts-forgot-password-student-login.png',
    BULK_CREATE_CSV_EXAMPLE: 'assets/images/bulk-create-csv-example.svg',
    UPLOAD_COMPLETE_GREEN: 'assets/images/upload-complete-green.svg',
    UPLOAD_FAILED_RED: 'assets/images/upload-failed-red.svg',
  },
  videos: {
    BULK_ACCOUNT_CREATE_TUTORIAL: 'assets/videos/bulk_account_create_tutorial.mp4',
    VIDEO_SCREENSHOT: 'assets/videos/video_screenshot2.png',
  },
};

export enum AppLinks {
  TICKET_MANAGER_LOGIN = 'https://app1.spotlighttms.com/app/index.html',
  WEBSITE_INQUIRIES = 'https://users.wix.com/signin?redirectTo=https:%2F%2Fmanage.wix.com%2Fdashboard%2F7c9b6b33-0429-43fe-8189-457bebc12001%2Finbox%2F%3FreferralInfo%3Dsidebar&originUrl=https:%2F%2Fmanage.wix.com%2Fdashboard%2F7c9b6b33-0429-43fe-8189-457bebc12001%2Finbox%2F%3FreferralInfo%3Dsidebar&overrideLocale=en&forceRender=true',
  METABASE_LOGIN = 'https://kts.dashboard.hoverstate.io/auth/login?redirect=%2F',
  METABASE_COLLGE_APPLICATION_WEEK = 'https://kts.dashboard.hoverstate.io/dashboard/28',
  KTS_RESOURCES = 'https://www.ktsutah.org/resources',
  PLANNING_GUIDE = 'https://drive.google.com/drive/u/0/folders/1zF87DdDNO3iTqnmsi71W8TmB0sUqg5F4',
  KTS_GOOGLE_DRIVE = 'https://drive.google.com/drive/folders/18onxJ3RFLNXJ2tilk-BMEXjYMXkIYawD',
  KTS_CONTACT_PAGE = 'https://www.ktsutah.org/contact',
  STUDENT_APP = 'https://app.ktsutah.org',
  STUDENT_APP_GUEST_SIGNIN = 'https://app.ktsutah.org/guest-signin',
}
