import React from 'react';
import {
  Box, Button, Modal
} from '@mui/material';
import { SkillTraining } from '../../features/skillTrainings/skillTrainings.model';
import { AppRoutes } from '../../app/app.types';
import OpenInNew from '@mui/icons-material/OpenInNew';

import './DuplicateResourceModal.scss';


interface DuplicateResourceModalProps {
  open: boolean;
  resourceText: string;
  additionalWarningText?: string;
  resources: SkillTraining[];
  onClose: () => void;
}

const DuplicateResourceModal: React.FC<DuplicateResourceModalProps> = ({
  open,
  resourceText,
  additionalWarningText,
  resources,
  onClose,
}) => {
  return (
    <Modal
      id="duplicate-resource-modal"
      open={open}
      onClose={onClose}
    >
      <Box className="modal-content card-background flex_col_jcenter_acenter">
        <h2>Duplicate {resourceText}</h2>
        <p className="warning-text">{additionalWarningText}</p>

        {resources.map((r: SkillTraining) => (
          <div key={r.id} className="resource-info flex_row_jbetween">
            <div className="info-box">
              <p className="">{r.name}</p>
            </div>

            <a className="link-box flex_jbetween_acenter"
              href={AppRoutes.skillTrainingDetails.path.replace(':id', r.id.toString())}
              target="_blank"
              rel="noreferrer noopener"
            >
              <p>View</p>
              <OpenInNew className="link-icon" />
            </a>
          </div>
        ))}

        <Button
          className="dismiss-button"
          variant="contained"
          onClick={onClose}
          disableElevation
        >
          Back to Course
        </Button>
      </Box>
    </Modal>
  );
};

export default DuplicateResourceModal;
