
import React from 'react';
import {
  AppAssetPaths, AppRoutes
} from '../../../app/app.types';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';


interface PasswordChangedProps { }

const PasswordChanged: React.FC<PasswordChangedProps> = () => {

  const navigate = useNavigate();

  return (
    <div className="content-container">
      <div className="top-section">
        <img src={AppAssetPaths.images.KTS_LOGO} alt="" />
        <h2>Password Changed</h2>
        <p className="subtitle">Your password has been reset.</p>

        <Button
          className="submit-button link-button"
          variant="contained"
          size="large"
          disableElevation
          onClick={() => navigate(AppRoutes.signIn.path)}
        >
          Back to Sign In
        </Button>
      </div>
    </div>
  );
};

export default PasswordChanged;
