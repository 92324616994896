/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HTTP_METHOD, requestFactory
} from '../../app/app.api';
import {
  ConstructResourceTag, ResourceTag, ResourceTagListQueryParams
} from './resourceTags.model';



export const getResourceTagList = async (query?: ResourceTagListQueryParams): Promise<{ resourceTags: ResourceTag[], count: number }> => {
  const offsetValue = query?.offset || 0;
  const limitValue = query?.limit || 100;
  // I feel like search is going to be added later so I put it in here :)
  const searchParam = query?.search ? `&search=${query?.search}` : '';
  let orderBy = query?.sortModel?.length ? `&order_by=${query?.sortModel[0].field}` : '';
  const orderDirecton = query?.sortModel?.length ? `&order_direction=${query?.sortModel[0].sort}` : '';

  orderBy = orderBy.replace('createdAt', 'created_at');

  const url = `/resource-tags?offset=${offsetValue}&limit=${limitValue}${searchParam}${orderBy}${orderDirecton}`;

  const { data } = await requestFactory(HTTP_METHOD.GET, url);

  const resourceTags = data.data.map((tag: any) => ConstructResourceTag(tag));
  return {
    resourceTags,
    count: data.count,
  };
};

export const getResourceTagById = async (id: number): Promise<ResourceTag> => {
  const { data } = await requestFactory(HTTP_METHOD.GET, `/resource-tags/${id}`);
  return await ConstructResourceTag(data.data);
};

export const createResourceTag = async (body: { name: string, description: string }): Promise<ResourceTag> => {
  const { data } = await requestFactory(HTTP_METHOD.POST, '/resource-tags', undefined, body);
  return await ConstructResourceTag(data.data);
};

export const updateResourceTag = async (id: number, body: { name: string, description: string }): Promise<ResourceTag> => {
  const { data } = await requestFactory(HTTP_METHOD.PATCH, `/resource-tags/${id}`, undefined, body);
  return await ConstructResourceTag(data.data);
};

export const deleteResourceTag = async (id: number): Promise<void> => {
  await requestFactory(HTTP_METHOD.DELETE, `/resource-tags/${id}`);
};