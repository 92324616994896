import { createAsyncThunk } from '@reduxjs/toolkit';
import * as scholarshipApi from './scholarship.api';


export const getScholarshipById = createAsyncThunk(
  'scholarships/getScholarshipById',
  async (id: number) => {
    return await scholarshipApi.getScholarshipById(id);
  }
);

export const getScholarshipApplicationDetails = createAsyncThunk(
  'scholarships/getScholarshipApplicationDetails',
  async (data: {id: number; userId: string}) => {
    return await scholarshipApi.getScholarshipApplicationDetails(data.id, data.userId);
  }
);

export const approveScholarshipApplication = createAsyncThunk(
  '/approvals/approve-applications',
  async (data: {ids: Array<number>; userId: string}) => {
    return await scholarshipApi.approveScholarshipApplications(data.ids, data.userId);
  }
);

export const rejectScholarshipApplication = createAsyncThunk(
  '/approvals/reject-applications',
  async (data: {ids: Array<number>; userId: string}) => {
    return await scholarshipApi.rejectScholarshipApplications(data.ids, data.userId);
  }
);
