import React, {
  useEffect, useState
} from 'react';
import {
  Button, CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup
} from '@mui/material';
import * as yup from 'yup';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import {
  useNavigate, useParams
} from 'react-router-dom';
import TextInput from '../../../components/forms/TextInput';
import DropdownSelect from '../../../components/forms/DropdownSelect';
import dayjs from 'dayjs';
import AddIcon from '@mui/icons-material/Add';
import SubmitButton from '../../../components/forms/SubmitButton';
import {
  AppAssetPaths, AppRoutes, StateStatus
} from '../../../app/app.types';
import {
  archiveMasterKey,
  createMasterKey,
  editMasterKey,
  getMasterKeyDetails,
  getMasterKeyTypeList
} from '../../../features/masterKey/masterKey.thunks';
import { masterKeySelectors } from '../../../features/masterKey/masterKey.slice';
import { setAlert } from '../../../features/alert/alert.slice';
import ArchiveMasterKeyConfirmationModal from '../../../components/modal/ArchiveMasterKeyConfirmationModal';
import { getUserTypeList } from '../../../features/user/user.thunks';
import { userSelectors } from '../../../features/user/user.slice';
import {
  MasterKeyTypes,
  MultiChoiceQuestion,
  ShortQuestion
} from '../../../features/masterKey/masterKey.model';
import { OrganizationType } from '../../../features/organization/organization.model';
import { store } from '../../../app/store';
import { ListMenuItem } from '../../../components/forms/form.types';

import './CreateEditMasterKey.scss';


interface CreateEditMasterKeyProps { }

const CreateEditMasterKey: React.FC<CreateEditMasterKeyProps> = () => {

  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const masterKey = useAppSelector(masterKeySelectors.selectMasterKeyDetails);
  const masterKeyStatus = useAppSelector(masterKeySelectors.selectMasterKeyDetailsStatus);
  const masterKeyTypeList = useAppSelector(masterKeySelectors.selectMasterKeyTypeList);
  const masterKeyTypeListLoaded = useAppSelector(masterKeySelectors.selectMasterKeyTypeListLoaded);
  const userTypeList = userSelectors.selectFilteredUserTypes(store.getState(), {
    omittedTypes: [
      'Adult',
    ],
  });

  const [
    editMode,
    setEditMode,
  ] = useState(false);
  const [
    userTypeFieldDisabled,
    setUserTypeFieldDisabled,
  ] = useState(false);
  const [
    readyToLoad,
    setReadyToLoad,
  ] = useState(false);
  const [
    pageLoaded,
    setPageLoaded,
  ] = useState(false);
  const [
    archiveModal,
    setArchiveModal,
  ] = useState(false);

  useEffect(() => {
    if (params && params.id) {
      const id = parseInt(params.id);
      dispatch(getMasterKeyDetails(id));
      setEditMode(true);
    }
    !masterKeyTypeListLoaded && dispatch(getMasterKeyTypeList());
    dispatch(getUserTypeList());
  }, []);

  useEffect(() => {
    if ((params && params.id && +params.id !== masterKey.id) || pageLoaded) return;

    if (
      masterKey &&
      (userTypeList && !!userTypeList.length) &&
      (masterKeyTypeList && !!masterKeyTypeList.length)
    ) {
      setReadyToLoad(true);
    }
  }, [
    masterKey,
    userTypeList,
  ]);

  useEffect(() => {
    if (readyToLoad) {
      prePopulateValues();
      setPageLoaded(true);
    }
  }, [
    readyToLoad,
  ]);

  const prePopulateValues = () => {
    setValue('title', editMode ? masterKey.title : '');
    setValue('startDate', editMode ? masterKey.startDate : dayjs().format('YYYY-MM-DD'));
    setValue('endDate', editMode ? masterKey.endDate : dayjs().add(1, 'day').format('YYYY-MM-DD'));
    setValue('masterKeyType', editMode ? masterKeyTypeList.find(t => t.id === masterKey.masterKeyTypeId) : null);
    setValue('userType', (editMode && masterKey.userTypeIds && !!userTypeList.length)
      ? masterKey.userTypeIds?.map((m: number) => userTypeList.find(e => e.id == m))
      : null
    );

    if (masterKey.questions) {
      masterKey.questions.map((question: ShortQuestion | MultiChoiceQuestion) => {
        if ((question as MultiChoiceQuestion).answers) {
          appendNewQuestion({
            question: question.question,
            // Two answers are appended as a minimum of 2 are required
            answers: [
              ...(question as MultiChoiceQuestion).answers,
            ],
            multi: (question as MultiChoiceQuestion).multi,
          });
        }
        else {
          appendNewQuestion({
            question: question.question,
          });
        }
      });
    }
    setValue('points', editMode ? masterKey.points : 1);
  };

  const formValidationSchema = yup.object().shape({
    title: yup.string()
      .required('Title is required')
      .typeError('Title is required'),
    masterKeyType: yup.object().nullable()
      .required('Master key type is required'),
    userType: yup.array()
      .min(1, 'User type is required')
      .required('User type is required')
      .typeError('User type is required'),
    startDate: yup.string()
      .required('Start date is required')
      .test(
        'start_date_test',
        'Start date must be before end date',
        (value, parent) => parent.parent.endDate ? dayjs(parent.parent.endDate).isSame(value) || dayjs(parent.parent.endDate).isAfter(value) : true
      ),
    endDate: yup.string()
      .required('End date is required')
      .test(
        'end_date_test',
        'End date must be after start date',
        (value, parent) => value ? dayjs(value).isAfter(parent.parent.startDate) : true
      ),
    points: yup.number()
      .required('Points are required')
      .typeError('Points are required'),
    questions: yup.array()
      .min(1, 'A quesiton is required')
      .required('Questions are required')
      .of(
        yup.object().shape({
          question: yup.string()
            .required('Question is required')
            .typeError('Question is required'),
          answers: yup.array()
            .min(2, 'At least one answer is required')
            .of(
              yup.string().required('Answer is required')
            ),
          multi: yup.bool(),
        })
      ),
  }).required();

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const { control, register, handleSubmit, setValue, getValues, formState: { errors } } = methods;

  const {
    fields,
    append: appendNewQuestion,
    update: updateQuestion,
    remove,
    move,
  } = useFieldArray({
    name: 'questions',
    control,
  });

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    if (params && params.id) {
      dispatch(editMasterKey({
        id: +params.id,
        values: values,
      }))
        .unwrap()
        .then(() => {
          dispatch(setAlert({
            type: 'success',
            message: 'Successfully updated master key.',
          }));
          navigate(AppRoutes.masterKey.path);
        })
        .catch(() => {
          dispatch(setAlert({
            type: 'error',
            message: 'Unable to update master key.',
          }));
        });
    }
    else {
      dispatch(createMasterKey(values))
        .unwrap()
        .then(() => {
          dispatch(setAlert({
            type: 'success',
            message: 'Successfully created new master key.',
          }));
          navigate(AppRoutes.masterKey.path);
        })
        .catch(() => {
          dispatch(setAlert({
            type: 'error',
            message: 'Unable to create new master key.',
          }));
        });
    }
  };

  const handleMasterKeyTypeSelection = (type: ListMenuItem) => {
    if (type?.label === MasterKeyTypes.College) {
      setValue('userType', [
        userTypeList.find(t => t.type === OrganizationType.HighSchool),
      ]);
      setUserTypeFieldDisabled(true);
    }
    else {
      setUserTypeFieldDisabled(false);
    }
  };

  const handleArchiveMasterKey = () => {
    setArchiveModal(false);
    if (params && params.id) {
      dispatch(archiveMasterKey(+params?.id))
        .unwrap()
        .then(() => {
          dispatch(setAlert({
            type: 'success',
            message: 'Successfully archived master key.',
          }));
          navigate(AppRoutes.masterKey.path);
        })
        .catch(() => {
          dispatch(setAlert({
            type: 'error',
            message: 'Unable to archive master key.',
          }));
        });
    }
  };

  const handleMoveQuestion = (index: number) => {
    return (
      <span className="flex_row_acenter">
        {index !== 0 && (
          <img className="cursor-pointer"
            src={AppAssetPaths.icons.ARROW_UP_BLACK}
            alt="Move up"
            onClick={() => move(index, (index - 1))}
          />
        )}
        {(index + 1) !== fields.length && (
          <img className="cursor-pointer"
            src={AppAssetPaths.icons.ARROW_DOWN_BLACK}
            alt="Move down"
            onClick={() => move(index, (index + 1))}
          />
        )}
        <img className="cursor-pointer"
          src={AppAssetPaths.icons.TRASH_RED}
          alt="Remove"
          onClick={() => remove(index)}
        />
      </span>
    );
  };

  return (
    <form id="create-edit-masterkey" onSubmit={handleSubmit(submitForm)}>
      {(masterKeyStatus === StateStatus.IDLE && pageLoaded) ? (
        <div className="card-background flex_col">
          <div className="masterkey-content-header">
            <h3>{editMode ? 'Edit Master Key' : 'New Master Key'}</h3>
          </div>

          <FormProvider {...methods}>
            <div className="form-content flex_row_jbetween_wrap">
              <div className="half-width">
                <TextInput
                  name="title"
                  label="Title"
                  errorMessage={errors?.title?.message}
                  type="text"
                  size="small"
                  required
                />

                <DropdownSelect
                  name="userType"
                  label="User Type"
                  itemList={userTypeList}
                  errorMessage={errors?.userType?.message}
                  disabled={userTypeFieldDisabled}
                  size="small"
                  required
                  multiple
                />

                <TextInput
                  name="endDate"
                  label="End Date"
                  errorMessage={errors?.endDate?.message}
                  type="date"
                  size="small"
                  required
                  InputProps={{
                    inputProps: {
                      min: dayjs().add(1, 'day').format('YYYY-MM-DD'),
                    },
                  }}
                />
              </div>

              <div className="half-width">
                <DropdownSelect
                  name="masterKeyType"
                  label="Master Key Type"
                  itemList={masterKeyTypeList}
                  errorMessage={errors?.masterKeyType?.message}
                  onChange={handleMasterKeyTypeSelection}
                  size="small"
                  required
                />

                <TextInput
                  name="startDate"
                  label="Start Date"
                  errorMessage={errors?.startDate?.message}
                  type="date"
                  size="small"
                  required
                  InputProps={{
                    inputProps: {
                      min: dayjs().format('YYYY-MM-DD'),
                    },
                  }}
                />

                <TextInput
                  name="points"
                  label="Points"
                  errorMessage={errors?.points?.message}
                  type="number"
                  size="small"
                  required
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                />
              </div>

              <div className="questions-container">
                {fields.length ? (
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  fields.map((q: any, i: number) => {
                    return (
                      <div key={q.id}>
                        {('answers' in q) ? (
                          <div>
                            <div className="flex_row_jbetween">
                              <p className="static-input-label"> {`Question ${i + 1}`} </p>
                              {handleMoveQuestion(i)}
                            </div>
                            <TextInput
                              name={`questions.${i}.question` as 'questions.0.question'}
                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              errorMessage={(errors?.questions as any)?.[i]?.question?.message}
                              type="textarea"
                              size="small"
                              required
                              multiLine
                              rows={3}
                            />

                            <FormControl>
                              <Controller
                                name={`questions.${i}.multi`}
                                control={control}
                                render={({ field }) => (
                                  <RadioGroup
                                    {...field}
                                    row >
                                    <FormControlLabel label="Select one"
                                      value={false}
                                      control={<Radio {...register(`questions.${i}.multi` as const)} />
                                      }
                                    />

                                    <FormControlLabel label="Select all that apply"
                                      value={true}
                                      control={<Radio {...register(`questions.${i}.multi` as const)} />}
                                    />
                                  </RadioGroup>
                                )} />
                            </FormControl>

                            {// eslint-disable-next-line @typescript-eslint/no-explicit-any
                              q.answers.map((a: any, j: number) => (
                                <div key={a.id}>
                                  <div className="flex_row_jbetween">
                                    <p className="static-input-label"> {`Answer ${j + 1}`} </p>

                                    <img className="cursor-pointer"
                                      src={AppAssetPaths.icons.TRASH_RED}
                                      alt="Remove"
                                      onClick={() => {
                                        q.answers.splice(j, 1);
                                        updateQuestion(i, {
                                          question: getValues(`questions.${i}.question`),
                                          answers: [
                                            ...getValues(`questions.${i}.answers`),
                                          ],
                                        });
                                      }}
                                    />
                                  </div>
                                  <TextInput
                                    name={`questions.${i}.answers.${j}` as 'questions.0.answers.0'}
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    errorMessage={(errors?.questions as any)?.[i]?.answers?.[j]?.message}
                                    type="text"
                                    size="small"
                                    required
                                  />
                                </div>
                              ))
                            }

                            <Button
                              startIcon={<AddIcon />}
                              onClick={() => {
                                updateQuestion(i, {
                                  question: getValues(`questions.${i}.question`),
                                  answers: [
                                    ...getValues(`questions.${i}.answers`),
                                    '',
                                  ],
                                });
                              }}
                            >
                              Add answer
                            </Button>

                            <span className="divider" />
                          </div>
                        ) : (
                          <div>
                            <div className="flex_row_jbetween">
                              <p className="static-input-label"> {`Question ${i + 1}`} </p>
                              {handleMoveQuestion(i)}
                            </div>
                            <TextInput
                              name={`questions.${i}.question` as 'questions.0.question'}
                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              errorMessage={(errors?.questions as any)?.[i]?.question?.message}
                              type="textarea"
                              size="small"
                              required
                              multiLine
                              rows={3}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <p>There must be at least one question</p>
                )}

              </div>
            </div>
          </FormProvider>

          <div className="buttons-container flex_row">
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              onClick={() => {
                appendNewQuestion({
                  question: '',
                });
              }}
            >
              Add Written Question
            </Button>

            <Button
              variant="contained"
              color="secondary"
              disableElevation
              onClick={() => {
                appendNewQuestion({
                  question: '',
                  // Two answers are appended as a minimum of 2 are required
                  answers: [
                    '',
                    '',
                  ],
                  multi: false,
                });
              }}
            >
              Add Multiple Choice Question
            </Button>
          </div>

          <div className="buttons-container flex_row_jbetween">
            {editMode && (
              <Button
                variant="outlined"
                disableElevation
                onClick={() => setArchiveModal(true)}
              >
                Archive Master Key
              </Button>
            )}

            <div className={editMode ? 'right-side flex_jend' : 'right-side flex_jbetween'}>
              <Button
                variant="outlined"
                disableElevation
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>

              <SubmitButton
                text="Save"
                variant="contained"
                disableElevation
              />
            </div>
          </div>

          <ArchiveMasterKeyConfirmationModal
            open={archiveModal}
            onConfirm={() => handleArchiveMasterKey()}
            onClose={() => setArchiveModal(false)}
          />
        </div>
      ) : (
        <div className="flex_jcenter_acenter loading-indicator">
          <CircularProgress size="50px" />
        </div>
      )}
    </form>
  );
};

export default CreateEditMasterKey;
