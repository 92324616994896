/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CareerCluster,
  CareerClusterListQuery,
  CareerGroup,
  ConstructCareerCluster,
  ConstructCareerGroup
} from './careers.model';
import {
  getApiBaseUrl,
  HTTP_METHOD,
  requestFactory
} from '../../app/app.api';
import { UserRole } from '../user/user.model';
import { getSessionToken } from '../../util/localStorage';


export const getCareerGroups = async (query?: CareerClusterListQuery): Promise<CareerGroup[]> => {

  let orderBy = (query && query.sortModel) ? `&order_by=${query.sortModel[0].field}` : '';
  const orderDirecton = (query && query.sortModel) ? `&order_direction=${query?.sortModel[0].sort}` : '';

  orderBy = orderBy.replace('label', 'title');

  const { data } = await requestFactory(HTTP_METHOD.GET, `/career-group${orderBy}${orderDirecton}`);

  return data.data.map((c: any) => ConstructCareerGroup(c));
};

export const getCareerClusters = async (): Promise<CareerGroup[]> => {
  const { data } = await requestFactory(HTTP_METHOD.GET, '/pathway-cluster');

  return data.data.map((c: any) => ConstructCareerGroup(c));
};


export const getCareerGroupById = async (id: number): Promise<CareerCluster> => {

  const { data } = await requestFactory(HTTP_METHOD.POST, `/skillup-courses/career-group/${id}`, undefined, {
  });

  return ConstructCareerCluster(data.data);
};


export const updateCareerGroupSkills = async (submitBody: { skills: any[], careerId: number }): Promise<any> => {
  const returnArray = submitBody.skills.map((s: any) => ({
    id: s.skill.id,
    is_must_have: s.haveType === 'mustHave' ? true : false,
    is_nice_to_have: s.haveType === 'niceToHave' ? true : false,
  }));

  const { data } = await requestFactory(HTTP_METHOD.PATCH, `/skillup-courses/career-group/${submitBody.careerId}`, undefined, {
    skills_data: returnArray,
  });

  return data.data;
};

export const removeAllCareerGroupSkills = async (id: number) => {
  const { data } = await requestFactory(HTTP_METHOD.DELETE, `/skillup-courses/career-group/${id}`);
  return data.data;
};



// ------------------------- INTO CAREERS ------------------------- \\

export const ssoLogin = async (userType: UserRole): Promise<void> => {

  const token = await getSessionToken();
  const baseUrl = window.location.origin;
  const apiUrl = await getApiBaseUrl();

  let url = `${baseUrl}/assets/into-careers.html?url=${apiUrl}/into-careers/signin&token=${btoa(token as string)}`;

  if (userType) {
    url = `${url}&userType=${userType}`;
  }

  window.open(url);
};
