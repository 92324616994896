/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export interface UserPathway {
  id: number;
  name?: string;
  creditsCompleted?: number;
  totalCredits?: number;
  courses?: UserCourse[];
}

export const ConstructUserPathway = (data: any): UserPathway => {
  return {
    id: data.id,
    name: data.name,
    creditsCompleted: data.credits_completed,
    totalCredits: data.credits_to_complete,
    courses: data.courses?.map((c: any) => ConstructUserCourse(c)),
  };
};

export interface UserCourse {
  id: number;
  name?: string;
  credits?: number;
  courseCompleted?: boolean;
}

export const ConstructUserCourse = (data: any): UserCourse => {
  return {
    id: data.id,
    name: data.name,
    credits: data.credits,
    courseCompleted: !!data.course_completed_count,
  };
};
