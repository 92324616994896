import React, {
  useEffect, useState
} from 'react';
import './ResourceTagsList.scss';
import { Box } from '@mui/system';
import {
  Button, Pagination
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import {
  AppRoutes, canUseAuthComponent
} from '../../../app/app.types';
import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import { TableState } from '../../../constants/tables/table.types';
import { resourceTagListColumns } from '../../../constants/tables/resourceTable.types';
import { ResourceTagListQueryParams } from '../../../features/resourceTags/resourceTags.model';
import { getResourceTagList } from '../../../features/resourceTags/resourceTags.thunks';
import { resourceTagSelectors } from '../../../features/resourceTags/resourceTags.slice';
import {
  DataGrid, GridSortModel
} from '@mui/x-data-grid';
import TableRowCount from '../../../components/table/TableRowCount';
import { userSelectors } from '../../../features/user/user.slice';



interface ResourceTagsListProps {
  inModal?: boolean;
  modalClose?: () => void
}

const ResourceTagsList: React.FC<ResourceTagsListProps> = ({ inModal, modalClose }) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(userSelectors.selectUser);
  const resourceTagList = useAppSelector(resourceTagSelectors.selectResourceTagList);
  const resourceTagListTotalCount = useAppSelector(resourceTagSelectors.selectResourceTagListCount);

  const [
    tableState,
    setTableState,
  ] = useState<TableState>({
    page: 1,
    pageSize: 100,
    columns: resourceTagListColumns,
    rows: [],
    loading: false,
  });

  const [
    listQuery,
    setListQuery,
  ] = useState<ResourceTagListQueryParams>({
    offset: 0,
    limit: undefined,
    search: undefined,
    sortModel: undefined,
  });

  //Filtering List
  useEffect(() => {
    setTableState((prev) => ({
      ...prev,
      page: 1,
      rows: [],
      loading: true,
    }));

    dispatch(getResourceTagList({
      ...listQuery,
      offset: 0,
    }));
  }, [
    listQuery,
  ]);

  // Loading more data for pagination
  useEffect(() => {
    if (tableState.page < resourceTagList.length / tableState.pageSize || !resourceTagList.length || resourceTagList.length >= resourceTagListTotalCount) return;

    setTableState((prev) => ({
      ...prev,
      loading: true,
    }));

    dispatch(getResourceTagList({
      ...listQuery,
      offset: tableState.pageSize * (tableState.page - 1),
    }));
  }, [
    tableState.page,
    tableState.pageSize,
  ]);

  useEffect(() => {
    setTableState((prev) => ({
      ...prev,
      loading: false,
      rows: resourceTagList,
    }));
  }, [
    resourceTagList,
  ]);



  return (
    <div id="resource-tags-list">
      <Box className="page-content card-background">
        <div className="list-header flex_row_jbetween_acenter">
          <div className="flex_col">
            <span className="back-button flex_acenter"
              onClick={() => !inModal ? navigate(AppRoutes.resources.path) : (modalClose && modalClose())}
            >
              <ChevronLeftIcon color="secondary" />
              <h3>Resource Tags</h3>
            </span>
            <p>Tags can be added to resources so that users can filter by categories.</p>
          </div>

          {(canUseAuthComponent(user.roles, AppRoutes.createResourceTag.authorizedRoles) && !inModal) && (
            <Button
              className="create-button"
              variant="contained"
              disableElevation
              onClick={() => navigate(AppRoutes.createResourceTag.path)}
            >
              New Tag
            </Button>
          )}
        </div>

        <DataGrid
          className="table"
          {...tableState}
          components={{
            Pagination: Pagination,
          }}
          getRowHeight={() => inModal ? 'auto' : 52}
          componentsProps={{
            pagination: {
              page: tableState.page,
              count: Math.ceil(resourceTagListTotalCount / tableState.pageSize),
              onChange: (_: void, page: number) => {
                setTableState((prev) => ({
                  ...prev,
                  page,
                }));
              },
            },
          }}
          onRowClick={(event) => navigate(AppRoutes.resourceTagDetails.path.replace(':id', event.row.id.toString()))}
          pagination
          paginationMode="server"
          sortingMode="server"
          sortModel={listQuery.sortModel}
          onSortModelChange={(newSortModel: GridSortModel) => {
            setListQuery((prev) => ({
              ...prev,
              sortModel: newSortModel,
            }));
          }}
          disableColumnFilter
          disableColumnMenu
          hideFooterSelectedRowCount
        />
        <TableRowCount
          length={resourceTagList.length}
          pageSize={tableState.pageSize}
          page={tableState.page}
          totalCount={resourceTagListTotalCount}
        />
      </Box>

    </div>
  );

};

export default ResourceTagsList;