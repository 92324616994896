import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { StateStatus } from '../../app/app.types';
import { Zipcode } from './zipcode.model';
import { getZipcodeList } from './zipcode.thunks';


/* State */

export interface UserState {
  zipcodeList: {
    status: StateStatus;
    data: Zipcode[];
    totalCount: number;
    loaded: boolean;
  }
}

const initialState: UserState = {
  zipcodeList: {
    status: StateStatus.IDLE,
    data: [],
    totalCount: 0,
    loaded: false,
  },
};


/* Reducer */

export const zipcodeSlice = createSlice({
  name: 'zipcode',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getZipcodeList.pending, (state) => {
        state.zipcodeList.status = StateStatus.LOADING;
      })
      .addCase(getZipcodeList.fulfilled, (state, action) => {
        state.zipcodeList.status = StateStatus.IDLE;
        state.zipcodeList.data = action.payload.zipcodes;
        state.zipcodeList.totalCount = action.payload.count;
        state.zipcodeList.loaded = true;
      })
      .addCase(getZipcodeList.rejected, (state) => {
        state.zipcodeList.status = StateStatus.FAILED;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const { } = zipcodeSlice.actions;


/* Selectors */

const selectZipcodeList = (state: RootState): Zipcode[] => state.zipcode.zipcodeList.data;
const selectTotalZipcodeListCount = (state: RootState): number => state.zipcode.zipcodeList.totalCount;

export const zipcodeSelectors = {
  selectZipcodeList,
  selectTotalZipcodeListCount,
};

export default zipcodeSlice.reducer;
