import { createSlice } from '@reduxjs/toolkit';
import { StateStatus } from '../../app/app.types';
import { RootState } from '../../app/store';
import {
  EmptyScholarship, Scholarship, ScholarshipApplicationDetails, EmptyScholarshipApplicationDetails
} from './scholarship.model';
import {
  getScholarshipById, getScholarshipApplicationDetails
} from './scholarship.thunks';

export interface ScholarshipState {
  scholarshipDetails: {
    status: StateStatus;
    data: Scholarship;
  },
  scholarshipApplicationDetails: {
    status: StateStatus;
    data: ScholarshipApplicationDetails,
  }
}

const initialState: ScholarshipState = {
  scholarshipDetails: {
    status: StateStatus.IDLE,
    data: EmptyScholarship,
  },
  scholarshipApplicationDetails: {
    status: StateStatus.IDLE,
    data: EmptyScholarshipApplicationDetails,
  },
};



/* Reducer */
export const scholarshipSlice = createSlice({
  name: 'scholarships',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getScholarshipById.pending, (state) => {
        state.scholarshipDetails.status = StateStatus.LOADING;
      })
      .addCase(getScholarshipById.fulfilled, (state, action) => {
        state.scholarshipDetails.status = StateStatus.IDLE;
        state.scholarshipDetails.data = action.payload;
      })
      .addCase(getScholarshipById.rejected, (state) => {
        state.scholarshipDetails.status = StateStatus.FAILED;
      })

      // ---------- Scholarship Application Details ---------- \\
      .addCase(getScholarshipApplicationDetails.pending, (state) => {
        state.scholarshipApplicationDetails.status = StateStatus.LOADING;
      })
      .addCase(getScholarshipApplicationDetails.fulfilled, (state, action) => {
        state.scholarshipApplicationDetails.status = StateStatus.IDLE;
        state.scholarshipApplicationDetails.data = action.payload;  
      })
      .addCase(getScholarshipApplicationDetails.rejected, (state) => {
        state.scholarshipApplicationDetails.status = StateStatus.FAILED;
      });
  },
});




/* Selectors */
// Scholarship Details
const selectScholarshipDetails = (state: RootState): Scholarship => state.scholarship.scholarshipDetails.data;
// ---------- Scholarship Application Details ---------- \\
const selectScholarshipApplicationDetails = (state: RootState): ScholarshipApplicationDetails => state.scholarship.scholarshipApplicationDetails.data;


export const scholarshipSelectors = {
  selectScholarshipDetails,
  selectScholarshipApplicationDetails,
};



export default scholarshipSlice.reducer;