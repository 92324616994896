import React, {
  useEffect, useState
} from 'react';
import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import {
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import { scholarshipSelectors } from '../../../features/scholarship/scholarship.slice';
import {
  getScholarshipApplicationDetails, approveScholarshipApplication, rejectScholarshipApplication
} from '../../../features/scholarship/scholarship.thunks';
import { userSelectors } from '../../../features/user/user.slice';
import './ScholarshipApplicationDetails.scss';
import ApproveRejectCard from '../../../components/details/ApproveRejectCard';
import { setAlert } from '../../../features/alert/alert.slice';
import { AppAssetPaths } from '../../../app/app.types';


interface ScholarshipApplicationDetailsProps {
}

const ScholarshipApplicationDetails: React.FC<ScholarshipApplicationDetailsProps> = () => {

  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const params = useParams();
  state.userId = params.id;
  const scholarshipApplicationDetails = useAppSelector(scholarshipSelectors.selectScholarshipApplicationDetails);
  const user = useAppSelector(userSelectors.selectUser);
  const navigate = useNavigate();

  const [
    showApproveReject,
    setShowApproveReject,
  ] = useState<boolean>(false);

  useEffect(() => {
    const data = {
      id: state.id,
      userId: state.userId,
    };
    dispatch(getScholarshipApplicationDetails(data));

  }, []);

  useEffect(() => {
    setShowApproveReject(scholarshipApplicationDetails.approved === null);
  }, [
    scholarshipApplicationDetails,
  ]);

  const handleReject = () => {

    dispatch(rejectScholarshipApplication({
      ids: [
        state.applicationId,
      ],
      userId: state.userId,
    })).unwrap()
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully rejected applicant.',
        }));
        setShowApproveReject(false);
        navigate(-1);
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to reject applicant.',
        }));
      });
  };

  return (
    <div className="card-background scholarship-application-details">
      <div className="details-header flex_row_jbetween_acenter">
        <h3>{state.title}</h3>

        {(user.isAdmin && showApproveReject) && (
          <ApproveRejectCard
            onApprove={() => {
              dispatch(approveScholarshipApplication({
                ids: [
                  state.applicationId,
                ],
                userId: state.userId,
              })).unwrap()
                .then(() => {
                  dispatch(setAlert({
                    type: 'success',
                    message: 'Successfully approved applicant.',
                  }));
                  setShowApproveReject(false);
                })
                .catch(() => {
                  dispatch(setAlert({
                    type: 'error',
                    message: 'Unable to approve applicant.',
                  }));
                });
            }}

            onReject={() => handleReject()}
          />
        )}
        {(!user.isAdmin && !scholarshipApplicationDetails.approved) && (
          <div className="details-header flex_row_jbetween_acenter">
            <h4>Approval Status:</h4>
            <img src={AppAssetPaths.icons.statusIcons.PENDING} alt="pending_label" />
          </div>
        )}
      </div>


      <div className="main-content flex_column">
        <div className="other-details flex_row_jbetween">
          <div className="left-column flex_col">
            <span className="other-details">
              <p> APPLICANT NAME </p>
              <p> {scholarshipApplicationDetails.name ? scholarshipApplicationDetails.name : '-'} </p>
            </span>

            <span>
              <p> GRADUATION YEAR </p>
              <p> {scholarshipApplicationDetails.graduationYear ? scholarshipApplicationDetails.graduationYear : '-'} </p>
            </span>
          </div>

          <div className="right-column flex_col">
            <span>
              <p> APPLICANT SCHOOL </p>
              <p> {scholarshipApplicationDetails.school ? scholarshipApplicationDetails.school : '-'} </p>
            </span>

            <span>
              <p> DATE APPLIED </p>
              <p> {scholarshipApplicationDetails.dateApplied ? scholarshipApplicationDetails.dateApplied : '-'} </p>
            </span>
          </div>
        </div>
        <div className="main-details flex_row_jbetween">
          <div className="left-column flex_col">
            <span>
              <p> FIELD OF INTEREST </p>
              <p> {scholarshipApplicationDetails.fieldofInterest ? scholarshipApplicationDetails.fieldofInterest : '-'} </p>
            </span>

            <span>
              <p> ESSAY </p>
              <p> {scholarshipApplicationDetails.essay ? scholarshipApplicationDetails.essay : '-'} </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScholarshipApplicationDetails;
