import React from 'react';
import './BulkCreateStepper.scss';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';




export interface BulkCreateStepperProps {
  currentStep: number;
}


const BulkCreateStepper: React.FC<BulkCreateStepperProps> = ({ currentStep }) => {

  const stepArray = [
    'Select School',
    'Upload CSV File',
    'Review Existing Users',
    'Review New Users',
    'Retrieve code',
  ];

  const createAllSteps = () => {
    return stepArray.map((ele: string, i: number) => (
      <React.Fragment key={i}>
        <div className={`stepper-step ${currentStep === i + 1 && 'current-step'} flex_row_jstart_acenter`}
        >
          <span className="flex_jcenter_acenter">{i + 1}</span>
          <p>{ele}</p>
        </div>
        {i !== 4 && <ChevronRightIcon />}
      </React.Fragment>
    ));
  };

  return (
    <div className="bulk-create-stepper flex_row_jstart_acenter">

      {(currentStep === 0)
        ? <p> Watch the video below for a step-by-step tutorial on how to bulk upload users. </p>
        : createAllSteps()
      }
    </div>
  );
};

export default BulkCreateStepper;