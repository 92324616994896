import React, {
  useEffect, useRef, useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppAssetPaths,
  AppRoutes,
  canUseAuthComponent
} from '../../app/app.types';
import { TableState } from '../../constants/tables/table.types';
import {
  communityAdminListColumns,
  deletedUserListColumns,
  districtAdminListColumns,
  institutionAdminListColumns,
  schoolAdminListColumns,
  studentListColumns
} from '../../constants/tables/userTable.types';
import './UserList.scss';
import {
  DataGrid,
  GridColumnHeaderParams,
  GridSelectionModel,
  GridSortModel
} from '@mui/x-data-grid';
import {
  Box,
  Button,
  ListItemText,
  Menu,
  MenuItem,
  Pagination,
  Popper,
  Tab,
  Tabs
} from '@mui/material';
import {
  useAppDispatch, useAppSelector
} from '../../app/hooks';
import { userSelectors } from '../../features/user/user.slice';
import {
  deletedUser,
  getUserSchoolType,
  User,
  UserListQueryParams,
  UserRole
} from '../../features/user/user.model';
import { getUserList } from '../../features/user/user.thunks';
import SearchInput from '../../components/table/SearchInput';
import {
  FormProvider, SubmitHandler, useForm
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { DownloadButton } from '../../components/CustomStyledComponents';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableRowCount from '../../components/table/TableRowCount';
import UserFilter from './UserFilter';
import {
  getSelectAllUsersTooltipSeen, setSelectAllUsersTooltipSeen
} from '../../util/localStorage';
import BulkAddPointsModal from '../../components/modal/BulkAddPoints.modal';
import {
  bottomCenterAnchorOrigin, defaultMenuPaperProps, topCenterTransformOrigin
} from '../../constants/menu.types';
import { startCase } from 'lodash';
import {
  exportPointRankingsCSV, exportUnactivatedUsersCSV, exportUserListCSV
} from '../../features/exports/exports.api';


interface UserListProps {
  initialTab: string;
}

const UserList: React.FC<UserListProps> = ({ initialTab }) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(userSelectors.selectUser);
  const userList = useAppSelector(userSelectors.selectUserList);
  const totalUserListCount = useAppSelector(userSelectors.selectTotalUserListCount);
  const userListLoaded = useAppSelector(userSelectors.selectUserListLoaded);

  const [
    selectedTab,
    setSelectedTab,
  ] = useState(initialTab);
  const [
    tableState,
    setTableState,
  ] = useState<TableState>({
    page: 1,
    pageSize: 100,
    columns: studentListColumns,
    rows: [],
    loading: false,
  });
  const [
    userListQuery,
    setUserListQuery,
  ] = useState<UserListQueryParams>({
    offset: 0,
    limit: undefined,
    role: initialTab,
    search: undefined,
    schoolType: getUserSchoolType(user.roles),
    schoolDistricts: undefined,
    graduationYears: undefined,
    sortModel: undefined,
  });
  const [
    filterAnchorElement,
    setFilterAnchorElement,
  ] = useState<HTMLElement | null>(null);
  const [
    downloadMenuAnchorElement,
    setDownloadMenuAnchorElement,
  ] = useState<HTMLElement | null>(null);
  const [
    selectAllTooltipAnchorElement,
    setSelectAllTooltipAnchorElement,
  ] = useState<HTMLElement | null>(null);
  const [
    newUserMenuAnchorElement,
    setNewUserMenuAnchorElement,
  ] = useState<HTMLElement | null>(null);
  const [
    addPointsModalOpen,
    setAddPointsModalOpen,
  ] = useState(false);

  // State for row selection in conjunction with server side pagination
  const [
    selectionModel,
    setSelectionModel,
  ] = useState<GridSelectionModel>([]);
  const prevSelectionModel = useRef<GridSelectionModel>(selectionModel);
  const [
    selectedUsers,
    setSelectedUsers,
  ] = useState<User[]>([]);
  const prevSelectedUsers = useRef<User[]>(selectedUsers);


  const changeColumns = () => {
    if (selectedTab === UserRole.keyContact || selectedTab === UserRole.counselor) {
      return schoolAdminListColumns;
    }
    else if (selectedTab === UserRole.districtAdmin) {
      return districtAdminListColumns;
    }
    else if (selectedTab === UserRole.institutionAdmin) {
      return institutionAdminListColumns;
    }
    else if (selectedTab === UserRole.community) {
      return communityAdminListColumns;
    }
    else if (selectedTab === deletedUser) {
      return deletedUserListColumns;
    }

    return studentListColumns;
  };

  useEffect(() => {
    setSelectedTab(initialTab);
  }, [
    initialTab,
  ]);

  useEffect(() => {
    if (!userListLoaded) return;

    setUserListQuery((prev) => ({
      ...prev,
      role: selectedTab,
    }));
  }, [
    selectedTab,
  ]);

  // Switching user lists
  useEffect(() => {
    setTableState((prev) => ({
      ...prev,
      page: 1,
      columns: changeColumns(),
      rows: [],
      loading: true,
    }));

    if (selectedTab === UserRole.student) {
      // Save selection model before list changes
      prevSelectionModel.current = selectionModel;
      prevSelectedUsers.current = selectedUsers;
    }

    dispatch(getUserList({
      ...userListQuery,
      offset: 0,
    }));
  }, [
    userListQuery,
  ]);

  // Loading more user data for pagination
  useEffect(() => {
    if (tableState.page < userList.length / tableState.pageSize || !userList.length || userList.length >= totalUserListCount) return;

    setTableState((prev) => ({
      ...prev,
      loading: true,
    }));

    dispatch(getUserList({
      ...userListQuery,
      offset: tableState.pageSize * (tableState.page - 1),
    }));
  }, [
    tableState.page,
    tableState.pageSize,
  ]);

  useEffect(() => {
    setTableState((prev) => ({
      ...prev,
      loading: false,
      rows: userList,
    }));

  }, [
    userList,
  ]);

  useEffect(() => {
    setSelectionModel(prevSelectionModel.current);
    setSelectedUsers(prevSelectedUsers.current);
  }, [
    tableState.rows,
  ]);

  const formValidationSchema = yup.object().shape({
    search: yup.string(),
  });

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const { handleSubmit } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    setUserListQuery((prev) => ({
      ...prev,
      search: values.search,
    }));
  };

  const removeDuplicates = (arrayWithDupes: any[]) => {
    const seenValues = new Set();
    const uniqueItems = arrayWithDupes.filter((ele) => {
      if (!seenValues.has(ele.id)) {
        seenValues.add(ele.id);
        return true;
      }
      return false;
    });
    return uniqueItems;
  };

  return (
    <div id="user-list">
      <Box className="page-content card-background">
        <Tabs
          className="main-page-tabs"
          variant="fullWidth"
          value={selectedTab}
          onChange={(_, tabName) => setSelectedTab(tabName)}
        >
          {canUseAuthComponent(user.roles, AppRoutes.studentUsers.authorizedRoles) && (
            <Tab label="Students" value={UserRole.student} onClick={() => navigate(AppRoutes.studentUsers.path)} />
          )}
          {canUseAuthComponent(user.roles, AppRoutes.keyContactUsers.authorizedRoles) && (
            <Tab label="Key Contacts" value={UserRole.keyContact} onClick={() => navigate(AppRoutes.keyContactUsers.path)} />
          )}
          {canUseAuthComponent(user.roles, AppRoutes.counselorUsers.authorizedRoles) && (
            <Tab label="Counselors" value={UserRole.counselor} onClick={() => navigate(AppRoutes.counselorUsers.path)} />
          )}
          {canUseAuthComponent(user.roles, AppRoutes.districtAdminUsers.authorizedRoles) && (
            <Tab label="District Admins" value={UserRole.districtAdmin} onClick={() => navigate(AppRoutes.districtAdminUsers.path)} />
          )}
          {canUseAuthComponent(user.roles, AppRoutes.communityUsers.authorizedRoles) && (
            <Tab label="Community" value={UserRole.community} onClick={() => navigate(AppRoutes.communityUsers.path)} />
          )}
          {canUseAuthComponent(user.roles, AppRoutes.institutionAdminUsers.authorizedRoles) && (
            <Tab label="Institution" value={UserRole.institutionAdmin} onClick={() => navigate(AppRoutes.institutionAdminUsers.path)} />
          )}
          {canUseAuthComponent(user.roles, AppRoutes.deletedUsers.authorizedRoles) && (
            <Tab label="Deleted Users" value={deletedUser} onClick={() => navigate(AppRoutes.deletedUsers.path)} />
          )}
        </Tabs>

        <div className="table-buttons-and-filters flex_jbetween">
          <div className="filters flex_acenter">
            <form className="content-container" onSubmit={handleSubmit(submitForm)}>
              <FormProvider {...methods}>
                <SearchInput
                  className="main-input search"
                  name="search"
                  onClear={handleSubmit(submitForm)}
                />
              </FormProvider>
            </form>
            {(!user.isInstitutionAdmin && !user.isUniversityKeyContact && !user.isUniversityCounselor && selectedTab !== deletedUser) && (
              <div className="flex_acenter filter-button" onClick={(e) => setFilterAnchorElement(e.currentTarget)}>
                <img src={AppAssetPaths.icons.FILTER} />
                <p>Filter</p>
              </div>
            )}
          </div>

          {selectedTab !== deletedUser && (
            <div className="flex_acenter">
              <DownloadButton
                variant="contained"
                startIcon={<img src={AppAssetPaths.icons.DOWNLOAD} />}
                endIcon={<ExpandMoreIcon />}
                onClick={(event) => setDownloadMenuAnchorElement(event.currentTarget)}
                disableElevation
              >
                Download
              </DownloadButton>


              {(selectedTab === UserRole.student) ? (
                (user.isInstitutionAdmin || user.isAdultAdmin || user.isDistrictInstitutionAdmin || user.isUniversityCounselor || user.isUniversityKeyContact) ? (
                  <Button
                    variant="contained"
                    onClick={() => navigate(AppRoutes.newUser.path.replace(':userType', selectedTab))}
                    disableElevation
                  >
                    New User
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    endIcon={<ExpandMoreIcon />}
                    onClick={(event) => setNewUserMenuAnchorElement(event.currentTarget)}
                    disableElevation
                  >
                    New User
                  </Button>
                )

              ) : (
                <Button
                  variant="contained"
                  onClick={() => navigate(AppRoutes.newUser.path.replace(':userType', selectedTab))}
                  disableElevation
                >
                  New User
                </Button>
              )}
            </div>
          )}
        </div>

        {
          (selectedTab === UserRole.student && !user.isInstitutionAdmin && !user.isAdultAdmin) && (
            <div className="select-all-container flex_acenter">
              <p>{selectionModel.length} Selected</p>
              <Button
                variant="contained"
                disabled={!selectionModel.length}
                onClick={() => setAddPointsModalOpen(true)}
                disableElevation
              >
                Add Points
              </Button>
            </div>
          )
        }

        <DataGrid
          className="table user-list"
          getRowClassName={(params) => `${getUserSchoolType(params.row.roles)?.replace(/_/g, '-')}-row`}
          {...tableState}
          components={{
            Pagination: Pagination,
          }}
          componentsProps={{
            pagination: {
              page: tableState.page,
              count: Math.ceil(totalUserListCount / tableState.pageSize),
              onChange: (_: void, page: number) => {
                prevSelectionModel.current = selectionModel;
                prevSelectedUsers.current = selectedUsers;
                setTableState((prev) => ({
                  ...prev,
                  page,
                }));
              },
            },
          }}
          onColumnHeaderClick={(params: GridColumnHeaderParams, event) => {
            if (params.field === '__check__' && !getSelectAllUsersTooltipSeen()) {
              setSelectAllTooltipAnchorElement(event.currentTarget);
            }
          }}
          onRowClick={(event) => navigate(AppRoutes.userDetails.path.replace(':userId', event.row.id.toString()))}
          checkboxSelection={selectedTab === UserRole.student && !user.isInstitutionAdmin && !user.isAdultAdmin}
          pagination
          paginationMode="server"
          onSelectionModelChange={(newSelectionModel: GridSelectionModel) => {
            setSelectionModel(newSelectionModel);
            setSelectedUsers([
              ...prevSelectedUsers.current,
              ...userList.filter(u => newSelectionModel.includes(u.id ? u.id : 0)),
            ]);
          }}
          selectionModel={selectionModel}
          sortingMode="server"
          sortModel={userListQuery.sortModel}
          onSortModelChange={(newSortModel: GridSortModel) => {
            setUserListQuery((prev) => ({
              ...prev,
              sortModel: newSortModel,
            }));
          }}
          disableColumnFilter
          disableColumnMenu
          hideFooterSelectedRowCount
        />

        <TableRowCount
          length={userList.length}
          pageSize={tableState.pageSize}
          page={tableState.page}
          totalCount={totalUserListCount}
        />
      </Box >

      {/* Filter Menu */}
      < UserFilter
        anchorElement={filterAnchorElement}
        setAnchorElement={setFilterAnchorElement}
        setFiltersCallback={(schoolType?: string, schoolDistricts?: number[], graduationYears?: number[]) => {
          setUserListQuery((prev) => ({
            ...prev,
            schoolType,
            schoolDistricts,
            graduationYears,
          }));
        }}
      />

      {/* Download Menu */}
      <Menu
        anchorEl={downloadMenuAnchorElement}
        open={Boolean(downloadMenuAnchorElement)}
        onClose={() => setDownloadMenuAnchorElement(null)}
        autoFocus={false}
        PaperProps={defaultMenuPaperProps}
        transformOrigin={topCenterTransformOrigin}
        anchorOrigin={bottomCenterAnchorOrigin}
      >
        <MenuItem
          onClick={() => {
            exportUserListCSV(selectedTab, userListQuery.schoolType, userListQuery.graduationYears),
            setDownloadMenuAnchorElement(null);
          }}
        >
          <ListItemText primary={`All ${startCase(selectedTab)} Users`} />
        </MenuItem>
        {/* not allow AdultAdmin and InstitutionAdmin to download Point Rankings */}
        {selectedTab === UserRole.student && !user.isAdultAdmin && !user.isInstitutionAdmin && (
          <MenuItem
            className="dropdown-button"
            onClick={() => {
              exportPointRankingsCSV();
              setDownloadMenuAnchorElement(null);
            }}
          >
            <ListItemText primary="Point Rankings" />
          </MenuItem>
        )}
        {selectedTab === UserRole.student && !user.isAdultAdmin && !user.isInstitutionAdmin && !user.isCommunity && (
          <MenuItem
            className="dropdown-button"
            onClick={() => {
              exportUnactivatedUsersCSV();
              setDownloadMenuAnchorElement(null);
            }}
          >
            <ListItemText primary="Unactivated Users" />
          </MenuItem>
        )}
      </Menu>

      {/* New User Menu */}
      <Menu
        anchorEl={newUserMenuAnchorElement}
        open={Boolean(newUserMenuAnchorElement)}
        onClose={() => setNewUserMenuAnchorElement(null)}
        autoFocus={false}
        PaperProps={defaultMenuPaperProps}
        transformOrigin={topCenterTransformOrigin}
        anchorOrigin={bottomCenterAnchorOrigin}
      >
        <MenuItem
          onClick={() => navigate(AppRoutes.newUser.path.replace(':userType', selectedTab))}
        >
          <ListItemText primary="Add Individual User" />
        </MenuItem>

        <MenuItem
          onClick={() => navigate(AppRoutes.bulkUserCreate.path)}
        >
          <ListItemText primary="Bulk Upload Users" />
        </MenuItem>
      </Menu>

      {/* Select All Modals */}
      <Popper
        id="select-all-tooltip"
        className="interactive-tooltip"
        anchorEl={selectAllTooltipAnchorElement}
        open={Boolean(selectAllTooltipAnchorElement)}
        placement="right-start"
      >
        <span className="arrow" />
        <div className="tooltip-content">
          <h4>Select All</h4>
          <p>This checkbox selects all users on this page only. To select more than 100 users, go to the next page(s) available.</p>
          <div className="flex_jend">
            <Button
              variant="outlined"
              onClick={() => {
                setSelectAllUsersTooltipSeen();
                setSelectAllTooltipAnchorElement(null);
              }}>
              Got it
            </Button>
          </div>
        </div>
      </Popper>

      <BulkAddPointsModal
        parentSelectionModel={selectionModel}
        setSelectionModelCallback={setSelectionModel}
        selectedUsers={removeDuplicates(selectedUsers)}
        setSelectedUsersCallback={setSelectedUsers}
        open={addPointsModalOpen}
        onClose={() => setAddPointsModalOpen(false)}
      />
    </div >
  );
};

export default UserList;
