/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { GridSortModel } from '@mui/x-data-grid';
import { ListMenuItem } from '../../components/forms/form.types';

export interface Career {
  id: number;
  title: string;
  groups: CareerGroup[];
}

export interface CareerClusterListQuery {
  sortModel?: GridSortModel;
}

export const ConstructCareer = (data: any): Career => {
  return {
    id: data.id,
    title: data.title,
    groups: data.career_groups && data.career_groups.map((g: any) => ConstructCareerGroup(g)),
  };
};

export interface CareerGroup extends ListMenuItem { }

export const ConstructCareerGroup = (data: any): CareerGroup => {

  return {
    id: data.id,
    label: data.title || '',
  };
};


// ------------------------------ CAREER CLUSTER ------------------------------ \\

export interface CareerCluster {
  id: number,
  pathwayClusterId: number;
  title: string;
  skills: CareerClusterSkill[];
}


export interface CareerClusterSkill {
  id: number;
  haveType: string;
}


export const ConstructCareerCluster = (data: any): CareerCluster => {
  return {
    id: data.id,
    pathwayClusterId: data.pathway_cluster_id,
    title: data.title,
    skills: data.skills.map((s: any) => ConstructCareerClusterSkill(s)),
  };
};


export const ConstructCareerClusterSkill = (data: any): CareerClusterSkill => {
  return {
    id: data.skill_id,
    haveType: (data.is_must_have) ? 'mustHave' : 'niceToHave',
  };
};