/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import isBetween from 'dayjs/plugin/isBetween';
import dayjs from 'dayjs';
dayjs.extend(isBetween);
import {
  HTTP_METHOD, requestFactory
} from '../../app/app.api';
import {
  ConstructResource,
  ConstructResourceApplication,
  Resource, ResourceApplication, ResourceListQueryParams, ResourceType
} from './resources.model';
import { OrganizationType } from '../organization/organization.model';
import { startCase } from 'lodash';


export const getResourceList = async (query: ResourceListQueryParams): Promise<{ resources: Resource[], count: number }> => {
  const resourceType = query.resourceType ? `${query.resourceType}` : 'scholarships';
  const limitValue = query.limit || 100;
  const searchParam = query.search ? `&search=${query.search}` : '';
  const queryTypeParam = query.queryType ? `&type=${query.queryType}` : '&type=current';
  const schoolDistrictsParam = query.schoolDistricts ? `&school_districts=${query.schoolDistricts}` : '';
  let orderBy = query.sortModel?.length ? `&order_by=${query.sortModel[0].field}` : '';
  const orderDirecton = query.sortModel?.length ? `&order_direction=${query.sortModel[0].sort}` : '';

  orderBy = orderBy.replace('organizationName', 'organization');
  orderBy = orderBy.replace('startDate', 'start_date');
  orderBy = orderBy.replace('endDate', 'end_date');
  orderBy = orderBy.replace('updatedAt', 'updated_at');

  const url = `/${resourceType}/admin?offset=${query.offset}&limit=${limitValue}${searchParam}${queryTypeParam}${schoolDistrictsParam}${orderBy}${orderDirecton}`;

  const { data } = await requestFactory(HTTP_METHOD.GET, url);

  const resources = data.data.map((r: any) => ConstructResource(r));

  return {
    resources,
    count: data.count,
  };
};

export const createResource = async (values: any, resourceType: string): Promise<void> => {
  const body = {
    title: values.title,
    description: values.description,
    details: values.description,
    organization_id: values.organization.id,
    url: values.link,
    link: values.link,
    career_group_ids: values.careers.map((e: any) => e.id) || [],
    dollar_amount: (values.fundingCheckboxes.static) ? values.dollarAmount : null,
    dollar_amount_range: (values.fundingCheckboxes.range === true) ?
      [
        values.dollarAmountRange.rangeBegin,
        values.dollarAmountRange.rangeEnd,
      ] :
      null,
    in_app: values.inApp,
    number_available: (values.availableCheckboxes.static) ? values.numberAvailable : null,
    number_available_range: (values.availableCheckboxes.range) ?
      [
        values.numberAvailableRange.rangeBegin,
        values.numberAvailableRange.rangeEnd,
      ] :
      null,
    school_ids: values.institutions.map((e: any) => e.id) || [],
    graduation_years: values.grade.map((e: any) => e.id).sort() || [],
    start_date: values.startDate || null,
    end_date: values.endDate || null,
    type: (values.type === ResourceType.internship || values.type === ResourceType.other) ? startCase(values.type) : values.type,
    user_types: values.userTypeIds || [],
    user_type_ids: values.userTypeIds || [],
    zipcode: values.zipcode?.zipcode || '',
    tags: values.tags,
  };

  if (resourceType == ResourceType.scholarship) {
    await requestFactory(HTTP_METHOD.POST, '/scholarships', undefined, body);
  }
  else {
    await requestFactory(HTTP_METHOD.POST, '/opportunities', undefined, body);
  }
};

export const updateResource = async (id: number, resourceType: string, values: any): Promise<Resource> => {
  const body = {
    title: values.title,
    description: values.description,
    details: values.description,
    organization_id: values.organization.id,
    url: values.link,
    link: values.link,
    career_group_ids: values.careers.map((e: any) => e.id) || [],
    dollar_amount: (values.fundingCheckboxes.static) ? values.dollarAmount : null,
    dollar_amount_range: (values.fundingCheckboxes.range === true) ?
      [
        values.dollarAmountRange.rangeBegin,
        values.dollarAmountRange.rangeEnd,
      ] :
      null,
    in_app: values.inApp,
    number_available: (values.availableCheckboxes.static) ? values.numberAvailable : null,
    number_available_range: (values.availableCheckboxes.range) ?
      [
        values.numberAvailableRange.rangeBegin,
        values.numberAvailableRange.rangeEnd,
      ] :
      null,
    school_ids: values.institutions.map((e: any) => e.id) || [],
    graduation_years: (values.userTypeIds?.length === 1 && values.userTypeIds[0].type === OrganizationType.Adult) ? [] : values.grade.map((e: any) => e.id).sort(),
    start_date: values.startDate || null,
    end_date: values.endDate || null,
    type: (values.type === ResourceType.internship || values.type === ResourceType.other) ? startCase(values.type) : values.type,
    user_types: values.userTypeIds || [],
    user_type_ids: values.userTypeIds || [],
    zipcode: values.zipcode?.zipcode || '',
    tags: values.tags || [],
  };

  let data;
  if (resourceType == ResourceType.scholarship) {
    data = await requestFactory(HTTP_METHOD.PATCH, `/scholarships/${id}`, undefined, body);
    data = data.data;
  }
  else {
    data = await requestFactory(HTTP_METHOD.PATCH, `/opportunity/${id}`, undefined, body);
  }

  const resource = ConstructResource(data.data);

  return resource;
};

export const getResourceById = async (id: number, resourceType: string): Promise<Resource> => {
  let data;
  if (resourceType == ResourceType.scholarship) {
    data = await requestFactory(HTTP_METHOD.GET, `/scholarships/${id}`);
  }
  else {
    data = await requestFactory(HTTP_METHOD.GET, `/opportunity/${id}`);
  }

  const resource = ConstructResource(data.data.data);

  return resource;
};

export const deleteResource = async (id: number, resourceType: string): Promise<void> => {

  if (resourceType == ResourceType.scholarship) {
    await requestFactory(HTTP_METHOD.DELETE, `/scholarships/${id}`);
  }
  else {
    await requestFactory(HTTP_METHOD.DELETE, `/opportunity/${id}`);
  }
};

export const getResourceApplications = async (id: number, resourceType: string): Promise<ResourceApplication[]> => {

  if (resourceType == ResourceType.scholarship) {
    const url = `/scholarships/${id}/applications`;
    const { data } = await requestFactory(HTTP_METHOD.GET, url);
    return data.data.map((r: any) => ConstructResourceApplication(r));
  }
  else {
    const url = `/opportunities/${id}/applications`;
    const { data } = await requestFactory(HTTP_METHOD.GET, url);
    return data.data.map((r: any) => ConstructResourceApplication(r));
  }
};

export const approveResource = async (id: number, resourceType: string): Promise<boolean> => {
  let response: any = null;

  if (resourceType == ResourceType.scholarship) {
    response = await requestFactory(HTTP_METHOD.PATCH, `/scholarships/${id}/approve`);
  }
  else {
    response = await requestFactory(HTTP_METHOD.PATCH, `/opportunities/${id}/approve`);
  }

  const data = await response.data;
  return data.approved;
};

export const rejectResource = async (id: number, resourceType: string): Promise<void> => {
  if (resourceType == ResourceType.scholarship) {
    await requestFactory(HTTP_METHOD.DELETE, `/scholarships/${id}/reject`);
  }
  else {
    await requestFactory(HTTP_METHOD.DELETE, `/opportunities/${id}/reject`);
  }
};
