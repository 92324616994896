import {
  Box,
  Tab, Tabs
} from '@mui/material';

import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import React, {
  useEffect, useState
} from 'react';
import {
  useAppDispatch, useAppSelector
} from '../../app/hooks';
import './CurrentMasterKeyCard.scss';

import { CurrentMasterKeysByType } from '../../features/dashboard/dashboard.model';
import { dashboardSelectors } from '../../features/dashboard/dashboard.slice';
import { getCurrentMasterKeys } from '../../features/dashboard/dashboard.thunk';
import {
  CurrentMasterKeyTab, CurrentMasterKeyTabs
} from '../../features/dashboard/types/currentMasterKey.types';
import {
  AppAssetPaths, AppRoutes, canUseAuthComponent
} from '../../app/app.types';
import { userSelectors } from '../../features/user/user.slice';
import { useNavigate } from 'react-router-dom';


interface CurrentMasterKeyCardProps { }

const CurrentMasterKeyCard: React.FC<CurrentMasterKeyCardProps> = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(userSelectors.selectUser);
  const currentMasterKeys = useAppSelector(dashboardSelectors.selectCurrentMasterKeys);

  const [
    selectedTab,
    setSelectedTab,
  ] = useState(0);

  useEffect(() => {
    dispatch(getCurrentMasterKeys());
  }, []);

  return (
    <div id="master-key-card" className="flex_col_jbetween card-background">
      <div className="title-bar flex_row_jbetween_aend">
        <h3 className="card-header">Current Master Keys</h3>
        <AddCircleOutlinedIcon
          className="create-masterkey-button"
          onClick={() => navigate(AppRoutes.createMasterKey.path)}
        />
      </div>
      <Tabs value={selectedTab}
        onChange={(_, newValue) => setSelectedTab(newValue)}
        variant="fullWidth"
      >
        {CurrentMasterKeyTabs.filter(m => canUseAuthComponent(user.roles, m.authorizedRoles)).map((tab: CurrentMasterKeyTab, i: number) => (
          <Tab id="dashboard-tab"
            label={tab.title}
            wrapped={false}
            key={i}
          />
        ))}
      </Tabs>

      {CurrentMasterKeyTabs.filter(m => canUseAuthComponent(user.roles, m.authorizedRoles)).map((tab: CurrentMasterKeyTab, i: number) => (
        <div role="tabpanel" hidden={selectedTab !== i} key={i}>
          <Box className="tab-content flex_column">
            {(currentMasterKeys[tab.dataKey as keyof CurrentMasterKeysByType]) ? (
              <div
                className="subcard flex_row_jbetween_acenter"
                onClick={() => navigate(AppRoutes.masterKeyDetails.path.replace(':masterKeyId', currentMasterKeys[tab.dataKey as keyof CurrentMasterKeysByType]!.id.toString()))}
              >
                <div className="subcard-month flex_column_jcenter_acenter">
                  <img src={AppAssetPaths.icons.MASTERKEY_APPS_RECT} alt="Master Key Icon" />
                </div>
                <div className="subcard-title flex_column_jcenter_astart">
                  {currentMasterKeys[tab.dataKey as keyof CurrentMasterKeysByType]?.title}
                </div>
                <div className="subcard-submissions flex_column_jcenter_acenter">
                  {currentMasterKeys[tab.dataKey as keyof CurrentMasterKeysByType]?.submissionCount}
                </div>
              </div>
            ) : (
              <div className="no-data flex_jcenter_acenter">
                <p>No current master key</p>
              </div>
            )}
            <div className="all-master-keys-link flex_acenter"
              onClick={() => navigate(AppRoutes.masterKey.path)}>
              <p>All Master Keys</p>
              <ChevronRightIcon className="chevron-icon" />
            </div>
          </Box>
        </div>
      ))}
    </div>
  );
};

export default CurrentMasterKeyCard;
