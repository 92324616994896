import {
  Box,
  Tab, Tabs
} from '@mui/material';
import React, {
  useEffect, useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppAssetPaths, AppRoutes, canUseAuthComponent
} from '../../app/app.types';
import {
  useAppDispatch, useAppSelector
} from '../../app/hooks';
import {
  ScholarshipApplicationsByType, SchoolScholarshipApplicationInfo
} from '../../features/dashboard/dashboard.model';
import { dashboardSelectors } from '../../features/dashboard/dashboard.slice';
import { getScholarshipsBySchool } from '../../features/dashboard/dashboard.thunk';

import {
  ScholarshipApplicationsTab, ScholarshipApplicationTabs
} from '../../features/dashboard/types/scholarshipApplications.types';
import { userSelectors } from '../../features/user/user.slice';

import './ScholarshipApplicationsCard.scss';


interface ScholarshipApplicationsCardProps { }

const ScholarshipApplicationsCard: React.FC<ScholarshipApplicationsCardProps> = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(userSelectors.selectUser);
  const scholarshipApplicationsBySchool = useAppSelector(dashboardSelectors.selectScholarshipApplicationsBySchool);

  const [
    selectedTab,
    setSelectedTab,
  ] = useState(0);

  useEffect(() => {
    dispatch(getScholarshipsBySchool());
  }, []);

  return (
    <div id="scholarship-applications-card" className="card-background">
      <h3 className="card-header">Scholarship Applications</h3>
      <Tabs value={selectedTab}
        onChange={(_, newValue) => setSelectedTab(newValue)}
        variant="fullWidth"
      >
        {ScholarshipApplicationTabs.filter(a => canUseAuthComponent(user.roles, a.authorizedRoles)).map((tab: ScholarshipApplicationsTab, i: number) => (
          <Tab id="dashboard-tab"
            label={tab.title}
            wrapped={false}
            key={i}
          />
        ))}
      </Tabs>

      {ScholarshipApplicationTabs.filter(s => canUseAuthComponent(user.roles, s.authorizedRoles)).map((tab: ScholarshipApplicationsTab, i: number) => (
        <div role="tabpanel" hidden={selectedTab !== i} key={i}>
          <Box className="card-content">
            <div className="column-headers flex_jbetween">
              <span className="school-header flex">
                <p>School</p>
                <img src={AppAssetPaths.icons.A_TO_Z_RED} />
              </span>
              <p className="width-30">Need Review</p>
              <p className="width-30">New This Week</p>
            </div>
            <div className="scroll-container">
              {scholarshipApplicationsBySchool[tab.dataKey as keyof ScholarshipApplicationsByType].map((s: SchoolScholarshipApplicationInfo, j: number) => (
                <div key={j}
                  className={'column-data flex_jbetween_acenter'}
                  onClick={() => navigate(AppRoutes.applicationApprovals.path)}
                >
                  <p className="school">{s.organizationName}</p>
                  <p className="need-review">{s.needReviewCount}</p>
                  <p className="new">{s.newCount}</p>
                </div>
              ))}
            </div>
          </Box>
        </div>
      ))}
    </div>
  );
};

export default ScholarshipApplicationsCard;
