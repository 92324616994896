import React, {
  useState,
  useEffect
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FormProvider,
  SubmitHandler,
  useForm
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  Box,
  Button,
  Pagination,
  Tab,
  Tabs
} from '@mui/material';
import {
  DataGrid,
  GridRowParams,
  GridSortModel
} from '@mui/x-data-grid';
import { startCase } from 'lodash';

import {
  AppAssetPaths,
  AppRoutes,
  canUseAuthComponent
} from '../../app/app.types';
import {
  useAppDispatch,
  useAppSelector
} from '../../app/hooks';
import { TableState } from '../../constants/tables/table.types';
import {
  AlertType, notificationListColumns, announcementListColumns, masterKeyListColumns
} from '../../constants/tables/alertTable.types';
import { userSelectors } from '../../features/user/user.slice';
import { UserRole } from '../../features/user/user.model';
import { NotificationListQueryParams } from '../../features/notifications/notifications.model';
import { notificationSelectors } from '../../features/notifications/notifications.slice';
import { getNotificationList } from '../../features/notifications/notifications.thunks';
import {
  Announcement, AnnouncementListQueryParams
} from '../../features/announcement/announcement.model';
import { announcementSelectors } from '../../features/announcement/announcement.slice';
import { getAnnouncementList } from '../../features/announcement/announcement.thunks';

import {
  MasterKey, MasterKeyListQueryParams
} from '../../features/masterKey/masterKey.model';
import {
  masterKeySelectors, setShowMasterKeyDeleteConfirmDialog 
} from '../../features/masterKey/masterKey.slice';
import {
  getMasterKeyList, deleteMasterKeyById
} from '../../features/masterKey/masterKey.thunks';
import { setAlert } from '../../features/alert/alert.slice';

import TableRowCount from '../../components/table/TableRowCount';
import SearchInput from '../../components/table/SearchInput';
import NotificationFilter from './notifications/NotificationFilter';
import DeleteConfirmationModal from '../../components/modal/DeleteConfirmationModal';

import './AlertList.scss';


interface AlertListProps {
  initialTab: string;
}

const AlertList: React.FC<AlertListProps> = ({ initialTab }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(userSelectors.selectUser);
  const notificationList = useAppSelector(notificationSelectors.selectNotificationList);
  const totalNotificationListCount = useAppSelector(notificationSelectors.selectTotalNotificationListCount);
  const announcementList = useAppSelector(announcementSelectors.selectAnnouncementList);
  const totalAnnouncementListCount = useAppSelector(announcementSelectors.selectTotalAnnouncementListCount);
  const masterKeyList = useAppSelector(masterKeySelectors.selectMasterKeyList);
  const totalMasterKeyListCount = useAppSelector(masterKeySelectors.selectTotalMasterKeyListCount);
  const showMasterKeyDeleteConfirmDialog = useAppSelector(masterKeySelectors.selectShowMasterKeyDeleteConfirmDialog);
  const masterKeyIdToDelete = useAppSelector(masterKeySelectors.selectMasterKeyIdToDelete);

  const changeColumns = () => {
    if (selectedTab === AlertType.Notification) {
      return notificationListColumns;
    } else if (selectedTab === AlertType.Announcement) {
      return announcementListColumns;
    } else if (selectedTab === AlertType.MasterKey) {
      return masterKeyListColumns;
    }

    return notificationListColumns;
  };

  const [
    selectedTab,
    setSelectedTab,
  ] = useState(initialTab);
  const [
    totalCount,
    setTotalCount,
  ] = useState(0);
  const [
    currentListCount,
    setCurrentListCount,
  ] = useState(0);

  const [
    tableState,
    setTableState,
  ] = useState<TableState>({
    page: 1,
    pageSize: 100,
    columns: changeColumns(),
    rows: [],
    loading: false,
  });

  const [
    notificationListQuery,
    setNotificationListQuery,
  ] = useState<NotificationListQueryParams>({
    offset: 0,
    limit: undefined,
    search: undefined,
    sortModel: undefined,
    schoolDistrictId: user.isDistrictAdmin ? user.organizationId : undefined,
    schoolId: (user.isAdmin || user.isDistrictAdmin) ? undefined : user.organizationId,
    inApp: undefined,
    push: undefined,
  });

  const [
    announcementListQuery,
    setAnnouncementListQuery,
  ] = useState<AnnouncementListQueryParams>({
    offset: 0,
    limit: undefined,
    search: undefined,
    sortModel: undefined,
  });

  const [
    masterKeyListQuery,
    setMasterKeyListQuery,
  ] = useState<MasterKeyListQueryParams>({
    offset: 0,
    limit: undefined,
    search: undefined,
    sortModel: undefined,
  });

  const [
    filterAnchorElement,
    setFilterAnchorElement,
  ] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setSelectedTab(initialTab);
  }, [
    initialTab,
  ]);

  // Load a different list
  useEffect(() => {
    setTableState((prev) => ({
      ...prev,
      page: 1,
      columns: changeColumns(),
      rows: [],
      loading: true,
    }));

    if (selectedTab === AlertType.Notification) {
      dispatch(getNotificationList({
        ...notificationListQuery,
        offset: 0,
      }));
    } else if (selectedTab === AlertType.Announcement) {
      dispatch(getAnnouncementList({
        ...announcementListQuery,
        offset: 0,
      }));
    } else if (selectedTab === AlertType.MasterKey) {
      dispatch(getMasterKeyList({
        ...masterKeyListQuery,
        offset: 0,
      }));
    }
  }, [
    selectedTab,
  ]);

  // Filtering list
  useEffect(() => {
    setTableState((prev) => ({
      ...prev,
      page: 1,
      columns: changeColumns(),
      rows: [],
      loading: true,
    }));

    if (selectedTab === AlertType.Notification) {
      dispatch(getNotificationList({
        ...notificationListQuery,
        offset: 0,
      }));
    } else if (selectedTab === AlertType.Announcement) {
      dispatch(getAnnouncementList({
        ...announcementListQuery,
        offset: 0,
      }));
    } else if (selectedTab === AlertType.MasterKey) {
      dispatch(getMasterKeyList({
        ...masterKeyListQuery,
        offset: 0,
      }));
    }
  }, [
    notificationListQuery,
    announcementListQuery,
    masterKeyListQuery,
  ]);

  useEffect(() => {
    let alertList = [];
    let totalAlertListCount = 0;
    if (selectedTab === AlertType.Notification) {
      alertList = notificationList;
      totalAlertListCount = totalNotificationListCount;
    } else if (selectedTab === AlertType.Announcement) {
      alertList = announcementList;
      totalAlertListCount = totalAnnouncementListCount;
    } else if (selectedTab === AlertType.MasterKey) {
      alertList = masterKeyList;
      totalAlertListCount = totalMasterKeyListCount;
    }

    if (tableState.page < alertList.length / tableState.pageSize || !alertList.length || alertList.length >= totalAlertListCount) return;

    setTableState((prev) => ({
      ...prev,
      loading: true,
    }));

    if (selectedTab === AlertType.Notification) {
      dispatch(getNotificationList({
        ...notificationListQuery,
        offset: tableState.pageSize * (tableState.page - 1),
      }));
    } else if (selectedTab === AlertType.Announcement) {
      dispatch(getAnnouncementList({
        ...announcementListQuery,
        offset: tableState.pageSize * (tableState.page - 1),
      }));
    } else if (selectedTab === AlertType.MasterKey) {
      dispatch(getMasterKeyList({
        ...masterKeyListQuery,
        offset: tableState.pageSize * (tableState.page - 1),
      }));
    }
  }, [
    tableState.page,
    tableState.pageSize,
  ]);

  useEffect(() => {
    let alertList: Notification[] | Announcement[] | MasterKey[] = [];
    let totalAlertListCount = 0;
    if (selectedTab === AlertType.Notification) {
      alertList = notificationList;
      totalAlertListCount = totalNotificationListCount;
    } else if (selectedTab === AlertType.Announcement) {
      alertList = announcementList;
      totalAlertListCount = totalAnnouncementListCount;
    } else if (selectedTab === AlertType.MasterKey) {
      alertList = masterKeyList;
      totalAlertListCount = totalMasterKeyListCount;
    }

    setTableState((prev) => ({
      ...prev,
      loading: false,
      rows: alertList,
    }));

    setTotalCount(totalAlertListCount);
    setCurrentListCount(alertList.length);
  }, [
    notificationList,
    announcementList,
    masterKeyList,
  ]);

  const changeSortModel = (newSortModel: GridSortModel) => {
    if (selectedTab === AlertType.Notification) {
      setNotificationListQuery((prev) => ({
        ...prev,
        sortModel: newSortModel,
      }));
    } else if (selectedTab === AlertType.Announcement) {
      setAnnouncementListQuery((prev) => ({
        ...prev,
        sortModel: newSortModel,
      }));
    } else if (selectedTab === AlertType.MasterKey) {
      setMasterKeyListQuery((prev) => ({
        ...prev,
        sortModel: newSortModel,
      }));
    }
  };

  const gotoNewPage = () => {
    if (selectedTab === AlertType.Notification) {
      navigate(AppRoutes.createNotification.path);
    } else if (selectedTab === AlertType.Announcement) {
      navigate(AppRoutes.createAnnouncement.path);
    } else if (selectedTab === AlertType.MasterKey) {
      navigate(AppRoutes.createMasterKey.path);
    }
  };

  const handleDetailsNavigation = (event: GridRowParams): void => {
    if (selectedTab === AlertType.Notification) {
      return navigate(AppRoutes.notificationDetails.path.replace(':notificationId', event.row.id.toString()));
    }
    else if (selectedTab === AlertType.Announcement) {
      return navigate(AppRoutes.announcementDetails.path.replace(':announcementId', event.row.id.toString()));
    }
    else if (selectedTab === AlertType.MasterKey) {
      return navigate(AppRoutes.masterKeyDetails.path.replace(':masterKeyId', event.row.id.toString()));
    }
  };

  const formValidationSchema = yup.object().shape({
    search: yup.string(),
  });

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const { handleSubmit } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    if (selectedTab === AlertType.Notification) {
      setNotificationListQuery((prev) => ({
        ...prev,
        search: values.search,
      }));
    } else if (selectedTab === AlertType.Announcement) {
      setAnnouncementListQuery((prev) => ({
        ...prev,
        search: values.search,
      }));
    } else if (selectedTab === AlertType.MasterKey) {
      setMasterKeyListQuery((prev) => ({
        ...prev,
        search: values.search,
      }));
    }
  };

  const onDeleteMasterKey = () => {
    dispatch(setShowMasterKeyDeleteConfirmDialog(false));

    dispatch(deleteMasterKeyById(masterKeyIdToDelete))
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Deleted the master key successfully.',
        }));

        dispatch(getMasterKeyList({
          ...masterKeyListQuery,
          offset: tableState.pageSize * (tableState.page - 1),
        }));
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to delete master key.',
        }));
      });
  };

  return (
    <div id="alert-list">
      <Box className="page-content card-background">
        <Tabs
          className="main-page-tabs"
          variant="fullWidth"
          value={selectedTab}
          onChange={(_, tabName) => setSelectedTab(tabName)}
        >
          {canUseAuthComponent(user.roles, AppRoutes.notifications.authorizedRoles) && (
            <Tab label="Notifications" value={AlertType.Notification} onClick={() => navigate(AppRoutes.notifications.path)} />
          )}

          {canUseAuthComponent(user.roles, AppRoutes.announcements.authorizedRoles) && (
            <Tab label="Announcements" value={AlertType.Announcement} onClick={() => navigate(AppRoutes.announcements.path)} />
          )}

          {canUseAuthComponent(user.roles, AppRoutes.masterKey.authorizedRoles) && (
            <Tab label="Master Key" value={AlertType.MasterKey} onClick={() => navigate(AppRoutes.masterKey.path)} />
          )}
        </Tabs>

        <div className="table-buttons-and-filters flex_jbetween">
          <div className="filters flex_acenter">
            <form className="content-container" onSubmit={handleSubmit(submitForm)}>
              <FormProvider {...methods}>
                <SearchInput
                  className="main-input search"
                  name="search"
                  onClear={handleSubmit(submitForm)}
                />
              </FormProvider>
            </form>
            {selectedTab === AlertType.Notification && canUseAuthComponent(user.roles, [
              UserRole.admin,
              UserRole.districtAdmin,
              UserRole.institutionAdmin,
            ]) && (
              <div className="flex_acenter filter-button" onClick={(e) => setFilterAnchorElement(e.currentTarget)}>
                <img src={AppAssetPaths.icons.FILTER} />
                <p>Filter</p>
              </div>
            )}
          </div>

          <div className="flex_acenter">
            {
              ((selectedTab === AlertType.Notification && canUseAuthComponent(user.roles, AppRoutes.createNotification.authorizedRoles)) ||
              selectedTab === AlertType.Announcement || selectedTab === AlertType.MasterKey) && (
                <Button
                  variant="contained"
                  onClick={gotoNewPage}
                  disableElevation
                >
                  New {startCase(selectedTab)}
                </Button>
              )}
          </div>
        </div>

        <DataGrid
          className="table"
          {...tableState}
          components={{
            Pagination,
          }}
          componentsProps={{
            pagination: {
              page: tableState.page,
              count: Math.ceil(totalCount / tableState.pageSize),
              onChange: (_: void, page: number) => {
                setTableState((prev) => ({
                  ...prev,
                  page,
                }));
              },
            },
          }}
          onRowClick={(event) => handleDetailsNavigation(event)}
          pagination
          paginationMode="server"
          sortingMode="server"
          sortModel={notificationListQuery.sortModel}
          onSortModelChange={(newSortModel: GridSortModel) => changeSortModel(newSortModel)}
          disableColumnFilter
          disableColumnMenu
          hideFooterSelectedRowCount
        />

        <TableRowCount
          length={currentListCount}
          pageSize={tableState.pageSize}
          page={tableState.page}
          totalCount={totalCount}
        />
      </Box>

      <NotificationFilter
        anchorElement={filterAnchorElement}
        setAnchorElement={setFilterAnchorElement}
        setFiltersCallback={(schoolDistrictId?: number, schoolId?: number, inApp?: boolean, push?: boolean) => {
          setNotificationListQuery((prev) => ({
            ...prev,
            schoolId,
            schoolDistrictId,
            inApp,
            push,
          }));
        }}
      />

      <DeleteConfirmationModal
        open={showMasterKeyDeleteConfirmDialog}
        deleteItemText="master key"
        additionalWarningText="This action cannot be undone."
        onConfirm={onDeleteMasterKey}
        onClose={() => dispatch(setShowMasterKeyDeleteConfirmDialog(false))}
      />
    </div>
  );
};

export default AlertList;
