import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { NavigationMenuItem } from '../../components/navigation/navigation.types';


/* State */

export interface NavigationState {
  pageHeader: string;
  selectedNavSection: NavigationMenuItem;
}

const initialState: NavigationState = {
  pageHeader: '',
  selectedNavSection: undefined as unknown as NavigationMenuItem,
};


/* Reducer */

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setPageHeader: (state, action) => {
      state.pageHeader = action.payload;
    },
    clearPageHeader: (state) => {
      state.pageHeader = '';
    },
    setSelectedNavSection: (state, action) => {
      state.selectedNavSection = action.payload;
    },
    clearSelectedNavSection: (state) => {
      state.selectedNavSection = undefined as unknown as NavigationMenuItem;
    },
  },
});

export const {
  setPageHeader,
  clearPageHeader,
  setSelectedNavSection,
  clearSelectedNavSection,
} = navigationSlice.actions;


/* Selectors */

const selectPageHeader = (state: RootState): string => state.navigation.pageHeader;
const selectSelectedNavSection = (state: RootState): NavigationMenuItem => state.navigation.selectedNavSection;

export const navigationSelectors = {
  selectPageHeader,
  selectSelectedNavSection,
};

export default navigationSlice.reducer;
