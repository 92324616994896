import React, { useEffect } from 'react';
import {
  useParams, useNavigate
} from 'react-router-dom';
import { Button } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import dayjs from 'dayjs';

import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import {
  AppRoutes, canUseAuthComponent
} from '../../../app/app.types';
import { announcementSelectors } from '../../../features/announcement/announcement.slice';
import { userSelectors } from '../../../features/user/user.slice';
import { getAnnouncementById } from '../../../features/announcement/announcement.thunks';
import { ListMenuItem } from '../../../components/forms/form.types';

import './AnnouncementDetails.scss';


interface AnnouncementDetailsProps { }

const AnnouncementDetails: React.FC<AnnouncementDetailsProps> = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector(userSelectors.selectUser);
  const announcement = useAppSelector(announcementSelectors.selectAnnouncement);

  useEffect(() => {
    if (!params || !params.announcementId) return;
    const id = parseInt(params.announcementId);

    dispatch(getAnnouncementById(id));
  }, []);

  return (
    <div className="card-background announcement-details">
      <div className="details-header flex_row_jbetween_acenter">
        <div className="back-button flex_row" onClick={() => navigate(AppRoutes.announcements.path)}>
          <ChevronLeftIcon className="carousel-icon" />
          <h3>Announcement Details</h3>
        </div>

        {canUseAuthComponent(user.roles, AppRoutes.editAnnouncement.authorizedRoles) && (
          <Button
            variant="outlined"
            onClick={() => navigate(AppRoutes.editAnnouncement.path.replace(':announcementId', announcement.id!.toString()))}
          >
            Edit
          </Button>
        )}
      </div>

      <div className="main-content flex_row_jbetween">
        <div className="left-column">
          <div className="detail-field-group flex_row_astart">
            <p>Message</p>
            <p>{ announcement.message }</p>
          </div>

          <div className="detail-field-group flex_row_astart">
            <p>User Type</p>
            <p>{ announcement.userTypes }</p>
          </div>

          <div className="detail-field-group flex_row_astart">
            <p>Roles</p>
            <p>{ announcement.roles }</p>
          </div>

          <div className="detail-field-group flex_row_astart">
            <p>Education Level</p>
            <p>{ announcement.educationLevels ? announcement.educationLevels.map((item: ListMenuItem) => item.label).join(', ') : '-' }</p>
          </div>
        </div>

        <div className="right-column">
          <div className="detail-field-group flex_row_astart">
            <p>Message Link</p>
            <p>{ announcement.link }</p>
          </div>

          <div className="detail-field-group flex_row_astart">
            <p>Start Date</p>
            <p>{ dayjs(announcement.startDate).format('MM/DD/YYYY') }</p>
          </div>

          <div className="detail-field-group flex_row_astart">
            <p>End Date</p>
            <p>{ dayjs(announcement.endDate).format('MM/DD/YYYY') }</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementDetails;
