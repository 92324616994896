/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { GridSortModel } from '@mui/x-data-grid';
import {
  CareerGroup, ConstructCareerGroup
} from '../careers/careers.model';
import { OrganizationType } from '../organization/organization.model';


export enum NotificationType {
  scholarship = 'scholarship',
  custom = 'custom'
}

export const EmptyNotification: Notification = {
  id: 0,
  title: '',
  approved: false,
  body: '',
  subject: '',
  type: NotificationType.custom,
  careerGroupIds: [],
  careerGroups: [],
  genderIds: [],
  graduationYears: [],
  organizationIds: [],
  organizationTypes: [],
  schoolDistrictIds: [],
  userTypeIds: [],
  ethnicityIds: [],
  parentEducationLevelIds: [],
  createdAt: '',
  isPush: false,
};

export interface NotificationListQueryParams {
  offset: number;
  limit?: number | string;
  search?: string;
  sortModel?: GridSortModel;
  schoolDistrictId?: number;
  push?: boolean;
  inApp?: boolean;
  schoolId?: number;
}

export interface Notification {
  id?: number;
  title?: string;
  approved?: boolean;
  body?: string;
  subject?: string;
  type?: NotificationType;
  careerGroupIds?: number[];
  careerGroups?: CareerGroup[];
  genderIds?: number[];
  graduationYears?: number[];
  organizationIds?: number[];
  organizationTypes?: OrganizationType[];
  schoolDistrictIds?: number[];
  userTypeIds?: number[];
  ethnicityIds?: number[],
  parentEducationLevelIds?: number[];
  createdAt?: string;
  isPush: boolean;
}

export const ConstructNotification = (data: any): Notification => {
  return {
    id: data.id,
    title: data.subject,
    approved: data.approved,
    body: data.body,
    subject: data.subject,
    type: data.type,
    careerGroupIds: data.career_group_ids || [],
    careerGroups: data.career_groups && data.career_groups.map((g: any) => ConstructCareerGroup(g)) || [],
    genderIds: data.gender_ids || [],
    graduationYears: data.graduation_years || [],
    organizationIds: data.organization_ids || [],
    organizationTypes: data.organization_types || [],
    schoolDistrictIds: data.school_district_ids || [],
    userTypeIds: data.user_type_ids || [],
    createdAt: data.created_at,
    isPush: data.is_push,
    ethnicityIds: data.ethnicity_ids,
    parentEducationLevelIds: data.parent_education_level_ids,
  };
};
