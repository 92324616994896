import { createAsyncThunk } from '@reduxjs/toolkit';
import * as inquiryApi from './inquiries.api';
import { InquiryListQueryParams } from './inquiries.model';



export const getInquiryList = createAsyncThunk(
  'inquiries/getInquiryList',
  async (query: InquiryListQueryParams) => {
    return await inquiryApi.getInquiryList(query);
  }
);