import React, {
  useEffect, useState
} from 'react';
import {
  FormGroup,
  FormLabel,
  Popover
} from '@mui/material';
import {
  FormProvider, SubmitHandler, useForm
} from 'react-hook-form';
import {
  useAppDispatch, useAppSelector
} from '../../app/hooks';
import DropdownSelect from '../../components/forms/DropdownSelect';
import {
  bottomRightAnchorOrigin, topCenterTransformOrigin
} from '../../constants/menu.types';
import { OrganizationType } from '../../features/organization/organization.model';
import { userSelectors } from '../../features/user/user.slice';
import { getGraduationYears } from '../../features/user/user.thunks';
import SubmitButton from '../../components/forms/SubmitButton';
import { ListMenuItem } from '../../components/forms/form.types';
import { organizationTypeList } from '../../util/dropdown';
import CloseIcon from '@mui/icons-material/Close';
import { SecondaryColorRadioGroup } from '../../components/CustomStyledComponents';
import { organizationSelectors } from '../../features/organization/organization.slice';
import { getAllSchoolDistricts } from '../../features/organization/organization.thunks';


interface UserFilterProps {
  anchorElement: HTMLElement | null;
  setAnchorElement: (anchorElement: HTMLElement | null) => void;
  setFiltersCallback: (schoolType?: string, schoolDistricts?: number[], graduationYears?: number[]) => void;
}

const UserFilter: React.FC<UserFilterProps> = ({
  anchorElement,
  setAnchorElement,
  setFiltersCallback,
}) => {

  const dispatch = useAppDispatch();

  const user = useAppSelector(userSelectors.selectUser);
  const schoolDistricts = useAppSelector(organizationSelectors.selectSchoolDistricts);
  const graduationYears = useAppSelector(userSelectors.selectGraduationYears);

  const [
    orgTypeList,
    setOrgTypeList,
  ] = useState(organizationTypeList.filter(o => o.id !== OrganizationType.Community));

  useEffect(() => {
    dispatch(getAllSchoolDistricts());
    dispatch(getGraduationYears());

    if (user.isDistrictAdmin) {
      setOrgTypeList(orgTypeList.filter(o => o.id !== OrganizationType.UniversityCollege && o.id !== OrganizationType.NoSchool));
    }
  }, []);

  type FormValues = { schoolType: string, schoolDistricts: ListMenuItem[], graduationYears: ListMenuItem[] };
  const methods = useForm<FormValues>();
  const { handleSubmit } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    const schoolDistrictFilter = (values.schoolDistricts && !!values.schoolDistricts.length) ? values.schoolDistricts.map((district: ListMenuItem) => +district.id) : undefined;
    const graduationFilter = (values.graduationYears && !!values.graduationYears.length) ? values.graduationYears.map((year: ListMenuItem) => +year.id) : undefined;
    setFiltersCallback(values.schoolType, schoolDistrictFilter, graduationFilter);
    setAnchorElement(null);
  };

  return (
    <Popover
      id="list-filter"
      anchorEl={anchorElement}
      open={Boolean(anchorElement)}
      onClose={() => setAnchorElement(null)}
      transformOrigin={topCenterTransformOrigin}
      anchorOrigin={bottomRightAnchorOrigin}
    >
      <CloseIcon className="close-icon" onClick={() => setAnchorElement(null)} />
      <form className="flex_col" onSubmit={handleSubmit(submitForm)}>
        <FormProvider {...methods}>
          {(!user.isMiddleSchoolAdmin && !user.isHighSchoolAdmin) && (
            <>
              <FormLabel id="school-type-filter">School Type</FormLabel>
              <SecondaryColorRadioGroup
                name="schoolType"
                itemList={orgTypeList}
                labelledBy="school-type-filter"
                size="small"
              />
            </>
          )}

          {user.isAdmin && (
            <>
              <FormLabel id="graduation-year-filter">School District</FormLabel>
              <FormGroup
                aria-labelledby="graduation-year-filter"
              >
                <DropdownSelect
                  name="schoolDistricts"
                  itemList={schoolDistricts}
                  size="small"
                  colorVariant="secondary"
                  multiple
                />
              </FormGroup>
            </>
          )}

          <FormLabel id="graduation-year-filter">Graduation Year</FormLabel>
          <FormGroup
            aria-labelledby="graduation-year-filter"
          >
            <DropdownSelect
              name="graduationYears"
              itemList={graduationYears}
              size="small"
              colorVariant="secondary"
              multiple
            />
          </FormGroup>

          <SubmitButton
            className="submit-button"
            text="Apply Filters"
            variant="contained"
            size="small"
            color="secondary"
            disableElevation
          />
        </FormProvider>
      </form>
    </Popover>
  );
};

export default UserFilter;
