import React, {
  useEffect, useState
} from 'react';
import {
  Button,
  Divider
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormProvider,
  SubmitHandler,
  useForm
} from 'react-hook-form';
import * as yup from 'yup';

import {
  useAppDispatch, useAppSelector
} from '../../app/hooks';
import { getApiBaseUrl } from '../../app/app.api';
import { userSelectors } from '../../features/user/user.slice';
import { UserRole } from '../../features/user/user.model';
import { Organization } from '../../features/organization/organization.model';
import { updateOrganization } from '../../features/organization/organization.thunks';
import { getOrganizationById } from '../../features/organization/organization.api';
import { setAlert } from '../../features/alert/alert.slice';
import {
  formatPhone, numberOnlyValidation
} from '../../util/formatting';

import TextInput from '../../components/forms/TextInput';
import SubmitButton from '../../components/forms/SubmitButton';
import UploadButton from '../../components/forms/UploadButton';
import './OrganizationSettings.scss';

interface OrganizationSettingsProps { }

const OrganizationSettings: React.FC<OrganizationSettingsProps> = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(userSelectors.selectUser);
  const [
    organization,
    setOrganization,
  ] = useState<Organization | null>(null);
  const [
    imagePath,
    setImagePath,
  ] = useState('');
  const [
    editMode,
    setEditMode,
  ] = useState(false);
  const [
    institutionAdmin,
    setInstitutionAdmin,
  ] = useState(false);
  // get organization data
  useEffect(() => {
    setInstitutionAdmin(user.roles.includes(UserRole.institutionAdmin));
    getOrganizationData();
  }, []);

  const getOrganizationData = async () => {
    const response = await getOrganizationById(user?.organization?.id);

    setOrganization(response);

    if (response.images && response.images.length > 0) {
      const baseUrl = await getApiBaseUrl();
      setImagePath(`${baseUrl}/assets/images/${response.id}/${response.images[0]}`);
    }
  };

  const formValidationSchema = yup.object().shape({
    name:
      yup.string()
        .default(organization?.name)
        .required('Organization name is required')
        .typeError('Organization name is required'),
    contactPhoneNumber:
        yup.string()
          .test('len', 'Must be exactly 10 characters', (val?: string) => {
            return !val || val.length === 12 || val.length === 0;
          }).typeError(''),
    url:
        yup.string()
          .default(organization?.url)
          .required('Website url is required')
          .isValidUrl(),
  }).required();

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    dispatch(updateOrganization({
      ...values,
      id: organization?.id,
    }))
      .unwrap()
      .then(() => {
        getOrganizationData();
        setEditMode(false);

        dispatch(setAlert({
          type: 'success',
          message: 'Successfully updated community.',
        }));
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to update community.',
        }));
      });
  };

  return (
    <div id="organization-settings">
      <div className="organization-settings-container card-background flex_col">
        <div className="header-wrapper">
          <h3>{institutionAdmin ? 'Institution Account' : 'Community Account'}</h3>

          {!editMode && (
            <Button
              className="form-button"
              variant="outlined"
              onClick={() => setEditMode(true)}>
              Edit
            </Button>
          )}
        </div>

        <Divider />
        {organization && (
          <>
            {editMode && (
              <form className="create-form-container" onSubmit={handleSubmit(submitForm)}>
                <FormProvider {...methods}>
                  <div className="form-content-wrapper">
                    <TextInput
                      containerClass="form-input"
                      name="name"
                      label="ORGANIZATION NAME"
                      errorMessage={errors.name && errors.name.message}
                      type="text"
                      size="small"
                      InputProps={{
                        disabled: institutionAdmin,
                      }}
                      defaultValue={user.organization?.name}
                    />

                    <TextInput
                      containerClass="form-input"
                      name="url"
                      label="WEBSITE"
                      errorMessage={errors.url && errors.url.message}
                      type="text"
                      size="small"
                      InputProps={{
                        disabled: institutionAdmin,
                      }}
                      defaultValue={organization?.url}
                    />

                    <TextInput
                      containerClass="form-input"
                      name="contactPhoneNumber"
                      label="CONTACT NUMBER"
                      errorMessage={errors.contactPhoneNumber && errors.contactPhoneNumber.message}
                      type="text"
                      size="small"
                      maxLength={12}
                      defaultValue={organization?.communityUser?.phoneNumber}
                      onKeyPress={(e) => {
                        numberOnlyValidation(e);
                        if (e.keyCode || e.keyCode == 0) {
                          e.target.value = formatPhone(e.target.value);
                        }
                      }}
                    />

                    <div className="field-container">
                      <p className="static-input-label">LOGO</p>
                      <UploadButton
                        text="Upload Logo"
                        name="logo"
                        size="large"
                        disableElevation
                        disabled={institutionAdmin}
                        defaultFileName={organization?.images && organization?.images[0] || ''}
                      />
                    </div>
                  </div>

                  <div className="flex_row_jbetween">
                    <Button
                      className="form-button"
                      variant="outlined"
                      onClick={() => setEditMode(false)}>
                      Cancel
                    </Button>

                    <SubmitButton
                      className="form-button"
                      text="Save"
                      variant="contained"
                      size="large"
                      disableElevation
                    />
                  </div>
                </FormProvider>
              </form>
            )}

            {!editMode && (
              <Grid container className="detail-content">
                <Grid item xs={12} md={6}>
                  <h4>{ organization?.name }</h4>
                  <div>
                    <div className="detail-item">
                      <span className="detail-item-type">Website</span>
                      <span>{organization?.url || '-'}</span>
                    </div>

                    <div className="detail-item">
                      <span className="detail-item-type">Phone Number</span>
                      <span>{organization?.communityUser?.phoneNumber}</span>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} md={6} className="image-wrapper">
                  { imagePath && (
                    <img src={imagePath} />
                  )}
                </Grid>
              </Grid>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default OrganizationSettings;
