/* eslint-disable react/react-in-jsx-scope */

import {
  GridColumns, GridRenderCellParams, GridValueFormatterParams
} from '@mui/x-data-grid';
import { AppAssetPaths } from '../../app/app.types';
import './resourceTable.types.scss';

export const exisitingUsersColumns: GridColumns = [
  {
    headerName: 'First Name',
    field: 'firstName',
    flex: 1,
    cellClassName: 'main-column',
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Last Name',
    field: 'lastName',
    flex: 1,
    cellClassName: 'main-column',
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Email',
    field: 'email',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'School',
    field: 'school',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Grade',
    field: 'grade',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: '',
    field: 'viewUser',
    flex: .5,
    renderCell: (params: GridRenderCellParams<boolean | undefined>) => (
      <div className="flex_acenter">
        <img src={AppAssetPaths.icons.RED_OPEN_IN_NEW_TAB}/>
      </div>
    ),
  },  
];

export const newUsersColumns: GridColumns = [
  {
    headerName: 'First Name',
    field: 'firstName',
    flex: 1,
    cellClassName: 'main-column',
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Last Name',
    field: 'lastName',
    flex: 1,
    cellClassName: 'main-column',
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Email',
    field: 'email',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'School',
    field: 'school',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Grade',
    field: 'grade',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: '',
    field: 'delete',
    flex: .2,
    renderCell: (params: GridRenderCellParams<boolean | undefined>) => (
      <div className="flex_acenter">
        <img src={AppAssetPaths.icons.TRASH} alt="delete"/>
      </div>
    ),
  },  
];