import { createSlice } from '@reduxjs/toolkit';
import { StateStatus } from '../../app/app.types';
import { RootState } from '../../app/store';
import {
  EmptySkill, Skill
} from './skills.model';
import {
  getSkillById, getSkillsList
} from './skills.thunks';


/* State */

export interface SkillsState {
  skillsList: {
    status: StateStatus;
    data: Skill[];
    totalCount: number;
    loaded: boolean;
  },
  skillDetails: {
    status: StateStatus;
    data: Skill;
  }
}

const initialState: SkillsState = {
  skillsList: {
    status: StateStatus.IDLE,
    data: [],
    totalCount: 0,
    loaded: false,
  },
  skillDetails: {
    status: StateStatus.IDLE,
    data: EmptySkill,
  },
};


/* Reducer */

export const skillsSlice = createSlice({
  name: 'skills',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSkillsList.pending, (state) => {
        state.skillsList.status = StateStatus.LOADING;
      })
      .addCase(getSkillsList.fulfilled, (state, action) => {
        state.skillsList.status = StateStatus.IDLE;
        state.skillsList.data = action.payload.skills;
        state.skillsList.totalCount = action.payload.count;
        state.skillsList.loaded = true;
      })
      .addCase(getSkillsList.rejected, (state) => {
        state.skillsList.status = StateStatus.FAILED;
      })

      .addCase(getSkillById.pending, (state) => {
        state.skillDetails.status = StateStatus.LOADING;
      })
      .addCase(getSkillById.fulfilled, (state, action) => {
        state.skillDetails.status = StateStatus.IDLE;
        state.skillDetails.data = action.payload;
      })
      .addCase(getSkillById.rejected, (state) => {
        state.skillDetails.status = StateStatus.FAILED;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const { } = skillsSlice.actions;


/* Selectors */

const selectSkillDetails = (state: RootState): Skill => state.skills.skillDetails.data;
const selectSkillDetailsStatus = (state: RootState): StateStatus => state.skills.skillDetails.status;

const selectSkillsList = (state: RootState): Skill[] => state.skills.skillsList.data;
const selectSkillsCount = (state: RootState): number => state.skills.skillsList.totalCount;

export const skillSelectors = {
  selectSkillDetails,
  selectSkillDetailsStatus,

  selectSkillsList,
  selectSkillsCount,
};

export default skillsSlice.reducer;
