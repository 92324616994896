import {
  Box, Button
} from '@mui/material';
import React, {
  useEffect, useState
} from 'react';
import {
  useNavigate, useParams
} from 'react-router-dom';
import { AppRoutes } from '../../../../app/app.types';
import {
  useAppDispatch, useAppSelector
} from '../../../../app/hooks';
import { getCollegeById } from '../../../../features/collegeApplicationChecklist/collegeApplicationChecklist.thunks';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import './CollegeDetails.scss';
import { collegeApplicationChecklistSelectors } from '../../../../features/collegeApplicationChecklist/collegeApplicationChecklist.slice';
import { startCase } from 'lodash';
import { TuitionOption } from '../../../../features/collegeApplicationChecklist/collegeApplicationChecklist.model';
import { getApiBaseUrl } from '../../../../app/app.api';
import { userSelectors } from '../../../../features/user/user.slice';

interface CollegeDetailsProps { }

const CollegeDetails: React.FC<CollegeDetailsProps> = () => {

  const params = useParams();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const college = useAppSelector(collegeApplicationChecklistSelectors.selectCollegeDetails);
  const collegeLoaded = useAppSelector(collegeApplicationChecklistSelectors.selectCollegeDetailsLoaded);
  const user = useAppSelector(userSelectors.selectUser);

  const [
    imagePath,
    setImagePath,
  ] = useState('');

  useEffect(() => {
    if (params.collegeId) {
      dispatch(getCollegeById(+params.collegeId));
    }
  }, []);

  useEffect(() => {
    if (college.id && college.logo && collegeLoaded) {
      getCollegeLogo();
    }
  }, [
    college,
  ]);

  const getCollegeLogo = async () => {
    const baseUrl = await getApiBaseUrl();
    setImagePath(`${baseUrl}/assets/images/colleges/${college.id}/${college.logo}`);
  };

  const createDetailsSpan = (title: string, details?: string | number) => {
    return (
      <span className="info-span">
        <p>{title}</p>
        <p>{details || '-'}</p>
      </span>
    );
  };

  const handleGetDegrees = () => {
    const tempArr = [];
    for (const [
      key,
      val,
    ] of Object.entries(college.degreesOffered)) {
      if (val === true) { tempArr.push(startCase(key)); }
    }
    return tempArr.join(', ');
  };

  const formatAddress = () => {
    // The address object was messing up the order in which it was ordered, so I made it do what I want
    return [
      college.address.streetAddress,
      college.address.city,
      college.address.state,
      college.address.zipcode,
    ].filter(Boolean).join(', ');
  };

  return (
    <div id="college-details">
      <Box className="card-background">
        <div className="list-header flex_row_jbetween">
          <span className="back-button flex_acenter" onClick={() => navigate(AppRoutes.collegeApplicationChecklistColleges.path)}>
            <ChevronLeftIcon color="secondary" />
            <h3>College</h3>
          </span>

          {(user.isAdmin || user.isDistrictInstitutionAdmin || (college?.organization?.id && user.isInstitutionAdmin && user.organizationId === college?.organization?.id)) && (
            <Button
              variant="outlined"
              onClick={() => navigate(AppRoutes.editCollegeApplicationChecklistCollege.path.replace(':collegeId', college.id.toString()))}
            >
              Edit
            </Button>
          )}
        </div>

        <div className="details-content">
          <h2>{college.name}</h2>

          <section className="flex_row_j_between top-section">
            <div className="flex_col_jstart">
              {createDetailsSpan('COLLEGE TYPE', college.type.label)}
              {createDetailsSpan('COLLEGE WEBSITE URL', college.websiteLinks.website)}
              {createDetailsSpan('CERTIFCATES & DEGREES OFFERED', handleGetDegrees())}

              {createDetailsSpan('NUMBER OF STUDENTS ENROLLED', college.studentsEnrolled)}
              {createDetailsSpan('ADDRESS', formatAddress())}

              {createDetailsSpan('ALL CAMPUS LOCATIONS LINK', college.websiteLinks.campusLink)}
              {createDetailsSpan('ADMISSION PHONE NUMBER', college.admissions.phone)}
            </div>

            <div className="img-container">
              {(college.logo && imagePath && collegeLoaded) && (
                <img
                  src={imagePath}
                  // Might seem weird. But if the picture breaks then instead of a gross box showing this makes it so it hides
                  onError={() => setImagePath('')}
                  alt="College Logo"
                />
              )}
            </div>
          </section>

          <section>
            <h5>TUITION</h5>
            <div className="flex_row_jbetween">
              {createDetailsSpan('NON-RESIDENT ANNUAL TUITON', `${college.tuition.nonResident}`)}
              {createDetailsSpan('RESIDENT ANNUAL TUITION', `${college.tuition.resident}`)}
            </div>
            {(college.tuition.options && !!college.tuition.options.length) && (
              college.tuition.options.map((ele: TuitionOption, i: number) => (
                <div className="flex_row_jbetween" key={i}>
                  {createDetailsSpan('TUITION LABEL', ele.label || '-')}
                  {createDetailsSpan('TUITION AMOUNT', ele.amount || '-')}
                </div>
              ))
            )}
          </section>

          <section>
            <h5>SOCIAL MEDIA</h5>
            <div className="flex_row_jbetween">
              {createDetailsSpan('INSTAGRAM', college.socialMediaLinks.instagram)}
              {createDetailsSpan('FACEBOOK', college.socialMediaLinks.facebook)}
            </div>
            <div className="flex_row_jbetween">
              {createDetailsSpan('LINKEDIN', college.socialMediaLinks.linkedIn)}
              {createDetailsSpan('TWITTER', college.socialMediaLinks.twitter)}
            </div>
            <div className="flex_row_jbetween">
              {createDetailsSpan('YOUTUBE', college.socialMediaLinks.youtube)}
              {createDetailsSpan('PINTREST', college.socialMediaLinks.pintrest)}
            </div>
            {createDetailsSpan('DISCORD', college.socialMediaLinks.discord)}

          </section>

          <section>
            <h5>APPLICATION</h5>
            <div className="flex_row_jbetween">
              {createDetailsSpan('COLLEGE APPLICATION URL', college.websiteLinks.applicationPage)}
              {createDetailsSpan('FALL ADMISSION DEADLINE', college.deadlines.fallAdmission)}
            </div>
            <div className="flex_row_jbetween">
              {createDetailsSpan('APPLICATION FEE AMOUNT', college.enrollmentFee ? `$${college.enrollmentFee}` : '-')}


              {createDetailsSpan('APPLICATION/ ENROLLMENT FEE START DATE', college.enrollmentFeeStartDate)}
            </div>
            <div className="flex_row_jbetween">
              {createDetailsSpan('DISPLAY APPLICATION FEE WAIVER', (college.hasFeeWaiver ? 'Yes' : 'No'))}
              {/* This is the post october application fee, just by another name*/}
              {(college.hasFeeWaiver) && (
                createDetailsSpan('APPLICATION FEE WAIVER EXPIRATION DATE', college.feeWaiverExpirationDate)
              )}

            </div>
            {(college.hasFeeWaiver) && (
              <div className="flex_row_jbetween">
                {createDetailsSpan('APPLICATION FEE WAIVER CODE', college.feeWaiverCode || 'N/A')}
                {/* Waiver explanation is when the fee waiver code is not just a code, but those long paragraphs */}
                {createDetailsSpan('WAIVER EXPLANATION', college.feeWaiverExplanation || '-')}
              </div>
            )}
          </section>

          <section>
            <h5>SCHOLARSHIPS</h5>
            <div className="flex_row_jbetween">
              {createDetailsSpan('SCHOLARSHIP APPLICATION DEADLINE', college.deadlines.scholarshipApplication)}
              {createDetailsSpan('SCHOLARSHIP WEBSITE URL', college.websiteLinks.scholarshipPage)}
              {createDetailsSpan('FINANCIAL PRIORITY DEADLINE', college.deadlines.financialAidPriority)}
            </div>
          </section>

          <section>
            <h5>CAMPUS TOUR</h5>
            <div className="flex_row_jbetween">
              {createDetailsSpan('LINK TO SCHEDULE A CAMPUS TOUR', college.websiteLinks.scheduleCampusTourLink)}
              {createDetailsSpan('CAMPUS TOUR VIDEO LINK', college.websiteLinks.tourVideo)}
            </div>
          </section>

          <section>
            {createDetailsSpan('FEATURED ON UTAHMAJORS.COM', (college.utahProgramsMajorGuide ? 'True' : 'False'))}
          </section>


        </div>

      </Box>

    </div>
  );

};

export default CollegeDetails;