import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  OrganizationListQueryParams, NewOrganization, OrganizationType
} from './organization.model';

import * as organizationApi from './organization.api';

export const getOrganizationbyId = createAsyncThunk(
  'organization/getOrganizationById',
  async (id: number) => {
    return await organizationApi.getOrganizationById(id);
  }
);

export const getOrganizationList = createAsyncThunk(
  'organization/getOrganizationList',
  async (query: OrganizationListQueryParams) => {
    return await organizationApi.getOrganizationList(query);
  }
);

export const getAllSchoolDistricts = createAsyncThunk(
  'organization/getAllSchoolDistricts',
  async () => {
    return await organizationApi.getAllSchoolDistricts();
  }
);

export const getAllSchools = createAsyncThunk(
  'organization/getAllSchools',
  async () => {
    return await organizationApi.getOrganizationsByType(undefined, undefined, true);
  }
);

export const getByType = createAsyncThunk(
  'organization/getByType',
  async (body: { orgType?: OrganizationType, schoolDistrictId?: number, onlySchools?: boolean, allowAll?: boolean }) => {
    return await organizationApi.getOrganizationsByType(body.orgType, body.schoolDistrictId, body.onlySchools, body.allowAll);
  }
);

export const createNewOrganization = createAsyncThunk(
  'organization/createOrganization',
  async (values: NewOrganization) => {
    return await organizationApi.createNewOrganization(values);
  }
);

export const updateOrganization = createAsyncThunk(
  'organization/updateOrganization',
  async (values: NewOrganization) => {
    return await organizationApi.updateOrganization(values);
  }
);

export const deleteOrganization = createAsyncThunk(
  'organization/deleteOrganization',
  async (organizationId: number) => {
    return await organizationApi.deleteOrganization(organizationId);
  }
);

export const getCommunityOrganizationTypeList = createAsyncThunk(
  'organization/getCommunityOrganizationTypeList',
  async () => {
    return await organizationApi.getCommunityOrganizationTypes();
  }
);
