import React, {
  useEffect, useState
} from 'react';
import {
  Box, Button, CircularProgress
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  useAppDispatch, useAppSelector
} from '../../../../app/hooks';
import { collegeApplicationChecklistSelectors } from '../../../../features/collegeApplicationChecklist/collegeApplicationChecklist.slice';
import {
  getFinancialAidInfoSections, updateFinancialAidSections
} from '../../../../features/collegeApplicationChecklist/collegeApplicationChecklist.thunks';
import { AppRoutes } from '../../../../app/app.types';
import { toWords } from 'number-to-words';
import { FinancialAidInfoSection } from '../../../../features/collegeApplicationChecklist/collegeApplicationChecklist.model';
import * as yup from 'yup';
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import SubmitButton from '../../../../components/forms/SubmitButton';
import TextInput from '../../../../components/forms/TextInput';
import { setAlert } from '../../../../features/alert/alert.slice';

import './EditFinancialAidInfo.scss';


interface EditFinancialAidInfoProps { }

const EditFinancialAidInfo: React.FC<EditFinancialAidInfoProps> = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const financialAidInfoSections = useAppSelector(collegeApplicationChecklistSelectors.selectFinancialAidInfoSections);
  const financialAidInfoSectionsLoaded = useAppSelector(collegeApplicationChecklistSelectors.selectFinancialAidInfoSectionsLoaded);

  const [
    pageLoaded,
    setPageLoaded,
  ] = useState(false);
  const [
    editMode,
    setEditMode,
  ] = useState(false);

  useEffect(() => {
    if (!financialAidInfoSectionsLoaded) {
      dispatch(getFinancialAidInfoSections());
    }
  }, []);

  useEffect(() => {
    if (financialAidInfoSectionsLoaded) {
      /*
       * Ideally we would be able to call "prePopulateValues" here directly since this page will always be in editMode.
       * The timing of the page is really weird though, and if we try that, it won't always populate correctly.
       * Blame the useFieldArray append function. It's nice, but causes this weirdness.
       */
      setEditMode(true);
    }
  }, [
    financialAidInfoSections,
  ]);

  useEffect(() => {
    if (editMode) {
      prePopulateValues();
      setPageLoaded(true);
    }
  }, [
    editMode,
  ]);

  const prePopulateValues = async () => {
    if (financialAidInfoSections.length && !sectionFields.length) {
      financialAidInfoSections.map((s: FinancialAidInfoSection) => appendSection(s));
    }
  };

  // Form validation
  const sectionValidation = yup.array().of(
    yup.object().shape({
      name: yup.string()
        .required('Name is required'),
      description: yup.string()
        .required('Description is required'),
      url: yup.string()
        .isValidUrl()
        .required('Url is required'),
    })
  );

  const formValidationSchema = yup.object().shape({
    sections: sectionValidation,
  }).required();

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const { control, handleSubmit, formState: { errors } } = methods;
  const {
    fields: sectionFields,
    append: appendSection,
  } = useFieldArray({
    name: 'sections',
    control,
  });

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    dispatch(updateFinancialAidSections(values.sections))
      .unwrap()
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully updated financial aid sections.',
        }));
        navigate(-1);
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to update financial aid sections.',
        }));
      });
  };

  return (
    <form id="edit-financial-aid-info" onSubmit={handleSubmit(submitForm)}>
      {pageLoaded ? (
        <FormProvider {...methods}>
          <Box className="card-background flex_col">
            <div className="content-header flex_row_jbetween">
              <span className="flex_row_jstart_acenter">
                <h3>Financial Aid Information</h3>
              </span>
            </div>

            <div className="form-container">
              {sectionFields.map((s, i) => (
                <div key={s.id} className="form-section">
                  <h3 className="section-header">Section {toWords(i+1)}</h3>
                  <TextInput
                    name={`sections.${i}.name` as 'sections.0.name'}
                    label="Section Name"
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    errorMessage={(errors?.sections as any)?.[i]?.name?.message}
                    type="text"
                    size="small"
                    required
                    containerClass="text-field"
                  />

                  <TextInput
                    name={`sections.${i}.description` as 'sections.0.description'}
                    label="Section Description"
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    errorMessage={(errors?.sections as any)?.[i]?.description?.message}
                    type="text"
                    size="small"
                    multiLine
                    rows={3}
                    required
                    containerClass="text-field"
                  />

                  <TextInput
                    name={`sections.${i}.url` as 'sections.0.url'}
                    label="Section Url"
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    errorMessage={(errors?.sections as any)?.[i]?.url?.message}
                    type="text"
                    size="small"
                    required
                    containerClass="text-field"
                  />
                </div>
              ))}

              <div className="flex_jbetween">
                <Button
                  className="form-button"
                  variant="outlined"
                  onClick={() => navigate(AppRoutes.financialAidInfoDetails.path)}
                >
                  Cancel
                </Button>

                <SubmitButton
                  className="form-button"
                  text="Save"
                  variant="contained"
                  size="large"
                  disableElevation
                />
              </div>
            </div>
          </Box>
        </FormProvider>
      ) : (
        <div className="flex_jcenter_acenter loading-indicator">
          <CircularProgress size="50px" />
        </div>
      )}
    </form>
  );
};

export default EditFinancialAidInfo;
