/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  ConstructEmptyPathwayCourseByType, PathwayCourse, PathwayCourseType
} from '../../../../features/pathways/pathways.model';
import ExpandableCard from '../../../../components/details/ExpandableCard';
import TextInput from '../../../../components/forms/TextInput';
import DropdownSelect from '../../../../components/forms/DropdownSelect';
import { yesNoDropdownList } from '../../../../util/dropdown';
import { AppAssetPaths } from '../../../../app/app.types';
import { Divider } from '@mui/material';
import { useAppSelector } from '../../../../app/hooks';
import { userSelectors } from '../../../../features/user/user.slice';

import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


interface SupportingCourseFormProps {
  supportingCourseFields: any;
  errors: any;
  appendCourseCallback: (course: PathwayCourse) => void;
  removeCourseCallback: (fieldIndex: number, courseId?: number, ) => void;
  updateCourseCallback: (fieldIndex: number, course: Partial<PathwayCourse>) => void;
  getValuesCallback: (fieldName: string) => any;
}

const SupportingCourseForm: React.FC<SupportingCourseFormProps> = ({
  supportingCourseFields,
  errors,
  appendCourseCallback,
  removeCourseCallback,
  updateCourseCallback,
  getValuesCallback,
}) => {

  const user = useAppSelector(userSelectors.selectUser);

  return (
    <ExpandableCard
      className="course-form"
      summary={<span>Supporting Courses</span>}
      details={
        <div className="course-container">
          {supportingCourseFields.map((c: PathwayCourse, i: number) => (
            <div key={c.id}>
              {i > 0 && <Divider variant="middle" className="divider course-divider" />}
              <div className="flex_jbetween_acenter">
                <p className="course-number">Course {i+1}</p>
                <div className="course-control flex">
                  <span
                    className="hide-button flex_acenter"
                    onClick={() => updateCourseCallback(i, {
                      ...supportingCourseFields[i],
                      name: getValuesCallback(`supportingCourses.${i}.name`),
                      link: getValuesCallback(`supportingCourses.${i}.link`),
                      concurrentEnrollment: getValuesCallback(`supportingCourses.${i}.concurrentEnrollment`),
                      alternateCourseName: getValuesCallback(`supportingCourses.${i}.alternateCourseName`),
                      active: !supportingCourseFields[i].active,
                    })}
                  >
                    {supportingCourseFields[i].active ? (
                      <div className="flex_acenter">
                        <p>Hide Course</p>
                        <VisibilityIcon className="eye-icon" />
                      </div>
                    ) : (
                      <div className="flex_acenter">
                        <p>Show Course</p>
                        <VisibilityOffIcon className="eye-icon" />
                      </div>
                    )}
                  </span>
                  {user.isAdmin && (
                    <span
                      className="remove-button flex_acenter"
                      onClick={() => removeCourseCallback(i, supportingCourseFields[i].courseId)}
                    >
                      <p>Remove Course</p>
                      <img className="trash-icon" src={AppAssetPaths.icons.TRASH_RED} alt="delete" />
                    </span>
                  )}
                </div>
              </div>
              <div className="form-content flex_row_jbetween">
                <div className="form-column">
                  <TextInput
                    name={`supportingCourses.${i}.name` as 'supportingCourses.0.name'}
                    label="COURSE NAME"
                    errorMessage={errors?.supportingCourses?.[i]?.name?.message}
                    type="text"
                    size="small"
                    disabled={user.isDistrictAdmin || user.isHighSchoolAdmin}
                  />
                  <DropdownSelect
                    name={`supportingCourses.${i}.concurrentEnrollment` as 'supportingCourses.0.concurrentEnrollment'}
                    label="CONCURRENT ENROLLMENT"
                    itemList={yesNoDropdownList}
                    errorMessage={errors?.supportingCourses?.[i]?.concurrentEnrollment?.message}
                    size="small"
                  />
                </div>
                <div className="form-column">
                  <TextInput
                    name={`supportingCourses.${i}.link` as 'supportingCourses.0.link'}
                    label="LINK"
                    errorMessage={errors?.supportingCourses?.[i]?.link?.message}
                    type="text"
                    size="small"
                    disabled={user.isDistrictAdmin || user.isHighSchoolAdmin}
                  />
                  {(user.isDistrictAdmin || user.isHighSchoolAdmin) && (
                    <TextInput
                      name={`supportingCourses.${i}.alternateCourseName` as 'supportingCourses.0.alternateCourseName'}
                      label="ALTERNATIVE COURSE NAME"
                      type="text"
                      size="small"
                      tooltip="If this field is filled out, the text here will replace the the original course name for your school."
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
          {user.isAdmin && (
            <span
              className="add-button flex_acenter"
              onClick={() => appendCourseCallback(ConstructEmptyPathwayCourseByType(PathwayCourseType.supporting))}
            >
              <AddIcon className="add-icon" />
              <p>Add Course</p>
            </span>
          )}
        </div>
      }
      expandable
    />
  );
};

export default SupportingCourseForm;
