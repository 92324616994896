import { createSlice } from '@reduxjs/toolkit';
import { StateStatus } from '../../app/app.types';
import { RootState } from '../../app/store';
import {
  EmptyResourceTag, ResourceTag
} from './resourceTags.model';
import {
  getResourceTagById, getResourceTagList
} from './resourceTags.thunks';



export interface ResourceTagState {
  resourceTagList: {
    status: StateStatus;
    data: ResourceTag[];
    totalCount: number;
    loaded: boolean;
  },
  resourceTagDetails: {
    status: StateStatus;
    data: ResourceTag;
  }
}

const initialState: ResourceTagState = {
  resourceTagList: {
    status: StateStatus.IDLE,
    data: [],
    totalCount: 0,
    loaded: false,
  },
  resourceTagDetails: {
    status: StateStatus.IDLE,
    data: EmptyResourceTag,
  },
};


export const resourceTagSlice = createSlice({
  name: 'resourceTags',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getResourceTagList.pending, (state) => {
        state.resourceTagList.status = StateStatus.LOADING;
      })
      .addCase(getResourceTagList.fulfilled, (state, action) => {
        state.resourceTagList.status = StateStatus.IDLE;
        state.resourceTagList.data = action.payload.resourceTags;
        state.resourceTagList.totalCount = action.payload.count;
        state.resourceTagList.loaded = true;
      })
      .addCase(getResourceTagList.rejected, (state) => {
        state.resourceTagList.status = StateStatus.FAILED;
      })

      .addCase(getResourceTagById.pending, (state) => {
        state.resourceTagDetails.status = StateStatus.LOADING;
      })
      .addCase(getResourceTagById.fulfilled, (state, action) => {
        state.resourceTagDetails.status = StateStatus.IDLE;
        state.resourceTagDetails.data = action.payload;
      })
      .addCase(getResourceTagById.rejected, (state) => {
        state.resourceTagDetails.status = StateStatus.FAILED;
      });

  },
});


// List
const selectResourceTagList = (state: RootState): ResourceTag[] => state.resourceTag.resourceTagList.data;
const selectResourceTagListCount = (state: RootState): number => state.resourceTag.resourceTagList.totalCount;

// Details
const selectResourceTagDetails = (state: RootState): ResourceTag => state.resourceTag.resourceTagDetails.data;
const selectResourceTagDetailsStatus = (state: RootState): StateStatus => state.resourceTag.resourceTagDetails.status;


export const resourceTagSelectors = {
  selectResourceTagList,
  selectResourceTagListCount,

  selectResourceTagDetails,
  selectResourceTagDetailsStatus,
};

export default resourceTagSlice.reducer;