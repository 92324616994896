import React, {
  useEffect, useState
} from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Popover
} from '@mui/material';
import {
  FormProvider, SubmitHandler, useForm
} from 'react-hook-form';
import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import DropdownSelect from '../../../components/forms/DropdownSelect';
import {
  bottomRightAnchorOrigin, topCenterTransformOrigin
} from '../../../constants/menu.types';
import SubmitButton from '../../../components/forms/SubmitButton';
import CloseIcon from '@mui/icons-material/Close';
import { organizationSelectors } from '../../../features/organization/organization.slice';
import { getAllSchoolDistricts } from '../../../features/organization/organization.thunks';
import { getOrganizationsByType } from '../../../features/organization/organization.api';
import { Organization } from '../../../features/organization/organization.model';
import { userSelectors } from '../../../features/user/user.slice';

interface NotificationFilterProps {
  anchorElement: HTMLElement | null;
  setAnchorElement: (anchorElement: HTMLElement | null) => void;
  setFiltersCallback: (schoolDistrictId?: number, organizationId?: number, inApp?: boolean, push?: boolean) => void;
}

const NotificationFilter: React.FC<NotificationFilterProps> = ({
  anchorElement,
  setAnchorElement,
  setFiltersCallback,
}) => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(userSelectors.selectUser);
  const schoolDistricts = useAppSelector(organizationSelectors.selectSchoolDistricts);

  const [
    schoolDistrictId,
    setSchoolDistrictId,
  ] = useState(0);

  const [
    organizationList,
    setOrganizationList,
  ] = useState<Organization[]>([]);

  const [
    inApp,
    setInApp,
  ] = useState(false);

  const [
    push,
    setPush,
  ] = useState(false);

  useEffect(() => {
    if (user.isDistrictAdmin || user.isInstitutionAdmin) {
      setSchoolDistrictId(user.organizationId);
      return;
    }

    if (!schoolDistricts || schoolDistricts.length === 0) {
      dispatch(getAllSchoolDistricts());
    }
  }, []);

  useEffect(() => {
    getOrganizationsByType(undefined, schoolDistrictId, true).then(res => {
      setOrganizationList(res);
    });
  }, [
    schoolDistrictId,
  ]);

  type FormValues = { schoolDistrict: Organization, organization: Organization };
  const methods = useForm<FormValues>();
  const { handleSubmit } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    const organizationId = values.organization?.id;
    setFiltersCallback(schoolDistrictId, organizationId, inApp, push);
    setAnchorElement(null);
  };

  return (
    <Popover
      id="list-filter"
      anchorEl={anchorElement}
      open={Boolean(anchorElement)}
      onClose={() => setAnchorElement(null)}
      transformOrigin={topCenterTransformOrigin}
      anchorOrigin={bottomRightAnchorOrigin}
    >
      <CloseIcon className="close-icon" onClick={() => setAnchorElement(null)} />
      <form className="flex_col" onSubmit={handleSubmit(submitForm)}>
        <FormProvider {...methods}>
          {(user.isAdmin) && (
            <div id="notification-filter">
              <FormLabel id="organization-filter">Notification Type</FormLabel>
              <FormGroup>
                <FormControlLabel
                  label="In-App"
                  control={
                    <Checkbox
                      checked={inApp}
                      onChange={() => setInApp(!inApp)} name="In App"
                    />
                  }
                />
                <FormControlLabel
                  label="Push"
                  control={
                    <Checkbox
                      checked={push}
                      onChange={() => setPush(!push)} name="Push"
                    />
                  }
                />
              </FormGroup>
            </div> )}
          {
            user && user.isAdmin && (
              <>
                <FormLabel id="school-district-filter">School District</FormLabel>
                <FormGroup
                  aria-labelledby="school-district-filter"
                >
                  <DropdownSelect
                    name="schoolDistrict"
                    itemList={schoolDistricts}
                    size="small"
                    colorVariant="secondary"
                    onChange={(e) => setSchoolDistrictId(e?.id)}
                  />
                </FormGroup>
              </>
            )
          }

          <FormLabel id="organization-filter">Organization</FormLabel>
          <FormGroup
            aria-labelledby="organization-filter"
          >
            <DropdownSelect
              name="organization"
              itemList={organizationList}
              size="small"
              colorVariant="secondary"
            />
          </FormGroup>

          <SubmitButton
            className="submit-button"
            text="Apply Filters"
            variant="contained"
            size="small"
            color="secondary"
            disableElevation
          />
        </FormProvider>
      </form>
    </Popover>
  );
};

export default NotificationFilter;