import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { StateStatus } from '../../app/app.types';
import {
  Announcement, EmptyAnnouncement 
} from './announcement.model';
import {
  getAnnouncementList, getAnnouncementById 
} from './announcement.thunks';

export interface AnnouncementState {
  announcementList: {
    status: StateStatus;
    data: Announcement[];
    totalAnnouncementCount: number;
    loaded: boolean;
  },
  announcement: {
    status: StateStatus;
    data: Announcement;
  }
}

const initialState: AnnouncementState = {
  announcementList: {
    status: StateStatus.IDLE,
    data: [],
    totalAnnouncementCount: 0,
    loaded: false,
  },
  announcement: {
    status: StateStatus.IDLE,
    data: EmptyAnnouncement,
  },
};

export const announcementSlice = createSlice({
  name: 'announcement',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAnnouncementList.pending, (state) => {
        state.announcementList.status = StateStatus.LOADING;
      })
      .addCase(getAnnouncementList.fulfilled, (state, action) => {
        state.announcementList.status = StateStatus.IDLE;
        state.announcementList.data = action.payload.announcements;
        state.announcementList.totalAnnouncementCount = action.payload.count;
        state.announcementList.loaded = true;
      })
      .addCase(getAnnouncementList.rejected, (state) => {
        state.announcementList.status = StateStatus.FAILED;
      })
      .addCase(getAnnouncementById.pending, (state) => {
        state.announcement.status = StateStatus.LOADING;
      })
      .addCase(getAnnouncementById.fulfilled, (state, action) => {
        state.announcement.status = StateStatus.IDLE;
        state.announcement.data = action.payload;
      })
      .addCase(getAnnouncementById.rejected, (state) => {
        state.announcement.status = StateStatus.FAILED;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const {  } = announcementSlice.actions;

/* Selectors */
const selectAnnouncementListStatus = (state: RootState): StateStatus => state.announcement.announcementList.status || StateStatus.IDLE;
const selectAnnouncementList = (state: RootState): Announcement[] => state.announcement.announcementList.data || [];
const selectTotalAnnouncementListCount = (state: RootState): number => state.announcement.announcementList.totalAnnouncementCount;
const selectAnnouncement = (state: RootState): Announcement => state.announcement.announcement.data;
const selectAnnouncementStatus = (state: RootState): StateStatus => state.announcement.announcement.status;

export const announcementSelectors = {
  selectAnnouncementListStatus,
  selectAnnouncementList,
  selectTotalAnnouncementListCount,
  selectAnnouncement,
  selectAnnouncementStatus,
};

export default announcementSlice.reducer;