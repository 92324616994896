/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ListMenuItem } from '../../components/forms/form.types';

export interface Zipcode extends ListMenuItem {
  zipcode: string;
  city: string;
  state: string;
}

export const ConstructZipCode = (data: any): Zipcode => {
  return {
    id: data.id,
    label: data.zipcode,
    zipcode: data.zipcode,
    city: data.zipcode,
    state: data.state,
  };
};
