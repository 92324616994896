import React from 'react';
import ReactPlayer from 'react-player';
import { AppAssetPaths } from '../../../app/app.types';
import './BulkCreateLandingStep.scss';




export interface BulkCreateLandingStepProps { }

const BulkCreateLandingStep: React.FC<BulkCreateLandingStepProps> = () => {

  return (
    <div className="landing-step main-content flex_col_jstart">
      <div className="tutorial-video flex_row_jcenter_acenter">
        <ReactPlayer
          className="the-vid"
          url={AppAssetPaths.videos.BULK_ACCOUNT_CREATE_TUTORIAL}
          height="100%"
          width="100%"
          light={AppAssetPaths.videos.VIDEO_SCREENSHOT}
          controls
          playing
        />
  
      </div>

      <p>
        At the end of this process you will be given a code to help these users access their new accounts.
        <b> The code will expire 7 days after it is generated. </b>
        Please keep this in mind before starting the process.
      </p>
    </div>
  );
};



export default BulkCreateLandingStep;