/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useEffect, useState
} from 'react';
import './CreateEditResource.scss';
import {
  useAppDispatch, useAppSelector
} from '../../app/hooks';
import {
  startCase, uniqWith, isEqual
} from 'lodash';
import {
  FormProvider, SubmitHandler, useForm
} from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import TextInput from '../../components/forms/TextInput';
import SubmitButton from '../../components/forms/SubmitButton';
import DropdownSelect from '../../components/forms/DropdownSelect';
import {
  Organization, OrganizationType
} from '../../features/organization/organization.model';
import { userSelectors } from '../../features/user/user.slice';
import { UserRole } from '../../features/user/user.model';
import {
  AppAssetPaths,
  AppRoutes, canUseAuthComponent, StateStatus
} from '../../app/app.types';
import { careerSelectors } from '../../features/careers/careers.slice';
import { getCareerGroups } from '../../features/careers/careers.thunks';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Tooltip
} from '@mui/material';
import dayjs from 'dayjs';
import {
  useLocation, useNavigate, useParams
} from 'react-router-dom';
import { ResourceType } from '../../features/resources/resources.model';
import { useSelector } from 'react-redux';
import { resourceSelectors } from '../../features/resources/resources.slice';
import {
  createResource,
  deleteResource,
  getResourceById,
  updateResource
} from '../../features/resources/resources.thunks';
import { setAlert } from '../../features/alert/alert.slice';
import DeleteConfirmationModal from '../../components/modal/DeleteConfirmationModal';
import { getUserTypeList } from '../../features/user/user.thunks';
import { UserType } from '../../features/user/userType.model';
import { ListMenuItem } from '../../components/forms/form.types';
import { getZipcodeList } from '../../features/zipcode/zipcode.thunks';
import { zipcodeSelectors } from '../../features/zipcode/zipcode.slice';
import { resourceTagSelectors } from '../../features/resourceTags/resourceTags.slice';
import { getResourceTagList } from '../../features/resourceTags/resourceTags.thunks';
import {
  ResourceTag, ResourceTagListQueryParams
} from '../../features/resourceTags/resourceTags.model';
import ResourceTagsModal from '../../components/modal/ResourceTagsModal';
import {
  getEndOfNextSchoolYear,
  END_OF_SCHOOL_YEAR
} from '../../util/dates';
import { getByType } from '../../features/organization/organization.thunks';
import { organizationSelectors } from '../../features/organization/organization.slice';
import { getOrganizationsByType } from '../../features/organization/organization.api';


interface CreateEditResourceProps { }

const CreateEditResource: React.FC<CreateEditResourceProps> = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();

  const user = useAppSelector(userSelectors.selectUser);
  const userTypeList = useAppSelector(userSelectors.selectUserTypeList);
  const zipcodeList = useAppSelector(zipcodeSelectors.selectZipcodeList);
  const tags = useAppSelector(resourceTagSelectors.selectResourceTagList);
  const careerGroups = useAppSelector(careerSelectors.selectCareerGroups);
  const resourceDetails = useSelector(resourceSelectors.selectResourceDetails);
  const resourceDetailsStatus = useAppSelector(resourceSelectors.selectResourceDetailsStatus);
  const organizationList = useSelector(organizationSelectors.selectOrganizationList);

  // BOOLEANS
  const [
    editMode,
    setEditMode,
  ] = useState<boolean>(false);
  const [
    inApp,
    setInApp,
  ] = useState(false);
  const [
    tuitionWaiver,
    setTuitionWaiver,
  ] = useState(false);
  const [
    userAvailability,
    setUserAvailability,
  ] = useState(false);
  const [
    addAnother,
    setAddAnother,
  ] = useState<boolean>(false);
  const [
    readyToLoad,
    setReadyToLoad,
  ] = useState<boolean>(false);
  const [
    pageLoaded,
    setPageLoaded,
  ] = useState<boolean>(false);
  const [
    showGrades,
    setShowGrades,
  ] = useState<boolean>(true);
  const [
    showDeleteConfirmDialog,
    setShowDeleteConfirmDialog,
  ] = useState<boolean>(false);
  const [
    resourceTagsModalOpen,
    setResourceTagsModalOpen,
  ] = useState<boolean>(false);
  // OBJECTS
  const [
    fundingCheckboxes,
    setFundingCheckboxes,
  ] = useState({
    static: true,
    range: false,
    varies: false,
  });
  const [
    availableCheckboxes,
    setAvailableCheckboxes,
  ] = useState({
    static: true,
    range: false,
    varies: false,
  });
  //ARRAYS
  const [
    staticGradeList,
    setStaticGradeList,
  ] = useState<any[]>([]);
  const [
    dynamicGradeList,
    setDynamicGradeList,
  ] = useState<any[]>([]);
  const [
    middleSchoolGrades,
    setMiddleSchoolGrades,
  ] = useState<any[]>([]);
  const [
    highSchoolGrades,
    setHighSchoolGrades,
  ] = useState<any[]>([]);

  const [
    creatingOrgList,
    setCreatingOrgList,
  ] = useState<Organization[]>([]);
  const [
    sendingToOrgList,
    setSendingToOrgList,
  ] = useState<Organization[]>([]);

  // OTHER
  const [
    resourceType,
    setResourceType,
  ] = useState<ResourceType>(ResourceType.scholarship);

  useEffect(() => {
    createStaticGradeList();

    dispatch(getCareerGroups());
    dispatch(getUserTypeList());
    dispatch(getZipcodeList());
    // By adding the sort model we can have the dropdown list be alphabetically at the load time.
    dispatch(getResourceTagList({
      sortModel: [
        {
          field: 'name',
          sort: 'asc',
        },
      ],
    } as ResourceTagListQueryParams));

    const userOrgId = (user.isDistrictAdmin && !user.isDistrictInstitutionAdmin)
      ? (user.organizationId)
      : ((user.isKeyContact || user.isCounselor) && !user.isUniversityCounselor && !user.isUniversityKeyContact)
        ? (user.organization.schoolDistrict?.id)
        : undefined;

    dispatch(getByType({
      orgType: undefined,
      schoolDistrictId: userOrgId,
      onlySchools: true,
    })).unwrap()
      .then((res) => {
        if (user.isDistrictInstitutionAdmin) {
          const filteredArray = res.filter((ele: Organization) => {
            return ele?.schoolDistrict?.id === user.organizationId;
          });
          setCreatingOrgList(filteredArray);
        }
      });

    if (state && state.type) {
      setResourceType(state.type);

      if (state.id) {
        dispatch(getResourceById(state));
        setEditMode(true);
      }
    }
  }, []);

  useEffect(() => {
    if ((params && params.resourceId && +params.resourceId !== resourceDetails.id) || pageLoaded) return;

    if (user
      && !!tags.length
      && !!userTypeList.length
      && !!zipcodeList.length
      && !!staticGradeList.length
      && !!middleSchoolGrades.length
      && !!highSchoolGrades.length
      && !!organizationList.length
    ) {

      // Thought about doing this as a ternary but
      if (user.isCounselor || user.isKeyContact) {
        handleCounselorKeyContact();
      }
      if ((user.isCommunity || user.isInstitutionAdmin) && !user.isDistrictInstitutionAdmin) {
        handleCommunityInstiAdmin();
      }
      // I Understand I can just have 'user.isDistrictAdmin' here and it would check true for both, but I added insti-dadmins to make it easier to debug
      if (user.isDistrictAdmin || user.isDistrictInstitutionAdmin) {
        handleDistrictAdmins();
      }

      // Leaving commented out for now incase  moving it to the first getbyType call doesn't solve all issues.
      // if (user.isDistrictInstitutionAdmin) {
      //   // Since the Insti-district admin is a bit special, they get their own special if statement
      //   getOrganizationsByType().then(res => {
      //     const filteredArray = res.filter((ele: Organization) => {
      //       return ele?.schoolDistrict?.id === user.organizationId;
      //     });
      //     setCreatingOrgList(filteredArray);
      //   });
      // }
      if (user.isAdmin) {
        getOrganizationsByType().then(res => {
          setCreatingOrgList(res);
          handleSuperAdmins();
        });
      }
      else {
        getOrganizationsByType().then(res => {
          setCreatingOrgList(res);
        });
      }

      setSendingToOrgList(organizationList);
      setReadyToLoad(true);
    }

  }, [
    tags,
    userTypeList,
    zipcodeList,
    user,
    staticGradeList,
    highSchoolGrades,
    middleSchoolGrades,
    organizationList,
    pageLoaded,
    resourceDetails,
  ]);

  useEffect(() => {
    if (readyToLoad) {
      prePopulateValues();
      setPageLoaded(true);
    }
  }, [
    readyToLoad,
  ]);

  // This handles the user types that can only create from and to their own organization
  // The types that would use this are: Middle School Counselor/Keycontact, High School Counselor/Keycontact, Adult Counselor/Keycontact, Higher Ed Counselor/Keycontact
  const handleCounselorKeyContact = () => {
    setValue('organization', (editMode) ? resourceDetails.organization : user.organization);
    // Find the users org type in the userTypeList. Since university isn't a type, they will use Adult instead
    if (user.isUniversityCounselor || user.isUniversityKeyContact) {
      setValue('organizationType', editMode ? resourceDetails.userTypes : [
        userTypeList.find((ele: UserType) => ele.type === OrganizationType.Adult),
      ]);
    }
    else {
      setValue('organizationType', editMode ? resourceDetails.userTypes : [
        userTypeList.find((ele: UserType) => ele.type === user.organization.type),
      ]);
    }
    handleGradesDynamically(true);
    user.isAdultAdmin && setShowGrades(false);
    setValue('institutions', editMode ? resourceDetails.institutions : [
      user.organization,
    ]);
    setValue('careers', editMode ? resourceDetails.careers : careerGroups);
  };


  const handleCommunityInstiAdmin = () => {
    setValue('organization', editMode ? resourceDetails.organization : user.organization);
    setValue('organizationType', editMode ? resourceDetails.userTypes : userTypeList);
    handleGradesDynamically(true);
    setValue('institutions', editMode ? resourceDetails.institutions : handleOrgTypeFieldChange());
    setValue('careers', careerGroups);
  };

  const handleDistrictAdmins = () => {
    setValue('organization', editMode ? resourceDetails.organization : null);
    setValue('organizationType', editMode ? resourceDetails.userTypes : userTypeList);
    handleGradesDynamically(true);
    setValue('institutions', editMode ? resourceDetails.institutions : handleOrgTypeFieldChange());
    setValue('careers', careerGroups);
  };

  const handleSuperAdmins = () => {
    setValue('organization', resourceDetails.organization ? resourceDetails.organization : '');
    setValue('organizationType', resourceDetails.userTypes ? resourceDetails.userTypes : '');
    handleGradesDynamically(true);
    setValue('institutions', resourceDetails.institutions ? resourceDetails.institutions : '');
    setValue('careers', resourceDetails.careers ? resourceDetails.careers : '');
  };


  const prePopulateValues = () => {
    setValue('title', editMode ? resourceDetails.title : '');
    setValue('description', editMode ? resourceDetails.description : '');
    setValue('link', editMode ? resourceDetails.url : '');
    setValue('startDate', editMode ? resourceDetails.startDate : dayjs().format('YYYY-MM-DD'));
    setValue('endDate', editMode ? resourceDetails.endDate : getEndOfNextSchoolYear());
    setValue('zipcode', resourceDetails.zipcode ? resourceDetails.zipcode : '');
    setValue('tags', resourceDetails.tags ? resourceDetails.tags : '');


    if (editMode && resourceDetails) {
      setFundingCheckboxes({
        static: Boolean(resourceDetails.dollarAmount),
        range: Boolean(resourceDetails.dollarAmountRange && !!resourceDetails.dollarAmountRange.length),
        varies: !resourceDetails.dollarAmount && !resourceDetails.dollarAmountRange,
      });
      setAvailableCheckboxes({
        static: Boolean(resourceDetails.available),
        range: Boolean(resourceDetails.availableRange && !!resourceDetails.availableRange.length),
        varies: !resourceDetails.available && !resourceDetails.availableRange,
      });
    }

    setValue('dollarAmount', resourceDetails.dollarAmount || 1);
    setValue('dollarAmountRange.rangeBegin', (resourceDetails.dollarAmountRange && !!resourceDetails.dollarAmountRange.length) ? resourceDetails.dollarAmountRange[0] : 1);
    setValue('dollarAmountRange.rangeEnd', (resourceDetails.dollarAmountRange && !!resourceDetails.dollarAmountRange.length) ? resourceDetails.dollarAmountRange[1] : 2);
    setValue('numberAvailable', resourceDetails.available || 1);
    setValue('numberAvailableRange.rangeBegin', (resourceDetails.availableRange && !!resourceDetails.availableRange.length) ? resourceDetails.availableRange[0] : 1);
    setValue('numberAvailableRange.rangeEnd', (resourceDetails.availableRange && !!resourceDetails.availableRange.length) ? resourceDetails.availableRange[1] : 2);

    // If edit mode, let the fields that are in the details decide whether it should show or not, else use the function made for this kind finding out whether to show it or not.
    if (editMode) {
      setShowGrades(resourceDetails.userTypes ? resourceDetails.userTypes.some((ut: UserType) => ut.type === OrganizationType.MiddleSchool || ut.type === OrganizationType.HighSchool) : false);
    }
    else {
      handleGradesDynamically();
    }
    setInApp(Boolean(resourceDetails.inApp));
    setTuitionWaiver(editMode && resourceDetails.type === ResourceType.tuition);
  };


  const handleOrgTypeFieldChange = () => {
    // We have to use getValues here since the types that is passed in does
    const fieldValue = getValues('organizationType');

    // The sending to org list will change based on what is selected.
    let filteredInstiList: any[] = [];
    if (fieldValue && fieldValue.some((ele: UserType) => ele.label === 'Select All')) {
      filteredInstiList = organizationList.filter((org: Organization) => org.type !== OrganizationType.Community);
    }
    else {
      filteredInstiList = organizationList.filter((org: Organization) => fieldValue && fieldValue.some((u: UserType) => org.type === OrganizationType.UniversityCollege ? u.type === OrganizationType.Adult : org.type === u.type));
    }

    if (fieldValue && !fieldValue.length) {
      filteredInstiList = [];
      setShowGrades(false);
    }
    setShowGrades(fieldValue && fieldValue.some((ut: UserType) => ut.type === OrganizationType.MiddleSchool || ut.type === OrganizationType.HighSchool));

    setSendingToOrgList(filteredInstiList);
    setValue('institutions', filteredInstiList);
    handleGradesDynamically();
    return filteredInstiList;
  };



  const createStaticGradeList = () => {
    const arr: any[] = [];
    const newYearIndex = (dayjs() < dayjs(`${END_OF_SCHOOL_YEAR}/${dayjs().year()}`)) ? 0 : 1;
    for (let i = 0; i <= 6; i++) {
      arr.unshift({
        id: new Date().getFullYear() + i + newYearIndex,
        label: ((12 - i) + 'th').toString(),
      });
    }
    setStaticGradeList(arr);
    setMiddleSchoolGrades(filterStaticGradelist(arr, [
      '6th',
      '7th',
      '8th',
      '9th',
    ]));
    setHighSchoolGrades(filterStaticGradelist(arr, [
      '9th',
      '10th',
      '11th',
      '12th',
    ]));
  };

  const filterStaticGradelist = (array: any[], grades: string[]) => {
    return array.filter(grade => grades.includes(grade.label));
  };

  const handleGradesDynamically = (shouldChange?: boolean) => {
    const orgTypes = getValues('organizationType');

    let returnGradeList: ListMenuItem[] = [];

    if (orgTypes && !!orgTypes.length) {
      // For when middle school is selected
      (orgTypes.some((o: UserType) => o.type === OrganizationType.MiddleSchool)) && (returnGradeList = returnGradeList.concat(middleSchoolGrades));
      // For when high school is selected
      (orgTypes.some((o: UserType) => o.type === OrganizationType.HighSchool)) && (returnGradeList = returnGradeList.concat(highSchoolGrades));
    }

    returnGradeList = uniqWith(returnGradeList, isEqual);
    setValue('grade', (shouldChange && editMode) ? staticGradeList.filter((ele) => resourceDetails.graduationYears.some((x) => ele.id === x)) : returnGradeList);
    setDynamicGradeList(returnGradeList);
    return returnGradeList;
  };


  const handleVisibleCheckboxFields = (checkboxes: any, type: string, detailsField?: string) => {
    if (editMode) {
      if (type == 'static') {
        if (detailsField == 'funding') {
          return Boolean(resourceDetails && resourceDetails.dollarAmount);

        }
        else return Boolean(resourceDetails && resourceDetails.available);

      }
      else if (type == 'range') {
        if (detailsField == 'funding') {
          return Boolean(resourceDetails && resourceDetails.dollarAmountRange && !!resourceDetails.dollarAmountRange.length);

        }
        else return Boolean(resourceDetails && resourceDetails.availableRange && !!resourceDetails.availableRange.length);

      }
      else {
        if (detailsField == 'funding') {
          return Boolean(resourceDetails && !resourceDetails.dollarAmount && !resourceDetails.dollarAmountRange);

        }
        else return Boolean(resourceDetails && !resourceDetails.available && !resourceDetails.availableRange);

      }
    }

    if (type == 'static') {
      return checkboxes.static;
    }
    else if (type == 'range') {
      return checkboxes.range;
    }
    else return checkboxes.varies;
  };

  const formValidationSchema = yup.object().shape({
    title: yup.string()
      .required(`${startCase(resourceType)} name is required.`),
    description: yup.string()
      .required('Description is required.'),
    organization: yup.object()
      .required('Institution / Organization is required.')
      .typeError('Institution / Organization is required.'),
    link: yup.string()
      .required('Application link is required.')
      .isValidUrl(),
    startDate: yup.string()
      .test(
        'start_date_test',
        'Start date must be before end date',
        (value, parent) => parent.parent.endDate ? dayjs(parent.parent.endDate).isAfter(value) : true
      ),
    endDate: yup.string()
      .test(
        'end_date_test',
        'End date must be after start date',
        (value, parent) => value ? dayjs(value).isAfter(parent.parent.startDate) : true
      ),
    zipcode: yup.object().nullable(),
    dollarAmount: (fundingCheckboxes.static) ? yup.number() : yup.number().nullable(),
    dollarAmountRange: (fundingCheckboxes.range) ? (
      yup.object().shape({
        rangeBegin: yup.number()
          .typeError('Min range is required.'),
        rangeEnd: yup.number()
          .typeError('Max range is required.')
          .moreThan(yup.ref('rangeBegin'), 'Must be greater than min range.'),
      })
    ) : (yup.object().default(null).nullable()),
    numberAvailable: (handleVisibleCheckboxFields(availableCheckboxes, 'static', 'available')) ? yup.number() : yup.number().nullable(),
    numberAvailableRange: (handleVisibleCheckboxFields(availableCheckboxes, 'range', 'available')) ? (
      yup.object().shape({
        rangeBegin: yup.number()
          .typeError('Min range is required.'),
        rangeEnd: yup.number()
          .typeError('Max range is required.')
          .moreThan(yup.ref('rangeBegin'), 'Must be greater than min range.'),
      })
    ) : (yup.object().nullable()),
    userAvailability: yup.bool(),
    organizationType: yup.array()
      .min(1, 'Organization type is required')
      .required('Organization type is required'),
    grade: yup.array()
      .when('organizationType', {
        is: (organizationType: UserType[]) => (
          organizationType && !!organizationType.length && organizationType.some((o: UserType) => o.type === OrganizationType.HighSchool || o.type === OrganizationType.MiddleSchool)
        ),
        then: yup.array()
          .min(1, 'Grades are required')
          .required('Grades are required'),
      }),
    institutions: yup.array()
      .min(1, 'Institutions are required')
      .required('Institutions are required'),
    careers: yup.array()
      .min(1, 'Careers are required')
      .required('Careers are required'),
    tags: yup.array().nullable(),
  }).required();

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const { handleSubmit, setValue, getValues, formState: { errors } } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    const newResourceData = {
      ...values,
      inApp,
      fundingCheckboxes,
      availableCheckboxes,
      type: handleResourceType(),
      userTypeIds: values.organizationType.map((e: UserType) => e.id),
      tags: (values.tags && !!values.tags.length) ? values.tags.map((e: ResourceTag) => e.id) : [],
    };

    if (editMode) {
      dispatch(updateResource({
        id: resourceDetails.id,
        type: resourceType,
        values: newResourceData,
      }))
        .unwrap()
        .then(() => {
          dispatch(setAlert({
            type: 'success',
            message: `Successfully updated ${resourceType}.`,
          }));
          handlePageToList();
        })
        .catch(() => {
          dispatch(setAlert({
            type: 'error',
            message: `Unable to update ${resourceType}.`,
          }));
        });
    }
    else {
      dispatch(createResource({
        type: resourceType,
        values: newResourceData,
      }))
        .unwrap()
        .then(() => {
          dispatch(setAlert({
            type: 'success',
            message: `Successfully created ${resourceType}.`,
          }));
          handlePageToList();
        })
        .catch(() => {
          dispatch(setAlert({
            type: 'error',
            message: `Unable to create ${resourceType}.`,
          }));
        });
    }
  };

  const handleResourceType = () => {
    if (resourceType == ResourceType.scholarship) {
      if (tuitionWaiver) {
        return ResourceType.tuition;
      }
      else return ResourceType.scholarship;
    }
    else if (resourceType == ResourceType.internship) {
      return ResourceType.internship;
    }
    else return ResourceType.other;
  };

  const handleAmountCheckboxes = (event: React.ChangeEvent<HTMLInputElement>, stateObj: any) => {
    const tempObj: any = {
    };
    // Builds an object to set the fundingCheckboxes state with.
    Object.keys(stateObj).map((ele: any) => {
      if (event.target.name === ele) {
        tempObj[event.target.name] = event.target.checked;
      }
      else tempObj[ele] = false;
    });

    if (stateObj === fundingCheckboxes) {
      setFundingCheckboxes(tempObj);
    }
    else {
      setAvailableCheckboxes(tempObj);
    }
  };

  const handlePageToList = () => {
    if (addAnother) return window.location.reload();


    if (resourceType == ResourceType.scholarship || resourceType == ResourceType.tuition) {
      navigate(AppRoutes.scholarships.path);
    }
    else if (resourceType == ResourceType.internship || resourceType == startCase(ResourceType.internship)) {
      navigate(AppRoutes.internships.path);
    }
    else {
      navigate(AppRoutes.opportunities.path);
    }
  };

  const onDeleteResource = () => {
    setShowDeleteConfirmDialog(false);
    dispatch(deleteResource({
      id: resourceDetails.id,
      type: resourceType,
    }))
      .unwrap()
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully deleted resource.',
        }));
        handlePageToList();
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to delete resource.',
        }));
      });
  };

  const handleSelectAllUsers = () => {
    if (user.isAdmin || user.isDistrictAdmin || user.isInstitutionAdmin || user.isCommunity) {
      setValue('organizationType', !userAvailability ? userTypeList : []);
      setValue('institutions', !userAvailability ? organizationList : []);
    }

    setValue('grade', !userAvailability ? handleGradesDynamically() : []);
    // Since counselors and keycontacts cant change their org type, the grades visibility does not change
    if ((!user.isCounselor && !user.isKeyContact) || user.isInstitutionAdmin) {
      setShowGrades(!userAvailability);
    }
    setValue('careers', !userAvailability ? careerGroups : []);

    setUserAvailability(!userAvailability);
  };


  return (
    <div className="card-background create-resource-container">
      <h3>{startCase(resourceType)} Details</h3>
      {(resourceDetailsStatus === StateStatus.IDLE && pageLoaded) ? (
        <form className="form-content flex_row_jbetween" onSubmit={handleSubmit(submitForm)}>
          <FormProvider {...methods}>
            <TextInput
              containerClass="half-width"
              name="title"
              errorMessage={errors?.title?.message}
              label={`${resourceType?.toUpperCase()} NAME`}
              type="text"
              size="small"
              required
            />

            <TextInput
              containerClass="full-width"
              name="description"
              label="DESCRIPTION"
              errorMessage={errors?.description?.message}
              type="textarea"
              size="small"
              required
              multiLine
              rows={8}
            />

            <DropdownSelect
              containerClass="half-width"
              name="organization"
              label="INSTITUTION / ORGANIZATION"
              itemList={creatingOrgList}
              disabled={!canUseAuthComponent(user.roles, [
                UserRole.admin,
                UserRole.districtAdmin,
              ])}
              errorMessage={errors?.organization?.message}
              size="small"
              required
            />

            <TextInput
              containerClass="half-width"
              name="link"
              label="APPLICATION LINK"
              errorMessage={errors?.link?.message}
              type="text"
              size="small"
              required
            />

            <TextInput
              containerClass="half-width"
              name="startDate"
              label="START DATE"
              errorMessage={errors?.startDate?.message}
              type="date"
              size="small"
            />

            <TextInput
              containerClass="half-width"
              name="endDate"
              label="END DATE"
              errorMessage={errors?.endDate?.message}
              type="date"
              size="small"
            />

            <DropdownSelect
              containerClass="half-width"
              name="zipcode"
              label="ZIP CODE"
              itemList={zipcodeList}
              errorMessage={errors?.zipcode?.message}
              size="small"
            />

            <DropdownSelect
              containerClass="half-width"
              name="tags"
              label="TAGS"
              itemList={tags}
              errorMessage={errors?.tags?.message}
              size="small"
              multiple
              tooltip="Click for tags list"
              tooltipClick={() => setResourceTagsModalOpen(true)}
            />

            <div className="full-width">

              {(resourceType == ResourceType.scholarship || resourceType == ResourceType.tuition) && (
                <p className="static-input-label checkbox-label"> AMOUNT OF FUNDING </p>
              )}

              {(resourceType == ResourceType.scholarship || resourceType == ResourceType.tuition) && (
                <div className="full-width flex_row">
                  <FormGroup className="full-width flex_row">
                    <FormControlLabel
                      label="Static Amount"
                      control={
                        <Checkbox
                          checked={fundingCheckboxes.static}
                          onChange={(e) => handleAmountCheckboxes(e, fundingCheckboxes)}
                          name="static"
                        />
                      }
                    />
                    <FormControlLabel
                      label="Range of amounts"
                      control={
                        <Checkbox
                          checked={fundingCheckboxes.range}
                          onChange={(e) => handleAmountCheckboxes(e, fundingCheckboxes)}
                          name="range"
                        />
                      }
                    />
                    <FormControlLabel
                      label="Amount offered varies"
                      control={
                        <Checkbox
                          checked={fundingCheckboxes.varies}
                          onChange={(e) => handleAmountCheckboxes(e, fundingCheckboxes)}
                          name="varies"
                        />
                      }
                    />
                  </FormGroup>
                </div>
              )}

              {(resourceType == ResourceType.scholarship || resourceType == ResourceType.tuition) && (
                <div className="full-width">
                  <div className="half-width">
                    {(fundingCheckboxes.static) ? (
                      <TextInput
                        containerClass="quarter-width"
                        name="dollarAmount"
                        errorMessage={errors?.dollarAmount?.message}
                        type="number"
                        size="small"
                        InputProps={{
                          inputProps: {
                            min: 1,
                          },
                        }}
                      />
                    ) : (fundingCheckboxes.range) ? (
                      <div className="full-width flex_row_jcenter_astart">
                        <TextInput
                          containerClass="quarter-width"
                          name="dollarAmountRange.rangeBegin"
                          defaultValue={(editMode && resourceDetails.dollarAmountRange && !!resourceDetails.dollarAmountRange.length) ? resourceDetails.dollarAmountRange[0] : 0}
                          errorMessage={(errors?.dollarAmountRange as any)?.rangeBegin?.message}
                          type="number"
                          size="small"
                          InputProps={{
                            inputProps: {
                              min: 1,
                            },
                          }}
                        />
                        &nbsp;
                        -
                        &nbsp;
                        <TextInput
                          containerClass="quarter-width"
                          name="dollarAmountRange.rangeEnd"
                          defaultValue={(editMode && resourceDetails.dollarAmountRange && !!resourceDetails.dollarAmountRange.length) ? resourceDetails.dollarAmountRange[1] : 0}
                          errorMessage={(errors?.dollarAmountRange as any)?.rangeEnd?.message}
                          type="number"
                          size="small"
                          InputProps={{
                            inputProps: {
                              min: 1,
                            },
                          }}
                        />
                      </div>
                    ) : (
                      <div className="full-width"> <br /><br /> </div>
                    )}
                  </div>
                </div>
              )}

              {(resourceType == ResourceType.scholarship || resourceType == ResourceType.tuition) && (
                <p className="static-input-label checkbox-label"> SCHOLARSHIPS AVAILABLE </p>
              )}

              {(resourceType == ResourceType.scholarship || resourceType == ResourceType.tuition) && (
                <div className="full-width flex_row">
                  <FormGroup className="full-width flex_row">
                    <FormControlLabel control={
                      <Checkbox
                        checked={availableCheckboxes.static}
                        onChange={(e) => handleAmountCheckboxes(e, availableCheckboxes)}
                        name="static" />
                    }
                    label="Static Amount" />
                    <FormControlLabel control={
                      <Checkbox
                        checked={availableCheckboxes.range}
                        onChange={(e) => handleAmountCheckboxes(e, availableCheckboxes)}
                        name="range" />
                    }
                    label="Range of amounts" />
                    <FormControlLabel control={
                      <Checkbox
                        checked={availableCheckboxes.varies}
                        onChange={(e) => handleAmountCheckboxes(e, availableCheckboxes)}
                        name="varies" />
                    }
                    label="Amount offered varies" />
                  </FormGroup>
                </div>
              )}

              {(resourceType == ResourceType.scholarship || resourceType == ResourceType.tuition) && (
                <div className="full-width">
                  <div className="half-width">
                    {availableCheckboxes.static ? (
                      <TextInput
                        containerClass="quarter-width"
                        name="numberAvailable"
                        type="number"
                        size="small"
                        InputProps={{
                          inputProps: {
                            min: 1,
                            defaultValue: 1,
                          },
                        }}
                      />
                    ) : (availableCheckboxes.range) ? (
                      <div className="full-width flex_row_jcenter_astart">
                        <TextInput
                          containerClass="quarter-width"
                          name="numberAvailableRange.rangeBegin"
                          defaultValue={(editMode && resourceDetails.availableRange && !!resourceDetails.availableRange.length) ? resourceDetails.availableRange[0] : 1}
                          errorMessage={(errors?.numberAvailableRange as any)?.rangeBegin?.message}
                          type="number"
                          size="small"
                          InputProps={{
                            inputProps: {
                              min: 1,
                            },
                          }}
                        />
                        &nbsp;
                        -
                        &nbsp;
                        <TextInput
                          containerClass="quarter-width"
                          name="numberAvailableRange.rangeEnd"
                          defaultValue={(editMode && resourceDetails.availableRange && !!resourceDetails.availableRange.length) ? resourceDetails.availableRange[1] : 1}
                          errorMessage={(errors?.numberAvailableRange as any)?.rangeEnd?.message}
                          type="number"
                          size="small"
                          InputProps={{
                            inputProps: {
                              min: 1,
                            },
                          }}
                        />
                      </div>
                    ) : <div className="full-width"> <br /><br /> </div>}
                  </div>
                </div>
              )}
            </div>

            <div className="full-width">
              <p className="static-input-label with-description">USER AVAILABILITY</p>
              <p className="field-description">
                Use the dropdowns to select which Organization Types, Grades, Institutions, and Careers will see this resource. By default, all options are selected.
              </p>
              <FormControlLabel
                label="All Users"
                control={
                  <Checkbox checked={userAvailability} onChange={handleSelectAllUsers} name="All Users" />
                }
              />
            </div>

            <DropdownSelect
              containerClass={showGrades ? 'half-width' : 'full-width'}
              inputClass={showGrades ? 'full-width' : 'half-width'}
              name="organizationType"
              label="ORGANIZATION TYPE"
              itemList={userTypeList}
              errorMessage={errors?.organizationType?.message}
              onChange={(e) => {
                handleOrgTypeFieldChange();
                setUserAvailability(false);
              }}
              disabled={!canUseAuthComponent(user.roles, [
                UserRole.admin,
                UserRole.districtAdmin,
                UserRole.community,
              ])}
              size="small"
              required
              multiple
              selectAll
            />

            {showGrades && (
              <DropdownSelect
                containerClass="half-width"
                name="grade"
                label="GRADE"
                itemList={dynamicGradeList}
                onChange={() => setUserAvailability(false)}
                errorMessage={errors?.grade?.message}
                size="small"
                required
                multiple
                selectAll
              />
            )}

            <DropdownSelect
              containerClass="half-width"
              name="institutions"
              label="INSTITUTIONS"
              itemList={sendingToOrgList}
              disabled={!canUseAuthComponent(user.roles, [
                UserRole.admin,
                UserRole.districtAdmin,
                UserRole.community,
              ])}
              errorMessage={errors?.institutions?.message}
              onChange={() => setUserAvailability(false)}
              size="small"
              required
              multiple
              selectAll
            />

            <DropdownSelect
              containerClass="half-width"
              name="careers"
              label="CAREERS"
              itemList={careerGroups}
              errorMessage={errors?.careers?.message}
              onChange={() => setUserAvailability(false)}
              size="small"
              multiple
              required
              selectAll
            />

            {(resourceType == ResourceType.scholarship || resourceType == ResourceType.tuition) && (
              <span className="full-width flex_acenter">
                <FormControlLabel
                  className="checkbox-label"
                  label="In app scholarship"
                  control={
                    <Checkbox
                      checked={inApp}
                      onChange={() => setInApp(!inApp)} />
                  }
                />
                <Tooltip
                  title="In app scholarships are sent to the student's scholarship counselors for selection. Please contact Keys to Success before you select this."
                  placement="right"
                  arrow
                >
                  <img src={AppAssetPaths.icons.INFO} alt="tooltip" />
                </Tooltip>
              </span>
            )}

            {(resourceType == ResourceType.scholarship || resourceType == ResourceType.tuition) && (
              <span className="full-width flex_acenter">
                <FormControlLabel
                  className="checkbox-label"
                  label="Tuition waiver (non-scholarship)"
                  control={
                    <Checkbox
                      checked={tuitionWaiver}
                      onChange={() => setTuitionWaiver(!tuitionWaiver)} />
                  }
                />
                <Tooltip
                  title="Select this if this is not a scholarship, but a tuition free voucher. This will be shown when the student views the scholarship on the app."
                  placement="right"
                  arrow
                >
                  <img src={AppAssetPaths.icons.INFO} alt="tooltip" />
                </Tooltip>
              </span>
            )}

            <div className="bottom-buttons flex_row_jbetween">
              {editMode ? (
                <Button
                  variant="outlined"
                  onClick={() => setShowDeleteConfirmDialog(true)}
                >
                  Delete Resource
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={handlePageToList}
                >
                  Cancel
                </Button>
              )}

              <div className="flex_row_jend">
                {
                  editMode ? (
                    <Button
                      variant="outlined"
                      onClick={handlePageToList}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Button
                      className="submit-button form-button"
                      type="submit"
                      variant="outlined"
                      size="large"
                      disableElevation
                      onClick={() => setAddAnother(true)}
                    >
                      Save and Start Another
                    </Button>
                  )
                }

                <SubmitButton
                  className="submit-button form-button"
                  text="Save"
                  variant="contained"
                  size="large"
                  disableElevation
                />
              </div>
            </div>
          </FormProvider>
        </form>
      ) : (
        <div className="flex_jcenter_acenter loading-indicator">
          <CircularProgress size="50px" />
        </div>
      )}

      <DeleteConfirmationModal
        open={showDeleteConfirmDialog}
        deleteItemText={resourceType}
        onConfirm={onDeleteResource}
        onClose={() => setShowDeleteConfirmDialog(false)}
      />

      <ResourceTagsModal
        open={resourceTagsModalOpen}
        onClose={() => setResourceTagsModalOpen(false)}
      />
    </div>
  );
};

export default CreateEditResource;
