/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { GridSortModel } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { secondaryOrganizationTypeList } from '../../util/dropdown';
import { ListMenuItem } from '../../components/forms/form.types';


export interface AnnouncementListQueryParams {
  offset: number;
  limit?: number | string;
  search?: string;
  sortModel?: GridSortModel;
}

export interface Announcement {
  id?: number;
  message?: string;
  startDate?: string;
  endDate?: string;
  educationLevels?: ListMenuItem[];
  createdAt?: string;
  userTypeIds?: number[];
  userTypes?: string;
  roles?: string;
  status?: string;
  link?: string;
  isForAdmin?: boolean;
}

export const EmptyAnnouncement: Announcement = {
  id: 0,
  message: '',
  startDate: '',
  endDate: '',
  educationLevels: [],
  userTypeIds: [],
  userTypes: '',
  createdAt: '',
  status: '',
  roles: '',
  link: '',
  isForAdmin: false,
};

const getUserTypes = (data: any[]) => {
  const isAdmin = data.find(item => item.is_admin_type);

  if (isAdmin) {
    return 'Admin';
  }

  return data.map(item => item.name).join(', ');
};

const getStatus = (data: any) => {
  const currentDate = dayjs().format('YYYY-MM-DD');
  if (dayjs(currentDate).isAfter(data.end_date)) {
    return 'expired';
  }

  if (dayjs(currentDate).isBefore(data.start_date)) {
    return 'inactive';
  }

  return 'active';
};

const getRoles = (data: any[]) => {
  const isAdmin = data.find(item => item.is_admin_type);

  if (!isAdmin) {
    return '';
  }

  const roles = data.map(item => item.name).join(', ');
  return roles;
};

const getEducationLevels = (educations: any) => {
  const educationLevels = secondaryOrganizationTypeList.filter(item => educations.includes(item.id));

  return educationLevels;
};

const checkForAdmin = (userTypes: any) => {
  const isAdmin = userTypes.find((item: any) => item.is_admin_type);

  return !!isAdmin;
};

export const ConstructAnnouncement = (data: any): Announcement => {
  const isForAdmin = checkForAdmin(data.user_types || []);
  return {
    id: data.id,
    message: data.message,
    startDate: data.start_date,
    endDate: data.end_date,
    educationLevels: getEducationLevels(data.education_levels || []),
    userTypeIds: data.user_type_ids || [],
    userTypes: getUserTypes(data.user_types || []),
    status: getStatus(data),
    roles: getRoles(data.user_types || []),
    link: data.link,
    isForAdmin,
    createdAt: data.created_at,
  };
};
