import { AuthComponent } from '../../../app/app.types';
import { UserRole } from '../../user/user.model';

export const DashboardLatestAnnouncements: AuthComponent = {
  authorizedRoles: [
    UserRole.admin,
  ],
};

export const DashboardAnnouncementBanner: AuthComponent = {
  authorizedRoles: [
    UserRole.districtAdmin,
    UserRole.keyContact,
    UserRole.counselor,
    UserRole.community,
  ],
};
