import { createSlice } from '@reduxjs/toolkit';
import { StateStatus } from '../../app/app.types';
import { RootState } from '../../app/store';
import {
  LatestAnnouncement,
  ApprovalSummaryByType,
  ExpiringResource,
  ScholarshipApplicationsByType,
  CurrentMasterKeysByType,
  AdminAnnouncement
} from './dashboard.model';
import {
  getLatestAnnouncements,
  getApprovalsSummary,
  getExpiringResources,
  getCurrentMasterKeys,
  getScholarshipsBySchool,
  getAdminAnnouncement,
  setLastSeenAnnouncementId
} from './dashboard.thunk';


/* State */

export interface DashboardState {
  approvalSummary: {
    status: StateStatus,
    data: ApprovalSummaryByType
  },
  scholarshipApplicationsBySchool: {
    status: StateStatus,
    data: ScholarshipApplicationsByType
  }
  latestAnnouncements: {
    status: StateStatus,
    data: LatestAnnouncement[]
  },
  expiringResources: {
    status: StateStatus,
    data: ExpiringResource[]
  },
  currentMasterKeys: {
    status: StateStatus,
    data: CurrentMasterKeysByType
  },
  adminAnnouncement: {
    status: StateStatus,
    data?: AdminAnnouncement
  }
}

const initialState: DashboardState = {
  approvalSummary: {
    status: 'idle' as StateStatus,
    data: {
      highSchool: {
        resources: 0,
        scholarshipApplications: 0,
        studentApprovals: 0,
        studentTransfers: 0,
        notifications: 0,
      },
      middleSchool: {
        resources: 0,
        scholarshipApplications: 0,
        studentApprovals: 0,
        studentTransfers: 0,
        notifications: 0,
      },
      adult: {
        resources: 0,
        scholarshipApplications: 0,
        notifications: 0,
      },
    },
  },
  scholarshipApplicationsBySchool: {
    status: 'idle' as StateStatus,
    data: {
      highSchool: [],
      middleSchool: [],
      adult: [],
    },
  },
  latestAnnouncements: {
    status: 'idle' as StateStatus,
    data: [],
  },
  expiringResources: {
    status: 'idle' as StateStatus,
    data: [],
  },
  currentMasterKeys: {
    status: 'idle' as StateStatus,
    data: {
      highSchool: undefined,
      middleSchool: undefined,
    },
  },
  adminAnnouncement: {
    status: 'idle' as StateStatus,
    data: undefined,
  },
};


/* Reducer */

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      // Approvals Summary
      .addCase(getApprovalsSummary.pending, (state) => {
        state.approvalSummary.status = StateStatus.LOADING;
      })
      .addCase(getApprovalsSummary.fulfilled, (state, action) => {
        state.approvalSummary.status = StateStatus.IDLE;
        state.approvalSummary.data = action.payload;
      })
      .addCase(getApprovalsSummary.rejected, (state) => {
        state.approvalSummary.status = StateStatus.FAILED;
      })
      // Scholarships Applications By School
      .addCase(getScholarshipsBySchool.pending, (state) => {
        state.scholarshipApplicationsBySchool.status = StateStatus.LOADING;
      })
      .addCase(getScholarshipsBySchool.fulfilled, (state, action) => {
        state.scholarshipApplicationsBySchool.status = StateStatus.IDLE;
        state.scholarshipApplicationsBySchool.data = action.payload;
      })
      .addCase(getScholarshipsBySchool.rejected, (state) => {
        state.scholarshipApplicationsBySchool.status = StateStatus.FAILED;
      })
      // Latest Announcements
      .addCase(getLatestAnnouncements.pending, (state) => {
        state.latestAnnouncements.status = StateStatus.LOADING;
      })
      .addCase(getLatestAnnouncements.fulfilled, (state, action) => {
        state.latestAnnouncements.status = StateStatus.IDLE;
        state.latestAnnouncements.data = action.payload;
      })
      .addCase(getLatestAnnouncements.rejected, (state) => {
        state.latestAnnouncements.status = StateStatus.FAILED;
      })
      // Expiring Resources
      .addCase(getExpiringResources.pending, (state) => {
        state.expiringResources.status = StateStatus.LOADING;
      })
      .addCase(getExpiringResources.fulfilled, (state, action) => {
        state.expiringResources.status = StateStatus.IDLE;
        state.expiringResources.data = action.payload;
      })
      .addCase(getExpiringResources.rejected, (state) => {
        state.expiringResources.status = StateStatus.FAILED;
      })
      // Current Master Keys
      .addCase(getCurrentMasterKeys.pending, (state) => {
        state.currentMasterKeys.status = StateStatus.LOADING;
      })
      .addCase(getCurrentMasterKeys.fulfilled, (state, action) => {
        state.currentMasterKeys.status = StateStatus.IDLE;
        state.currentMasterKeys.data = action.payload;
      })
      .addCase(getCurrentMasterKeys.rejected, (state) => {
        state.currentMasterKeys.status = StateStatus.FAILED;
      })
      // Admin Announcements
      .addCase(getAdminAnnouncement.pending, (state) => {
        state.adminAnnouncement.status = StateStatus.LOADING;
      })
      .addCase(getAdminAnnouncement.fulfilled, (state, action) => {
        state.adminAnnouncement.status = StateStatus.IDLE;
        state.adminAnnouncement.data = action.payload;
      })
      .addCase(getAdminAnnouncement.rejected, (state) => {
        state.adminAnnouncement.status = StateStatus.FAILED;
      })
      .addCase(setLastSeenAnnouncementId.pending, (state) => {
        state.adminAnnouncement.status = StateStatus.LOADING;
      })
      .addCase(setLastSeenAnnouncementId.fulfilled, (state) => {
        state.adminAnnouncement.status = StateStatus.IDLE;
        state.adminAnnouncement.data = undefined;
      })
      .addCase(setLastSeenAnnouncementId.rejected, (state) => {
        state.adminAnnouncement.status = StateStatus.FAILED;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const { } = dashboardSlice.actions;


/* Selectors */

const selectApprovalSummaryStatus = (state: RootState): StateStatus => state.dashboard.approvalSummary.status;
const selectApprovalSummary = (state: RootState): ApprovalSummaryByType => state.dashboard.approvalSummary.data;
const selectScholarshipApplicationsBySchoolStatus = (state: RootState): StateStatus => state.dashboard.scholarshipApplicationsBySchool.status;
const selectScholarshipApplicationsBySchool = (state: RootState): ScholarshipApplicationsByType => state.dashboard.scholarshipApplicationsBySchool.data;
const selectLatestAnnouncementsStatus = (state: RootState): StateStatus => state.dashboard.latestAnnouncements.status;
const selectLatestAnnouncements = (state: RootState): LatestAnnouncement[] => state.dashboard.latestAnnouncements.data;
const selectExpiringResourcesStatus = (state: RootState): StateStatus => state.dashboard.expiringResources.status;
const selectExpiringResources = (state: RootState): ExpiringResource[] => state.dashboard.expiringResources.data;
const selectCurrentMasterKeysStatus = (state: RootState): StateStatus => state.dashboard.currentMasterKeys.status;
const selectCurrentMasterKeys = (state: RootState): CurrentMasterKeysByType => state.dashboard.currentMasterKeys.data;
const selectAdminAnnouncementStatus = (state: RootState): StateStatus => state.dashboard.adminAnnouncement.status;
const selectAdminAnnouncement = (state: RootState): AdminAnnouncement | undefined => state.dashboard.adminAnnouncement.data;

export const dashboardSelectors = {
  selectApprovalSummaryStatus,
  selectApprovalSummary,
  selectScholarshipApplicationsBySchoolStatus,
  selectScholarshipApplicationsBySchool,
  selectLatestAnnouncementsStatus,
  selectLatestAnnouncements,
  selectExpiringResourcesStatus,
  selectExpiringResources,
  selectCurrentMasterKeysStatus,
  selectCurrentMasterKeys,
  selectAdminAnnouncementStatus,
  selectAdminAnnouncement,
};

export default dashboardSlice.reducer;
