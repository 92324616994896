import React from 'react';
import ExpandableCard from '../ExpandableCard';
import {
  UserCourse, UserPathway
} from '../../../features/pathways/userPathways.model';
import StatusLabel, { Status } from '../../StatusLabel';

import './PathwaysTable.scss';


interface PathwaysTableProps {
  pathways?: UserPathway[];
  defaultExpanded?: boolean;
}

const PathwaysTable: React.FC<PathwaysTableProps> = ({ pathways, defaultExpanded }) => {
  const EMPTY = '-';

  if (pathways === undefined) {
    pathways = [];
  }

  const getCompletionStatus = (credits?: number, totalCredits?: number): Status => {
    if (!credits || !totalCredits) {
      return Status.inProgress;
    }
    else if (credits === totalCredits) {
      return Status.complete;
    }
    else {
      return Status.inProgress;
    }
  };

  const getDataRow = (header: boolean, key?: number, status?: Status, title?: string, courses?: UserCourse[]) => (
    <div key={key} className={`pathways-data-row flex ${header ? 'header' : ''}`}>
      <div className="pathways-data-cell flex_column_astart">
        <div className="pathways-data-label">CTE Pathway Name</div>
        <div className="pathways-data-content proper">{ title || EMPTY }</div>
      </div>
      <div className="pathways-data-cell flex_column_astart">
        <div className="pathways-data-label">Courses</div>
        <div className="pathways-data-content proper">
          <ul>
            { courses?.map((c, i) => <li key={i}>{ c.name }</li>) }
          </ul>
        </div>
      </div>
      <div className="pathways-data-cell flex_column_astart">
        <div className="pathways-data-label">Status</div>
        <div className="pathways-data-content"><StatusLabel status={status} /></div>
      </div>
    </div>
  );

  const getDetails = () => (
    <div className="pathways-data-wrapper flex_column">
      { getDataRow(true) }
      { !!pathways?.length && pathways.map((p, i) => getDataRow(false, i, getCompletionStatus(p.creditsCompleted, p.totalCredits), p.name, p.courses)) }
    </div>
  );

  return (
    <ExpandableCard
      className="user-pathways-table"
      summary={<span>CTE Pathways</span>}
      details={getDetails()}
      expandable
      defaultExpanded={defaultExpanded}
    />
  );
};

export default PathwaysTable;
