import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';


/* Type */

export interface PopupAlert {
  show: boolean;
  type: 'success' | 'info' | 'warning' | 'error' | undefined;
  message: string;
}


/* State */

export interface AlertState {
  alert: PopupAlert;
}

const emptyAlert: PopupAlert = {
  show: false,
  type: 'error',
  message: '',
};

const initialState: AlertState = {
  alert: emptyAlert,
};


/* Reducer */

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setAlert: (state, action) => {
      state.alert = {
        show: true,
        ...action.payload,
      };
    },
    clearAlert: (state) => {
      state.alert = emptyAlert;
    },
  },
});

export const { setAlert, clearAlert } = alertSlice.actions;


/* Selectors */

const selectAlert = (state: RootState): PopupAlert => state.alert.alert;

export const alertSelectors = {
  selectAlert,
};

export default alertSlice.reducer;
