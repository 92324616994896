import React, {
  useEffect, useState 
} from 'react';
import './ExportsCard.scss';
import {
  AppAssetPaths, canUseAuthComponent
} from '../../app/app.types';
import { useAppSelector } from '../../app/hooks';
import {
  DashboardExports, DistrictInstitutionAdminDashboardExports, Export, ExportMenuItem
} from '../../features/dashboard/types/exports.types';
import { userSelectors } from '../../features/user/user.slice';
import {
  Menu, MenuItem
} from '@mui/material';
import {
  bottomCenterAnchorOrigin, defaultMenuPaperProps, topCenterTransformOrigin
} from '../../constants/menu.types';


interface ExportsCardProps { }

const ExportsCard: React.FC<ExportsCardProps> = () => {

  const user = useAppSelector(userSelectors.selectUser);

  const [
    anchorElement,
    setAnchorElement,
  ] = useState<null | HTMLElement>(null);

  const [
    exportsArray,
    setExportsArray,
  ] = useState<Export[]>([]);

  useEffect(() => {
    if (user && user.isDistrictInstitutionAdmin) {
      setExportsArray(DistrictInstitutionAdminDashboardExports);
    }
    else {setExportsArray(DashboardExports);}
  }, []);


  return (
    <div id="exports-card" className="flex_col card-background">
      <span className="card-header flex_row">
        <img src={AppAssetPaths.icons.EXPORT} />
        <h3>Exports</h3>
      </span>

      <div className="tab-content">
        {exportsArray.map((ele: Export, i: number) => (
          canUseAuthComponent(user.roles, ele.authorizedRoles) && (
            <div className="export-menu flex_row_jcenter_acenter"
              id={i.toString()}
              key={i}
              onMouseUp={(e) => setAnchorElement(e.currentTarget)}>

              {ele.alternateTitle?.title && canUseAuthComponent(user.roles, ele.alternateTitle?.authorizedRoles) ? ele.alternateTitle?.title : ele.title}

              {ele.menu && !!ele.menu.length && (
                <Menu className="export-menu flex_row_jcenter_acenter"
                  anchorEl={anchorElement}
                  open={Boolean(anchorElement) && anchorElement?.getAttribute('id') === i.toString()}
                  onClose={() => setAnchorElement(null)}
                  autoFocus={false}
                  PaperProps={defaultMenuPaperProps}
                  transformOrigin={topCenterTransformOrigin}
                  anchorOrigin={bottomCenterAnchorOrigin}>
                  {ele.menu.map((item: ExportMenuItem, e: number) => (
                    canUseAuthComponent(user.roles, item.authorizedRoles) && (
                      <MenuItem className="menu-button"
                        onClick={() => {
                          item.onClick();
                          setAnchorElement(null);
                        }}
                        key={e} >
                        {item.title}
                      </MenuItem>
                    )))}
                </Menu>
              )}
            </div>
          )))}
      </div>
    </div>
  );
};

export default ExportsCard;
