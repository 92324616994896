import React from 'react';
import './TopGuideMenu.scss';
import { canUseAuthComponent } from '../../app/app.types';
import { useAppSelector } from '../../app/hooks';
import { userSelectors } from '../../features/user/user.slice';
import {
  TopGuideItem, TopGuideItems
} from '../../features/dashboard/types/topGuide.types';
import { useNavigate } from 'react-router-dom';
import { checkFeaturesHigherEdCounselor } from '../../app/app.permissions';


interface TopGuideMenuProps { }

const TopGuideMenu: React.FC<TopGuideMenuProps> = () => {

  const navigate = useNavigate();

  const user = useAppSelector(userSelectors.selectUser);

  return (
    <div id="top-guide-menu" className="flex_jbetween">
      {TopGuideItems.map((card: TopGuideItem, i: number) => (
        (canUseAuthComponent(user.roles, card.route ? card.route.authorizedRoles : undefined) && checkFeaturesHigherEdCounselor(card.title, user.roles)) && (
          <div
            key={i}
            className="card-background menu-card flex_column_acenter"
            onClick={() => navigate(card.route.path)}
          >
            <img src={card.icon} />
            <h4>{card.title}</h4>
            <p>{card.description}</p>
          </div>
        )))}
    </div>
  );
};

export default TopGuideMenu;
