import React, { useEffect } from 'react';
import {
  Box, Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  AppRoutes, canUseAuthComponent
} from '../../../../app/app.types';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  useAppDispatch, useAppSelector
} from '../../../../app/hooks';
import { userSelectors } from '../../../../features/user/user.slice';
import { collegeApplicationChecklistSelectors } from '../../../../features/collegeApplicationChecklist/collegeApplicationChecklist.slice';
import { getFafsaLinks } from '../../../../features/collegeApplicationChecklist/collegeApplicationChecklist.thunks';
import { createDetailsSpan } from '../../../../util/sharedHTML';
import { FafsaLinkNames } from '../../../../features/collegeApplicationChecklist/collegeApplicationChecklist.model';

import './FafsaLinksDetails.scss';


interface FafsaLinksDetailsProps { }

const FafsaLinksDetails: React.FC<FafsaLinksDetailsProps> = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(userSelectors.selectUser);
  const fafsaLinks = useAppSelector(collegeApplicationChecklistSelectors.selectFafsaLinks);
  const fafsaLinksLoaded = useAppSelector(collegeApplicationChecklistSelectors.selectFafsaLinksLoaded);

  useEffect(() => {
    if (!fafsaLinksLoaded) {
      dispatch(getFafsaLinks());
    }
  }, []);

  return (
    <div id="fafsa-links-details">
      <Box className="card-background flex_col">
        <div className="content-header flex_row_jbetween">
          <span
            className="cursor-pointer flex_row_jstart_acenter"
            onClick={() => navigate(AppRoutes.collegeApplicationChecklistFinancialAidList.path)}
          >
            <ChevronLeftIcon className="back-icon" color="secondary" />
            <h3>Fill Out a FAFSA Form</h3>
          </span>

          <span className="flex_row_jstart_acenter">
            {canUseAuthComponent(user.roles, AppRoutes.editFafsaLinks.authorizedRoles) && (
              <Button
                className="edit-button"
                variant="outlined"
                onClick={() => navigate(AppRoutes.editFafsaLinks.path)}
              >
                Edit
              </Button>
            )}
          </span>
        </div>

        <div className="details-container">
          <div className="detail-section">
            <h3 className="section-header">FAFSA Videos</h3>
            <div className="flex">
              {createDetailsSpan('English Version', fafsaLinks.fafsaVideoEnglish.url)}
              {createDetailsSpan('Spanish Version', fafsaLinks.fafsaVideoSpanish.url)}
            </div>
          </div>

          <div className="detail-section">
            <h3 className="section-header">External Links</h3>
            <div className="flex">
              {createDetailsSpan(FafsaLinkNames.HowAidWorks, fafsaLinks.howAidWorks.url)}
              {createDetailsSpan(FafsaLinkNames.FormPrep, fafsaLinks.formPrep.url)}
            </div>
            <div className="flex">
              {createDetailsSpan(FafsaLinkNames.WorkStudyJobs, fafsaLinks.workStudyJobs.url)}
              {createDetailsSpan(FafsaLinkNames.Deadlines, fafsaLinks.deadlines.url)}
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default FafsaLinksDetails;
