/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { GridSortModel } from '@mui/x-data-grid';
import { ListMenuItem } from '../../components/forms/form.types';
import {
  ConstructSkill, Skill
} from '../skills/skills.model';

export enum CourseFormats {
  OnlineSelfPace = 'onlineSelfPaced',
  OnlineInstructorLead = 'onlineInstructorLead',
  InPersonInstructorLead = 'inPersonInstructorLead',
  Hybrid = 'hybrid'
}


export interface SkillTrainingListQueryParams {
  offset: number;
  limit?: number;
  search?: string;
  sortModel?: GridSortModel;
}

export interface SkillTraining {
  id: number;
  name: string;
  company: string;
  active: boolean;
  createdAt: string;
  createdById: number | null;

  description: string;
  url: string;
  durationUnit: string;
  durationValue: number
  courseFormat: SkilltrainingCourseFormat;
  cost: number;
  isDiscounted: boolean;
  isFree: boolean;
  isLearnAndWork: boolean;
  note: string;
  skills: Skill[],
  image: string;
  zipcode: string;
  approved: boolean;
  startDate: string;
  endDate: string;
}


export const EmptySkillTraining: SkillTraining = {
  id: 0,
  name: '',
  company: '',
  active: false,
  createdAt: '',
  createdById: null,

  description: '',
  url: '',
  durationUnit: '',
  durationValue: 0,
  courseFormat: {
    onlineSelfPaced: false,
    inPersonInstructorLead: false,
    onlineInstructorLead: false,
    hybrid: false,
  },
  cost: 0,
  isDiscounted: false,
  isFree: false,
  isLearnAndWork: false,
  note: '',
  skills: [],
  image: '',
  zipcode: '',
  approved: false,
  startDate: '',
  endDate: '',
};

export interface SkilltrainingCourseFormat {
  onlineSelfPaced: false,
  inPersonInstructorLead: false,
  onlineInstructorLead: false,
  hybrid: false,
}

export interface SkillTrainingCourse extends ListMenuItem {
  value: string;
}

export const ConstructSkillTraining = (data: any): SkillTraining => {
  return {
    id: data.id,
    name: data.name,
    company: data.company,
    active: data.active,
    createdAt: data.created_at,
    createdById: data.created_by_id,

    description: data.description,
    url: data.link,
    durationUnit: data.duration_unit,
    durationValue: data.duration_value,
    courseFormat: {
      onlineSelfPaced: data.online_self_paced,
      inPersonInstructorLead: data.in_person_instructor_lead,
      onlineInstructorLead: data.online_instructor_lead,
      hybrid: data.hybrid,
    },
    cost: data.cost,
    isDiscounted: data.is_discounted,
    isFree: data.is_free,
    isLearnAndWork: data.is_learn_and_work,
    note: data.note,
    skills: (data.skills && data.skills.length) ? data.skills.map((s: any) => ConstructSkill(s)) : [],
    image: data.image,
    zipcode: data.zipcode,
    approved: data.approved,
    startDate: data.start_date,
    endDate: data.end_date,
  };
};
