import {
  AppAssetPaths,
  AppRoute,
  AppRoutes,
  AuthComponent
} from '../../../app/app.types';
import { UserRole } from '../../user/user.model';

export interface TopGuideItem {
  title: string;
  description: string;
  route: AppRoute;
  icon: string;
}

export const DashboardTopGuide: AuthComponent = {
  authorizedRoles: [
    UserRole.districtAdmin,
    UserRole.keyContact,
    UserRole.counselor,
    UserRole.community,
  ],
};

export const TopGuideItems: TopGuideItem[] = [
  {
    title: 'Users',
    description: 'View all the student Keys to Success users at your schools',
    route: AppRoutes.users,
    icon: AppAssetPaths.icons.USERS_ORANGE,
  },
  {
    title: 'Organizations',
    description: 'View organizations participating in Keys to Success',
    route: AppRoutes.organizations,
    icon: AppAssetPaths.icons.ORGANIZATIONS_BLUE,
  },
  {
    title: 'Resources',
    description: 'Add scholarships, internships, and other opportunities and view applicants',
    route: AppRoutes.resources,
    icon: AppAssetPaths.icons.RESOURCES_PURPLE,
  },
  {
    title: 'Features',
    description: 'Access features that enhance the Keys to Success app',
    route: AppRoutes.features,
    icon: AppAssetPaths.icons.STAR_GREEN,
  },
  {
    title: 'Alerts',
    description: 'Create notifications for the student app',
    route: AppRoutes.alerts,
    icon: AppAssetPaths.icons.ALERT_PURPLE,
  },
  {
    title: 'Approvals',
    description: 'Approve new users and transfers',
    route: AppRoutes.approvals,
    icon: AppAssetPaths.icons.APPROVALS_RED,
  },
];
