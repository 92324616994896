import React from 'react';
import {
  useForm, FormProvider
} from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import TextInput from '../forms/TextInput';
import SubmitButton from '../forms/SubmitButton';

import './ChangePasswordDialog.scss';


interface ChangePasswordDialogProps {
  showDialog: boolean;
  handleClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitForm: (values: any) => void;
}

const ChangePasswordDialog: React.FC<ChangePasswordDialogProps> = ({ showDialog, handleClose, submitForm }) => {
  const formValidationSchema = yup.object().shape({
    password: yup.string()
      .required('Password is required')
      .isStrongPassword()
      .typeError('Password is required'),
  }).required();

  const methods = useForm({
    resolver: yupResolver(formValidationSchema),
  });
  const { handleSubmit, formState: { errors } } = methods;

  return (
    <Dialog className="change-password-dialog" open={showDialog}>
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(submitForm)}>
          <FormProvider { ...methods }>
            <TextInput
              containerClass="main-input"
              name="password"
              label="NEW PASSWORD"
              errorMessage={errors.password?.message}
              type="password"
              size="small"
              required
            />
            <div className="flex_row_jbetween action-buttons">
              <Button
                variant="outlined"
                onClick={handleClose}>
                Cancel
              </Button>

              <SubmitButton
                text="Change Password"
                size="large"
                variant="contained"
                disableElevation={true}
              />
            </div>
          </FormProvider>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePasswordDialog;
