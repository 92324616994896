
import React, { useState } from 'react';

import {
  useAppDispatch, useAppSelector
} from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { signOut } from '../../features/user/auth.thunks';
import { userSelectors } from '../../features/user/user.slice';
import {
  AppAssetPaths, AppRoutes, canUseAuthComponent
} from '../../app/app.types';
import { UserRole } from '../../features/user/user.model';

import {
  Avatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem
} from '@mui/material';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import SettingsIcon from '@mui/icons-material/Settings';

import './ProfileNav.scss';
import {
  bottomCenterAnchorOrigin, topCenterTransformOrigin
} from '../../constants/menu.types';
import {
  clearSelectedNavSection, setPageHeader
} from '../../features/navigation/navigation.slice';


interface ProfileNavProps { }

const ProfileNav: React.FC<ProfileNavProps> = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(userSelectors.selectUser);

  const [
    anchorElement,
    setAnchorElement,
  ] = useState<null | HTMLElement>(null);

  const getUserRole = () => {
    if (!user) return;

    if (user.roles.includes(UserRole.admin)) {
      return 'admin';
    }
    if (user.roles.includes(UserRole.districtAdmin)) {
      return 'district admin';
    }
    if (user.roles.includes(UserRole.keyContact) && user.roles.includes(UserRole.community)) {
      return 'institution';
    }
    if (user.roles.includes(UserRole.keyContact)) {
      return 'key contact';
    }
    if (user.roles.includes(UserRole.counselor)) {
      return 'counselor';
    }
    if (user.roles.includes(UserRole.community)) {
      return 'community';
    }
  };

  return (
    <div id="profile-nav">
      {(canUseAuthComponent(user.roles, AppRoutes.inquiries.authorizedRoles)) && (
        <img
          className="inquiries-button"
          src={AppAssetPaths.icons.INQUIRIES}
          onClick={() => navigate(AppRoutes.inquiries.path)}
        />
      )}
      <div className="profile-button" onMouseUp={(e) => setAnchorElement(e.currentTarget)}>
        <Avatar className="profile-avatar">{user?.firstname?.[0]}{user?.lastname?.[0]}</Avatar>

        <div className="profile-info">
          <div className="profile-info-name">
            {user?.firstname} {user?.lastname}
          </div>
          <div className="profile-info-title">
            {getUserRole()}
          </div>
        </div>

        <div className="profile-expand">
          <ExpandMoreRoundedIcon className="icon-lg" />
        </div>

        <Menu
          anchorEl={anchorElement}
          open={Boolean(anchorElement)}
          onClose={() => setAnchorElement(null)}
          autoFocus={false}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(2px 3px 5px rgba(174, 174, 174, 0.25))',
              width: '225px',
              borderRadius: '6px',
            },
          }}
          transformOrigin={topCenterTransformOrigin}
          anchorOrigin={bottomCenterAnchorOrigin}
        >
          {user.roles.includes(UserRole.community) && (
            <MenuItem
              onClick={() => {
                setAnchorElement(null);
                dispatch(setPageHeader('Organization Settings'));
                dispatch(clearSelectedNavSection());
                navigate(AppRoutes.organizationSettings.path);
              }}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </MenuItem>
          )}
          <MenuItem
            className="dropdown-button"
            onClick={() => {
              // This is here to fix an annoying bug where when calling signOut() the dashboard page would flicker, recalling all api calls on the dashboard
              dispatch(signOut()).finally(() => {
                navigate(AppRoutes.signIn.path);
              });
            }}
          >
            <ListItemIcon>
              <LogoutRoundedIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default ProfileNav;
