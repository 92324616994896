/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { GridSortModel } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import {
  ConstructListMenuItem, ListMenuItem
} from '../../components/forms/form.types';
import {
  User, ConstructUser
} from '../user/user.model';
import { UserType } from '../user/userType.model';

export interface MasterKeyListQueryParams {
  offset: number;
  limit?: number | string;
  search?: string;
  sortModel?: GridSortModel;
}

export interface MasterKeySubmissionListQueryParams {
  offset: number;
  limit?: number | string;
  sortModel?: GridSortModel;
  masterKeyId?: number;
}

export enum MasterKeyTypes {
  Monthly = 'Monthly',
  College = 'College Master Key',
}

export interface MasterKeyType extends ListMenuItem { }

export const ConstructMasterKeyTypes = (data: any): MasterKeyType => {
  return {
    id: data.id,
    label: data.type,
  };
};

export interface MasterKey {
  id?: number;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  createdById?: number;
  userTypeIds: number[];
  userTypes: UserType[];
  startDate: string;
  endDate: string;
  points: number;
  title: string;
  archived: boolean;
  questions: ShortQuestion[] | MultiChoiceQuestion[]
  masterKeyTypeId: number;
  masterKeyType?: ListMenuItem;
  status?: string;
}

export interface UserShortAnswer {
  question: string;
  userAnswer: string;
}

export interface UserMultiChoiceAnswer {
  question: string;
  userAnswers: string[];
  multi: boolean;
}

export interface ShortQuestion {
  question: string;
}

export const ConstructEmptyShortQuestion = (data: any): ShortQuestion => {
  return {
    question: '',
  };
};

export interface MultiChoiceQuestion {
  question: string;
  answers: string[];
  multi: boolean;
}

export const ConstructEmptyMultiChoiceQuestion = (): MultiChoiceQuestion => {
  return {
    question: '',
    answers: [
      '',
    ],
    multi: false,
  };
};

export const EmptyMasterKey: MasterKey = {
  id: 0,
  createdAt: '',
  updatedAt: '',
  deletedAt: '',
  createdById: NaN,
  userTypeIds: [],
  userTypes: [],
  startDate: '',
  endDate: '',
  points: NaN,
  title: '',
  archived: false,
  questions: [],
  masterKeyTypeId: NaN,
  status: '',
};


export interface MasterKeySubmission {
  id?: number;
  masterKeyId?: number;
  masterKeyTitle?: string;
  user?: User;
  pointsAwarded?: boolean;
  questions?: ShortQuestion[] | MultiChoiceQuestion[]
  deletedAt?: string;
  createdAt?: string;
  updatedAt?: string;
  status?: string;
  points?: number;
}

export const EmptyMasterKeySubmission: MasterKeySubmission = {
  id: 0,
  masterKeyId: 0,
  masterKeyTitle: '',
  user: undefined,
  pointsAwarded: undefined,
  questions: [],
};


export interface MasterKeyDetails {
  id: number;
  title: string;
  userTypeIds: number[];
  userTypes: UserType[];
  startDate: string;
  endDate: string;
  points: number;
  questions?: [];
  masterKeyTypeId: number;
  masterKeyType?: ListMenuItem;
  submissionCount: number;
}

export const EmptyMasterKeyDetails: MasterKeyDetails = {
  id: 0,
  title: '',
  userTypes: [],
  userTypeIds: [],
  points: NaN,
  startDate: '',
  endDate: '',
  questions: [],
  masterKeyTypeId: NaN,
  submissionCount: NaN,
};

const getStatus = (data: any) => {
  if (data.archived === true) {
    return 'archived';
  }

  if (dayjs().isAfter(data.end_date)) {
    return 'expired';
  }

  if (dayjs().isBefore(data.start_date)) {
    return 'inactive';
  }

  return 'active';
};

const getMasterKeyUserTypes = (userTypeIds: number[], userTypeList: UserType[]) => {
  return userTypeList.filter((u: UserType) => userTypeIds.some((id: number) => id === u.id));
};

export const ConstructMasterKey = (data: any): MasterKey => {
  return {
    id: data[0].id,
    createdAt: data[0].created_at,
    updatedAt: data[0].updated_at,
    deletedAt: data[0].deleted_at,
    createdById: data[0].created_by_id,
    startDate: data[0].start_date,
    endDate: data[0].end_date,
    points: data[0].points,
    archived: data[0].archived,
    questions: data[0].questions,
    title: data[0].title,
    userTypeIds: data[0].user_type_ids || [],
    userTypes: getMasterKeyUserTypes(data[0].user_type_ids, data[1]),
    masterKeyTypeId: data[0].type_id,
    masterKeyType: data[0].master_key_type && ConstructListMenuItem(data[0].master_key_type.id, data[0].master_key_type.type),
    status: getStatus(data[0]),
  };
};

export const ConstructMasterKeySubmission = (data: any): MasterKeySubmission => {
  return {
    id: data.id,
    masterKeyId: data.master_key_id,
    masterKeyTitle: data.master_key_title,
    points: data.master_key?.points || 0,
    user: data.user && ConstructUser(data.user),
    pointsAwarded: data.points_awarded,
    questions: data.master_key_questions,
    createdAt: data.created_at,
    deletedAt: data.deleted_at,
    updatedAt: data.updated_at,
    status: '',
  };
};

export const ConstructMasterKeyDetails = (data: any): MasterKeyDetails => {
  return {
    id: data.id,
    title: data.title,
    userTypeIds: data.user_type_ids || [],
    userTypes: getMasterKeyUserTypes(data.user_type_ids, data.user_type_list),
    points: data.points,
    startDate: data.start_date,
    endDate: data.end_date,
    questions: data.questions || [],
    masterKeyTypeId: data.type_id,
    masterKeyType: data.master_key_type && ConstructListMenuItem(data.master_key_type.id, data.master_key_type.type),
    submissionCount: data.submission_count,
  };
};
