import React, { useEffect } from 'react';
import {
  useLocation, useParams
} from 'react-router-dom';
import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import CertificateDetails from '../../../components/details/pathway/CertificateDetails';
import CompleterCoursesTable from '../../../components/details/pathway/CompleterCoursesTable';
import ConcentratorCoursesTable from '../../../components/details/pathway/ConcentratorCoursesTable';
import ExplorerCoursesTable from '../../../components/details/pathway/ExplorerCoursesTable';
import MiddleSchoolCoursesTable from '../../../components/details/pathway/MiddleSchoolCoursesTable';
import PathwayOverview from '../../../components/details/pathway/PathwayOverview';
import PostSecondaryResourcesTable from '../../../components/details/pathway/PostSecondaryResourcesTable';
import SupportingCoursesTable from '../../../components/details/pathway/SupportingCoursesTable';
import { pathwaySelectors } from '../../../features/pathways/pathways.slice';
import {
  dismissPathwayUpdatedBanner,
  getPathwayDetails,
  togglePathwayCourseActive,
  togglePathwayCourseActiveForSchool
} from '../../../features/pathways/pathways.thunks';
import { userSelectors } from '../../../features/user/user.slice';
import { UserRole } from '../../../features/user/user.model';
import {
  PathwayCourse, PathwayCourseSchool
} from '../../../features/pathways/pathways.model';
import { Tooltip } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import './PathwayDetails.scss';


interface PathwayDetailsProps { }

const PathwayDetails: React.FC<PathwayDetailsProps> = () => {

  const params = useParams();
  const { state } = useLocation();
  const dispatch = useAppDispatch();

  const user = useAppSelector(userSelectors.selectUser);
  const pathwayDetails = useAppSelector(pathwaySelectors.selectPathwayDetails);

  useEffect(() => {
    if (params.id) {
      dispatch(getPathwayDetails({
        id: +params.id,
        organizationId: state && state.schoolId,
      }));
    }
  }, []);

  const showUpdateBanner = (courses?: PathwayCourse[]) => {
    if (!courses) return false;

    for (const course of courses) {
      if (user.isHighSchoolAdmin && course.pathwayCourseSchool?.updatedValues) return true;
    }

    return false;
  };

  const isConcurrentEnrollment = (course?: PathwayCourse) => {
    if (!user.isAdmin && course?.pathwayCourseSchool?.concurrentEnrollment != null) {
      return course?.pathwayCourseSchool.concurrentEnrollment as boolean;
    }

    return course?.concurrentEnrollment as boolean || false;
  };

  const getCourseActiveStatus = (id?: number, active?: boolean, pathwayCourseSchool?: PathwayCourseSchool) => {
    if (user.roles.includes(UserRole.admin)) {
      return (
        active ? (
          <Tooltip title={!user.isMiddleSchoolAdmin ? 'Click to hide this from school admins' : ''} arrow>
            <VisibilityIcon className="eye-icon cursor-pointer" onClick={() => id && dispatch(togglePathwayCourseActive(id))} />
          </Tooltip>
        ) : (
          <Tooltip title={!user.isMiddleSchoolAdmin ? 'Click to show this to school admins' : ''} arrow>
            <VisibilityOffIcon className="eye-icon cursor-pointer" onClick={() => id && dispatch(togglePathwayCourseActive(id))} />
          </Tooltip>
        )
      );
    }
    else {
      return (
        !pathwayCourseSchool?.isHidden ? (
          <Tooltip title={!user.isMiddleSchoolAdmin ? 'Click to hide this from students' : ''} arrow>
            <VisibilityIcon
              className={`eye-icon ${!user.isMiddleSchoolAdmin && 'cursor-pointer'}`}
              onClick={() => {
                if (user.isMiddleSchoolAdmin) return;

                if (id && pathwayCourseSchool) {
                  dispatch(togglePathwayCourseActiveForSchool({
                    id,
                    isHidden: true,
                    organizationId: user.isDistrictAdmin ? pathwayCourseSchool.organizationId || state.schoolId : undefined,
                  }));
                }
              }}
            />
          </Tooltip>
        ) : (
          <Tooltip title={!user.isMiddleSchoolAdmin ? 'Click to show this to students' : ''} arrow>
            <VisibilityOffIcon
              className={`eye-icon ${!user.isMiddleSchoolAdmin && 'cursor-pointer'}`}
              onClick={() => {
                if (user.isMiddleSchoolAdmin) return;

                if (id && pathwayCourseSchool) {
                  dispatch(togglePathwayCourseActiveForSchool({
                    id,
                    isHidden: false,
                    organizationId: user.isDistrictAdmin ? pathwayCourseSchool.organizationId || state.schoolId : undefined,
                  }));
                }
              }}
            />
          </Tooltip>
        )
      );
    }
  };

  return (
    <div id="pathway-details">
      {(user.isHighSchoolAdmin && pathwayDetails.updatedByDistrictAdmin) && (
        <div className="updated-banner">
          <b>A district admin has made changes to this pathway</b>
          <p>Changes are displayed in red below.</p>

          <CloseIcon className="close-icon" onClick={() => dispatch(dismissPathwayUpdatedBanner(pathwayDetails.pathwaySchool.id))} />
        </div>
      )}

      <PathwayOverview
        pathway={pathwayDetails}
        schoolId={user.isDistrictAdmin || user.isHighSchoolAdmin ? state.schoolId : null}
      />

      <div className="colapsable-sections">
        <h3 className="section-header">Middle School/Jr. High</h3>
        <MiddleSchoolCoursesTable
          courses={pathwayDetails.courses?.middleSchool}
          getCourseActiveStatus={getCourseActiveStatus}
          showUpdateBanner={showUpdateBanner}
        />

        <h3 className="section-header">High School</h3>
        <SupportingCoursesTable
          courses={pathwayDetails.courses?.supporting}
          getCourseActiveStatus={getCourseActiveStatus}
          isConcurrentEnrollment={isConcurrentEnrollment}
          showUpdateBanner={showUpdateBanner}
        />
        <ExplorerCoursesTable
          courses={pathwayDetails.courses?.explorer}
          getCourseActiveStatus={getCourseActiveStatus}
          isConcurrentEnrollment={isConcurrentEnrollment}
          showUpdateBanner={showUpdateBanner}
        />
        <ConcentratorCoursesTable
          courses={pathwayDetails.courses?.concentrator}
          getCourseActiveStatus={getCourseActiveStatus}
          isConcurrentEnrollment={isConcurrentEnrollment}
          showUpdateBanner={showUpdateBanner}
        />
        <CompleterCoursesTable
          courses={pathwayDetails.courses?.completer}
          getCourseActiveStatus={getCourseActiveStatus}
          isConcurrentEnrollment={isConcurrentEnrollment}
          showUpdateBanner={showUpdateBanner}
        />
        <PostSecondaryResourcesTable
          courses={pathwayDetails.courses?.postSecondaryResources}
          getCourseActiveStatus={getCourseActiveStatus}
        />

        <CertificateDetails />
      </div>
    </div>
  );
};

export default PathwayDetails;
