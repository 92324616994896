/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ResourceListQueryParams } from './resources.model';
import * as resourceApi from './resources.api';


export const getResourceList = createAsyncThunk(
  'resources/getResourceList',
  async (query: ResourceListQueryParams) => {
    return await resourceApi.getResourceList(query);
  }
);

export const getResourceById = createAsyncThunk(
  'resources/getResourceById',
  async (data: { id: number, type: string }) => {
    return await resourceApi.getResourceById(data.id, data.type);
  }
);

export const getResourceApplications = createAsyncThunk(
  'resources/getResourceApplications',
  async (data: { id: number, type: string }) => {
    return await resourceApi.getResourceApplications(data.id, data.type);
  }
);

export const createResource = createAsyncThunk(
  'resources/createResource',
  async (data: { type: string, values: any }) => {
    return await resourceApi.createResource(data.values, data.type);
  }
);

export const updateResource = createAsyncThunk(
  'resources/updateResource',
  async (data: { id: number, type: string, values: any }, thunkAPI) => {
    await resourceApi.updateResource(data.id, data.type, data.values);
    thunkAPI.dispatch(getResourceById({
      id: data.id,
      type: data.type,
    }));
  }
);

export const deleteResource = createAsyncThunk(
  'resources/deleteResource',
  async (data: { id: number, type: string }) => {
    return await resourceApi.deleteResource(data.id, data.type);
  }
);

export const approveResource = createAsyncThunk(
  'resources/approve',
  async (data: { id: number, type: string }) => {
    return await resourceApi.approveResource(data.id, data.type);
  }
);

export const rejectResource = createAsyncThunk(
  'resources/reject',
  async (data: { id: number, type: string }) => {
    return await resourceApi.rejectResource(data.id, data.type);
  }
);
