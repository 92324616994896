import { createAsyncThunk } from '@reduxjs/toolkit';
import * as zipcodeApi from './zipcode.api';


export const getZipcodeList = createAsyncThunk(
  'zipcode/getZipcodeList',
  async () => {
    return await zipcodeApi.getZipcodeList();
  }
);
