import React, { useEffect } from 'react';
import { Box } from '@mui/system';
import './ResourceTagDetails.scss';
import {
  useNavigate, useParams
} from 'react-router-dom';
import {
  AppRoutes, canUseAuthComponent
} from '../../../app/app.types';
import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import { resourceTagSelectors } from '../../../features/resourceTags/resourceTags.slice';
import { getResourceTagById } from '../../../features/resourceTags/resourceTags.thunks';
import { userSelectors } from '../../../features/user/user.slice';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button } from '@mui/material';


interface ResourceTagDetailsProps { }

export const ResourceTagDetails: React.FC<ResourceTagDetailsProps> = () => {

  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(userSelectors.selectUser);
  const tagDetails = useAppSelector(resourceTagSelectors.selectResourceTagDetails);


  useEffect(() => {
    if (params && params.id) {
      dispatch(getResourceTagById(+params.id));
    }
  }, []);

  return (
    <div id="resource-tag-details">
      <Box className="card-background">
        <div className="details-header flex_row_jbetween">

          <span className="back-button flex_acenter" onClick={() => navigate(AppRoutes.resourceTags.path)}>
            <ChevronLeftIcon color="secondary" />
            <h3>Tag Details</h3>
          </span>

          {canUseAuthComponent(user.roles, AppRoutes.editResourceTag.authorizedRoles) && (
            <Button
              variant="outlined"
              onClick={() => navigate(AppRoutes.editResourceTag.path.replace(':id', params.id!.toString()))}
            >
              Edit
            </Button>
          )}
        </div>

        <div className="card-content flex_col_jstart_astart">
          <span>
            <p>TAG NAME</p>
            <p>{tagDetails.name}</p>
          </span>

          <span>
            <p>TAG DESCRIPTION</p>
            <p>{tagDetails.description}</p>
          </span>
        </div>
      </Box>

    </div>
  );
};


export default ResourceTagDetails;