import React, {
  useEffect, useState
} from 'react';
import './CareerClusterList.scss';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import { careerSelectors } from '../../../features/careers/careers.slice';
import { getCareerGroups } from '../../../features/careers/careers.thunks';
import { CareerClusterListQuery } from '../../../features/careers/careers.model';
import {
  DataGrid, GridSortModel
} from '@mui/x-data-grid';
import { TableState } from '../../../constants/tables/table.types';
import { careerClusterListColumns } from '../../../constants/tables/careerClusterTable.types';
import { AppRoutes } from '../../../app/app.types';
import TableRowCount from '../../../components/table/TableRowCount';
import { Pagination } from '@mui/material';


interface CareerClusterListProps { }

const CareerClusterList: React.FC<CareerClusterListProps> = () => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const careerGroups = useAppSelector(careerSelectors.selectCareerGroups);
  const careerGroupsCount = useAppSelector(careerSelectors.selectCareerGroupsCount);

  const [
    tableState,
    setTableState,
  ] = useState<TableState>({
    page: 1,
    pageSize: 100,
    columns: careerClusterListColumns,
    rows: [],
    loading: false,
  });

  const [
    careerClustListQuery,
    setCareerClusterListQuery,
  ] = useState<CareerClusterListQuery>({
    sortModel: undefined,
  });

  useEffect(() => {
    setTableState((prev) => ({
      ...prev,
      loading: false,
      rows: careerGroups,
    }));
  }, [
    careerGroups,
  ]);

  useEffect(() => {
    dispatch(getCareerGroups({
      ...careerClustListQuery,
    }));
  }, [
    careerClustListQuery,
  ]);


  return (
    <div id="career-cluster-list">
      <Box className="page-content card-background flex_col">
        <div className="list-header flex_col">
          <span className="back-button flex_acenter" onClick={() => navigate(AppRoutes.skillTrainings.path)}>
            <ChevronLeftIcon color="secondary" />
            <h3> Career Clusters </h3>
          </span>
          <p> Career clusters are used to categorize skill training courses. Within each career cluster, skills are added as <b>must have</b> or <b>need to have</b> skills.</p>
        </div>

        <DataGrid
          className="table"
          {...tableState}
          components={{
            Pagination: Pagination,
          }}
          componentsProps={{
            pagination: {
              page: tableState.page,
              count: Math.ceil(careerGroupsCount / tableState.pageSize),
              onChange: (_: void, page: number) => {
                setTableState((prev) => ({
                  ...prev,
                  page,
                }));
              },
            },
          }}
          onRowClick={(event) => navigate(AppRoutes.careerClusterDetails.path.replace(':id', event.row.id.toString()))}
          pagination
          paginationMode="server"
          sortingMode="server"
          sortModel={careerClustListQuery.sortModel}
          onSortModelChange={(newSortModel: GridSortModel) => {
            setCareerClusterListQuery((prev) => ({
              ...prev,
              sortModel: newSortModel,
            }));
          }}
          disableColumnFilter
          disableColumnMenu
          hideFooterSelectedRowCount
        />

        <TableRowCount
          length={careerGroupsCount}
          pageSize={tableState.pageSize}
          page={tableState.page}
          totalCount={careerGroupsCount}
        />
      </Box>
    </div>
  );
};

export default CareerClusterList;
