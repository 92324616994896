import {
  GridColumns,
  GridValueFormatterParams
} from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { startCase } from 'lodash';

export const schoolListColumns: GridColumns = [
  {
    headerName: 'School',
    field: 'name',
    cellClassName: 'main-column',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'School Type',
    field: 'type',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => startCase(params.value) || '-',
  },
  {
    headerName: 'Url',
    field: 'url',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Date Created',
    field: 'createdAt',
    width: 150,
    valueFormatter: (params: GridValueFormatterParams) => dayjs(params.value as string).format('DD MMM YYYY'),
  },
];

export const communityListColumns: GridColumns = [
  {
    headerName: 'Community',
    field: 'name',
    cellClassName: 'main-column pl-20',
    headerClassName: 'pl-20',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Date Created',
    field: 'createdAt',
    width: 150,
    valueFormatter: (params: GridValueFormatterParams) => dayjs(params.value as string).format('DD MMM YYYY'),
  },
];
